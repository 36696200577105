import React from 'react';
import PubSub from "pubsub-js";
import settings from "../../../config/settings.json";
import Popup from "react-popup";
import { customMediaTableStyles, userTableColumns, setPageTitle, userGroupsTableColumns } from "../../../helpers/LayoutHelper";
import {
    createUsers,
    getUsers,
    getGroups,
    createGroups,
    removeGroups,
    createGroupAttach,
    updateProfile, sendMailAfterCreateUser, getProfileByPhone
} from "../../../services/api";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import MediaRowDetail from "../../../components/lists/MediaRowDetail";
import LoadingView from "../../../components/common/LoadingView";
import EmptyView from "../../../components/common/EmptyView";
import {Checkbox, Modal, ModalActions, ModalContent, ModalHeader} from "semantic-ui-react";
import pieImg from "../../../assets/images/pieChart.png";
import DevMultiSelect from "../../../components/forms/DevMultiSelect";
import Swal from "sweetalert2";
import tr from "date-fns/locale/tr";
import DatePicker from "react-datepicker";
import moment from "moment";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/semantic-ui.css'

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "Kişiler",
            loaded: false,
            adminList: [],
            userList: [],
            allUserList: [],

            userGroups: [],

            isUserOrAdminPage: true,
            groupName: '',
            groupUser: [],

            userFirstName: '',
            userLastName: '',
            username: '',
            userEmail: '',
            userPhone: '',
            userBirthDate: null,
            userGroup: [],
            userTitle:'',
            department:'',
            locations:'',
            city:'',


            selectedGroups: [],
            groupsForBox: []
        }

        this.inputCss = { height: 40, width: 300, cursor: 'default' }


        this.subscribeToken = PubSub.subscribe('DATECHANGED', this.subscribeDate);
    }

    // Clear the inputs in Kullanıcı Ekle modal
    clearUserForm = () => {
        this.setState({
            userFirstName: '',
            userLastName: '',
            username: '',
            userEmail: '',
            userPhone: '',
            userBirthDate: null,
            userTitle: '',
            department: '',
            isContentAdmin: false,
            isSystemAdmin: false,
            city: '',
            locations: '',
            userGroup: [],
            selectedGroups: [],
        })
    }

    clearUserGroupForm = () => {
        this.setState({
            groupName: "",
            groupUser: []

        })
    }


    /* Standart methods for all pages */
    componentDidMount() {
        PubSub.publish('MENU', 'users');

        this.updatePageTitle();
        this.loadGroups()

        PubSub.subscribe('update-users', this.getList)
        PubSub.subscribe('update-users-2', this.getList)

        PubSub.subscribe('group-delete-reload', this.getList)
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount() {
        this.updatePage(this.props);
    }

    updatePage = async (props) => {
        this.getList();
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {

    };
    /* Standart methods for all pages */

    addUserModalToggle = () => {
        this.setState({ addUserModal: !this.state.addUserModal })
    }

    loadGroups = async () => {
        const users = await getUsers();

        let usersForBox = []
        users.map(user => {
            let tempObj = { value: user?.id, label: user?.first_name+" "+user?.last_name ,id:user?.id}
            usersForBox.push(tempObj)
        })
        this.setState({
            users: users,
            allUserList: usersForBox
        });
    }

    // React-Dual-listbox onChange function
    handleListBoxOnChange = (selected) => {
        this.setState({selectedGroups: selected})
    }
    handleListBoxGroupOnChange = (selected) => {
        this.setState({groupUser: selected})
    }


    handleSaveUser = () => {

        if (this.state.userFirstName.length < 2 ||
            this.state.userLastName.length < 2 ||
            (this.state.userEmail.length < 2 && this.state.userPhone.length < 2 ) ||
            this.state.userTitle.length < 2 ||
            this.state.department.length < 2 ||
            this.state.city.length < 2 ||
            this.state.locations.length < 2 ||
            this.state.userBirthDate ===null ||
            this.state.selectedGroups.length===0) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen zorunlu alanları doldurunuz!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            return;
        }

        let groups = []
        let tempArr = []
        this.state.selectedGroups.map(data => {
            let tempObj = this.state.groupsForBox.filter(i => i.label === data)
            tempArr.push(tempObj)
        })
        tempArr.map(res => {
            res.map(group => {
                let tempObj = {id: group.id, name: group.label}
                let tempId = group.id
                groups.push(tempId)
            })
        })
        let username = this.state.userFirstName.toLocaleLowerCase()
                .replace(/ğ/g, "g")
                .replace(/ü/g, "u")
                .replace(/ş/g, "s")
                .replace(/ı/g, "i")
                .replace(/ö/g, "o")
                .replace(/ç/g, "c").trim().replaceAll(' ', '')
            + this.state.userLastName.toLocaleLowerCase()
                .replace(/ğ/g, "g")
                .replace(/ü/g, "u")
                .replace(/ş/g, "s")
                .replace(/ı/g, "i")
                .replace(/ö/g, "o")
                .replace(/ç/g, "c").trim().replaceAll(' ', '')


        const payload = {
            first_name: this.state.userFirstName,
            last_name: this.state.userLastName,
            username: username,
            email: this.state.userEmail,
            groups: groups,
            is_active: true,
            password: "deneme12"
        }
        // console.log('PAYLOAD : ', payload)

        getProfileByPhone(this.state.userPhone)
            .then(res => {
                if(res.length !== 0 && this.state.userPhone !== ""){
                    Swal.fire({
                        icon: 'warning',
                        text: "Bu numara ile kayıtlı bir kullanıcı mevcut!",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    })
                    return
                } else {
                    createUsers(payload)
                        .then(res => {
                            if(res.status === 200 && res.errors.length !== 0){
                                let msg = res.errors.username[0]
                                Swal.fire({
                                    icon: 'warning',
                                    text: msg,
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                                return
                            }
                            if(res.status===400){
                                let msg = res.message
                                Swal.fire({
                                    icon: 'warning',
                                    text: msg,
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                                return
                            }
                            let email = {email: this.state.userEmail}
                            sendMailAfterCreateUser(email)
                                .then(res => console.log('RES : ', res))
                                .catch(err => console.log('ERR : ', err))

                            const profilePayload = {
                                user: res.id,
                                title: this.state.userTitle,
                                department: this.state.department,
                                phone: this.state.userPhone,
                                city: this.state.city,
                                locations: this.state.locations,
                                birthDate: moment(this.state.userBirthDate).format('DD-MM-YYYY'),
                                isContentAdmin : this.state.isContentAdmin,
                                isSystemAdmin : this.state.isSystemAdmin,
                                userType: 'SA',
                            }
                            updateProfile(res.id, profilePayload)
                                .then(response => {
                                    if(response.status===200){
                                        let msg = ""
                                        if(response.errors.phone){msg=response.errors.phone[0]}
                                        Swal.fire({
                                            icon: 'warning',
                                            text: msg,
                                            showCancelButton: true,
                                            showConfirmButton: false,
                                            cancelButtonColor: '#3B4182',
                                            cancelButtonText: "Tamam",
                                            focusCancel: false,
                                        })
                                        return
                                    }
                                    Swal.fire({
                                        icon: 'success',
                                        text: 'Kullanıcı Oluşturuldu!',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#3B4182',
                                        cancelButtonText: "Tamam",
                                        focusCancel: false,
                                    })
                                })
                                .catch(error => {
                                    Swal.fire({
                                        icon: 'error',
                                        text: 'Kullanıcı Oluşturulamadı!',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#3B4182',
                                        cancelButtonText: "Tamam",
                                        focusCancel: false,
                                    })
                                })
                            PubSub.publish('user-created',{});
                            this.addUserModalToggle();
                            this.clearUserForm();
                            this.getList();
                            this.loadGroups();


                        })
                        .catch(err => {
                            Swal.fire({
                                icon: 'error',
                                text: 'Kullanıcı Oluşturulamadı!',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonColor: '#3B4182',
                                cancelButtonText: "Tamam",
                                focusCancel: false,

                            })
                        })
                }
            }).catch(err => console.log('PROFILE ERROR : ', err))

    }

    handleSaveGroup = () => {
        let users = []
        let tempArr = []
        this.state.groupUser.map(data => {
            let tempObj = this.state.allUserList.filter(i => i.value === data)
            tempArr.push(tempObj)
        })
        tempArr.map(res => {
            res.map(user => {
                let tempId = user.id
                users.push(tempId)
            })
        })

        const payload = {
            name: this.state.groupName,
        }

        createGroups(payload).then(async (res) =>{
            // console.log('RESPONSE : ', res)
            if(res && res.errors && res.errors.name[0] && res.errors.name[0] === "Bu adı alanına sahip grup zaten mevcut."){
                Swal.fire({
                    icon: 'warning',
                    text: 'Bu isime sahip bir grup zaten mevcut!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,
                })
                return
            }
            const attachPayload = {
                "group": res.group_id,
                "users": users
            }
            createGroupAttach(attachPayload).then(res => {
                Swal.fire({
                    icon: 'success',
                    text: 'Grup Oluşturuldu!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,
                })
            }).catch(err=>{
                console.log(err.status)
                Swal.fire({
                    icon: 'warning',
                    text: 'Grup Oluşturulamadı!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,
                })
            })



            this.addUserGroupModalToggle();
            this.clearUserGroupForm();
            this.getList();
            PubSub.publish('group-created', {})
        }).catch(err =>
            Swal.fire({
                icon: 'warning',
                text: 'Grup Oluşturulamadı!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            }))
    }

    handleRemoveGroup = (id) => {
        removeGroups(id)
            .then((res) => {
                Swal.fire({
                    icon: 'error',
                    text: 'Grup Silindi!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                this.getList()
            })
            .catch(err => console.error('Grup SİLİNEMEDİ : ', err))
    }

    getList = async () => {
        const users = await getUsers();
        const userGroups = await getGroups();
        let allUsers = users.filter(user => user.is_active===true)
        let groupsForBox = []
        userGroups.map(group => {
            let tempObj = {value: group.name, label: group.name, id: group.id}
            groupsForBox.push(tempObj)
        })

        // AdminUsers İçerik yöneticisi tabı için
        // normalUsers Kullanıcılar tabı için kullanılıyordu ancak değiştirildi. şuanda ikisi de kullanılmıyor
        let adminUsers = allUsers?.filter((item) => { return item.is_staff }).map(item => { return { ...item, full_name: item.first_name + ' ' + item.last_name, groups: item.groups.map(g => <span className="badge badge-sm light badge-secondary mr-1">{g.name}</span>) } });
        let normalUsers = allUsers?.filter((item) => { return !item.is_staff }).map(item => { return { ...item, full_name: item.first_name + ' ' + item.last_name, groups: item.groups.map(g => <span className="badge badge-sm light badge-secondary mr-1">{g.name}</span>) } });
        let groupUsers = adminUsers?.concat(normalUsers);

        // Hakan Ünal yorum satısı şuan için kalsın 14 eylül 09:07
        // const test = userGroups.map(group => group.permissions.map(per => per + ", "))
        // userGroups.map((group, index) => group.permissions = test[index])

        const tempArr = userGroups.map(group => group.user_list.filter(user => user.is_active===true).map(user => user.first_name + " " + user.last_name + " "))
        userGroups.map((group, index) => group.userList = tempArr[index])

        this.setState({
            loaded: true,
            adminList: adminUsers,
            userList: allUsers,
            //allUserList: groupUsers,
            userGroups: userGroups,
            groupsForBox: groupsForBox,

        });
    }
    addUserGroupModalToggle = () => {
        this.setState({ addUserGroupModal: !this.state.addUserGroupModal })
    }

    // handleMultiSelectBox = (values, choice) => {
    //     if (choice === "user") {
    //         values.map((value) => {
    //             if (value.value === "*") {
    //                 this.setState({
    //                     userGroup: this.state.userGroups?.map((userGroup) => {
    //                         return { value: userGroup?.id, label: userGroup?.name };
    //                     })
    //                 })
    //             } else {
    //                 this.setState({
    //                     userGroup: values
    //                 })
    //             }
    //         })
    //     }
    //
    //     if (choice === "group") {
    //         values.map((value) => {
    //             if (value.value === "*") {
    //                 this.setState({
    //                     groupUser: this.state.allUserList?.map((user) => {
    //                         return { value: user?.id, label: user?.name };
    //                     })
    //                 })
    //             } else {
    //                 this.setState({
    //                     groupUser: values
    //                 })
    //             }
    //         })
    //     }
    // }

    render() {

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body px-4 py-3 py-md-2">
                                <div className="row align-items-center">
                                    <div className="col-sm-12 col-md-7">
                                        <ul className="nav nav-pills review-tab">
                                            {/*<li className="nav-item">*/}
                                            {/*    <a href="#list-admins" onClick={() => {*/}
                                            {/*        this.setState({ isUserOrAdminPage: true })*/}
                                            {/*        this.getList()*/}
                                            {/*    }}*/}
                                            {/*        className="nav-link px-2 px-lg-3 active" data-toggle="tab" aria-expanded="false">İçerik Yöneticileri</a>*/}
                                            {/*</li>*/}
                                            <li className="nav-item">
                                                <a href="#list-users" onClick={() => {
                                                    this.setState({ isUserOrAdminPage: true })
                                                    this.getList()
                                                }} className="nav-link px-2 px-lg-3 active" data-toggle="tab" aria-expanded="false">Kullanıcılar</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#list-groups" onClick={() => {
                                                    this.setState({ isUserOrAdminPage: false })
                                                    this.getList()
                                                }}
                                                    className="nav-link px-2 px-lg-3" data-toggle="tab" aria-expanded="false">Gruplar</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-12 col-md-5 text-md-right mt-md-0 mt-4">
                                        <Link onClick={() => this.state.isUserOrAdminPage ? this.addUserModalToggle() : this.addUserGroupModalToggle()} className="btn btn-primary rounded btn-sm px-4">{this.state.isUserOrAdminPage ? "Kullanıcı Ekle" : "Grup Ekle"}</Link>
                                    </div>



                                    {/**User and Admin add Modal Start */}
                                    <Modal onClose={() => this.addUserModalToggle()}
                                        onOpen={() => this.addUserModalToggle()}
                                        closeIcon
                                        size="tiny"
                                        open={this.state.addUserModal}>
                                        <ModalHeader>Kullanıcı Ekle</ModalHeader>
                                        <ModalContent>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>Adı *: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        name="userFirstName"
                                                        id="userFirstName"
                                                        className='form-control float-left'
                                                        value={this.state.userFirstName}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                        style={this.inputCss}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>Soyadı *: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        name="userLastName"
                                                        id="userLastName"
                                                        className='form-control float-left'
                                                        value={this.state.userLastName}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                        style={this.inputCss}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>Kullanıcı Adı *: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        name="username"
                                                        id="username"
                                                        className='form-control'
                                                        value={this.state.userFirstName.toLocaleLowerCase()
                                                            .replace(/ğ/g, "g")
                                                            .replace(/ü/g, "u")
                                                            .replace(/ş/g, "s")
                                                            .replace(/ı/g, "i")
                                                            .replace(/ö/g, "o")
                                                            .replace(/ç/g, "c").trim().replaceAll(' ', '')
                                                        + this.state.userLastName.toLocaleLowerCase()
                                                            .replace(/ğ/g, "g")
                                                            .replace(/ü/g, "u")
                                                            .replace(/ş/g, "s")
                                                            .replace(/ı/g, "i")
                                                            .replace(/ö/g, "o")
                                                            .replace(/ç/g, "c").trim().replaceAll(' ', '')}
                                                        readOnly
                                                        style={this.inputCss}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>E-Posta {this.state.userPhone === "" || this.state.userPhone === "90" ? "*" : ""}: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        name="userEmail"
                                                        id="userEmail"
                                                        className='form-control'
                                                        value={this.state.userEmail}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                        style={this.inputCss}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>Tel No {this.state.userEmail === "" ? "*" : ""}: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <PhoneInput
                                                        country={'tr'}
                                                        // name="userPhone"
                                                        // id="userPhone"
                                                        inputClass="react-tel-input"
                                                        buttonClass="react-tel-input-flag"
                                                        value={this.state.userPhone}
                                                        onChange={e => this.setState({ userPhone: e})}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>Doğum Tarihi *: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <DatePicker
                                                        wrapperClassName="datePicker"
                                                        placeholderText="Gün.Ay.Yıl"
                                                        selected={this.state.userBirthDate}
                                                        isClearable={false}
                                                        showTimeSelect={false}
                                                        timeFormat="H:mm"
                                                        timeIntervals={15}
                                                        locale={tr}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd.MM.yyyy"
                                                        onChange={date => {
                                                            // console.log(date)
                                                            this.setState({userBirthDate: date})
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>Unvan *: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        name="userTitle"
                                                        id="userTitle"
                                                        className='form-control float-left'
                                                        value={this.state.userTitle}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                        style={this.inputCss}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>Departman *: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        name="department"
                                                        id="department"
                                                        className='form-control float-left'
                                                        value={this.state.department}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                        style={this.inputCss}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className="col-sm-1"/>
                                                <div className="col-sm-10">
                                                    <label className='mt-2'>Yetkilendirme : </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-sm-2' />
                                                <div className='col-sm-3'>
                                                    <Checkbox className="mt-3" label='Standart Kullanıcı' checked={true}/>
                                                </div>
                                                <div className='col-sm-3'>
                                                    <Checkbox className="mt-3" label='İçerik Yöneticisi' checked={this.state.isContentAdmin} value={this.state.isContentAdmin}
                                                              onChange={() => this.setState({isContentAdmin: !this.state.isContentAdmin})}/>
                                                </div>
                                                <div className='col-sm-3'>
                                                    <Checkbox className="mt-3" label='Sistem Yöneticisi' checked={this.state.isSystemAdmin} value={this.state.isSystemAdmin}
                                                              onChange={() => {
                                                                  this.setState({isSystemAdmin: !this.state.isSystemAdmin}, () => {
                                                                      if(this.state.isSystemAdmin){
                                                                          this.setState({isContentAdmin: true})
                                                                      }
                                                                  })
                                                              }}/>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>İl *: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        name="city"
                                                        id="city"
                                                        className='form-control'
                                                        value={this.state.city}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                        style={this.inputCss}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1' />
                                                <div className='col-sm-3'>
                                                    <label className='mt-2'>Lokasyon *: </label>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <input
                                                        name="locations"
                                                        id="locations"
                                                        className='form-control'
                                                        value={this.state.locations}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                        style={this.inputCss}
                                                    />
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="row mt-2">
                                                <div className='col-sm-1'/>
                                                <div className='col-sm-11'>
                                                    <label className='mt-2'>Gruplar *:</label>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-1'/>
                                                <div className='col-sm-10'>
                                                    <DualListBox
                                                        canFilter
                                                        filterPlaceholder="Ara..."
                                                        options={this.state.groupsForBox}
                                                        selected={this.state.selectedGroups}
                                                        onChange={this.handleListBoxOnChange}
                                                    />
                                                </div>
                                            </div>
                                        </ModalContent>
                                        <ModalActions>
                                            <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                                                onClick={() => this.addUserModalToggle()}>Vazgeç
                                            </button>
                                            <input type="button" className="btn btn-success rounded btn-sm px-4 float-right " onClick={this.handleSaveUser} value="Kaydet" />
                                        </ModalActions>
                                    </Modal>


                                    {/**User Group Modal Start */}
                                    <Modal onClose={() => this.addUserGroupModalToggle()}
                                        onOpen={() => this.addUserGroupModalToggle()}
                                        closeIcon
                                        size="small"
                                        open={this.state.addUserGroupModal}>
                                        <ModalHeader>Grup Ekle</ModalHeader>
                                        <ModalContent>
                                            <div className="row">
                                                <div className='col-sm-2'>
                                                    <label className='mt-2'>Grup Adı : </label>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <input
                                                        name="groupName"
                                                        id="groupName"
                                                        className='form-control'
                                                        value={this.state.groupName}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                        style={{ height: 40, width: 200, cursor: 'text' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className='col-sm-2'>
                                                    <label className='mt-2'>Kullanıcılar : </label>
                                                </div>
                                                <div className='col-sm-10'>
                                                    <DualListBox
                                                        canFilter
                                                        filterPlaceholder="Ara..."
                                                        options={this.state.allUserList}
                                                        selected={this.state.groupUser}
                                                        onChange={this.handleListBoxGroupOnChange}
                                                    />
                                                    {/*<DevMultiSelect*/}
                                                    {/*    itemTitle='Grup'*/}
                                                    {/*    name='groups'*/}
                                                    {/*    allGroups={false}*/}
                                                    {/*    onChange={(values) => this.handleMultiSelectBox(values, "group")}*/}
                                                    {/*    value={this.state.groupUser}*/}
                                                    {/*    options={this.state.allUserList?.map((user) => {*/}
                                                    {/*        return { value: user?.id, label: user?.full_name };*/}
                                                    {/*    })}*/}
                                                    {/*/>*/}


                                                </div>
                                            </div>


                                        </ModalContent>
                                        <ModalActions>
                                            <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                                                onClick={() => this.addUserGroupModalToggle()}>Geri
                                            </button>
                                            <input type="button" className="btn btn-success rounded btn-sm px-4 float-right " onClick={this.handleSaveGroup} value="Kaydet" />
                                        </ModalActions>
                                    </Modal>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="tab-content">
                            {/*<div id="list-admins" className="tab-pane active">*/}
                            {/*    <div className="dataTablesCard">*/}
                            {/*        <DataTable*/}
                            {/*            title=""*/}
                            {/*            defaultSortField="first_name"*/}
                            {/*            defaultSortAsc={true}*/}
                            {/*            customStyles={customMediaTableStyles}*/}
                            {/*            noHeader={true}*/}
                            {/*            selectableRows={false}*/}
                            {/*            highlightOnHover={true}*/}
                            {/*            fixedHeader={true}*/}
                            {/*            responsive={true}*/}
                            {/*            expandableRows={false}*/}
                            {/*            progressPending={!this.state.loaded}*/}
                            {/*            expandableRowsComponent={<MediaRowDetail />}*/}
                            {/*            progressComponent={<LoadingView />}*/}
                            {/*            noDataComponent={<EmptyView />}*/}
                            {/*            columns={userTableColumns}*/}
                            {/*            data={this.state.adminList}*/}
                            {/*            actions={null}*/}
                            {/*            contextActions={null}*/}
                            {/*            pagination={true}*/}
                            {/*            paginationComponentOptions={*/}
                            {/*                { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div id="list-users" className="tab-pane active">
                                <div className="dataTablesCard">
                                    <DataTable
                                        title=""
                                        defaultSortField="first_name"
                                        defaultSortAsc={true}
                                        customStyles={customMediaTableStyles}
                                        noHeader={true}
                                        selectableRows={false}
                                        highlightOnHover={true}
                                        fixedHeader={true}
                                        responsive={true}
                                        expandableRows={false}
                                        progressPending={!this.state.loaded}
                                        expandableRowsComponent={<MediaRowDetail />}
                                        progressComponent={<LoadingView />}
                                        noDataComponent={<EmptyView />}
                                        columns={userTableColumns}
                                        data={this.state.userList}
                                        pagination={true}
                                        paginationComponentOptions={
                                            { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }
                                        }
                                    />
                                </div>
                            </div>

                            <div id="list-groups" className="tab-pane">
                                <div className="dataTablesCard">
                                    <DataTable
                                        title=""
                                        defaultSortField="first_name"
                                        defaultSortAsc={true}
                                        customStyles={customMediaTableStyles}
                                        noHeader={true}
                                        selectableRows={false}
                                        highlightOnHover={true}
                                        fixedHeader={true}
                                        responsive={true}
                                        expandableRows={false}
                                        progressPending={!this.state.loaded}
                                        expandableRowsComponent={<MediaRowDetail />}
                                        progressComponent={<LoadingView />}
                                        noDataComponent={<EmptyView />}
                                        columns={userGroupsTableColumns}
                                        data={this.state.userGroups}
                                        pagination={true}
                                        paginationComponentOptions={
                                            { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Users
