// Pdf export için kullanılan component.
// DataTables kütüphanesinde tablo oluşturup pdf'i bu tabloya göre düzenliyoruz.


import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ProgressBarLine } from "react-progressbar-line";
import 'datatables.net-dt/css/jquery.dataTables.css'

import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import DataTable from "react-data-table-component";
import moment from "moment";

import duyuroomIcon from '../../assets/images/duyuroom-erkek-logo.png'
import duyuroomIcon2 from '../../assets/images/Allianz_Logo256x64.png'

// CDN ile import edilen pdfMake kütüphanesini cdn importunu kaldırdıktan sonra 
// Bu şekilde import edip manipüle edersek font hatasından kurtuluyoruz ve
// DataTables PDF çalışır hale geliyor.
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs


const $ = require('jquery');
$.DataTable = require('datatables.net-dt');
const jzip = require('jzip');


const columns = [
    {
        title: 'Kişi Adı',
        data: 'user'
    },
    {
        title: 'Mecra Adı',
        data: 'type'
    },
    {
        title: 'Gönderi Başlığı',
        data: 'title'
    },
    {
        title: 'Gönderen',
        data: 'sender'
    },
    {
        title: 'İçerik Yöneticisi',
        data: 'announcementCreator'
    },
    {
        title: 'Yayınlanma Tarihi',
        data: 'announcementDate',
        type: 'datetime',
        displayFormat: 'DD.MM.YYYY HH:mm:ss'
    },
    {
        title: 'Görüntülenme Tarihi',
        data: 'duyuruLogUserSeenDate',
        type: 'datetime',
        displayFormat: 'DD.MM.YYYY HH:mm:ss'
    },
    {
        title: 'Görüntülenme Durumu',
        data: 'duyuruLogUserSeenStatus'
    },
    {
        title: 'Cihaz Tipi',
        data: 'deviceType'
    },
    {
        title: 'Öteleme Adedi',
        data: 'duyuruUserSnoozedCount'
    },
];

class UserReportsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        }

        this.tableAPI = null

    }


    componentDidMount() {
        this.setState({ data: this.props.data })
        window.JSZip = jzip

        // const companyLogo = duyuroomIcon


        const generalInfo = this.props.generalInfo
        const startDate = this.props.linkData.startDate
        const endDate = this.props.linkData.endDate

        this.tableAPI = $(this.refs.dataTable).DataTable({
            dom: '<"data-table-wrapper"t> Bfrtip',
            searching: false,
            ordering: false,
            paging: false,
            info: false,
            data: this.props.data,
            columns,
            columnDefs: [{
                targets: [5, 6], render: function (data) {
                    if (data) {
                        return moment(data).format('DD.MM.YYYY HH:mm:ss');
                    }
                    return ''
                }
            }],
            buttons: [
                {
                    extend: 'excel',
                    text: 'Excel',
                    fileName: "data.xlsx",
                    title: "Kullanıcı Etkinlik Raporu",
                    className: 'btn btn-primary btn-sm ml-4'
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    fileName: "rapor.pdf",
                    title: "Kullanıcı Etkinlik Raporu",
                    messageTop: null,
                    messageBottom: null,
                    orientation: 'landscape',
                    className: 'btn btn-primary btn-sm',
                    customize: function (doc) {
                        doc.content.splice(1, 0, {
                            columns: [{
                                margin: [0, 0, 0, 12],
                                alignment: 'left',
                                image: duyuroomIcon2,
                                fit: [64, 64]
                            }, {
                                margin: [0, 0, 0, 0],
                                alignment: 'right',
                                image: duyuroomIcon,
                            },]
                        });
                        doc.content.splice(2, 0, {
                            text: [{
                                text: 'Rapor Adı: ',
                                bold: true,
                                fontSize: 11
                            }, {
                                text: 'Kullanıcı Etkinlik Raporu \n',
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Raporlama Tarihi: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${moment().format('DD.MM.YYYY')} \n`,
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Rapor Tarihi Aralığı: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${moment(startDate).format('DD.MM.YYYY')} / ${moment(endDate).format('DD.MM.YYYY')} \n`,
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Toplam Gönderi Sayısı: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${generalInfo.totalAnnouncementCount} \n`,
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Görüntülenen Gönderi Sayısı: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${generalInfo.totalOpened} \n`,
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Görüntülenmeyen Gönderi Sayısı: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${generalInfo.totalNonOpened} \n`,
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Görüntüleme Performansı: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${generalInfo.totalOpenedRate} \n`,
                                bold: false,
                                fontSize: 11
                            }
                            ],
                            margin: [0, 0, 0, 12],
                            alignment: 'left'
                        });
                    }
                },
                {
                    extend: 'csv',
                    text: 'CSV',
                    fileName: "rapor.csv",
                    title: "Kullanıcı Etkinlik Raporu",
                    className: 'btn btn-primary btn-sm'
                }
            ]
        });

        // $(this.refs.reportsTable).DataTable({
        //     dom: '',
        //     ordering: false,
        //     pageSize: 10,
        // });

    }

    componentWillUnmount() {
        $('.report-table')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.tableAPI.clear();
        this.tableAPI.rows.add(this.props.data);
        this.tableAPI.draw();
    }

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if (nextProps.data.length !== this.props.data.length) {
    //         this.reloadTableData(nextProps.data)
    //         return true
    //     }
    //     return false
    // }
    //
    // reloadTableData = (data) => {
    //     const dtable = $('.data-table-wrapper')
    //         .find('table')
    //         .DataTable();
    //     dtable.clear();
    //     dtable.rows.add(data);
    //     dtable.draw();
    //     alert('BURDAYIZ')
    //     let table = $('reportsTable').DataTable();
    //     table.draw();
    // }


    render() {
        return (
            <div>
                <table hidden={true} ref="dataTable" />
                {/*<table hidden={true} className="table table-striped report-table" ref="reportsTable" id='reportsTable'>*/}
                {/*    <thead>*/}
                {/*    <tr>*/}
                {/*        <th>Mecra</th>*/}
                {/*        <th>Klasör</th>*/}
                {/*        <th>İçerik Yöneticisi</th>*/}
                {/*        <th className="text-center">Okunma/Ulaşma</th>*/}
                {/*        <th>Gruplar</th>*/}
                {/*    </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody>*/}
                {/*    {this.props.data && this.props.data.map(row => {*/}
                {/*        return (*/}
                {/*            <tr>*/}
                {/*                <td>*/}
                {/*                    <Link to={{*/}
                {/*                        pathname: '/report_detail/' + row.id, state: {*/}
                {/*                            content: this.props.linkData.content,*/}
                {/*                            startDate: this.props.linkData.startDate,*/}
                {/*                            endDate: this.props.linkData.endDate*/}
                {/*                        }*/}
                {/*                    }}>*/}
                {/*                        <img className="float-left mr-2 mt-1"*/}
                {/*                             src={`/images/icons/${row.type}.png`} width={20}*/}
                {/*                             height={20}/>*/}
                {/*                        <div*/}
                {/*                            className="float-left">{row?.title}<br/><small*/}
                {/*                            className="text-muted">{row.sender}</small></div>*/}
                {/*                    </Link>*/}
                {/*                </td>*/}
                {/*                <td>{row.folder}</td>*/}
                {/*                <td>{row.createdby}</td>*/}
                {/*                <td className="text-center">*/}
                {/*                    {row.duyuruLogOpenedCount}/{row.duyuruLogDeliveredCount}*/}
                {/*                    <br/>*/}
                {/*                    <ProgressBarLine*/}
                {/*                        value={row.openRate}*/}
                {/*                        min={0}*/}
                {/*                        max={100}*/}
                {/*                        trailWidth={1}*/}
                {/*                        strokeWidth={3}*/}
                {/*                        styles={{*/}
                {/*                            width: 100,*/}
                {/*                            path: {*/}
                {/*                                stroke: '#3b4182'*/}
                {/*                            },*/}
                {/*                            trail: {*/}
                {/*                                stroke: '#646aa5'*/}
                {/*                            },*/}
                {/*                            text: {*/}
                {/*                                fill: '#404040',*/}
                {/*                                textAlign: 'center',*/}
                {/*                                fontSize: '12px'*/}
                {/*                            }*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                </td>*/}
                {/*                <td style={{fontSize: 13}}>{row.groups.split(",").map(g => <span*/}
                {/*                    className="badge badge-sm light badge-secondary mr-1 mt-2">{g}</span>)}</td>*/}
                {/*            </tr>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*    </tbody>*/}
                {/*</table>*/}
            </div>
        );
    }
}

export default UserReportsTable;