import React from 'react';
import { renderToString } from 'react-dom/server';
import PubSub from "pubsub-js";
import { Button } from "semantic-ui-react";
import PreviewWindow from "../../components/common/PreviewWindow";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewFrame from "../../components/common/PreviewFrame";
import CanvasManager from "../../tools/CanvasManager";
import {isoValues, setPageTitle} from "../../helpers/LayoutHelper";
import QuizBuilder from "../../components/quiz/QuizBuilder";
import Swal from "sweetalert2";
import {PreviewCss} from "../../helpers/PreviewCssHelper";

const CM = new CanvasManager();
let subTokens = [];

class QuizDesignPage extends React.Component {
    mounted = false;

    state = {
        mode: 'design-content-1',
        content1: {},
        content2: {},
        notify: {},
        model: null,
        questions: []
    };

    constructor(props) {
        super(props);

        this.state={
            previewPage:0
        }

        this.editorContent1 = null;
        this.editorContent2 = null;
        this.editorNotify = null;
    }

    componentDidMount = () => {
        if (this.mounted) return;
        this.mounted = true;

        if (this.props.location.state && this.props.location.state.type === "QUIZ") {
            setPageTitle(this.props.location.state.pageTitle);
            PubSub.publish("CANVAS", { showPanel: true, model: this.props.location.state });
            this.loadCanvas();
        } else {
            if (this.props.history) {
                this.props.history.push('/dashboard');
            }
        }

        PubSub.subscribe('canvas_popup.save', this.onSave.bind(this));
        PubSub.subscribe('canvas_popup.update', this.onUpdate.bind(this));
        PubSub.subscribe('ask-template-quiz',this.sendCurrentTemplate)
        PubSub.subscribe('data-classification',this.addISO)
    }

    addISO = (msg,data) => {
        const component = this.editorContent1?.getWrapper().find('#isoFooter');
        if (component?.length !==0){
            component[0].remove();
        }
        const component1 = this.editorContent2?.getWrapper().find('#isoFooter');
        if (component1?.length !==0){
            component1[0].remove();
        }
        const componentText = this.editorContent1?.getWrapper().find('.second-container');
        const componentText1 = this.editorContent2?.getWrapper().find('.second-container');
        if (data === "PUBLIC"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.PUBLIC}</p>`);
            }
            if (componentText1?.length !==0){
                componentText1[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.PUBLIC}</p>`);
            }
        }else if(data === "RESTRICTED"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.RESTRICTED}</p>`);
            }
            if (componentText1?.length !==0){
                componentText1[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.RESTRICTED}</p>`);
            }

        }else if(data === "CONFIDENTIAL"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.CONFIDENTIAL}</p>`);
            }
            if (componentText1?.length !==0){
                componentText1[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.CONFIDENTIAL}</p>`);
            }

        }else if(data === "TOPSECRET"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.TOPSECRET}</p>`);
            }
            if (componentText1?.length !==0){
                componentText1[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.TOPSECRET}</p>`);
            }

        }

    }

    getPosition = (string, subString, index) => {
        return string.split(subString, index).join(subString).length;
    }

    loadCanvas() {
        CM.ElementWaiting(['canvasTools'], () => {
            this.editorContent1 = CM.CreateCanvas('design-content-1');
            this.editorContent2 = CM.CreateCanvas('design-content-2');
            this.editorNotify = CM.CreateCanvas('design-notify');

            CM.AppendDefaultHeadLinks();

            this.editorContent1.setComponents(' <div class="ui container main-container">\n' +
                '    <div class="ui raised very padded text container segment second-container" style="background-color: #f8f8f8bb;">\n' +
                '      <div class="ui middle aligned two column centered padded grid">\n' +
                '        <div class="row">\n' +
                '          <div class="column"><img class="ui fluid image second-image" style="margin:auto"\n' +
                '              src="https://dummyimage.com/700x400/000000/ffffff.jpg&text=G%C3%B6rsel+%C4%B0%C3%A7erik"></div>\n' +
                '        </div>\n' +
                '      </div>\n' +
                '      <div class="ui divider"></div>\n' +
                '      <div class="ui text">\n' +
                '        <h2 class="first">Ön Yazı</h2>\n' +
                '        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                '          magna aliqua. Luctus accumsan tortor posuere ac ut consequat semper viverra. In hac habitasse platea dictumst.\n' +
                '          Etiam tempor orci eu lobortis elementum nibh. Est ultricies integer quis auctor elit sed vulputate. Aliquet\n' +
                '          eget sit amet tellus cras adipiscing enim. Nunc mi ipsum faucibus vitae aliquet nec ullamcorper sit. Lectus\n' +
                '          urna duis convallis convallis tellus. Lectus urna duis convallis convallis tellus id. Fusce ut placerat orci\n' +
                '          nulla pellentesque dignissim enim. Aliquet lectus proin nibh nisl condimentum id venenatis a. Ipsum\n' +
                '          suspendisse ultrices gravida dictum fusce ut placerat. Consectetur lorem donec massa sapien faucibus et\n' +
                '          molestie. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Massa eget egestas purus viverra\n' +
                '          accumsan in nisl nisi. Maecenas volutpat blandit aliquam etiam. Mauris sit amet massa vitae tortor condimentum\n' +
                '          lacinia quis vel. At volutpat diam ut venenatis tellus in metus vulputate.</p>\n' +
                '      </div>\n' +
                '    </div>\n' +
                '  </div>');
            this.editorContent1.setStyle('.isoFooterCss{color: #010232;font-size: 12px;})')
            this.editorContent2.setStyle('.isoFooterCss{color: #010232;font-size: 12px;})')
            this.editorContent2.setComponents(' <div class="ui container main-container">\n' +
                '    <div class="ui raised very padded text container segment second-container" style="background-color: #f8f8f8bb;">\n' +
                '      <div class="ui middle aligned two column centered padded grid">\n' +
                '        <div class="row">\n' +
                '          <div class="column"><img class="ui fluid image second-image" style="margin:auto"\n' +
                '              src="https://dummyimage.com/700x400/000000/ffffff.jpg&text=G%C3%B6rsel+%C4%B0%C3%A7erik"></div>\n' +
                '        </div>\n' +
                '      </div>\n' +
                '      <div class="ui divider"></div>\n' +
                '      <div class="ui text">\n' +
                '        <h2 class="first">Kapanış Yazısı</h2>\n' +
                '        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
                '          magna aliqua. Luctus accumsan tortor posuere ac ut consequat semper viverra. In hac habitasse platea dictumst.\n' +
                '          Etiam tempor orci eu lobortis elementum nibh. Est ultricies integer quis auctor elit sed vulputate. Aliquet\n' +
                '          eget sit amet tellus cras adipiscing enim. Nunc mi ipsum faucibus vitae aliquet nec ullamcorper sit. Lectus\n' +
                '          urna duis convallis convallis tellus. Lectus urna duis convallis convallis tellus id. Fusce ut placerat orci\n' +
                '          nulla pellentesque dignissim enim. Aliquet lectus proin nibh nisl condimentum id venenatis a. Ipsum\n' +
                '          suspendisse ultrices gravida dictum fusce ut placerat. Consectetur lorem donec massa sapien faucibus et\n' +
                '          molestie. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Massa eget egestas purus viverra\n' +
                '          accumsan in nisl nisi. Maecenas volutpat blandit aliquam etiam. Mauris sit amet massa vitae tortor condimentum\n' +
                '          lacinia quis vel. At volutpat diam ut venenatis tellus in metus vulputate.</p>\n' +
                '      </div>\n' +
                '    </div>\n' +
                '  </div>');
            this.editorNotify.setComponents('<div class="ui container main-notify-container first-layer">\n' +
                '            <img class="ui wireframe image fluid image-style" src="https://dummyimage.com/320x86/ba84ba/fff.jpg&text=Görsel" />\n' +
                '            <div class="ui basic segment height180">\n' +
                '                <p class="headerText">Bildirim Başlığı</p>\n' +
                '                <p class="infoText">Kısa bildirim içeriğini buraya yazabilirsiniz.</p>\n' +
                '            </div>\n' +
                '            <div class="emptyDiv"></div>\n' +
                '            <div class="ui middle aligned two column centered padded grid footerNotify">\n' +
                '                <div class="ui divider row"></div>\n' +
                '                <div class="row footerDiv">\n' +
                '                    <div class="column middle aligned"><button class="ui button button-morcivert" onclick="handleClick(0)">Ötele</button></div>\n' +
                '                    <div class="column middle aligned"><button class="ui button button-morcivert" onclick="handleClick(1)">Göster</button></div>\n' +
                '                </div>\n' +
                '            </div>\n' +
                '        </div>')
            this.editorNotify.setStyle('.image-style {\n' +
                '                margin: auto;\n' +
                '                max-width: 96%;\n' +
                '            }\n' +
                '            .first-layer {\n' +
                '                background-color: #fff;\n' +
                '                margin: 10px auto auto;\n' +
                '                border: 1px solid rgba(34, 36, 38, 0.15);\n' +
                '                border-radius: 10px;\n' +
                '                width: 300px !important;\n' +
                '                overflow:auto;\n' +
                '            }\n' +
                '            .height180 {\n' +
                '                height: auto;\n' +
                '            }\n' +
                '            .button-morcivert{\n' +
                '                background-color: #3b4182 !important;\n' +
                '                color: white !important;\n' +
                '                font-size: 10px !important;\n' +
                '            }\n' +
                '            .footerNotify{\n' +
                '                position:fixed;\n' +
                '                width: 310px;\n' +
                '                bottom:1px;\n' +
                '                background-color: white;\n' +
                '                z-index: 100;\n' +
                '                margin-top: 1px;\n' +
                '            }\n' +
                '            .emptyDiv{\n' +
                '                height: 58px;\n' +
                '            }\n' +
                '            .footerDiv{\n' +
                '                margin-top: -46px;\n' +
                '                height: 46px;\n' +
                '            }\n' +
                '            .headerText {\n' +
                '                font-size: 16px;\n' +
                '                font-weight: 700;\n' +
                '            }\n' +
                '            .infoText {\n' +
                '                font-size: 12px;\n' +
                '                word-wrap: break-word;\n' +
                '                width:280px;\n' +
                '            }')

            let text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
            let textStart = this.getPosition(text, '>', 1)
            let textEnd = this.getPosition(text, '<', 2)
            let finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
            let finalTextLength = finalText.length

            this.editorNotify.on('change:editing', (em, editing) => {
                setTimeout(()=>{
                    if (editing) {
                        const cmp = this.editorNotify.getSelected();
                        const el = cmp && cmp.getEl();
                        if (el && el.innerText === 'Kısa bildirim içeriğini buraya yazabilirsiniz.') //placeholderın içini siliyor
                            el.innerText = '';

                        text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
                        textStart = this.getPosition(text, '>', 1)
                        textEnd = this.getPosition(text, '<', 2)
                        finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
                        finalTextLength = finalText.length

                        if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                            this.editorNotify?.getWrapper().find('.infoLengthText')[0].remove()
                            this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength+"/300"}</p>`)
                        }
                    }
                },500)

            });


            this.editorNotify.on('component:update', model => {
                text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML() // seçili classın html bilgisi dönüyor
                textStart = this.getPosition(text, '>', 1)
                textEnd = this.getPosition(text, '<', 2)
                finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
                finalTextLength = finalText.length

                if (finalTextLength>=300){
                    Swal.fire({
                        icon: 'warning',
                        text: '300 karakter ile sınırlıdır.',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,

                    });
                    text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
                    textStart = this.getPosition(text, '>', 1)
                    textEnd = this.getPosition(text, '<', 2)
                    finalText = text.substr(Number(textStart)+1,299)

                    if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                        this.editorNotify?.getWrapper().find('.infoText')[0]?.remove()
                        this.editorNotify?.getWrapper().find('.infoText')[0]?.replaceWith(`<p class="infoText">${finalText}</p>`)
                    }
                }

                if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                    this.editorNotify?.getWrapper().find('.infoLengthText')[0].remove()
                    this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength+"/300"}</p>`)
                }
            })

            if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                this.editorNotify?.getWrapper().find('.headerText')[0].remove()
                this.editorNotify?.getWrapper().find('.infoText')[0].remove()
                this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="headerText" data-gjs-removable="false">${this.props.location.state.title}</p>`,{ at: 0 })
                this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoText" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-droppable="false">Kısa bildirim içeriğini buraya yazabilirsiniz.</p>`,{ at: 1 })
                this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength+"/300"}</p>`)
            }

            if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                this.editorNotify?.getWrapper().find('.headerText')[0].remove()
                this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="headerText">${this.props.location.state.title}</p>`,{ at: 0 })
            }
            this.setActiveCanvas('design-content-1');
        });
    }

    onRemount() {
        subTokens.forEach(token => {
            PubSub.unsubscribe(token)
        });

        subTokens = [];
    }

    onSave = (event, data) => {
        this.state.model = data;
        if (this.editorContent) {
            this.state.content = {
                css: this.editorContent.getCss(),
                html: this.editorContent.getHtml()
            };
        }

        if (this.editorNotify) {
            this.state.notify = {
                css: this.editorNotify.getCss(),
                html: this.editorNotify.getHtml()
            };
        }

        console.log('Design State:');
        console.log(JSON.stringify(this.state));
    }

    onUpdate = (event, data) => {
        this.state.model = data;
        console.log('Design Update:');
        console.log(JSON.stringify(this.state));
    }

    componentWillUnmount() {
        if (!this.mounted) return;
        this.mounted = false;

        PubSub.unsubscribe('canvas_popup.save');
        PubSub.unsubscribe('canvas_popup.update');
        PubSub.unsubscribe('ask-template-quiz')
        PubSub.unsubscribe('data-classification')

        PubSub.publish("CANVAS", { showPanel: false });

        if (this.editorContent1) {
            this.editorContent1.destroy();
        }
        if (this.editorContent2) {
            this.editorContent2.destroy();
        }
        if (this.editorNotify) {
            this.editorNotify.destroy();
        }
    }

    setActiveCanvas = (mode) => {
        if (this.state.mode !== mode) {
            this.setState({ mode,previewPage:0 }, () => {
                if(mode === 'preview'){
                    if(document.getElementsByClassName("infoLengthText")[0] && document.getElementsByClassName("infoLengthText")[0].style){
                        document.getElementsByClassName("infoLengthText")[0].style.visibility = 'hidden'
                    }
                }
            });
        }

        if (this.editorNotify && this.editorNotify.hideWrappers)
            this.editorNotify.hideWrappers();
        if (this.editorContent1 && this.editorContent1.hideWrappers)
            this.editorContent1.hideWrappers();
        if (this.editorContent2 && this.editorContent2.hideWrappers)
            this.editorContent2.hideWrappers();

        switch (mode) {
            case 'design-content-1':
                if (this.editorContent1)
                    this.editorContent1.showWrappers();
                break;
            case 'design-content-2':
                if (this.editorContent2)
                    this.editorContent2.showWrappers();
                break;
            case 'design-notify':
                if (this.editorNotify)
                    this.editorNotify.showWrappers();
                break;
            case 'preview':

            case 'quiz':

            default:
                break;
        }
    }


    sendCurrentTemplate = () => {
        if (this.editorNotify?.getWrapper().find('.headerText').length !== 0) {
            this.editorNotify?.getWrapper().find('.infoLengthText')[0].remove()
        }
        let tempArr = []
        this.state?.questions?.map((data) => {
            if(data.type === null || data.text === "" || (data.answers.length === 0 && data.cols.length === 0)) {
                tempArr.push(data)
            }
        })

        if (this.state.questions === undefined) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen Soru giriniz.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }
        if (tempArr.length !== 0) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen Soru/Soruların detaylarını giriniz.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }
        let tempQuestions = this.state.questions.map((data,index)=>{
            if(data.type==="Doğru/Yanlış Sorusu"){
                data.type="YESNO"
                return data
            }else if(data.type==="Tek Seçimli Soru"){
                data.type="SINGLE"
                return data
            }else if(data.type==="Çok Seçimli Soru"){
                data.type="MULTIPLE"
                return data
            }
        })

        const templates = {
            beginNote: "<style>" + this.editorContent1?.getCss() + "</style>" + this.editorContent1?.getHtml(),
            endNote: "<style>" + this.editorContent2?.getCss() + "</style>" + this.editorContent2?.getHtml(),
            notify : "<style>" + this.editorNotify?.getCss() + "</style>" + this.editorNotify?.getHtml(),
            questions: tempQuestions
        }
        PubSub.publish('current-template',templates)
    }

    surveyToHtml = () => {
        return renderToString(<QuizBuilder data={this.state.questions} show={false} mode="preview"/>)
    }

    render() {
        return (
            <div className="canvasRoot" style={{background: '#ffffff'}}>
                <div className="design-change" style={{zIndex:4}}>
                    <Button.Group>
                        <Button toggle onClick={() => this.setActiveCanvas('design-content-1')}>Ön Yazı</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('quiz')}>Sorular</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('design-content-2')}>Kapanış Yazısı</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('design-notify')}>Bildirim</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('preview')}>Önizleme</Button>
                    </Button.Group>
                </div>
                <div id="design-content-1" className="canvas-content">

                </div>

                <div id="design-content-2" className="canvas-content">

                </div>

                <div id="design-notify" className="canvas-content">

                </div>

                <div id="quiz" style={{background: '#ffffff', display: this.state.mode === 'quiz' ? 'block' : 'none' }}>
                    <QuizBuilder
                        mode="edit"
                        onChange={ (questions) => this.setState({questions}) }
                    />
                </div>

                <div id="preview" style={{ marginLeft: 'auto', marginRight: 'auto', width: 900, paddingLeft: 30, display: this.state.mode === 'preview' ? 'block' : 'none' }}>
                    <PreviewDevice
                        deviceType="DESKTOP"
                        device="macbookpro"
                        scale="0.8"
                        wallpaper="url(/images/macos-wallpaper.png)"
                        notification={{
                            show: this.state.previewPage===0,
                            title: 'Mesaj başlığını buraya yazınız',
                            subtitle: 'Yazılı ve görsel mesajların e-posta trafiğine takılmadan çalışanlara ulaşmasını sağlar.',
                            body: 'Pazarlama',
                            snooze: 0,
                            top: 0,
                            content: this.editorNotify ? `<style>html,body{padding:0; margin:0};${this.editorNotify.getCss()}${PreviewCss.css}</style>${this.editorNotify.getHtml()}` : null,
                        }}>
                        <PreviewWindow
                            bgcolor="#ffffff"
                            mediaType="POPUP"
                            title='The title of message'
                            subtitle='The sub title of message'>
                            <div style={{height:this.state.previewPage===2?"100%":"510px"}}>
                            <button style={{position:"absolute",right:"20px",bottom:"3px",display:this.state.previewPage===2?"none":"block"}} className="btn btn-primary btn-sm px-4" onClick={()=>this.setState({previewPage:this.state.previewPage+1})}>İleri</button>
                            {this.state.previewPage === 0 ?
                                <PreviewFrame
                                    content={`<style>html,body{padding:5; margin:0}</style>${this.editorContent1?.getHtml()}`}
                                />

                                : this.state.previewPage === 1 ?
                                    <PreviewFrame
                                        content={`<style>html,body{padding:5; margin:0}</style>${this.surveyToHtml()}`}
                                    />
                                    : this.state.previewPage === 2 ?
                                        <PreviewFrame
                                            content={`<style>html,body{padding:5; margin:0}</style>${this.editorContent2.getHtml()}`}
                                        />
                                        : null
                            }
                            </div>


                        </PreviewWindow>
                    </PreviewDevice>

                </div>
            </div>
        )
    }
}

export default QuizDesignPage;
