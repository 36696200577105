import React, {useState} from "react";
import Question from "./Question";
import ListController from "./ListController";
import styled from "styled-components";
import EmojiInput from "../emoji/EmojiInput";
import {Editor} from "@tinymce/tinymce-react";
import settings from "../../config/settings.json";
import {protectedApi} from "../../services/axios";


// https://github.com/jpuri/react-draft-wysiwyg

export default function QuestionForm({ question, setQuestion ,total }) {
    const [correctArray, setCorrectArray] = useState([]);

    let listController = new ListController(question?.options, setOptions);

    function handleChangeText(e) {
        setQuestion(question.merge({ text: e })); // e.target.value
    }

    function handleChangeType(e) {
        setQuestion(question.merge({ type: e.target.value, options: e.target.value === Question.TYPES.YESNO ? ["Doğru", "Yanlış"] : question.options }));
        let tempArray = new Array(question.options.length).fill(false)
        setCorrectArray(tempArray)
    }

    function setOptions(options) {
        setQuestion(question.merge({ options }));
    }

    function setYesNo(index,value){
        let correctArray = new Array(question.options.length).fill(false)
        correctArray[index]=value
        setCorrectArray(correctArray)
        let answers = []
        for (let i = 0;i<question.options.length;i++){
            let temp = {option:question.options[i],isCorrect:correctArray[i]}
            answers.push(temp)
        }
        setQuestion(question.merge({answers:answers}))
        console.log(answers)
    }

    function setCorrectSingle(index,value){
        let correctArray = new Array(question.options.length).fill(false)
        correctArray[index]=value
        setCorrectArray(correctArray)
        let answers = []
        for (let i = 0;i<question.options.length;i++){
            let temp = {option:question.options[i],isCorrect:correctArray[i]}
            answers.push(temp)
        }
        setQuestion(question.merge({answers:answers}))
        console.log(answers)
    }
    function setCorrectMultiple(index,value){
        let tempArray = correctArray
        tempArray[index]=value
        setCorrectArray(tempArray)
        let answers = []
        for (let i = 0;i<question.options.length;i++){
            let temp = {option:question.options[i],isCorrect:correctArray[i]}
            answers.push(temp)
        }
        setQuestion(question.merge({answers:answers}))
        console.log(answers)
    }

    function setHasOther(value){
        setQuestion(question.merge({hasOther:value}))
    }

    function getEditor (type, placeholder,index,val) {

        return(
            <section style={{ width: "80%", display: "table",marginTop:"5px",float:type==='question' || question.type !== Question.TYPES.YESNO?"right":"left"}} >
                <div style={{display: "table-cell", width: "100%", verticalAlign:"middle"}}>
                    <Editor
                        apiKey="4wp2oams7a8jp489lzu8ywwx16edfuxlxrhjchhl7vsbafut"
                        value={val}
                        style={{border:"1px solid"}}
                        init={{
                            selector: 'textarea#myTextArea',
                            height: 120,
                            menubar: false,
                            inline: true,
                            toolbar_mode: "sliding",
                            language: "tr",
                            language_url: '/js/tr.js',
                            placeholder: placeholder,
                            automatic_uploads: true,
                            image_uploadtab: true,
                            image_caption: false,
                            image_advtab: false,
                            image_description: false,
                            image_dimensions: true,
                            content_css : "/css/editor.css",
                            file_browser_callback_types: 'image media',
                            contextmenu: false,
                            images_upload_url: settings.apiBase + 'announcement/fileuploads',
                            images_upload_base_path: settings.apiBase,
                            images_upload_credentials: false,
                            browser_spellcheck: true,
                            force_br_newlines: false,
                            force_p_newlines: false,
                            forced_root_block: '',
                            images_upload_handler: function (blobInfo, success, failure, progress) {
                                let data = new FormData();
                                let config = {header: {'Content-Type': 'multipart/form-data'}}

                                data.append('file', blobInfo.blob(), blobInfo.filename());

                                protectedApi.post('/announcement/fileuploads', data, config).then(response => {
                                    success(settings.apiBaseClear + response.data.file);
                                }).catch(error => {
                                    failure(error);
                                })
                            },
                            plugins: [
                                'emoticons'
                            ],
                            toolbar:
                                'emoticons'
                        }}
                        onEditorChange={(content, editor) => {
                            if (type==='option'){
                                listController.set(index, content)
                            }else if (type==='question'){
                                setQuestion(question.merge({ text: content }))
                            }

                            // hack the editor to enable spellcheck
                            editor.bodyElement.spellcheck = true;
                        }}

                    />
                </div>

            </section>
        )
    }

    return (
        <div className="ui form">
            <select
                id="question-type"
                value={question.type !== null ? question.type : null}
                style={{marginTop: 5}}
                onChange={handleChangeType}>
                <option key="typeOfQuestion" hidden value={null}>Soru Tipi Seçiniz</option>
                {Object.values(Question.TYPES).map(type => {
                    return(
                    <option key={type} value={type}>
                        {type}
                    </option>
                )})}
            </select>
            {question.type!==null&&
            <div style={{display:"flex"}}>
                {question.type === Question.TYPES.YESNO?<span style={{marginTop:"15px",textAlign:"center",display:"flex",margin:"auto"}}>Doğru Cevap</span>:null}
                {getEditor('question', 'Sorunuzu bu alana yazınız.',"",question.text)}

            </div>}




            {question.type === Question.TYPES.YESNO &&
                <>
                    <div>
                        <br/>
                        <div style={{display:"flex"}}>
                            <input style={{marginTop:"15px",textAlign:"center",display:"flex",margin:"auto"}} checked={question?.answers[0]?.isCorrect} onChange={(event)=>setYesNo(0,event.target.checked)} type="checkbox" name="deneme"/>
                            {getEditor('option', 'Seçenek belirtiniz.',0,question?.options[0])}
                            {/*<InputEmoji*/}
                            {/*    borderRadius={0}*/}
                            {/*    value={question.options[0]}*/}
                            {/*    onChange={e => listController.set(0, e)}*/}
                            {/*    placeholder="Seçenek belirtiniz."*/}
                            {/*/>*/}
                            {/*<EmojiInput*/}
                            {/*    style={{width: 200, background: '#FFE8BB', color: '#000'}}*/}
                            {/*    input={true}*/}
                            {/*    type="text"*/}
                            {/*    placeholder="Seçenek belirtiniz."*/}
                            {/*    value={question.options[0]}*/}
                            {/*    onChange={e => listController.set(0, e)} // e.target.value*/}
                            {/*/>*/}
                        </div>


                        <br/>


                        <div style={{display:"flex"}}>
                            <input style={{marginTop:"15px",textAlign:"center",display:"flex",margin:"auto"}} checked={question?.answers[1]?.isCorrect} onChange={(event)=>setYesNo(1,event.target.checked)} type="checkbox" name="deneme"/>
                            {getEditor('option', 'Seçenek belirtiniz.',1,question?.options[1])}
                            {/*<InputEmoji*/}
                            {/*    borderRadius={0}*/}
                            {/*    value={question.options[1]}*/}
                            {/*    onChange={e => listController.set(1, e)}*/}
                            {/*    placeholder="Seçenek belirtiniz."*/}
                            {/*/>*/}
                            {/*<EmojiInput*/}
                            {/*    style={{width: 200, background: '#FFE8BB', color: '#000'}}*/}
                            {/*    input={true}*/}
                            {/*    type="text"*/}
                            {/*    placeholder="Seçenek belirtiniz."*/}
                            {/*    value={question.options[1]}*/}
                            {/*    onChange={e => listController.set(1, e)} // e.target.value*/}
                            {/*/>*/}



                        </div>

                    </div>
                    <p style={{clear: 'both'}}/>
                </>
            }

            {question.hasOptions && question.type !== Question.TYPES.YESNO && (
                <div style={{marginTop: 10}}>

                    {question.type !== Question.TYPES.YESNO &&
                    <p style={{float:"left",marginLeft:"12px"}}>
                        <span >Doğru Cevap</span>
                        <button style={{marginLeft:"30px"}} className="btn btn-sm btn-light" onClick={() => listController.add("")}>+ Seçenek Ekle</button>
                        <span style={{marginLeft:"30px"}}>Açık Uçlu Seçenek Ekle <input type="checkbox" onChange={(event)=>setHasOther(event.target.checked)} style={{marginTop:"12px",marginLeft:"12px"}}/></span>
                    </p>
                    }
                    <br/><br/><br/>
                    {question.options.map((option, i) => (
                        <Option key={i} >
                            {question.type==="Tek Seçimli Soru"?
                                <input type="checkbox" checked={question?.answers[i]?.isCorrect} onChange={(event)=>setCorrectSingle(i,event.target.checked)} style={{marginTop:"12px",marginLeft:"47px"}}/>
                                :
                                <input   type="checkbox" checked={question?.answers[i]?.isCorrect} onChange={(event)=>setCorrectMultiple(i,event.target.checked)} style={{marginTop:"12px",marginLeft:"47px"}}/>

                            }
                            <Buttons >
                                <button className="btn btn-xs" onClick={() => listController.moveUp(i)}>
                                    <i className="fa fa-angle-up" />
                                </button>
                                <button className="btn btn-xs" onClick={() => listController.moveDown(i)}>
                                    <i className="fa fa-angle-down" />
                                </button>
                                <button  className="btn btn-xs" onClick={() => listController.remove(i)}>
                                    <i className="fa fa-trash" />
                                </button>
                            </Buttons>
                            {getEditor('option', 'Seçenek belirtiniz.',i,option)}

                            {/*<EmojiInput*/}
                            {/*    className="w-100"*/}
                            {/*    input={true}*/}
                            {/*    type="text"*/}
                            {/*    placeholder="Seçenek belirtiniz."*/}
                            {/*    name={option}*/}
                            {/*    value={option}*/}
                            {/*    onChange={e => listController.set(i, e)} // e.target.value*/}
                            {/*/>*/}


                        </Option>
                    ))}
                </div>
            )}
        </div>
    );
}

const Option = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const Buttons = styled.div`
  margin-left: 75px;
  display: flex;
  justify-content: flex-end;
`;
