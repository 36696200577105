import React from 'react';
import PubSub from "pubsub-js";
import { Button } from "semantic-ui-react";
import PreviewWindow from "../../components/common/PreviewWindow";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewFrame from "../../components/common/PreviewFrame";
import CanvasManager from "../../tools/CanvasManager";
import * as API from '../../services/api';
import {isoValues, setPageTitle} from "../../helpers/LayoutHelper";
import {PreviewCss} from "../../helpers/PreviewCssHelper";
import Swal from "sweetalert2";

const CM = new CanvasManager();

class NewsletterDesignPage extends React.Component {
    mounted = false;

    state = {
        mode: 'design-content',
        content: {},
        notify: {},
        model: null
    };

    constructor(props) {
        super(props);

        this.editorContent = null;
        this.editorNotify = null;
    }

    componentDidMount = () => {
        if (this.mounted) return;
        this.mounted = true;

        if (this.props.location.state && this.props.location.state.type == "NEWSLETTER") {
            setPageTitle(this.props.location.state.pageTitle);
            PubSub.publish("CANVAS", { showPanel: true, model: this.props.location.state });
            this.loadTemplate();
        } else {
            if (this.props.history) {
                this.props.history.push('/dashboard');
            }
        }

        PubSub.subscribe('canvas_newsletter.save', this.onSave.bind(this));
        PubSub.subscribe('canvas_newsletter.update', this.onUpdate.bind(this));
        PubSub.subscribe('ask-template-newsletter',this.sendCurrentTemplate)
        PubSub.subscribe('data-classification',this.addISO)
    }

    addISO = (msg,data) => {
        const component = this.editorContent?.getWrapper().find('#isoFooter');
        if (component?.length !==0){
            component[0].remove();
        }
        const componentText = this.editorContent?.getWrapper();
        if (data === "PUBLIC"){
            if (componentText?.length !==0){
                componentText.append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.PUBLIC}</p>`);
            }
        }else if(data === "RESTRICTED"){
            if (componentText?.length !==0){
                componentText.append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.RESTRICTED}</p>`);
            }

        }else if(data === "CONFIDENTIAL"){
            if (componentText?.length !==0){
                componentText.append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.CONFIDENTIAL}</p>`);
            }

        }else if(data === "TOPSECRET"){
            if (componentText?.length !==0){
                componentText.append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.TOPSECRET}</p>`);
            }

        }

    }

    getPosition = (string, subString, index) => {
        return string.split(subString, index).join(subString).length;
    }

    loadTemplate() {
        API.getTemplate(this.props.location.state.template).then((response) => {
            if (response) {
                CM.ElementWaiting(['canvasTools'], () => {
                    this.editorContent = CM.CreateCanvas('design-content',response.width,response.height);
                    this.setState({templateWidth:response.width && response.width >0 ?response.width:1024,templateHeight:response.height && response.height >0 ?response.height:800})
                    this.editorNotify = CM.CreateCanvas('design-notify');
                    this.setActiveCanvas('design-content');
                    CM.AppendDefaultHeadLinks();

                    if (response.templateJson.content) {
                        if (response.templateJson.content.html)
                            this.editorContent.setComponents(CM.GetLiquidTemplateContent(response.templateJson.content.html));

                        if (response.templateJson.content.css)
                            this.editorContent.setStyle(response.templateJson.content.css+'.isoFooterCss{color: #010232;font-size: 12px;text-align:center;position:absolute;left:50%;right:25%;bottom:20px;margin-left: -150px;width:300px}');
                    }

                    if (response.templateJson.notify) {
                        if (response.templateJson.notify.html)
                            this.editorNotify.setComponents(response.templateJson.notify.html);

                        if (response.templateJson.notify.css)
                            this.editorNotify.setStyle(response.templateJson.notify.css+'.infoText{word-wrap: break-word; width:280px;}');

                        let text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
                        let textStart = this.getPosition(text, '>', 1)
                        let textEnd = this.getPosition(text, '<', 2)
                        let finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
                        let finalTextLength = finalText.length

                        console.log(this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText)

                        this.editorNotify.on('change:editing', (em, editing) => {
                            setTimeout(()=>{
                                if (editing) {
                                    const cmp = this.editorNotify.getSelected();
                                    const el = cmp && cmp.getEl();
                                    if (el && el.innerText === 'Kısa bildirim içeriğini buraya yazabilirsiniz.') //placeholderın içini siliyor
                                        el.innerText = '';

                                    text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
                                    textStart = this.getPosition(text, '>', 1)
                                    textEnd = this.getPosition(text, '<', 2)
                                    finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
                                    finalTextLength = finalText.length

                                    if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                                        this.editorNotify?.getWrapper().find('.infoLengthText')[0].remove()
                                        this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength+"/300"}</p>`)
                                    }
                                }
                            },500)

                        });


                        this.editorNotify.on('component:update', model => {
                            text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML() // seçili classın html bilgisi dönüyor
                            textStart = this.getPosition(text, '>', 1)
                            textEnd = this.getPosition(text, '<', 2)
                            finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
                            finalTextLength = finalText.length

                            if (finalTextLength>=300){
                                Swal.fire({
                                    icon: 'warning',
                                    text: '300 karakter ile sınırlıdır.',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,

                                });
                                text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
                                textStart = this.getPosition(text, '>', 1)
                                textEnd = this.getPosition(text, '<', 2)
                                finalText = text.substr(Number(textStart)+1,299)

                                if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                                    this.editorNotify?.getWrapper().find('.infoText')[0]?.remove()
                                    this.editorNotify?.getWrapper().find('.infoText')[0]?.replaceWith(`<p class="infoText">${finalText}</p>`)
                                }
                            }

                            if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                                this.editorNotify?.getWrapper().find('.infoLengthText')[0].remove()
                                this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength+"/300"}</p>`)
                            }
                        })

                        if (this.editorNotify?.getWrapper().find('.headerText').length !== 0){
                            this.editorNotify?.getWrapper().find('.headerText')[0].remove()
                            this.editorNotify?.getWrapper().find('.infoText')[0].remove()
                            this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="headerText" data-gjs-removable="false">${this.props.location.state.title}</p>`,{ at: 0 })
                            this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoText" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-droppable="false">Kısa bildirim içeriğini buraya yazabilirsiniz.</p>`,{ at: 1 })
                            this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength+"/300"}</p>`)
                        }
                        // Pop-up dizayn sırasında şablon üzerinde kısıtlamalar yapmak.
                        // ve Şablonu sabitlemek için kullanılacak kod
                        // const updateComp = (component) => {
                        //     console.log('BURADA NE VAR : ', component)
                        //     if (component) {
                        //         let tempArr = component.attributes.type === "image" ? [{ "command": "tui-image-editor", "label": "<svg viewBox=\"0 0 24 24\">\n                    <path d=\"M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75L3 17.25z\">\n                    </path>\n                  </svg>" }] : []
                        //         component.set({
                        //             removable: false,   // False olduğu zaman sağ üstteki menüden silinemiyor.
                        //             editable: true,     // False olduğu zaman editlenemiyor sabit kalıyor.
                        //             selectable: true,   // False olduğu zaman gri menü kullanılamıyor ve Düzen menüsü kapanıyor.
                        //             highlightable: false,
                        //             hoverable: false,   // True olduğu zaman mouse hangi componentin üzerindeyse onun çerçevesini gösteriyor.
                        //             copyable: false,    // False olduğu zaman sağ üstteki menüden kopyalanamıyor.
                        //             draggable: false,   // Draggable droppable movable aynı işlevler. draggable true dropplable false durumunda düzgün çalışmıyor
                        //             droppable: false,   // dropabble true olduğu durumda komponentler hareket ettirilebilir oluyor.
                        //             movable: false,     // false veya true olmasının etkisi çözülemedi ?
                        //             layerable: false,   // İşevi bilinmiyor default değeri true
                        //             resizable: false,   // False olduğu zaman boyutu değiştirilemiyor.
                        //             stylable: true,     // False olduğu zaman Düzen menüsü kapanıyor.
                        //             badgable: true,     // İşevi bilinmiyor default değeri true
                        //             toolbar: tempArr,   // çerçevenin sağ üstünde bulunan menü iconlarını kaldırmak için. iconların işlevlerini kaldırdığımız için görsel olarak kapatıldı.
                        //         });
                        //         component.get('components').each(c => updateComp(c))
                        //     }
                        // }
                        // this.editorContent.getComponents().each(c => updateComp(c))

                    }
                });
            }
        });
    }

    onSave = (event, data) => {

        this.state.model = data;
        if (this.editorContent) {
            this.state.content = {
                css: this.editorContent.getCss(),
                html: this.editorContent.getHtml()
            };
        }

        if (this.editorNotify) {
            this.state.notify = {
                css: this.editorNotify.getCss(),
                html: this.editorNotify.getHtml()
            };
        }

        console.log('Design State:');
        console.log(JSON.stringify(this.state));
    }

    onUpdate = (event, data) => {
        this.state.model = data;
        console.log('Design Update:');
        console.log(JSON.stringify(this.state));
    }

    componentWillUnmount() {
        if (!this.mounted) return;
        this.mounted = false;

        PubSub.unsubscribe('canvas_newsletter.save');
        PubSub.unsubscribe('canvas_newsletter.update');
        PubSub.unsubscribe('ask-template-newsletter')
        PubSub.unsubscribe('data-classification')

        PubSub.publish("CANVAS", { showPanel: false });

        if (this.editorContent) {
            this.editorContent.destroy();
        }
        if (this.editorNotify) {
            this.editorNotify.destroy();
        }
    }

    setActiveCanvas = (mode) => {
        if (this.state.mode != mode) {
            this.setState({ mode }, () => {
                if(mode === 'preview'){
                    if(document.getElementsByClassName("infoLengthText")[0] && document.getElementsByClassName("infoLengthText")[0].style){
                        document.getElementsByClassName("infoLengthText")[0].style.visibility = 'hidden'
                    }
                }
            });
        }

        if (this.editorNotify && this.editorNotify.hideWrappers)
            this.editorNotify.hideWrappers();
        if (this.editorContent && this.editorContent.hideWrappers)
            this.editorContent.hideWrappers();

        switch (mode) {
            case 'design-content':
                if (this.editorContent)
                    this.editorContent.showWrappers();
                break;
            case 'design-notify':
                if (this.editorNotify)
                    this.editorNotify.showWrappers();
                break;
            case 'preview':
            default:
                break;
        }
    }

    sendCurrentTemplate = () => {
        if (this.editorNotify?.getWrapper().find('.headerText').length !== 0) {
            this.editorNotify?.getWrapper().find('.infoLengthText')[0].remove()
        }
        const template = {
            content : "<style>"+this.editorContent?.getCss()+"</style>"+this.editorContent?.getHtml(),
            notify : "<style>"+this.editorNotify?.getCss()+"</style>"+this.editorNotify?.getHtml(),
            width:this.state.templateWidth,
            height:this.state.templateHeight,
        }
        PubSub.publish('current-template',template)
    }

    render() {
        return (
            <div className="canvasRoot">
                <div className="design-change">
                    <Button.Group>
                        <Button toggle onClick={() => this.setActiveCanvas('design-content')}>İçerik</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('design-notify')}>Bildirim</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('preview')}>Önizleme</Button>
                    </Button.Group>
                </div>
                <div id="design-content" className="canvas-content">

                </div>
                <div id="design-notify" className="canvas-content">

                </div>
                <div id="preview" style={{ marginLeft: 'auto', marginRight: 'auto', width: 900, paddingLeft: 30, display: this.state.mode === 'preview' ? 'block' : 'none' }}>
                    <PreviewDevice
                        deviceType="DESKTOP"
                        device="macbookpro"
                        scale="0.8"
                        wallpaper="url(/images/macos-wallpaper.png)"
                        notification={{
                            show: true,
                            title: 'Mesaj başlığını buraya yazınız',
                            subtitle: 'Yazılı ve görsel mesajların e-posta trafiğine takılmadan çalışanlara ulaşmasını sağlar.',
                            body: 'Pazarlama',
                            snooze: 0,
                            top: 0,
                            content: this.editorNotify ? `<style>html,body{padding:0; margin:0};${this.editorNotify.getCss()}${PreviewCss.css}</style>${this.editorNotify.getHtml()}` : null
                        }}>
                        <PreviewWindow
                            bgcolor="#ffffff"
                            mediaType="POPUP"
                            title='The title of message'
                            subtitle='The sub title of message'>
                            {this.editorContent &&
                                <PreviewFrame
                                    content={`<style>html,body{padding:0; margin:0};${this.editorContent.getCss()}</style>${this.editorContent.getHtml()}`} />
                            }
                        </PreviewWindow>
                    </PreviewDevice>

                </div>
            </div>
        )
    }
}

export default NewsletterDesignPage;
