import React from 'react';
import settings from "../../config/settings.json";
import Popup from "react-popup";
import {
    setChonkyDefaults,
    FullFileBrowser,
    ChonkyActions,
    defineFileAction,
    ChonkyIconName,
    FileViewMode
} from 'chonky';
import { turkishI18n } from "../../helpers/Language";
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { customMediaTableStyles, mediaTableColumnsWithoutFolder, setPageTitle } from "../../helpers/LayoutHelper";
import PubSub from "pubsub-js";
import { getFolders, createFolder, getAnnouncementsByFolder, deleteFolder, updateFolder } from "../../services/api";
import DataTable from "react-data-table-component";
import MediaRowDetail from "../../components/lists/MediaRowDetail";
import LoadingView from "../../components/common/LoadingView";
import EmptyView from "../../components/common/EmptyView";
import { Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import Swal from "sweetalert2";
import { protectedApi } from "../../services/axios";

const useTinyThumbnails = defineFileAction({
    id: 'use_tiny_thumbnails',
    button: {
        name: ' ',
        toolbar: true,
        contextMenu: true,
        icon: ChonkyIconName.smallThumbnail,
    },
    fileViewConfig: {
        mode: FileViewMode.Grid,
        entryWidth: 100,
        entryHeight: 100,
    },
})

class Folders extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageTitle: 'Klasörler',
            folders: [],
            list: [],
            loading: false,
            folderChain: [],
            folderName: '',

            selectedFolder: {
                id: '',
                name: '',
            }
        }

        const renameAction = defineFileAction({
            id: 'rename',
            button: {
                name: 'Yeniden Adlandır',
                toolbar: true,
                contextMenu: true,
                icon: '',
                group: 'Actions'
            },
        });


        this.folderActions = [
            ChonkyActions.CreateFolder,
            useTinyThumbnails,
            //ChonkyActions.UploadFiles,
            // ChonkyActions.EnableListView,
            // ChonkyActions.EnableGridView,
            ChonkyActions.OpenParentFolder,
            ChonkyActions.OpenSelection,
            ChonkyActions.DeleteFiles,
            renameAction
        ];
    }

    /* Standart methods for all pages */
    componentDidMount() {
        PubSub.publish('MENU', 'folders');

        setChonkyDefaults({ iconComponent: ChonkyIconFA });
        this.updatePageTitle();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount() {
        this.updatePage(this.props);
    }

    updatePage = async (props) => {
        let folderId = props.match.params.id;

        this.updatePageTitle();
        this.getFolderList()
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {

    };
    /* Standart methods for all pages */

    getFolderList = () => {
        getFolders().then(res => {
            this.setState({ folders: res })
        })
    }

    handleAction = (event) => {
        if (event.id === "create_folder") {
            //let folderName = window.prompt("Lütfen bir klasör adı yazınız.");
            this.createFolderModalToggle()


            // if(folderName !== null && folderName.trim() !== ""){
            //     createFolder(folderName).then(res => {
            //         this.getFolderList()
            //     })
            // }
        }

        if (event.id === "rename") {
            // Popup.alert(settings.underconstructor_message);
            if (event.state.selectedFiles[0]) {
                console.log('SELECTED FILE ID : ', event.state.selectedFiles[0])
                this.setState({ selectedFolder: { id: event.state.selectedFiles[0].id, name: event.state.selectedFiles[0].name } }, () => console.log('SELECTED FODLER : ', this.state.selectedFolder))
                this.updateFolderModalToggle()
            }
        }

        if (event.id === "delete_files") {
            // console.log('EVENT BİLGİLERİ : ' , event)
            //Popup.alert(settings.underconstructor_message);
            Swal.fire({
                icon: 'warning',
                text: "Seçtiğiniz klasörü silmek istiyor musunuz?",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: '#3B4182',
                confirmButtonText: 'Evet',
                cancelButtonColor: '#E40426',
                cancelButtonText: 'Hayır',
                focusCancel: false,
                reverseButtons: true,
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //const cancellationDescription = window.prompt("İptal nedinini yazınız");
                    deleteFolder(event.state.contextMenuTriggerFile.id)
                        .then(res => {
                            Swal.fire({
                                icon: 'success',
                                text: 'Klasör Silindi!',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonColor: '#3B4182',
                                cancelButtonText: "Tamam",
                                focusCancel: false,

                            })
                            this.getFolderList()
                        })
                        .catch(err => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Klasör Silinemedi!',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonColor: '#3B4182',
                                cancelButtonText: "Tamam",
                                focusCancel: false,

                            })
                            console.log('HATA : ', err)
                        })
                }
            })

        }

        if (event.id === "open_files") {
            this.getList(event.payload.files[0].id)
        }
    }

    getList = async (folderId) => {
        let announcements = await getAnnouncementsByFolder(folderId);
        this.setState({ loading: true })
        if (Array.isArray(announcements) && announcements.length > 0) {
            console.log(announcements)
            this.setState({
                loading: false,
                list: announcements
            });
        } else {
            this.setState({
                loading: false,
                list: []
            });
        }
    }
    createFolderModalToggle = () => {
        this.setState({ createFolderModal: !this.state.createFolderModal })
    }

    handleCreateFolder = () => {
        if (this.state.folderName && this.state.folderName.trim() !== '') {
            createFolder(this.state.folderName).then(res => {
                Swal.fire({
                    icon: 'success',
                    title: 'Klasör eklendi!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                this.getFolderList()
                this.createFolderModalToggle()
            })
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Klasör adı boş olamaz!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
        }
    }

    // This function is handling the rename folder action modal toggle
    updateFolderModalToggle = () => {
        this.setState({ updateFolderModal: !this.state.updateFolderModal })
    }

    // This function is handling the folder rename action
    handleUpdateFolder = () => {
        if (this.state.folderName && this.state.folderName.trim() !== '') {
            updateFolder(this.state.selectedFolder.id, this.state.folderName).then(res => {
                Swal.fire({
                    icon: 'success',
                    title: 'Klasör adı güncelendi!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                this.getFolderList()
                this.updateFolderModalToggle()
            })
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Klasör adı boş olamaz!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card" style={{ height: 300 }}>
                            <div className="card-body">
                                <FullFileBrowser
                                    i18n={turkishI18n.folders}
                                    files={this.state.folders}
                                    folderChain={this.state.folderChain}
                                    fileActions={this.folderActions}
                                    disableDragAndDrop={true}
                                    disableDefaultFileActions={true}
                                    defaultFileViewActionId={useTinyThumbnails.id}
                                    onFileAction={this.handleAction}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            {this.state.list.length > 0 &&
                                <DataTable
                                    title=""
                                    defaultSortField="startDate"
                                    defaultSortAsc={false}
                                    customStyles={customMediaTableStyles}
                                    noHeader={true}
                                    selectableRows={false}
                                    highlightOnHover={true}
                                    fixedHeader={true}
                                    responsive={true}
                                    expandableRows={true}
                                    progressPending={this.state.loading}
                                    expandableRowsComponent={<MediaRowDetail />}
                                    progressComponent={<LoadingView />}
                                    noDataComponent={<EmptyView title="Bu klasör içerisinde gönderi bulunamadı." />}
                                    columns={mediaTableColumnsWithoutFolder}
                                    data={this.state.list}
                                    actions={null}
                                    contextActions={null}
                                    pagination={true}
                                    paginationComponentOptions={
                                        { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }
                                    }
                                />
                            }

                            {this.state.list.length === 0 &&
                                <EmptyView title="Lütfen görüntülemek istediğiniz klasöre çift tıklayınız." />
                            }
                        </div>
                    </div>
                </div>
                <Modal onClose={() => this.createFolderModalToggle()}
                    onOpen={() => this.createFolderModalToggle()}
                    closeIcon
                    size="small"
                    open={this.state.createFolderModal}>
                    <ModalHeader>Klasör Ekle</ModalHeader>
                    <ModalContent>
                        <div className="row">
                            <div className='col-sm-2'>
                                <label className='mt-2'>Klasör Adı : </label>
                            </div>
                            <div className='col-sm-4'>
                                <input
                                    name="folderName"
                                    id="folderName"
                                    className='form-control'
                                    value={this.state.folderName}
                                    onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                    style={{ height: 40, width: 200, marginLeft: 10, marginRight: 10, cursor: 'text' }}
                                />
                            </div>
                        </div>
                    </ModalContent>
                    <ModalActions>
                        <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                            onClick={() => this.createFolderModalToggle()}>Geri
                        </button>
                        <input type="button" className="btn btn-success rounded btn-sm px-4 float-right " onClick={this.handleCreateFolder} value="Kaydet" />
                    </ModalActions>
                </Modal>

                <Modal onClose={() => this.updateFolderModalToggle()}
                    onOpen={() => this.updateFolderModalToggle()}
                    closeIcon
                    size="small"
                    open={this.state.updateFolderModal}>
                    <ModalHeader>Klasör Adını Güncelle</ModalHeader>
                    <ModalContent>
                        <div className="row">
                            <div className='col-sm-2'>
                                <label className='mt-2'>Klasör Adı : </label>
                            </div>
                            <div className='col-sm-4'>
                                <input
                                    name="folderName"
                                    id="folderName"
                                    className='form-control'
                                    defaultValue={this.state.selectedFolder.name}
                                    onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                    style={{ height: 40, width: 200, marginLeft: 10, marginRight: 10, cursor: 'text' }}
                                />
                            </div>
                        </div>
                    </ModalContent>
                    <ModalActions>
                        <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                            onClick={() => this.updateFolderModalToggle()}>Geri
                        </button>
                        <input type="button" className="btn btn-success rounded btn-sm px-4 float-right " onClick={this.handleUpdateFolder} value="Güncelle" />
                    </ModalActions>
                </Modal>
            </div>
        )
    }
}

export default Folders;
