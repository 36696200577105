import React from 'react';
import {AnnouncementStatus} from "../../helpers/LayoutHelper";
import {Link} from "react-router-dom";
import moment from "moment";

class MediaRowDetail extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        console.log(this.props.data)
        return(
            <div className="card-body" style={{padding: 5}}>
                <div className="">
                    <div className="ibox">
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-lg-12">
                                    <dl className="dl-horizontal">
                                        <dt>Durum:</dt>
                                        <dd><span className="label duyuroom-bg text-white rounded">{AnnouncementStatus[this.props.data.isPublished]}</span></dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5">
                                    <dl className="dl-horizontal">
                                        <dt>İçerik Yöneticisi:</dt> <dd><img className="img rounded-circle" src="/images/avatar_empty.png" width={20} height={20}/> {this.props.data.createdby.first_name} {this.props.data.createdby.last_name}</dd>
                                        <dt>Departman:</dt> <dd>{this.props.data.sender}</dd>
                                        <dt>Klasör:</dt> <dd><Link to="/folders"><i className="flaticon-381-folder-8"/> {this.props.data.folder.name}</Link></dd>
                                        <dt>Gönderi No:</dt> <dd>{this.props.data.id}</dd>
                                    </dl>
                                </div>
                                <div className="col-lg-7" id="cluster_info">
                                    <dl className="dl-horizontal">
                                        <dt>Gönderi Oluşturulma Tarihi:</dt> <dd>{moment(this.props.data.created).format('DD.MM.YYYY HH:mm')}</dd>
                                        <dt>Yayımlanma Tarihi:</dt> <dd>{moment(this.props.data.startDate).format('DD.MM.YYYY HH:mm')}</dd>
                                        <dt>Mecra:</dt>
                                        <dd className="project-people">
                                            <img src={`/images/icons/${this.props.data.type}.png`} width={31} height={31}/> {this.props.data.title}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <dl className="dl-horizontal">
                                        <dt>Erişim Oranı:</dt>
                                        <dd>
                                            <div className="progress progress-striped active m-b-sm">
                                                <div style={{width: `${(this.props.data.openedCount/this.props.data.deliveryUsers.length)*100}%`}} className="progress-bar duyuroom-bg" />
                                            </div>
                                            <small>Gönderinin hedefe ulaşma yüzdesi <strong>%{(this.props.data.openedCount/this.props.data.deliveryUsers.length)*100}</strong></small>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MediaRowDetail;
