import React, { Component } from 'react';
import {Checkbox, Modal, ModalActions, ModalContent, ModalHeader} from "semantic-ui-react";
import {getGroups, getUser, updateProfile, updateUser} from "../../services/api";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import { format,parse } from 'date-fns'
import PubSub from "pubsub-js";
import DualListBox from "react-dual-listbox";
import moment from "moment";
import PhoneInput from "react-phone-input-2";


class UpdateUserModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            updateUserModal: false,
            userFirstName: '',
            userLastName: '',
            username: '',
            userEmail: '',
            userPhone: '',
            userBirthDate: null,
            userGroup: [],
            userTitle:'',
            department:'',
            locations:'',
            city:'',

            selectedGroups: [],
            groupsForBox: []
        }

        this.inputCss = { height: 40, width: 300, cursor: 'default' }

    }

    componentDidMount() {
        this.loadGroups()
        PubSub.subscribe('group-delete-reload', this.loadGroups)
        PubSub.subscribe('group-created', this.loadGroups)

    }

    // React-Dual-listbox onChange function
    handleListBoxOnChange = (selected) => {
        this.setState({selectedGroups: selected})
    }

    loadGroups = async () => {
        const userGroups = await getGroups();

        let groupsForBox = []
        userGroups.map(group => {
            let tempObj = {value: group.name, label: group.name, id: group.id}
            groupsForBox.push(tempObj)
        })
        this.setState({
            userGroups: userGroups,
            groupsForBox: groupsForBox
        });
    }



    loadData = () => {
        if (this.props.id) {
            getUser(this.props.id)
                .then(res => {
                    let tempArr = []
                    res.groups.map(data => {
                        let tempObj = data.name
                        tempArr.push(tempObj)
                    })
                    this.setState({
                        userFirstName: res.first_name,
                        userLastName: res.last_name,
                        username: res.username,
                        userEmail: res.email,
                        userPhone: res.profile?.phone,
                        userTitle: res.profile?.title,
                        department: res.profile?.department,
                        city: res.profile?.city,
                        locations: res.profile?.locations,
                        isContentAdmin: res.profile?.isContentAdmin,
                        isSystemAdmin: res.profile?.isSystemAdmin,
                        selectedGroups: tempArr,
                        userBirthDate:res.profile?.birthDate?moment(res.profile?.birthDate,"DD-MM-YYYY").format():new Date(),
                    })

                })
                .catch(err => console.log('ERR : ', err))
        }
    }

    handleUpdateUser = () => {
        if (this.state.userFirstName.length < 2 ||
            this.state.userLastName.length < 2 ||
            this.state.userEmail.length < 2 ||
            this.state.userPhone.length < 2 ||
            this.state.userTitle.length < 2 ||
            this.state.department.length < 2 ||
            this.state.city.length < 2 ||
            this.state.locations.length < 2 ||
            this.state.userBirthDate ===null ||
            this.state.selectedGroups.length===0){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen bütün alanları doldurunuz!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            return;
        }
        let groups = []
        let tempArr = []
        this.state.selectedGroups.map(data => {
            let tempObj = this.state.groupsForBox.filter(i => i.label === data)
            tempArr.push(tempObj)
        })
        tempArr.map(res => {
            res.map(group => {
                let tempId = {id:group.id}
                groups.push(tempId)
            })
        })

        let username = this.state.userFirstName.toLocaleLowerCase()
                .replace(/ğ/g, "g")
                .replace(/ü/g, "u")
                .replace(/ş/g, "s")
                .replace(/ı/g, "i")
                .replace(/ö/g, "o")
                .replace(/ç/g, "c").trim().replaceAll(' ', '')+
            this.state.userLastName.toLocaleLowerCase()
                .replace(/ğ/g, "g")
                .replace(/ü/g, "u")
                .replace(/ş/g, "s")
                .replace(/ı/g, "i")
                .replace(/ö/g, "o")
                .replace(/ç/g, "c").trim().replaceAll(' ', '')

        const payload = {
            "id": this.props.id,
            'first_name': this.state.userFirstName,
            'last_name': this.state.userLastName,
            'username': username,
            'email': this.state.userEmail,
            'groups': groups,
            is_active: true,
        }
        console.log('PAYLOAD : ', payload)

        updateUser(this.props.id, payload)
            .then(res => {
                const profilePayload = {
                    'user': this.props.id,
                    'birthDate': moment(this.state.userBirthDate).format("DD-MM-YYYY"),
                    'city': this.state.city,
                    'locations': this.state.locations,
                    'department': this.state.department,
                    'isContentAdmin': this.state.isContentAdmin,
                    'isSystemAdmin': this.state.isSystemAdmin,
                    'phone': this.state.userPhone,
                    'title': this.state.userTitle,
                    'userType': 'SA'
                }
                updateProfile(res.id, profilePayload)
                    .then(response => {
                        if(response.status===400){
                            let msg = ""
                            if(response.errors.phone){msg=response.errors.phone[0]}
                            Swal.fire({
                                icon: 'warning',
                                text: msg,
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonColor: '#3B4182',
                                cancelButtonText: "Tamam",
                                focusCancel: false,
                            })
                            return
                        }
                        Swal.fire({
                            icon: 'success',
                            text: 'Bilgiler Güncellendi!',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,
                        })
                        this.updateUserModalToggle(false)
                        PubSub.publish('update-users', [])
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            text: 'Kullanıcı Güncellenemedi!',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,

                        })
                    })
            }).catch(err => {
                Swal.fire({
                    icon: 'success',
                    text: 'Kullanıcı Güncellendi',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                console.log('ERR : ', err)
            })
    }


    updateUserModalToggle = (state) => {
        if (state) {
            this.loadData()
        }
        this.setState({ updateUserModal: state });
    }
    render() {
        return (
            <Modal onClose={() => this.updateUserModalToggle(false)}
                onOpen={() => this.updateUserModalToggle(true)}
                trigger={this.props.trigger}
                closeIcon
                size="tiny"
                open={this.state.updateUserModal}>
                <ModalHeader>Kullanıcı Güncelle</ModalHeader>
                <ModalContent>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>Adı *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <input
                                name="userFirstName"
                                id="userFirstName"
                                className='form-control float-left'
                                value={this.state.userFirstName}
                                onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                style={this.inputCss}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>Soyadı *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <input
                                name="userLastName"
                                id="userLastName"
                                className='form-control float-left'
                                value={this.state.userLastName}
                                onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                style={this.inputCss}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>Kullanıcı Adı *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <input
                                name="username"
                                id="username"
                                className='form-control'
                                value={this.state.userFirstName.toLocaleLowerCase()
                                    .replace(/ğ/g, "g")
                                    .replace(/ü/g, "u")
                                    .replace(/ş/g, "s")
                                    .replace(/ı/g, "i")
                                    .replace(/ö/g, "o")
                                    .replace(/ç/g, "c").trim().replaceAll(' ', '')+
                                    this.state.userLastName.toLocaleLowerCase()
                                    .replace(/ğ/g, "g")
                                    .replace(/ü/g, "u")
                                    .replace(/ş/g, "s")
                                    .replace(/ı/g, "i")
                                    .replace(/ö/g, "o")
                                    .replace(/ç/g, "c").trim().replaceAll(' ', '')}
                                readOnly
                                style={this.inputCss}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>E-Posta *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <input
                                name="userEmail"
                                id="userEmail"
                                className='form-control'
                                value={this.state.userEmail}
                                onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                style={this.inputCss}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>Tel No *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <PhoneInput
                                country={'tr'}
                                // name="userPhone"
                                // id="userPhone"
                                inputClass="react-tel-input"
                                buttonClass="react-tel-input-flag"
                                value={this.state.userPhone}
                                onChange={e => this.setState({ userPhone: e})}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>Doğum Tarihi *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <DatePicker
                                wrapperClassName="datePicker"
                                placeholderText="Gün.Ay.Yıl"
                                selected={new Date(this.state.userBirthDate)}
                                isClearable={false}
                                showTimeSelect={false}
                                timeFormat="H:mm"
                                timeIntervals={15}
                                locale={tr}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="dd.MM.yyyy"
                                onChange={date => {
                                    this.setState({userBirthDate: date})
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>Unvan *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <input
                                name="userTitle"
                                id="userTitle"
                                className='form-control float-left'
                                value={this.state.userTitle}
                                onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                style={this.inputCss}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>Departman *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <input
                                name="department"
                                id="department"
                                className='form-control float-left'
                                value={this.state.department}
                                onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                style={this.inputCss}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className="col-sm-1"/>
                        <div className="col-sm-10">
                            <label className='mt-2'>Yetkilendirme : </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-sm-2' />
                        <div className='col-sm-3'>
                            <Checkbox className="mt-3" label='Standart Kullanıcı' checked={true}/>
                        </div>
                        <div className='col-sm-3'>
                            <Checkbox className="mt-3" label='İçerik Yöneticisi' checked={this.state.isContentAdmin} value={this.state.isContentAdmin}
                                      onChange={() => this.setState({isContentAdmin: !this.state.isContentAdmin})}/>
                        </div>
                        <div className='col-sm-3'>
                            <Checkbox className="mt-3" label='Sistem Yöneticisi' checked={this.state.isSystemAdmin} value={this.state.isSystemAdmin}
                                      onChange={() => {
                                          this.setState({isSystemAdmin: !this.state.isSystemAdmin}, () => {
                                              if(this.state.isSystemAdmin){
                                                  this.setState({isContentAdmin: true})
                                              }
                                          })
                                      }}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>İl *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <input
                                name="city"
                                id="city"
                                className='form-control'
                                value={this.state.city}
                                onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                style={this.inputCss}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-3'>
                            <label className='mt-2'>Lokasyon *: </label>
                        </div>
                        <div className='col-sm-6'>
                            <input
                                name="locations"
                                id="locations"
                                className='form-control'
                                value={this.state.locations}
                                onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                style={this.inputCss}
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className="row mt-2">
                        <div className='col-sm-1' />
                        <div className='col-sm-10'>
                            <label className='mt-2'>Gruplar *:</label>
                            <DualListBox
                                canFilter
                                filterPlaceholder="Ara..."
                                options={this.state.groupsForBox}
                                selected={this.state.selectedGroups}
                                onChange={this.handleListBoxOnChange}
                            />
                        </div>
                    </div>
                </ModalContent>
                <ModalActions>
                    <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                        onClick={() => this.updateUserModalToggle(false)}>Vazgeç
                    </button>
                    <input type="button" className="btn btn-success rounded btn-sm px-4 float-right " onClick={this.handleUpdateUser} value="Güncelle" />
                </ModalActions>
            </Modal>
        );
    }
}

export default UpdateUserModal;