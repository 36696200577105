import React from 'react';

class PreviewNotification extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className="notificationPreview" onClick={() => this.props.onPress()} style={{position: 'absolute', top: this.props.notification.top, right: 0, color: '#000000', cursor: 'pointer' }}>
                <label style={{position: 'absolute', fontWeight: 'bold', marginTop: 37, marginLeft: 45, maxHeight: 22, lineHeight: '14px'}}>
                    {this.props.notification.title.substr(0, 40)}<br/>
                    <label style={{position: 'absolute', fontSize: 12, fontWeight: 'normal'}}>{this.props.notification.body}</label>
                </label>

                <label className="notificationSubTitle" style={{position: 'absolute', marginTop: 70, marginLeft: 45, marginRight: 5, height: 25, overflow: 'hidden', overflowX: 'hidden'}}>{this.props.notification.subtitle}</label>
                <img src="/images/notification-preview.png" style={{width: this.props.deviceType === 'DESKTOP' ? 390 : '100%'}}/>
            </div>
        )
    }
}

export default PreviewNotification;
