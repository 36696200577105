import React from 'react';
import '../../assets/css/login.css';
import {isValidEmail} from "../../helpers/LayoutHelper";
import AuthService from "../../services/auth.service";
import {Link} from "react-router-dom";

class Forgot extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            message: "",
            loading: false
        }
    }

    handleForgot = (e) => {
        e.preventDefault();

        let errors = [];
        let email = this.state.email;

        if(email.trim() === ''){
            errors.push("&bull; Lütfen e-posta adresinizi giriniz.");
        }else{
            if(!isValidEmail(email)){
                errors.push("&bull; Lütfen geçerli bir e-posta adresi giriniz.")
            }
        }

        if (errors.length === 0) {
            this.setState({
                message: "",
                loading: true
            });

            AuthService.reset(this.state.email).then(
                (response) => {
                    const resMessage =
                        (response.response && response.response.data && response.response.data.message) || response.message || response.toString();
                    this.setState({
                        loading: false,
                        message: "&bull; " + resMessage
                    });
                },
                error => {
                    const resMessage =
                        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    this.setState({
                        loading: false,
                        message: "&bull; " + resMessage
                    });
                }
            );
        } else {
            this.setState({
                loading: false,
                message: errors.join('<br/>')
            });
        }
    };

    render() {
        return (
            <div className="login_wrapper" style={{backgroundImage: "url(/images/background_login.png)", backgroundSize: "cover", backgroundColor: "#3B4182"}}>
                <div className="row">
                    <div className="col-lg-7 col-xl-7 d-none d-lg-block text-center" style={{paddingTop: 75}}>
                        <h2 style={{color: "#ffffff", fontSize: '5vw', fontWeight: "550", fontFamily: "Montserrat"}}>Hoş Geldiniz!</h2>
                        <img src="/images/decorative_login.png" width="60%" style={{marginTop: 30}}/>
                    </div>
                    <div className="col-lg-5 col-xl-5 col-md-12 text-center">
                        <img src="/images/logo_login.png" width={180} style={{paddingTop: 50}}/>
                        <div style={{width: "70%", minHeight: 470, minWidth: 385, paddingTop: 50, paddingLeft:50, paddingRight: 50, borderRadius: 30, marginTop: 75, marginLeft: "auto", marginRight: "auto", border: "solid 1px #ffffff"}}>
                            <h3 style={{color: "#ffffff", fontSize: 26, marginBottom: 60, fontWeight: "400", fontFamily: "Montserrat"}}>Şifrenizi Sıfırlayın</h3>
                            <form autoComplete="off" autoCorrect="off">
                                <div className="form-group mb-3">
                                    <input type="text"
                                           placeholder="E-posta adresi"
                                           autoComplete="new-password"
                                           autoCorrect="off"
                                           className="form-control rounded-pill border-0 shadow-sm px-4"
                                           value={this.state.email}
                                           onChange={(e) => {this.setState({email: e.target.value})}}
                                    />
                                </div>

                                <button type="submit" onClick={this.handleForgot} className="btn btn-login btn-block text-uppercase mb-2 rounded-pill shadow-sm" disabled={this.state.loading}>GÖNDER</button>


                                <div className="text-center d-flex justify-content-between mt-4">
                                    <p style={{fontSize: 12}}>
                                        <Link to="/authentication/login" style={{color: '#ffffff'}}>
                                            <i className="flaticon-381-back"/> Giriş yapmak için geri dönün
                                        </Link>
                                    </p>
                                </div>

                            </form>
                        </div>

                        {this.state.message !== '' &&
                        <div
                            className="alert alert-outline-warning"
                            dangerouslySetInnerHTML={{__html:this.state.message}}
                            style={{width: "70%", minWidth: 385, marginTop: 10, marginLeft: "auto", marginRight: "auto"}}
                        />
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default Forgot
