import React from 'react';

class EmptyView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{padding: 25, textAlign: "center"}}>
                <i className={this.props.icon}/>
                <br/>
                <h4>{this.props.title}</h4>
            </div>
        );
    }
}

EmptyView.defaultProps = {
    icon: "la la-box-open la-5x",
    title: "Henüz gönderiniz bulunmuyor."
}

export default EmptyView;
