import React from 'react'
import { withRouter, Link } from "react-router-dom";
import PopupModal from "./CreatePopupModal";
import EmergencyModal from "./CreateEmergencyModal";
import NewsletterModal from "./CreateNewsletterModal";
import SurveyModal from "./CreateSurveyModal";
import LockModal from "./CreateLockModal";
import { Tooltip } from '@trendmicro/react-tooltip';
import settings from "../../config/settings.json";
import Popup from "react-popup";
import LCVModal from "./CreateLCVModal";
import TickerModal from "./CreateTickerModal";
import QuizModal from "./CreateQuizModal";
import WallpaperModal from "./CreateWallpaperModal";
import ScreensaverModal from "./CreateScreensaverModal";
import SocialModal from "./CreateSocialModal";
import ElearningModal from "./CreateElearningModal";
import Swal from "sweetalert2";

class MediaModalMenager extends React.Component {
    // {`/media/add/${item.key}`}

    render() {
        const item = this.props.data;
        const widthHeight = 20
        if (item) {
            if (item.key === "POPUP" && item.licence.popup === "True") {
                return (
                    <PopupModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            } else if (item.key === "TICKER" && item.licence.ticker === "True") {
                return (
                    <TickerModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            } else if (item.key === "LOCK" && item.licence.lock === "True") {
                return (
                    <LockModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            } else if (item.key === "WALLPAPER" && item.licence.wallpaper === "True") {
                return (
                    <WallpaperModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            }else if (item.key === "SCREENSAVER" && item.licence.screensaver === "True") {
                return (
                    <ScreensaverModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            }else if (item.key === "SOCIAL" && item.licence.social === "True") {
                return (
                    <SocialModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            }else if (item.key === "EMERGENCY" && item.licence.emergency === "True") {
                return (
                    <EmergencyModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            } else if (item.key === "NEWSLETTER" && item.licence.newsletter === "True") {
                return (
                    <NewsletterModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            } else if (item.key === "LCV" && item.licence.lcv === "True") {
                return (
                    <LCVModal key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            } else if (item.key === "SURVEY" && item.licence.survey === "True") {
                return (
                    <SurveyModal type="survey" key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            } else if (item.key === "QUIZ" && item.licence.quiz === "True") {
                return (
                    <QuizModal type="quiz" key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            }else if (item.key === "ELEARNING" && item.licence.elearning === "True") {
                return (
                    <ElearningModal type="elearning" key={this.props.key} item={item} trigger={
                        <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                            <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                                <Link style={{ borderRadius: 30 }} to={'#'} className="nav-link primary">
                                    <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                                </Link>
                            </Tooltip>
                        </li>
                    } />
                );
            }else if (item.key === "DOCUMENT" && item.licence.library === "True") {
                return (
                    <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                        <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                            <Link style={{ borderRadius: 30 }} to={'/documentmanagement'} className="nav-link primary">
                                <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                            </Link>
                        </Tooltip>
                    </li>
                );
            }else {
                return (
                    <li key={this.props.key} className="nav-item notification_dropdown" style={{ marginRight: 0 }}>
                        <Tooltip content={item.title} placement="bottom" tooltipClassName="deneme" tooltipStyle={{}} >
                            <Link style={{ borderRadius: 30 }} to={'#'}
                                  // onClick={() => Popup.alert(settings.underconstructor_message)}
                                  onClick={() => {
                                      Swal.fire({
                                          icon: 'warning',
                                          text: 'Bu mecranın kullanımı lisansınıza dahil değil. Lütfen müşteri temsilciniz ile iletişime geçiniz.',
                                          showCancelButton: true,
                                          showConfirmButton: false,
                                          cancelButtonColor: '#3B4182',
                                          cancelButtonText: "Tamam",
                                          focusCancel: false,
                                      })
                                  }}
                                  className="nav-link primary">
                                <img src={`/images/${item.icon}`} width={widthHeight} height={widthHeight} />
                            </Link>
                        </Tooltip>
                    </li>
                );
            }

        } else return (null);
    }
}

export default MediaModalMenager;
