export default (editor, config, opt = {}) => {
  const c = opt
  const bm = editor.BlockManager;
  const toAdd = name => config.blocks.indexOf(name) >= 0;

  // Romove the default blocks
  bm.remove('column1')
  bm.remove('column2')
  bm.remove('column3')
  bm.remove('column3-7')
  bm.remove('map')
  bm.remove('text')
  bm.remove('link')
  bm.remove('video')
  bm.remove('image')


  toAdd('defaultImage') && bm.add('defaultImage', {
    label: 'Görsel',
    category: 'Basic',
    attributes: { class: 'gjs-fonts gjs-f-image' },
    content: {
      style: { color: 'black' },
      type: 'image',
      activeOnRender: 0
    },
  });
  toAdd('videowithupload') && bm.add("videowithupload", {
    label: 'Video',
    category: 'Basic',
    attributes: { class: "fa fa-youtube-play" },
    content: {
      attributes: { id: "videoBlock" },
      type: "video",
      resizable: true,
      // activeOnRender: 1,
    },
  });
  toAdd('defaultText') && bm.add('defaultText', {
    label: 'Metin',
    category: 'Basic',
    attributes: { class: "gjs-fonts gjs-f-text" },
    content: {
      resizable: true,
      removable: true,
      type: "text",
      style: { width: '200px' },
      content: 'Metninizi buraya ekleyin.',
      activeOnRender: 1,
    },
  });

  toAdd('header') && bm.add('header', {
    label: 'Başlık',
    category: 'Basic',
    attributes: { class: 'fa fa-header' },
    content: {
      resizable: true,
      style: { width: '520px', height: '30px', "font-size": "18px", "font-weight": "900" },
      type: "text",
      content: 'Başlığınızı buraya ekleyin.',
      activeOnRender: 1,
    },
  });

  toAdd('MyText') && bm.add('MyText', {
    label: 'Text Taze',
    category: 'Basic',
    attributes: { class: "gjs-fonts gjs-f-text" },
    content: {
      resizable: true,
      style: { width: '520px', height: '30px' },
      spellcheck: true,
      type: "text",
      content: `<p>Burayı doldurun</p>`,
      activeOnRender: 1,
    },
  });

  toAdd('text-basic') && bm.add('text-basic', {
    category: 'Basic',
    label: 'Text section',
    attributes: { class: 'gjs-fonts gjs-f-h1p' },
    content: {
      resizable: true,
      style: { width: '200px', height: '30px' },
      type: "text",
      content: 'Insert your header here',
      activeOnRender: 1,
    },
  });
  // console.log('TEXT BASIC : ', JSON.stringify(bm.get('text-basic')))



  // toAdd('link-block') && bm.add('link-block', {
  //   category: 'Basic',
  //   label: 'Link Block',
  //   attributes: { class: 'fa fa-link' },
  //   content: {
  //     type:'link',
  //     editable: false,
  //     droppable: true,
  //     style:{
  //       display: 'inline-block',
  //       padding: '5px',
  //       'min-height': '50px',
  //       'min-width': '50px'
  //     }
  //   },
  // });

  // toAdd('quote') && bm.add('quote', {
  //   label: 'Quote',
  //   category: 'Basic',
  //   attributes: { class: 'fa fa-quote-right' },
  //   content: `<blockquote class="quote">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit
  //     </blockquote>`
  // });

  // toAdd('buttonYes') && bm.add('buttonYes', {
  //   category: 'Buttons',
  //   label: 'Evet',
  //   attributes: { class: "gjs-fonts gjs-f-button" },
  //   content: "<button class='btn' id='lcv-button' onclick='handleClick(1)' >Evet</button>"
  // });
  // toAdd('buttonNo') && bm.add('buttonNo', {
  //   category: 'Buttons',
  //   label: 'Hayır',
  //   attributes: { class: "gjs-fonts gjs-f-button" },
  //   content: "<button class='btn' onclick='handleClick(0)' >Hayır</button>"
  // });
  // toAdd('buttonMaybe') && bm.add('buttonMaybe', {
  //   category: 'Buttons',
  //   label: 'Belki',
  //   attributes: { class: "gjs-fonts gjs-f-button" },
  //   content: "<button class='btn' onclick='handleClick(2)' >Belki</button>"
  // });

  toAdd('buttonBlock') && bm.add('buttonBlock', {
    category: 'Buttons',
    'label': 'Butonlar',
    attributes: { class: 'gjs-fonts gjs-f-button' },
    content: "<div><button class='btn btn-yes' id='buttonYes' onclick='handleClick(1)'>Evet</button><button class='btn btn-no' id='buttonNo' onclick='handleClick(0)'>Hayır</button><button class='btn btn-maybe' id='buttonMaybe' onclick='handleClick(2)'>Belki</button></div>",
  })

  //toAdd('button') && bm.add('button', {
  //  category: 'Button',
  //  label: 'Button',
  //  attributes: { class: "gjs-fonts gjs-f-button" },
  //  content: "<button class='btn button-block' id='button-block' >Buton</button>"
  //});

  // toAdd('myVideo') && bm.add('myVideo', {
  //   category: 'Basic',
  //   label: 'My Video',
  //   attributes: { class: 'fa fa-youtube-play' },
  //   content: `<video width="320" height="240" ><source src="../../../assets/dummy_video.mp4" type="video/mp4">Your browser does not support the video tag.</video>`
  // });
  // toAdd('youtubeVideo') && bm.add('youtubeVideo', {
  //   category: 'Basic',
  //   label: 'Youtube Video',
  //   attributes: { class: 'fa fa-youtube-play' },
  //   content: `<iframe width="560" height="315" src="https://www.youtube.com/embed/l2X5rwJ_bW4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
  // });
}
