import React, { useState, useEffect } from "react";
import QuizQuestion from "./QuizQuestion";
import Question from "./Question";
import ListController from "./ListController";
import EmptyView from "../common/EmptyView";
// import {thunkUpdateDefaultFileViewActionId} from "chonky/lib/redux/thunks/file-actions.thunks";

export default function QuizBuilder({onChange, mode, data,show,subMode}) {


    const [questions, setQuestions] = useState(data ? data : []);
    const [count,setCount] = useState(0)
    const listController = new ListController(questions, setQuestions);

    if(subMode==="again"){
        if (data!==questions && count <= 1){
            setQ()
            setCount(count+1)
        }

        function setQ()  {

            const temp = data?.map((data,index)=>{
                return(new Question({answers:data.answers,text:data.text,hasOther:data.hasOther,type:data.type,options:data.options,cols:data.cols,rows:data.rows,id:data.id}))
            })
            setQuestions(temp)


        }
    }




    useEffect(() => {
        if(questions?.length > 0 && mode === "edit"){
            onChange(questions);
        }

    }, [questions])





    return (
        <div className="small-container" style={{overflowY:"auto"}}>
            <ul>
                {questions?.map((question, i) => {
                    return(
                    <QuizQuestion
                        key={question?.id}
                        index={i}
                        mode={mode}
                        total={questions?.length}
                        question={question}
                        setQuestion={question => listController.set(i, question)}
                        removeQuestion={() => listController.remove(i)}
                        moveQuestionUp={() => listController.moveUp(i)}
                        moveQuestionDown={() => listController.moveDown(i)}
                        copyQuestion={() => listController.copy(i)}
                        show={show}
                    />
                )})}
            </ul>

            {questions?.length === 0 &&
            <>
                <br/>
                <EmptyView title="Henüz gösterilecek bir soru yok 'Soru Ekle' butonu ile hemen yeni bir soru oluşturabilirsiniz."/>
            </>
            }

            {mode === "edit" &&
                <div className="text-center">
                    <button className="btn btn-primary mt-5" style={{display:"inline-block",margin:"auto",width:"150px"}}
                            onClick={() => {listController.add(new Question())
                                let div = document.getElementById("quiz_builder_bottom_div")
                                div.scrollIntoView({ behavior: "smooth" })}}>Soru Ekle</button>
                </div>

            }
            <div id="quiz_builder_bottom_div" style={{ float:"left", clear: "both" ,height:"200px"}}>
            </div>



            {/*{mode === "edit" &&*/}
            {/*<button className="btn btn-primary" style={{height: 60, width: 60, borderRadius: 30, position: "fixed", bottom: 30,  right: 400}} onClick={() => listController.add(new Question())}>+</button>*/}
            {/*}*/}
        </div>
    );
}
