// eslint-disable-next-line import/no-anonymous-default-export
export default (editor, config) => {
    const sm = editor.StyleManager;
    const csm = config.customStyleManager;

    sm.getSectors().reset(csm && csm.length ? csm : [
        // {
        //     name: config.textGeneral,
        //     open: true,
        //     buildProps: ['float', 'align-content', 'display', 'position', 'top', 'right', 'left', 'bottom'],
        //     properties: [
        //         {
        //             property: 'float',
        //             list: [
        //                 {value: 'none', name: 'Yok'},
        //                 {value: 'left', name: 'Sol'},
        //                 {value: 'center', name: 'Orta'},
        //                 {value: 'right', name: 'Sağ'}]
        //         },
        //         {
        //             property: 'display',
        //             type: 'select',
        //             defaults: 'block',
        //             list: [{
        //                 value: 'block',
        //                 name: 'Blok'
        //             }, {
        //                 value: 'inline',
        //                 name: 'Satır İçi'
        //             }, {
        //                 value: 'inline-block',
        //                 name: 'Satır İçi-Blok'
        //             }, {
        //                 value: 'flex',
        //                 name: 'Esnek'
        //             }, {
        //                 value: 'none',
        //                 name: 'Yok'
        //             }],
        //         },
        //         {
        //             property: 'position',
        //             list: [
        //                 {value: 'static', name: 'Statik'},
        //                 {value: 'relative', name: 'Göreceli'},
        //                 {value: 'absolute', name: 'Mutlak'},
        //                 {value: 'fixed', name: 'Sabit'},],
        //         },
        //         {
        //             property: 'top',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0
        //         },
        //         {
        //             property: 'right',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0
        //         },
        //         {
        //             property: 'left',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0
        //         },
        //         {
        //             property: 'bottom',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0
        //         },
        //     ]
        // },
        // {
        //     name: config.textLayout,
        //     open: true,
        //     buildProps: ['width', 'height', 'max-width', 'min-height', 'padding'],
        //     properties: [
        //         {
        //             property: 'width',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0
        //         },
        //         {
        //             property: 'height',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0
        //         },
        //         {
        //             property: 'max-width',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0
        //         },
        //         {
        //             property: 'min-height',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0
        //         },
        //     ]
        // },
        {
            name: config.textTypography,
            open: true,
            buildProps: ['font-family', 'font-size', 'font-weight', 'line-height', 'text-align', 'color'],
            properties: [
                {
                    property: 'font-size',
                    type: 'integer',
                    defaults: 14,
                    min: 0
                },
                {
                    property: 'font-family',
                    name: 'Font',
                    defaults: 'Arial, Helvetica, sans-serif',
                    list: [
                        { name: 'Arial', value: 'Arial, Helvetica, sans-serif' },
                        {value: "Arial Black", name: 'Arial Black'},
                        {value: "Brush Script MT, cursive", name: 'Brush Script MT'},
                        {value: "Pacifico, cursive", name: 'Pacifico'},
                        // {value: "allianzRegular", name: 'Allianz Neo'},
                        {value: "motionPicture", name: 'Motion Picture'},
                        {value: "nickainley", name: 'Nickainley'},
                        // {value: "'Oswald', sans-serif", name: 'Oswald'},
                        // {value: "Helvetica Neue,Helvetica,Arial,sans-serif", name: 'Helvetica'},
                        // {value: "sans-serif", name: 'sans-serif'},
                        {value: "Tahoma", name: 'Tahoma'},
                        {value: "Verdana, Geneva, sans-serif", name: 'Verdana'},
                        {value: "Courier New Courier, monospace", name: 'Courier New Courier'},
                        {value: "'Lato', sans-serif", name: 'Lato'},
                        {value: "Times New Roman", name: 'Times New Roman'},
                        // {value: "'Open Sans', sans-serif", name: 'Open Sans'},
                        {value: "'Montserrat', sans-serif", name: 'Montserrat'},
                    ],
                },
                {
                    property: 'text-align',
                    list: [
                        {value: 'left', className: 'fa fa-align-left', title: 'Sol'},
                        {value: 'center', className: 'fa fa-align-center', title: 'Orta'},
                        {value: 'right', className: 'fa fa-align-right', title: 'Sağ'},
                        {value: 'justify', className: 'fa fa-align-justify', title: 'Yasla'},],
                },
                {
                    property: 'line-height',
                    type: 'select',
                    defaults: '20px',
                    list: [{
                        value: '10px',
                        name: '0.5 Satır'
                    }, {
                        value: '20px',
                        name: '1 Satır'
                    }, {
                        value: '25px',
                        name: '1.5 Satır'
                    }, {
                        value: '30px',
                        name: '2 Satır'
                    }, {
                        value: '35px',
                        name: '2.5 Satır'
                    }, {
                        value: '40px',
                        name: '3 Satır'
                    },],
                },
                {
                    property: 'font-weight',
                    type: 'select',
                    defaults: '400',
                    list: [
                        // {
                        //     value: '100',
                        //     name: 'İnce'
                        // }, {
                        //     value: '200',
                        //     name: 'Ekstra Zayıf'
                        // }, {
                        //     value: '300',
                        //     name: 'Zayıf'
                        // },
                        {
                            value: '400',
                            name: 'Normal'
                        },
                        // {
                        //     value: '500',
                        //     name: 'Orta'
                        // }, {
                        //     value: '600',
                        //     name: 'Yarı kalın'
                        // }, {
                        //     value: '700',
                        //     name: 'Kalın'
                        // }, {
                        //     value: '800',
                        //     name: 'Ekstra kalın'
                        // },
                        {
                            value: '900',
                            name: 'Kalın'
                        }
                    ],
                },
                {
                    property: 'color',
                    defaults: '',
                },
            ]
        }, {
            name: config.textDecorations,
            open: true,
            buildProps: ['background-color', 'border-radius-c', 'border-radius', 'border-style', 'border-width', 'border-color', 'background'],
            properties: [
                {
                    property: 'background-color',
                    defaults: '',
                },
                {
                    property: 'border-style',
                    type: 'select',
                    defaults: 'none',
                    list: [{
                        value: 'none',
                        name: 'Yok'
                    }, {
                        value: 'solid',
                        name: 'Katı'
                    }, {
                        value: 'dotted',
                        name: 'Noktalı'
                    }, {
                        value: 'dashed',
                        name: 'Kesikli'
                    }, {
                        value: 'double',
                        name: 'İkili'
                    }, {
                        value: 'groove',
                        name: 'Çerçeve'
                    }, {
                        value: 'ridge',
                        name: 'Yükseltili'
                    }, {
                        value: 'inset',
                        name: 'İçe Dönük Çerçeve'
                    }, {
                        value: 'outset',
                        name: 'Dışa Dönük Çerçeve'
                    }],
                },
                {
                    property: 'border-width',
                    type: 'integer',
                    defaults: 0,
                    min: 0
                },
                {
                    property: 'border-color',
                    defaults: '',
                },
                // {
                //     property: 'box-shadow',
                //     properties: [
                //         {name: 'X position', property: 'box-shadow-h'},
                //         {name: 'Y position', property: 'box-shadow-v'},
                //         {name: 'Blur', property: 'box-shadow-blur'},
                //         {name: 'Spread', property: 'box-shadow-spread'},
                //         {name: 'Color', property: 'box-shadow-color', defaults: ''},
                //         {
                //             name: 'Shadow type',
                //             property: 'box-shadow-type',
                //             type: 'select',
                //             defaults: 'Outside',
                //             list: [{
                //                 value: 'Outside',
                //                 name: 'Dışa dönük'
                //             }, {
                //                 value: 'Inside',
                //                 name: 'İçe Dönük'
                //             },]
                //         }
                //     ],
                // },
                {
                    property: 'background',
                    properties: [
                        {name: 'background-image', property: 'background-image'},
                        {
                            name: 'background-repeat',
                            property: 'background-repeat',
                            type: 'select',
                            defaults: 'repeat',
                            list: [{
                                value: 'repeat',
                                name: 'Tekrar'
                            }, {
                                value: 'repeat-x',
                                name: 'X Ekseninde Tekrar'
                            }, {
                                value: 'repeat-y',
                                name: 'Y Ekseninde Tekrar'
                            }, {
                                value: 'no-repeat',
                                name: 'Tekrar Yok'
                            },
                            ]
                        },
                        {
                            name: 'background-position',
                            property: 'background-position',
                            type: 'select',
                            defaults: 'left top',
                            list: [{
                                value: 'left top',
                                name: 'Sol Üst'
                            }, {
                                value: 'left center',
                                name: 'Sol Orta'
                            }, {
                                value: 'left bottom',
                                name: 'Sol Alt'
                            }, {
                                value: 'right top',
                                name: 'Sağ Üst'
                            }, {
                                value: 'right center',
                                name: 'Sağ Orta'
                            }, {
                                value: 'right bottom',
                                name: 'Sağ Alt'
                            }, {
                                value: 'center top',
                                name: 'Orta Üst'
                            }, {
                                value: 'center center',
                                name: 'Orta'
                            }, {
                                value: 'center bottom',
                                name: 'Orta Alt'
                            },
                            ]
                        },
                        {
                            name: 'background-attachment',
                            property: 'background-attachment',
                            type: 'select',
                            defaults: 'scroll',
                            list: [{
                                value: 'scroll',
                                name: 'Kaydır'
                            }, {
                                value: 'fixed',
                                name: 'Sabit'
                            }, {
                                value: 'local',
                                name: 'Bağıl'
                            },
                            ]
                        },
                        {
                            name: 'background-size',
                            property: 'background-size',
                            type: 'select',
                            defaults: 'auto',
                            list: [{
                                value: 'auto',
                                name: 'Otomatik'
                            }, {
                                value: 'cover',
                                name: 'İçermek'
                            }, {
                                value: 'contain',
                                name: 'Kapsamak'
                            },
                            ]
                        },
                    ],

                },
            ]
        },
        // {
        //     name: config.textExtra,
        //     open: true,
        //     buildProps: ['transition', 'transform'],
        //     properties: [
        //         {
        //             property: 'transition',
        //             properties: [
        //                 {
        //                     name: 'transition-property',
        //                     property: 'transition-property',
        //                     type: 'select',
        //                     defaults: 'width',
        //                     list: [{
        //                         value: 'all',
        //                         name: 'Hepsi'
        //                     }, {
        //                         value: 'width',
        //                         name: 'Genişlik'
        //                     }, {
        //                         value: 'height',
        //                         name: 'Yükseklik'
        //                     }, {
        //                         value: 'background-color',
        //                         name: 'Arkaplan Rengi'
        //                     }, {
        //                         value: 'transform',
        //                         name: 'Dönüştürmek'
        //                     }, {
        //                         value: 'box-shadow',
        //                         name: 'Kutu Gölgesi'
        //                     }, {
        //                         value: 'opacity',
        //                         name: 'Opaklık'
        //                     }
        //                     ]
        //                 },
        //                 {name: 'transition-duration', property: 'transition-duration'},
        //                 {
        //                     name: 'transition-timing-function',
        //                     property: 'transition-timing-function',
        //                     type: 'select',
        //                     defaults: 'ease',
        //                     list: [{
        //                         value: 'linear',
        //                         name: 'Doğrusal'
        //                     }, {
        //                         value: 'ease',
        //                         name: 'Yumuşak Geçiş'
        //                     }, {
        //                         value: 'ease-in',
        //                         name: 'Yumuşak Geçiş-İç'
        //                     }, {
        //                         value: 'ease-out',
        //                         name: 'Yumuşak Geçiş-Dış'
        //                     }, {
        //                         value: 'ease-in-out',
        //                         name: 'Yumuşak Geçiş-İç-Dış'
        //                     },]
        //                 }
        //             ],
        //         },
        //     ]
        // }
    ]);
}
