import { ChonkyActions } from 'chonky';

export const turkishI18n = {
    folders: {
        locale: 'tr',
        messages: {
            'chonky.toolbar.searchPlaceholder': 'Ara',
            'chonky.toolbar.visibleFileCount': `{fileCount, plural,
                =0 {# items}
                one {# item}
                other {# items}
            }`,
            'chonky.toolbar.selectedFileCount': `{fileCount, plural,
                =0 {}
                one {# Seçili}
                other {# Seçili}
            }`,
            'chonky.toolbar.hiddenFileCount': `{fileCount, plural,
                =0 {}
                one {# Gizli}
                other {# Gizli}
            }`,
            'chonky.fileList.nothingToShow': 'Henüz bir dosya yüklenmedi.',
            'chonky.contextMenu.browserMenuShortcut': 'Tarayıcı Menüsü',

            [`chonky.actionGroups.Actions`]: 'Dosya Ayarları',
            [`chonky.actionGroups.Options`]: 'Seçenekler',
            [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: 'Klasöre Git',
            [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]: 'Klasör Oluştur',
            [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]: 'Yeni bir klasör oluştur',
            [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: 'Sil',
            [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]: 'Aç',
            [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]: 'Tümünü Seç',
            [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]: 'Seçimi Temizle',
            [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]: 'Liste Görünümü',
            [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]: 'Klasör Görünümü',
            [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]: 'Ada Göre Sırala',
            [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]: 'Boyuta Göre Sırala',
            [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]: 'Tarihe Göre Sırala',
            [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]: 'Gizlileri Göster',
            [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]: 'Önce Klasörler',
            [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.name`]: 'Dosya yükle',
            [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.tooltip`]: 'Klasöre yeni bir dosya yükle',
            [`chonky.actions.${ChonkyActions.DownloadFiles.id}.button.name`]: 'İndir',
            [`chonky.actions.${ChonkyActions.DownloadFiles.id}.button.tooltip`]: 'Yüklenen dosyayı indir'
        }
    }
}
