import React from 'react';
import PubSub from "pubsub-js";
import DataTable from 'react-data-table-component';
import {Link, withRouter} from "react-router-dom";
import {MessageTypes} from "../../../models/Message";
import LoadingView from "../../../components/common/LoadingView";
import EmptyView from "../../../components/common/EmptyView";
import MediaRowDetail from "../../../components/lists/MediaRowDetail";
import {mediaTableColumnsTest, setPageTitle} from "../../../helpers/LayoutHelper";
import {getAnnouncements,getAnnouncementsByOffset} from "../../../services/api";
import {customMediaTableStyles, mediaTableColumns,mediaTableColumnsDraft} from "../../../helpers/LayoutHelper";
import CreatePopupModal from "../../../components/modals/CreatePopupModal";
import {Button, Form, Grid, Modal} from "semantic-ui-react";
import {Formik} from "formik";
import PopupSchema from "../../../models/validations/PopupFormValidations";
import { Form as FormikForm } from 'formik';
import * as API from "../../../services/api";
import CanvasManager from "../../../tools/CanvasManager";
import InitialMediaModel from "../../../models/constants/InitialMediaModel";

import SurveyScheme from '../../../models/validations/SurveyFormValidations';
import {protectedApi} from "../../../services/axios";
import Swal from "sweetalert2";

const CM = new CanvasManager();

class ListMedia extends React.Component {
    editorContent = null;
    editorContainer = null;

    constructor(props) {
        super(props);

        this.state = {
            media: null,
            mediaNamespace : null,
            mediaType : props.match.params.type,
            action : props.match.params.action,
            loaded: false,
            listPending: [],
            listSent: [],
            listDraft: [],
            listTest: [],
            item:{
                title:"Pop-up Mesajı"
            },
            open: false,
            openAnalysis:false,
            folders: [],
            templates: [],
            title:null,
            draftPageCount:0,
            sentPageCount:0,
            testPageCount:0,
            pendingPageCount:0,
        }

        this.subscribeToken = PubSub.subscribe('DATECHANGED', this.subscribeDate);
    }

    /* Standart methods for all pages */
    componentDidMount(){
        PubSub.publish('MENU', 'medias');

        if (!window.selectedPane){
            window.selectedPane="sent"
        }

        this.updatePageTitle();

        PubSub.subscribe('delete-published-test',this.getTestId)
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.type !== this.state.mediaType){
            this.updatePage(nextProps);
        }

    }

    componentWillMount(){
        this.updatePage(this.props);
    }

    updatePage = async(props) => {
        let media = MessageTypes[props.match.params.type];

        this.setState({
            media: media,
            mediaNamespace : media?.namespace,
            mediaType : props.match.params.type,
            action : props.match.params.action,
            loaded: false
        }, () => {
            this.updatePageTitle();
            this.getListBySent(1,"",true)
            //this.getList();
        });
    };

    updatePageTitle = () => {
        // if(this.state.media === null){
        //     this.props.history.push("/dashboard");
        //     return
        // }
        if(this.state.media?.title){
            setPageTitle(this.state.media.title);
        }else {
            this.props.history.push("/dashboard");
        }
    };

    subscribeDate = (msg, data) => {

    };
    /* Standart methods for all pages */

    getList = async () => {
        let announcements = await getAnnouncements(this.state.mediaNamespace, this.state.mediaType);

        if(Array.isArray(announcements) && announcements.length > 0){
            let pendingArr = announcements.filter((item) => {return item.isPublished === 'PENDING' && item.isVisible === true});
            let draftArr = announcements.filter((item) => {return item.isPublished === 'DRAFT'});
            let sentArr = announcements.filter((item) => {return item.isPublished === 'SENT' && item.isVisible === true});
            let testArr = announcements.filter((item) => {return item.isPublished === 'TEST' && item.isVisible === true});

            this.setState({
                loaded: true,
                listPending: pendingArr,
                listSent: sentArr,
                listDraft: draftArr,
                listTest: testArr
            });
        }else{
            this.setState({
                loaded: true,
                listPending: [],
                listSent: [],
                listDraft: [],
                listTest: []
            });
        }
    }

    getListByDraft = async (newPerPage, page,isFirst) =>{
        let draftArr = []
        let offset = newPerPage===1?0:(newPerPage-1)*10+(newPerPage-1)
        if (newPerPage>this.state.draftPageCount || isFirst){
            this.setState({loaded:false})
            draftArr = await getAnnouncementsByOffset(this.state.mediaNamespace, this.state.mediaType,11,offset,"DRAFT")
            this.setState({loaded:true,listDraft:[...this.state.listDraft,...draftArr],draftPageCount:this.state.draftPageCount+1})
        }
    }

    getListBySent = async (newPerPage, page,isFirst) =>{
        let sentArr = []
        let offset = newPerPage===1?0:(newPerPage-1)*10+(newPerPage-1)
        if (newPerPage>this.state.sentPageCount || isFirst){
            this.setState({loaded:false})
            sentArr = await getAnnouncementsByOffset(this.state.mediaNamespace, this.state.mediaType,11,offset,"SENT")
            if(typeof sentArr !== "object"){sentArr = []}
            this.setState({loaded:true,listSent:[...this.state.listSent,...sentArr],sentPageCount:this.state.sentPageCount+1})
        }

    }
    getListByTest = async (newPerPage, page,isFirst) =>{
        let testArr = []
        let offset = newPerPage===1?0:(newPerPage-1)*10+(newPerPage-1)
        if (newPerPage>this.state.testPageCount || isFirst){
            this.setState({loaded:false})
            testArr = await getAnnouncementsByOffset(this.state.mediaNamespace, this.state.mediaType,11,offset,"TEST")
            this.setState({loaded:true,listTest:[...this.state.listTest,...testArr],testPageCount:this.state.testPageCount+1})
        }

    }
    getListByPending = async (newPerPage, page,isFirst) =>{
        let pendingArr = []
        let offset = newPerPage===1?0:(newPerPage-1)*10+(newPerPage-1)
        if (newPerPage>this.state.pendingPageCount || isFirst){
            this.setState({loaded:false})
            pendingArr = await getAnnouncementsByOffset(this.state.mediaNamespace, this.state.mediaType,11,offset,"PENDING")
            this.setState({loaded:true,listPending:[...this.state.listPending,...pendingArr],pendingPageCount:this.state.pendingPageCount+1})
        }

    }

    setOpen = (state) => {
        if (state) {
            this.loadData().then(() => {

            });
        }
        this.setState({ open: state });
    }
    setOpenAnalysis = (state) => {
        if (state) {
            this.loadData().then(() => {

            });
        }
        this.setState({ openAnalysis: state });
    }

    async loadData() {

        const [folders, templates] = await Promise.all([
            API.getFolders(),
            API.getTemplates(this.state.mediaType)
        ]);

        this.setState({ folders, templates });
    }

    onSubmit = (values, actions) => {

        setTimeout(() => {
            actions.setSubmitting(false);
            this.setOpen(false);

            values["pageTitle"] = this.state.media.title;
            this.props.history.push(`/publish/${this.state.mediaType.toLowerCase()}`, values);


        }, 400);
    }
    onSubmitAnalysis = (values, actions) => {

        setTimeout(() => {
            actions.setSubmitting(false);
            this.setOpen(false);

            values["pageTitle"] = this.state.media.title;
            this.props.history.push(`/publish/${this.state.mediaType.toLowerCase()}`, values);


        }, 400);
    }

    loadTemplate(id) {
        API.getTemplate(id).then(response => {
            if (response && this.editorContent) {
                this.editorContent.setComponents('');
                this.editorContent.setStyle('');
                if (this.editorContainer)
                    this.editorContainer.style.display = 'none';

                if (response.templateJson.content) {
                    if (response.templateJson.content.html) {
                        this.editorContent.setComponents(CM.GetLiquidTemplateContent(response.templateJson.content.html));
                        if (this.editorContainer)
                            this.editorContainer.style.display = 'block';
                    }

                    if (response.templateJson.content.css) {
                        this.editorContent.setStyle(response.templateJson.content.css);
                        if (this.editorContainer)
                            this.editorContainer.style.display = 'block';
                    }
                }

                const updateComp = (component) => {
                    if (component) {
                        component.set({
                            removable: false,
                            editable: false,
                            selectable: false,
                            highlightable: false,
                            hoverable: false
                        });
                        component.get('components').each(c => updateComp(c))
                    }
                }
                this.editorContent.getComponents().each(c => updateComp(c))
            }
        });
    }

    handleCanvasContainer = (e) => {
        this.editorContainer = e;
        this.editorContent = CM.CreateCanvas('preview-design-content');
        CM.AppendDefaultHeadLinks();
        if (this.editorContainer)
            this.editorContainer.style.display = 'none';
    }

    getTestId = (msg,data) => {
        this.deletePublishedTest(data)
    }

    deletePublishedTest = (id) => {
        const payload={
            "isVisible":false
        }
        protectedApi.put("/announcement/announcement/"+id,payload).then(response => {
            Swal.fire({
            icon: 'success',
            text: 'Başarıyla silindi',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: '#3B4182',
            cancelButtonText: "Tamam",
            focusCancel: false,

        })
            this.setState({loaded:false})
        this.getList()
        })
            .catch(err => {Swal.fire({
            icon: 'warning',
            text: 'Silme işlemi başarısız.',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: '#3B4182',
            cancelButtonText: "Tamam",
            focusCancel: false,

        })
            this.setState({loaded:false})
            this.getList()
        })
    }

    render(){
        const model = Object.assign(InitialMediaModel, { type: this.state.mediaType, announcementType: "MESSAGE" });
        const modelAnalysis = Object.assign(InitialMediaModel, { type: this.state.mediaType, announcementType: "ANALYSIS" });
        console.log(this.state.title)
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body px-4 py-3 py-md-2">
                                <div className="row align-items-center">
                                    <div className="col-sm-12 col-md-7">
                                        <ul className="nav nav-pills review-tab">
                                            <li className="nav-item">
                                                <a href="#list-draft"
                                                   className={`nav-link px-2 px-lg-3 ${window.selectedPane === "draft" ? "active" : ""}`}
                                                   data-toggle="tab" aria-expanded="false"
                                                   onClick={() => {
                                                       this.setState({draftPageCount:0,listDraft:[]})
                                                       this.getListByDraft(1,"",true)
                                                       window.selectedPane = "draft"
                                                   }}>Taslaklar</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#list-pending"
                                                   className={`nav-link px-2 px-lg-3 ${window.selectedPane === "pending" ? "active" : ""}`}
                                                   data-toggle="tab" aria-expanded="false"
                                                   onClick={() => {
                                                       this.setState({pendingPageCount:0,listPending:[]})
                                                       this.getListByPending(1,"",true)
                                                       window.selectedPane = "pending"}}>Planlanmış</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#list-test"
                                                   className={`nav-link px-2 px-lg-3 ${window.selectedPane === "test" ? "active" : ""}`}
                                                   data-toggle="tab" aria-expanded="false"
                                                   onClick={() => {
                                                       this.setState({testPageCount:0,listTest:[]})
                                                       this.getListByTest(1,"",true)
                                                       window.selectedPane = "test"}}>Test</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#list-sent"
                                                   className={`nav-link px-2 px-lg-3 ${window.selectedPane === "sent" ? "active" : ""}`}
                                                   data-toggle="tab" aria-expanded="false"
                                                   onClick={() => {
                                                       this.setState({sentPageCount:0,listSent:[]})
                                                       this.getListBySent(1,"",true)
                                                       window.selectedPane = "sent"}}>Yayımlanmış</a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-sm-12 col-md-5 text-md-right mt-md-0 mt-4">
                                        <button className="btn btn-primary rounded btn-sm px-4" onClick={()=> this.state.mediaType==="POPUP"||this.state.mediaType==="TICKER"||this.state.mediaType==="LCV"||this.state.mediaType==="NEWSLETTER"||this.state.mediaType==="WALLPAPER"||this.state.mediaType==="SCREENSAVER"||this.state.mediaType==="LOCK"||this.state.mediaType==="EMERGENCY"?this.setOpen(true):this.setOpenAnalysis(true)}> Oluştur</button>
                                        {/*<Link to={`/media/add/${this.state.mediaType}`} className="btn btn-primary rounded btn-sm px-4">{this.state.media.create_title}</Link>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*{this.state.mediaType==="POPUP"?*/}
                    {/*<CreatePopupModal notDashboard={true} item={this.state.item} history={this.props.history} pushPopup={this.pushPopup}/>*/}
                    {/*:*/}
                    {/*null*/}
                    {/*}*/}
                    <div className="col-xl-12">
                        <div className="tab-content">
                            <div id="list-pending" className={`tab-pane ${window.selectedPane==="pending"?"active":""}`}>
                                <div className="dataTablesCard">
                                    <DataTable
                                        title=""
                                        defaultSortField="startDate"
                                        defaultSortAsc={false}
                                        customStyles={customMediaTableStyles}
                                        noHeader={true}
                                        selectableRows={false}
                                        highlightOnHover={true}
                                        fixedHeader={true}
                                        responsive={true}
                                        expandableRows={true}
                                        progressPending={!this.state.loaded}
                                        expandableRowsComponent={<MediaRowDetail/>}
                                        progressComponent={<LoadingView/>}
                                        noDataComponent={<EmptyView/>}
                                        columns={mediaTableColumns}
                                        data={this.state.listPending}
                                        onChangePage={this.getListByPending}
                                        actions={null}
                                        contextActions={null}
                                        pagination={true}
                                        paginationComponentOptions={
                                            { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }
                                        }
                                    />
                                </div>
                            </div>
                            <div id="list-sent" className={`tab-pane ${window.selectedPane==="sent"?"active":""}`}>
                                <div className="dataTablesCard">
                                    <DataTable
                                        title=""
                                        defaultSortField="startDate"
                                        defaultSortAsc={false}
                                        customStyles={customMediaTableStyles}
                                        noHeader={true}
                                        selectableRows={false}
                                        highlightOnHover={true}
                                        fixedHeader={true}
                                        responsive={true}
                                        expandableRows={true}
                                        progressPending={!this.state.loaded}
                                        expandableRowsComponent={<MediaRowDetail/>}
                                        progressComponent={<LoadingView/>}
                                        noDataComponent={<EmptyView/>}
                                        onChangePage={this.getListBySent}
                                        columns={mediaTableColumns}
                                        data={this.state.listSent}
                                        pagination={true}
                                        paginationComponentOptions={
                                            { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }
                                        }
                                    />
                                </div>
                            </div>
                            <div id="list-test" className={`tab-pane ${window.selectedPane==="test"?"active":""}`}>
                                <div className="dataTablesCard">
                                    <DataTable
                                        title=""
                                        defaultSortField="startDate"
                                        defaultSortAsc={false}
                                        customStyles={customMediaTableStyles}
                                        noHeader={true}
                                        selectableRows={false}
                                        highlightOnHover={true}
                                        fixedHeader={true}
                                        responsive={true}
                                        expandableRows={true}
                                        progressPending={!this.state.loaded}
                                        expandableRowsComponent={<MediaRowDetail/>}
                                        progressComponent={<LoadingView/>}
                                        noDataComponent={<EmptyView/>}
                                        onChangePage={this.getListByTest}
                                        columns={mediaTableColumnsTest}
                                        data={this.state.listTest}
                                        pagination={true}
                                        paginationComponentOptions={
                                            { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }
                                        }
                                    />
                                </div>
                            </div>
                            <div id="list-draft" className={`tab-pane ${window.selectedPane==="draft"?"active":""}`}>
                                <div className="dataTablesCard">
                                    <DataTable
                                        title=""
                                        defaultSortField="startDate"
                                        defaultSortAsc={false}
                                        customStyles={customMediaTableStyles}
                                        noHeader={true}
                                        selectableRows={false}
                                        highlightOnHover={true}
                                        fixedHeader={true}
                                        responsive={true}
                                        expandableRows={true}
                                        progressPending={!this.state.loaded}
                                        expandableRowsComponent={<MediaRowDetail/>}
                                        progressComponent={<LoadingView/>}
                                        noDataComponent={<EmptyView/>}
                                        onChangePage={this.getListByDraft}
                                        columns={mediaTableColumnsDraft}
                                        data={this.state.listDraft}
                                        pagination={true}
                                        paginationComponentOptions={
                                            { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    onClose={() => this.setOpen(false)}
                    onOpen={() => this.setOpen(true)}
                    open={this.state.open}
                    dimmer='inverted'
                >
                    <Formik
                        initialValues={model}
                        onSubmit={this.onSubmit}
                        validationSchema={this.state.mediaType!=="TICKER"?PopupSchema:SurveyScheme}
                    >
                        {props => {
                            const handleChange = (e, { name, value }) => {
                                props.setFieldValue(name, value)
                            };
                            const onTemplateChange = (e, { name, value }) => {
                                if (this.state.mediaType==="EMERGENCY"){
                                    props.setFieldValue('folder', '12')
                                }
                                props.setFieldValue(name, value);
                                this.loadTemplate(value);
                            };
                            return (
                                <React.Fragment>
                                    <Modal.Header>{this.state.media.title}</Modal.Header>
                                    <Modal.Content>
                                        <Grid padded>
                                            <Grid.Row columns={2} divided="horizantally">
                                                <Grid.Column textAlign="left" verticalAlign="top">
                                                    <FormikForm className="ui form" >
                                                        {this.state.mediaType!=="TICKER" ?
                                                            <Form.Select
                                                                fluid
                                                                label="Şablon"
                                                                name="template"
                                                                onChange={onTemplateChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.template}
                                                                options={this.state.templates.map((m) => { return { key: m.id, value: m.id, text: m.name } })}
                                                                placeholder='Şablon seçiniz'
                                                                className={props.errors.template && props.touched.template ? 'error' : ''}
                                                            />
                                                        :
                                                        null
                                                        }

                                                        {this.state.mediaType!=="EMERGENCY"?
                                                            <Form.Select
                                                                fluid
                                                                label="Klasör"
                                                                name="folder"
                                                                onChange={handleChange}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.folder}
                                                                options={this.state.folders.map((m) => { return { key: m.id, value: m.id, text: m.name } })}
                                                                placeholder='Klasör seçiniz'
                                                                className={props.errors.folder && props.touched.folder ? 'error' : ''}
                                                            />
                                                        :
                                                            null
                                                        }


                                                        <Form.Input
                                                            label="Başlık"
                                                            name="title"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.title}
                                                            placeholder="Gönderinizin başlığını bu alana yazınız."
                                                            className={props.errors.title && props.touched.title ? 'error' : ''}
                                                        />
                                                    </FormikForm>
                                                </Grid.Column>

                                                <Grid.Column textAlign="left" verticalAlign="top">
                                                    {props.values.template?

                                                        <div className="preview-canvas" style={{paddingTop:"-40px"}}>
                                                            <div ref={this.handleCanvasContainer} id="preview-design-content" style={{ display: 'none' }}>

                                                            </div>
                                                        </div>

                                                        :

                                                        <div className="previewTemp">
                                                            <div className="previewTag">
                                                                Ön İzleme
                                                            </div>
                                                        </div>

                                                    }
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button type="button" onClick={() => this.setOpen(false)}>
                                            İptal
                                        </Button>
                                        <Button
                                            onClick={props.handleSubmit}
                                            disabled={props.isSubmitting}
                                            type="submit"
                                            content="Başla"
                                            className='modalStartButtonColor'
                                        />
                                    </Modal.Actions>
                                </React.Fragment>
                            )
                        }}
                    </Formik>
                </Modal>
                <Modal
                    onClose={() => this.setOpenAnalysis(false)}
                    onOpen={() => this.setOpenAnalysis(true)}
                    open={this.state.openAnalysis}
                    dimmer='inverted'
                >
                    <Formik
                        initialValues={modelAnalysis}
                        onSubmit={this.onSubmitAnalysis}
                        validationSchema={SurveyScheme}
                    >
                        {props => {
                            const handleChange = (e, { name, value }) => props.setFieldValue(name, value);
                            const onTemplateChange = (e, { name, value }) => {
                                props.setFieldValue(name, value);
                                this.loadTemplate(value);
                            };
                            return (
                                <React.Fragment>
                                    <Modal.Header>{this.state.media.title}</Modal.Header>
                                    <Modal.Content>
                                        <Grid padded>
                                            <Grid.Row columns={2} divided="horizantally">
                                                <Grid.Column textAlign="left" verticalAlign="top">
                                                    <FormikForm className="ui form" >


                                                        <Form.Select
                                                            fluid
                                                            label="Klasör"
                                                            name="folder"
                                                            onChange={handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.folder}
                                                            options={this.state.folders.map((m) => { return { key: m.id, value: m.id, text: m.name } })}
                                                            placeholder='Klasör seçiniz'
                                                            className={props.errors.folder && props.touched.folder ? 'error' : ''}
                                                        />

                                                        <Form.Input
                                                            label="Başlık"
                                                            name="title"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.title}
                                                            placeholder="Gönderinizin başlığını bu alana yazınız."
                                                            className={props.errors.title && props.touched.title ? 'error' : ''}
                                                        />
                                                    </FormikForm>
                                                </Grid.Column>

                                                <Grid.Column textAlign="left" verticalAlign="top">
                                                    {props.values.template?

                                                        <div className="preview-canvas" style={{paddingTop:"-40px"}}>
                                                            <div ref={this.handleCanvasContainer} id="preview-design-content" style={{ display: 'none' }}>

                                                            </div>
                                                        </div>

                                                        :

                                                        <div className="previewTemp">
                                                            <div className="previewTag">
                                                                Ön İzleme
                                                            </div>
                                                        </div>

                                                    }
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button type="button" onClick={() => this.setOpenAnalysis(false)}>
                                            İptal
                                        </Button>
                                        <Button
                                            onClick={props.handleSubmit}
                                            disabled={props.isSubmitting}
                                            type="submit"
                                            content="Başla"
                                            className='modalStartButtonColor'
                                        />
                                    </Modal.Actions>
                                </React.Fragment>
                            )
                        }}
                    </Formik>
                </Modal>
            </div>
        )
    }
}

export default withRouter(ListMedia);
