import React from 'react';

class LoadingView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{padding: 25, textAlign: "center"}}>
                <img src="/images/sendy.gif"/>
                <br/>
                <h4>Yükleniyor&hellip;</h4>
            </div>
        );
    }
}

export default LoadingView;
