import React from 'react';

const PreviewFrame = (props) => {
    let iref = null;
    const writeHTML = (frame) => {

        if (!frame) {
            return;
        }

        iref = frame;
        let doc = frame.contentDocument;
        doc.open();
        doc.write(props.content);
        doc.close();
        frame.style.width = '100%';
        frame.style.height = '100%'; //`${frame.contentWindow.document.body.scrollHeight}px`;

        /*
        var link1 = document.createElement("link");
        link1.href = "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
        link1.rel = "stylesheet";
        doc.getElementsByTagName('head')[0].append(link1);
*/

        var link2 = document.createElement("link");
        link2.href = window.location.protocol + "//" + window.location.host + "/templates/css/template.css";
        link2.rel = "stylesheet";
        doc.getElementsByTagName('head')[0].append(link2);

        var link3 = document.createElement("link");
        link3.href = window.location.protocol + "//" + window.location.host + "/templates/css/previewFrame.css";
        link3.rel = "stylesheet";
        doc.getElementsByTagName('head')[0].append(link3);
    };

    return <iframe src="about:blank" id="iframe-preview" style={{ overflowY: 'hidden', overflowX: 'hidden' }} scrolling="yes" frameBorder="0" ref={writeHTML} />
};

export default PreviewFrame;
