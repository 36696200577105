import React from 'react';
import PubSub from "pubsub-js";
import {setPageTitle} from "../../helpers/LayoutHelper";
import settings from "../../config/settings.json";
import {Link} from "react-router-dom";
import {Accordion} from "semantic-ui-react";

class HelpFaq extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "Yardım"
        }
    }

    /* Standart methods for all pages */
    componentDidMount(){
        this.updatePageTitle();

        PubSub.publish('MENU', '');
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount(){
        this.updatePage(this.props);
    }

    updatePage = async(props) => {
        this.updatePageTitle();
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {

    };
    /* Standart methods for all pages */

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const activeIndex = this.state.activeIndex
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <h2>Sıkça Sorulan Sorular</h2>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-4 col-lg-3">
                                <div className="menu-lg">
                                    <div className="item">
                                        <img className="float-left" src="/images/menu/menu_icon_medias_alt.svg" width={30} height={30}/>
                                        <div className="content">
                                            <div className="title"><Link to="/help/howto">Teknik Bilgiler</Link></div>
                                            <div className="description">
                                                Duyuroom'un tüm özelliklerini keşfedin.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <i className="fa fa-unlock-alt duyuroom-text" />
                                        <div className="content">
                                            <div className="title"><Link to="/help/gdpr">Veri Güvenliği</Link></div>
                                            <div className="description">
                                                Kişisel veri koruma politikamız hakkında detaylı bilgi edinin.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <i className="fa fa-phone duyuroom-text" />
                                        <div className="content">
                                            <div className="title"><Link to="/help/contact">Bize Ulaşın</Link></div>
                                            <div className="description">
                                                Tüm görüş, öneri ve destek taleplerinizi hızlıca bize iletin.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-8 col-lg-9">
                                <div className="panel-group" id="faqList">
                                    {settings.faq.map((qa, index) => {
                                        return(
                                            <>
                                                <Accordion>
                                                    <Accordion.Title
                                                        active={this.state.activeIndex === index}
                                                        index={index}
                                                        onClick={this.handleClick}
                                                    >
                                                        <i className="la la-plus"/>
                                                        {qa.question}
                                                    </Accordion.Title>
                                                    <Accordion.Content active={this.state.activeIndex === index}>
                                                        <p>
                                                            {qa.answer}
                                                        </p>
                                                    </Accordion.Content>
                                                </Accordion>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpFaq
