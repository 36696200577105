import React from "react";
import Question from "./Question";
import ListController from "./ListController";
import styled from "styled-components";
import EmojiInput from "../emoji/EmojiInput";
import {Editor} from "@tinymce/tinymce-react";
import settings from "../../config/settings.json";
import {protectedApi} from "../../services/axios";

// https://github.com/jpuri/react-draft-wysiwyg

export default function QuestionForm({ question, setQuestion }) {
    let listController = new ListController(question.options, setOptions);

    function handleChangeText(e) {
        setQuestion(question.merge({ text: e })); // e.target.value
    }

    function handleChangeType(e) {
        setQuestion(question.merge({ type: e.target.value, cols: e.target.value === Question.TYPES.RANGE1 ? ["Kötü","Orta","İyi"] : [], rows: e.target.value === Question.TYPES.RANGE1 ? ["1", "2", "3"] : [],  options: e.target.value === Question.TYPES.YESNO ? ["Evet", "Hayır"] : e.target.value === Question.TYPES.POLARIZATION ? ["",""] : question.options }));
    }

    function setOptions(options) {
        setQuestion(question.merge({ options }));
    }

    function addRow(){
        let rows = [...question.rows];

        rows.push("");

        setQuestion(question.merge({ rows }))
    }


    function removeRow(i){
        let rows = [...question.rows];

        console.log(rows);

        rows.splice(i, 1);

        console.log(rows);

        setQuestion(question.merge({ rows }))
    }

    function addOnlyColumn(){
        let cols = [...question.cols];

        cols.push("");

        setQuestion(question.merge({ cols }))
    }

    function updateRow(val, i){
        let rows = [...question.rows];

        rows[i] = val;

        setQuestion(question.merge({ rows }))
    }

    function removeOnlyColumn(i){
        let cols = [...question.cols];

        cols.splice(i, 1);

        setQuestion(question.merge({ cols }))
    }

    function addColumn(){
        let cols = [...question.cols];
        let rows = [...question.rows];
        let previousVal = rows[rows.length - 1] ?  parseInt(rows[rows.length - 1]) : 0;

        cols.push("Yeni Sütun");
        rows.push(previousVal + 1);

        setQuestion(question.merge({ cols, rows }))
    }

    function removeColumn(i){
        let cols = [...question.cols];
        let rows = [...question.rows];

        cols.splice(i, 1);
        rows.splice(i, 1);

        setQuestion(question.merge({ cols, rows }))
    }

    function updateColText(e, i){
        let cols = [...question.cols];

        cols[i] = e;

        setQuestion(question.merge({ cols }))
    }

    function updateColVal(e, i){
        let rows = [...question.rows];

        rows[i] = e;

        setQuestion(question.merge({ rows }))
    }

    function getEditor (type, placeholder,index,val) {

        return(
            <section style={{ width: "100%", display: "table",marginTop:"5px"}} >
                <div style={{display: "table-cell", width: "100%", verticalAlign:"middle"}}>
                    <Editor
                        apiKey="4wp2oams7a8jp489lzu8ywwx16edfuxlxrhjchhl7vsbafut"
                        value={val}
                        style={{border:"1px solid"}}
                        init={{
                            selector: 'textarea#myTextArea',
                            height: 120,
                            menubar: false,
                            inline: true,
                            toolbar_mode: "sliding",
                            language: "tr",
                            language_url: '/js/tr.js',
                            placeholder: placeholder,
                            automatic_uploads: true,
                            image_uploadtab: true,
                            image_caption: false,
                            image_advtab: false,
                            image_description: false,
                            image_dimensions: true,
                            content_css : "/css/editor.css",
                            file_browser_callback_types: 'image media',
                            contextmenu: false,
                            images_upload_url: settings.apiBase + 'announcement/fileuploads',
                            images_upload_base_path: settings.apiBase,
                            images_upload_credentials: false,
                            browser_spellcheck: true,
                            force_br_newlines: false,
                            force_p_newlines: false,
                            forced_root_block: '',
                            images_upload_handler: function (blobInfo, success, failure, progress) {
                                let data = new FormData();
                                let config = {header: {'Content-Type': 'multipart/form-data'}}

                                data.append('file', blobInfo.blob(), blobInfo.filename());

                                protectedApi.post('/announcement/fileuploads', data, config).then(response => {
                                    success(settings.apiBaseClear + response.data.file);
                                }).catch(error => {
                                    failure(error);
                                })
                            },
                            plugins: [
                                'emoticons'
                            ],
                            toolbar:
                                'emoticons'
                        }}
                        onEditorChange={(content, editor) => {
                            if (type==='colText'){
                                updateColText(content, index)
                            }else if (type==='question'){
                                setQuestion(question.merge({ text: content }))
                            }else if(type==="row"){
                                updateRow(content, index)
                            }else if (type==='colVal'){
                                updateColVal(content, index)
                            }else if (type==='pol' || type ==='list'){
                                listController.set(index, content)
                            }
                            console.log("content",content)
                            console.log("editor",editor)
                            console.log("option",question)

                            // hack the editor to enable spellcheck
                            editor.bodyElement.spellcheck = true;
                        }}

                    />
                </div>

            </section>
        )
    }

    return (
        <div className="ui form">
            <select
                id="question-type"
                value={question.type !== null ? question.type : null}
                style={{marginTop: 5}}
                onChange={handleChangeType}>
                <option key="typeOfQuestion" hidden value={null}>Soru Tipi Seçiniz</option>
                {Object.values(Question.TYPES).map(type => (
                    <option key={type} value={type}>
                        {type}
                    </option>
                ))}
            </select>
            {question.type!==null&&
            getEditor('question', 'Sorunuzu bu alana yazınız.',"",question.text)}
            {/*<EmojiInput rows={2} value={question.text}*/}
            {/*            placeholder="Sorunuzu bu alana yazınız, emoji eklemek için iki nokta ile başlayınız, örneğin :apple"*/}
            {/*            onChange={handleChangeText}/>}*/}

            {question.type === Question.TYPES.RANGEN &&
            <>
                <p>
                    <button className="btn btn-sm btn-light" onClick={() => addOnlyColumn()}>+ Sütun Ekle</button>
                    &nbsp;&nbsp;
                    <button className="btn btn-sm btn-light" onClick={() => addRow()}>+ Satır Ekle</button>
                </p>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th/>
                        {question.cols.map((col, i) => {
                            return(
                                <th style={{padding:2, textAlign: "center"}}>
                                    <button className="btn btn-xs" onClick={() => removeOnlyColumn(i)}><i className="fa fa-trash" /></button>
                                </th>
                            )
                        })}
                    </tr>
                    <tr>
                        <th style={{background: '#e1e1e1'}}/>
                        {question.cols.map((col, i) => {
                            return(
                                <th style={{padding:2, background: '#e1e1e1'}}>
                                    {getEditor('colText', 'Seçenek belirtiniz.',i,col)}
                                    {/*<EmojiInput input onChange={e => updateColText(e, i)} value={col}/>*/}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                        {question.rows.map((row, i) => {
                            return (
                                <tr key={`myFairyRow${i}`}>
                                    <td style={{padding:2}}>
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <button className="float-left" style={{border: 'none', background: 'white', paddingTop: 8}} onClick={() => removeRow(i)}><i className="fa fa-trash" /></button>
                                            </div>
                                            <div className="col">
                                                {getEditor('row', 'Seçenek belirtiniz.',i,row)}
                                                {/*<EmojiInput className="ml-1" input onChange={e => updateRow(e, i)} value={row}/>*/}
                                            </div>
                                        </div>
                                    </td>
                                    {question.cols.map((v, i) => {
                                        return(
                                            <td style={{padding:2}}>
                                                <EmojiInput className="disabled" input value={i+1} disabled={true}/>
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </>
            }


            {question.type === Question.TYPES.RANGE1 &&
                <>
                    <p>
                        <button className="btn btn-sm btn-light" onClick={() => addColumn()}>+ Likert Ölçeği Ekle</button>
                    </p>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            {question.cols.map((col, i) => {
                                return(
                                    <th style={{padding:2, textAlign: "center"}}>
                                        <button className="btn btn-xs" onClick={() => removeColumn(i)}><i className="fa fa-trash" /></button>
                                    </th>
                                )
                            })}
                        </tr>
                        <tr>
                            {question.cols.map((col, i) => {
                                return(
                                    <th style={{padding:2}}>
                                        {getEditor('colText', 'Seçenek belirtiniz.',i,col)}
                                        {/*<EmojiInput input onChange={e => updateColText(e, i)} value={col}/>*/}
                                    </th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {question.rows.map((row, i) => {
                                return(
                                    <td style={{padding:2}}>
                                        {getEditor('colVal', 'Seçenek belirtiniz.',i,row)}
                                        {/*<EmojiInput input onChange={e => updateColVal(e, i)} value={row}/>*/}
                                    </td>
                                )
                            })}
                        </tr>
                        </tbody>
                    </table>
                </>
            }


            {question.type === Question.TYPES.POLARIZATION &&
                <>
                    <br/>
                    <div className="row">
                        <div className="col-4">
                            {getEditor('pol', 'Seçenek belirtiniz.',0,question.options[0])}
                            {/*<EmojiInput input onChange={e => listController.set(0, e)} value={question.options[0]}/>*/}
                        </div>
                        <div className="col-4">
                            <input disabled={true} type="range" min={-100} max={100} defaultValue={0} style={{width: '100%', marginTop: 8}}/>
                        </div>
                        <div className="col-4">
                            {getEditor('pol', 'Seçenek belirtiniz.',1,question.options[1])}
                            {/*<EmojiInput input onChange={e => listController.set(1, e)} value={question.options[1]}/>*/}
                        </div>
                    </div>

                </>
            }

            {question.hasOptions && (
                <div style={{marginTop: 10}}>

                    {question.type !== Question.TYPES.YESNO &&
                    <p>
                        <button className="btn btn-sm btn-light" onClick={() => listController.add("")}>+ Seçenek Ekle</button>
                    </p>
                    }

                    {question.options.map((option, i) => (
                        <Option key={i}>
                            {getEditor('list', 'Seçenek belirtiniz.',i,option)}
                            {/*<EmojiInput*/}
                            {/*    className="w-100"*/}
                            {/*    input={true}*/}
                            {/*    type="text"*/}
                            {/*    placeholder="Seçenek belirtiniz."*/}
                            {/*    name={option}*/}
                            {/*    value={option}*/}
                            {/*    onChange={e => listController.set(i, e)} // e.target.value*/}
                            {/*/>*/}
                            <Buttons>
                                <button className="btn btn-xs" onClick={() => listController.moveUp(i)}>
                                    <i className="fa fa-angle-up" />
                                </button>
                                <button className="btn btn-xs" onClick={() => listController.moveDown(i)}>
                                    <i className="fa fa-angle-down" />
                                </button>
                                <button className="btn btn-xs" onClick={() => listController.remove(i)}>
                                    <i className="fa fa-trash" />
                                </button>
                            </Buttons>
                        </Option>
                    ))}

                </div>
            )}
        </div>
    );
}

const Option = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
