import React from 'react';

class PreviewWindow extends React.Component {
    constructor(props) {
        super(props);

        this.width = this.props.width
        this.height = this.props.height
    }

    render(){
        return (
            <>
                {this.props.mediaType==="WALLPAPER" || this.props.mediaType==="SCREENSAVER" || this.props.mediaType==="LOCK"?
                <div style={{position: 'absolute', width: 975, height: 600, overflow: 'hidden', borderRadius: 8, backgroundColor: this.props.bgcolor }}>
                    {this.props.children}
                </div>
            :
                <div style={{position: 'absolute', marginTop: 20, marginLeft: `calc(50% - ${this.width !== undefined ? this.width/2 : '350'}px)`, width: (this.width !== undefined && this.height <900) ? this.width : 700, height: (this.height !== undefined && this.height < 560) ? this.height : 560, borderRadius: 8, backgroundColor: this.props.bgcolor }}>
                    {this.props.children}
                </div>
            }
            </>

        )
    }
}

export default PreviewWindow;
