import React, { Component } from 'react';
import PubSub from "pubsub-js";
import DataTable from "react-data-table-component";
import {
    customMediaTableStyles, documentTableArchiveColumns,
    documentTableColumns, setPageTitle,
} from "../../helpers/LayoutHelper";
import MediaRowDetail from "../../components/lists/MediaRowDetail";
import LoadingView from "../../components/common/LoadingView";
import EmptyView from "../../components/common/EmptyView";
import { Button, Form, Grid, Modal, ModalActions, ModalContent } from "semantic-ui-react";
import { createDocument, getDocuments } from "../../services/api";
import moment from "moment";
import Swal from "sweetalert2";
import CustomStorage from "../../models/CustomStorage";
import LoadingOverlay from 'react-loading-overlay';

class DocumentManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true,
            allDocuments: [],
            activeDocuments: [],
            passiveDocuments: [],

            documentName: '',
            documentDescription: '',
            documentDepartment: '',
            documentCreator: '',

            isLoading: false
        }

        this.documentFile = null

        this.cs = new CustomStorage();
    }

    componentDidMount() {
        let user = this.cs.getItem('user');
        user = JSON.parse(user).user
        user = user.first_name + " " + user.last_name
        this.setState({ documentCreator: user })

        PubSub.subscribe('load-documents', this.loadData)
        PubSub.subscribe('load-documents-2', this.loadData)

        PubSub.publish('MENU', 'documentmanagement');
        setPageTitle("Kütüphane");

        if (!window.selectedPane) {
            window.selectedPane = "sent"
        }

        this.loadData()

    }

    loadData = () => {
        getDocuments()
            .then(res => {
                res.map((data, index) => {
                    data.createdby = data.createdby.first_name + " " + data.createdby.last_name
                    data.updatedby = data.updatedby ? data?.updatedby?.first_name + " " + data?.updatedby?.last_name : ""
                })
                let activeDocuments = res.filter(data => data.documentStatus)
                let passiveDocuments = res.filter(data => !data.documentStatus)
                this.setState({
                    allDocuments: res,
                    activeDocuments: activeDocuments,
                    passiveDocuments: passiveDocuments
                })
            })
            .catch(err => {
                console.log('ERR : ', err)
            })
    }

    setOpen = (state) => {
        this.setState({ open: state });
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleCreate = () => {
        if (this.documentFile === null || this.state.documentName.length <= 2 || this.state.documentDescription.length <= 2 || this.state.documentDepartment.length <= 2) {
            Swal.fire({
                icon: 'warning',
                title: 'Lütfen bütün alanları doldurunuz.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }
        if (this.state.allDocuments.filter(document => document.documentName === this.state.documentName).length !== 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Aynı isimle bir döküman mevcut lütfen onu güncelleyiniz.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }
        this.setState({ isLoading: true })
        this.setOpen(false)

        let data = new FormData();
        data.append("documentName", this.state.documentName)
        data.append('documentFile', this.documentFile, this.documentFile.name);
        data.append('description', this.state.documentDescription);
        data.append('department', this.state.documentDepartment);
        data.append('documentStatus', true);

        createDocument(data)
            .then(res => {
                this.loadData()
                Swal.fire({
                    icon: 'success',
                    title: 'Döküman Yüklendi.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                this.setState({ isLoading: false })
                this.clearInput()
            })
            .catch(err => {
                console.log('OLMADI : ', err)
            })
    }

    clearInput = () => {
        this.setState({
            documentName: '',
            documentDescription: '',
            documentDepartment: '',
        })
    }


    render() {
        return (
            // <div className="col-xl-12 mt-3">
            //     <div id="list-users" className="tab-pane">
            //         <div className="dataTablesCard">
            //             <DataTable
            //                 title=""
            //                 defaultSortField="1"
            //                 defaultSortAsc={true}
            //                 customStyles={customMediaTableStyles}
            //                 noHeader={true}
            //                 selectableRows={false}
            //                 highlightOnHover={true}
            //                 fixedHeader={true}
            //                 responsive={true}
            //                 expandableRows={false}
            //                 progressPending={!this.state.loaded}
            //                 expandableRowsComponent={<MediaRowDetail/>}
            //                 progressComponent={<LoadingView/>}
            //                 noDataComponent={<EmptyView/>}
            //                 columns={documentTableColumns}
            //                 data={this.state.data}
            //                 pagination={true}
            //                 paginationComponentOptions={
            //                     {
            //                         rowsPerPageText: 'Listelenen:',
            //                         rangeSeparatorText: '/',
            //                         noRowsPerPage: false,
            //                         selectAllRowsItem: false,
            //                         selectAllRowsItemText: 'Tümü'
            //                     }
            //                 }
            //             />
            //         </div>
            //     </div>
            // </div>

            <div className="container-fluid">
                <LoadingOverlay
                    active={this.state.isLoading}
                    // spinner
                    // text="Doküman yükleniyor"
                    styles={{ wrapper: { width: '100%', height: '100%' } }}
                >
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body px-4 py-3 py-md-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-12 col-md-7">
                                            <ul className="nav nav-pills review-tab">
                                                <li className="nav-item">
                                                    <a href="#list-archive"
                                                        className={`nav-link px-2 px-lg-3 ${window.selectedPane === "archive" ? "active" : ""}`}
                                                        data-toggle="tab" aria-expanded="false"
                                                        onClick={() => window.selectedPane = "archive"}>Arşiv</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#list-sent"
                                                        className={`nav-link px-2 px-lg-3 ${window.selectedPane === "sent" ? "active" : ""}`}
                                                        data-toggle="tab" aria-expanded="false"
                                                        onClick={() => window.selectedPane = "sent"}>Yayımlanmış</a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="col-sm-12 col-md-5 text-md-right mt-md-0 mt-4">
                                            <button className="btn btn-primary rounded btn-sm px-4"
                                                onClick={() => this.setOpen(true)}> Oluştur
                                            </button>
                                            {/*<Link to={`/media/add/${this.state.mediaType}`} className="btn btn-primary rounded btn-sm px-4">{this.state.media.create_title}</Link>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="tab-content">
                                <div id="list-sent"
                                    className={`tab-pane ${window.selectedPane === "sent" ? "active" : ""}`}>
                                    <div className="dataTablesCard">
                                        <DataTable
                                            title=""
                                            defaultSortField="1"
                                            defaultSortAsc={true}
                                            customStyles={customMediaTableStyles}
                                            noHeader={true}
                                            selectableRows={false}
                                            highlightOnHover={true}
                                            fixedHeader={true}
                                            responsive={true}
                                            expandableRows={false}
                                            progressPending={!this.state.loaded}
                                            expandableRowsComponent={<MediaRowDetail />}
                                            progressComponent={<LoadingView />}
                                            noDataComponent={<EmptyView />}
                                            columns={documentTableColumns}
                                            data={this.state.activeDocuments}
                                            pagination={true}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Listelenen:',
                                                    rangeSeparatorText: '/',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: false,
                                                    selectAllRowsItemText: 'Tümü'
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div id="list-archive"
                                    className={`tab-pane ${window.selectedPane === "archive" ? "active" : ""}`}>
                                    <div className="dataTablesCard">
                                        <DataTable
                                            title=""
                                            defaultSortField="1"
                                            defaultSortAsc={true}
                                            customStyles={customMediaTableStyles}
                                            noHeader={true}
                                            selectableRows={false}
                                            highlightOnHover={true}
                                            fixedHeader={true}
                                            responsive={true}
                                            expandableRows={false}
                                            progressPending={!this.state.loaded}
                                            expandableRowsComponent={<MediaRowDetail />}
                                            progressComponent={<LoadingView />}
                                            noDataComponent={<EmptyView />}
                                            columns={documentTableArchiveColumns}
                                            data={this.state.passiveDocuments}
                                            pagination={true}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Listelenen:',
                                                    rangeSeparatorText: '/',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: false,
                                                    selectAllRowsItemText: 'Tümü'
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        onClose={() => this.setOpen(false)}
                        onOpen={() => this.setOpen(true)}
                        open={this.state.open}
                        dimmer='inverted'
                        size='mini'
                    >
                        <Modal.Header>Doküman Oluştur</Modal.Header>
                        <ModalContent>
                            <div className="row">
                                <div className='col-sm-4'>
                                    <label className='mt-2' style={{ marginLeft: 10 }}>Versiyon: </label>
                                </div>
                                <div className='col-sm-8'>
                                    <input
                                        name="documentVersion"
                                        id="documentVersion"
                                        className='form-control'
                                        readOnly
                                        value={1.00.toFixed(2)}
                                        style={{ height: 40, width: 200, marginLeft: 5, cursor: 'default' }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className='col-sm-4'>
                                    <label className='mt-2' style={{ marginLeft: 10 }}>Adı: </label>
                                </div>
                                <div className='col-sm-8'>
                                    <input
                                        name="documentName"
                                        id="documentName"
                                        className='form-control'
                                        value={this.state.documentName}
                                        onChange={e => this.handleInput(e)}
                                        style={{ height: 40, width: 200, marginLeft: 5, cursor: 'text' }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className='col-sm-4'>
                                    <label className='mt-2' style={{ marginLeft: 10 }}>Amacı: </label>
                                </div>
                                <div className='col-sm-8'>
                                    {/*<input*/}
                                    {/*    type="textarea"*/}
                                    {/*    name="documentDescription"*/}
                                    {/*    id="documentDescription"*/}
                                    {/*    className='form-control mt-2'*/}
                                    {/*    value={this.state.documentDescription}*/}
                                    {/*    onChange={e => this.handleInput(e)}*/}
                                    {/*    style={{ height: 40, width: 200, marginLeft: 10, marginRight: 10, cursor: 'text' }}*/}
                                    {/*/>*/}
                                    <textarea className="form-select"
                                        style={{ cursor: 'text', whiteSpace: 'pre-line', borderBottomLeftRadius: 20, borderTopLeftRadius: 20, marginLeft: 5, width: 200, paddingLeft: 20, paddingRight: 20 }}
                                        value={this.state.documentDescription.replace('\\n', '\n')}
                                        onChange={e => { this.setState({ documentDescription: e.target.value }) }}
                                        rows={3} maxLength={140} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className='col-sm-4'>
                                    <label className='mt-2' style={{ marginLeft: 10 }}>Yayımlanma Tarihi: </label>
                                </div>
                                <div className='col-sm-8'>
                                    <input
                                        name="documentCreatedDate"
                                        id="documentCreatedDate"
                                        className='form-control mt-2'
                                        value={moment().format('DD/MM/YYYY')}
                                        readOnly
                                        style={{ height: 40, width: 200, marginLeft: 5, cursor: 'text' }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className='col-sm-4'>
                                    <label className='mt-2' style={{ marginLeft: 10 }}>Sorumlu: </label>
                                </div>
                                <div className='col-sm-8'>
                                    <input
                                        name="documentCreator"
                                        id="documentCreator"
                                        className='form-control mt-2'
                                        value={this.state.documentCreator}
                                        readOnly
                                        style={{ height: 40, width: 200, marginLeft: 5, cursor: 'default' }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className='col-sm-4'>
                                    <label className='mt-2' style={{ marginLeft: 10 }}>Departman: </label>
                                </div>
                                <div className='col-sm-8'>
                                    <input
                                        name="documentDepartment"
                                        id="documentDepartment"
                                        className='form-control'
                                        value={this.state.documentDepartment}
                                        onChange={e => this.handleInput(e)}
                                        style={{ height: 40, width: 200, marginLeft: 5, cursor: 'text' }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className='col-sm-4'>
                                    <label className='mt-3' style={{ marginLeft: 10 }}>Doküman: </label>
                                </div>
                                <div className='col-sm-8'>
                                    <button className="btn mt-2 ml-2" style={{ marginLeft: -10, marginRight: 10, border: "1px solid #f3f3f3", borderRadius: 20, height: 40, width: 200 }} onClick={() => {
                                        this.upload.click();
                                    }}>
                                        <i className="fa fa-upload" /> Dosya Yükle
                                    </button>
                                    {this.state.documentFileName &&
                                        <h4 style={{ marginLeft: '20px' }}><i className="fa fa-file" /> {this.state.documentFileName}</h4>
                                    }
                                    <input
                                        name="documentFile"
                                        id="documentFile"
                                        type='file'
                                        accept=".xlsx, .docx, .pdf .txt"
                                        ref={(ref) => this.upload = ref}
                                        style={{ display: 'none' }}
                                        onChange={(event) => {
                                            this.setState({ documentFileName: event.target.files[0].name })
                                            this.documentFile = event.target.files[0]
                                        }}
                                    />
                                </div>
                            </div>
                        </ModalContent>
                        <ModalActions>
                            <button className="btn btn-primary rounded btn-sm px-4 mr-4"
                                onClick={() => this.setOpen(false)}>Vazgeç
                            </button>
                            <input type="button" className="btn btn-success rounded btn-sm float-right px-4" style={{ marginRight: 16 }} onClick={this.handleCreate} value="Kaydet" />
                        </ModalActions>
                    </Modal>

                </LoadingOverlay>
            </div>
        );
    }
}

export default DocumentManagement;