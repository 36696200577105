import React, { useState } from "react";
import QuestionForm from "./QuestionForm";
import styled from "styled-components";
import Question from "./Question";
import Swal from "sweetalert2";

export default function SurveyQuestion({
                                           index,
                                           mode,
                                           total,
                                           question,
                                           setQuestion,
                                           removeQuestion,
                                           moveQuestionUp,
                                           moveQuestionDown,
                                           show
                                       }) {
    const [editing, setEditing] = useState(true);

    function toggleEditing() {
        console.log('question içeriği : ', question)
        const tempOptions = question.options.filter(data => data === '')
        const tempCols = question.cols.filter(data => data === '')
        const tempRows = question.rows.filter(data => data === '')

        if(editing && question.text.trim() === ""){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen sorunuzu yazınız.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            });
        }else{
            if (tempOptions.length !== 0 || tempCols.length !== 0 || tempRows.length !== 0) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Lütfen seçenekleri doldurunuz!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                return;
            }
            setEditing(!editing);
        }
    }

    return (
        <QuestionField>
            {mode === "edit" && editing ? (
                <QuestionForm question={question} setQuestion={setQuestion} />
            ) : (
                <>
                    <h4>{index + 1}/{total}</h4>
                    <p style={{fontSize: 16, background: '#f1f1f1', padding: 8, borderRadius: 8}}>{question?.text}</p>
                    {show?
                        question && (
                            question.options?.map((option, i) => (
                                <>
                                    <label key={i}>
                                        <input
                                            type={question.inputType}
                                            id={option}
                                            name={option}
                                            disabled
                                        />
                                        {" "}{option}
                                    </label>
                                    <br/>
                                </>
                            ))
                        )
                    :
                        question.hasOptions && (
                                question.options?.map((option, i) => (
                                    <>
                                        <label key={i}>
                                            <input
                                                type={question.inputType}
                                                id={option}
                                                name={option}
                                                disabled
                                            />
                                            {" "}{option}
                                        </label>
                                        <br/>
                                    </>
                                ))
                            )
                    }


                    {question.type === Question.TYPES.TEXT && (
                        <div className="ui form">
                            <textarea rows={2} className="w-100 disabled" disabled={true} readOnly={true}>Kullanıcılar açık uçlu soru cevabını buraya yazacaklar.</textarea>
                        </div>
                    )}

                    {question.type === Question.TYPES.POLARIZATION && (
                        <table>
                            <tr>
                                <td>{question.options[0]}</td>
                                <td style={{paddingLeft: 20, paddingRight: 20}}><input disabled={true} type="range" min={-100} max={100} defaultValue={0} style={{width: '100%', marginTop: 8}}/></td>
                                <td>{question.options[1]}</td>
                            </tr>
                        </table>
                    )}

                    {question.type === Question.TYPES.RANGE1 && (
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    {question.cols.map((col, i) => {
                                        return(
                                            <th style={{padding:2, textAlign: "center"}}>{col}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {question.rows.map((row, i) => {
                                        return(
                                            <td style={{padding:2, textAlign: "center"}}><input type="radio" disabled={true}/> {row}</td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    )}

                    {question.type === Question.TYPES.RANGEN && (
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th/>
                                    {question.cols.map((col, i) => {
                                        return(
                                            <th style={{padding:2, textAlign: 'center'}}>
                                                {col}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                            {question.rows.map((row, i) => {
                                return (
                                    <tr key={`myFairyRow${i}`}>
                                        <td style={{padding:2}}>
                                            {row}
                                        </td>
                                        {question.cols.map((v, i) => {
                                            return(
                                                <td style={{padding:2, textAlign: 'center'}}>
                                                    <input type="radio" disabled={true}/> {i+1}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    )}

                </>
            )}

            {mode === "edit" &&
                <>
                    <br/>
                    <button className="btn btn-primary btn-sm mr-1" onClick={toggleEditing}>
                        {editing ? (
                            <>
                                Kaydet
                            </>
                        ) : (
                            <>
                                Düzenle
                            </>
                        )}
                    </button>

                    <button className="btn btn-danger btn-sm mr-2" onClick={removeQuestion}>Sil</button>
                    <button className="btn btn-light btn-sm mr-1" onClick={moveQuestionUp}><i className="fas fa-angle-up icon" /></button>
                    <button className="btn btn-light btn-sm" onClick={moveQuestionDown}><i className="fas fa-angle-down icon" /></button>
                </>
            }
        </QuestionField>
    );
}

const QuestionField = styled.li`
  margin: 5px; 
  padding: 10px;
  border: solid 1px #eaeaea;
`;


