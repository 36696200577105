import React from 'react';
import settings from "../../config/settings.json";
import Popup from "react-popup";
import MessageItem from '../../components/dashboard/MessageItem';
import BirthdayItem from "../../components/dashboard/BirthdayItem";
import { MessageTypes } from "../../models/Message";
import { setPageTitle } from "../../helpers/LayoutHelper";
import {filterMessages, filterMessagesByDate, getUsers,getProfilesByBirthDate} from "../../services/api";
import moment from 'moment';
import 'moment/locale/tr';
import PubSub from "pubsub-js";
import EmptyView from "../../components/common/EmptyView";
import {protectedApi} from "../../services/axios";
import LoadingOverlay from 'react-loading-overlay';
import LoadingView from "../../components/common/LoadingView";


class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            pageTitle: '',
            latestMessages: [],
            endDate: '',
            tableSize: 5,
            messageStatus: '',
            birthDateUsers:[]
        }
    }

    componentDidMount() {

        PubSub.publish('MENU', 'dashboard');

        setPageTitle(this.state.pageTitle);

        this.loadMessages('SENT')


        PubSub.subscribe('filterDate', this.handleCalenderDate)
    }

    handleCalenderDate = (msg, data) => {
        this.setState({endDate: data.endDate, isLoaded: false},() => {
            filterMessagesByDate('',this.state.tableSize, data.endDate)
                .then(r => this.setState({latestMessages: r, isLoaded: true}))
        })
    }


    loadMessages = (status) => {
        const format = 'YYYY-MM-DD HH:mm:ss'
        const today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date+' '+time;
        const dateEnd = moment(dateTime).endOf("day").format(format)
        this.setState({isLoaded: false, messageStatus: status, today: dateEnd}, () => {
            filterMessagesByDate(status,5, this.state.today)
                .then(r => this.setState({latestMessages: r, isLoaded: true}))

            getProfilesByBirthDate(moment().format("DD-MM")).then(res => {
                if (res)
                this.setState({birthDateUsers:res.sort((a,b)=>a.user.first_name-b.user.first_name)})
            })
            // filterMessages(status).then(list => {
            //     this.setState({latestMessages: list, isLoaded: true})
            // })
        })
    }

    handleTableSize = (status) => {
        if(status === 'LESS') {
            this.setState({tableSize: this.state.tableSize-5, isLoaded: false}, () => {
                filterMessagesByDate(this.state.messageStatus, this.state.tableSize, this.state.endDate)
                    .then(r => this.setState({latestMessages: r, isLoaded: true}))
            })
        }
        else if(status === 'MORE') {
            this.setState({tableSize: this.state.tableSize+5, isLoaded: false}, () => {
                filterMessagesByDate(this.state.messageStatus, this.state.tableSize, this.state.endDate)
                    .then(r => this.setState({latestMessages: r, isLoaded: true}))
            })
        }

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <a
                            href="https://duyuroom.com/sss/"
                            target="_blank"
                            className="btn btn-login text-uppercase mb-3 mt-3 rounded-pill shadow-sm"
                            style={{position: "absolute", bottom: 20, marginLeft: 50, background: '#F4C752'}}
                        >Detaylı bilgi için &hellip;</a>
                        <div className="welcomeHeader">
                            <h4 className="welcomeHeaderPrimary">Duyuroom'a <span className="welcomeHeaderSecondary">hoş geldiniz!</span></h4>
                            <br/>
                            <p className="welcomeText">Tek bir platform üzerinden mesajlarınızı tüm çalışanlarınıza kolayca ulaştıracak yeni nesil teknolojik çözümlerimizi keşfedin.</p>
                        </div>
                        <img className="rounded" src={'/images/banner.jpg'} style={{width: '100%'}}/>
                    </div>
                    <div className="col-xl-12 mt-5">
                        <div className="card">
                            <div className="card-header border-0 pb-0">
                                <h4 className="card-title mb-1">Duyurular</h4>
                                <div className="float-right">
                                    <a href="javascript:void(0)" onClick={() => this.loadMessages('PENDING,SENT')} style={{color: '#7e7e7e', fontSize: 16}}><i className="la la-bullhorn"/> Tümü</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="javascript:void(0)" onClick={() => this.loadMessages('PENDING')} style={{color: '#7e7e7e', fontSize: 16}}><i className="la la-refresh"/> Gönderilecek Mesajlar</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="javascript:void(0)" onClick={() => this.loadMessages('SENT')} style={{color: '#7e7e7e', fontSize: 16}}><i className="la la-send"/> Gönderilenler</a>
                                </div>
                            </div>
                            <div className="card-body pt-0 p-10" style={{overflow:'hidden'}}>

                                {(this.state.isLoaded) &&
                                    <table width="100%">
                                        {
                                            this.state.latestMessages?.map(msg => {
                                                return(
                                                    <MessageItem
                                                        history={this.props.history}
                                                        id={`${msg.id}`}
                                                        message={msg}
                                                        sent={msg.isPublished === 'SENT'}
                                                        type={msg.type ? MessageTypes[msg.type] : MessageTypes['POPUP']}
                                                        summary={msg.summary}
                                                        title={msg.title}
                                                        to={msg.groups.map(g => g.name)}
                                                        sender={msg.sender}
                                                        readPercent={(msg.openedCount/msg.deliveryUsers.length)*100}
                                                        startDate={moment(msg.startDate).locale('tr')}
                                                        loadPage={this.loadMessages}
                                                    />
                                                )
                                            })
                                        }
                                    </table>
                                }

                                {this.state.latestMessages?.length === 0 && this.state.isLoaded &&
                                    <EmptyView title="Bekleyen gönderi bulunmuyor." />
                                }
                                {(!this.state.isLoaded) &&
                                <LoadingView/>
                                    }
                            </div>
                            <div className="card-body pt-0 p-10">
                                <button className="btn btn-primary btn-rounded btn-sm shadow float-left" style={{visibility: this.state.tableSize === 5 ? 'hidden' : 'visible'}} onClick={()=>this.handleTableSize('LESS')}>Daha az göster</button>
                                <button className="btn btn-primary btn-rounded btn-sm shadow float-right" style={{visibility: this.state.isLoaded ? 'visible' : 'hidden'}} onClick={()=>this.handleTableSize('MORE')}>Daha fazla göster</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-5">
                        <div className="card">
                            <div className="card-header border-0 pb-0">
                                <h4 className="card-title mb-1">Bugün Doğanlar</h4>
                            </div>
                            <div className="card-body pt-0 p-10" style={{overflow:'hidden'}}>

                                {(this.state.isLoaded) &&
                                <table width="100%" className="mt-3">
                                    <thead >
                                    <tr >
                                        <th>Ad Soyad</th>
                                        <th>Departman</th>
                                        <th>Pozisyon</th>
                                        <th className="text-center">Durum</th>
                                    </tr>
                                    </thead>
                                    {
                                        this.state.birthDateUsers?.map(user => {
                                            return(
                                                <BirthdayItem
                                                    fullName={user.user?.first_name+" "+user.user?.last_name}
                                                    departments = {user.department?user.department:""}
                                                    title={user.title?user.title:""}
                                                />
                                            )
                                        })
                                    }
                                </table>
                                }
                                {this.state.birthDateUsers?.length === 0 && this.state.isLoaded &&
                                <EmptyView title="Bugün doğan kimse bulunmuyor." />
                                }
                                {(!this.state.isLoaded) &&
                                <LoadingView/>
                                }
                            </div>
                            {/*<div className="card-body pt-0 p-10">*/}
                            {/*    <button className="btn btn-primary btn-rounded btn-sm shadow float-left" style={{visibility: this.state.tableSize === 5 ? 'hidden' : 'visible'}} onClick={()=>this.handleTableSize('LESS')}>Daha az göster</button>*/}
                            {/*    <button className="btn btn-primary btn-rounded btn-sm shadow float-right" style={{visibility: this.state.isLoaded ? 'visible' : 'hidden'}} onClick={()=>this.handleTableSize('MORE')}>Daha fazla göster</button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard;
