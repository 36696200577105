import React from 'react';

class PreviewDesktopNotification extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className="notificationPreview" onClick={() => this.props.onPress()} style={{position: 'absolute', bottom: 10, right: 10, color: '#000000', zIndex: 9999, cursor: 'pointer' }}>
                {
                    this.props.notification && this.props.notification.content && 
                    <React.Fragment >
                        <div dangerouslySetInnerHTML={{__html: this.props.notification.content}}></div>
                    </React.Fragment>
                }
                {
                    this.props.notification && !this.props.notification.content && 
                    <React.Fragment>
                        {this.props.notification.thumbnail && this.props.notification.thumbnail.length === 1 &&
                        <img src={this.props.notification.thumbnail[0].thumbnailUrl} style={{width: '100%', height: 95, minHeight: 95, position: 'absolute', objectFit: 'cover', backgroundColor: '#ffffff', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}/>
                        }
                        <label style={{position: 'absolute', fontWeight: 'bold', marginTop: 100, marginLeft: 15, maxHeight: 50, lineHeight: '16px'}}>
                            {this.props.notification.title.substr(0, 40)}<br/>
                            <label style={{position: 'absolute', marginTop: 2, fontWeight: 'normal', fontSize: 12}}>{this.props.notification.body}</label>
                        </label>
                        <div className="notificationSubTitle" style={{position: 'absolute', height: 50, overflow: 'scroll', overflowX: 'hidden', marginTop: 160, marginLeft: 15, fontSize: 14}}>{this.props.notification.subtitle}</div>

                        <img src="/images/bg_desktop_notification.jpg" style={{width: 200, borderRadius: 8}}/>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default PreviewDesktopNotification;
