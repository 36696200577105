import React, { Component } from 'react';
import { Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import {getGroup, updateGroup, getUsers, createGroupAttach, getGroups} from "../../services/api";
import Swal from "sweetalert2";
import DevMultiSelect from "../../components/forms/DevMultiSelect";
import DualListBox from "react-dual-listbox";

class UpdateUserGroupModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addUserGroupModal: false,
            name: '',
            allUserList: [],
            groupUsers: [],
            groupUser: []
        }
    }

    componentDidMount() {
    }

    loadGroups = async () => {
        const users = await getUsers();

        let usersForBox = []
        users.map(user => {
            let tempObj = { value: user?.id, label: user?.first_name+" "+user?.last_name ,id:user?.id}
            usersForBox.push(tempObj)
        })
        this.setState({
            users: users,
            allUserList: usersForBox
        });
    }

    loadData = () => {


        if (this.props.id) {
            getGroup(this.props.id)
                .then(async res => {
                    const allUsers = await getUsers();
                    this.setState({
                        name: res[0].name,
                        allUserList: allUsers?.map((user) => {
                            return { value: user?.first_name+" "+user?.last_name , label: user?.first_name+" "+user?.last_name ,id:user?.id};
                        }),
                        groupUser: res[0].user_list.map((user) => { return user?.first_name+" "+user?.last_name  })
                    })
                })
                .catch(err => console.log('ERR : ', err))
        }

    }

    handleListBoxOnChange = (selected) => {
        this.setState({groupUser: selected})
    }

    handleUpdateGroup = () => {

        let users = []
        let tempArr = []
        this.state.groupUser.map(data => {
            let tempObj = this.state.allUserList.filter(i => i.label === data)
            tempArr.push(tempObj)
        })
        tempArr.map(res => {
            res.map(user => {
                let tempId = user.id
                users.push(tempId)
            })
        })




        const attachPayload = {
            "group": Number(this.props.id),
            "users": users
            //"users": this.state.groupUser.map(user => user.value)
        }
        createGroupAttach(attachPayload).then(res => {
            Swal.fire({
                icon: 'success',
                text: 'Grup Güncellendi.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
        }).catch(err => {
            Swal.fire({
                icon: 'warning',
                text: 'Grup Güncellenemedi.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
        });

        this.addUserGroupModalToggle(false)

    }

    handleMultiSelectBox = (values, choice) => {
        if (choice === "user") {
            values.map((value) => {
                if (value.value === "*") {
                    this.setState({
                        userGroup: this.state.userGroups?.map((userGroup) => {
                            return { value: userGroup?.id, label: userGroup?.name };
                        })
                    })
                } else {
                    this.setState({
                        userGroup: values
                    })
                }
            })
        }

        if (choice === "group") {
            values.map((value) => {
                if (value.value === "*") {
                    this.setState({
                        groupUser: this.state.allUserList?.map((user) => {
                            return { value: user?.id, label: user?.name };
                        })
                    })
                } else {
                    this.setState({
                        groupUser: values
                    })
                }
            })
        }
    }


    addUserGroupModalToggle = (state) => {
        if (state) {
            this.loadData()
        }
        this.setState({ addUserGroupModal: state });
    }
    render() {
        return (
            <Modal onClose={() => this.addUserGroupModalToggle(false)}
                onOpen={() => this.addUserGroupModalToggle(true)}
                trigger={this.props.trigger}
                closeIcon
                size="small"
                open={this.state.addUserGroupModal}>
                <ModalHeader>Grup Güncelle</ModalHeader>
                <ModalContent>
                    <div className="row mt-2">
                        <div className='col-sm-2'>
                            <label className='mt-2' style={{ whiteSpace: 'nowrap' }}>Grup Adı : </label>
                        </div>
                        <div className='col-sm-10'>
                            <DualListBox
                                canFilter
                                filterPlaceholder="Ara..."
                                options={this.state.allUserList}
                                selected={this.state.groupUser}
                                onChange={this.handleListBoxOnChange}
                            />
                        </div>
                    </div>

                    {/*<div className="row">*/}
                    {/*    <div className='col-sm-2'>*/}
                    {/*        <label className='mt-2'>Kullanıcılar : </label>*/}
                    {/*    </div>*/}
                    {/*    <div className='col-sm-4'>*/}
                    {/*        <DevMultiSelect*/}
                    {/*            itemTitle='Grup'*/}
                    {/*            name='groups'*/}
                    {/*            allGroups={false}*/}
                    {/*            onChange={(values) => this.handleMultiSelectBox(values, "group")}*/}
                    {/*            value={this.state.groupUser}*/}
                    {/*            options={this.state.allUserList?.map((user) => {*/}
                    {/*                return { value: user?.id, label: user?.first_name + " " + user?.last_name };*/}
                    {/*            })}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                </ModalContent>
                <ModalActions>
                    <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                        onClick={() => this.addUserGroupModalToggle(false)}>Geri
                    </button>
                    <input type="button" className="btn btn-success rounded btn-sm px-4 float-right " onClick={this.handleUpdateGroup} value="Kaydet" />
                </ModalActions>
            </Modal>
        );
    }
}

export default UpdateUserGroupModal;