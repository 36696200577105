import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import './assets/css/duyuroom.css';
import Master from "./layouts/Master";
import Login from "./pages/public/Login";
import Forgot from "./pages/public/Forgot";
import Reset from "./pages/public/Reset";
import CustomStorage from "./models/CustomStorage";
import Popup from 'react-popup';
import SettingsManager from './tools/SettingsManager';
import CreatePassword from "./pages/public/CreatePassword";

const SM = SettingsManager.create();

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null
        }

        this.cs = new CustomStorage();
    }

    componentDidMount() {
        const user = this.cs.getItem('user');

        this.setState({ user: user })
    }

    render() {
        return (
            <Router basename="/" history={createBrowserHistory()}>
                <Popup
                    className="mm-popup"
                    btnClass="mm-popup__btn"
                    closeBtn={true}
                    closeHtml={null}
                    defaultOk="Tamam"
                    defaultCancel="Vazgeç"
                    wildClasses={false}
                    escToClose={true} />

                {this.state.user &&
                    <Switch>
                        <Route path="/*" component={Master} />
                    </Switch>
                }

                {!this.state.user &&
                    <Switch>
                        <Route path="/authentication/reset/:uid/:token" component={Reset} />
                        <Route path="/authentication/create-password/:token" component={CreatePassword} />
                        <Route path="/authentication/login" component={Login} />
                        <Route path="/authentication/forgot" component={Forgot} />
                        <Route path="/*" component={Master} />
                    </Switch>
                }
            </Router>
        );
    }
}

ReactDOM.render(<App />, document.querySelector('#root'));
