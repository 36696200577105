import React from 'react';

class PreviewScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div style={{backgroundImage: this.props.wallpaper, backgroundSize: 'cover', height: '100%'}}>
                {this.props.children}
            </div>
        )
    }
}

export default PreviewScreen;
