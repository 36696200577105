class CustomStorage {
    constructor() {
        this.adapter = localStorage.getItem('sm');
    }

    setItem = (key, value) => {
        if(this.adapter === 'ls'){
            localStorage.setItem(key, value);
        }

        if(this.adapter === 'ss'){
            sessionStorage.setItem(key, value);
        }
    };

    getItem = (key, asParsed= false) => {
        if(!asParsed){
            if(this.adapter === 'ls'){
                return localStorage.getItem(key);
            }

            if(this.adapter === 'ss'){
                return sessionStorage.getItem(key);
            }
        }else{
            if(this.adapter === 'ls'){
                return JSON.parse(localStorage.getItem(key));
            }

            if(this.adapter === 'ss'){
                return JSON.parse(sessionStorage.getItem(key));
            }
        }
    };

    removeItem = (key) => {
        if(this.adapter === 'ls'){
            localStorage.removeItem(key);
        }

        if(this.adapter === 'ss'){
            sessionStorage.removeItem(key);
        }
    };

    clear = () => {
        if(this.adapter === 'ls'){
            localStorage.clear();
        }

        if(this.adapter === 'ss'){
            sessionStorage.clear();
        }
    };
}

export default CustomStorage;
