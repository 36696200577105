import React, { useState } from "react";
import QuestionForm from "./QuestionForm";
import styled from "styled-components";
import Question from "./Question";
import Swal from "sweetalert2";

export default function QuizQuestion({
                                           index,
                                           mode,
                                           total,
                                           question,
                                           setQuestion,
                                           removeQuestion,
                                           copyQuestion,
                                           moveQuestionUp,
                                           moveQuestionDown,
                                           show
                                       }) {
    const [editing, setEditing] = useState(true);

    function toggleEditing() {
        const tempAnswer = question.answers.filter(data => data.isCorrect === true)
        console.log('TEMP ANSWER : ', tempAnswer)
        console.log('question içeriği : ', question)
        const tempOptions = question.options.filter(data => data === '')

        if(editing && question.text.trim() === ""){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen sorunuzu yazınız.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
        }else{
            if (tempOptions.length !== 0) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Lütfen seçenekleri doldurunuz!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                return;
            }
            if(tempAnswer.length === 0) {
                Swal.fire({
                    icon: 'warning',
                    text: 'En az 1 adet doğru cevap işaretlenmelidir!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                return
            }
            setEditing(!editing);
        }
    }

    return (
        <QuestionField>
            {mode === "edit" && editing ? (
                <QuestionForm question={question} total={total} setQuestion={setQuestion} />
            ) : (
                <>
                    <h4>{index + 1}/{total}</h4>
                    <p style={{fontSize: 16, background: '#f1f1f1', padding: 8, borderRadius: 8}}>{question.text}</p>
                    {show?
                        question && (
                                question.options?.map((option, i) => {
                                    console.log(option)
                                    return(
                                        <>
                                            {question.type !== Question.TYPES.YESNO &&
                                            <>
                                                <label key={i}>
                                                    <input
                                                        type={question.inputType}
                                                        id={option}
                                                        name={option}
                                                        disabled
                                                    />
                                                    {" "}{option}
                                                </label>
                                            </>
                                            }

                                            {question.type === Question.TYPES.YESNO &&
                                            <>
                                                <button className="btn btn-sm" style={{width: 200, background: '#FFE8BB', color: '#fff', display: 'block',  marginRight: 10}}>{option}</button>
                                            </>
                                            }
                                            <br/>
                                        </>
                                    )})
                            )
                    :
                        question.hasOptions && (
                                question.options?.map((option, i) => {
                                    console.log(option)
                                    return(
                                        <>
                                            {question.type !== Question.TYPES.YESNO &&
                                            <>
                                                <label key={i}>
                                                    <input
                                                        type={question.inputType}
                                                        id={option}
                                                        name={option}
                                                        disabled
                                                    />
                                                    {" "}{option}
                                                </label>
                                            </>
                                            }

                                            {question.type === Question.TYPES.YESNO &&
                                            <>
                                                <button className="btn btn-sm" style={{width: 200, background: '#FFE8BB', color: '#fff', display: 'block',  marginRight: 10}}>{option}</button>
                                            </>
                                            }
                                            <br/>
                                        </>
                                    )})
                            )
                    }


                </>
            )}

            {mode === "edit" &&
            <>
                <br/>
                <p style={{clear: 'both'}}/>
                <button className="btn btn-primary btn-sm mr-1" onClick={toggleEditing}>
                    {editing ? (
                        <>
                            Kaydet
                        </>
                    ) : (
                        <>
                            Düzenle
                        </>
                    )}
                </button>

                <button className="btn btn-danger btn-sm mr-2" onClick={removeQuestion}>Sil</button>
                <button className="btn btn-light btn-sm mr-2" onClick={copyQuestion}>Kopyala</button>
                <button className="btn btn-light btn-sm mr-1" onClick={moveQuestionUp}><i className="fas fa-angle-up icon" /></button>
                <button className="btn btn-light btn-sm" onClick={moveQuestionDown}><i className="fas fa-angle-down icon" /></button>
            </>
            }
        </QuestionField>
    );
}

const QuestionField = styled.li`
  margin: 5px; 
  padding: 10px;
  border: solid 1px #eaeaea;
`;


