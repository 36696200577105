import React from 'react';
import UserAvatar from "react-user-avatar";
import moment from "moment";

class PlatformUserItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className="media" style={{marginTop: 6}}>
                <div className="pull-left mr-3">
                    <UserAvatar
                        size="48"
                        name={this.props.fullname}
                        colors={['#fc5c65', '#fd9644', '#fed330', '#26de81', '#2bcbba', '#eb3b5a', '#fa8231', '#f7b731', '#20bf6b', '#0fb9b1', '#45aaf2', '#4b7bec', '#a55eea', '#d1d8e0', '#778ca3', '#2d98da', '#3867d6', '#8854d0', '#a5b1c2', '#4b6584']}
                        src={this.props.image}
                    />
                </div>
                <div className="media-body">
                    <h4 className="media-heading">
                        {this.props.fullname}<br/>
                        <small style={{fontSize: 11}}>{moment(this.props.lastActivity).fromNow()}</small>
                        <button type="button" onClick={() => this.props.onClickChatButton()} className={`btn ${this.props.online ? 'btn-success' : 'btn-light'} btn-xs float-right mt-2`}><i className="la la-send"/></button>
                        <br/>
                        <small className="text-muted">{this.props.email}</small>
                    </h4>
                    <hr style={{marginBottom: 3}}/>
                </div>
            </div>
        )
    }
}

export default PlatformUserItem;
