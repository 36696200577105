import React from 'react';
import { Button, Header, Label, Form, Modal, Grid, Checkbox, Icon } from 'semantic-ui-react'
import { Formik, ErrorMessage } from 'formik';
import { Form as FormikForm } from 'formik';
import tr from "date-fns/locale/tr";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import MultiSelectAll from "../../../components/forms/MultiSelectAll";
import { Tooltip } from "@trendmicro/react-tooltip";
import InitialMediaModel from '../../../models/constants/InitialMediaModel';
import * as E from '../../../models/enums/Common';
import TemplateSchema from '../../../models/validations/TemplateFormValidations';
import * as API from '../../../services/api';
import AsyncSelect from 'react-select/async';
import moment from "moment";
import { setChonkyDefaults, FullFileBrowser, ChonkyActions, defineFileAction } from 'chonky';
import { turkishI18n } from "../../../helpers/Language";
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import settings from "../../../config/settings.json";
import PubSub from "pubsub-js";
import Swal from "sweetalert2";

class CreateTemplate extends React.Component {


    state = {
        templates: [],
        model: {
            id: '',
            name: '',
            type: '',
            templateJson: {},
            createdby: 1,
            updatedby: 1
        },
        isBirthday:true,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    onSubmit = (values, actions) => {

        const payload = {
            "id": '',
            "name": values.name,
            "type": values.type === "BIRTHDAY"?"POPUP":values.type,
            "width":values.width,
            "height":values.height,
            "isBirthDayTemplate":values.type === "BIRTHDAY",
            "templateJson": {
                "notify":{
                    "css":"* { box-sizing: border-box; } body {margin: 0;}#ia8n{background-color:#fff;max-width:300px!important;max-height:400px!important;margin:auto!important;border:1px solid rgba(34,36,38,.15);border-radius:10px;}#iw8i{height:100px;}#ik8q{margin:auto;max-height:100px;}#i3je{height:180px;} " +
                        ".image-style {\n" +
                        "                margin: auto;\n" +
                        "                max-width: 96%;\n" +
                        "            }\n" +
                        "            .first-layer {\n" +
                        "                background-color: #fff;\n" +
                        "                margin: 10px auto auto;\n" +
                        "                border: 1px solid rgba(34, 36, 38, 0.15);\n" +
                        "                border-radius: 10px;\n" +
                        "                width: 300px !important;\n" +
                        "                overflow:auto;\n" +
                        "            }\n" +
                        "            .height180 {\n" +
                        "                height: auto;\n" +
                        "            }\n" +
                        "            .button-morcivert{\n" +
                        "                background-color: #3b4182 !important;\n" +
                        "                color: white !important;\n" +
                        "                font-size: 10px !important;\n" +
                        "            }\n" +
                        "            .footerNotify{\n" +
                        "                position:fixed;\n" +
                        "                width: 310px;\n" +
                        "                bottom:1px;\n" +
                        "                background-color: white;\n" +
                        "                z-index: 100;\n" +
                        "                margin-top: 1px;\n" +
                        "            }\n" +
                        "            .emptyDiv{\n" +
                        "                height: 58px;\n" +
                        "            }\n" +
                        "            .footerDiv{\n" +
                        "                margin-top: -46px;\n" +
                        "                height: 46px;\n" +
                        "            }\n" +
                        "            .headerText {\n" +
                        "                font-size: 16px;\n" +
                        "                font-weight: 700;\n" +
                        "            }\n" +
                        "            .infoText {\n" +
                        "                font-size: 12px;\n" +
                        "                word-wrap: break-word;\n" +
                        "                width:280px;\n" +
                        "            }",
                    "html":"<div class=\"ui container main-notify-container first-layer\">\n" +
                        "            <img class=\"ui wireframe image fluid image-style\" src=\"https://dummyimage.com/320x86/ba84ba/fff.jpg&text=Görsel\" />\n" +
                        "            <div class=\"ui basic segment height180\">\n" +
                        "                <p class=\"headerText\">Bildirim Başlığı</p>\n" +
                        "                <p class=\"infoText\">Kısa bildirim içeriğini buraya yazabilirsiniz.</p>\n" +
                        "            </div>\n" +
                        "            <div class=\"emptyDiv\"></div>\n" +
                        "            <div class=\"ui middle aligned two column centered padded grid footerNotify\">\n" +
                        "                <div class=\"ui divider row\"></div>\n" +
                        "                <div class=\"row footerDiv\">\n" +
                        "                    <div class=\"column middle aligned\"><p class=\"ui button button-morcivert\" onclick=\"handleClick(0)\">Ötele</p></div>\n" +
                        "                    <div class=\"column middle aligned\"><p class=\"ui button button-morcivert\" onclick=\"handleClick(1)\">Göster</p></div>\n" +
                        "                </div>\n" +
                        "            </div>\n" +
                        "        </div>"
                },
                "content":{
                    "css":"* { box-sizing: border-box; } body {margin: 0;}#i3lb{background-color:#f8f8f8bb;}#itb5{float:left;}#ip6xh{margin:auto;} .isoFooterCss{color: #010232;font-size: 12px;} ",
                    "html":""
                },
            }
        }
        API.createTemplate(payload).then(response => {
            // console.log('RESPONSE : ', response)
            window.template_id = response.id
            window.template_name = response.name
            Swal.fire({
                icon: 'success',
                text: 'Şablon Oluşturuldu!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            PubSub.publish(('canvas_template.new').toLowerCase(), response.id);
            actions.resetForm({});
            this.props.history.push('/canvas')
        }).catch(error => Swal.fire({
            icon: 'error',
            text: 'Şablon Oluşturulamadı!',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: '#3B4182',
            cancelButtonText: "Tamam",
            focusCancel: false,

        }))
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.state.model}
                validationSchema={TemplateSchema}
                onSubmit={this.onSubmit}
            >
                {props => {
                    const handleChange = (e, { name, value }) => {
                        if (name === "type"){
                            this.setState({isBirthday:false})
                        }
                        props.setFieldValue(name, value)};

                    return (
                        <FormikForm className="ui form" style={{ padding: 16 }}>

                            <Form.Select
                                fluid
                                label="Şablon Tipi"
                                name="type"
                                onChange={(handleChange)}
                                onBlur={props.handleBlur}
                                value={props.values.type}
                                options={[
                                    { key: 'POPUP', text: 'Pop-up', value: 'POPUP' },
                                    { key: 'EMERGENCY', text: 'Acil Durum', value: 'EMERGENCY' },
                                    { key: 'NEWSLETTER', text: 'Newsletter', value: 'NEWSLETTER' },
                                    { key: 'LCV', text: 'LCV', value: 'LCV' },
                                    { key: 'LOCK', text: 'Kilit Ekranı', value: 'LOCK' },
                                    { key: 'WALLPAPER', text: 'Duvar Kağıdı', value: 'WALLPAPER' },
                                    //{ key: 'SOCIAL', text: 'Sosyal Hat', value: 'SOCIAL' },
                                    { key: 'SCREENSAVER', text: 'Ekran Koruyucu', value: 'SCREENSAVER' },
                                    { key: 'BIRTHDAY', text: 'Doğum Günü', value: 'BIRTHDAY' },

                                ]}
                                placeholder='Şablon tipi seçiniz'
                                className={props.errors.type && props.touched.type ? 'error' : ''}
                            />
                            <Form.Group widths='equal'>

                                <Form.Input
                                    label="Yükseklik (px)"
                                    placeholder="Örneğin: 1920 px"
                                    name="height"
                                    type='number'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.height}
                                    className={props.errors.type && props.touched.type ? 'error' : ''}
                                />
                                <Form.Input
                                    label="Genişlik (px)"
                                    placeholder="Örneğin: 1080 px"
                                    name="width"
                                    type='number'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.width}
                                    className={props.errors.type && props.touched.type ? 'error' : ''}
                                />
                            </Form.Group>


                            <Form.Input
                                label="Yeni Şablon Adı"
                                placeholder="Örneğin: Varsayılan Şablon 1"
                                name="name"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.name}
                                className={props.errors.name && props.touched.name ? 'error' : ''}
                            />


                            {/*{props.values.type === "POPUP" &&*/}
                            {/*<Form.Checkbox*/}
                            {/*    label="Doğum Günü Şablonu"*/}
                            {/*    name="isBirthday"*/}
                            {/*    onChange={(e)=> this.setState({isBirthday:!this.state.isBirthday})}*/}
                            {/*    checked={this.state.isBirthday}*/}
                            {/*    className={props.errors.name && props.touched.name ? 'error' : ''}*/}
                            {/*/>*/}
                            {/*}*/}


                            <div className="field">
                                <Button type='submit' color="default" icon style={{ width: '100%' }}>
                                    <Icon name='share' />
                                    &nbsp; Oluştur
                                </Button>
                            </div>

                            <div className="field">
                                <Button type='reset' icon style={{ width: '100%' }} onClick={()=>{
                                    this.props.closeEvent()
                                    props.handleReset()}}>
                                    <Icon name='cancel' />
                                    &nbsp;
                                    Vazgeç
                                </Button>
                            </div>

                        </FormikForm>
                    )
                }}
            </Formik>
        )
    }
}

export default CreateTemplate;
