import React from 'react';
import AuthService from '../../services/auth.service';
import { Link } from "react-router-dom";

class Reset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pwd1: "",
            pwd2: "",
            uid: "",
            token: "",
            message: "",
            loading: false
        }
    }

    componentDidMount() {
        let uid = this.props.match.params.uid;
        let token = this.props.match.params.token;

        this.setState({
            uid: uid,
            token : token
        });
    }

    handleForgot = (e) => {
        e.preventDefault();

        let errors = [];
        let pwd1 = this.state.pwd1;
        let pwd2 = this.state.pwd2;

        if(pwd1.trim() === ''){
            errors.push("&bull; Lütfen yeni şifrenizi belirleyiniz.");
        }

        if(pwd2.trim() === ''){
            errors.push("&bull; Lütfen yeni şifrenizi tekrar giriniz.");
        }

        if(pwd1 !== pwd2){
            errors.push("&bull; Belirlediğiniz şifreler uyuşmuyor.");
        }

        if (errors.length === 0) {
            this.setState({
                message: "",
                loading: true
            });

            AuthService.updatePassword(this.state.uid, this.state.token, pwd1, pwd2).then(
                (response) => {
                    console.log('RESPONSE : ', response)
                    const resMessage =
                        (response.response && response.response.data && response.response.data.message) || response.message || response.toString();
                    this.setState({
                        loading: false,
                        message: "&bull; " + resMessage
                    });
                },
                error => {
                    console.log('ERROR : ', error)
                    const resMessage =
                        (error.response && error.response.data && error.response.data.message) || error.message.new_password2[0] || error.toString();
                    this.setState({
                        loading: false,
                        message: "&bull; " + resMessage
                    });
                }
            );
        } else {
            this.setState({
                loading: false,
                message: errors.join('<br/>')
            });
        }
    };

    render() {
        return (
            <div className="authincation h-100 mt-5">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">
                            <div className="text-center mt-5 mb-5">
                                <img src="/images/logo.svg" style={{width: '50%'}}/>
                            </div>
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form">
                                            <form>
                                                <div className="form-group">
                                                    <label><strong>Yeni Şifreniz</strong></label>
                                                    <input type="password" className="form-control" placeholder="buraya yazınız." value={this.state.pwd1} onChange={(e) => {this.setState({pwd1: e.target.value})}} />
                                                </div>
                                                <div className="form-group">
                                                    <label><strong>Şifre Tekrar</strong></label>
                                                    <input type="password" className="form-control" placeholder="buraya yazınız." value={this.state.pwd2} onChange={(e) => {this.setState({pwd2: e.target.value})}} />
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" onClick={this.handleForgot} className="btn btn-primary btn-block" disabled={this.state.loading}>Güncelle</button>
                                                    <br/>
                                                    <Link to="/authentication/login">Giriş sayfasına dönün</Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="clearfix"/>
                            {this.state.message !== '' &&
                            <div className="alert alert-danger" dangerouslySetInnerHTML={{__html:this.state.message}}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Reset;
