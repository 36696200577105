import React from 'react';
import {customMediaTableStyles, mediaTableColumnsWithoutUser, setPageTitle} from "../../helpers/LayoutHelper";
import CustomStorage from "../../models/CustomStorage";
import PubSub from "pubsub-js";
import UserAvatar from "react-user-avatar";
import {
    changePassword,
    getAnnouncementsByUser,
    getGroups,
    getProfile,
    updateProfile,
    updateUser
} from "../../services/api";
import {protectedApi} from "../../services/axios";
import DataTable from "react-data-table-component";
import MediaRowDetail from "../../components/lists/MediaRowDetail";
import LoadingView from "../../components/common/LoadingView";
import EmptyView from "../../components/common/EmptyView";
import {Modal,ModalHeader,ModalActions,ModalContent} from "semantic-ui-react";
import AvatarEditor from 'react-avatar-editor'
import moment from "moment";
import tr from "date-fns/locale/tr";
import DatePicker from "react-datepicker";
import DualListBox from "react-dual-listbox";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";


class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "Profilim",
            user: null,
            loading: false,
            list: [],
            uploadModal:false,
            preview:undefined,
            image:undefined,
            scale:1,

            userEmail: "",
            userPhone: "",
            userTitle: "",
            department: "",
            locations: "",
            birthDate: "",

            isContentAdmin: "",
            isStandartUser: "",
            isSuperAdmin: "",
            isSystemAdmin: "",

            isLoaded: false,

            userProfile: null,

            groupsForBox: [],
            selectedGroups: [],

            newPassword1:"",
            newPassword2:""

        }

        this.inputCss = { height: 40, width: 300, cursor: 'default' }

        this.cs = new CustomStorage();

        this.upload = React.createRef();
    }

    /* Standart methods for all pages */
    componentDidMount(){
        this.loadData()

        this.updatePageTitle();
        this.getList();

        this.loadGroups()

        PubSub.publish('MENU', '');
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount(){
        this.updatePage(this.props);
    }

    updatePage = async(props) => {
        this.updatePageTitle();
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {};
    /* Standart methods for all pages */

    changeAvatar = () => {
        this.uploadModalToggle()
    }
    selectImage = () => {
        this.upload.click();
    }
    uploadModalToggle = () => {
        this.setState({uploadModal:!this.state.uploadModal})

    }

    loadData = () => {
        const user = this.cs.getItem('user');
        const user_id = JSON.parse(user).user.pk;
        getProfile(user_id).then(data => {
            if(data !== undefined){
                let tempArr = []
                data.user.groups.map(data => {
                    let tempObj = data.name
                    tempArr.push(tempObj)
                })
                this.setState({
                    user: {...data.user, img:data.img, phone: data.phone },
                    userProfile: data,
                    firstName: data.user.first_name,
                    lastName: data.user.last_name,
                    email: data.user.email,
                    phone: data.phone,
                    title: data.title,
                    department: data.department,
                    birthDateDisplay:data.birthDate,
                    birthDate: data?.birthDate?moment(data?.birthDate,"DD-MM-YYYY").format():new Date(),
                    city: data.city,
                    locations: data.locations,
                    selectedGroups: tempArr,
                    isContentAdmin: data.isContentAdmin,
                    isStandartUser: data.isStandartUser,
                    isSuperAdmin: data.isSuperAdmin,
                    isSystemAdmin: data.isSystemAdmin,
                });
            }
        })
    }

    // Loading the all groups
    loadGroups = async () => {
        const userGroups = await getGroups();

        let groupsForBox = []
        userGroups?.map(group => {
            let tempObj = {value: group.name, label: group.name, id: group.id}
            groupsForBox.push(tempObj)
        })
        this.setState({
            userGroups: userGroups,
            groupsForBox: groupsForBox
        });
    }

    // React-Dual-listbox onChange function
    handleListBoxOnChange = (selected) => {
        this.setState({selectedGroups: selected})
    }


    getList = async () => {
        let user = this.cs.getItem('user');
        let user_id = JSON.parse(user).user.pk;
        let announcements = await getAnnouncementsByUser(user_id);
        this.setState({loading: true})
        if(Array.isArray(announcements) && announcements.length > 0){
            this.setState({
                loading: false,
                list: announcements,
                isLoaded: true
            });
        }else{
            this.setState({
                loading: false,
                list: []
            });
        }
    }

    handleFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let selectedFile = event.target.files[0];

        this.handleFileUpload(selectedFile);
    }

    handleFileUpload = () => {
        const img = this.editor.getImage().toDataURL();
        let file = this.dataURLtoFile(img,"ProfilePicture.png")
        let data = new FormData();
        let config = {header: {'Content-Type': 'multipart/form-data'}}
        let user_id = this.state.user.id;

        data.append('user', user_id);
        data.append('img', file, file.name);
        data.append('isStandartUser', this.state.isStandartUser);
        data.append('isContentAdmin', this.state.isContentAdmin);
        data.append('isSuperAdmin', this.state.isSuperAdmin);
        data.append('isSystemAdmin', this.state.isSystemAdmin);

        protectedApi.put('/management/profile/' + user_id, data, config).then(response => {
            let img_path = response.data.img;

            this.setState({user: {...this.state.user, img:img_path}}, () => {
                PubSub.publish('PROFILE', img_path);
            });
        });
        this.uploadModalToggle()
    }

    setEditorRef = (editor) => {
        if (editor) this.editor = editor;
    };

    handleNewImage = (e) => {
        this.setState({ image: e.target.files[0] });
    };

    handleSave = (data) => {
        // const img = this.editor.getImageScaledToCanvas().toDataURL();
        const img = this.editor.getImage().toDataURL();

        this.setState({
            preview: {
                img
            }
        });
    };

    handleScale = (e) => {
        const scale = parseFloat(e.target.value);
        this.setState({ scale });
    };

    dataURLtoFile=(dataurl, filename)=> {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    setOpen = (state) => {
        this.setState({updateUserModal: state});
    }

    updatePasswordModalToggle = (state) => {
        if(this.state.userProfile.userType === "SA"){
            this.setState({updatePasswordModal: state});
        }else {
            Swal.fire({
                icon: 'warning',
                text: 'Şifrenizi güncelleyemezsiniz. Lütfen Sistem yöneticinize danışın!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            return;
        }
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleUpdateUser = () => {
        if (
            this.state.email.length < 2 ||
            this.state.phone.length < 2 ||
            this.state.title.length < 2 ||
            this.state.department.length < 2 ||
            this.state.city.length < 2 ||
            this.state.locations.length < 2 ||
            this.state.birthDate ===null ||
            this.state.selectedGroups.length===0) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen zorunlu alanları doldurunuz!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            return;
        }

        let groups = []
        let tempArr = []
        this.state.selectedGroups.map(data => {
            let tempObj = this.state.groupsForBox.filter(i => i.label === data)
            tempArr.push(tempObj)
        })
        tempArr.map(res => {
            res.map(group => {
                let tempId = {"id":group.id}
                groups.push(tempId)
            })
        })



        const payload = {
            "id": this.state.user.id,
            // 'first_name': this.state.userFirstName,
            // 'last_name': this.state.userLastName,
            // 'username': this.state.userFirstName.toLowerCase().trim() + this.state.userLastName.toLowerCase().trim(),
            'email': this.state.email,
            'groups': groups,
            is_active: true,
        }

        updateUser(this.state.user.id, payload)
            .then(res => {
                const profilePayload = {
                    'user': this.state.user.id,
                    'birthDate': moment(this.state.birthDate).format("DD-MM-YYYY"),
                    'city': this.state.city,
                    'locations': this.state.locations,
                    'department': this.state.department,
                    // 'isContentAdmin': this.state.isContentAdmin,
                    // 'isSystemAdmin': this.state.isSystemAdmin,
                    'phone': this.state.phone,
                    'title': this.state.title
                }
                updateProfile(this.state.user.id, profilePayload)
                    .then(response => {
                        if(response.status===400){
                            let msg = ""
                            if(response.errors.phone){msg=response.errors.phone[0]}
                            Swal.fire({
                                icon: 'warning',
                                text: msg,
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonColor: '#3B4182',
                                cancelButtonText: "Tamam",
                                focusCancel: false,
                            })
                            return
                        }
                        Swal.fire({
                            icon: 'success',
                            text: 'Kullanıcı güncellendi!',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,
                        })
                        this.loadData()
                        this.setOpen(false)
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            text: 'Kullanıcı güncellenemedi!',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,

                        })
                    })
            }).catch(err => {
            Swal.fire({
                icon: 'success',
                text: 'Kullanıcı Güncellendi',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            console.log('ERR : ', err)
        })
    }


    handleUpdatePassword = () => {
        const payload = {
            new_password1: this.state.newPassword1,
            new_password2: this.state.newPassword2,
            is_active: true
        }
        changePassword(payload)
            .then(res => {
                if(res.data.status === 400){
                    if(res.data.message === "Parolanız adı ile çok benzerdir."){
                        res.data.message = "Parolanız adınız ile çok benzerdir."
                    }
                    const resMessage = res.data.message

                    Swal.fire({
                        icon: 'warning',
                        text: resMessage,
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    });
                }
                if(res.data.status === 200){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Şifreniz güncellenmiştir',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,

                    });
                }
            })
            .catch(err => {
                console.log('ERROR CATCH : ', err)
            })

    }

    render() {
        if(!this.state.user){
            return null
        }

        // console.log(this.state)

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body" style={{paddingLeft: 0}}>
                                <form method="post">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="profile-img pl-5 pt-2">
                                                <input
                                                    type="file"
                                                    ref={(ref) => this.upload = ref}
                                                    style={{display: 'none'}}
                                                    onChange={this.handleFileChange}
                                                />
                                                <button type="button"
                                                        onClick={() => this.uploadModalToggle()}
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                            backgroundColor: '#F4C752',
                                                            borderWidth: 0,
                                                            borderRadius: 15,
                                                            position: 'absolute',
                                                            bottom: -5,
                                                            marginLeft: -30,
                                                            cursor: 'pointer',
                                                            zIndex: 1
                                                        }}>📷
                                                </button>
                                                <UserAvatar
                                                    size="180"
                                                    name={this.state.user.first_name + ' ' + this.state.user.last_name}
                                                    colors={['#fc5c65', '#fd9644', '#fed330', '#26de81', '#2bcbba', '#eb3b5a', '#fa8231', '#f7b731', '#20bf6b', '#0fb9b1', '#45aaf2', '#4b7bec', '#a55eea', '#d1d8e0', '#778ca3', '#2d98da', '#3867d6', '#8854d0', '#a5b1c2', '#4b6584']}
                                                    src={this.state.user ? this.state.user.img : ''}
                                                />
                                                <Modal onClose={() => this.uploadModalToggle()}
                                                       onOpen={() => this.uploadModalToggle()}
                                                       closeIcon
                                                       style={{position: "relative", height: "525px", width: "600px"}}
                                                       size="small"
                                                       open={this.state.uploadModal}>
                                                    <ModalHeader style={{height: "50px", width: "600px"}}>Profil Resmi
                                                        Güncelleme</ModalHeader>
                                                    <ModalContent style={{marginTop: "50px", height: "300px"}}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <AvatarEditor
                                                                    ref={this.setEditorRef}
                                                                    className="editor-canvas"
                                                                    image={this.state.image ? this.state.image : this.state.user.img}
                                                                    borderRadius={125}
                                                                    width={250}
                                                                    height={250}
                                                                    border={25}
                                                                    color={[255, 255, 255, 0.6]} // RGBA
                                                                    scale={parseFloat(this.state.scale)}
                                                                    rotate={0}
                                                                />
                                                                <br/>
                                                                <input
                                                                    style={{marginLeft: "95px"}}
                                                                    name="scale"
                                                                    type="range"
                                                                    onChange={this.handleScale}
                                                                    min="0.1"
                                                                    max="5"
                                                                    step="0.01"
                                                                    defaultValue="1"
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input name="newImage" ref={(ref) => this.upload = ref}
                                                                       style={{display: 'none'}} type="file"
                                                                       onChange={this.handleNewImage}/>
                                                                <button type="button"
                                                                        className="btn btn-success rounded btn-sm px-4  float-right"
                                                                        onClick={this.selectImage}>Ekle
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ModalContent>
                                                    <ModalActions style={{marginTop: "100px", height: "55px"}}>
                                                        <button className="btn btn-primary rounded btn-sm px-4 "
                                                                onClick={() => this.uploadModalToggle()}>Geri
                                                        </button>
                                                        <input type="button" disabled={this.state.image === undefined}
                                                               className="btn btn-success rounded btn-sm px-4 float-right "
                                                               onClick={this.handleFileUpload} value="Kaydet"/>
                                                    </ModalActions>
                                                </Modal>

                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="profile-head">
                                                <h3>
                                                    {this.state.user && `${this.state.user.first_name} ${this.state.user.last_name}`}
                                                </h3>
                                                <h6>
                                                    {this.state.user.is_superuser ? 'İçerik Yöneticisi' : 'Kullanıcı'}
                                                </h6>
                                                <br/>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home"
                                                           role="tab" aria-controls="home" aria-selected="true">Bilgiler</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#list"
                                                           role="tab" aria-controls="profile" aria-selected="false">Aktiviteler</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginTop: -20}}>
                                        <div className="col-md-3">
                                            <br/>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="tab-content profile-tab" id="myTabContent">
                                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <div className='row'>
                                                        <div className='col-md-6 ml-2'>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>Kullanıcı Adı:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.user && this.state.user.username}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>Ad:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.user && this.state.user.first_name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>Soyad:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.user && this.state.user.last_name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>E-Posta:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.user && this.state.user.email}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>Telefon:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.user && this.state.user.phone}</p>
                                                                </div>
                                                            </div>
                                                            {/*SERVİS TAMAMLANMADIĞI İÇİN DEĞERLERİ GİRİLMEDİ*/}
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>Unvan:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.userProfile.title}</p>
                                                                </div>
                                                            </div>
                                                            {/*SERVİS TAMAMLANMADIĞI İÇİN DEĞERLERİ GİRİLMEDİ*/}
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>Departman:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.userProfile.department}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>İl:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.userProfile.city}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>İlçe:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.userProfile.locations}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>Doğum Tarihi:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.userProfile.birthDate}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <label>Görevi:</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>{this.state.userProfile.isSystemAdmin ? "Sistem Yöneticisi" : 'İçerik Yöneticisi'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <div className="profile-work ml-n5">
                                                                <h4>Gruplar:</h4>
                                                                {this.state.user && this.state.user.groups.map(g => {
                                                                    return (<><a href=""><span className="badge light badge-secondary">{g.name}</span></a></>)
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className='col-sm-12'>
                                                            <p className="btn btn-primary rounded btn-sm px-4 float-right" style={{color: "white", cursor: 'pointer'}}
                                                               onClick={() => this.setOpen(true)}> Düzenle
                                                            </p>
                                                            <p className="btn btn-primary rounded btn-sm px-4 float-right mx-1" style={{color: "white", cursor: 'pointer'}}
                                                               onClick={() => this.updatePasswordModalToggle(true)}> Şifreyi Güncelle
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <Modal
                                                        onClose={() => this.setOpen(false)}
                                                        onOpen={() => this.setOpen(true)}
                                                        open={this.state.updateUserModal}
                                                        dimmer='inverted'
                                                        size='tiny'
                                                        closeIcon
                                                    >
                                                        <Modal.Header>Kullanıcıyı Güncelle</Modal.Header>
                                                        <ModalContent>
                                                            <div className="row">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>Kullanıcı Adı *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="username"
                                                                        id="username"
                                                                        className='form-control float-left'
                                                                        readOnly
                                                                        value={this.state.user?.username}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>Ad *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="firstName"
                                                                        id="firstName"
                                                                        className='form-control float-left'
                                                                        readyOnly
                                                                        value={this.state.firstName}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>Soyad *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="lastName"
                                                                        id="lastName"
                                                                        className='form-control float-left'
                                                                        readyOnly
                                                                        value={this.state.lastName}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>E-Posta *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="email"
                                                                        id="email"
                                                                        className='form-control float-left'
                                                                        value={this.state.email}
                                                                        onChange={e => this.handleInput(e)}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>Tel No *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <PhoneInput
                                                                        country={'tr'}
                                                                        // name="userPhone"
                                                                        // id="userPhone"
                                                                        inputClass="react-tel-input"
                                                                        buttonClass="react-tel-input-flag"
                                                                        value={this.state.phone}
                                                                        onChange={e => this.setState({ phone: e})}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>Unvan *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="title"
                                                                        id="title"
                                                                        className='form-control float-left'
                                                                        value={this.state.title}
                                                                        onChange={e => this.handleInput(e)}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>Departman *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="department"
                                                                        id="department"
                                                                        className='form-control float-left'
                                                                        value={this.state.department}
                                                                        onChange={e => this.handleInput(e)}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>Doğum Tarihi *: </label>
                                                                </div>
                                                                <div className='col-sm-6 date-div'>
                                                                    <DatePicker
                                                                        wrapperClassName="datePicker"
                                                                        placeholderText="Gün.Ay.Yıl"
                                                                        selected={new Date(this.state.birthDate)}
                                                                        isClearable={false}
                                                                        showTimeSelect={false}
                                                                        timeFormat="H:mm"
                                                                        timeIntervals={15}
                                                                        locale={tr}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        dateFormat="dd.MM.yyyy"
                                                                        onChange={date => {
                                                                            this.setState({birthDate: date})
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>İl *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="city"
                                                                        id="city"
                                                                        className='form-control float-left'
                                                                        value={this.state.city}
                                                                        onChange={e => this.handleInput(e)}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-3'>
                                                                    <label className='mt-2'>ilçe *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="locations"
                                                                        id="locations"
                                                                        className='form-control float-left'
                                                                        value={this.state.locations}
                                                                        onChange={e => this.handleInput(e)}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-1' />
                                                                <div className='col-sm-10'>
                                                                    <label className='mt-2'>Gruplar *</label>
                                                                    <DualListBox
                                                                        options={this.state.groupsForBox}
                                                                        selected={this.state.selectedGroups}
                                                                        onChange={this.handleListBoxOnChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </ModalContent>
                                                        <ModalActions>
                                                            <button className="btn btn-primary rounded btn-sm px-4 mr-4"
                                                                    onClick={() => this.setOpen(false)}>Vazgeç
                                                            </button>
                                                            <input type="button" className="btn btn-success rounded btn-sm float-right px-4" style={{marginRight: 20}} onClick={this.handleUpdateUser} value="Güncelle" />
                                                        </ModalActions>
                                                    </Modal>


                                                    <Modal
                                                        onClose={() => this.updatePasswordModalToggle(false)}
                                                        onOpen={() => this.updatePasswordModalToggle(true)}
                                                        open={this.state.updatePasswordModal}
                                                        dimmer='inverted'
                                                        size='tiny'
                                                        closeIcon
                                                    >
                                                        <Modal.Header>Şifreyi Güncelle</Modal.Header>
                                                        <ModalContent>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-4'>
                                                                    <label className='mt-2'>Yeni şifre *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="newPassword1"
                                                                        id="newPassword1"
                                                                        className='form-control float-left'
                                                                        type="password"
                                                                        onChange={(e) => this.handleInput(e)}
                                                                        value={this.state.newPassword1}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className='col-sm-4'>
                                                                    <label className='mt-2'>Yeni şifre Tekrar *: </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <input
                                                                        name="newPassword2"
                                                                        id="newPassword2"
                                                                        className='form-control float-left'
                                                                        type="password"
                                                                        onChange={(e) => this.handleInput(e)}
                                                                        value={this.state.newPassword2}
                                                                        style={this.inputCss}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </ModalContent>
                                                        <ModalActions>
                                                            <button className="btn btn-primary rounded btn-sm px-4 mr-4"
                                                                    onClick={() => this.updatePasswordModalToggle(false)}>Vazgeç
                                                            </button>
                                                            <input type="button" className="btn btn-success rounded btn-sm float-right px-4" style={{marginRight: 20}} onClick={this.handleUpdatePassword} value="Güncelle" />
                                                        </ModalActions>
                                                    </Modal>


                                                </div>
                                                <div className="tab-pane fade" id="list" role="tabpanel" aria-labelledby="profile-tab">
                                                    {this.state.isLoaded ?
                                                        <DataTable
                                                            title=""
                                                            defaultSortField="startDate"
                                                            defaultSortAsc={false}
                                                            customStyles={customMediaTableStyles}
                                                            noHeader={true}
                                                            selectableRows={false}
                                                            highlightOnHover={true}
                                                            fixedHeader={true}
                                                            responsive={true}
                                                            expandableRows={true}
                                                            progressPending={this.state.loading}
                                                            expandableRowsComponent={<MediaRowDetail/>}
                                                            progressComponent={<LoadingView/>}
                                                            noDataComponent={<EmptyView title="Henüz paylaştığınız bir gönderi bulunamadı."/>}
                                                            columns={mediaTableColumnsWithoutUser}
                                                            data={this.state.list}
                                                            actions={null}
                                                            contextActions={null}
                                                            pagination={true}
                                                            paginationComponentOptions={
                                                                { rowsPerPageText: 'Listelenen:', rangeSeparatorText: '/', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Tümü' }
                                                            }
                                                        /> :
                                                        <LoadingView/>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile
