import React from 'react';
import { Button, Header, Label, Form, Modal, Grid, Checkbox, Icon } from 'semantic-ui-react'
import { Formik, ErrorMessage } from 'formik';
import { Form as FormikForm } from 'formik';
import tr from "date-fns/locale/tr";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import MultiSelectAll from "../../../components/forms/MultiSelectAll";
import { Tooltip } from "@trendmicro/react-tooltip";
import InitialMediaModel from '../../../models/constants/InitialMediaModel';
import * as E from '../../../models/enums/Common';
import TemplateSchema from '../../../models/validations/TemplateFormValidations';
import * as API from '../../../services/api';
import AsyncSelect from 'react-select/async';
import moment from "moment";
import { setChonkyDefaults, FullFileBrowser, ChonkyActions, defineFileAction } from 'chonky';
import { turkishI18n } from "../../../helpers/Language";
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import settings from "../../../config/settings.json";
import PubSub from "pubsub-js";
import { setPageTitle } from "../../../helpers/LayoutHelper";

class TemplateSettings extends React.Component {


    state = {
        templates: [],
        model: {
            id: '',
            name: '',
            type: '',
            templateJson: {},
        }
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.loadTemplates();
        this.loadModel();
        PubSub.subscribe('canvas_template.new', this.onCreated.bind(this));
    }

    loadModel = () => {
        let model = this.state.model
        API.getTemplate(window.template_id).then(response => {
            setTimeout(() => {
                if (response) {
                    model.id = window.template_id;
                    model.name = response.name;
                    model.type = response.type;
                    model.templateJson = response.templateJson;

                    this.setState({ model: model })
                    PubSub.publish(('canvas_template.update').toLowerCase(), response);

                }
            }, 1500)


        });

    }

    onCreated = (id) => {
        this.loadTemplates();
    }

    onSubmit = (values, actions) => {
        if (values.saveAs) {
            PubSub.publish(('canvas_template.saveAs'), values);
        } else {
            PubSub.publish(('canvas_template.save').toLowerCase(), values);
        }

    }

    async loadTemplates() {
        const [templates] = await Promise.all([
            API.getTemplates('')
        ]);
        const [birthday] = await Promise.all([
            API.getTemplates('', true)
        ]);

        const tempArr = [...templates, ...birthday]

        this.setState({ templates: tempArr });
    }

    loadTemplate(formProps, id) {
        API.getTemplate(id).then(response => {
            if (response) {
                formProps.setFieldValue('id', id);
                formProps.setFieldValue('name', response.name);
                formProps.setFieldValue('type', response.type);
                formProps.setFieldValue('templateJson', response.templateJson);

                setPageTitle(response.name);

                PubSub.publish(('canvas_template.update').toLowerCase(), response);
            }

        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe('canvas_template.new');
    }
    saveAs = () => {
    }
    render() {
        return (
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.model}
                    //validationSchema={TemplateSchema}
                    onSubmit={this.onSubmit}
                >
                    {props => {
                        const onTemplateChange = (e, { name, value }) => {
                            props.setFieldValue(name, value);
                            this.loadTemplate(props, value);
                        };

                        const handleChange = (e, { name, value }) => props.setFieldValue(name, value);

                        const saveAs = () => props.setFieldValue("saveAs", true)

                        return (
                            <FormikForm className="ui form" style={{ padding: 16 }}>
                                <Form.Select
                                    clearable={true}
                                    label="Mevcut Şablonlar"
                                    name="id"
                                    onChange={onTemplateChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.id}
                                    options={this.state.templates.map(t => { return { key: 't-' + t.id, text: t.name, value: t.id } })}
                                    placeholder='Düzenlemek için seçiniz.'
                                    className={props.errors.id && props.touched.id ? 'error' : ''}
                                />
                                {

                                    props.values.id &&
                                    <Form.Input
                                        label="Şablon Adı"
                                        placeholder="Örneğin; Varsayılan Şablon 1"
                                        name="name"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        className={props.errors.name && props.touched.name ? 'error' : ''}
                                    />
                                }
                                {

                                    props.values.id &&
                                    <Form.Select
                                        fluid
                                        label="Şablon Tipi"
                                        name="type"
                                        onChange={(handleChange)}
                                        onBlur={props.handleBlur}
                                        value={props.values.type}
                                        options={[
                                            { key: 'POPUP', text: 'POP-UP', value: 'POPUP' },
                                            { key: 'EMERGENCY', text: 'ACİL DURUM', value: 'EMERGENCY' },
                                            { key: 'NEWSLETTER', text: 'NEWSLETTER', value: 'NEWSLETTER' },
                                            { key: 'LCV', text: 'LCV', value: 'LCV' },
                                            { key: 'WALLPAPER', text: 'DUVAR KAĞIDI', value: 'WALLPAPER' },

                                        ]}
                                        placeholder='Şablon tipi seçiniz'
                                        className={props.errors.type && props.touched.type ? 'error' : ''}
                                    />
                                }
                                {

                                    props.values.id &&
                                    <div className="field">
                                        <Button type='submit' color="default" icon style={{ width: '100%' }}>
                                            <Icon name='share' />
                                            &nbsp; Kaydet
                                        </Button>
                                    </div>
                                }
                                {

                                    props.values.id &&
                                    <div className="field">
                                        <Button type='submit' color="default" icon style={{ width: '100%' }} onClick={saveAs}>
                                            <Icon name='save' />
                                            &nbsp; Farklı Kaydet
                                        </Button>
                                    </div>
                                }
                                {

                                    props.values.id &&
                                    <div className="field">
                                        <Button type='reset' icon style={{ width: '100%' }} onClick={props.handleReset}>
                                            <Icon name='cancel' />
                                            &nbsp;
                                            Temizle
                                        </Button>
                                    </div>
                                }
                            </FormikForm>
                        )
                    }}
                </Formik>

                {/*<div className="field" style={{display:"flex"}}>*/}
                {/*    <Button className="ui default button mb-3" color="default" style={{width: '92%',margin:"auto"}}*/}
                {/*            onClick={() => this.saveAs()}>*/}
                {/*        <Icon name='save'/>*/}
                {/*        &nbsp; Farklı Kaydet*/}
                {/*    </Button>*/}
                {/*</div>*/}


            </>

        )
    }
}

export default TemplateSettings;
