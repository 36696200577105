import React from 'react';
import PubSub from "pubsub-js";
import {Button, Form, Grid, Modal} from "semantic-ui-react";
import PreviewWindow from "../../components/common/PreviewWindow";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewFrame from "../../components/common/PreviewFrame";
import CanvasManager from "../../tools/CanvasManager";
import * as API from '../../services/api';
import {isoValues, setPageTitle} from "../../helpers/LayoutHelper";
import {PreviewCss} from "../../helpers/PreviewCssHelper";

const CM = new CanvasManager();
let subTokens = [];

class PublishAgainPage extends React.Component {
    mounted = false;

    state = {
        mode: 'design-content',
        content: {},
        notify: {},
        model: null,
        link:null,
    };

    constructor(props) {
        super(props);

        this.editorContent = null;
        this.editorNotify = null;
    }

    componentDidMount = () => {
        if (this.mounted) return;
        this.mounted = true;

        this.loadTemplate();
        PubSub.publish("CANVAS", { showPanel: true, model: this.props.location.state });
        PubSub.subscribe('ask-template-popup',this.sendCurrentTemplate)
        PubSub.subscribe('ask-template-newsletter',this.sendCurrentTemplate)
        PubSub.subscribe('ask-template-emergency',this.sendCurrentTemplate)
        //PubSub.subscribe('ask-template-wallpaper',this.sendCurrentTemplate)
        //PubSub.subscribe('ask-template-lock',this.sendCurrentTemplate)
        //PubSub.subscribe('ask-template-screensaver',this.sendCurrentTemplate)
        PubSub.subscribe('ask-template-lcv',this.sendCurrentTemplate)
        PubSub.subscribe('data-classification',this.addISO)
    }

    addISO = (msg,data) => {
        const component = this.editorContent?.getWrapper().find('#isoFooter');
        if (component?.length !==0){
            component[0].remove();
        }
        const componentText = this.editorContent?.getWrapper().find('.second-container');
        if (data === "PUBLIC"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss">${isoValues.PUBLIC}</p>`);
            }
        }else if(data === "RESTRICTED"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss">${isoValues.RESTRICTED}</p>`);
            }

        }else if(data === "CONFIDENTIAL"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss">${isoValues.CONFIDENTIAL}</p>`);
            }

        }else if(data === "TOPSECRET"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss">${isoValues.TOPSECRET}</p>`);
            }

        }

    }

    getPosition = (string, subString, index) => {
        return string.split(subString, index).join(subString).length;
    }


    loadTemplate() {
        API.getAnnouncement(this.props.location?.state?.id?this.props.location.state.id:window.sendAgainId).then((response) => {
            if (response) {
                PubSub.publish('sendAgainPage', response)
                setPageTitle(response.title);
                let cssEnd = this.getPosition(response.description, '<', 2)
                let cssContent = response.description.substr(0,Number(cssEnd))

                cssContent = cssContent.replace('<style>', '')
                let htmlContent = response.description.substr(Number(cssEnd)+8)

                let notifyCssEnd = this.getPosition(response.notifyHtml, '<', 2)
                let notifyCssContent = response.notifyHtml.substr(0,Number(notifyCssEnd))

                notifyCssContent = notifyCssContent.replace('<style>', '')
                let notifyHtmlContent = response.notifyHtml.substr(Number(notifyCssEnd)+8)


                CM.ElementWaiting(['canvasTools'], () => {
                    this.editorContent = CM.CreateCanvas('design-content',response.width,response.height);
                    this.setState({templateWidth:response.width && response.width >0 ?response.width:1024,templateHeight:response.height && response.height >0 ?response.height:800})
                    this.editorNotify = CM.CreateCanvas('design-notify');
                    this.setActiveCanvas('design-content');
                    CM.AppendDefaultHeadLinks();

                    this.editorContent.setComponents(CM.GetLiquidTemplateContent(htmlContent));
                    this.editorContent.setStyle(cssContent+'.isoFooterCss{color: #010232;font-size: 12px;}');


                    this.editorNotify.setComponents(notifyHtmlContent);
                    this.editorNotify.setStyle(notifyCssContent);
                });
            }
        });
    }

    onRemount() {
        subTokens.forEach(token => {
            PubSub.unsubscribe(token)
        });

        subTokens = [];
    }

    onSave = (event, data) => {

        this.state.model = data;
        if (this.editorContent) {
            this.state.content = {
                css: this.editorContent.getCss(),
                html: this.editorContent.getHtml()
            };
        }

        if (this.editorNotify) {
            this.state.notify = {
                css: this.editorNotify.getCss(),
                html: this.editorNotify.getHtml()
            };
        }

        console.log('Design State:');
        console.log(JSON.stringify(this.state));
    }

    onUpdate = (event, data) => {
        this.state.model = data;
        console.log('Design Update:');
        console.log(JSON.stringify(this.state));
    }

    componentWillUnmount() {
        if (!this.mounted) return;
        this.mounted = false;

        PubSub.unsubscribe('canvas_popup.save');
        PubSub.unsubscribe('canvas_popup.update');
        PubSub.unsubscribe('ask-template-popup')
        PubSub.unsubscribe('ask-template-newsletter')
        PubSub.unsubscribe('ask-template-emergency')
        //PubSub.subscribe('ask-template-wallpaper',this.sendCurrentTemplate)
        //PubSub.subscribe('ask-template-lock',this.sendCurrentTemplate)
        //PubSub.subscribe('ask-template-screensaver',this.sendCurrentTemplate)
        PubSub.unsubscribe('ask-template-lcv')
        PubSub.unsubscribe('data-classification')

        PubSub.publish("CANVAS", { showPanel: false });

        if (this.editorContent) {
            this.editorContent.destroy();
        }
        if (this.editorNotify) {
            this.editorNotify.destroy();
        }
    }

    setActiveCanvas = (mode) => {
        if (this.state.mode != mode) {
            this.setState({ mode });
        }

        if (this.editorNotify && this.editorNotify.hideWrappers)
            this.editorNotify.hideWrappers();
        if (this.editorContent && this.editorContent.hideWrappers)
            this.editorContent.hideWrappers();


        switch (mode) {
            case 'design-content':
                if (this.editorContent)
                    this.editorContent.showWrappers();
                break;
            case 'design-notify':
                if (this.editorNotify)
                    this.editorNotify.showWrappers();
                break;
            case 'preview':
            default:
                break;
        }
    }

    sendCurrentTemplate = () => {
        const template = {
            content : "<style>"+this.editorContent?.getCss()+"</style>"+this.editorContent?.getHtml(),
            notify : "<style>"+this.editorNotify?.getCss()+"</style>"+this.editorNotify?.getHtml(),
            isUpdate:true,
            width:this.state.templateWidth,
            height:this.state.templateHeight,
        }

        PubSub.publish('current-template',template)
    }

    backButton = () => {
        this.props.history.goBack()
    }



    render() {
        return (
            <div className="canvasRoot">
                <div className="col-12" style={{backgroundColor:"white",height:"35px"}}>
                    <button className="btn btn-primary btn-xs" onClick={() => {this.backButton()}}><i className="la la-arrow-left"/></button>
                    <span className="ml-1">Geri</span>
                </div>
                <div className="design-change" style={{zIndex:4}}>
                    <Button.Group>
                        <Button toggle onClick={() => this.setActiveCanvas('design-content')}>İçerik</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('design-notify')}>Bildirim</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('preview')}>Önizleme</Button>
                    </Button.Group>
                </div>
                <div>
                    <div id="design-content" className="canvas-content">

                    </div>
                </div>

                <div id="design-notify" className="canvas-content">

                </div>
                <div id="preview" style={{ marginLeft: 'auto', marginRight: 'auto', width: 900, paddingLeft: 30, display: this.state.mode === 'preview' ? 'block' : 'none' }}>
                    <PreviewDevice
                        deviceType="DESKTOP"
                        device="macbookpro"
                        scale="0.8"
                        wallpaper="url(/images/macos-wallpaper.png)"
                        notification={{
                            show: true,
                            title: 'Mesaj başlığını buraya yazınız',
                            subtitle: 'Yazılı ve görsel mesajların e-posta trafiğine takılmadan çalışanlara ulaşmasını sağlar.',
                            body: 'Pazarlama',
                            snooze: 0,
                            top: 0,
                            content: this.editorNotify ? `<style>html,body{padding:0; margin:0};${this.editorNotify.getCss()}${PreviewCss.css}</style>${this.editorNotify.getHtml()}` : null
                        }}>
                        <PreviewWindow
                            bgcolor="#ffffff"
                            mediaType="POPUP"
                            title='The title of message'
                            subtitle='The sub title of message'>
                            {this.editorContent &&
                            <PreviewFrame
                                content={`<style>html,body{padding:0; margin:0};${this.editorContent.getCss()}</style>${this.editorContent.getHtml()}`} />
                            }
                        </PreviewWindow>
                    </PreviewDevice>

                </div>
            </div>
        )
    }
}

export default PublishAgainPage;
