import React from "react";
import settings from "../config/settings.json";
import Popup from "react-popup";
import tr from "date-fns/locale/tr";
import { DatePickerCalendar } from "react-nice-dates";
import PubSub from "pubsub-js";
import moment from "moment";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import {
  ProSidebar,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Tooltip } from "@trendmicro/react-tooltip";
import mediaList from "../config/media.json";
import {
  filterMessages,
  getCalenderDates,
  getOnlineUsers, getUser, getUsers,
  getWaitingTimes,
  sendPing,
} from "../services/api";
import Dashboard from "../pages/protected/Dashboard";
import Profile from "../pages/protected/Profile";
import Folders from "../pages/protected/Folders";
import Users from "../pages/protected/lists/Users";
import Settings from "../pages/protected/forms/Settings";
import PlatformUserItem from "../components/master/PlatformUserItem";
import { Button, Form, Grid, Modal, Tab } from "semantic-ui-react";
import MediaModalMenager from "../components/modals/MediaModalMenager";

import CreateMedia from "../pages/protected/forms/CreateMedia";
import CreatePopup from "../pages/protected/forms/CreatePopup";
import CreateTicker from "../pages/protected/forms/CreateTicker";

import MediaSettings from "../pages/protected/forms/MediaSettings";
import TemplateSettings from "../pages/protected/forms/TemplateSettings";
import TickerSettings from "../pages/protected/forms/TickerSettings";
import CreateTemplate from "../pages/protected/forms/CreateTemplate";
import TemplateDesignPage from "../pages/protected/TemplateDesignPage";
import PopupDesignPage from "../pages/protected/PopupDesignPage";
import EmergencyDesignPage from "../pages/protected/EmergencyDesignPage";
import NewsletterDesignPage from "../pages/protected/NewsletterDesignPage";
import LcvDesignPage from "../pages/protected/LcvDesignPage";
import SurveyDesignPage from "../pages/protected/SurveyDesignPage";
import QuizDesignPage from "../pages/protected/QuizDesignPage";
import TickerDesignPage from "../pages/protected/TickerDesignPage";
import LockDesignPage from "../pages/protected/LockDesignPage";
import WallpaperDesignPage from "../pages/protected/WallpaperDesignPage";
import ScreensaverDesignPage from "../pages/protected/ScreensaverDesignPage";
import SocialDesignPage from "../pages/protected/SocialDesignPage";
import TemplateSelectionPage from "../pages/protected/TemplateSelectionPage";
import PublishedPage from "../pages/protected/PublishedPage";
import PublishedTickerPage from "../pages/protected/PublishedTickerPage";
import PublishedQuizPage from "../pages/protected/PublishedQuizPage";
import PublishedSurveyPage from "../pages/protected/PublishedSurveyPage";
import PublishedElearningPage from "../pages/protected/PublishedElearningPage";
import DocumentManagement from "../pages/protected/DocumentManagement";

import ListMedia from "../pages/protected/lists/ListMedia";
import AuthService from "../services/auth.service";
import Reports from "../pages/protected/Reports";
import ReportsDetail from "../pages/protected/ReportsDetail";
import { MenuIcon } from "../components/master/MenuIcon";
import HelpFaq from "../pages/protected/HelpFaq";
import HelpHowTo from "../pages/protected/HelpHowTo";
import HelpContact from "../pages/protected/HelpContact";
import HelpGdpr from "../pages/protected/HelpGdpr";
import UserAvatar from "react-user-avatar";
import CustomStorage from "../models/CustomStorage";
import ChatBox, { ChatFrame } from "react-chat-plugin";
import firebase from "firebase/app";
import "firebase/database";
import fbConfig from "../config/firebase.json";
import LoadingOverlay from "react-loading-overlay";
import { toast, ToastContainer } from "react-toastify";
import ELearningDesignPage from "../pages/protected/ELearningDesignPage";
import { Picker } from "emoji-mart";
import { protectedApi } from "../services/axios";
import PublishAgainPage from "../pages/protected/PublishAgainPage";
import EmergencyModal from "../components/modals/CreateEmergencyModal";
import { Formik } from "formik";
import PopupSchema from "../models/validations/PopupFormValidations";
import { Form as FormikForm } from "formik";
import InitialMediaModel from "../models/constants/InitialMediaModel";
import * as API from "../services/api";
import CanvasManager from "../tools/CanvasManager";
import PublishAgainTicker from "../pages/protected/PublishAgainTicker";
import PublishAgainQuiz from "../pages/protected/PublishAgainQuiz";
import PublishAgainSurvey from "../pages/protected/PublishAgainSurvey";
import PublishAgainElearning from "../pages/protected/PublishAgainElearning";
import Swal from "sweetalert2";
import AnnouncementSettings from "../pages/protected/forms/AnnouncementSettings";
import CreatePassword from "../pages/public/CreatePassword";
import axios from "axios";
import VideoUrlPage from "../pages/protected/forms/VideoUrlPage";
import Folders_ from "../pages/protected/Folders_";

const CM = new CanvasManager();

class Master extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      logging: "",
      activeMenu: "dashboard",
      onlineUsers: [],
      receiverUser: null,
      canvas: {
        showPanel: false,
        model: null,
      },
      chat: {
        showChatbox: false,
        showIcon: false,
        messages: [],
      },
      sending: false,
      showTickerSettings: false,
      description: null,
      analysisEndNote: null,
      questions: null,
      traitToggle: false,
      message: "",
      position: "BOTTOM",
      speed: "1",
      periodInterval: "0",
      periodCount: "0",
      repeatCount: "1",
      bgcolor: "#FFFFFF",

      chatMessages: {},
      otherUsers: [],

      openEmergency: false,
      folders: [],
      templates: [],
      sendingText: "",

      modifiers: {},
      modifiersClassNames: {
        disabledRed: "-disabledRed",
        highlight: "-highlightMaster",
        clickableFalse: "-calenderClickFalseMaster",
      },
      pendingMessageDates: [],

      isPageSecondLoad: false,

      unseenMessageSenders: [],
      unseenMessageList: [],

      licenceInfo: {},
      licenceWarningCount: 0,

      canvasSelectedButton: null
    };

    this.cs = new CustomStorage();
    this.database = null;
    this.intervalToken = null;

    this.collapseHeader = {
      backgroundColor: "#3B4182",
      height: "38px",
      padding: "9px 10px 9px 20px",
      letterSpacing: "1px",
      color: "white",
      cursor: "pointer",
      borderBottom: "1px solid",
      borderColor: "#2c3161",
    };
    this.contentCss = { padding: 16 };

    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closeChat();
      }
    });
  }

  closeChat = () => {
    this.setState({ chat: { ...this.state.chat, showChatbox: false } });
  };


  componentDidMount = async () => {
    this.connectAws()
    let authResponse = await AuthService.getCurrentUser(true);
    let user = this.cs.getItem("user");

    if (user) {
      this.setState({ user: JSON.parse(user).user });
      this.startPing();
      this.loadOnlineUsers();
    }
    const fastEmergency = mediaList.filter(
      (data) => data.key === "EMERGENCY"
    )[0];

    this.setState({
      logging: authResponse,
      fastEmergency,
    });


    PubSub.subscribe("trait-toggle", this.showTrait);

    PubSub.subscribe("MENU", this.menuChanged);

    PubSub.subscribe("PROFILE", this.profileChanged);

    PubSub.subscribe("CANVAS", this.canvasChanged);

    PubSub.subscribe("TICKER", this.tickerChanged);

    PubSub.subscribe("current-template", this.setDescription);

    PubSub.subscribe("test-to-sent", this.setToPublished);

    PubSub.subscribe("load-users", this.loadOnlineUsers);

    PubSub.subscribe("load-calender", this.loadCalender);

    PubSub.subscribe("reload-calender", this.loadCalender);

    PubSub.subscribe('user-created', this.loadOnlineUsers);
    PubSub.subscribe('update-users', this.loadOnlineUsers);
    PubSub.subscribe('update-users-2', this.loadOnlineUsers)


    // PubSub.subscribe('button-block-selected', this.handleCanvasButtonSelected)

    // init firebase
    if (!firebase.apps.length) {
      firebase.initializeApp(fbConfig);
    }

    this.loadPendingMessages();
    this.loadCalender();

    // Sayfa yüklendiğinde chatbox'ın açık kalmamasını sağlıyor.
    this.chatNotification();

    this.haveAnyUnseenMessages(JSON.parse(user)?.user.pk)
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log(prevProps.location.pathname)
    //console.log(this.props.location.pathname)
  }

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  // CanvasManager tarafından gelen seçilmiş komponentin bilgilerini aldığımız sinyalin fonksiyonu
  // Amacı: Buton seçildiğinde Düzen tabında ayarlar çıkartabilmek ve seçili butonun bilgilerini almak
  // handleCanvasButtonSelected = (msg, data) => {
  //   //
  //   if(data.includes('button-block')){
  //     this.setState({canvasSelectedButton: data})
  //   }else {
  //     // Eğer ki buton seçili değilse Düzen tabında gözükmemesi için null eşitlemesi yapıyoruz.
  //     this.setState({canvasSelectedButton: null})
  //   }
  // }

  // Buton için açılan selectboxta seçim sonrası seçili işlemin canvasManager'a yönlendirmesinde kullanılacak fonksiyon.
  // handleSelectedButtonFunc = (event) => {
  //   const payload = {
  //     id: this.state.canvasSelectedButton,
  //     event: event.target.value
  //   }
  //   PubSub.publish('selected-button-event', payload)
  // }

  // 2 tarih arasındaki farkı gün olarak dönen fonksiyon
  diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));


  connectAws = () => {

    const config = {
      headers: { "x-api-key": "I2byJef7O13ymaItCl2Gq7hRuMIcaTvsaYy6Fzwt" },
    };
    const payload = { customerID: settings.customerID };
    axios.post("https://9b2gn7jd5h.execute-api.eu-central-1.amazonaws.com/default/checkLicenseDuyuroom", payload, config
    ).then((result) => {
      const validDate = moment(result.data.validuntil).format('YYYY-MM-DD')
      const now = moment().format('YYYY-MM-DD')
      const licenceDayLeft = this.diffDays(new Date(validDate), new Date(now))

      const alertText = `Lisans süreniz ${licenceDayLeft} gün içinde sona erecektir.`

      let showLicenceAlert = window.sessionStorage.getItem('showLicenceAlert')
      if (showLicenceAlert === null) {
        window.sessionStorage.setItem('showLicenceAlert', true)
      }
      if (showLicenceAlert === "true" && licenceDayLeft < 61) {
        setTimeout(() => {
          Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
          }).fire({
            icon: 'warning',
            title: 'Uyarı',
            text: alertText,
            showCloseButton: true
          }).then((res) => {
            if (res.isDismissed) {
              window.sessionStorage.setItem('showLicenceAlert', false)
            }
          })
        }, 1000)
      }

      this.setState({ licenceInfo: result.data })
    })
      .catch(err => {
        console.log('CATCH ERROR : ', err)
      })
  }

  showTrait = () => {
    this.setState({
      traitToggle: document
        .getElementById("traits-container")
        ?.innerHTML.includes('title="Link"'),
    });
  };

  loadPendingMessages = () => {
    filterMessages("PENDING").then((list) => {
      const tempArr = list?.map((data) => {
        return moment(data.startDate).format();
      });
      this.setState({
        pendingMessages: list,
        isLoaded: true,
        pendingMessageDates: tempArr,
      });
    });
  };

  startPing = async () => {
    this.intervalToken = setInterval(() => {
      this.loadOnlineUsers();
      sendPing();
    }, 60000);
  };

  loadOnlineUsers = () => {
    getOnlineUsers().then((users) => {
      if (users !== undefined) {
        let filteredUsers = users.filter(
          (data) => data?.profile?.isContentAdmin === true
        );
        let userId = JSON.parse(this.cs.getItem("user")).user.pk;
        let withoutMe = filteredUsers.filter((u) => u.id !== userId);
        // sort users by last_activity
        withoutMe.sort((a, b) =>
          a.profile.last_activity < b.profile.last_activity
            ? 1
            : b.profile.last_activity < a.profile.last_activity
              ? -1
              : 0
        );
        this.setState({ onlineUsers: withoutMe });
        // if (!this.state.isPageSecondLoad) {
        //   setTimeout(() => {
        //     withoutMe.map((user) => this.openChat(user));
        //     this.setState({ isPageSecondLoad: true });
        //   }, 2500);
        // }
      }
    });
  };

  loadCalender = () => {
    getCalenderDates().then((data) => {
      if (data !== undefined) {
        const tempArr = data.map((date) => {
          return moment(date.date).format();
        });
        this.setState({
          disableDateInfo: data,
          calenderDates: tempArr,
          modifiers: {
            disabledRed: (date) => {
              let newDate = moment(date).format();
              const isDisabled = this.state.calenderDates.some(
                (dateToDisable) => {
                  if (newDate === dateToDisable) {
                    return true;
                  }
                }
              );
              return isDisabled;
            },
            highlight: (date) => {
              let newDate = moment(date).format("YYYY-MM-DD");
              const highlighted = this.state.pendingMessageDates.some(
                (highlight) => {
                  let newHighlight = moment(highlight).format("YYYY-MM-DD");
                  if (newDate === newHighlight) {
                    return true;
                  }
                }
              );
              return highlighted;
            },
            clickableFalse: (date) => true,
          },
        });
      }
    });
  };

  dateChanged = (e) => {
    alert("DATECHANGED !")
    const format = "YYYY-MM-DD HH:mm:ss";
    const newDateStart = moment(e).startOf("day").format(format);
    const newDateEnd = moment(e).endOf("day").format(format);

    const disableDate = this.state.calenderDates.filter(
      (date) => date === String(moment(e).format())
    );

    if (disableDate.length !== 0) {
      alert("Disable DATE TIKLANDI")
      const dateInfo = this.state.disableDateInfo.filter(
        (date) => date.date === moment(e).format("YYYY-MM-DD")
      );
      const textForAlert =
        dateInfo[0].createdby.first_name +
        " " +
        dateInfo[0].createdby.last_name +
        " tarafından '" +
        dateInfo[0].description +
        "' sebebiyle kapatılmıştır.";
      Swal.fire({
        icon: "info",
        title: moment(dateInfo[0].date).format("DD/MM/YYYY"),
        text: textForAlert,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: "#3B4182",
        cancelButtonText: "Tamam",
        focusCancel: false,
      });
    } else {
      PubSub.publish("filterDate", {
        startDate: newDateStart,
        endDate: newDateEnd,
      });
      this.props.history.push("/dashboard");
    }

    let dateObject = {
      iso: moment(e),
      readable: moment(e).format("DD.MM.YYYY"),
    };
    PubSub.publish("DATECHANGED", dateObject);
  };

  handleLogout = () => {
    AuthService.logout();
    document.location.reload();
  };

  profileChanged = (msg, data) => {


    let user = { ...this.state.user };
    try {

      user["profile"]["img"] = data;
      this.setState({ user: user }, () => {
        let user = JSON.parse(sessionStorage.getItem("user"))
        if (user !== null) {
          user.user.profile.img = data
          sessionStorage.setItem("user", JSON.stringify(user))
        } else {
          let user = JSON.parse(window.localStorage.getItem("user"))
          user.user.profile.img = data

          window.localStorage.setItem("user", JSON.stringify(user))
        }
        this.setState({ user: user })

      });
    } catch (e) { }


  };

  canvasChanged = (msg, data) => {
    if (msg === "CANVAS") {
      this.setState({ canvas: data });
    }
  };

  tickerChanged = (msg, data) => {
    if (msg === "TICKER") {
      this.setState({ showTickerSettings: data });
    }
  };

  menuChanged = (msg, data) => {
    this.setState({ activeMenu: data });
  };

  // handleDate = (msg, data) => {
  //     this.setState({modifiers: data}, () => console.log('DATA : ',this.state.modifiers))
  // }

  conversationNode = (id1, id2) => {
    if (parseInt(id1) > parseInt(id2)) {
      return "messages-" + id2 + "-" + id1;
    } else {
      return "messages-" + id1 + "-" + id2;
    }
  };

  conversationNodeForUnseenMessages = (id1, id2) => {
    if (parseInt(id1) > parseInt(id2)) {
      return id2 + "-" + id1;
    } else {
      return id1 + "-" + id2;
    }
  };

  handleOnSendMessage = (message) => {
    const myId = this.state.user.pk;
    const otherUserId = this.state.receiverUser.id;
    const room = this.conversationNode(myId, otherUserId);

    let date = +new Date()

    // firebase insert
    firebase
      .database()
      .ref(settings.customerID + "/chat/" + room)
      .push({
        author: {
          username:
            this.state.user.first_name + " " + this.state.user.last_name,
          id: this.state.user.pk,
          avatarUrl: null,
        },
        text: message,
        type: "text",
        timestamp: date,
        hasError: false,
      });

    const seenRoom = this.conversationNodeForUnseenMessages(myId, otherUserId);
    const seenTableUrl = settings.customerID + "/unseenMessages/" + seenRoom
    firebase.database().ref(seenTableUrl).push({
      authorId: myId,
      otherUserId: otherUserId,
    })
    firebase.database().ref(seenTableUrl).update({
      seen: false,
      whoSend: myId,
      isWebVisible: true
    })
  };


  chatNotification = () => {
    const myId = this.state.user?.pk;

    firebase
      .database()
      .ref(settings.customerID)
      .on("value", (snapshot) => {
        let tempArr = [];
        let otherUsers = [];
        snapshot.forEach((node) => {
          let chatObj = node.val();
          tempArr.push(chatObj);
        });
        let chatData = tempArr[0];
        // get all object keys and iterate over them
        Object.keys(chatData).forEach((data, index) => {
          if (
            data.includes(`messages-${myId}-` || data.slice(-2) === `-${myId}`)
          ) {
          } else {
            delete chatData[data];
          }
        });

        Object.keys(chatData).forEach((data, index) => {
          let tempStr = data;
          tempStr = tempStr.replace("messages-", "");
          tempStr = tempStr.replace("-", "");
          tempStr = tempStr.replace(myId, "");
          this.state.onlineUsers.map((user) => {
            if (user.id === Number(tempStr)) {
              otherUsers.push(user);
            }
          });
        });
        this.setState({ chatMessages: chatData, otherUsers: otherUsers });

        // this.setState({
        //     receiverUser: withUser,
        //     chat: {...this.state.chat, showChatbox: true, messages: chatData}
        // })
      });
  };

  haveAnyUnseenMessages = (myId) => {
    const seenTableUrl = settings.customerID + "/unseenMessages/"
    let activeArray = []
    let passiveArray = []
    let concatArray = []

    firebase.database().ref(seenTableUrl).on('value', (snapshot) => {
      let unseenMessageSenderIds = []
      let seenArray = []
      if (snapshot.val()) {
        for (const [key, value] of Object.entries(snapshot.val())) {
          for (const [key2, value2] of Object.entries(value)) {
            if (value.isWebVisible && value2.otherUserId === myId) {
              let otherId = key?.replace(myId, '').replace('-', '');
              if (!activeArray.includes(Number(otherId)) && !passiveArray.includes((Number(otherId)))) {
                if (!value.seen && value.whoSend !== myId) {
                  activeArray.push(Number(otherId))
                } else {
                  passiveArray.push(Number(otherId))
                }
              }

              if (!unseenMessageSenderIds.includes(Number(otherId))) {
                seenArray.push({ id: otherId, seen: value.seen, whoSend: value.whoSend })
                unseenMessageSenderIds.push(Number(otherId));
              }
            }
          }
        }
        this.setState({ unseenMessageList: seenArray })
      }

      concatArray = activeArray.concat(passiveArray)

      let orderedUserList = []
      getUsers().then(res => {
        if (!res) return

        concatArray.map(data => {
          let user = res.filter(user => data === user.id)
          orderedUserList.push(user[0])
        })
        this.setState({ unseenMessageSenders: orderedUserList })
      })


    })
  }

  openChat = (withUser) => {
    const myId = this.state.user?.pk;
    const otherUserId = withUser.id;
    const room = this.conversationNode(myId, otherUserId);

    this.haveAnyUnseenMessages(myId);

    // if(this.state.isPageSecondLoad){
    //   this.setState({
    //     chat: {
    //       ...this.state.chat,
    //       showChatbox: true}
    //   }, () => console.log('STATAT : ', this.state))
    // }

    this.setState({ receiverUser: withUser })
    this.state.chat.showChatbox = true

    // console.log('STATAT : ', this.state)

    firebase
      .database()
      .ref(settings.customerID + "/chat/" + room)
      .on("value", (snapshot) => {
        let chatData = [];
        snapshot.forEach((node) => {
          let chatObj = node.val();
          chatObj.author.username = null
          chatData.push(chatObj);
        });
        this.setState({
          chat: {
            ...this.state.chat,
            messages: (this.state.openChatUserId === otherUserId) ? chatData : this.state.chat.messages,
          },
        }, () => {
          if (this.state.chat.showChatbox) {
            const unseenMessageUrl = this.conversationNodeForUnseenMessages(myId, otherUserId)
            const tableUrl = settings.customerID + "/unseenMessages/" + unseenMessageUrl
            firebase.database().ref(tableUrl).on('value', (snapshot) => {
              snapshot.forEach((node) => {
                let room = node.val();
                // console.log('ROOM : ', room)
                // console.log('ROOM IS INT : ', Number.isInteger(room))
                // console.log('ROOM IS NOT MY ID : ', Number(room) !== Number(myId))
                if (Number.isInteger(room) && Number(room) !== Number(myId)) {
                  // CHANGE SEEN TO TRUE IF ITS FALSE
                  firebase.database().ref(tableUrl).update({
                    seen: true
                  })
                }
              })
            })
          }
        });
      });
  };

  deleteNotification = (withUser) => {
    Swal.fire({
      icon: 'warning',
      text: "Bildirimi silmek isediğinize emin misiniz?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#3B4182',
      confirmButtonText: 'Evet',
      cancelButtonColor: '#E40426',
      cancelButtonText: 'Hayır',
      focusCancel: false,
      reverseButtons: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const myId = this.state.user?.pk;
        const otherUserId = withUser.id;
        const unseenMessageUrl = this.conversationNodeForUnseenMessages(myId, otherUserId)
        const tableUrl = settings.customerID + "/unseenMessages/" + unseenMessageUrl

        firebase.database().ref(tableUrl).update({ isWebVisible: false })
          .then((res) => this.haveAnyUnseenMessages(myId))
          .catch(err => console.log('ERROR : ', err))
      }
    })

  }


  getTemplate = () => {
    if (this.state.canvas.model.type === "POPUP") {
      PubSub.publish("ask-template-popup", {});
    } else if (this.state.canvas.model.type === "SURVEY") {
      PubSub.publish("ask-template-survey", {});
    } else if (this.state.canvas.model.type === "QUIZ") {
      PubSub.publish("ask-template-quiz", {});
    } else if (this.state.canvas.model.type === "LOCK") {
      PubSub.publish("ask-template-lock", {});
    } else if (this.state.canvas.model.type === "LCV") {
      PubSub.publish("ask-template-lcv", {});
    } else if (this.state.canvas.model.type === "NEWSLETTER") {
      PubSub.publish("ask-template-newsletter", {});
    } else if (this.state.canvas.model.type === "EMERGENCY") {
      PubSub.publish("ask-template-emergency", {});
    } else if (this.state.canvas.model.type === "WALLPAPER") {
      PubSub.publish("ask-template-wallpaper", {});
    } else if (this.state.canvas.model.type === "SCREENSAVER") {
      PubSub.publish("ask-template-screensaver", {});
    } else if (this.state.canvas.model.type === "SOCIAL") {
      PubSub.publish("ask-template-social", {});
    } else if (this.state.canvas.model.type === "TICKER") {
      PubSub.publish("ask-template-ticker", {});
    } else if (this.state.canvas.model.type === "ELEARNING") {
      PubSub.publish("ask-template-elearning", {});
    }
  };
  setDescription = (msg, data) => {
    if (this.state.canvas?.model?.type === "POPUP") {
      this.setState(
        {
          description: data.content,
          notifyHtml: data.notify,
          isUpdate: data.isUpdate,
          templateWidth: data.width,
          templateHeight: data.height,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "SURVEY") {
      this.setState(
        {
          description: data.beginNote,
          analysisEndNote: data.endNote,
          questions: data.questions,
          notifyHtml: data.notify,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "QUIZ") {
      this.setState(
        {
          description: data.beginNote,
          analysisEndNote: data.endNote,
          questions: data.questions,
          notifyHtml: data.notify,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "LOCK") {
      this.setState(
        {
          wallPaperHtml: data.html,
          wallPaperCss: data.css,
          notifyHtml: data.notify,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "LCV") {
      this.setState(
        {
          description: data.content,
          notifyHtml: data.notify,
          templateWidth: data.width,
          templateHeight: data.height,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "NEWSLETTER") {
      this.setState(
        {
          description: data.content,
          notifyHtml: data.notify,
          templateWidth: data.width,
          templateHeight: data.height,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "EMERGENCY") {
      this.setState(
        {
          description: data.content,
          notifyHtml: data.notify,
          templateWidth: data.width,
          templateHeight: data.height,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "WALLPAPER") {
      this.setState(
        {
          wallPaperHtml: data.html,
          wallPaperCss: data.css,
          notifyHtml: data.notify,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "SCREENSAVER") {
      this.setState(
        {
          wallPaperHtml: data.html,
          wallPaperCss: data.css,
          notifyHtml: data.notify,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "SOCIAL") {
      this.setState(
        { description: data.content, notifyHtml: data.notify },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "TICKER") {
      this.setState(
        {
          bgcolor: data.bgcolor,
          repeatCount: String(data.repeatCount),
          periodCount: data.periodCount,
          message: data.message,
          speed: data.speed,
          position: data.position,
          periodInterval: data.periodInterval,
          folder: data.folder,
          title: data.title,
          notifyHtml: data.notify,
        },
        async () => await this.startSendMessage()
      );
    } else if (this.state.canvas?.model?.type === "ELEARNING") {
      this.setState(
        {
          description: data.beginNote,
          analysisEndNote: data.endNote,
          questions: data.questions,
          files: data.files,
          notifyHtml: data.notify,
        },
        async () => await this.startSendMessage()
      );
    }
  };

  getValues = (values) => {
    this.setState({ canvas: { ...this.state.canvas, model: values } }, () =>
      this.getTemplate()
    );
  };

  startSendMessage = async () => {
    let sendingText =
      this.state.canvas.model.isPublished === "SENT"
        ? ""
        : this.state.canvas.model.isPublished === "DRAFT"
          ? "Taslaklara kaydediliyor..."
          : this.state.canvas.model.isPublished === "TEST"
            ? "Test grubuna gönderiliyor..."
            : "";
    this.setState({ sending: true, showSettings: false, sendingText });
    let payload = {
      notifyHtml: this.state.notifyHtml,
      announcementType: this.state.canvas.model.announcementType,
      description: this.state.description,
      summary: this.state.canvas.model.title,
      type: this.state.canvas.model.type,
      deliveryOption: this.state.canvas.model.deliveryOption,
      notificationType: this.state.canvas.model.notificationType,
      folder: this.state.canvas.model.folder,
      groups: this.state.canvas.model.groups,
      IsAllGroups: this.state.canvas.model.isAllGroups,
      bgcolor: this.state.bgcolor,
      startDate: this.state.canvas.model.startDate
        ? this.state.canvas.model.startDate
        : "",
      endDate: this.state.canvas.model.endDate, // TODO: hemen seciliyse
      isPublished: this.state.canvas.model.isPublished,
      sender: this.state.canvas.model.sender,
      snoozeTime:
        this.state.canvas.model.notificationType !== "POPUP"
          ? 0
          : this.state.canvas.model.snoozeTime === ""
            ? 0
            : this.state.canvas.model.snoozeTime,
      snoozeCount:
        this.state.canvas.model.notificationType !== "POPUP"
          ? 0
          : this.state.canvas.model.snoozeCount === ""
            ? 0
            : this.state.canvas.model.snoozeCount,
      title: this.state.canvas.model.title,
      position:
        this.state.canvas.model.type === "QUIZ" ||
          this.state.canvas.model.type === "ELEARNING"
          ? this.state.canvas.model.showAnswers
            ? "TOP"
            : "BOTTOM"
          : this.state.position,
      speed: this.state.speed,
      wallPaperHtml: this.state.wallPaperHtml ? this.state.wallPaperHtml : "",
      wallPaperCss: this.state.wallPaperCss ? this.state.wallPaperCss : "",
      repeatCount: this.state.canvas.model.repeatCount,
      tickermessages: this.state.message,
      questions: this.state.questions,
      analysisEndNote: this.state.analysisEndNote,
      video:
        this.state.canvas.model.video !== null
          ? this.state.canvas.model.video.id
          : null,
      image:
        this.state.canvas.model.image !== null
          ? this.state.canvas.model.image.id
          : null,
      lcv: this.state.canvas.model.lcv,
      windowSize: this.state.canvas.model.windowSize,
      periodInterval: this.state.canvas.model.periodInterval,
      periodCount: this.state.canvas.model.periodCount,
      repeatBy: this.state.canvas.model.repeatBy,
      periodReaming: this.state.canvas.model.periodCount,
      dataClassification: this.state.canvas.model.dataClassification,
      isVisible: true,
      width: this.state.templateWidth !== null ? this.state.templateWidth : 600,
      height: this.state.templateHeight !== null ? this.state.templateHeight : 550,
      files:
        this.state.canvas.model.type === "ELEARNING"
          ? this.state.files
          : this.state.canvas.model.files,
      includeUser: this.state.canvas.model.includeUser
        ? this.state.canvas.model.includeUser.map((item) => item.value)
        : [],
      excludeUser: this.state.canvas.model.excludeUser
        ? this.state.canvas.model.excludeUser.map((item) => item.value)
        : [],
    };

    getWaitingTimes("1").then((result) => {
      this.setState(
        {
          waitingTime:
            result[`${this.state.canvas.model.type.toLowerCase()}WaitingTime`] *
            1000,
        },
        () => {
          const payloadTimeout = setTimeout(() => {
            protectedApi
              .post("/announcement/announcements", payload)
              .then((response) => {
                if (response) {
                  if (payload.isPublished === "SENT") {
                    Swal.fire({
                      icon: "success",
                      title: "Mesajınız gönderildi!",
                      showCancelButton: true,
                      showConfirmButton: false,
                      cancelButtonColor: "#3B4182",
                      cancelButtonText: "Tamam",
                      focusCancel: false,
                    });
                  } else if (payload.isPublished === "DRAFT") {
                    Swal.fire({
                      icon: "success",
                      title: "Mesajınız taslaklara kaydedildi!",
                      showCancelButton: true,
                      showConfirmButton: false,
                      cancelButtonColor: "#3B4182",
                      cancelButtonText: "Tamam",
                      focusCancel: false,
                    });
                  } else if (payload.isPublished === "TEST") {
                    Swal.fire({
                      icon: "success",
                      title: "Mesajınız test grubuna gönderildi!",
                      showCancelButton: true,
                      showConfirmButton: false,
                      cancelButtonColor: "#3B4182",
                      cancelButtonText: "Tamam",
                      focusCancel: false,
                    });
                  }
                }

                this.setState({ sending: false });
                this.props.history.push("/dashboard");
              })
              .catch((error) => {
                console.log("HATA : ", error);
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Duyuru oluşturulamadı!',
                //     showCancelButton: true,
                //     showConfirmButton: false,
                //     cancelButtonColor: '#3B4182',
                //     cancelButtonText: "Tamam",
                //     focusCancel: false,
                // })
                this.setState({ sending: false });
              });
          }, this.state.waitingTime + 1000);

          toast.warn(
            (contentProps) =>
              this.UndoMessageButton(this.stopMessage, contentProps, payload.isPublished),
            {
              onClose: () => {
                clearTimeout(payloadTimeout);
                if (this.state.sending) {
                  this.props.history.push("/dashboard");
                } else {
                  console.log("sending canceled");
                }
              },
            }
          );
        }
      );
    });
  };

  setToPublished = (msg, data) => {
    getWaitingTimes("1").then((result) => {
      this.setState(
        {
          waitingTime:
            result[`${this.state.canvas.model.type.toLowerCase()}WaitingTime`] *
            1000,
        },
        () => {
          if (window.publishControl) {
            let sendingText = "";
            const payload = {
              isPublished: "SENT",
            };
            this.setState({ sending: true, sendingText });

            const payloadTimeout = setTimeout(() => {
              protectedApi
                .put("/announcement/announcement/" + data, payload)
                .then((response) => {
                  this.setState({ sending: false });
                  this.props.history.push("/dashboard");
                })
                .catch((error) => {
                  console.log(error);
                });
            }, this.state.waitingTime + 1000);

            toast.warn(
              (contentProps) =>
                this.UndoMessageButton(this.stopMessage, contentProps, payload.isPublished),
              {
                onClose: () => {
                  clearTimeout(payloadTimeout);
                  if (this.state.sending) {
                    this.props.history.push("/dashboard");
                  } else {
                    console.log("sending canceled");
                  }
                },
              }
            );
          }
          window.publishControl = true;
        }
      );
    });
  };

  stopMessage = () => {
    this.setState({ sending: false });
  };

  UndoMessageButton = (onUndo, contentProps, isPublished) => {
    const handleClick = () => {
      Swal.fire({
        icon: 'info',
        title: 'Gönderiminiz durduruldu.',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: '#3B4182',
        cancelButtonText: "Tamam",
        focusCancel: false,

      })
      onUndo();
      contentProps.closeToast();
    };

    return (
      <div>
        <span style={{ marginTop: 6 }}>{isPublished === 'DRAFT' ? 'Kaydediliyor' : 'Gönderiliyor'}&hellip;</span>
        <button
          className="float-right btn btn-xs btn-primary"
          onClick={handleClick}
        >
          Durdur
        </button>
      </div>
    );
  };

  onSubmitEmergency = (values, actions) => {
    setTimeout(() => {
      // alert(JSON.stringify(values, null, 2));
      actions.setSubmitting(false);

      this.setOpenEmergency(false);

      if (this.props.history) {
        values["pageTitle"] = "Acil Durum Mesajı";
        values["type"] = "EMERGENCY";
        this.props.history.push("/publish/emergency", values);
      }
    }, 400);
  };

  setOpenEmergency = (state) => {
    if (state) {
      this.loadDataEmergency().then(() => { });
    }
    this.setState({ openEmergency: state });
  };

  async loadDataEmergency() {
    const [folders, templates] = await Promise.all([
      API.getFolders(),
      API.getTemplates("EMERGENCY"),
    ]);

    this.setState({ folders, templates });
  }

  loadTemplateEmergency(id) {
    API.getTemplate(id).then((response) => {
      if (response && this.editorContent) {
        this.editorContent.setComponents("");
        this.editorContent.setStyle("");
        if (this.editorContainer) this.editorContainer.style.display = "none";

        if (response.templateJson.content) {
          if (response.templateJson.content.html) {
            this.editorContent.setComponents(
              CM.GetLiquidTemplateContent(response.templateJson.content.html)
            );
            if (this.editorContainer)
              this.editorContainer.style.display = "block";
          }

          if (response.templateJson.content.css) {
            this.editorContent.setStyle(response.templateJson.content.css);
            if (this.editorContainer)
              this.editorContainer.style.display = "block";
          }
        }

        const updateComp = (component) => {
          if (component) {
            component.set({
              removable: false,
              editable: false,
              selectable: false,
              highlightable: false,
              hoverable: false,
            });
            component.get("components").each((c) => updateComp(c));
          }
        };
        this.editorContent.getComponents().each((c) => updateComp(c));
      }
    });
  }

  handleCanvasContainerEmergency = (e) => {
    this.editorContainer = e;
    this.editorContent = CM.CreateCanvas("preview-design-content");
    CM.AppendDefaultHeadLinks();
    if (this.editorContainer) this.editorContainer.style.display = "none";
  };

  render() {
    const modelEmergency = Object.assign(InitialMediaModel, {
      type: "EMERGENCY",
      announcementType: "MESSAGE",
    });
    if (this.state.logging === "") {
      return null;
    } else if (this.state.logging === false) {
      return <Redirect to="/authentication/login" />;
    }
    return (
      <div id="main-wrapper" className="menu-toggle">
        <div
          className="nav-heading"
          style={{ width: "80px", backgroundColor: "#3B4182" }}
        >
          <Link to="/dashboard" className="brand-logo">
            <img
              alt="logo"
              className="logo-abbr"
              style={{ width: "80px" }}
              src="/images/logo.inverted.png"
            />
          </Link>
        </div>
        <LoadingOverlay
          active={this.state.sending}
          // spinner
          // text=""
          styles={{ wrapper: { width: "100%", height: "100%" } }}
        >
          <div className="heading" style={{ zIndex: 9 }}>
            <div className="heading-content">
              <nav className="navbar navbar-expand">
                <div className="collapse navbar-collapse justify-content-between">
                  <div className="heading-left">
                    <div className="dashboard_bar" style={{ paddingTop: 10 }}>
                      <h1
                        id="pageTitle"
                        style={{ fontSize: "1vw", lineHeight: "20px" }}
                      />
                      <h6
                        id="pageSubTitle"
                        style={{
                          fontWeight: "normal",
                          fontSize: "0.7vw",
                          position: "absolute",
                        }}
                        className="text-muted"
                      />
                    </div>
                  </div>
                  <ul className="navbar-nav heading-right">
                    {mediaList.map((item, index) => {
                      item.licence = this.state.licenceInfo
                      return (
                        <MediaModalMenager key={`menu_${index}`} data={item} onClick={() => {
                          return;
                        }} />
                      );
                    })}
                    <li className="dropdown schedule-event">
                      <div
                        className="nav-item dropdown notification_dropdown"
                        style={{ marginRight: 10 }}
                      >
                        <a
                          className="nav-link primary"
                          href="javascript:void(0)"
                          role="button"
                          data-toggle="dropdown"
                          style={{ padding: 10, borderRadius: 30 }}
                        >
                          <Tooltip
                            content="Duyuroom Destek Merkezi"
                            placement="bottom"
                          >
                            <img
                              src="/images/icons/Sendy.svg"
                              width={42}
                              height={42}
                            />
                          </Tooltip>
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          style={{ zIndex: 9999 }}
                        >
                          <Link to="/help/contact" className="dropdown-item">
                            <span className="ml-2">Bize Ulaşın</span>
                          </Link>
                          <Link to="/help/howto" className="dropdown-item">
                            <span className="ml-2">Teknik Bilgiler</span>
                          </Link>
                          <Link to="/help/faq" className="dropdown-item">
                            <span className="ml-2">Sıkça Sorulan Sorular</span>
                          </Link>
                        </div>
                      </div>

                      <div
                        className="nav-item dropdown notification_dropdown"
                        style={{ marginRight: 10 }}
                      >
                        <a
                          className="nav-link primary"
                          href="javascript:void(0)"
                          role="button"
                          data-toggle="dropdown"
                          style={{ borderRadius: 30 }}
                        >
                          <Tooltip content="Mesajlar" placement="bottom">
                            <svg
                              width={32}
                              height={32}
                              viewBox="0 0 26 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.75 14.8385V12.0463C21.7471 9.88552 20.9385 7.80353 19.4821 6.20735C18.0258 4.61116 16.0264 3.61555 13.875 3.41516V1.625C13.875 1.39294 13.7828 1.17038 13.6187 1.00628C13.4546 0.842187 13.2321 0.75 13 0.75C12.7679 0.75 12.5454 0.842187 12.3813 1.00628C12.2172 1.17038 12.125 1.39294 12.125 1.625V3.41534C9.97361 3.61572 7.97429 4.61131 6.51794 6.20746C5.06159 7.80361 4.25291 9.88555 4.25 12.0463V14.8383C3.26257 15.0412 2.37529 15.5784 1.73774 16.3593C1.10019 17.1401 0.751339 18.1169 0.75 19.125C0.750764 19.821 1.02757 20.4882 1.51969 20.9803C2.01181 21.4724 2.67904 21.7492 3.375 21.75H8.71346C8.91521 22.738 9.45205 23.6259 10.2331 24.2636C11.0142 24.9013 11.9916 25.2497 13 25.2497C14.0084 25.2497 14.9858 24.9013 15.7669 24.2636C16.548 23.6259 17.0848 22.738 17.2865 21.75H22.625C23.321 21.7492 23.9882 21.4724 24.4803 20.9803C24.9724 20.4882 25.2492 19.821 25.25 19.125C25.2486 18.117 24.8998 17.1402 24.2622 16.3594C23.6247 15.5786 22.7374 15.0414 21.75 14.8385ZM6 12.0463C6.00232 10.2113 6.73226 8.45223 8.02974 7.15474C9.32723 5.85726 11.0863 5.12732 12.9212 5.125H13.0788C14.9137 5.12732 16.6728 5.85726 17.9703 7.15474C19.2677 8.45223 19.9977 10.2113 20 12.0463V14.75H6V12.0463ZM13 23.5C12.4589 23.4983 11.9316 23.3292 11.4905 23.0159C11.0493 22.7026 10.716 22.2604 10.5363 21.75H15.4637C15.284 22.2604 14.9507 22.7026 14.5095 23.0159C14.0684 23.3292 13.5411 23.4983 13 23.5ZM22.625 20H3.375C3.14298 19.9999 2.9205 19.9076 2.75644 19.7436C2.59237 19.5795 2.50014 19.357 2.5 19.125C2.50076 18.429 2.77757 17.7618 3.26969 17.2697C3.76181 16.7776 4.42904 16.5008 5.125 16.5H20.875C21.571 16.5008 22.2382 16.7776 22.7303 17.2697C23.2224 17.7618 23.4992 18.429 23.5 19.125C23.4999 19.357 23.4076 19.5795 23.2436 19.7436C23.0795 19.9076 22.857 19.9999 22.625 20Z"
                                fill="#000"
                              />
                            </svg>
                          </Tooltip>
                          {this.state.unseenMessageSenders.map((user, index) => {
                            return (
                              this.state.unseenMessageList.map(msg => {
                                let a = 1
                                if (Number(msg?.id) === user?.id && !msg.seen && msg.whoSend === user?.id) {
                                  a = 2
                                }
                                return (
                                  <div key={index} style={{ display: a !== 2 && 'none' }} className="pulse-css" />
                                );
                              })
                            )
                          })}
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <div
                            id="DZ_W_Notification1"
                            className="widget-media dz-scroll p-3"
                            style={{ height: "380px", overflowY: "scroll" }}
                          >
                            <ul className="timeline">
                              {this.state.unseenMessageSenders.map((user, index) => {
                                let fontWeight = ""
                                this.state.unseenMessageList.map(msg => {
                                  if (Number(msg?.id) === user?.id && !msg.seen && msg.whoSend === user?.id) {
                                    fontWeight = "900"
                                  } else {
                                  }
                                })
                                return (
                                  <li key={index}>
                                    <div className="timeline-panel">
                                      <div className="media mr-2">
                                        <img
                                          alt="image"
                                          src={
                                            user?.profile && user?.profile.img
                                              ? user?.profile?.img
                                              : "https://w7.pngwing.com/pngs/340/956/png-transparent-profile-user-icon-computer-icons-user-profile-head-ico-miscellaneous-black-desktop-wallpaper.png"
                                          }
                                          width={30}
                                        />
                                      </div>
                                      <div className="media-body mr-1">
                                        <h6 className="mb-1" style={{ fontWeight: fontWeight !== "" ? fontWeight : "300" }}>
                                          {user?.first_name +
                                            " " +
                                            user?.last_name}{" "}
                                          size mesaj gönderdi
                                        </h6>
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          this.setState({ openChatUserId: user.id }, () => {
                                            this.openChat(user);
                                          })
                                        }}
                                        className={`btn btn-success btn-xs float-right mt-2`}
                                      >
                                        <i className="la la-send" />
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => this.deleteNotification(user)}
                                        className={`btn btn-danger btn-xs float-right mt-2 ml-1`}
                                      >
                                        <i className="la la-trash" />
                                      </button>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="nav-item dropdown heading-profile">
                        <a
                          className="nav-link"
                          href="javascript:void(0)"
                          role="button"
                          data-toggle="dropdown"
                          style={{ borderRadius: 30 }}
                        >
                          <UserAvatar
                            size="62"
                            name={
                              this.state.user.first_name +
                              " " +
                              this.state.user.last_name
                            }
                            colors={[
                              "#fc5c65",
                              "#fd9644",
                              "#fed330",
                              "#26de81",
                              "#2bcbba",
                              "#eb3b5a",
                              "#fa8231",
                              "#f7b731",
                              "#20bf6b",
                              "#0fb9b1",
                              "#45aaf2",
                              "#4b7bec",
                              "#a55eea",
                              "#d1d8e0",
                              "#778ca3",
                              "#2d98da",
                              "#3867d6",
                              "#8854d0",
                              "#a5b1c2",
                              "#4b6584",
                            ]}
                            src={
                              this.state.user.profile
                                ? this.state.user.profile.img
                                : ""
                            }
                          />
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          style={{ zIndex: 9999 }}
                        >
                          <Link to="/profile" className="dropdown-item ai-icon">
                            <svg
                              id="icon-user1"
                              xmlns="http://www.w3.org/2000/svg"
                              className="text-primary"
                              width={18}
                              height={18}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                              <circle cx={12} cy={7} r={4} />
                            </svg>
                            <span className="ml-2">Profilim </span>
                          </Link>
                          <a
                            href="javascript:void(0)"
                            onClick={this.handleLogout}
                            className="dropdown-item ai-icon"
                          >
                            <svg
                              id="icon-logout"
                              xmlns="http://www.w3.org/2000/svg"
                              className="text-danger"
                              width={18}
                              height={18}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                              <polyline points="16 17 21 12 16 7" />
                              <line x1={21} y1={12} x2={9} y2={12} />
                            </svg>
                            <span className="ml-2">Çıkış </span>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div className="deznav">
            <ProSidebar collapsed>
              <SidebarContent>
                <Menu iconShape="circle" popperArrow>
                  <MenuItem
                    style={{ marginBottom: 25 }}
                    title="Yönetim Paneli"
                    icon={
                      <MenuIcon
                        icon="dashboard"
                        title="Yönetim Paneli"
                        visible={
                          this.state.activeMenu === "dashboard"
                            ? "visible"
                            : "hidden"
                        }
                      />
                    }
                  >
                    <Link to="/dashboard" />
                  </MenuItem>
                  <SubMenu
                    style={{ marginBottom: 25 }}
                    title="Mecralar"
                    icon={
                      <MenuIcon
                        icon="medias"
                        title="Mecralar"
                        visible={
                          this.state.activeMenu === "medias"
                            ? "visible"
                            : "hidden"
                        }
                      />
                    }
                  >
                    {mediaList.map((item, index) => {
                      item.licence = this.state.licenceInfo
                      item.licence["document"] = item.licence.library
                      return (
                        <MenuItem key={`mediaMenu_${index}`}>
                          {item.title}
                          <Link to={item.licence[item.key.toLowerCase()] === "False" ? "#" : item.key === 'DOCUMENT' ? '/documentmanagement' : `/media/list/${item.key}`}
                            onClick={() => {
                              if (item.licence[item.key.toLowerCase()] === "False") {
                                Swal.fire({
                                  icon: 'warning',
                                  text: 'Bu mecranın kullanımı lisansınıza dahil değil. Lütfen müşteri temsilciniz ile iletişime geçiniz.',
                                  showCancelButton: true,
                                  showConfirmButton: false,
                                  cancelButtonColor: '#3B4182',
                                  cancelButtonText: "Tamam",
                                  focusCancel: false,
                                })
                              }
                            }} />
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                  <MenuItem
                    style={{ marginBottom: 25 }}
                    title="Klasörler"
                    icon={
                      <MenuIcon
                        icon="folders"
                        title="Klasörler"
                        visible={
                          this.state.activeMenu === "folders"
                            ? "visible"
                            : "hidden"
                        }
                      />
                    }
                  >
                    <Link to="/folders" />
                  </MenuItem>
                  <MenuItem
                    style={{ marginBottom: 25 }}
                    title="Kişiler"
                    icon={
                      <MenuIcon
                        icon="users"
                        title="Kişiler"
                        visible={
                          this.state.activeMenu === "users"
                            ? "visible"
                            : "hidden"
                        }
                      />
                    }
                  >
                    <Link to="/users" />
                  </MenuItem>
                  <MenuItem
                    style={{ marginBottom: 25 }}
                    title="Raporlar"
                    icon={
                      <MenuIcon
                        icon="reports"
                        title="Raporlar"
                        visible={
                          this.state.activeMenu === "reports"
                            ? "visible"
                            : "hidden"
                        }
                      />
                    }
                  >
                    <Link to="/reports" />
                  </MenuItem>
                  <MenuItem
                    style={{ marginBottom: 25 }}
                    title="Ayarlar"
                    icon={
                      <MenuIcon
                        icon="settings"
                        title="Ayarlar"
                        visible={
                          this.state.activeMenu === "settings"
                            ? "visible"
                            : "hidden"
                        }
                      />
                    }
                  >
                    <Link to={"/settings"} />
                  </MenuItem>
                  <MenuItem
                    style={{ marginBottom: 25 }}
                    title="Kanvas"
                    icon={
                      <MenuIcon
                        icon="editor"
                        title="Şablonlar"
                        visible={
                          this.state.activeMenu === "selectcanvas"
                            ? "visible"
                            : "hidden"
                        }
                      />
                    }
                  >
                    <Link to="/selectcanvas" />
                  </MenuItem>

                </Menu>
              </SidebarContent>
            </ProSidebar>
          </div>
          <div
            className="event-sidebar active"
            id="eventSidebar"
            style={{ zIndex: 2, top: 105, height: "calc(100% - 105px)" }}
          >
            {this.state.canvas.showPanel ? (
              <React.Fragment>
                <Tab
                  menu={{ secondary: true, pointing: true, borderless: true }}
                  renderActiveOnly={false}
                  defaultActiveIndex={
                    this.state.canvas.editTemplates
                      ? this.state.canvas.selected
                        ? 0
                        : 2
                      : 0
                  }
                  panes={
                    this.state.canvas.editTemplates
                      ? [
                        {
                          menuItem: "Araçlar",
                          pane: { content: <div id="canvasComponents" /> },
                        },
                        {
                          menuItem: "Düzen",
                          pane: {
                            content: (
                              <div>
                                <div id="canvasTools"></div>
                                {/* {this.state.canvasSelectedButton !== null &&
                                      <div className="row">
                                        <div className='col-sm-4'>
                                          <small className='mt-4' style={{marginLeft: 10}}>Buton İşlevi: </small>
                                        </div>
                                        <div className='col-sm-12'>
                                          <select className="form-select" style={{width:100, borderRadius:10, marginLeft:10}} onChange={e => this.handleSelectedButtonFunc(e)}>
                                            <option hidden>Seçiniz</option>
                                            <option value="1">Evet</option>
                                            <option value="0">Hayır</option>
                                            <option value="2">Belki</option>
                                          </select>
                                        </div>
                                      </div>
                                  } */}
                              </div>
                            ),
                          },
                        },
                        {
                          menuItem: "Şablonlar",
                          pane: {
                            content: <TemplateSettings />,
                          },
                        },
                        // {
                        //   menuItem: "Videolar",
                        //   pane: {
                        //     content: <VideoUrlPage />,
                        //   },
                        // },
                      ]
                      : [
                        {
                          menuItem: "Düzen",
                          pane: {
                            content: (
                              <div>
                                <div id="canvasTools"></div>

                                {/* {this.state.canvasSelectedButton !== null &&
                                      <div className="row">
                                        <div className='col-sm-4'>
                                          <small className='mt-4' style={{marginLeft: 10}}>Buton İşlevi: </small>
                                        </div>
                                        <div className='col-sm-12'>
                                          <select className="form-select" style={{width:100, borderRadius:10, marginLeft:10}} onChange={e => this.handleSelectedButtonFunc(e)}>
                                            <option hidden>Seçiniz</option>
                                            <option value="1">Evet</option>
                                            <option value="0">Hayır</option>
                                            <option value="2">Belki</option>
                                          </select>
                                        </div>
                                      </div>
                                  } */}
                              </div>
                            ),
                          },
                        },
                        {
                          menuItem: "Video Ekleme",
                          pane: {
                            content: <VideoUrlPage />,
                          },
                        },
                        {
                          menuItem: "Gönderi Ayarı",
                          pane: {
                            content: (
                              <AnnouncementSettings
                                mediaType={this.props.location.pathname}
                                model={this.state.canvas.model}
                                getValues={this.getValues}
                                validateMessage={this.validateMessage}
                              />
                            ),
                          },
                        },
                      ]
                  }
                />
              </React.Fragment>
            ) : this.state.showTickerSettings ? (
              <React.Fragment>
                <Tab
                  menu={{ secondary: true, pointing: true, borderless: true }}
                  renderActiveOnly={false}
                  defaultActiveIndex={0}
                  panes={[
                    {
                      menuItem: "Düzen",
                      pane: {
                        content: <TickerSettings type={"TICKER"} />,
                      },
                    },
                    {
                      menuItem: "Bildirim Ayarları",
                      pane: {
                        content: <div id="canvasTools"></div>,
                      },
                    },
                    {
                      menuItem: "Gönderi Ayarı",
                      pane: {
                        content: (
                          <AnnouncementSettings
                            mediaType={this.props.location.pathname}
                            type={"TICKER"}
                            model={this.state.canvas.model}
                            getValues={this.getValues}
                            validateMessage={this.validateMessage}
                          />
                        ),
                      },
                    },
                  ]}
                />
              </React.Fragment>
            ) : (
              <>
                <div className="card shadow-none rounded-0 bg-transparent h-auto mb-0">
                  <div className="card-body text-center event-calender pb-2">
                    <DatePickerCalendar
                      locale={tr}
                      modifiers={this.state.modifiers}
                      modifiersClassNames={this.state.modifiersClassNames}
                      onDateChange={this.dateChanged}
                    />
                  </div>
                </div>
                {this.state.onlineUsers.length > 0 && (
                  <div className="card shadow-none rounded-0 bg-transparent h-auto">
                    <div className="card-header border-0 pb-0">
                      <h4 className="text-black">İçerik Yöneticileri</h4>
                    </div>
                    <div className="card-body pb-3 pt-3">
                      {this.state.onlineUsers.map((user, index) => {
                        return (
                          <PlatformUserItem key={index}
                            image={user.profile ? user.profile.img : ""}
                            fullname={user.first_name + " " + user.last_name}
                            email={user.email}
                            lastActivity={user.profile.last_activity}
                            online={moment(user.profile.last_activity).isAfter(
                              moment().subtract(2, "minutes"),
                              "minutes"
                            )}
                            onClickChatButton={() => {
                              this.setState({ openChatUserId: user.id }, () => {
                                this.openChat(user);
                              })
                            }}
                          />
                        );
                      })}
                    </div>
                    <div
                      className="card-footer justify-content-between border-0 d-flex fs-14"
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <button
                        className="btn btn-block btn-primary btn-rounded btn-lg shadow"
                        onClick={() => this.setOpenEmergency(true)}
                      >
                        +Hızlı Duyuru
                      </button>
                      {/*<Link to="/media/add/EMERGENCY"*/}
                      {/*      className="btn btn-block btn-primary btn-rounded btn-lg shadow">+*/}
                      {/*    Hızlı Duyuru</Link>*/}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="content-body rightside-event">
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              {/*<Route path="/authentication/create-password/:token" component={CreatePassword} />*/}
              <Route path="/profile" component={Profile} />
              {/* <Route path="/folders_old" component={Folders} /> */}
              <Route path="/folders" component={Folders_} />
              <Route path="/folders/:id" component={Folders_} />
              <Route path="/users" component={Users} />
              <Route path="/settings" component={Settings} />
              <Route path="/report_detail/:id" component={ReportsDetail} />
              <Route path="/reports" component={Reports} />
              <Route path="/media/list/:type" component={ListMedia} />
              <Route path="/media/list/DOCUMENT" component={Profile} />

              <Route path="/publish/popup" component={PopupDesignPage} />
              <Route
                path="/publish/emergency"
                component={EmergencyDesignPage}
              />
              <Route
                path="/publish/newsletter"
                component={NewsletterDesignPage}
              />
              <Route path="/publish/lcv" component={LcvDesignPage} />
              <Route path="/publish/survey" component={SurveyDesignPage} />
              <Route path="/publish/quiz" component={QuizDesignPage} />
              <Route
                path="/publish/elearning"
                component={ELearningDesignPage}
              />
              <Route path="/publish/ticker" component={TickerDesignPage} />
              <Route path="/publish/lock" component={LockDesignPage} />
              <Route
                path="/publish/wallpaper"
                component={WallpaperDesignPage}
              />
              <Route
                path="/publish/screensaver"
                component={ScreensaverDesignPage}
              />
              <Route path="/publish/social" component={SocialDesignPage} />

              <Route path="/publish/again" component={PublishAgainPage} />
              <Route
                path="/publish/againticker"
                component={PublishAgainTicker}
              />
              <Route path="/publish/againquiz" component={PublishAgainQuiz} />
              <Route
                path="/publish/againsurvey"
                component={PublishAgainSurvey}
              />
              <Route
                path="/publish/againelearning"
                component={PublishAgainElearning}
              />

              <Route path="/publish/showcanvas" component={PublishedPage} />
              <Route
                path="/publish/showticker"
                component={PublishedTickerPage}
              />
              <Route path="/publish/showquiz" component={PublishedQuizPage} />
              <Route
                path="/publish/showsurvey"
                component={PublishedSurveyPage}
              />
              <Route
                path="/publish/showelearning"
                component={PublishedElearningPage}
              />

              <Route path="/canvas" component={TemplateDesignPage} />
              <Route path="/selectcanvas" component={TemplateSelectionPage} />
              <Route
                path="/documentmanagement"
                component={DocumentManagement}
              />

              <Route path="/media/:action/POPUP" component={CreatePopup} />
              <Route path="/media/:action/TICKER" component={CreateTicker} />

              <Route path="/media/:action/:type/:id" component={CreateMedia} />
              <Route path="/media/:action/:type" component={CreateMedia} />
              <Route path="/help/faq" component={HelpFaq} />
              <Route path="/help/howto" component={HelpHowTo} />
              <Route path="/help/contact" component={HelpContact} />
              <Route path="/help/gdpr" component={HelpGdpr} />
              <Route path="/" component={Dashboard} />
            </Switch>
          </div>

          <ChatFrame
            chatbox={
              <div>
                <div className="chatHeader">
                  <div className="row">
                    <div className="col-1">
                      <UserAvatar
                        size="36"
                        name={
                          this.state.receiverUser &&
                          this.state.receiverUser.first_name +
                          " " +
                          this.state.receiverUser.last_name
                        }
                        colors={[
                          "#fc5c65",
                          "#fd9644",
                          "#fed330",
                          "#26de81",
                          "#2bcbba",
                          "#eb3b5a",
                          "#fa8231",
                          "#f7b731",
                          "#20bf6b",
                          "#0fb9b1",
                          "#45aaf2",
                          "#4b7bec",
                          "#a55eea",
                          "#d1d8e0",
                          "#778ca3",
                          "#2d98da",
                          "#3867d6",
                          "#8854d0",
                          "#a5b1c2",
                          "#4b6584",
                        ]}
                        src={
                          this.state.receiverUser &&
                            this.state.receiverUser.profile
                            ? this.state.receiverUser.profile.img
                            : ""
                        }
                      />
                    </div>
                    <div className="col-9">
                      <h5
                        style={{
                          paddingTop: 8,
                          marginLeft: 10,
                        }}
                      >
                        {this.state.receiverUser &&
                          this.state.receiverUser.first_name +
                          " " +
                          this.state.receiverUser.last_name}
                      </h5>
                    </div>
                    <div className="col-2">
                      <button
                        onClick={() => this.closeChat()}
                        className="btn btn-xxs btn-danger"
                      >
                        x
                      </button>
                    </div>
                  </div>
                </div>
                <ChatBox
                  onSendMessage={this.handleOnSendMessage}
                  userId={this.state.user.pk}
                  messages={this.state.chat.messages}
                  width={"380px"}
                  height={"450px"}
                  showTypingIndicator={false}
                  activeAuthor={{
                    username: this.state.user.username,
                    id: this.state.user.pk,
                    avatarUrl: null,
                  }}
                />
              </div>
            }
            clickIcon={null}
            showChatbox={this.state.chat.showChatbox}
            showIcon={this.state.chat.showIcon}
            iconStyle={{ background: "red", fill: "white" }}
          ></ChatFrame>
          <ToastContainer
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            closeOnClick={false}
            closeButton={false}
            draggable={false}
            position="bottom-left"
            autoClose={Number(this.state.waitingTime)}
          />
        </LoadingOverlay>
        <Modal
          onClose={() => this.setOpenEmergency(false)}
          onOpen={() => this.setOpenEmergency(true)}
          open={this.state.openEmergency}
          trigger={this.props.trigger}
          dimmer="inverted"
        >
          <Formik
            initialValues={modelEmergency}
            onSubmit={this.onSubmitEmergency}
            validationSchema={PopupSchema}
          >
            {(props) => {
              const handleChange = (e, { name, value }) =>
                props.setFieldValue(name, value);
              const onTemplateChange = (e, { name, value }) => {
                props.setFieldValue("folder", "6");
                props.setFieldValue(name, value);
                this.loadTemplateEmergency(value);
              };
              return (
                <React.Fragment>
                  <Modal.Header>Acil Durum Mesajı</Modal.Header>
                  <Modal.Content>
                    <Grid padded>
                      <Grid.Row columns={2} divided="horizantally">
                        <Grid.Column textAlign="left" verticalAlign="top">
                          <FormikForm className="ui form">
                            <Form.Select
                              fluid
                              label="Şablon"
                              name="template"
                              onChange={onTemplateChange}
                              onBlur={props.handleBlur}
                              value={props.values.template}
                              options={this.state.templates.map((m) => {
                                return { key: m.id, value: m.id, text: m.name };
                              })}
                              placeholder="Şablon seçiniz"
                              className={
                                props.errors.template && props.touched.template
                                  ? "error"
                                  : ""
                              }
                            />
                            {/*
                                                        <Form.Select
                                                            fluid
                                                            label="Klasör"
                                                            name="folder"
                                                            onChange={handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.folder}
                                                            options={this.state.folders.map((m) => { console.log("KLASÖRLER : ", m); return { key: m.id, value: m.id, text: m.name } })}
                                                            placeholder='Klasör seçiniz'
                                                            className={props.errors.folder && props.touched.folder ? 'error' : ''}
                                                        />*/}

                            <Form.Input
                              label="Başlık"
                              name="title"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.title}
                              placeholder="Gönderinizin başlığını bu alana yazınız."
                              className={
                                props.errors.title && props.touched.title
                                  ? "error"
                                  : ""
                              }
                            />
                          </FormikForm>
                        </Grid.Column>

                        <Grid.Column textAlign="left" verticalAlign="top">
                          <div className="preview-canvas">
                            <div
                              ref={this.handleCanvasContainerEmergency}
                              id="preview-design-content"
                              style={{ display: "none" }}
                            ></div>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      type="button"
                      onClick={() => this.setOpenEmergency(false)}
                    >
                      İptal
                    </Button>
                    <Button
                      onClick={props.handleSubmit}
                      disabled={props.isSubmitting}
                      content="Başla"
                      className="modalStartButtonColor"
                    />
                  </Modal.Actions>
                </React.Fragment>
              );
            }}
          </Formik>
        </Modal>
      </div>
    );
  }
}

export default Master;
