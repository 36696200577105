import CustomStorage from "../models/CustomStorage";
import { publicApi } from "./axios";
import SettingsManager from '../tools/SettingsManager';

const SM = SettingsManager.create();

class AuthService {

    login(username, password, remember= false) {
        if(remember){
            // use localStorage to remember user
            this.storageMethod = 'ls';
            localStorage.setItem('sm', this.storageMethod);
        }else {
            // use sessionStorage to use things only this session
            this.storageMethod = 'ss';
            localStorage.setItem('sm', this.storageMethod);
        }

        const loginField = username.includes("@") ? "email" : "username";

        return publicApi
            .post("/pass/login/", {
                [loginField]: username,
                "password": password
            })
            .then(response => {
                if (response.data.access_token) {
                    new CustomStorage().setItem("user", JSON.stringify(response.data));
                    SM.LoadAll();
                }

                return response.data;
            });
    }

    reset(email){
        return publicApi
            .post("/pass/reset/", {
                email
            })
            .then(response => {
                return response.data;
            });
    }

    updatePassword = (uid, token, pwd1, pwd2) => {
        return publicApi
            .post(`/pass/reset_confirm/${uid}/${token}/`, {
                "new_password1": pwd1,
                "new_password2": pwd2,
                "uid": uid,
                "token": token
            })
            .then(response => {
                console.log('RESPONSE : ', response.data)
                return response.data;
            });
    }

    logout() {
        new CustomStorage().removeItem("user");
    }

    getCurrentUser(withVerify = false) {
        try {
            let user = JSON.parse(new CustomStorage().getItem('user'));

            if(!user){
                return false;
            }else{
                if(withVerify){
                    this.verifyToken(user.access_token).then(r => {
                        if(r){
                            return user;
                        }else{
                            return false;
                        }
                    });
                }else{
                    return user;
                }
            }
        }catch (e){
           return false;
        }
    }

    verifyToken(token){
        return publicApi
            .post("/pass/token/verify/", {
                "token": token
            })
            .then(response => {
                if (response.status === 200) {
                    return true
                }
            })
            .catch(function (error) {
                return false;
            });
    }
}

export default new AuthService();
