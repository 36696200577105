import React from 'react';
import settings from "../../../config/settings.json";
import Popup from "react-popup";
import {
    customMediaTableStyles,
    setPageSubTitle,
    setPageTitle, testGroupUserTableColumns,
    userTableColumns, profileTableColumns, calenderTableColumns
} from "../../../helpers/LayoutHelper";
import PubSub from "pubsub-js";
import ReportCard from "../../../components/reports/ReportCard";
import {Modal, ModalActions, ModalContent, ModalHeader, Form, Card, Image, Dropdown} from "semantic-ui-react";
import UserAvatar from "react-user-avatar";
import AvatarEditor from "react-avatar-editor";
import {
    createCalenderDates,
    createTestGroup, filterMessages,
    getBraces,
    getCalenderDates,
    getCompany,
    getProfiles,
    getTestGroup,
    getUsers,
    getWaitingTimes,
    updateWaitingTimes,
    getBirthdaySettings, getFolders, createBirthdaySettings, createWaitingTimes
} from "../../../services/api";
import {protectedApi} from "../../../services/axios";
import {Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import MediaRowDetail from "../../../components/lists/MediaRowDetail";
import LoadingView from "../../../components/common/LoadingView";
import EmptyView from "../../../components/common/EmptyView";
import DefaultImg from '../../../assets/images/whiteScreen.png'
import {DateRangePicker, START_DATE, END_DATE, DatePickerCalendar} from 'react-nice-dates'
import tr from "date-fns/locale/tr";
import Swal from "sweetalert2";
import moment from "moment";
import CustomStorage from "../../../models/CustomStorage";
import {isToday} from "date-fns";
import templateImg from "../../../assets/images/templateImg.jpg";
import TimePicker from "rc-time-picker";
import Select from "react-select";


class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            pageTitle: 'Ayarlar',
            pageSubTitle: "",
            company: null,
            image: undefined,
            braceImage: undefined,
            scale: 0.4,
            braceName: '',
            braces: [],

            elearningWaitingTime: null,
            emergencyWaitingTime: null,
            lcvWaitingTime: null,
            lockWaitingTime: null,
            newsletterWaitingTime: null,
            popupWaitingTime: null,
            quizWaitingTime: null,
            screensaverWaitingTime: null,
            socialWaitingTime: null,
            surveyWaitingTime: null,
            tickerWaitingTime: null,
            wallpaperWaitingTime: null,


            startDate: null,
            endDate: null,

            modifiers: {
                disabled: (date) => {
                    let newDate = moment(date).format()
                    const isDisabled = this.state.calenderDates.some((dateToDisable) => {
                            if (!isToday(date) && newDate === dateToDisable) {
                                return true
                            }
                        }
                    );
                    return isDisabled;
                },
                highlight: (date) => {
                    let newDate = moment(date).format('YYYY-MM-DD')
                    const highlighted = this.state.pendingMessageDates.some((highlight) => {
                        let newHighlight = moment(highlight).format('YYYY-MM-DD')
                        if(newDate === newHighlight){
                            return true
                        }
                    })
                    return highlighted
                },
                today: (date) => isToday(date),
            },
            modifiersClassNames: {
                highlight: '-highlight',
                disabled: '-disabled'
            },
            calenderDescription: "",
            date1: null,
            date2: null,

            testGroupUsers: [],
            profile: [],

            calenderDates: [],
            pickerDateArr: [],
            pickerDate: "",
            pendingMessageDates: [],
            braceFileSize:0,

            birthdayContentAdmin: null,
            birthdayFolder: null,
            createBirthday: false,

            createCompany: false,
            createWaitingTime: false,
        }

        this.headerStyle = {textAlign:"center",fontSize:"0.7vw"}
        this.cardWidth = {maxWidth:"99%"}
        this.contentHeight = {height:"100px"}
        this.buttonCss = {position:"absolute",bottom:"1px",right:"1px"}
        this.imageClick = {cursor:"pointer"}

        this.headerStyleBrace = {textAlign:"center",fontSize:"0.7vw"}
        this.cardWidthBrace = {maxWidth:"80%"}
        this.contentHeightBrace = {height:"80px"}
        this.buttonCssBrace = {position:"absolute",bottom:"1px",right:"1px",width:"16px",height:"24px"}
        this.fileSizeTextCssBrace = {position:"absolute",bottom:"1px",left:"1px"}
        this.imageClickBrace = {cursor:"pointer",width:"100px"}



        this.cs = new CustomStorage();
    }

    loadData = () => {
        getBirthdaySettings().then(response => {
            if(response === undefined) {
                this.setState({createBirthday: true})
                return
            }
            // console.log(response)
            this.setState({birthdayTemplateId:response.templateId,birthdaySendTime:response?.sendTime, birthdaySettings: response})
        }).catch(error => console.log('ERROR DİKKATTTT: ', error))
        getBraces()
            .then(data => {
                if (data !== undefined) {
                    this.setState({braces: data})
                }
            })

        getCalenderDates()
            .then(data => {
                if (data !== undefined) {
                    const tempArr = data.map((date) => {
                        return (
                            moment(date.date).format()
                        )
                    })
                    this.setState({calenderDates: tempArr, calenderDateRow: data})
                    PubSub.publish('load-calender', {})
                }
            })

        getFolders()
            .then(folders => {
                this.setState({folders: folders})
            })
            .catch(error => {
                console.log('ERROR : ', error)
            })

    }

    /* Standart methods for all pages */
    componentDidMount = async () => {
        this.loadPendingMessages()
        let user = this.cs.getItem('user');
        this.setState({user: JSON.parse(user).user})

        getCompany("1").then(data => {
            if (data !== undefined) {
                this.setState({company: {...data.user, logo: data.logo, name: data.name}});
            }else {
                this.setState({createCompany: true})
            }
        })
        PubSub.publish('MENU', 'settings');

        this.updatePageTitle();


        PubSub.subscribe('reload-calender', this.loadData)
        PubSub.subscribe('group-user-reload', this.getTestGroupUsers)

        this.loadData()
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount() {
        this.updatePage(this.props);
    }

    updatePage = async (props) => {
        this.updatePageTitle();
        this.getList();
        this.getTestGroupUsers()
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {

    };
    /* Standart methods for all pages */

    updateBirthdaySettings = () => {
        if(this.state.birthdayFolder == null ||this.state.birthdayContentAdmin === null || this.state.birthdayTemplateId === null || this.state.birthdaySendTime === null){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen bütün alanları doldurunuz!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }

        const payload={
            "id": 1,
            "templateId": this.state.birthdayTemplateId,
            "sendTime":this.state.birthdaySendTime,
            "folderId": this.state.birthdayFolder,
            "contentCreatorId": this.state.birthdayContentAdmin
        }
        if(this.state.createBirthday){
            createBirthdaySettings(payload)
                .then(response =>{
                    if(response)
                        Swal.fire({
                            icon: 'success',
                            text: 'Başarıyla Güncellendi.',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,
                        })
                    this.loadData()
                    return

                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Doğum günü bilgileri güncellenemedi.',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    })
                    return
                })
            return;
        }

        protectedApi.put('/announcement/birthdaysetting/1', payload)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    text: 'Başarıyla Güncellendi.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,
                })
                this.loadData()
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    text: 'Güncelleme başarısız.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,
                })
            });
    }

    getBirthdayTemplates = () =>{

        protectedApi.get(`/announcement/templates?type=POPUP&ordering=name&isBirthDayTemplate=true`)
            .then((response) => {
                //this.setState({templates: response.data.results})

                this.setState({birthdayTemplates:response.data.results})
            })
            .catch((error) => {
                console.log(error);
            });


    }

    loadPendingMessages = () => {
        filterMessages("PENDING").then(list => {
            const tempArr = list?.map((data) => {
                return (
                    moment(data.startDate).format()
                )
            })
            this.setState({pendingMessages: list, isLoaded: true, pendingMessageDates: tempArr})
        })
    }


    getList = async () => {
        const testGroupUsers = await getTestGroup();
        const allUsers = await getUsers();

        let testGroupUsernames = testGroupUsers.map(item => item.user.username)


        let adminUsers = allUsers?.filter((item) => {
            return item.profile.isContentAdmin
        }).map(item => {
            return {
                ...item,
                full_name: item.first_name + ' ' + item.last_name,
                groups: item.groups.map(g => <span
                    className="badge badge-sm light badge-secondary mr-1">{g.name}</span>)
            }
        });
        let normalUsers = allUsers?.map(item => {
            return {
                ...item,
                full_name: item.first_name + ' ' + item.last_name,
                groups: item.groups.map(g => <span
                    className="badge badge-sm light badge-secondary mr-1">{g.name}</span>)
            }
        });

        let filteredNormalUsers = normalUsers.filter(( data ) => !testGroupUsernames.includes(data.username));





        const profileList = await getProfiles();
        this.setState({
            loaded: true,
            adminUsers: adminUsers,
            userList: filteredNormalUsers,
            profile: profileList
        });
    }

    getTestGroupUsers = async () => {
        const testGroupUsers = await getTestGroup();

        this.setState({testGroupUsers: testGroupUsers})
    }



    userSettingsModalToggle = () => {
        this.setState({userSettingsModal: !this.state.userSettingsModal})
    }

    testGroupModalToggle = () => {
        this.setState({testGroupModal: !this.state.testGroupModal})
    }

    uploadModalToggle = () => {
        this.setState({uploadModal: !this.state.uploadModal})
    }

    uploadBraceModalToggle = () => {
        this.setState({uploadBraceModal: !this.state.uploadBraceModal,braceImage:undefined})
    }
    uploadCompanyLogoToggle = () => {
        this.setState({uploadCompanyLogoModal: !this.state.uploadCompanyLogoModal})
    }

    calenderModalToggle = (status) => {
        this.setState({calenderModal: status})
    }

    selectImage = () => {
        this.upload.click();
    }


    handleNewCompanyLogo = (e) => {
        if (e.target.files[0].size > 1097152) // 2 MiB for bytes.
        {
            Swal.fire({
                icon: 'warning',
                text: 'Dosya boyutu en fazla 1MB olabilir',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return;
        }else{
            this.setState({companyLogo :e.target.files[0]});
        }
    }

    handleCompanyLogoUpload = () => {
        let file = this.state.companyLogo

        let data = new FormData();
        let config = {header: {'Content-Type': 'multipart/form-data'}}
        let company_id = "1";

        data.append("name", "Devtagon")
        data.append('logo', file, file.name);

        if (this.state.createCompany){
            protectedApi.post('/announcement/companies', data, config)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: 'Firma logosu kaydedilmiştir.',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    })
                    return
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Firma logosu kaydedilemedi!',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    })
                })
            return
        }
        // Post çıkarken id oluşturmasın diye post sonrası eğer put çıkarsa id verisini burada ekledik
        data.append('id', company_id);
        protectedApi.put('/announcement/company/' + company_id, data, config).then(response => {
            Swal.fire({
                icon: 'success',
                text: 'Firma logosu güncellenmiştir.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            let logo_path = response.data.logo;

            this.setState({company: {...this.state.company, logo: logo_path}});

        });
        this.uploadCompanyLogoToggle()
    }

    // handleFileUpload = () => {
    //     const img = this.editor.getImage().toDataURL();
    //     let file = this.dataURLtoFile(img, "CompanyLogo.png")
    //     let data = new FormData();
    //     let config = {header: {'Content-Type': 'multipart/form-data'}}
    //     let company_id = "1";
    //
    //     data.append("name", "Devtagon")
    //     data.append('logo', file, file.name);
    //     // data.append("createdby", company_id);
    //     // data.append("updatedby", company_id);
    //
    //     if (this.state.createCompany){
    //         protectedApi.post('/announcement/companies', data, config)
    //             .then(response => {
    //                 Swal.fire({
    //                     icon: 'success',
    //                     text: 'Firma logosu kaydedilmiştir.',
    //                     showCancelButton: true,
    //                     showConfirmButton: false,
    //                     cancelButtonColor: '#3B4182',
    //                     cancelButtonText: "Tamam",
    //                     focusCancel: false,
    //                 })
    //                 return
    //             })
    //             .catch(error => {
    //                 Swal.fire({
    //                     icon: 'warning',
    //                     text: 'Firma logosu kaydedilemedi!',
    //                     showCancelButton: true,
    //                     showConfirmButton: false,
    //                     cancelButtonColor: '#3B4182',
    //                     cancelButtonText: "Tamam",
    //                     focusCancel: false,
    //                 })
    //             })
    //         return
    //     }
    //     // Post çıkarken id oluşturmasın diye post sonrası eğer put çıkarsa id verisini burada ekledik
    //     data.append('id', company_id);
    //     protectedApi.put('/announcement/company/' + company_id, data, config).then(response => {
    //         Swal.fire({
    //             icon: 'success',
    //             text: 'Firma logosu güncellenmiştir.',
    //             showCancelButton: true,
    //             showConfirmButton: false,
    //             cancelButtonColor: '#3B4182',
    //             cancelButtonText: "Tamam",
    //             focusCancel: false,
    //         })
    //         let logo_path = response.data.logo;
    //
    //         this.setState({company: {...this.state.company, logo: logo_path}});
    //
    //     });
    //     this.uploadModalToggle()
    // }


    setEditorRef = (editor) => {
        if (editor) this.editor = editor;
    };

    handleNewImage = (e) => {
        this.setState({image: e.target.files[0]});
    };

    handleSave = (data) => {
        // const img = this.editor.getImageScaledToCanvas().toDataURL();
        const img = this.editor.getImage().toDataURL();

        this.setState({
            preview: {
                img
            }
        });
    };

    handleScale = (e) => {
        const scale = parseFloat(e.target.value);
        this.setState({scale});
    };

    dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    handleDateChange = (field, date) => {
        const tempDate = moment(date).format('DD.MM.YYYY')
        this.setState({[field]: date})
        if (field = 'endDate') {

        }
    }


    selectBraceImage = () => {
        this.braceUpload.click();
    }

    selectCompanyLogo = () => {
        this.companyLogoUpload.click();
    }


    handleNewBraceImage = (e) => {
        if (e.target.files[0].size > 2097152) // 2 MiB for bytes.
        {
            Swal.fire({
                icon: 'warning',
                text: 'Dosya boyutu en fazla 2MB olabilir',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return;
        }else{
            this.setState({braceImage: e.target.files[0],braceFileSize:e.target.files[0].size});
        }


    };

    handleBraceFileUpload = () => {
        const img = this.editor.getImage().toDataURL();
        let file = this.dataURLtoFile(img, "brace.png")
        if (this.state.braceName.trim()===""){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen Ayraç adı giriniz',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }
        let data = new FormData();
        let config = {header: {'Content-Type': 'multipart/form-data'}}

        data.append("name", this.state.braceName)
        data.append("fileSize", (this.state.braceFileSize/1024/1024).toFixed(3))
        data.append('logo', file, file.name);
        data.append("createdby", "6");
        data.append("updatedby", "6")

        protectedApi.post('/announcement/braces', data, config)
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    text: 'Ayraç başarıyla eklendi',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,
                })
                this.loadData()
                this.uploadBraceModalToggle()
                let logo_path = response.data.logo;
                this.setState({braceImage: logo_path})
            }).catch(err => {
            Swal.fire({
                icon: 'error',
                text: 'Ayraç yüklenemedi',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
        })

    }
    handleSaveCalender = () => {
        this.state.calenderDates.forEach((date) => {
            const payload = {
                "type": "DATE",
                "date": moment(date).format('YYYY-MM-DD'),
                "description": this.state.calenderDescription,
                "createdby": this.state.user.pk,
                "updatedby": this.state.user.pk
            }
            createCalenderDates(payload)
                .then(res => {
                    Swal.fire({
                        icon: 'success',
                        text: 'Takvim Güncellendi.',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    })
                    this.calenderModalToggle(false)
                    this.loadData()
                })
                .catch(err => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Takvim Güncellenemedi.',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    })
                    console.log('TAKVİM GÜNCELLEME HATA : ', err)
                })
        })

    }

    handleSaveTestGroupUser = () => {
        const payload = {
            user: this.state.selectedUser
        }
        createTestGroup(payload)
            .then(res => {
                Swal.fire({
                    icon: 'success',
                    text: 'Kullanıcı test grubuna eklendi.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                this.testGroupModalToggle()
                this.getTestGroupUsers()

            })
            .catch(err => console.log('HATA :', err))
    }


    showSettings = () => {
        Popup.alert(settings.underconstructor_message)
    }

    updateWaitingTimes = () => {
        if (this.state.popupWaitingTime>=0 && this.state.popupWaitingTime<61 && this.state.tickerWaitingTime>=0 && this.state.tickerWaitingTime<61 && this.state.quizWaitingTime>=0 && this.state.quizWaitingTime<61 && this.state.surveyWaitingTime>=0 && this.state.surveyWaitingTime<61 && this.state.elearningWaitingTime>=0 && this.state.elearningWaitingTime<61 && this.state.lcvWaitingTime>=0 && this.state.lcvWaitingTime<61 && this.state.lockWaitingTime>=0 && this.state.lockWaitingTime<61 && this.state.wallpaperWaitingTime>=0 && this.state.wallpaperWaitingTime<61 && this.state.socialWaitingTime>=0 && this.state.socialWaitingTime<61 && this.state.screensaverWaitingTime>=0 && this.state.screensaverWaitingTime<61 && this.state.newsletterWaitingTime>=0 && this.state.newsletterWaitingTime<61 && this.state.emergencyWaitingTime>=0 && this.state.emergencyWaitingTime<61){
            const payload = {
                elearningWaitingTime: this.state.elearningWaitingTime,
                emergencyWaitingTime: this.state.emergencyWaitingTime,
                lcvWaitingTime: this.state.lcvWaitingTime,
                lockWaitingTime: this.state.lockWaitingTime,
                newsletterWaitingTime: this.state.newsletterWaitingTime,
                popupWaitingTime: this.state.popupWaitingTime,
                quizWaitingTime: this.state.quizWaitingTime,
                screensaverWaitingTime: this.state.screensaverWaitingTime,
                socialWaitingTime: this.state.socialWaitingTime,
                surveyWaitingTime: this.state.surveyWaitingTime,
                tickerWaitingTime: this.state.tickerWaitingTime,
                wallpaperWaitingTime: this.state.wallpaperWaitingTime,
            }
            if(this.state.createWaitingTime){
                createWaitingTimes(payload)
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            text: 'Ayarlar kaydedildi.',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,
                        })
                        return
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            text: 'Ayarlar kaydedilemedi.',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,
                        })
                        return
                    })
            }
            updateWaitingTimes(payload).then(result =>{
                Swal.fire({
                    icon: 'success',
                    text: 'Ayarlar kaydedildi.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
            }).catch(err =>{
                Swal.fire({
                    icon: 'error',
                    text: 'Ayarlar kaydedilemedi.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
            })
        }else {
            Swal.fire({
                icon: 'error',
                text: 'Lütfen paylaşım bekletme sürelerinizi    0 - 60 aralığında giriniz.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
        }
    }

    updatePageSubTitle = async () => {
        if (this.state.pageSubTitle === "CompanySettings") {
            setPageSubTitle("Firma Ayarları")
        }

        if (this.state.pageSubTitle === "CompanySettings/companyLogo") {
            setPageSubTitle("Firma Logo Ayarları")
        }

        if (this.state.pageSubTitle === "MediaSettings/subtitleBracket") {
            setPageSubTitle("Altyazı Ayracı Ayarları")
        }

        if (this.state.pageSubTitle === "CompanySettings/testGroupUser") {
            setPageSubTitle("Test Grup Ayarları")
        }
        if (this.state.pageSubTitle === "CompanySettings/socialMedia") {
            setPageSubTitle("Sosyal Medya Ayarları")
        }

        if (this.state.pageSubTitle === "userSettings") {
            setPageSubTitle("Kullanıcı Ayarları")
        }

        if (this.state.pageSubTitle === "CalendarSettings") {
            setPageSubTitle("Takvim Ayarları")
        }

        if (this.state.pageSubTitle === "MediaSettings") {
            setPageSubTitle("Mecra Ayarları")
        }

        if (this.state.pageSubTitle === "MediaSettings/snoozeSettings") {
            getWaitingTimes("1").then(result => {
                if (result === undefined){
                    this.setState({createWaitingTime: true})
                }
                this.setState({
                    elearningWaitingTime: result.elearningWaitingTime,
                    emergencyWaitingTime: result.emergencyWaitingTime,
                    lcvWaitingTime: result.lcvWaitingTime,
                    lockWaitingTime: result.lockWaitingTime,
                    newsletterWaitingTime: result.newsletterWaitingTime,
                    popupWaitingTime: result.popupWaitingTime,
                    quizWaitingTime: result.quizWaitingTime,
                    screensaverWaitingTime: result.screensaverWaitingTime,
                    socialWaitingTime: result.socialWaitingTime,
                    surveyWaitingTime: result.surveyWaitingTime,
                    tickerWaitingTime: result.tickerWaitingTime,
                    wallpaperWaitingTime: result.wallpaperWaitingTime,
                })
            })
            setPageSubTitle("Paylaşım Bekletme Süreleri")
        }

    };


    selectSettings = (data) => {
        if (data !== "CompanySettings" && data !== this.state.user.profile.isContentAdmin && !this.state.user.profile.isSuperAdmin && !this.state.user.profile.isSystemAdmin) {
            Swal.fire({
                icon: 'warning',
                text: 'Bu alana giriş yetkiniz bulunmamaktadır.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
        } else {
            this.setState({pageSubTitle: data}, () => this.updatePageSubTitle())
        }

    }

    deleteBrace = (id) => {

        Swal.fire({
            icon: 'warning',
            text: "Seçtiğiniz ayracı silmek istiyor musunuz?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#3B4182',
            confirmButtonText: 'Evet',
            cancelButtonColor: '#E40426',
            cancelButtonText: 'Hayır',
            focusCancel: false,
            reverseButtons: true,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                //const cancellationDescription = window.prompt("İptal nedinini yazınız");
                protectedApi.delete(`/announcement/brace/${id}`)
                    .then((response) => {
                        Swal.fire({
                            icon: 'success',
                            text: 'Ayraç Silindi!',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,

                        })
                        this.loadData();
                    }).catch(error => Swal.fire({
                    icon: 'error',
                    text: 'Ayraç Silinemedi!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                }))
            }
        })

    }

    disabledHours = () => {
        return [1, 2, 3, 4, 5, 6];
    }


    render() {
        let templateBIRTHDAY = this.state.birthdayTemplates?.map((data,index)=>{
            return (
                <>
                    <div className="col-sm-2 w-25">
                        <Card style={this.cardWidth} >
                            <Image style={this.imageClick}  src={templateImg} wrapped ui={false} />
                            <button className="btn btn-xs" disabled={data.id===this.state.birthdayTemplateId} onClick={()=>{
                                if (data.id===this.state.birthdayTemplateId){
                                    return
                                }else{
                                    this.setState({birthdayTemplateId:data.id})
                                }
                            }}
                                    style={this.buttonCss}>
                                {data.id===this.state.birthdayTemplateId?<h4>Seçili</h4>:"Seç"}</button>
                            <Card.Content style={this.contentHeight}>
                                <Card.Header style={this.headerStyle}>{data.name}<br/></Card.Header>

                            </Card.Content>
                        </Card>
                    </div>
                </>
            )
        })
        let braces = this.state.braces?.map((data,index)=>{
            // console.log('DATAA : ', data)
            return (
                <>
                    <div className="col-sm-2 w-25">
                        <Card style={this.cardWidthBrace} >
                            <Image style={this.imageClickBrace} onClick={()=>{}} src={data.logo} size={"small"} centered fluid={false} />
                            <h6 style={this.fileSizeTextCssBrace}>{data.fileSize ? data.fileSize + " MB" : "0 MB"}</h6>
                            <button className="btn btn-xs" onClick={()=>this.deleteBrace(data.id)} style={this.buttonCssBrace}>
                                <i className="fa fa-trash-o" style={{fontSize:"16px"}} aria-hidden="true"/>
                            </button>
                            <Card.Content style={this.contentHeightBrace}>
                                <Card.Header style={this.headerStyleBrace}>{data.name}<br/></Card.Header>

                            </Card.Content>
                        </Card>
                    </div>
                </>
            )
        })
        return (
            <div className="container-fluid">
                {this.state.pageSubTitle === "" &&
                <>
                    <div className="row">
                        <div className="col-3">
                            <ReportCard
                                id="general"
                                icon="la la-tools la-5x"
                                title="Genel Ayarlar"
                                description="Bu bölümde genel ayarları düzenleyebilirsiniz."
                                onSelect={this.showSettings}
                            />
                        </div>
                        <div className="col-3">
                            <ReportCard
                                id="smtp"
                                icon="la la-envelope la-5x"
                                title="Smtp Ayarları"
                                description="Bu bölümde smtp ayarlarını düzenleyebilirsiniz."
                                onSelect={this.showSettings}
                            />
                        </div>
                        <div className="col-3">
                            <ReportCard
                                id="smtp"
                                icon="la la-sitemap la-5x"
                                title="Firma Ayarları"
                                description="Bu bölümde firma ayarlarını düzenleyebilirsiniz."
                                onSelect={() => this.selectSettings("CompanySettings")}
                            />
                        </div>
                        <div className="col-3">
                            <ReportCard
                                id="smtp"
                                icon="la la-user la-5x"
                                title="Kullanıcı Ayarları"
                                description="Bu bölümde kullanıcıların yetkinliklerini düzenleyebilirsiniz."
                                onSelect={() => {
                                    this.showSettings()
                                    // Sayfa Güncelleneceği için şimdilik kapatılmıştır.
                                    //this.selectSettings("userSettings")
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <ReportCard
                                id="smtp"
                                icon="la la-calendar la-5x"
                                title="Takvim Ayarları"
                                description="Bu bölümde takvim ayarlarını düzenleyebilirsiniz."
                                onSelect={() => this.selectSettings("CalendarSettings")}
                            />
                        </div>
                        <div className="col-3">
                            <ReportCard
                                id="media"
                                icon="la la-desktop la-5x"
                                title="Mecra Ayarları"
                                description="Bu bölümde mecra ayarlarını düzenleyebilirsiniz."
                                onSelect={() => this.selectSettings("MediaSettings")}
                            />
                        </div>

                        {/*<div className="col-3">*/}
                        {/*    <ReportCard*/}
                        {/*        id="defaults"*/}
                        {/*        icon="la la-user-cog la-3x"*/}
                        {/*        title="Varsayılan Ayarlar"*/}
                        {/*        description="Bu bölümde varsayılan ayarları düzenleyebilirsiniz."*/}
                        {/*        onSelect={this.showSettings}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="col-3">*/}
                        {/*    <ReportCard*/}
                        {/*        id="integrations"*/}
                        {/*        icon="la la-exchange la-3x"*/}
                        {/*        title="Entegrasyonlar"*/}
                        {/*        description="Bu bölümde entegrasyon ayarlarını düzenleyebilirsiniz."*/}
                        {/*        onSelect={this.showSettings}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="col-3">*/}
                        {/*    <ReportCard*/}
                        {/*        id="security"*/}
                        {/*        icon="la la-shield la-3x"*/}
                        {/*        title="Güvenlik Ayarları"*/}
                        {/*        description="Bu bölümde güvenlik ayarlarını düzenleyebilirsiniz."*/}
                        {/*        onSelect={this.showSettings}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                </>
                }
                {this.state.pageSubTitle === "MediaSettings" &&
                <>
                    <div className="row">
                        <div className="col-2 mb-2">
                            <button className="btn btn-primary btn-xs" onClick={() => {
                                this.setState({pageSubTitle: ''});
                                setPageSubTitle('')
                            }}><i className="la la-arrow-left"/></button>
                            <span className="ml-1">Geri</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <ReportCard
                                id="snooze"
                                icon="la la-hourglass-half la-3x"
                                title="Paylaşım Bekletme Süreleri"
                                description="Bu bölümde paylaşım bekletme sürelerini düzenleyebilirsiniz."
                                onSelect={() => this.selectSettings("MediaSettings/snoozeSettings")}
                            />
                        </div>
                        <div className="col-3">
                            <ReportCard
                                id="snooze"
                                icon="la la-calendar la-3x"
                                title="Doğum Günü Ayarları"
                                description="Bu bölümde paylaşım bekletme sürelerini düzenleyebilirsiniz."
                                onSelect={() => {
                                    this.getBirthdayTemplates()
                                    this.selectSettings("MediaSettings/birthdaySettings")
                                }}
                            />
                        </div>
                        <div className="col-3">
                            <ReportCard
                                id="smtp"
                                icon="la la-pause la-5x"
                                title="Altyazı Ayracı"
                                description="Bu bölümde Altyazı için ayraç ekleyebilirsiniz."
                                onSelect={() => this.selectSettings("MediaSettings/subtitleBracket")}
                            />
                        </div>
                    </div>
                </>
                }
                {this.state.pageSubTitle === "MediaSettings/birthdaySettings" &&
                <div>
                    <div className="row">
                        <div className="col-2 mb-2">
                            <button className="btn btn-primary btn-xs" onClick={() => {
                                this.setState({pageSubTitle: ''});
                                setPageSubTitle('')
                            }}><i className="la la-arrow-left"/></button>
                            <span className="ml-1">Geri</span>
                        </div>
                        <div className="col-10 mb-2">
                            <button className="btn btn-primary btn-xl float-right" onClick={() => {this.updateBirthdaySettings()}}>Kaydet</button>
                        </div>
                    </div>
                    <div className="row">
                        <h2>Doğum Günü Şablonları</h2>
                    </div>
                    <div className="row mt-3">
                        {templateBIRTHDAY}
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="row">
                                <h2>Gönderim Saati</h2>
                            </div>
                            <div className="row mt-3">
                                <div className='field'>
                                    <label>Mevcut gönderim saati : {this.state.birthdaySettings?.sendTime}</label><br/>
                                    <TimePicker  placeholder='Saat seçiniz.'
                                                 showSecond={false}
                                                 minuteStep={15}
                                                 disabledHours={this.disabledHours}
                                                 clearIcon={<button type='button'
                                                                    className='react-datepicker__close-icon'
                                                                    tabIndex='-1'/>}
                                                 onChange={(e)=>{
                                                     this.setState({birthdaySendTime:moment(e).format("HH:mm:00")})

                                                 }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="row">
                                <h2>Gönderen Yönetici</h2>
                            </div>
                            <div className="row mt-3">
                                <div className='field'style={{width:'185px'}}>
                                    <label style={{whiteSpace:'nowrap'}}>
                                        Mevcut yönetici : {this.state.adminUsers?.filter(user => user.id === this.state.birthdaySettings?.contentCreatorId)[0].first_name}
                                        {this.state.adminUsers?.filter(user => user.id === this.state.birthdaySettings?.contentCreatorId)[0].last_name}
                                    </label><br/>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        options={this.state.adminUsers.map(u => {
                                            return {value: u.id, label: u.full_name}
                                        })}
                                        placeholder="İçerik Yön&hellip;"
                                        loadingMessage={() => <span>Yükleniyor</span>}
                                        onChange={e => {
                                            this.setState({birthdayContentAdmin: e.value})
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="row">
                                <h2>Klasör</h2>
                            </div>
                            <div className="row mt-3">
                                <div className='field'style={{width:'185px'}}>
                                    <label style={{whiteSpace:'nowrap'}}>Mevcut klasör : {this.state.folders?.filter(folder => folder.id === this.state.birthdaySettings?.folderId)[0].name}</label><br/>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        options={this.state.folders.map(u => {
                                            return {value: u.id, label: u.name}
                                        })}
                                        placeholder="Klasör;"
                                        loadingMessage={() => <span>Yükleniyor</span>}
                                        onChange={e => {
                                            this.setState({birthdayFolder: e.value})
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.pageSubTitle === "MediaSettings/snoozeSettings" &&
                <>
                    <div className="row">
                        <div className="col-2 mb-2">
                            <button className="btn btn-primary btn-xs" onClick={() => {
                                this.setState({pageSubTitle: ''});
                                setPageSubTitle('')
                            }}><i className="la la-arrow-left"/></button>
                            <span className="ml-1">Geri</span>
                        </div>
                        <div className="col-10 mb-2">
                            <button className="btn btn-primary btn-xl float-right" onClick={() => {this.updateWaitingTimes()}}>Kaydet</button>
                        </div>
                    </div>
                    <div className="card field">
                        <table className="table table-striped">
                            <thead>
                            <tr >
                                <th >Mecra Adı</th>
                                <th >Bekleme Süresi(saniye)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr >
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/POPUP.png`} width={30}
                                         height={30}/>
                                    <span>POPUP</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="popupWaitingTime" value={this.state.popupWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/TICKER.png`} width={30}
                                         height={30}/>
                                    <span>TICKER</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="tickerWaitingTime" value={this.state.tickerWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/QUIZ.png`} width={30}
                                         height={30}/>
                                    <span>QUIZ</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="quizWaitingTime" value={this.state.quizWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/SURVEY.png`} width={30}
                                         height={30}/>
                                    <span>SURVEY</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="surveyWaitingTime" value={this.state.surveyWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/LCV.png`} width={30}
                                         height={30}/>
                                    <span>LCV</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="lcvWaitingTime" value={this.state.lcvWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/ELEARNING.png`} width={30}
                                         height={30}/>
                                    <span>ELEARNING</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="elearningWaitingTime" value={this.state.elearningWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/EMERGENCY.png`} width={30}
                                         height={30}/>
                                    <span>EMERGENCY</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="emergencyWaitingTime" value={this.state.emergencyWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/LOCK.png`} width={30}
                                         height={30}/>
                                    <span>LOCK</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="lockWaitingTime" value={this.state.lockWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/WALLPAPER.png`} width={30}
                                         height={30}/>
                                    <span>WALLPAPER</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="wallpaperWaitingTime" value={this.state.wallpaperWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/SCREENSAVER.png`} width={30}
                                         height={30}/>
                                    <span>SCREENSAVER</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="screensaverWaitingTime" value={this.state.screensaverWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <img className="mr-2 mt-1"
                                         src={`/images/icons/NEWSLETTER.png`} width={30}
                                         height={30}/>
                                    <span>NEWSLETTER</span>
                                </td>
                                <td>
                                    <div className="ui form field">
                                        <input style={{width:"40%"}} className="ui input" type="text" name="newsletterWaitingTime" value={this.state.newsletterWaitingTime} onChange={(event)=> {this.setState({[event.target.name]:event.target.value.replace(/[^0-9.-]+/g,'').replace('-','')})}}/>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </>
                }
                {this.state.pageSubTitle === "CompanySettings" &&
                <>
                    <div className="row">
                        <div className="col-2 mb-2">
                            <button className="btn btn-primary btn-xs" onClick={() => {
                                this.setState({pageSubTitle: ''});
                                setPageSubTitle('')
                            }}><i className="la la-arrow-left"/></button>
                            <span className="ml-1">Geri</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <ReportCard
                                id="smtp"
                                icon="la la-image la-5x"
                                title="Şirket Logosu"
                                description="Bu bölümde şirket logosunu düzenleyebilirsiniz."
                                onSelect={() => this.selectSettings("CompanySettings/companyLogo")}
                            />
                        </div>
                        <div className="col-3">
                            <ReportCard
                                id="smtp"
                                icon="la la-group la-5x"
                                title="Test Grubu"
                                description="Bu bölümde Test grubu oluşturabilir ve kullanıcılarını yönetebilirsiniz."
                                onSelect={() => this.selectSettings("CompanySettings/testGroupUser")}
                            />
                        </div>
                        <div className="col-3">
                            <ReportCard
                                id="smtp"
                                icon="la la-facebook-square la-5x"
                                title="Sosyal Medya"
                                description="Bu bölümde sosyal medya ayarlarınızı yönetebilirsiniz."
                                onSelect={() => Popup.alert(settings.underconstructor_message)}
                            />
                        </div>
                    </div>
                </>
                }
                {this.state.pageSubTitle === "CompanySettings/companyLogo" &&
                <>
                    <div className="row">
                        <div className="col-2 mb-2">
                            <button className="btn btn-primary btn-xs" onClick={() => {
                                this.setState({pageSubTitle: "CompanySettings"});
                                setPageSubTitle('Firma Ayarları')
                            }}><i className="la la-arrow-left"/></button>
                            <span className="ml-1">Geri</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body px-4 py-3 py-md-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-12 col-md-12 text-md-right mt-md-0 mt-4">
                                            <p onClick={() => this.uploadCompanyLogoToggle()}
                                                  className="btn btn-primary rounded btn-sm px-4">Şirket Logosu Ekle</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-2 w-25">
                            <Image src={this.state.company?.logo} size={"medium"} centered fluid={false} />

                            {/*<Card style={{maxWidth:'100%'}} >*/}
                            {/*    /!*<Card.Content style={this.contentHeightBrace}>*!/*/}
                            {/*    /!*    <Card.Header style={this.headerStyleBrace}>{data.name}<br/></Card.Header>*!/*/}
                            {/*    /!*</Card.Content>*!/*/}
                            {/*</Card>*/}
                        </div>
                    </div>


                    <Modal onClose={() => this.uploadCompanyLogoToggle()}
                           onOpen={() => this.uploadCompanyLogoToggle()}
                           closeIcon
                           style={{position: "relative", height: "475px", width: "350px"}}
                           size="small"
                           open={this.state.uploadCompanyLogoModal}>
                        <ModalHeader>Şirket Logosu Ekleme/Güncelleme</ModalHeader>
                        <ModalContent style={{marginTop: "4px"}}>
                            <div className="row">
                                <div className="col-md-12 mt-5">
                                    <div className="file-input-div" onClick={this.selectCompanyLogo} style={{cursor:"pointer",height:"200px"}}>
                                        {this.state.companyLogo ?
                                            <p className="previewTag" style={{color:'#000000'}}>{this.state.companyLogo.name}</p>
                                            :
                                            <p className="previewTag" style={{color:'#000000'}}>Logo yüklemek için tıklayın</p>
                                        }
                                    </div>
                                </div>
                                {/*{this.state.company.logo===undefined?*/}
                                {/*    <div className="col-md-12 mt-5">*/}
                                {/*        <div className="file-input-div" onClick={this.selectCompanyLogo} style={{cursor:"pointer",height:"200px"}}>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    <div className="col-md-12 d-flex justify-content-center file-input-div" onClick={this.selectCompanyLogo} style={{cursor:"pointer",height:"200px"}}>*/}
                                {/*        <AvatarEditor*/}
                                {/*            ref={this.setEditorRef}*/}
                                {/*            className="editor-canvas"*/}
                                {/*            image={this.state.company.logo ? this.state.company.logo : DefaultImg}*/}
                                {/*            // width={250}*/}
                                {/*            // height={250}*/}
                                {/*            scale={2}*/}
                                {/*            style={{cursor:"pointer", marginTop:'-25px'}}*/}
                                {/*            color={[200, 255, 255, 0]} // RGBA*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mt-1">
                                    <p style={{color:'red', fontWeight:'100'}}>
                                        Logo dosyası 1 MB'tan küçük olmamak üzere SVG, PNG veya GIF biçiminde olmalıdır. GIF dosyaları animasyonlu olamaz. Görsel genişliği veya uzunluğu 100 pixel’den küçük olmaması önerilir.
                                    </p>
                                </div>
                            </div>
                        </ModalContent>
                        <ModalActions>
                            <input name="newImage" accept=".jpg, .png, .jpeg" ref={(ref) => this.companyLogoUpload = ref}
                                   style={{display: 'none'}} type="file" onChange={this.handleNewCompanyLogo}/>
                            <button className="btn btn-primary rounded btn-sm px-4 "
                                    onClick={() => this.uploadCompanyLogoToggle()}>Vazgeç
                            </button>
                            <input type="button" disabled={this.state.companyLogo === undefined}
                                   className="btn btn-success rounded btn-sm px-4 ml-1 float-right "
                                   onClick={this.handleCompanyLogoUpload} value="Kaydet"/>
                        </ModalActions>
                    </Modal>

                    {/*<div className="row mt-3">*/}
                    {/*    <div className="col-4 profile-img pl-5 pt-2 ml-3">*/}
                    {/*        <button type="button"*/}
                    {/*                onClick={() => this.uploadModalToggle()}*/}
                    {/*                style={{*/}
                    {/*                    width: 30,*/}
                    {/*                    height: 30,*/}
                    {/*                    backgroundColor: '#F4C752',*/}
                    {/*                    borderWidth: 0,*/}
                    {/*                    borderRadius: 15,*/}
                    {/*                    position: 'absolute',*/}
                    {/*                    bottom: -5,*/}
                    {/*                    marginLeft: -30,*/}
                    {/*                    cursor: 'pointer',*/}
                    {/*                    zIndex: 1*/}
                    {/*                }}>📷*/}
                    {/*        </button>*/}
                    {/*        <UserAvatar*/}
                    {/*            size="180"*/}
                    {/*            name={this.state.company ? this.state.company.name : "Logo"}*/}
                    {/*            colors={['#fc5c65', '#fd9644', '#fed330', '#26de81', '#2bcbba', '#eb3b5a', '#fa8231', '#f7b731', '#20bf6b', '#0fb9b1', '#45aaf2', '#4b7bec', '#a55eea', '#d1d8e0', '#778ca3', '#2d98da', '#3867d6', '#8854d0', '#a5b1c2', '#4b6584']}*/}
                    {/*            src={this.state.company ? this.state.company.logo : ''}*/}
                    {/*        />*/}
                    {/*        <Modal onClose={() => this.uploadModalToggle()}*/}
                    {/*               onOpen={() => this.uploadModalToggle()}*/}
                    {/*               closeIcon*/}
                    {/*               style={{position: "relative", height: "525px", width: "350px"}}*/}
                    {/*               size="small"*/}
                    {/*               open={this.state.uploadModal}>*/}
                    {/*            <ModalHeader style={{height: "50px", width: "350px"}}>Profil Resmi*/}
                    {/*                Güncelleme</ModalHeader>*/}
                    {/*            <ModalContent style={{marginTop: "50px", height: "300px"}}>*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-md-12">*/}
                    {/*                        <AvatarEditor*/}
                    {/*                            ref={this.setEditorRef}*/}
                    {/*                            className="editor-canvas"*/}
                    {/*                            image={this.state.image ? this.state.image : this.state.company?.logo}*/}
                    {/*                            borderRadius={125}*/}
                    {/*                            width={250}*/}
                    {/*                            height={250}*/}
                    {/*                            border={25}*/}
                    {/*                            color={[200, 255, 255, 0.6]} // RGBA*/}
                    {/*                            scale={parseFloat(this.state.scale)}*/}
                    {/*                            rotate={0}*/}
                    {/*                        />*/}
                    {/*                        <br/>*/}
                    {/*                        <input*/}
                    {/*                            style={{marginLeft: "95px"}}*/}
                    {/*                            name="scale"*/}
                    {/*                            type="range"*/}
                    {/*                            onChange={this.handleScale}*/}
                    {/*                            min="0.01"*/}
                    {/*                            max="3"*/}
                    {/*                            step="0.01"*/}
                    {/*                            defaultValue="0.4"*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-6">*/}

                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </ModalContent>*/}
                    {/*            <ModalActions style={{marginTop: "60px", height: "50px"}}>*/}
                    {/*                <input name="newImage" ref={(ref) => this.upload = ref}*/}
                    {/*                       style={{display: 'none'}} type="file"*/}
                    {/*                       onChange={this.handleNewImage}/>*/}
                    {/*                <button type="button"*/}
                    {/*                        className="btn btn-success rounded btn-sm px-4  float-left"*/}
                    {/*                        onClick={this.selectImage}>Ekle*/}
                    {/*                </button>*/}
                    {/*                <button className="btn btn-primary rounded btn-sm px-4 "*/}
                    {/*                        onClick={() => this.uploadModalToggle()}>Geri*/}
                    {/*                </button>*/}
                    {/*                <input type="button" disabled={this.state.image === undefined}*/}
                    {/*                       className="btn btn-success rounded btn-sm px-4 float-right "*/}
                    {/*                       onClick={this.handleFileUpload} value="Kaydet"/>*/}
                    {/*            </ModalActions>*/}
                    {/*        </Modal>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-7">*/}
                    {/*        <div className="profile-head">*/}
                    {/*            /!*<h2>{this.state.company.name}</h2>*!/*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </>
                }
                {this.state.pageSubTitle === "MediaSettings/subtitleBracket" &&
                <>
                    <div className="row">
                        <div className="col-2 mb-2">
                            <button className="btn btn-primary btn-xs" onClick={() => {
                                this.setState({pageSubTitle: "MediaSettings"});
                                setPageSubTitle('Firma Ayarları')
                            }}><i className="la la-arrow-left"/></button>
                            <span className="ml-1">Geri</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body px-4 py-3 py-md-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-12 col-md-12 text-md-right mt-md-0 mt-4">
                                            <Link onClick={() => this.uploadBraceModalToggle()}
                                                  className="btn btn-primary rounded btn-sm px-4">Ayraç Ekle</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>

                        {
                            braces
                            // this.state.braces.map((brace, index) => {
                            //     return (
                            //         <div className='col-sm-1 d-flex justify-content-center'>
                            //             <img src={brace.logo} alt='Ayrac' style={{width: 50, height: 50}}/>
                            //         </div>
                            //     )
                            // })
                        }

                    </div>
                    <Modal onClose={() => this.uploadBraceModalToggle()}
                           onOpen={() => this.uploadBraceModalToggle()}
                           closeIcon
                           style={{position: "relative", height: "475px", width: "350px"}}
                           size="small"
                           open={this.state.uploadBraceModal}>
                        <ModalHeader style={{height: "50px", width: "350px"}}>Yazı Ayracı Ekleme</ModalHeader>
                        <ModalContent style={{marginTop: "50px", height: "250px"}}>
                            <div className="row">
                                <div className='col-md-12 d-flex justify-content-center'>
                                    <label className='mt-2'>Ayraç Adı : </label>
                                    <input
                                        required
                                        name="braceName"
                                        id="braceName"
                                        className='form-control'
                                        value={this.state.braceName}
                                        onChange={e => this.setState({[e.target.name]: e.target.value})}
                                        style={{
                                            height: 40,
                                            width: 200,
                                            marginLeft: 10,
                                            marginRight: 10,
                                            cursor: 'text'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {this.state.braceImage===undefined?
                                    <div className="col-md-12 mt-5">
                                        <div className="file-input-div" onClick={this.selectBraceImage} style={{cursor:"pointer",height:"200px"}}>
                                            <div className="previewTag" style={{lineHeight:"200px"}}>
                                                Resim eklemek için tıklayın(.jpg, .png)
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col-md-12 d-flex justify-content-center mt-5 file-input-div" onClick={this.selectBraceImage} style={{cursor:"pointer",height:"200px"}}>
                                        <AvatarEditor
                                            ref={this.setEditorRef}
                                            className="editor-canvas"
                                            image={this.state.braceImage ? this.state.braceImage : DefaultImg}
                                            width={50}
                                            height={50}
                                            scale={1}
                                            style={{cursor:"pointer"}}
                                            color={[200, 255, 255, 0]} // RGBA
                                        />
                                        <span style={{position:"absolute",bottom:"5px",right:"5px"}}>Dosya Boyutu : {(this.state.braceFileSize/1024/1024).toFixed(3)} MB</span>
                                    </div>
                                }


                            </div>
                        </ModalContent>
                        <ModalActions style={{marginTop: "60px", height: "50px"}}>
                            <input name="newImage" accept=".jpg, .png, .jpeg" ref={(ref) => this.braceUpload = ref}
                                   style={{display: 'none'}} type="file"
                                   onChange={this.handleNewBraceImage}/>
                            {/*<button type="button"*/}
                            {/*        className="btn btn-success rounded btn-sm px-4  float-left"*/}
                            {/*        onClick={this.selectBraceImage}>Ekle*/}
                            {/*</button>*/}
                            <button className="btn btn-primary rounded btn-sm px-4 "
                                    onClick={() => this.uploadBraceModalToggle()}>Vazgeç
                            </button>
                            <input type="button" disabled={this.state.braceImage === undefined}
                                   className="btn btn-success rounded btn-sm px-4 ml-1 float-right "
                                   onClick={this.handleBraceFileUpload} value="Kaydet"/>
                        </ModalActions>
                    </Modal>
                </>
                }
                {this.state.pageSubTitle === "CompanySettings/testGroupUser" &&
                <>
                    <div className="row">
                        <div className="col-2 mb-2">
                            <button className="btn btn-primary btn-xs" onClick={() => {
                                this.setState({pageSubTitle: "CompanySettings"});
                                setPageSubTitle('Firma Ayarları')
                            }}><i className="la la-arrow-left"/></button>
                            <span className="ml-1">Geri</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body px-4 py-3 py-md-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-12 col-md-12 text-md-right mt-md-0 mt-4">
                                            <Link onClick={() => this.testGroupModalToggle()}
                                                  className="btn btn-primary rounded btn-sm px-4">Kullanıcı Ekle</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div id="list-users" className="tab-pane">
                                <div className="dataTablesCard">
                                    <DataTable
                                        title=""
                                        defaultSortField="first_name"
                                        defaultSortAsc={true}
                                        customStyles={customMediaTableStyles}
                                        noHeader={true}
                                        selectableRows={false}
                                        highlightOnHover={true}
                                        fixedHeader={true}
                                        responsive={true}
                                        expandableRows={false}
                                        progressPending={!this.state.loaded}
                                        expandableRowsComponent={<MediaRowDetail/>}
                                        progressComponent={<LoadingView/>}
                                        noDataComponent={<EmptyView/>}
                                        columns={testGroupUserTableColumns}
                                        data={this.state.testGroupUsers}
                                        pagination={true}
                                        paginationComponentOptions={
                                            {
                                                rowsPerPageText: 'Listelenen:',
                                                rangeSeparatorText: '/',
                                                noRowsPerPage: false,
                                                selectAllRowsItem: false,
                                                selectAllRowsItemText: 'Tümü'
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <Modal onClose={() => this.testGroupModalToggle()}
                               onOpen={() => this.testGroupModalToggle()}
                               closeIcon
                               size="small"
                               open={this.state.testGroupModal}>
                            <ModalHeader>Test Grubuna Kullanıcı Ekle</ModalHeader>
                            <ModalContent>
                                <div className="row">
                                    <div className='col-sm-2'>
                                        <label className='mt-2'>Kullanıcı : </label>
                                    </div>
                                    <div className='col-sm-4'>
                                        <Dropdown
                                            placeholder='Kullanıcı seçiniz...'
                                            fluid
                                            search
                                            selection
                                            onChange={(e,data)=>this.setState({selectedUser: data.value})}
                                            options={this.state?.userList?.map((user, index) => {
                                                return (
                                                    { key: user.id, value: user.id, text: user.full_name }
                                                )
                                            })}
                                        />

                                        {/*<select className="form-select" value={this.state.selectedUser}*/}
                                        {/*        name='selectedUser'*/}
                                        {/*        onChange={e => this.setState({[e.target.name]: e.target.value})}>*/}
                                        {/*    <option hidden value="null">Seçiniz</option>*/}
                                        {/*    {*/}
                                        {/*        this.state?.userList?.map((user, index) => {*/}
                                        {/*            return (*/}
                                        {/*                { key: user.id, value: user.id, text: user.full_name }*/}
                                        {/*            )*/}
                                        {/*        })*/}
                                        {/*    }*/}
                                        {/*</select>*/}
                                    </div>
                                </div>
                            </ModalContent>
                            <ModalActions>
                                <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                                        onClick={() => this.testGroupModalToggle()}>Geri
                                </button>
                                <input type="button" className="btn btn-success rounded btn-sm px-4 float-right "
                                       onClick={this.handleSaveTestGroupUser} value="Ekle"/>
                            </ModalActions>
                        </Modal>
                    </div>
                </>
                }
                {
                    this.state.pageSubTitle === "userSettings" &&
                    <>
                        <div className="row">
                            <div className="col-2 mb-2">
                                <button className="btn btn-primary btn-xs" onClick={() => {
                                    this.setState({pageSubTitle: ""});
                                    setPageSubTitle('')
                                }}><i className="la la-arrow-left"/></button>
                                <span className="ml-1">Geri</span>
                            </div>
                        </div>
                        <div className="row">
                            {/*<div className="col-xl-12">*/}
                            {/*    <div className="card">*/}
                            {/*        <div className="card-body px-4 py-3 py-md-2">*/}
                            {/*            <div className="row align-items-center">*/}
                            {/*                <div className="col-sm-12 col-md-12 text-md-right mt-md-0 mt-4">*/}
                            {/*                    <Link onClick={() => this.userSettingsModalToggle()}*/}
                            {/*                          className="btn btn-primary rounded btn-sm px-4">Kullanıcı*/}
                            {/*                        Ekle</Link>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-xl-12">
                                <div id="list-users" className="tab-pane">
                                    <div className="dataTablesCard">
                                        <DataTable
                                            title=""
                                            defaultSortField="first_name"
                                            defaultSortAsc={true}
                                            customStyles={customMediaTableStyles}
                                            noHeader={true}
                                            selectableRows={false}
                                            highlightOnHover={true}
                                            fixedHeader={true}
                                            responsive={true}
                                            expandableRows={false}
                                            progressPending={!this.state.loaded}
                                            expandableRowsComponent={<MediaRowDetail/>}
                                            progressComponent={<LoadingView/>}
                                            noDataComponent={<EmptyView/>}
                                            columns={profileTableColumns}
                                            data={this.state.profile}
                                            pagination={true}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Listelenen:',
                                                    rangeSeparatorText: '/',
                                                    noRowsPerPage: false,
                                                    selectAllRowsItem: false,
                                                    selectAllRowsItemText: 'Tümü'
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {this.state.pageSubTitle === "CalendarSettings" &&
                <>
                    <div className="row">
                        <div className="col-2 mb-2">
                            <button className="btn btn-primary btn-xs" onClick={() => {
                                this.setState({pageSubTitle: ""});
                                setPageSubTitle('')
                            }}><i className="la la-arrow-left"/></button>
                            <span className="ml-1">Geri</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body px-4 py-3 py-md-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-12 col-md-12 text-md-right mt-md-0 mt-4">
                                            <Link onClick={() => this.calenderModalToggle(true)}
                                                  className="btn btn-primary rounded btn-sm px-4">Takvim Düzenle</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div id="list-users" className="tab-pane">
                                <div className="dataTablesCard">
                                    <DataTable
                                        title=""
                                        defaultSortField="first_name"
                                        defaultSortAsc={true}
                                        customStyles={customMediaTableStyles}
                                        noHeader={true}
                                        selectableRows={false}
                                        highlightOnHover={true}
                                        fixedHeader={true}
                                        responsive={true}
                                        expandableRows={false}
                                        progressPending={!this.state.loaded}
                                        expandableRowsComponent={<MediaRowDetail/>}
                                        progressComponent={<LoadingView/>}
                                        noDataComponent={<EmptyView/>}
                                        columns={calenderTableColumns}
                                        data={this.state.calenderDateRow}
                                        pagination={true}
                                        paginationComponentOptions={
                                            {
                                                rowsPerPageText: 'Listelenen:',
                                                rangeSeparatorText: '/',
                                                noRowsPerPage: false,
                                                selectAllRowsItem: false,
                                                selectAllRowsItemText: 'Tümü'
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal onClose={() => this.calenderModalToggle(false)}
                           onOpen={() => this.calenderModalToggle(true)}
                           closeIcon
                           size="large"
                           open={this.state.calenderModal}>
                        <ModalHeader>Takvim Bilgileri</ModalHeader>
                        <ModalContent>
                            <div className="row">
                                <div className="col-sm-5 card shadow-none rounded-0 bg-transparent h-auto mb-0">
                                    <div className="card-body text-center event-calender">
                                        <DatePickerCalendar locale={tr} modifiers={this.state.modifiers} modifiersClassNames={this.state.modifiersClassNames}
                                                            onDateChange={(e) => {
                                                                let tempArr = this.state.calenderDates
                                                                tempArr.push(moment(e).format())
                                                                this.setState({pickerDate: e, calenderDates: tempArr})
                                                            }}/>
                                    </div>
                                </div>
                                <div className='col-sm-2'>
                                    <label className='mt-2'>Açıklama : </label>
                                </div>
                                <div className='col-sm-4'>
                                    <textarea className="form-select" style={{cursor: 'text', whiteSpace: 'pre-line'}} value={this.state.calenderDescription.replace('\\n', '\n')} onChange={e => {this.setState({calenderDescription: e.target.value})}} rows={3} maxLength={140}/>
                                </div>
                            </div>
                            {/*<div className="col-sm-5">*/}
                            {/*    <DatePickerCalendar locale={tr} date={this.state.pickerDate} modifiers={this.state.pickerDateModifiers} modifiersClassNames={this.state.modifiersClassNames}*/}
                            {/*                        onDateChange={(e)=> {*/}
                            {/*                            let tempArr = this.state.calenderDates*/}
                            {/*                            tempArr.push(moment(e).format())*/}
                            {/*                            this.setState({pickerDate: e, calenderDates: tempArr})*/}
                            {/*                        }}/>*/}
                            {/*</div>*/}
                        </ModalContent>
                        <ModalActions>
                            <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                                    onClick={() => this.calenderModalToggle(false)}>Geri
                            </button>
                            <input type="button" className="btn btn-success rounded btn-sm px-4 float-right "
                                   onClick={this.handleSaveCalender} value="Kaydet"/>
                        </ModalActions>
                    </Modal>

                    {/*<>*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-sm-7">*/}
                    {/*            <div className="card shadow-none rounded-0 bg-transparent w-50  mb-0">*/}
                    {/*                <div className="card-body text-center event-calender pb-2">*/}
                    {/*                    <DatePickerCalendar locale={tr}*/}

                    {/*                                        onDateChange={this.dateChanged}*/}
                    {/*                                        modifiers={this.state.modifiers}*/}
                    {/*                                        modifiersClassNames={this.state.modifiersClassNames}/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</>*/}
                </>

                }
            </div>
        )
    }
}

export default Settings;
