import React, { Component } from 'react';
import {Checkbox, Modal, ModalActions, ModalContent, ModalHeader} from "semantic-ui-react";
import {getProfile, getProfileById, updateProfile, updateUser} from "../../services/api";
import Swal from "sweetalert2";
import PubSub from "pubsub-js";

class UpdateProfileModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addUserModal: false,

            isSystemAdmin: false,
            isContentAdmin: false
        }
    }

    componentDidMount() {
    }

    loadData = () => {
        if (this.props.profile) {
            getProfile(this.props.profile.user.id)
                .then(res => {
                    this.setState({
                        isSystemAdmin: res.user.profile.isSystemAdmin,
                        isContentAdmin: res.user.profile.isContentAdmin,
                    })
                })
                .catch(err => console.log('ERR : ', err))
        }

    }

    handleUpdateProfile = () => {
        const payload = {
            'user': this.props.profile.user.id,
            'isSystemAdmin': this.state.isSystemAdmin,
            'isContentAdmin': this.state.isContentAdmin
        }
        updateProfile(Number(this.props.profile.user.id), payload)
            .then(res => {
                PubSub.publish('load-users',{})
                this.addUserModalToggle(false)
                Swal.fire({
                    icon: 'success',
                    text: 'Kullanıcı Güncellendi.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,
                })
            }).catch(err => {
            Swal.fire({
                icon: 'success',
                text: 'Kullanıcı Güncellenemedi',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            console.log('ERR : ', err)
        })
    }


    addUserModalToggle = (state) => {
        if (state) {
            this.loadData()
        }
        this.setState({ addUserModal: state });
    }
    render() {
        return (
            <Modal onClose={() => this.addUserModalToggle(false)}
                   onOpen={() => this.addUserModalToggle(true)}
                   trigger={this.props.trigger}
                   closeIcon
                   size="small"
                   open={this.state.addUserModal}>
                <ModalHeader>Kullanıcı Ekle</ModalHeader>
                <ModalContent>
                    <div className="row mt-2">
                        <div className='col-sm-3'>
                            <label className='mt-2'>Content Admin : </label>
                        </div>
                        <div className='col-sm-4 mt-2'>
                            <Checkbox label='Content Admin' checked={this.state.isContentAdmin} value={this.state.isContentAdmin}
                                      onChange={() => this.setState({isContentAdmin: !this.state.isContentAdmin})}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-3'>
                            <label className='mt-2'>System Admin : </label>
                        </div>
                        <div className='col-sm-4 mt-2'>
                            <Checkbox label='System Admin' checked={this.state.isSystemAdmin} value={this.state.isSystemAdmin}
                                      onChange={() => this.setState({isSystemAdmin: !this.state.isSystemAdmin})}/>
                        </div>
                    </div>
                </ModalContent>
                <ModalActions>
                    <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                            onClick={() => this.addUserModalToggle(false)}>Geri
                    </button>
                    <input type="button" className="btn btn-success rounded btn-sm px-4 float-right " onClick={this.handleUpdateProfile} value="Kaydet" />
                </ModalActions>
            </Modal>
        );
    }
}

export default UpdateProfileModal;