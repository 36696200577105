import React from 'react';
import { renderToString } from 'react-dom/server';
import PubSub from "pubsub-js";
import { Button } from "semantic-ui-react";
import PreviewWindow from "../../components/common/PreviewWindow";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewFrame from "../../components/common/PreviewFrame";
import CanvasManager from "../../tools/CanvasManager";
import { setPageTitle } from "../../helpers/LayoutHelper";
import QuizBuilder from "../../components/quiz/QuizBuilder";
import * as API from "../../services/api";
import SurveyBuilder from "../../components/survey/SurveyBuilder";
import {PreviewCss} from "../../helpers/PreviewCssHelper";

const CM = new CanvasManager();
let subTokens = [];

class PublishedSurveyPage extends React.Component {
    mounted = false;

    state = {
        mode: 'quiz',
        content1: {},
        content2: {},
        notify: {},
        model: null,
        questions: []
    };

    constructor(props) {
        super(props);

        this.editorContent1 = null;
        this.editorContent2 = null;
        this.editorNotify = null;
    }

    componentDidMount = () => {
        this.loadTemplate();
        PubSub.publish("CANVAS", { showPanel: false});
    }

    loadTemplate = () => {
        API.getAnnouncement(this.props.location.state.id).then((response) => {
            if (response && response.questions.filter(que => que===null).length ===0) {
                const temp = response.questions.map((data,index)=> {
                    if (data.type==="YESNO"){
                        data.type="Evet/Hayır Sorusu"
                        data.inputType="radio"
                        return data
                    }else if (data.type==="SINGLE"){
                        data.type="Tek Seçimli Soru"
                        data.inputType="radio"
                        return data
                    }else if (data.type==="MULTIPLE"){
                        data.type="Çok Seçimli Soru"
                        data.inputType="checkbox"
                        return data
                    }else if (data.type==="TEXT"){
                        data.type="Açık Uçlu Soru"
                        return data
                    }else if (data.type==="RANGE1"){
                        data.type="Derecelendirme - Tek Satırlı"
                        data.inputType="radio"
                        return data
                    }else if (data.type==="RANGEN"){
                        data.type="Derecelendirme - Çok Satırlı"
                        data.inputType="radio"
                        return data
                    }else if (data.type==="POLARIZATION"){
                        data.type="Eğilim Sorusu"
                        data.inputType="radio"
                        return data
                    }
                })
                this.setState({
                    questions:temp
                })
                console.log(temp)
                setPageTitle(response.title);
                this.setActiveCanvas("design-content-1")
                setTimeout(()=>{
                    this.setActiveCanvas("preview")
                },1)

            }
        })
    }



    setActiveCanvas = (mode) => {
        if (this.state.mode !== mode) {
            this.setState({ mode });
        }

        if (this.editorNotify && this.editorNotify.hideWrappers)
            this.editorNotify.hideWrappers();
        if (this.editorContent1 && this.editorContent1.hideWrappers)
            this.editorContent1.hideWrappers();
        if (this.editorContent2 && this.editorContent2.hideWrappers)
            this.editorContent2.hideWrappers();

        switch (mode) {
            case 'design-content-1':
                if (this.editorContent1)
                    this.editorContent1.showWrappers();
                break;
            case 'design-content-2':
                if (this.editorContent2)
                    this.editorContent2.showWrappers();
                break;
            case 'design-notify':
                if (this.editorNotify)
                    this.editorNotify.showWrappers();
                break;
            case 'preview':

            case 'survey':

            default:
                break;
        }
    }



    surveyToHtml = () => {
        return renderToString(<SurveyBuilder data={this.state.questions} show={true} mode="preview"/>)
    }

    backButton = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="canvasRoot" style={{background: '#ffffff'}}>
                <div className="col-12" style={{backgroundColor:"white",height:"35px"}}>
                    <button className="btn btn-primary btn-xs" onClick={() => {this.backButton()}}><i className="la la-arrow-left"/></button>
                    <span className="ml-1">Geri</span>
                </div>
                {/*<div className="design-change" style={{zIndex:4}}>*/}
                {/*    <Button.Group>*/}
                {/*        <Button toggle onClick={() => this.setActiveCanvas('design-content-1')}>Ön Yazı</Button>*/}
                {/*        <Button toggle onClick={() => this.setActiveCanvas('survey')}>Sorular</Button>*/}
                {/*        <Button toggle onClick={() => this.setActiveCanvas('design-content-2')}>Kapanış Yazısı</Button>*/}
                {/*        <Button toggle onClick={() => this.setActiveCanvas('design-notify')}>Bildirim</Button>*/}
                {/*        <Button toggle onClick={() => this.setActiveCanvas('preview')}>Önizleme</Button>*/}
                {/*    </Button.Group>*/}
                {/*</div>*/}
                <div id="design-content-1" className="canvas-content">

                </div>

                <div id="design-content-2" className="canvas-content">

                </div>

                <div id="design-notify" className="canvas-content">

                </div>



                <div id="preview" style={{ marginLeft: 'auto', marginRight: 'auto', width: 900, paddingLeft: 30, display: this.state.mode === 'preview' ? 'block' : 'none' }}>
                    <PreviewDevice
                        deviceType="DESKTOP"
                        device="macbookpro"
                        scale="0.8"
                        wallpaper="url(/images/macos-wallpaper.png)"
                        notification={{
                            show: false,
                            title: 'Mesaj başlığını buraya yazınız',
                            subtitle: 'Yazılı ve görsel mesajların e-posta trafiğine takılmadan çalışanlara ulaşmasını sağlar.',
                            body: 'Pazarlama',
                            snooze: 0,
                            top: 0,
                            content: this.editorNotify ? `<style>html,body{padding:0; margin:0};${this.editorNotify.getCss()}${PreviewCss.css}</style>${this.editorNotify.getHtml()}` : null
                        }}>
                        <PreviewWindow
                            bgcolor="#ffffff"
                            mediaType="POPUP"
                            title='The title of message'
                            subtitle='The sub title of message'>

                            <PreviewFrame
                                content={`<style>html,body{padding:5; margin:0}</style>${this.surveyToHtml()}`}
                            />

                        </PreviewWindow>
                    </PreviewDevice>

                </div>
            </div>
        )
    }
}

export default PublishedSurveyPage;
