import React from 'react';
import settings from "../../config/settings.json";
import Popup from "react-popup";
import { setPageTitle, setPageSubTitle } from "../../helpers/LayoutHelper";
import tr from 'date-fns/locale/tr';
import PubSub from "pubsub-js";
import ReportCard from "../../components/reports/ReportCard";
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import { Tooltip } from '@trendmicro/react-tooltip';
import {
    getGroups,
    getReport,
    getFolders,
    getUsers,
    searchUser,
    getUsersParam,
    getLoginLogoutDetail, singleUserReport, singleGroupReport, getCompany
} from "../../services/api";
import Chart from 'react-google-charts';
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import mediaList from '../../config/media.json';
import { Link } from "react-router-dom";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import EmptyView from "../../components/common/EmptyView";
import { ProgressBarLine } from 'react-progressbar-line'
import { Button } from "semantic-ui-react";
import UserReportsTable from "../../components/table/userReportsTable";
import Swal from "sweetalert2";

import { jsPDF } from "jspdf";
import GroupReportsTable from "../../components/table/groupReportsTable";

class Reports extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageTitle: 'Raporlar',
            content: 'report_list',
            detailed_filter: false,
            groupOptions: [],
            folderOptions: [],
            userOptions: [],
            loading: false,
            startDate: null,
            endDate: null,
            selectedGroup: '',
            deliveryUsers: '',
            typeR: '',
            folder: '',
            device: '',
            notificationType: '',
            user: '',
            logType: '',
            chartData: [],
            chartType: 'PieChart',
            report: null,
            allUsers: [],
            allUsersConst: [],
            onlineStatus: null,

            userEventReport: null,
            loadingEvent: false,

        }

        this.selectInputRef = React.createRef();
    }

    /* Standart methods for all pages */
    componentDidMount = async () => {
        if (this.props.location.state) {
            this.showReport(this.props.location?.state?.backButtonState?.content)
            this.setState({ startDate: this.props.location?.state?.backButtonState?.startDate, endDate: this.props.location?.state?.backButtonState?.endDate }, () => this.refreshReport())
        }
        PubSub.publish('MENU', 'reports');

        this.updatePageTitle();

        // groups
        let groupResponse = await getGroups();
        let folderResponse = await getFolders();
        let allUsers = await getUsers();

        let groupArr = groupResponse.map((item) => {
            return { "id": item.id, "value": item.id, "label": item.name }
        });
        let folderArr = folderResponse.map((item) => {
            return { "id": item.id, "value": item.id, "label": item.name }
        });
        let adminUsers = allUsers.filter((item) => {
            return item.profile.isContentAdmin || item.profile.isSuperAdmin || item.profile.isSystemAdmin
        }).map(item => {
            return { ...item, full_name: item.first_name + ' ' + item.last_name }
        });

        allUsers.forEach(user => {
            getLoginLogoutDetail(user.id)
                .then(res => {
                    if (res.length !== 0) {

                        let loginLogoutDate = ""
                        let lastLogoutDate = ""
                        if (res[0].isLogin) {
                            let resDate = moment(res[0].loginLogoutDate).format()
                            loginLogoutDate = String(moment(resDate).fromNow()).replace('önce', '').trim()

                        } else {
                            let logoutDate = moment(res[0].loginLogoutDate, 'DD-MM-YYYY hh:mm:ss')
                            let loginDate = moment(res[1].loginLogoutDate, 'DD-MM-YYYY hh:mm:ss')
                            loginLogoutDate = logoutDate.diff(loginDate, 'hours') !== 0 ? (String(logoutDate.diff(loginDate, 'hours')) + " Saat") :
                                String(logoutDate.diff(loginDate, 'minutes')) + " Dakika"
                            lastLogoutDate = moment(res[0].loginLogoutDate).format('DD.MM.YYYY HH:mm')
                        }

                        let info = JSON.parse(res[0]?.ipAddress)


                        user.log = {
                            appVersion: res[0].appVersion,
                            ipAddress: res.operatingSystem === "darwin" ? info.en0[1].address : info["Wi-Fi"][1].address,
                            macAddress: res.operatingSystem === "darwin" ? info.en0[1].mac : info["Wi-Fi"][1].mac,
                            operatingSystem: res[0].operatingSystem,
                            isLogin: res[0].isLogin,
                            loginLogoutDate: loginLogoutDate,
                            logoutDate: lastLogoutDate
                        }
                    } else {
                        user.log = {
                            appVersion: "",
                            ipAddress: "",
                            macAddress: "",
                            operatingSystem: "",
                            isLogin: false,
                            loginLogoutDate: "",
                        }
                    }
                })
                .catch(err => {
                    console.log('ERROR : ', err)
                })
        })


        this.setState({
            allUsers: allUsers,
            allUsersConst: allUsers,
            groupOptions: groupArr,
            folderOptions: folderArr,
            userOptions: adminUsers
        });

    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount() {
        this.updatePage(this.props);
    }

    updatePage = async (props) => {
        this.updatePageTitle();
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {

    };
    /* Standart methods for all pages */

    showReport = async (report) => {
        if (report === "user_events") { setPageSubTitle('Kullanıcı Etkinlik Raporu') }
        if (report === "group_events") { setPageSubTitle('Grup Etkinlik Raporu') }
        if (report === "media_report") { setPageSubTitle('Mecra Raporu') }
        if (report === "admin_report") { setPageSubTitle('İçerik Yöneticisi Raporu') }
        if (report === "poll_report") { setPageSubTitle('Anket Raporu') }
        if (report === "quiz_report") { setPageSubTitle('Quiz Raporu') }
        if (report === "up_down_report") {
            setPageSubTitle('Up/Down Statü Raporu')
            // let allUsers = await getUsers();
            this.setState({ report: [], loading: false })
        }

        this.setState({ content: report, startDate: null, endDate: null })
    }

    handleDateChange = (field, date) => {
        this.setState({ [field]: date }, () => {
            this.refreshReport()
        })
    }

    handleGroupChange = (value) => {
        this.setState({ selectedGroup: value }, () => {
            this.refreshReport()
        })
    };

    handleChangeTypeR = (value) => {
        this.setState({ typeR: value }, () => {
            this.refreshReport()
        })
    }

    handleChangeFolder = (value) => {
        this.setState({ folder: value }, () => {
            this.refreshReport()
        })
    }

    handleChangeOnlineStatus = (value) => {
        let allUsers = []
        if (value === 0) {
            allUsers = this.state.allUsersConst.filter(user => !moment(user.profile?.last_activity).isAfter(moment().subtract(2, 'minutes')))
        } else if (value === 1) {
            allUsers = this.state.allUsersConst.filter(user => moment(user.profile?.last_activity).isAfter(moment().subtract(2, 'minutes')))
        } else {
            allUsers = this.state.allUsersConst
        }
        this.setState({ allUsers: allUsers })
    }

    handleChangeDevice = (value) => {
        this.setState({ device: value }, () => {
            this.refreshReport()
        })
    }

    handleChangeNotifyType = (value) => {
        this.setState({ notificationType: value }, () => {
            this.refreshReport()
        })
    }

    handleChangeUser = (value) => {
        this.setState({ user: value }, () => {
            this.refreshReport()
        })
    }

    handleReceivedUserChange = (value) => {
        // this.selectInputRef.current.select.clearValue();
        this.setState({ deliveryUsers: value, onlineStatus: null }, () => {
            this.refreshReport()
        })
    }

    handleChangeLog = (value) => {
        this.setState({ logType: value }, () => {
            this.refreshReport()
        })
    }

    refreshReport = () => {
        let params = []
        if (this.state.content === "up_down_report") {
            params = [
                { "field": "id", "value": this.state.deliveryUsers },
            ]
            getUsersParam(params).then(r => {
                this.setState({ report: [], loading: false, allUsers: r.results })
            })

        }
        if (this.state.startDate === null || this.state.endDate === null) {
            // warn user due to dates
        } else {
            params = [
                { "field": "announcementType", "value": "" },
                { "field": "folder", "value": this.state.folder },
                { "field": "startDate", "value": moment(this.state.startDate, "DD.MM.YYY").format("YYYY-MM-DD") },
                { "field": "endDate", "value": moment(this.state.endDate, "DD.MM.YYY").format("YYYY-MM-DD") },
                { "field": "type", "value": this.state.content === 'poll_report' ? 'SURVEY' : this.state.content === 'quiz_report' ? 'QUIZ' : this.state.typeR },
                { "field": "deliveryOption", "value": this.state.device },
                { "field": "notificationType", "value": this.state.notificationType },
                { "field": "user", "value": this.state.user },
                { "field": "group", "value": this.state.selectedGroup },
                { "field": "deliveryUsers", "value": this.state.deliveryUsers },
                { "field": "isPublished", "value": "SENT" },
                { "field": "logType", value: this.state.logType }
            ];

            this.setState({ loading: true });

            if (this.state.content !== "up_down_report") {
                getReport(params).then(r => {
                    if (r) {
                        const chartData = [];
                        const types = r?.ozet.totalType;

                        chartData.push(['Mecra', 'Gönderim']);

                        for (let type of types) {
                            let typeTitle = mediaList.filter(m => m.key === type.type)[0]?.title;
                            let typeTotal = type.total;
                            chartData.push([typeTitle, typeTotal]);
                        }


                        this.setState({ report: r, loading: false, chartData: chartData });
                    }

                })
            }


        }
    }


    userEventsSearch = () => {
        if (this.state.startDate === null || this.state.endDate === null) {
            Swal.fire({
                icon: 'warning',
                text: 'Rapor için tarih alanı zorunludur!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }

        this.setState({ loadingEvent: true })
        let startDate = moment(this.state.startDate).format('YYYY-MM-DD')
        let endDate = moment(this.state.endDate).format('YYYY-MM-DD')
        singleUserReport(startDate, endDate, this.state.userEventSearchIds)
            .then(response => {
                const chartData = [];
                const types = response?.ozet.totalType;

                chartData.push(['Mecra', 'Gönderim']);

                for (let [key, value] of Object.entries(types)) {
                    let typeTitle = mediaList.filter(m => m.key === key)[0]?.title
                    let typeTotal = value
                    chartData.push([typeTitle, typeTotal]);
                }

                // for (let type of types) {
                //     let typeTitle = mediaList.filter(m => m.key === type.type)[0]?.title;
                //     let typeTotal = type.total;
                //     chartData.push([typeTitle, typeTotal]);
                // }

                this.setState({ userEventReport: response, loadingEvent: false, pieChartData: chartData })
            })
            .catch(error => {
                console.log('ERROR : ', error)
            })
    }


    groupEventsSearch = () => {
        if (this.state.startDate === null || this.state.endDate === null) {
            Swal.fire({
                icon: 'warning',
                text: 'Rapor için tarih alanı zorunludur!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }
        this.setState({ loadingEvent: true })
        let startDate = moment(this.state.startDate).format('YYYY-MM-DD')
        let endDate = moment(this.state.endDate).format('YYYY-MM-DD')

        singleGroupReport(startDate, endDate, this.state.groupEventSearchIds)
            .then(response => {

                const chartData = [];
                const types = response?.ozet.totalType;

                chartData.push(['Mecra', 'Gönderim']);

                for (let [key, value] of Object.entries(types)) {
                    let typeTitle = mediaList.filter(m => m.key === key)[0]?.title
                    let typeTotal = value
                    chartData.push([typeTitle, typeTotal]);
                }

                // for (let type of types) {
                //     let typeTitle = mediaList.filter(m => m.key === type.type)[0]?.title;
                //     let typeTotal = type.total;
                //     chartData.push([typeTitle, typeTotal]);
                // }

                this.setState({ groupEventReport: response, loadingEvent: false, pieChartData: chartData })

            })
            .catch(error => {
                console.log('ERROR : ', error)
            })
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.content === 'report_list' &&
                    <>
                        <div className="row">
                            <div className="col-3">
                                <ReportCard
                                    id="user_events"
                                    icon="la la-user la-3x"
                                    title="Kullanıcı Etkinlik Raporu"
                                    description="Kullanıcı bazlı etkinlik raporuna buradan erişebilirsiniz."
                                    onSelect={() => this.showReport('user_events')}
                                />
                            </div>
                            <div className="col-3">
                                <ReportCard
                                    id="group_events"
                                    icon="la la-users la-3x"
                                    title="Grup Etkinlik Raporu"
                                    description="Grup bazlı etkinlik raporuna buradan erişebilirsiniz."
                                    onSelect={() => this.showReport('group_events')}
                                />
                            </div>
                            <div className="col-3">
                                <ReportCard
                                    id="media_report"
                                    icon="la la-desktop la-3x"
                                    title="Mecra Raporu"
                                    description="Kullandığınız mecraların performanslarını ölçümleyerek, şirketinize en uygun çözümlerle ilerleyebilirsiniz."
                                    onSelect={() => this.showReport('media_report')}
                                />
                            </div>
                            <div className="col-3">
                                <ReportCard
                                    id="admin_report"
                                    icon="la la-user-edit la-3x"
                                    title="İçerik Yöneticisi Raporu"
                                    description="İçerik yöneticilerinin aktivitelerini buradan görebilirsiniz."
                                    onSelect={() => this.showReport('admin_report')}
                                />
                            </div>
                            <div className="col-3">
                                <ReportCard
                                    id="poll_report"
                                    icon="la la-poll la-3x"
                                    title="Anket Sonuçları"
                                    description="Düzenlediğiniz anketlerin sonuçlarını kontrol edebilir ve detaylı raporlar alabilirsiniz."
                                    onSelect={() => this.showReport('poll_report')}
                                />
                            </div>
                            <div className="col-3">
                                <ReportCard
                                    id="quiz_report"
                                    icon="la la-question la-3x"
                                    title="Quiz Sonuçları"
                                    description="Yaptığınız quiz’lere gelen yanıtları detaylı bir şekilde buradan görebilirsiniz. "
                                    onSelect={() => this.showReport('quiz_report')}
                                />
                            </div>
                            <div className="col-3">
                                <ReportCard
                                    id="up_down_report"
                                    icon="las la-chalkboard la-3x"
                                    title="Erişilebilirlik Raporu"
                                    description="Kullanıcıların anlık olarak erişim durumlarını görebilirsiniz."
                                    onSelect={() => this.showReport('up_down_report')}
                                />
                            </div>
                        </div>
                    </>
                }

                {this.state.content === 'user_events' &&
                    <>
                        <div className="card">
                            <div className="card-body"
                                style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                                <table className="float-left">
                                    <tr>
                                        <td style={{ width: 30 }}>
                                            <button className="btn btn-primary btn-xs" onClick={() => {
                                                this.setState({
                                                    content: 'report_list',
                                                    startDate: null,
                                                    endDate: null,
                                                    userEventReport: null,
                                                });
                                                setPageSubTitle('')
                                            }}><i className="la la-arrow-left" /></button>
                                        </td>
                                        <td style={{ paddingLeft: 10, paddingRight: 15 }}><b>Rapor Tarihi</b></td>
                                        <td>
                                            <DateRangePicker
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onStartDateChange={d => this.handleDateChange('startDate', d)}
                                                onEndDateChange={d => this.handleDateChange('endDate', d)}
                                                minimumLength={0}
                                                format='dd.MM.yyyy'
                                                locale={tr}
                                            >
                                                {({ startDateInputProps, endDateInputProps, focus }) => (
                                                    <div className='date-range'>
                                                        <input
                                                            {...startDateInputProps}
                                                            className={'input' + (focus === START_DATE ? ' -focused' : '')}
                                                            placeholder='Başlangıç'
                                                            style={{
                                                                width: 80,
                                                                height: 36,
                                                                border: 'none',
                                                                marginRight: 15
                                                            }}
                                                        />
                                                        <i className="la la-arrow-right" style={{ marginRight: 15 }} />
                                                        <input
                                                            {...endDateInputProps}
                                                            className={'input' + (focus === END_DATE ? ' -focused' : '')}
                                                            placeholder='Bitiş'
                                                            style={{ width: 80, height: 36, border: 'none' }}
                                                        />
                                                    </div>
                                                )}
                                            </DateRangePicker>
                                        </td>
                                    </tr>
                                </table>
                                <table className="float-right">
                                    <tr>
                                        <td style={{ paddingRight: 15 }} width={400}>
                                            <AsyncSelect
                                                className="borderLess"
                                                isMulti={true}
                                                isClearable={true}
                                                loadingMessage={() => <span>Yükleniyor</span>}
                                                noOptionsMessage={() => <span><i className="la la-sad-cry" /> Kişi bulunamadı</span>}
                                                onChange={e => {
                                                    if (e) {
                                                        let ids = []
                                                        e.forEach((user) => {
                                                            ids.push(user.value)
                                                        })
                                                        ids = ids.join(',')
                                                        this.setState({ userEventSearchIds: ids })
                                                    }
                                                }}
                                                placeholder="Kişi arayın&hellip;"
                                                defaultOptions={[]}
                                                loadOptions={inputValue => {
                                                    return searchUser(inputValue).then(list => {
                                                        return list.map(u => {
                                                            return {
                                                                value: u.id,
                                                                label: u.first_name + ' ' + u.last_name
                                                            }
                                                        })
                                                    })
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip content="Arama" placement="bottom"
                                                tooltipClassName="deneme" tooltipStyle={{}}>
                                                <button className="btn btn-primary btn-xs" onClick={this.userEventsSearch}>
                                                    <i className={`la la-arrow-right`} />
                                                </button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <LoadingOverlay
                            active={this.state.loadingEvent}
                            // spinner
                            text='Rapor hazırlanıyor...'
                        >
                            <div style={{ width: 100, height: 400, display: !this.state.loadingEvent && 'none' }}>

                            </div>
                        </LoadingOverlay>
                        {this.state.userEventReport &&
                            <div id="user-report">
                                <div>
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="ml-3 mt-5">
                                                    <p><strong>Rapor Adı: </strong>Kullanıcı Etkinlik Raporu</p>
                                                    <p><strong>Raporlama Tarihi: </strong>{moment().format('DD.MM.YYYY')}</p>
                                                    <p><strong>Rapor Tarih Aralığı: </strong>{moment(this.state.startDate).format('DD.MM.YYYY')} / {moment(this.state.endDate).format('DD.MM.YYYY')}</p>
                                                    <p><strong>Toplam Gönderi Sayısı: </strong>{this.state.userEventReport.ozet.totalAnnouncementCount}</p>
                                                    <p><strong>Görüntülenen Gönderi Sayısı: </strong>{this.state.userEventReport.ozet.totalOpened}</p>
                                                    <p><strong>Görüntülenmeyen Gönderi Sayısı: </strong>{this.state.userEventReport.ozet.totalNonOpened}</p>
                                                    <p><strong>Görüntüleme Performansı: </strong>{this.state.userEventReport.ozet.totalOpenedRate}</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div>
                                                            <Chart
                                                                style={{ marginLeft: "-30px" }}
                                                                // width={'350px'}
                                                                height={'300px'}
                                                                chartType={this.state.chartType}
                                                                loader={null}
                                                                data={this.state.pieChartData}
                                                                options={{
                                                                    legend: 'bottom',
                                                                    is3D: true
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="mt-5">
                                                            <b>Mecralar</b>
                                                            <br />
                                                            {Object.keys(this.state.userEventReport.ozet.totalType).map((key) => {
                                                                return (
                                                                    <ul>
                                                                        <li className="text-muted fs-13">{this.state.userEventReport.ozet.totalType[key]} {mediaList.filter(media => media.key === key)[0]?.title}</li>
                                                                    </ul>
                                                                )
                                                            })}

                                                            {/*{this.state.userEventReport.ozet.totalType.map(m => {*/}
                                                            {/*    return (*/}
                                                            {/*        <ul>*/}
                                                            {/*            <li className="text-muted fs-13">{m.total} {mediaList.filter(media => media.key === m.type)[0]?.title}</li>*/}
                                                            {/*        </ul>*/}
                                                            {/*    )*/}
                                                            {/*})}*/}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mt-5 mr-3">
                                                            <b>Grafik Tipi</b>
                                                            <p>Aşağıdaki grafik seçeneklerinden verilerinize uygun olanı seçerek
                                                                değiştirebilir ve paydaların/sütunların üzerine gelerek ilgili rakamları
                                                                görebilirsiniz.</p>
                                                            <br />
                                                            <img src="/images/chart1.png" style={{ cursor: 'pointer' }} height={25}
                                                                onClick={() => this.setState({ chartType: 'PieChart' })} />
                                                            <img src="/images/chart2.png" style={{ cursor: 'pointer' }} height={25}
                                                                onClick={() => this.setState({ chartType: 'ColumnChart' })}
                                                                className="mr-2" />
                                                        </div>
                                                        {/*<button onClick={() => {*/}
                                                        {/*    let elementHTML = document.getElementById('user-report')*/}

                                                        {/*    let doc = new jsPDF({format:[1400,600]})*/}

                                                        {/*    console.log('HTML : ', elementHTML)*/}

                                                        {/*    let specialElementHandlers = {*/}
                                                        {/*        '#user-report': (element, renderer) => true*/}
                                                        {/*    }*/}
                                                        {/*    doc.html(elementHTML, {*/}
                                                        {/*        callback: function (doc) {*/}
                                                        {/*            doc.save('test.pdf')*/}
                                                        {/*        }*/}
                                                        {/*    })*/}


                                                        {/*}}>Selam</button>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card table-responsive">
                                        <UserReportsTable data={this.state.userEventReport.Duyurular} generalInfo={this.state.userEventReport.ozet} linkData={{ content: this.state.content, startDate: this.state.startDate, endDate: this.state.endDate }} />
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th style={{ verticalAlign: 'middle' }}>Kişi Adı</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Mecra Türü</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Mecra Adı</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Gönderi Başlığı</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Gönderen</th>
                                                    <th style={{ verticalAlign: 'middle' }}>İçerik Yöneticisi</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Yayınlanma Tarihi</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Görüntülenme Tarihi</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Görüntülenme Durumu</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Cihaz Tipi</th>
                                                    <th style={{ verticalAlign: 'middle' }}>Öteleme Adedi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.userEventReport.Duyurular.map(row => {
                                                    return (
                                                        <tr>
                                                            <td>{row.user}</td>
                                                            <td><img className="float-left mr-2 mt-1"
                                                                src={`/images/icons/${row.type}.png`} width={20}
                                                                height={20} /></td>
                                                            <td>{row.type}</td>
                                                            <td>{row.title}</td>
                                                            <td>{row.sender}</td>
                                                            <td>{row.announcementCreator}</td>
                                                            <td>{!!row.announcementDate ? moment(row.announcementDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                                                            <td>{!!row.duyuruLogUserSeenDate ? moment(row.duyuruLogUserSeenDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                                                            <td>{row.duyuruLogUserSeenStatus}</td>
                                                            <td>{row.deviceType ? row.deviceType : "Bilinmiyor"}</td>
                                                            <td>{row.duyuruUserSnoozedCount}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }



                {this.state.content === 'group_events' &&
                    <>
                        <div className="card">
                            <div className="card-body"
                                style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                                <table className="float-left">
                                    <tr>
                                        <td style={{ width: 30 }}>
                                            <button className="btn btn-primary btn-xs" onClick={() => {
                                                this.setState({
                                                    content: 'report_list',
                                                    startDate: null,
                                                    endDate: null,
                                                    groupEventReport: null,
                                                });
                                                setPageSubTitle('')
                                            }}><i className="la la-arrow-left" /></button>
                                        </td>
                                        <td style={{ paddingLeft: 10, paddingRight: 15 }}><b>Rapor Tarihi</b></td>
                                        <td>
                                            <DateRangePicker
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onStartDateChange={d => this.handleDateChange('startDate', d)}
                                                onEndDateChange={d => this.handleDateChange('endDate', d)}
                                                minimumLength={0}
                                                format='dd.MM.yyyy'
                                                locale={tr}
                                            >
                                                {({ startDateInputProps, endDateInputProps, focus }) => (
                                                    <div className='date-range'>
                                                        <input
                                                            {...startDateInputProps}
                                                            className={'input' + (focus === START_DATE ? ' -focused' : '')}
                                                            placeholder='Başlangıç'
                                                            style={{
                                                                width: 80,
                                                                height: 36,
                                                                border: 'none',
                                                                marginRight: 15
                                                            }}
                                                        />
                                                        <i className="la la-arrow-right" style={{ marginRight: 15 }} />
                                                        <input
                                                            {...endDateInputProps}
                                                            className={'input' + (focus === END_DATE ? ' -focused' : '')}
                                                            placeholder='Bitiş'
                                                            style={{ width: 80, height: 36, border: 'none' }}
                                                        />
                                                    </div>
                                                )}
                                            </DateRangePicker>
                                        </td>
                                    </tr>
                                </table>
                                <table className="float-right">
                                    <tr>
                                        <td style={{ paddingRight: 15 }} width={400}>
                                            <Select
                                                className="borderLess"
                                                defaultValue={this.state.selectedGroup}
                                                isClearable={true}
                                                isSearchable={true}
                                                isMulti={true}
                                                options={this.state.groupOptions}
                                                placeholder="Grup seçin&hellip;"
                                                loadingMessage={() => <span>Yükleniyor</span>}
                                                noOptionsMessage={() => <span><i className="la la-sad-cry" /> Grup bulunamadı</span>}
                                                onChange={e => {
                                                    if (e) {
                                                        let ids = []
                                                        e.forEach((group) => {
                                                            ids.push(group.id)
                                                        })
                                                        ids = ids.join(',')
                                                        this.setState({ groupEventSearchIds: ids })
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip content="Arama" placement="bottom"
                                                tooltipClassName="deneme" tooltipStyle={{}}>
                                                <button className="btn btn-primary btn-xs" onClick={this.groupEventsSearch}>
                                                    <i className={`la la-arrow-right`} />
                                                </button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <LoadingOverlay
                            active={this.state.loadingEvent}
                            // spinner
                            text='Rapor hazırlanıyor...'
                        >
                            <div style={{ width: 100, height: 400, display: !this.state.loadingEvent && 'none' }}>

                            </div>
                        </LoadingOverlay>

                        {this.state.groupEventReport &&
                            <div>
                                <div className="card">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="ml-3 mt-5">
                                                <p><strong>Rapor Adı: </strong>Grup Etkinlik Raporu</p>
                                                <p><strong>Raporlama Tarihi: </strong>{moment().format('DD.MM.YYYY')}</p>
                                                <p><strong>Rapor Tarih aralığı: </strong>{moment(this.state.startDate).format('DD.MM.YYYY')} / {moment(this.state.endDate).format('DD.MM.YYYY')}</p>
                                                <p><strong>Toplam Gönderi Sayısı: </strong>{this.state.groupEventReport.ozet?.totalAnnouncementCount}</p>
                                                <p><strong>Görüntüleme Performansı: </strong>{this.state.groupEventReport.ozet?.totalOpenedRate}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div>
                                                        <Chart
                                                            style={{ marginLeft: "-30px" }}
                                                            // width={'350px'}
                                                            height={'300px'}
                                                            chartType={this.state.chartType}
                                                            loader={null}
                                                            data={this.state.pieChartData}
                                                            options={{
                                                                legend: 'bottom',
                                                                is3D: true
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="mt-5">
                                                        <b>Mecralar</b>
                                                        <br />
                                                        {/*{this.state.report && this.state.report.ozet.totalType.map(m => {*/}
                                                        {/*    return (*/}
                                                        {/*        <ul>*/}
                                                        {/*            <li className="text-muted fs-13">{m.total} {mediaList.filter(media => media.key === m.type)[0]?.title}</li>*/}
                                                        {/*        </ul>*/}
                                                        {/*    )*/}
                                                        {/*})}*/}
                                                        {Object.keys(this.state.groupEventReport.ozet.totalType).map((key) => {
                                                            return (
                                                                <ul>
                                                                    <li className="text-muted fs-13">{this.state.groupEventReport.ozet.totalType[key]} {mediaList.filter(media => media.key === key)[0]?.title}</li>
                                                                </ul>
                                                            )
                                                        })}
                                                        {/*{this.state.groupEventReport.ozet.totalType.map(m => {*/}
                                                        {/*    return (*/}
                                                        {/*        <ul>*/}
                                                        {/*            <li className="text-muted fs-13">{m.total} {mediaList.filter(media => media.key === m.type)[0]?.title}</li>*/}
                                                        {/*        </ul>*/}
                                                        {/*    )*/}
                                                        {/*})}*/}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mt-5 mr-3">
                                                        <b>Grafik Tipi</b>
                                                        <p>Aşağıdaki grafik seçeneklerinden verilerinize uygun olanı seçerek
                                                            değiştirebilir ve paydaların/sütunların üzerine gelerek ilgili rakamları
                                                            görebilirsiniz.</p>
                                                        <br />
                                                        <img src="/images/chart1.png" style={{ cursor: 'pointer' }} height={25}
                                                            onClick={() => this.setState({ chartType: 'PieChart' })} />
                                                        <img src="/images/chart2.png" style={{ cursor: 'pointer' }} height={25}
                                                            onClick={() => this.setState({ chartType: 'ColumnChart' })}
                                                            className="mr-2" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card table-responsive">
                                    <GroupReportsTable data={this.state.groupEventReport.Duyurular} generalInfo={this.state.groupEventReport.ozet} linkData={{ content: this.state.content, startDate: this.state.startDate, endDate: this.state.endDate }} />
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th style={{ verticalAlign: 'middle' }}>Grup Adı</th>
                                                <th style={{ verticalAlign: 'middle' }}>Mecra Türü</th>
                                                <th style={{ verticalAlign: 'middle' }}>Mecra Adı</th>
                                                <th style={{ verticalAlign: 'middle' }}>Gönderi Başlığı</th>
                                                <th style={{ verticalAlign: 'middle' }}>Gönderen</th>
                                                <th style={{ verticalAlign: 'middle' }}>İçerik Yöneticisi</th>
                                                <th style={{ verticalAlign: 'middle' }}>Yayınlanma Tarihi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.groupEventReport.Duyurular.map(row => {
                                                return (
                                                    <tr>
                                                        <td>{row.group}</td>
                                                        <td><img className="float-left mr-2 mt-1"
                                                            src={`/images/icons/${row.type}.png`} width={20}
                                                            height={20} /></td>
                                                        <td>{row.type}</td>
                                                        <td>{row.title}</td>
                                                        <td>{row.sender}</td>
                                                        <td>{row.announcementCreator}</td>
                                                        <td>{!!row.announcementDate ? moment(row.announcementDate).format('DD.MM.YYYY HH:mm:ss') : 'Bilinmiyor'}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </>
                }


                {(this.state.content === 'admin_report' || this.state.content === 'media_report' || this.state.content === 'poll_report' || this.state.content === 'quiz_report' || this.state.content === 'up_down_report') &&
                    <LoadingOverlay
                        active={this.state.loading}
                    // spinner
                    // text='Rapor hazırlanıyor...'
                    >
                        <div>
                            <div className="card">
                                <div className="card-body"
                                    style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                                    <table className="float-left">
                                        <tr>
                                            <td style={{ width: 30 }}>
                                                <button className="btn btn-primary btn-xs" onClick={() => {
                                                    this.setState({
                                                        content: 'report_list',
                                                        detailed_filter: false,
                                                        report: null
                                                    });
                                                    setPageSubTitle('')
                                                }}><i className="la la-arrow-left" /></button>
                                            </td>
                                            {this.state.content !== "up_down_report" &&
                                                <>
                                                    <td style={{ paddingLeft: 10, paddingRight: 15 }}><b>Rapor Tarihi</b></td>
                                                    <td>
                                                        <DateRangePicker
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            onStartDateChange={d => this.handleDateChange('startDate', d)}
                                                            onEndDateChange={d => this.handleDateChange('endDate', d)}
                                                            minimumLength={0}
                                                            format='dd.MM.yyyy'
                                                            locale={tr}
                                                        >
                                                            {({ startDateInputProps, endDateInputProps, focus }) => (
                                                                <div className='date-range'>
                                                                    <input
                                                                        {...startDateInputProps}
                                                                        className={'input' + (focus === START_DATE ? ' -focused' : '')}
                                                                        placeholder='Başlangıç'
                                                                        style={{
                                                                            width: 80,
                                                                            height: 36,
                                                                            border: 'none',
                                                                            marginRight: 15
                                                                        }}
                                                                    />
                                                                    <i className="la la-arrow-right" style={{ marginRight: 15 }} />
                                                                    <input
                                                                        {...endDateInputProps}
                                                                        className={'input' + (focus === END_DATE ? ' -focused' : '')}
                                                                        placeholder='Bitiş'
                                                                        style={{ width: 80, height: 36, border: 'none' }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </DateRangePicker>
                                                    </td>
                                                </>
                                            }

                                        </tr>
                                    </table>
                                    <table className="float-right">
                                        <tr>


                                            {/*{this.state.content === "group_events" &&*/}
                                            {/*    <>*/}
                                            {/*        <td style={{paddingRight: 15}} width={200}>*/}
                                            {/*            <Select*/}
                                            {/*                className="borderLess"*/}
                                            {/*                defaultValue={this.state.selectedGroup}*/}
                                            {/*                isClearable={true}*/}
                                            {/*                isSearchable={true}*/}
                                            {/*                options={this.state.groupOptions}*/}
                                            {/*                placeholder="Grup seçin&hellip;"*/}
                                            {/*                loadingMessage={() => <span>Yükleniyor</span>}*/}
                                            {/*                noOptionsMessage={() => <span><i className="la la-sad-cry"/> Grup bulunamadı</span>}*/}
                                            {/*                onChange={e => {*/}
                                            {/*                    if (e) {*/}
                                            {/*                        this.handleGroupChange(e.id)*/}
                                            {/*                    } else {*/}
                                            {/*                        this.handleGroupChange(null)*/}
                                            {/*                    }*/}
                                            {/*                }}*/}
                                            {/*            />*/}
                                            {/*        </td>*/}
                                            {/*    </>*/}
                                            {/*}*/}

                                            {this.state.content === "media_report" &&
                                                <>
                                                    <td style={{ paddingRight: 15 }} width={200}>
                                                        <Select
                                                            className="borderLess"
                                                            defaultValue={this.state.typeR}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={mediaList.map(m => {
                                                                return { value: m.key, label: m.title }
                                                            })}
                                                            placeholder="Mecra seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeTypeR(e.value)
                                                                } else {
                                                                    this.handleChangeTypeR(null)
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ paddingRight: 15 }} width={200}>
                                                        <Select
                                                            className="borderLess"
                                                            defaultValue={this.state.folder}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={this.state.folderOptions}
                                                            placeholder="Klasör seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeFolder(e.value)
                                                                } else {
                                                                    this.handleChangeFolder(null)
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </>
                                            }

                                            {this.state.content === "admin_report" &&
                                                <>
                                                    <td style={{ paddingRight: 15 }} width={200}>
                                                        <Select
                                                            className="borderLess"
                                                            defaultValue={this.state.user}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={this.state.userOptions.map(u => {
                                                                return { value: u.id, label: u.full_name }
                                                            })}
                                                            placeholder="İçerik Yön&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeUser(e.value)
                                                                } else {
                                                                    this.handleChangeUser(null)
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ paddingRight: 15 }} width={200}>
                                                        <Select
                                                            className="borderLess"
                                                            defaultValue={this.state.typeR}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={mediaList.map(m => {
                                                                return { value: m.key, label: m.title }
                                                            })}
                                                            placeholder="Mecra seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeTypeR(e.value)
                                                                } else {
                                                                    this.handleChangeTypeR(null)
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </>
                                            }
                                            {(this.state.content === "poll_report" || this.state.content === 'quiz_report') &&
                                                <td style={{ paddingRight: 15 }} width={200}>
                                                    <Select
                                                        className="borderLess"
                                                        defaultValue={this.state.folder}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={this.state.folderOptions}
                                                        placeholder="Klasör seçin&hellip;"
                                                        loadingMessage={() => <span>Yükleniyor</span>}
                                                        onChange={e => {
                                                            if (e) {
                                                                this.handleChangeFolder(e.value)
                                                            } else {
                                                                this.handleChangeFolder(null)
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            }
                                            {this.state.content === "up_down_report" &&
                                                <>
                                                    <td style={{ paddingRight: 15 }} width={200}>
                                                        <Select
                                                            ref={this.selectInputRef}
                                                            className="borderLess"
                                                            defaultValue={this.state.onlineStatus}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={[
                                                                { "value": 0, "label": "Çevrimdışı" },
                                                                { "value": 1, "label": "Çevrimiçi" },
                                                            ]
                                                            }
                                                            placeholder="Durum seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeOnlineStatus(e.value)
                                                                } else {
                                                                    this.handleChangeOnlineStatus(null)
                                                                }
                                                            }}
                                                        />

                                                    </td>
                                                    <td style={{ paddingRight: 15 }} width={200}>
                                                        <AsyncSelect
                                                            className="borderLess"
                                                            isMulti={false}
                                                            isClearable={true}
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            noOptionsMessage={() => <span><i className="la la-sad-cry" /> Kişi bulunamadı</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleReceivedUserChange(e.value)
                                                                } else {
                                                                    this.handleReceivedUserChange(null)
                                                                }
                                                            }}
                                                            placeholder="Kişi arayın&hellip;"
                                                            defaultOptions={[]}
                                                            loadOptions={inputValue => {
                                                                return searchUser(inputValue).then(list => {
                                                                    return list.map(u => {
                                                                        return {
                                                                            value: u.id,
                                                                            label: u.username
                                                                        }
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </>
                                            }
                                            {
                                                this.state.content !== 'poll_report' && this.state.content !== 'quiz_report' && this.state.content !== 'user_events' && this.state.content !== 'group_events' &&
                                                <td>
                                                    <Tooltip content="Detaylı Filtreleme" placement="bottom"
                                                        tooltipClassName="deneme" tooltipStyle={{}}>
                                                        <button className="btn btn-primary btn-xs"
                                                            onClick={() => this.setState({ detailed_filter: !this.state.detailed_filter })}>
                                                            <i className={`la ${this.state.detailed_filter ? 'la-arrow-up' : 'la-arrow-down'}`} />
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                            }

                                        </tr>
                                    </table>
                                    {this.state.content === "admin_report" && this.state.detailed_filter &&
                                        <>
                                            <p className="clearfix" />
                                            <div id="detail_filters">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <b>Grup</b>
                                                        <Select
                                                            defaultValue={this.state.selectedGroup}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={this.state.groupOptions}
                                                            placeholder="Grup seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            noOptionsMessage={() => <span><i className="la la-sad-cry" /> Grup bulunamadı</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleGroupChange(e.id)
                                                                } else {
                                                                    this.handleGroupChange(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Kişi</b>
                                                        <AsyncSelect
                                                            isMulti={false}
                                                            isClearable={true}
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            noOptionsMessage={() => <span><i className="la la-sad-cry" /> Kişi bulunamadı</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleReceivedUserChange(e.value)
                                                                } else {
                                                                    this.handleReceivedUserChange(null)
                                                                }
                                                            }}
                                                            placeholder="Kişi arayın&hellip;"
                                                            defaultOptions={[]}
                                                            loadOptions={inputValue => {
                                                                return searchUser(inputValue).then(list => {
                                                                    return list.map(u => {
                                                                        return {
                                                                            value: u.id,
                                                                            label: u.first_name + ' ' + u.last_name
                                                                        }
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Cihaz Tipi</b>
                                                        <Select
                                                            defaultValue={this.state.device}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={[{ value: "ALL", label: "Tümü" }, {
                                                                value: "MOBILE",
                                                                label: "Mobil Cihazlar"
                                                            }, { value: "DESKTOP", label: "Bilgisayarlar" }]}
                                                            placeholder="Cihaz seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeDevice(e.value)
                                                                } else {
                                                                    this.handleChangeDevice(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Gönderi Tipi</b>
                                                        <Select
                                                            defaultValue={this.state.notificationType}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={[{ value: "", label: "Tümü" }, {
                                                                value: "POPUP",
                                                                label: "Bildirim"
                                                            }, { value: "DIRECT", label: "Doğrudan" }, {
                                                                value: "FLASHING",
                                                                label: "Sessiz"
                                                            }]}
                                                            placeholder="Gönderi tipi&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeNotifyType(e.value)
                                                                } else {
                                                                    this.handleChangeNotifyType(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Klasör</b>
                                                        <Select
                                                            defaultValue={this.state.folder}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={this.state.folderOptions}
                                                            placeholder="Klasör seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeFolder(e.value)
                                                                } else {
                                                                    this.handleChangeFolder(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Log</b>
                                                        <Select
                                                            defaultValue={this.state.logType}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={[
                                                                { value: "", label: "Tümü" },
                                                                { value: "DELIVERED", label: "İletildi" },
                                                                { value: "OPENED", label: "Açıldı" },
                                                                { value: "CLOSED", label: "Kapatıldı" },
                                                                { value: "SNOOZED", label: "Ertelendi" },
                                                                { value: "LINK", label: "Linke tıklandı" },
                                                                { value: "STARTED", label: "Başlatıldı" },
                                                                { value: "PAUSED", label: "Durduruldu" },
                                                                { value: "FINISHED", label: "Bitirildi" },
                                                            ]}
                                                            placeholder="Log seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeLog(e.value)
                                                                } else {
                                                                    this.handleChangeLog(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {this.state.content === "media_report" && this.state.detailed_filter &&
                                        <>
                                            <p className="clearfix" />
                                            <div id="detail_filters">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <b>Grup</b>
                                                        <Select
                                                            defaultValue={this.state.selectedGroup}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={this.state.groupOptions}
                                                            placeholder="Grup seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            noOptionsMessage={() => <span><i className="la la-sad-cry" /> Grup bulunamadı</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleGroupChange(e.id)
                                                                } else {
                                                                    this.handleGroupChange(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Kişi</b>
                                                        <AsyncSelect
                                                            isMulti={false}
                                                            isClearable={true}
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            noOptionsMessage={() => <span><i className="la la-sad-cry" /> Kişi bulunamadı</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleReceivedUserChange(e.value)
                                                                } else {
                                                                    this.handleReceivedUserChange(null)
                                                                }
                                                            }}
                                                            placeholder="Kişi arayın&hellip;"
                                                            defaultOptions={[]}
                                                            loadOptions={inputValue => {
                                                                return searchUser(inputValue).then(list => {
                                                                    return list.map(u => {
                                                                        return {
                                                                            value: u.id,
                                                                            label: u.first_name + ' ' + u.last_name
                                                                        }
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Cihaz Tipi</b>
                                                        <Select
                                                            defaultValue={this.state.device}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={[{ value: "ALL", label: "Tümü" }, {
                                                                value: "MOBILE",
                                                                label: "Mobil Cihazlar"
                                                            }, { value: "DESKTOP", label: "Bilgisayarlar" }]}
                                                            placeholder="Cihaz seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeDevice(e.value)
                                                                } else {
                                                                    this.handleChangeDevice(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Gönderi Tipi</b>
                                                        <Select
                                                            defaultValue={this.state.notificationType}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={[{ value: "", label: "Tümü" }, {
                                                                value: "POPUP",
                                                                label: "Bildirim"
                                                            }, { value: "DIRECT", label: "Doğrudan" }, {
                                                                value: "FLASHING",
                                                                label: "Sessiz"
                                                            }]}
                                                            placeholder="Gönderi tipi&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeNotifyType(e.value)
                                                                } else {
                                                                    this.handleChangeNotifyType(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>İçerik Yöneticisi</b>
                                                        <Select
                                                            defaultValue={this.state.user}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={this.state.userOptions.map(u => {
                                                                return { value: u.id, label: u.full_name }
                                                            })}
                                                            placeholder="İçerik Yön&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeUser(e.value)
                                                                } else {
                                                                    this.handleChangeUser(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <b>Log</b>
                                                        <Select
                                                            defaultValue={this.state.logType}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={[
                                                                { value: "", label: "Tümü" },
                                                                { value: "DELIVERED", label: "İletildi" },
                                                                { value: "OPENED", label: "Açıldı" },
                                                                { value: "CLOSED", label: "Kapatıldı" },
                                                                { value: "SNOOZED", label: "Ertelendi" },
                                                                { value: "LINK", label: "Linke tıklandı" },
                                                                { value: "STARTED", label: "Başlatıldı" },
                                                                { value: "PAUSED", label: "Durduruldu" },
                                                                { value: "FINISHED", label: "Bitirildi" },
                                                            ]}
                                                            placeholder="Log seçin&hellip;"
                                                            loadingMessage={() => <span>Yükleniyor</span>}
                                                            onChange={e => {
                                                                if (e) {
                                                                    this.handleChangeLog(e.value)
                                                                } else {
                                                                    this.handleChangeLog(null)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {this.state.content === "user_events" && this.state.detailed_filter &&
                                        //    USER EVENT İÇERİSİNDE DETAYLI FİLTRELEME OLMAYACAĞI İÇİN KOD KAPATILDI
                                        <>
                                            {/*<p className="clearfix"/>*/}
                                            {/*<div id="detail_filters">*/}
                                            {/*    <div className="row">*/}
                                            {/*        <div className="col-2">*/}
                                            {/*            <div className="form-group">*/}
                                            {/*                <b>Mecra</b>*/}
                                            {/*                <Select*/}
                                            {/*                    defaultValue={this.state.typeR}*/}
                                            {/*                    isClearable={true}*/}
                                            {/*                    isSearchable={true}*/}
                                            {/*                    options={mediaList.map(m => {*/}
                                            {/*                        return {value: m.key, label: m.title}*/}
                                            {/*                    })}*/}
                                            {/*                    placeholder="Mecra seçin&hellip;"*/}
                                            {/*                    loadingMessage={() => <span>Yükleniyor</span>}*/}
                                            {/*                    onChange={e => {*/}
                                            {/*                        if (e) {*/}
                                            {/*                            this.handleChangeTypeR(e.value)*/}
                                            {/*                        } else {*/}
                                            {/*                            this.handleChangeTypeR(null)*/}
                                            {/*                        }*/}
                                            {/*                    }}*/}
                                            {/*                />*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="col-2">*/}
                                            {/*            <b>Klasör</b>*/}
                                            {/*            <Select*/}
                                            {/*                defaultValue={this.state.folder}*/}
                                            {/*                isClearable={true}*/}
                                            {/*                isSearchable={true}*/}
                                            {/*                options={this.state.folderOptions}*/}
                                            {/*                placeholder="Klasör seçin&hellip;"*/}
                                            {/*                loadingMessage={() => <span>Yükleniyor</span>}*/}
                                            {/*                onChange={e => {*/}
                                            {/*                    if (e) {*/}
                                            {/*                        this.handleChangeFolder(e.value)*/}
                                            {/*                    } else {*/}
                                            {/*                        this.handleChangeFolder(null)*/}
                                            {/*                    }*/}
                                            {/*                }}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*        <div className="col-2">*/}
                                            {/*            <b>Cihaz Tipi</b>*/}
                                            {/*            <Select*/}
                                            {/*                defaultValue={this.state.device}*/}
                                            {/*                isClearable={true}*/}
                                            {/*                isSearchable={true}*/}
                                            {/*                options={[{value: "ALL", label: "Tümü"}, {*/}
                                            {/*                    value: "MOBILE",*/}
                                            {/*                    label: "Mobil Cihazlar"*/}
                                            {/*                }, {value: "DESKTOP", label: "Bilgisayarlar"}]}*/}
                                            {/*                placeholder="Cihaz seçin&hellip;"*/}
                                            {/*                loadingMessage={() => <span>Yükleniyor</span>}*/}
                                            {/*                onChange={e => {*/}
                                            {/*                    if (e) {*/}
                                            {/*                        this.handleChangeDevice(e.value)*/}
                                            {/*                    } else {*/}
                                            {/*                        this.handleChangeDevice(null)*/}
                                            {/*                    }*/}
                                            {/*                }}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*        <div className="col-2">*/}
                                            {/*            <b>Gönderi Tipi</b>*/}
                                            {/*            <Select*/}
                                            {/*                defaultValue={this.state.notificationType}*/}
                                            {/*                isClearable={true}*/}
                                            {/*                isSearchable={true}*/}
                                            {/*                options={[{value: "", label: "Tümü"}, {*/}
                                            {/*                    value: "POPUP",*/}
                                            {/*                    label: "Bildirim"*/}
                                            {/*                }, {value: "DIRECT", label: "Doğrudan"}, {*/}
                                            {/*                    value: "FLASHING",*/}
                                            {/*                    label: "Sessiz"*/}
                                            {/*                }]}*/}
                                            {/*                placeholder="Gönderi tipi&hellip;"*/}
                                            {/*                loadingMessage={() => <span>Yükleniyor</span>}*/}
                                            {/*                onChange={e => {*/}
                                            {/*                    if (e) {*/}
                                            {/*                        this.handleChangeNotifyType(e.value)*/}
                                            {/*                    } else {*/}
                                            {/*                        this.handleChangeNotifyType(null)*/}
                                            {/*                    }*/}
                                            {/*                }}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*        <div className="col-2">*/}
                                            {/*            <b>İçerik Yöneticisi</b>*/}
                                            {/*            <Select*/}
                                            {/*                defaultValue={this.state.user}*/}
                                            {/*                isClearable={true}*/}
                                            {/*                isSearchable={true}*/}
                                            {/*                options={this.state.userOptions.map(u => {*/}
                                            {/*                    return {value: u.id, label: u.full_name}*/}
                                            {/*                })}*/}
                                            {/*                placeholder="İçerik Yön&hellip;"*/}
                                            {/*                loadingMessage={() => <span>Yükleniyor</span>}*/}
                                            {/*                onChange={e => {*/}
                                            {/*                    if (e) {*/}
                                            {/*                        this.handleChangeUser(e.value)*/}
                                            {/*                    } else {*/}
                                            {/*                        this.handleChangeUser(null)*/}
                                            {/*                    }*/}
                                            {/*                }}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*        <div className="col-2">*/}
                                            {/*            <b>Log</b>*/}
                                            {/*            <Select*/}
                                            {/*                defaultValue={this.state.logType}*/}
                                            {/*                isClearable={true}*/}
                                            {/*                isSearchable={true}*/}
                                            {/*                options={[*/}
                                            {/*                    {value: "", label: "Tümü"},*/}
                                            {/*                    {value: "DELIVERED", label: "İletildi"},*/}
                                            {/*                    {value: "OPENED", label: "Açıldı"},*/}
                                            {/*                    {value: "CLOSED", label: "Kapatıldı"},*/}
                                            {/*                    {value: "SNOOZED", label: "Ertelendi"},*/}
                                            {/*                    {value: "LINK", label: "Linke tıklandı"},*/}
                                            {/*                    {value: "STARTED", label: "Başlatıldı"},*/}
                                            {/*                    {value: "PAUSED", label: "Durduruldu"},*/}
                                            {/*                    {value: "FINISHED", label: "Bitirildi"},*/}
                                            {/*                ]}*/}
                                            {/*                placeholder="Log seçin&hellip;"*/}
                                            {/*                loadingMessage={() => <span>Yükleniyor</span>}*/}
                                            {/*                onChange={e => {*/}
                                            {/*                    if (e) {*/}
                                            {/*                        this.handleChangeLog(e.value)*/}
                                            {/*                    } else {*/}
                                            {/*                        this.handleChangeLog(null)*/}
                                            {/*                    }*/}
                                            {/*                }}*/}
                                            {/*            />*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </>
                                    }
                                </div>
                            </div>
                            {this.state.report && this.state.content === "user_events" &&
                                <div>
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="ml-3 mt-5">
                                                    <p><strong>Rapor Adı: </strong>Rapor adı</p>
                                                    <p><strong>Raporlama Tarihi: </strong>{moment().format('DD.MM.YYYY')}</p>
                                                    <p><strong>Rapor Tarih aralığı: </strong>{moment(this.state.startDate).format('DD.MM.YYYY')} / {moment(this.state.endDate).format('DD.MM.YYYY')}</p>
                                                    <p><strong>Toplam Gönderi Sayısı: </strong>{this.state.report && this.state.report.ozet.totalAnnouncementCount}</p>
                                                    <p><strong>Görüntülenen Gönderi Sayısı: </strong>90</p>
                                                    <p><strong>Görüntülenmeyen Gönderi Sayısı: </strong>10</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div>
                                                            <Chart
                                                                style={{ marginLeft: "-30px" }}
                                                                // width={'350px'}
                                                                height={'300px'}
                                                                chartType={this.state.chartType}
                                                                loader={null}
                                                                data={this.state.chartData}
                                                                options={{
                                                                    legend: 'bottom',
                                                                    is3D: true
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="mt-5">
                                                            <b>Mecralar</b>
                                                            <br />
                                                            {this.state.report && this.state.report.ozet.totalType.map(m => {
                                                                return (
                                                                    <ul>
                                                                        <li className="text-muted fs-13">{m.total} {mediaList.filter(media => media.key === m.type)[0]?.title}</li>
                                                                    </ul>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mt-5 mr-3">
                                                            <b>Grafik Tipi</b>
                                                            <p>Aşağıdaki grafik seçeneklerinden verilerinize uygun olanı seçerek
                                                                değiştirebilir ve paydaların/sütunların üzerine gelerek ilgili rakamları
                                                                görebilirsiniz.</p>
                                                            <br />
                                                            <img src="/images/chart1.png" style={{ cursor: 'pointer' }} height={25}
                                                                onClick={() => this.setState({ chartType: 'PieChart' })} />
                                                            <img src="/images/chart2.png" style={{ cursor: 'pointer' }} height={25}
                                                                onClick={() => this.setState({ chartType: 'ColumnChart' })}
                                                                className="mr-2" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<table>*/}
                                        {/*    <tr>*/}
                                        {/*        <td>*/}
                                        {/*            <Chart*/}
                                        {/*                width={'350px'}*/}
                                        {/*                height={'300px'}*/}
                                        {/*                chartType={this.state.chartType}*/}
                                        {/*                loader={null}*/}
                                        {/*                data={this.state.chartData}*/}
                                        {/*                options={{*/}
                                        {/*                    legend: 'bottom',*/}
                                        {/*                    is3D: true*/}
                                        {/*                }}*/}
                                        {/*            />*/}
                                        {/*        </td>*/}
                                        {/*        <td width={150} style={{paddingLeft: 10, verticalAlign: 'center'}}>*/}
                                        {/*            <b className="mb-1">Toplam</b>*/}
                                        {/*            <br/>*/}
                                        {/*            <h4>{this.state.report && this.state.report.ozet.totalAnnouncementCount}</h4>*/}
                                        {/*            <br/>*/}
                                        {/*            <b className="mb-1">Mecralar</b>*/}
                                        {/*            <br/>*/}
                                        {/*            {this.state.report && this.state.report.ozet.totalType.map(m => {*/}
                                        {/*                return (*/}
                                        {/*                    <ul>*/}
                                        {/*                        <li className="text-muted fs-13">{m.total} {mediaList.filter(media => media.key === m.type)[0]?.title}</li>*/}
                                        {/*                    </ul>*/}
                                        {/*                )*/}
                                        {/*            })}*/}
                                        {/*        </td>*/}
                                        {/*        <td style={{paddingLeft: 50}}>*/}
                                        {/*            <b className="mb-1">Grafik Tipi</b>*/}
                                        {/*            <p>Aşağıdaki grafik seçeneklerinden verilerinize uygun olanı seçerek*/}
                                        {/*                değiştirebilir ve paydaların/sütunların üzerine gelerek ilgili rakamları*/}
                                        {/*                görebilirsiniz.</p>*/}
                                        {/*            <br/>*/}
                                        {/*            <img src="/images/chart1.png" style={{cursor: 'pointer'}} height={25}*/}
                                        {/*                 onClick={() => this.setState({chartType: 'PieChart'})}/>*/}
                                        {/*            <img src="/images/chart2.png" style={{cursor: 'pointer'}} height={25}*/}
                                        {/*                 onClick={() => this.setState({chartType: 'ColumnChart'})}*/}
                                        {/*                 className="mr-2"/>*/}
                                        {/*            /!*<img src="/images/chart4.png" style={{cursor: 'pointer'}} height={25}*!/*/}
                                        {/*            /!*     onClick={() => this.setState({chartType: 'LineChart'})}/>*!/*/}
                                        {/*        </td>*/}
                                        {/*    </tr>*/}
                                        {/*</table>*/}
                                    </div>
                                    <div className="card">
                                        {/*<UserReportsTable data={this.state.report.Duyurular} linkData={{content: this.state.content, startDate: this.state.startDate, endDate: this.state.endDate}}/>*/}
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th width={200}>Mecra</th>
                                                    <th width={100}>Klasör</th>
                                                    <th width={150}>İçerik Yöneticisi</th>
                                                    <th width={250} className="text-center">Okunma/Ulaşma</th>
                                                    <th>Gruplar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.report && this.state.report.Duyurular.map(row => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <Link to={{
                                                                    pathname: '/report_detail/' + row.id, state: {
                                                                        content: this.state.content,
                                                                        startDate: this.state.startDate,
                                                                        endDate: this.state.endDate
                                                                    }
                                                                }} >
                                                                    <img className="float-left mr-2 mt-1"
                                                                        src={`/images/icons/${row.type}.png`} width={20}
                                                                        height={20} />
                                                                    <div
                                                                        className="float-left">{row?.title}<br /><small
                                                                            className="text-muted">{row.sender}</small></div>
                                                                </Link>
                                                            </td>
                                                            <td>{row.folder}</td>
                                                            <td>{row.createdby}</td>
                                                            <td className="text-center">
                                                                {row.duyuruLogOpenedCount}/{row.duyuruLogDeliveredCount}
                                                                <br />
                                                                <ProgressBarLine
                                                                    value={row.openRate}
                                                                    min={0}
                                                                    max={100}
                                                                    trailWidth={1}
                                                                    strokeWidth={3}
                                                                    styles={{
                                                                        width: 100,
                                                                        path: {
                                                                            stroke: '#3b4182'
                                                                        },
                                                                        trail: {
                                                                            stroke: '#646aa5'
                                                                        },
                                                                        text: {
                                                                            fill: '#404040',
                                                                            textAlign: 'center',
                                                                            fontSize: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ fontSize: 13 }}>{row.groups.split(",").map(g => <span
                                                                className="badge badge-sm light badge-secondary mr-1 mt-2">{g}</span>)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {this.state.report && this.state.content === "media_report" &&
                                <div>
                                    <div className="card">
                                        <table>
                                            <tr>
                                                <td>
                                                    <Chart
                                                        width={'350px'}
                                                        height={'300px'}
                                                        chartType={this.state.chartType}
                                                        loader={null}
                                                        data={this.state.chartData}
                                                        options={{
                                                            legend: 'bottom',
                                                            is3D: true
                                                        }}
                                                    />
                                                </td>
                                                <td width={150} style={{ paddingLeft: 10, verticalAlign: 'center' }}>
                                                    <b className="mb-1">Toplam</b>
                                                    <br />
                                                    <h4>{this.state.report && this.state.report.ozet.totalAnnouncementCount}</h4>
                                                    <br />
                                                    <b className="mb-1">Mecralar</b>
                                                    <br />
                                                    {this.state.report && this.state.report.ozet.totalType.map(m => {
                                                        return (
                                                            <ul>
                                                                <li className="text-muted fs-13">{m.total} {mediaList.filter(media => media.key === m.type)[0]?.title}</li>
                                                            </ul>
                                                        )
                                                    })}
                                                </td>
                                                <td style={{ paddingLeft: 50 }}>
                                                    <b className="mb-1">Grafik Tipi</b>
                                                    <p>Aşağıdaki grafik seçeneklerinden verilerinize uygun olanı seçerek
                                                        değiştirebilir ve paydaların/sütunların üzerine gelerek ilgili rakamları
                                                        görebilirsiniz.</p>
                                                    <br />
                                                    <img src="/images/chart1.png" style={{ cursor: 'pointer' }} height={25}
                                                        onClick={() => this.setState({ chartType: 'PieChart' })} />
                                                    <img src="/images/chart2.png" style={{ cursor: 'pointer' }} height={25}
                                                        onClick={() => this.setState({ chartType: 'ColumnChart' })}
                                                        className="mr-2" />
                                                    {/*<img src="/images/chart4.png" style={{cursor: 'pointer'}} height={25}*/}
                                                    {/*     onClick={() => this.setState({chartType: 'LineChart'})}/>*/}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="card">
                                        {/*<UserReportsTable data={this.state.report.Duyurular} linkData={{content: this.state.content, startDate: this.state.startDate, endDate: this.state.endDate}}/>*/}
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th width={200}>Mecra</th>
                                                    <th width={100}>Klasör</th>
                                                    <th width={150}>İçerik Yöneticisi</th>
                                                    <th width={250} className="text-center">Okunma/Ulaşma</th>
                                                    <th>Gruplar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.report && this.state.report.Duyurular.map(row => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <Link to={{
                                                                    pathname: '/report_detail/' + row.id, state: {
                                                                        content: this.state.content,
                                                                        startDate: this.state.startDate,
                                                                        endDate: this.state.endDate
                                                                    }
                                                                }} >
                                                                    <img className="float-left mr-2 mt-1"
                                                                        src={`/images/icons/${row.type}.png`} width={20}
                                                                        height={20} />
                                                                    <div
                                                                        className="float-left">{row?.title}<br /><small
                                                                            className="text-muted">{row.sender}</small></div>
                                                                </Link>
                                                            </td>
                                                            <td>{row.folder}</td>
                                                            <td>{row.createdby}</td>
                                                            <td className="text-center">
                                                                {row.duyuruLogOpenedCount}/{row.duyuruLogDeliveredCount}
                                                                <br />
                                                                <ProgressBarLine
                                                                    value={row.openRate}
                                                                    min={0}
                                                                    max={100}
                                                                    trailWidth={1}
                                                                    strokeWidth={3}
                                                                    styles={{
                                                                        width: 100,
                                                                        path: {
                                                                            stroke: '#3b4182'
                                                                        },
                                                                        trail: {
                                                                            stroke: '#646aa5'
                                                                        },
                                                                        text: {
                                                                            fill: '#404040',
                                                                            textAlign: 'center',
                                                                            fontSize: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ fontSize: 13 }}>{row.groups.split(",").map(g => <span
                                                                className="badge badge-sm light badge-secondary mr-1 mt-2">{g}</span>)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {this.state.report && this.state.content === "up_down_report" &&
                                <div>
                                    {/*<div className="card">*/}
                                    {/*    <table>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>*/}
                                    {/*                <Chart*/}
                                    {/*                    width={'350px'}*/}
                                    {/*                    height={'300px'}*/}
                                    {/*                    chartType={this.state.chartType}*/}
                                    {/*                    loader={null}*/}
                                    {/*                    data={this.state.chartData}*/}
                                    {/*                    options={{*/}
                                    {/*                        legend: 'bottom',*/}
                                    {/*                        is3D: true*/}
                                    {/*                    }}*/}
                                    {/*                />*/}
                                    {/*            </td>*/}
                                    {/*            <td width={150} style={{paddingLeft: 10, verticalAlign: 'center'}}>*/}
                                    {/*                <b className="mb-1">Toplam</b>*/}
                                    {/*                <br/>*/}
                                    {/*                <h4>{this.state.report && this.state.report.ozet.totalAnnouncementCount}</h4>*/}
                                    {/*                <br/>*/}
                                    {/*                <b className="mb-1">Mecralar</b>*/}
                                    {/*                <br/>*/}
                                    {/*                {this.state.report && this.state.report.ozet.totalType.map(m => {*/}
                                    {/*                    return (*/}
                                    {/*                        <ul>*/}
                                    {/*                            <li className="text-muted fs-13">{m.total} {mediaList.filter(media => media.key === m.type)[0]?.title}</li>*/}
                                    {/*                        </ul>*/}
                                    {/*                    )*/}
                                    {/*                })}*/}
                                    {/*            </td>*/}
                                    {/*            <td style={{paddingLeft: 50}}>*/}
                                    {/*                <b className="mb-1">Grafik Tipi</b>*/}
                                    {/*                <p>Aşağıdaki grafik seçeneklerinden verilerinize uygun olanı seçerek*/}
                                    {/*                    değiştirebilir ve paydaların/sütunların üzerine gelerek ilgili rakamları*/}
                                    {/*                    görebilirsiniz.</p>*/}
                                    {/*                <br/>*/}
                                    {/*                <img src="/images/chart1.png" style={{cursor: 'pointer'}} height={25}*/}
                                    {/*                     onClick={() => this.setState({chartType: 'PieChart'})}/>*/}
                                    {/*                <img src="/images/chart2.png" style={{cursor: 'pointer'}} height={25}*/}
                                    {/*                     onClick={() => this.setState({chartType: 'ColumnChart'})}*/}
                                    {/*                     className="mr-2"/>*/}
                                    {/*                <img src="/images/chart4.png" style={{cursor: 'pointer'}} height={25}*/}
                                    {/*                     onClick={() => this.setState({chartType: 'LineChart'})}/>*/}
                                    {/*            </td>*/}
                                    {/*        </tr>*/}
                                    {/*    </table>*/}
                                    {/*</div>*/}
                                    <div className="card">
                                        {/*<UserReportsTable data={this.state.report.Duyurular} linkData={{content: this.state.content, startDate: this.state.startDate, endDate: this.state.endDate}}/>*/}
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className="text-center">
                                                    <th >Ad</th>
                                                    <th >Soyad</th>
                                                    <th >Kullanıcı Adı</th>
                                                    <th >Cihaz Tipi</th>
                                                    <th >Mac Adresi</th>
                                                    <th >IP Adresi</th>
                                                    <th >Versiyon</th>
                                                    <th>Son Erişim Zamanı</th>
                                                    <th>Çıkış Tarihi</th>
                                                    <th>Çalışma Süresi (Kesintisiz)</th>
                                                    <th >Çevrimiçi/Çevrimdışı</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* OLD SORT
                                        this.state.allUsers.sort((a,b)=>b.profile?.last_activity.localeCompare(a.profile?.last_activity)).map
                                    */}
                                                {this.state.report && this.state.allUsers.sort((a, b) => a.log?.isLogin === b.log?.isLogin ? 0 : a.log?.isLogin ? -1 : 1).map(row => {
                                                    if (row.profile) {
                                                        return (
                                                            <tr className="text-center">
                                                                <td>{row.first_name}</td>
                                                                <td>{row.last_name}</td>
                                                                <td>{row.username}</td>
                                                                <td>{row.log ? row.log.operatingSystem : "Bilinmiyor"}</td>
                                                                <td>{row.log ? row.log.macAddress : "Bilinmiyor"}</td>
                                                                <td>{row.log ? row.log.ipAddress : "Bilinmiyor"}</td>
                                                                <td>{row.log ? row.log.appVersion : "Bilinmiyor"}</td>
                                                                <td>{moment(row.profile?.last_activity).format("DD.MM.YYYY HH:mm")}</td>
                                                                <td>{row.log ? row.log.logoutDate : "Bilinmiyor"}</td>
                                                                <td>{row.log ? row.log.loginLogoutDate : "Bilinmiyor"}</td>
                                                                {/*<td className="text-center"><button className="btn btn-xl" style={{color:moment(row.profile?.last_activity).isAfter(moment().subtract(2, 'minutes'), 'minutes') ? "green" : "red"}}>*/}
                                                                {/*    {moment(row.profile?.last_activity).isAfter(moment().subtract(2, 'minutes'), 'minutes')? <i className="fa fa-check fs-20"/> : <i className="fa fa-times fs-20"/>}*/}
                                                                {/*</button></td>*/}
                                                                <td className="text-center">
                                                                    <button className="btn btn-xl" style={{ color: row.log?.isLogin === true ? "green" : "red" }}>
                                                                        {row.log?.isLogin ? <i className="fa fa-check fs-20" /> : <i className="fa fa-times fs-20" />}
                                                                    </button></td>
                                                            </tr>
                                                        )
                                                    }

                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }

                            {!this.state.report &&
                                <EmptyView
                                    title="Rapor oluşturmak için lütfen başlangıç ve bitiş tarihini seçiniz."
                                />
                            }
                        </div>
                    </LoadingOverlay>
                }
            </div>
        )
    }
}

export default Reports;
