import React from 'react';
import Chart from "react-google-charts";
import { Tooltip } from '@trendmicro/react-tooltip';
import { Link } from 'react-router-dom';
import {Modal, ModalActions, ModalContent, ModalHeader} from "semantic-ui-react";
import AvatarEditor from "react-avatar-editor";
import {protectedApi} from "../../services/axios";
import Swal from "sweetalert2";
import {removeUsers} from "../../services/api";


class MessageItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sentDepartmentsToggle:false,
            sendAgainModal: false,
            sendAgainValue: 0
        }

    }

    sentDepartmentsToggle = () => {
        this.setState({sentDepartmentsToggle:!this.state.sentDepartmentsToggle})
    }
    handleDeletePost = (id) => {
        const payload = {
            isVisible:false
        }
        Swal.fire({
            icon: 'warning',
            text: "Seçtiğiniz duyuruyu silmek istiyor musunuz?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#3B4182',
            confirmButtonText: 'Evet',
            cancelButtonColor: '#E40426',
            cancelButtonText: 'Hayır',
            focusCancel: false,
            reverseButtons: true,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                protectedApi.put("/announcement/announcement/"+id,payload)
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            text: 'Duyuru silindi!',
                            // text: response.message,
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,

                        })
                        this.props.loadPage('SENT')
                    }).catch(error => {
                    Swal.fire({
                        icon: 'success',
                        text: 'Duyuru silindi',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    })
                })
            }
        })
        // protectedApi.put("/announcement/announcement/"+id,payload).then(response => this.props.loadPage('SENT'))
        // this.deletePostModalToggle()
    }

    handlePullBack = (id) => {
        const payload = {
            isVisible:false,
            isPullBack:true,
            isPublished:"DRAFT"
        }
        Swal.fire({
            icon: 'warning',
            text: "Seçtiğiniz gönderiyi geri çağırmak istiyor musunuz?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#3B4182',
            confirmButtonText: 'Evet',
            cancelButtonColor: '#E40426',
            cancelButtonText: 'Hayır',
            focusCancel: false,
            reverseButtons: true,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                protectedApi.put("/announcement/announcement/"+id,payload)
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            text: 'Gönderiniz geri çağırıldı, taslak olarak kaydedildi.',
                            // text: response.message,
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,

                        })
                        this.props.loadPage('SENT')
                    }).catch(error => {
                    Swal.fire({
                        icon: 'success',
                        text: 'Gönderiniz geri çağırıldı, taslak olarak kaydedildi.',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,
                    })
                })
            }
        })
        // protectedApi.put("/announcement/announcement/"+id,payload).then(response => this.props.loadPage('SENT'))
        // this.deletePostModalToggle()
    }


    handlePublishAgain = () => {
        // console.log(this.props)
        window.sendAgainId = this.props.id
        if (this.props.type.key==="TICKER"){
            this.props.history.push('/publish/againticker');
        }else{
            this.props.history.push('/publish/again');
        }

    }

    render() {
        const sentDepartments = this.props.to.map((item,index)=>{
            return (
                <div className="col-sm-4" key={index}>
                    <span>&bull;{item}</span>
                </div>
            )
        })
        return (
            <tr className="align-items-center border-bottom p-md-4 p-3" style={{height: 100}}>
                <td style={{width: 30}}>
                    {this.props.sent &&
                    <div style={{height: 56}}>
                        <Link
                            className="number mr-4 px-0 align-self-center d-none d-sm-inline-block">
                            <Tooltip content="Geri Çağır" placement="top">
                                <i className="la la-redo-alt la-2x" style={{cursor: 'pointer',fontSize:"26px"}}
                                   onClick={event => this.handlePullBack(this.props.id)}/>
                            </Tooltip>
                        </Link>
                    </div> }
                </td>
                <td style={{width: 30}}>
                    {this.props.sent &&

                    <>
                        <Link
                            className="number mr-4 px-0 align-self-center d-none d-sm-inline-block">
                            <Tooltip content="Tekrar Gönder" placement="top">
                                <i onClick={() => this.handlePublishAgain()}
                                   className="la la-sync la-2x" style={{cursor: 'pointer'}}/>
                            </Tooltip>
                        </Link>
                        <br/>
                        <Link
                            className="number mr-4 px-0 align-self-center d-none d-sm-inline-block">
                            <Tooltip content="Kaldır" placement="bottom">
                                <i className="la la-trash la-2x" style={{cursor: 'pointer'}}
                                   onClick={event => this.handleDeletePost(this.props.id)}/>
                            </Tooltip>
                        </Link>
                    </>







                    }
                    {!this.props.sent &&
                    <Link className="number mr-4 px-0 align-self-center d-none d-sm-inline-block" style={{color: '#7e7e7e'}}>
                        <Tooltip content="Bu simge mesajın henüz gönderilmediğini gösterir." placement="top">
                            <i className="la la-clock la-2x"/>
                        </Tooltip>
                    </Link>
                    }
                </td>
                <td className="event-list" style={{width: 100}}>
                    <Tooltip content="Mesajın gönderim tarihi" placement="top">
                        <div className={`p-1 text-center date-bx ${this.props.sent ? 'bgl-primary' : 'bgl-danger'}`} style={{width: 60, borderRadius: 4}}>
                            <h3 className="mb-0 text-black">{this.props.startDate.format('D')}</h3>
                            <h6 className="mb-1 text-black fs-13">{this.props.startDate.format('MMMM')}</h6>
                        </div>
                    </Tooltip>
                </td>
                <td>
                    <h5 className="mt-0 mb-0">
                        <Link className="text-black" to={`/report_detail/${this.props.id}`} style={{fontSize: 16}}>
                            <img src={'/images/icons/' + this.props.message.type + '.png'} style={{width: 16, height: 16}}/> {this.props.title}
                        </Link>
                        <div className="mt-3">
                            {this.props.to.map( (item, index) => {
                                if (index <3){
                                    return (
                                        <span key={`item${index}`} className="badge badge-sm light badge-secondary mr-1">{item}</span>
                                    )
                                }
                                if (index === 3){
                                    return (
                                        <span key={`item${index}`} onClick={()=> this.sentDepartmentsToggle()} style={{cursor:"pointer"}} className="badge badge-sm light badge-secondary mr-1">...</span>
                                    )
                                }
                            })}
                        </div>
                    </h5>
                </td>
                <td style={{width: 200}}>
                    <div className="text-center">
                        <span><small><b>{this.props.sender}</b></small></span><br/>
                        <span><small>{`${this.props.message.createdby.first_name} ${this.props.message.createdby.last_name}`}</small></span>
                    </div>
                </td>
                <td style={{width: 100, height: 50}}>
                    <Chart
                        width={80}
                        height={80}
                        chartType="Gauge"
                        loader={""}
                        data={[
                            ['Label', 'Value'],
                            ['Erişim', parseInt(this.props.readPercent)],
                        ]}
                        options={{
                            redFrom: 0,
                            redTo: 20,
                            yellowFrom: 20,
                            yellowTo: 75,
                            greenFrom: 75,
                            greenTo: 100,
                            minorTicks: 5,
                        }}
                    />
                </td>
                <Modal onClose={() => this.sentDepartmentsToggle()}
                       onOpen={() => this.sentDepartmentsToggle()}
                       closeIcon
                       style={{position: "relative", height: "200px", width: "450px"}}
                       size="small"
                       open={this.state.sentDepartmentsToggle}>
                    <ModalHeader style={{height: "50px", width: "450px"}}>Gönderilen Departmanlar</ModalHeader>
                    <ModalContent className="sentDepartmentDiv" style={{height: "150px", width: "450px",overflow:"auto"}}>
                        <div className="row">
                            {sentDepartments}
                        </div>
                    </ModalContent>
                    {/*<ModalActions style={{marginTop: "50px", height: "60px"}}>*/}
                    {/*    <button className="btn btn-primary rounded btn-sm px-4 mr-2"*/}
                    {/*            onClick={() => this.sentDepartmentsToggle()}>Hayır*/}
                    {/*    </button>*/}
                    {/*    <input type="button"*/}
                    {/*           className="btn btn-success rounded btn-sm px-4 float-right "*/}
                    {/*           onClick={()=>this.handleDeletePost(this.props.id)} value="Evet"/>*/}
                    {/*</ModalActions>*/}
                </Modal>

            </tr>
        )
    }
}

export default MessageItem;
