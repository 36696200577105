import React from 'react';
import {getMessageReport, getAnnouncement} from "../../services/api";
import PubSub from "pubsub-js";
import {
    setPageTitle,
    AnnouncementStatus,
    deliveryOptionsTitles,
    notificationDisplay,
    setPageSubTitle
} from "../../helpers/LayoutHelper";
import {Link} from "react-router-dom";
import moment from "moment";
import {MessageTypes} from "../../models/Message";
import GaugeChart from 'react-gauge-chart'
import {Button, Modal} from "semantic-ui-react";
import pieImg from '../../assets/images/pieChart.png'

import { jsPDF } from "jspdf";
import Pdf from "react-to-pdf";

class ReportsDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: 'Detay',
            report: null,
            isLoaded: false,
            questionNo: 0
        }

        this.ref = React.createRef();

        this.announcementId = null
    }

    /* Standart methods for all pages */
    componentDidMount = async () => {
        PubSub.publish('MENU', 'reports');

        this.updatePageTitle();

        // const parsedUrl = new URL(window.location.href);
        this.announcementId = this.props.match.params.id

        // getAnnouncement
        const report = await getMessageReport(this.announcementId);
        const detail = await getAnnouncement(this.announcementId);
        const media = MessageTypes[detail?.type];
        const object = {...report, detail, media};

        this.setState({report: object, isLoaded: true})
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount() {
        this.updatePage(this.props);
    }

    updatePage = async (props) => {
        this.updatePageTitle();
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {

    };

    /* Standart methods for all pages */

    handleExportPdf = () => {
        const doc = new jsPDF();
        doc.setLanguage('tr')
        doc.setFont("", "", undefined)
        // console.log('REPORT : ', this.state.report)
        if(this.state.report.media.key === 'QUIZ' || this.state.report.media.key === 'SURVEY'){
            alert(this.state.report.media.key)
        }
        if(this.state.report.media.key === 'TICKER'){
            alert(this.state.report.media.key)
        }
        if(this.state.report.media.key === 'POPUP'){
            doc.text(`Icerik Yöneticisi: ${this.state.report.ozet.createdby}`, 10, 15);
            doc.text(`Gönd. Olst. Tarihi: ${moment(this.state.report.detail.created).format('DD.MM.YYYY HH:mm')}`, 100, 15);
            doc.text(`Departman: ${this.state.report.ozet.sender.replace(/ğ/g, "g").replace('ı', "i").replace(/ş/g, "s")}`, 10, 25);
            doc.text(`Yayimlanma Tarihi: ${moment(this.state.report.detail.startDate).format('DD.MM.YYYY HH:mm')}`, 100, 25);

            doc.save("a4.pdf");
        }

    }


    render() {
        if (!this.state.isLoaded) {
            return null
        }

        return (
            <div className="container-fluid">
                <div className='row mb-2 ml-2'>
                    <div className='col-md-12'>
                        <Link className="btn btn-primary btn-xs"
                              to={{pathname:'/reports', state: {backButtonState: this.props.location.state}}} >
                            <i className="la la-arrow-left"/>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-body" style={{padding: 5}}>
                                <div className="wrapper wrapper-content">
                                    <div className="ibox" ref={this.ref}>
                                        <div className="ibox-content">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="m-b-md">
                                                        <h2>{this.state.report.ozet.title || this.state.report.ozet.summary}</h2>
                                                    </div>
                                                    <dl className="dl-horizontal">
                                                        <dt>Durum:</dt>
                                                        <dd><span
                                                            className="label duyuroom-bg text-white rounded">{AnnouncementStatus[this.state.report.ozet.isPublished]}</span>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <dl className="dl-horizontal">
                                                        <dt>İçerik Yöneticisi:</dt>
                                                        <dd><img className="img rounded-circle"
                                                                 src="/images/avatar_empty.png" width={20}
                                                                 height={20}/> {this.state.report.ozet.createdby}</dd>
                                                        <dt>Departman:</dt>
                                                        <dd>{this.state.report.ozet.sender}</dd>
                                                        <dt>Klasör:</dt>
                                                        <dd><Link to="/folders"><i
                                                            className="flaticon-381-folder-8"/> {this.state?.report?.ozet?.folder}
                                                        </Link></dd>
                                                        {/*<dt>Gönderi No:</dt>*/}
                                                        {/*<dd>{this.announcementId}</dd>*/}
                                                        <dt>PDF olarak dışa aktar</dt>
                                                        <dd>
                                                            <Pdf targetRef={this.ref} filename={`${this.state.report.ozet.title}.pdf`} scale={0.7}>
                                                                {({ toPdf }) => <Button color='grey' onClick={toPdf}>Dışa Aktar</Button>}
                                                            </Pdf>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="col-lg-7" id="cluster_info">
                                                    <dl className="dl-horizontal">
                                                        <dt>Gönderi Oluşturulma Tarihi:</dt>
                                                        <dd>{moment(this.state.report.detail.created).format('DD.MM.YYYY HH:mm')}</dd>
                                                        <dt>Yayımlanma Tarihi:</dt>
                                                        <dd>{moment(this.state.report.detail.startDate).format('DD.MM.YYYY HH:mm')}</dd>
                                                        <dt>Mecra:</dt>
                                                        <dd className="project-people">
                                                            <img
                                                                src={`/images/icons/${this.state.report.detail.type}.png`}
                                                                width={31} height={31}/> {this.state.report.media.title}
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <dl className="dl-horizontal">
                                                        <dt>Erişim Oranı:</dt>
                                                        <dd>
                                                            <div className="progress progress-striped active m-b-sm">
                                                                <div
                                                                    style={{width: `${(this.state.report.ozet.duyuruLogDeliveredCount/this.state.report.ozet.deliveryUsersCount)*100}%`}}
                                                                    className="progress-bar duyuroom-bg"/>
                                                            </div>
                                                            <small>Gönderinin hedefe ulaşma
                                                                yüzdesi <strong>%{(this.state.report.ozet.duyuruLogDeliveredCount/this.state.report.ozet.deliveryUsersCount)*100}</strong></small>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                            {this.state.report.UserDetails.length > 0 && (this.state.report.media.key !== 'QUIZ' && this.state.report.media.key !== 'SURVEY') &&
                                            <div className="row m-t-sm">
                                                <div className="col-lg-12">
                                                    <div className="panel blank-panel">
                                                        <div className="panel-heading">
                                                            <div className="panel-options">
                                                                <ul className="nav nav-tabs">
                                                                    <li className="active"><a href="#tab-2"
                                                                                              data-toggle="tab"
                                                                                              aria-expanded="true"><b>Kişiler</b></a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="tab-content">
                                                                <div className="tab-pane active" id="tab-2">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped">
                                                                            <thead>
                                                                            <tr>
                                                                                <th>Ad Soyad</th>
                                                                                <th>Mesaj Ulaşma</th>
                                                                                <th>Mesajı Açma</th>
                                                                                <th>Mesajı Kapatma</th>
                                                                                <th>Açma/Kapatma</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {this.state.report.UserDetails.map(u => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{u.userName} {u.userLastName}<br/><small>{u.userEmail}</small>
                                                                                        </td>
                                                                                        <td>{moment(this.state.report.detail.startDate).format('DD.MM.YYYY')}</td>
                                                                                        <td>{u.duyuruLogOpenedCountUserOpened.length > 0 && moment(u.duyuruLogOpenedCountUserOpened[0].logDate).format('DD.MM.YYYY HH:mm')}</td>
                                                                                        <td>{u.duyuruLogClosedCountUserClosed.length > 0 && moment(u.duyuruLogClosedCountUserClosed[0].logDate).format('DD.MM.YYYY HH:mm')}</td>
                                                                                        <td>{u.duyuruLogOpenedCountUser}/{u.duyuruLogClosedCountUser}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {
                                                this.state.report.UserDetails.length > 0 && (this.state.report.media.key === 'QUIZ' || this.state.report.media.key === 'SURVEY') &&
                                                <>
                                                    <div className="row m-t-sm">
                                                        <div className="col-lg-12">
                                                            <div className="panel blank-panel">
                                                                <div className="panel-heading">
                                                                    <div className="panel-options">
                                                                        <ul className="nav nav-tabs">
                                                                            <li className="active"><a href="#tab-2"
                                                                                                      data-toggle="tab"
                                                                                                      aria-expanded="true"><b>Sorular</b></a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="panel-body">
                                                                    <div className="tab-content">
                                                                        <div className="tab-pane active" id="tab-2">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th>Sorular</th>
                                                                                        <th>Gönderilen Kişi Sayısı</th>
                                                                                        <th>Başlayan Kişi Sayısı</th>
                                                                                        <th>Yarım Bırakan kişi sayısı</th>
                                                                                        <th>Yanıt Oranları</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {
                                                                                        this.state?.report?.detail?.questions.map((question,index) => {
                                                                                            // console.log('QUEST : ', question)
                                                                                            return(
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <Button
                                                                                                            onClick={() => this.setState({ questionModal: !this.state.questionModal, questionNo: index })}
                                                                                                            content={`Soru ${index+1}`}
                                                                                                            color="default"/>
                                                                                                    </td>
                                                                                                    <td>{this.state.report.ozet.deliveryUsersCount}</td>
                                                                                                    <td>6</td>
                                                                                                    <td>4</td>
                                                                                                    <td>%{(this.state.report.ozet.duyuruLogDeliveredCount/this.state.report.ozet.deliveryUsersCount)*100}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Modal onClose={() => this.setState({ questionModal: false })} open={this.state.questionModal} style={{width:"50%"}} dimmer='inverted'>
                                                        <Modal.Header>Soru {this.state.questionNo+1}</Modal.Header>
                                                        <Modal.Content>
                                                            <div className='row'>
                                                                <div className='col-sm-8'>
                                                                    {
                                                                        this.state.report.detail.questions[this.state.questionNo].type === "YESNO" &&
                                                                            <>
                                                                                <h3>{this.state.report.detail.questions[this.state.questionNo].text}</h3>
                                                                                {
                                                                                    this.state?.report?.detail?.questions[this.state.questionNo]?.options?.map((options, index) =>{
                                                                                        return(
                                                                                            <h5 key={index}>{options}</h5>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                    }
                                                                    {
                                                                        this.state.report.detail.questions[this.state.questionNo].type === "TEXT" &&
                                                                        <>
                                                                            <h3>{this.state.report.detail.questions[this.state.questionNo].text}</h3>
                                                                        </>
                                                                    }
                                                                    {
                                                                        this.state.report.detail.questions[this.state.questionNo].type === "SINGLE" &&
                                                                        <>
                                                                            <h3>{this.state.report.detail.questions[this.state.questionNo].text}</h3>
                                                                            {
                                                                                this.state?.report?.detail?.questions[this.state.questionNo]?.options?.map((options, index) =>{
                                                                                    return(
                                                                                        <h5 key={index}>{options}</h5>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                    {
                                                                        this.state.report.detail.questions[this.state.questionNo].type === "MULTIPLE" &&
                                                                        <>
                                                                            <h3>{this.state.report.detail.questions[this.state.questionNo].text}</h3>
                                                                            {
                                                                                this.state?.report?.detail?.questions[this.state.questionNo]?.options?.map((options, index) =>{
                                                                                    return(
                                                                                        <h5 key={index}>{options}</h5>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                    {
                                                                        this.state.report.detail.questions[this.state.questionNo].type === "RANGE1" &&
                                                                        <>
                                                                            <h3>{this.state.report.detail.questions[this.state.questionNo].text}</h3>
                                                                            {
                                                                                this.state?.report?.detail?.questions[this.state.questionNo]?.cols?.map((col, index) =>{
                                                                                    return(
                                                                                        <h5 key={index}>{col}</h5>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                    {
                                                                        this.state.report.detail.questions[this.state.questionNo].type === "RANGEN" &&
                                                                        <>
                                                                            <h3>{this.state.report.detail.questions[this.state.questionNo].text}</h3>
                                                                            {
                                                                                this.state?.report?.detail?.questions[this.state.questionNo]?.cols?.map((col, index) =>{
                                                                                    return(
                                                                                        <h5 key={index}>{col}</h5>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                    {
                                                                        this.state.report.detail.questions[this.state.questionNo].type === "POLARIZATION" &&
                                                                        <>
                                                                            <h3>{this.state.report.detail.questions[this.state.questionNo].text}</h3>
                                                                            {
                                                                                this.state?.report?.detail?.questions[this.state.questionNo]?.options?.map((option, index) =>{
                                                                                    return(
                                                                                        <h5 key={index}>{option}</h5>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }

                                                                </div>
                                                                <div className='col-sm-4'>
                                                                    <img src={pieImg} alt="Pie Chart" width="200" height="200"/>
                                                                </div>
                                                            </div>
                                                        </Modal.Content>
                                                    </Modal>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="wrapper wrapper-content project-manager">
                            <h4>Hızlı Bakış</h4>
                            <p className="small">
                                <div className="row">
                                    <div className="col-4" style={{paddingLeft: 0}}>
                                        <GaugeChart
                                            id={`gaugeChart${new Date().getMilliseconds()}`}
                                            style={{width: 100, height: 50}}
                                            percent={this.state.report.ozet.openRate / 100}
                                            colors={['#EA4228', '#F5CD19', '#5BE12C']}
                                            hideText={true}
                                            textColor="#424242"
                                        />
                                    </div>
                                    <div className="col-8">
                                        <b>{this.state.report.ozet.duyuruLogDeliveredCount}</b> kişiye ulaşmış, açılma
                                        oranı <b>%{this.state.report.ozet.openRate}</b>'dür.
                                    </div>
                                </div>
                            </p>

                            <p className="small font-bold">
                                <span><img src="/images/menu/menu_icon_medias_alt.svg" width={14}
                                           height={14}/> {deliveryOptionsTitles[this.state.report.ozet.deliveryOption]}</span>
                                <br/>
                                <span><i
                                    className="flaticon-381-notification"/> {notificationDisplay(this.state.report.ozet.notificationType)}</span>
                            </p>

                            {this.state.report.detail.groups && this.state.report.detail.groups.length > 0 &&
                            <div>
                                <h5>Gruplar</h5>
                                <ul className="tag-list" style={{padding: 0}}>
                                    {this.state.report.detail.groups.map(g => {
                                        return <li><span className="badge badge-sm light badge-secondary"><i
                                            className="fa fa-tag"/> {g.name}</span></li>
                                    })}
                                </ul>
                            </div>
                            }

                            {this.state.report.detail.files && this.state.report.detail.files.length > 0 &&
                            <div className="mt-3">
                                <h5>Dosya Ekleri</h5>
                                <ul className="list-unstyled project-files">
                                    {this.state.report.detail.files.map(f => {
                                        return <li><a href={f.url} target="_blank"><i className="fa fa-file"/> {f.name}
                                        </a></li>
                                    })}
                                </ul>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportsDetail
