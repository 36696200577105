import * as Yup from "yup";

const schema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    type: Yup.string()
        .required('Required'),
    width: Yup.string()
        .required('Required'),
    height: Yup.string()
        .required('Required'),
});

export default schema;