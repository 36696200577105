export const MessageTypes = {
    'POPUP': {key: 'POPUP', title: 'Pop-up Mesaj', create_title: "Mesaj Oluştur", namespace: "MESSAGE",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'TICKER': {key: 'TICKER', title: 'Alt Yazı Bandı', create_title: "Alt Yazı Oluştur", namespace: "MESSAGE",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": true},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'QUIZ': {key: 'QUIZ', title: 'Quiz', create_title: "Quiz Oluştur", namespace: "ANALYSIS",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'SURVEY': {key: 'SURVEY', title: 'Anket', create_title: "Anket Oluştur", namespace: "ANALYSIS",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'LCV': {key: 'LCV', title: 'LCV', create_title: "Davet Oluştur", namespace: "MESSAGE",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'ELEARNING': {key: 'ELEARNING', title: 'Online Eğitim', create_title: "Eğitim Oluştur", namespace: "ANALYSIS",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'EMERGENCY': {key: 'EMERGENCY', title: 'Acil Durum Mesajı', create_title: "Uyarı Oluştur", namespace: "MESSAGE",
        "defaults": {
            "notifyType": "DIRECT",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": true,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'SOCIAL': {key: 'SOCIAL', title: 'Sosyal Hat', create_title: "Oluştur", namespace: "MESSAGE",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'LOCK': {key: 'LOCK', title: 'Kilit Ekranı', create_title: "Oluştur", namespace: "MESSAGE",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'WALLPAPER': {key: 'WALLPAPER', title: 'Duvar Kağıdı', create_title: "Oluştur", namespace: "MESSAGE",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'SCREENSAVER': {key: 'SCREENSAVER', title: 'Ekran Koruyucu', create_title: "Oluştur", namespace: "MESSAGE",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    },
    'NEWSLETTER': {key: 'NEWSLETTER', title: 'Newsletter', namespace: "MESSAGE",
        "defaults": {
            "notifyType": "POPUP",
            "devices": {"mobile": false, "desktop": false},
            "isAllGroups": false,
            "bgColor": "#ffffff",
            "textColor": "#000000"
        }
    }
}
