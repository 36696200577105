import React from 'react';
import ReactDevicePreview from "react-device-preview";
import PreviewNotification from "./PreviewNotification";
import PreviewDesktopNotification from "./PreviewDesktopNotification";
import PreviewScreen from "./PreviewScreen";

class PreviewDevice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showContent: false
        }
    }

    onPress = () => {
        this.setState({showContent: true})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({showContent: false})
    }

    render(){
        return(
            <ReactDevicePreview device={this.props.device} scale={this.props.scale}>
                <PreviewScreen wallpaper={this.props.wallpaper}>

                    {this.props.notification && (!this.state.showContent && this.props.notification.show && this.props.deviceType === 'MOBILE') &&
                        <PreviewNotification
                            deviceType={this.props.deviceType}
                            notification={this.props.notification}
                            onPress={this.onPress}
                        />
                    }

                    {this.props.notification && (!this.state.showContent && this.props.notification.show && this.props.deviceType === 'DESKTOP') &&
                        <PreviewDesktopNotification
                            deviceType={this.props.deviceType}
                            notification={this.props.notification}
                            onPress={this.onPress}
                        />
                    }

                    {this.props.notification && ((this.props.notification.show && this.state.showContent) || !this.props.notification.show ) &&
                        <div onClick={() => this.setState({showContent: false})} style={{cursor: 'pointer', height: '100%'}}>
                            {this.props.children}
                        </div>
                    }

                </PreviewScreen>
            </ReactDevicePreview>
        )
    }
}

export default PreviewDevice;
