import React from 'react';
import PubSub from "pubsub-js";
import {setPageTitle} from "../../helpers/LayoutHelper";
import * as API from "../../services/api";
import Swal from "sweetalert2";
import CustomStorage from "../../models/CustomStorage";
import {getProfile} from "../../services/api";
import LoadingOverlay from 'react-loading-overlay';

class HelpContact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "Yardım",
            name: "",
            email: "",
            message: "",
            sending:false
        }
        this.cs = new CustomStorage();
    }

    /* Standart methods for all pages */
    componentDidMount() {
        const user = this.cs.getItem('user');
        const user_id = JSON.parse(user).user.pk;

        getProfile(user_id).then(data => {
            if (data !== undefined) {
                console.log("DATATATATATA", data)
                this.setState({
                    name: data.user.first_name + " " + data.user.last_name,
                    email: data.user.email
                })
            }
        })

        this.updatePageTitle();

        PubSub.publish('MENU', '');
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount() {
        this.updatePage(this.props);
    }

    updatePage = async (props) => {
        this.updatePageTitle();
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {

    };
    /* Standart methods for all pages */

    isEmailValid = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isEmpty = (str) => {
        return !str.trim().length
    }

    handleSendClick = () => {
        this.setState({sending:true})
        let errors = false

        if (this.isEmpty(this.state.name) || this.isEmpty(this.state.message)) {
            errors = true
        }
        if (this.state.email === '' || !this.isEmailValid(this.state.email)) {
            errors = true;
        }

        if (errors) {
            Swal.fire({
                icon: 'warning',
                title: 'Bütün alanları doldurunuz!',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
        } else {
            const payload = {
                "fullname": this.state.name,
                "email": this.state.email,
                "message_text": this.state.message
            }
            API.sendEmailHelp(payload).then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Mesajınız gönderildi.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })

                this.setState({sending:false})
            })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Mesaj oluşturulamadı!',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,

                    })

                    this.setState({sending:false})
                })
            this.setState({
                message: ""
            })
        }


    }

    render() {
        return (
            <LoadingOverlay
                active={this.state.sending}
                // spinner
                // text={"Mesajınız gönderiliyor"}
                styles={{wrapper: {width: '100%', height: '100vh'}}}
            >
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h2>Bize Ulaşın</h2>
                        </div>
                        <div className="card-body">
                            <div className="row text-center">
                                <div className="col-sm-4">
                                    <div className="contact-detail-box">
                                        <i className="fa fa-th fa-3x text-colored"/>
                                        <h4>İletişim Kurun</h4>
                                        <abbr title="Phone">T:</abbr> +90 (850) 360 08 18<br/>
                                        E: <a href="mailto:info@duyuroom.com"
                                              className="text-muted">info@duyuroom.com</a>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="contact-detail-box">
                                        <i className="fa fa-map-marker fa-3x text-colored"/>
                                        <h4>Marmara Üniversitesi Teknoparkı</h4>
                                        <address>
                                            Başıbüyük Mah. Süreyyapaşa Başıbüyük Yolu<br/>
                                            No 4/2 – K11, Maltepe/İstanbul
                                        </address>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="contact-detail-box">
                                        <i className="fa fa-book fa-3x text-colored"/>
                                        <h4>7x24 Destek</h4>
                                        <p>Sektör lideri destek ekibimiz ile görüşün.</p>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="contact-map">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6023.964729150928!2d29.05763!3d40.98186700000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x240db2eec55d694e!2sMarmara%20%C3%9Cniversitesi%20Teknopark%20A%C5%9E!5e0!3m2!1str!2str!4v1613047600634!5m2!1str!2str"
                                            scrolling="no" marginHeight={0} marginWidth={0}
                                            style={{width: '100%', height: 360}} frameBorder={0}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <form role="form" name="ajax-form" id="ajax-form" method="post"
                                          className="form-main">
                                        <div className="form-group">
                                            <label htmlFor="name2">Ad Soyad</label>
                                            <input disabled className="form-control" id="name2"
                                                   name="name" value={this.state.name} type="text"
                                                   onChange={(event) => this.setState({[event.target.name]: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email2">E-Posta</label>
                                            <input disabled className="form-control" id="email2"
                                                   name="email" type="text" value={this.state.email}
                                                   onChange={(event) => this.setState({[event.target.name]: event.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message2">Mesajınız</label>
                                            <textarea className="form-control" id="message2"
                                                      name="message" rows={5}
                                                      value={this.state.message}
                                                      onChange={(event) => this.setState({[event.target.name]: event.target.value})}/>
                                        </div>
                                        <button type="button"
                                                className="btn btn-primary btn-rounded btn-block"
                                                id="send"
                                                onClick={() => this.handleSendClick()}>Gönder
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}

export default HelpContact
