import React, { Component } from 'react';
import { Modal, ModalActions, ModalContent } from "semantic-ui-react";
import { createDocument, getDocument, getDocuments, updateDocument } from "../../services/api";
import Swal from "sweetalert2";
import PubSub from "pubsub-js";
import moment from "moment";
import LoadingOverlay from 'react-loading-overlay';

class UpdateDocumentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,

            allDocuments: [],

            documentName: '',
            documentDescription: '',
            documentDepartment: '',
            documentVersion: null,
            documentCreatedDate: '',

            isLoading: false
        }

        this.documentFile = null
    }

    componentDidMount() {

        this.loadData()

        //this.getAllDocuments()
    }

    loadData = () => {
        getDocument(this.props.id)
            .then(res => {
                let tempNumber = 1
                let user = res.updatedby ? res.updatedby.first_name + " " + res.updatedby.last_name : ""
                this.setState({
                    documentName: res.documentName,
                    documentDescription: res.description,
                    documentDepartment: res.department,
                    documentFileUrl: res.documentFile.url,
                    documentFileName: res.documentFile.name,
                    documentVersion: res.version ? res.version : tempNumber.toFixed(2),
                    documentCreator: user,
                    documentCreatedDate: moment(res.created).format('DD/MM/YYYY'),
                    documentStatus: res.documentStatus
                })
            })
    }

    getAllDocuments = () => {
        getDocuments()
            .then(res => {
                this.setState({
                    allDocuments: res.reverse(),
                })
            })
            .catch(err => {
                console.log('ERR : ', err)
            })
    }

    setOpen = (state) => {
        this.setState({ open: state });
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    handleCreate = () => {
        if (this.state.documentFileName === null || this.state.documentName.length <= 2 || this.state.documentDescription.length <= 2 || this.state.documentDepartment.length <= 1) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen bütün alanları doldurunuz.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }

        this.setState({ isLoading: true })

        // if(this.state.allDocuments.filter(document => document.documentName === this.state.documentName).length !== 0) {
        //     Swal.fire({
        //         icon: 'warning',
        //         title: 'Aynı isimle bir döküman mevcut lütfen onu güncelleyiniz.',
        //         showCancelButton: true,
        //         showConfirmButton: false,
        //         cancelButtonColor: '#3B4182',
        //         cancelButtonText: "Tamam",
        //         focusCancel: false,
        //     })
        //     return
        // }

        let tempVersion = Number(this.state.documentVersion) + 0.01

        let data = new FormData();
        data.append("id", this.props.id)
        data.append("version", tempVersion)
        data.append("documentName", this.state.documentName)
        if (this.documentFile !== null) {
            data.append('documentFile', this.documentFile, this.state.documentFileName);
        }
        data.append('description', this.state.documentDescription);
        data.append('department', this.state.documentDepartment);
        data.append('documentStatus', this.state.documentStatus);

        updateDocument(this.props.id, data)
            .then(res => {
                PubSub.publish('load-documents-2', [])
                Swal.fire({
                    icon: 'success',
                    text: 'Döküman Güncellendi.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
                this.loadData()
                this.setState({ isLoading: false })
                this.setOpen(false)
            })
            .catch(err => {
                console.log('HATA : ', err)
            })
    }

    render() {
        return (

            <Modal
                onClose={() => this.setOpen(false)}
                onOpen={() => this.setOpen(true)}
                open={this.state.open}
                closeOnDimmerClick={!this.state.isLoading}
                closeOnEscape={!this.state.isLoading}
                trigger={this.props.trigger}
                dimmer='inverted'
                size='mini'
            >
                <Modal.Header>Dokümanı Güncelle</Modal.Header>
                <ModalContent><LoadingOverlay
                    active={this.state.isLoading}
                    // spinner
                    // text="Doküman yükleniyor"
                    styles={{ wrapper: { width: '100%', height: '100%' } }}
                ></LoadingOverlay>
                    <div className="row">
                        <div className='col-sm-4'>
                            <label className='mt-2' style={{ marginLeft: 10 }}>Versiyon: </label>
                        </div>
                        <div className='col-sm-8'>
                            <input
                                name="documentVersion"
                                id="documentVersion"
                                className='form-control'
                                readOnly
                                value={this.state.documentVersion}
                                style={{ height: 40, width: 200, marginLeft: 5, cursor: 'default' }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-4'>
                            <label className='mt-2' style={{ marginLeft: 10 }}>Adı: </label>
                        </div>
                        <div className='col-sm-8'>
                            <input
                                name="documentName"
                                id="documentName"
                                className='form-control'
                                value={this.state.documentName}
                                onChange={e => this.handleInput(e)}
                                style={{ height: 40, width: 200, marginLeft: 5, cursor: 'text' }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-4'>
                            <label className='mt-2' style={{ marginLeft: 10 }}>Amacı: </label>
                        </div>
                        <div className='col-sm-8'>
                            {/*<input*/}
                            {/*    type="textarea"*/}
                            {/*    name="documentDescription"*/}
                            {/*    id="documentDescription"*/}
                            {/*    className='form-control mt-2'*/}
                            {/*    value={this.state.documentDescription}*/}
                            {/*    onChange={e => this.handleInput(e)}*/}
                            {/*    style={{ height: 40, width: 200, marginLeft: 10, marginRight: 10, cursor: 'text' }}*/}
                            {/*/>*/}
                            <textarea className="form-select"
                                style={{
                                    cursor: 'text',
                                    whiteSpace: 'pre-line',
                                    borderBottomLeftRadius: 20,
                                    borderTopLeftRadius: 20,
                                    marginLeft: 5,
                                    width: 200,
                                    paddingLeft: 20,
                                    paddingRight: 20
                                }}
                                value={this.state.documentDescription?.replace('\\n', '\n')}
                                onChange={e => {
                                    this.setState({ documentDescription: e.target.value })
                                }}
                                rows={3} maxLength={140} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-4'>
                            <label className='mt-2' style={{ marginLeft: 10 }}>Yayınlanma Tarihi: </label>
                        </div>
                        <div className='col-sm-8'>
                            <input
                                name="documentCreatedDate"
                                id="documentCreatedDate"
                                className='form-control mt-2'
                                value={this.state.documentCreatedDate}
                                readOnly
                                style={{ height: 40, width: 200, marginLeft: 5, cursor: 'text' }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-4'>
                            <label className='mt-2' style={{ marginLeft: 10 }}>Revizyon Sorumlusu: </label>
                        </div>
                        <div className='col-sm-8'>
                            <input
                                name="documentCreator"
                                id="documentCreator"
                                className='form-control mt-2'
                                value={this.state.documentCreator}
                                readOnly
                                style={{ height: 40, width: 200, marginLeft: 5, cursor: 'default' }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-4'>
                            <label className='mt-2' style={{ marginLeft: 10 }}>Departman: </label>
                        </div>
                        <div className='col-sm-8'>
                            <input
                                name="documentDepartment"
                                id="documentDepartment"
                                className='form-control'
                                value={this.state.documentDepartment}
                                onChange={e => this.handleInput(e)}
                                style={{ height: 40, width: 200, marginLeft: 5, cursor: 'text' }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-sm-4'>
                            <label className='mt-2' style={{ marginLeft: 10 }}>Doküman: </label>
                        </div>
                        <div className='col-sm-8'>
                            <button className="btn mt-2 ml-2" style={{
                                marginLeft: -10,
                                marginRight: 10,
                                border: "1px solid #f3f3f3",
                                borderRadius: 20,
                                height: 40,
                                width: 200
                            }} onClick={() => {
                                this.upload.click();
                            }}>
                                <i className="fa fa-upload" /> Dosya Yükle
                            </button>
                            {this.state.documentFileName &&
                                <h4 style={{ marginLeft: '20px' }}><i className="fa fa-file" /> {this.state.documentFileName}</h4>
                            }
                            <input
                                name="documentFile"
                                id="documentFile"
                                type='file'
                                accept=".xlsx, .docx, .pdf .txt"
                                ref={(ref) => this.upload = ref}
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                    this.setState({ documentFileName: event.target.files[0]?.name })
                                    this.documentFile = event.target?.files[0]
                                }}
                            />
                        </div>
                    </div>

                </ModalContent>
                <ModalActions>
                    <button className="btn btn-primary rounded btn-sm px-4 mr-4 "
                        onClick={() => this.setOpen(false)}>Vazgeç
                    </button>
                    <input type="button" className="btn btn-success rounded btn-sm px-4 float-right"
                        style={{ marginRight: 16 }} onClick={this.handleCreate} value="Güncelle" />
                </ModalActions>
            </Modal>
        );
    }
}

export default UpdateDocumentModal;