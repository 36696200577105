import emojiKeywords from 'emojis-keywords';
import emojiList from 'emojis-list';

export const emojis = emojiKeywords.map((keyword, index) => {
    return {
        keyword: emojiKeywords[index],
        character: emojiList[index],
    };
});

export function getEmojiMatches(query) {
    const matches = [];
    emojis.forEach((emoji) => {
        const matchingIndex = emoji.keyword.indexOf(query);
        if (matchingIndex !== -1) {
            matches.push(Object.assign({}, emoji, {
                index: matchingIndex,
            }));
        }
    });

    return matches.sort((a, b) => {
        return a.index - b.index;
    });
}

export function set(array, index, element) {
    return [...array.slice(0, index), element, ...array.slice(index + 1)];
}

export function remove(array, index) {
    return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function insert(array, index, element) {
    return [...array.slice(0, index), element, ...array.slice(index)];
}

export function move(array, fromIndex, toIndex) {
    return insert(remove(array, fromIndex), toIndex, array[fromIndex])
}
