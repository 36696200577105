import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Tooltip} from "@trendmicro/react-tooltip";
import Chart from "react-google-charts";
import {Modal, ModalContent, ModalHeader} from "semantic-ui-react";
import moment from "moment";

class BirthdayItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sentDepartmentsToggle:false
        }

    }

    sentDepartmentsToggle = () => {
        this.setState({sentDepartmentsToggle:!this.state.sentDepartmentsToggle})
    }

    render() {
        const currentDepartments = this.props.groups?.map((item,index)=>{
            return (
                <div className="col-sm-4">
                    <span>&bull;{item}</span>
                </div>
            )
        })
        return (
            <tr className="align-items-center border-bottom p-md-4 p-3" style={{height: 100}}>
                <td style={{width: "25%"}}>
                    <span>{this.props.fullName}</span>
                </td>
                <td style={{width: "25%"}}>
                    <span>{this.props.departments}</span>
                </td>
                <td className="event-list" style={{width: "25%"}}>
                    <span>{this.props.title}</span>
                </td>
                <td className="text-center"><button className="btn btn-xl" style={{color:"red",width: "25%"}}>
                    <i className="fa fa-hourglass-half fs-20"/>
                </button></td>
                <Modal onClose={() => this.sentDepartmentsToggle()}
                       onOpen={() => this.sentDepartmentsToggle()}
                       closeIcon
                       style={{position: "relative", height: "200px", width: "450px"}}
                       size="small"
                       open={this.state.sentDepartmentsToggle}>
                    <ModalHeader style={{height: "50px", width: "450px"}}>Departmanlar</ModalHeader>
                    <ModalContent className="sentDepartmentDiv" style={{height: "150px", width: "450px",overflow:"auto"}}>
                        <div className="row">
                            {currentDepartments}
                        </div>
                    </ModalContent>

                </Modal>




            </tr>

        );
    }
}

export default BirthdayItem;