import { protectedApi, publicApi } from "./axios";

export const filterMessages = (status) => {
    return protectedApi.get('/announcement/announcements?limit=5&isVisible=true&isPublished=' + status + '&ordering=-startDate')
        .then(response => {
            return response.data.results
        })
        .catch((error) => {
            console.log(error);
        });
}
export const filterMessagesByDate = (status, pageSize, endDate) => {
    return protectedApi.get('/announcement/announcements?limit=' + pageSize + '&isVisible=true&isPublished=' + status + '&ordering=-startDate&startDate__lte=' + endDate)
        .then(response => {
            return response.data.results
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getFolders = () => {
    return protectedApi.get('/announcement/announcementFolders')
        .then((response) => {
            return response.data.results.map((r) => { return { ...r, isDir: true, childrenCount: r['count'], modDate: r['created'] } });
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createFolder = (folderName) => {
    let payload = {
        "name": folderName,
        "description": "Yeni oluşturulan klasör"
    };

    return protectedApi.post('/announcement/announcementFolders', payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
};
export const updateFolder = (id, folderName) => {
    let payload = {
        "name": folderName,
        "description": "Adı değiştirilen klasör"
    };

    return protectedApi.put('/announcement/announcementFolder/' + id, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const deleteFolder = (id) => {
    return protectedApi.delete('/announcement/announcementFolder/' + id)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getGroups = () => {
    return protectedApi.get('/groups')
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createGroups = (payload) => {
    return protectedApi.post('/groups', payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createGroupAttach = (payload) => {
    return protectedApi.post('/management/attachusers2group', payload)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}



export const removeGroups = (id) => {
    return protectedApi.delete('/management/group/' + id)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getGroup = (id) => {
    return protectedApi.get('/management/groups?id=' + id)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const updateGroup = (id, payload) => {
    return protectedApi.put('/management/group/' + id, payload)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}


export const getUsers = () => {
    return protectedApi.get('/management/users?is_active=true')
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const createUsers = (payload) => {
    return protectedApi.post('/management/users', payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const removeUsers = (id) => {
    return protectedApi.delete('/management/user/' + id)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const getUser = (id) => {
    return protectedApi.get('/management/user/' + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const getUserByUsername = (username) => {
    return protectedApi.get('/management/users?username=' + username)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const updateUser = (id, payload) => {
    return protectedApi.put('/management/user/' + id, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getTestGroup = () => {
    return protectedApi.get('/announcement/testgroup')
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createTestGroup = (payload) => {
    return protectedApi.post('/announcement/testgroup', payload)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const removeTestGroup = (id) => {
    return protectedApi.delete('/announcement/testgroup/' + id)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getOnlineUsers = () => {
    return protectedApi.get('/management/users?ordering=first_name&is_active=true')
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const verifyToken = (token) => {
    return protectedApi.post('/pass/token/verify/', { "token": token })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const searchUser = (query) => {
    return protectedApi.get('/management/users?is_active=true&search=' + query)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnnouncements = (namespace, type) => {
    return protectedApi.get(`/announcement/announcements?announcementType=${namespace}&type=${type}`)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const getAnnouncementsByOffset = (namespace, type, limit, offset, isPublished) => {
    return protectedApi.get(`/announcement/announcements?limit=${limit}&offset=${offset}&announcementType=${namespace}&type=${type}&isPublished=${isPublished}`)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnnouncementsByFolder = (folderId) => {
    return protectedApi.get(`/announcement/announcements?folder=${folderId}`)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnnouncementsByUser = (userId) => {
    return protectedApi.get(`/announcement/announcements?createdby_id=${userId}`)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAnnouncement = (id) => {
    return protectedApi.get(`/announcement/announcement/` + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const sendMessage = (payload) => {
    return protectedApi.post('/announcement/announcements', payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getReport = (params) => {
    let queryString = "";

    for (let kv of params) {
        if (kv["value"] !== "" && kv["value"] !== null && kv["value"] !== undefined) {
            queryString = queryString + "&" + kv["field"] + "=" + kv["value"];
        }
    }

    return protectedApi.get(`/announcement/generalReport?${queryString}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getUsersParam = (params) => {
    let queryString = "";

    for (let kv of params) {
        if (kv["value"] !== "" && kv["value"] !== null && kv["value"] !== undefined) {
            queryString = queryString + "&" + kv["field"] + "=" + kv["value"];
        }
    }

    return protectedApi.get(`/management/users?is_active=true${queryString}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getMessageReport = (id) => {
    return protectedApi.get(`/announcement/singleReport/?announcement=${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getProfile = (id) => {
    // this will get profile by user_id
    return protectedApi.get(`management/profile/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getProfiles = () => {
    return protectedApi.get("management/profile?is_active=true")
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getProfilesByBirthDate = (date) => {
    return protectedApi.get("management/profile?is_active=true&search=" + date)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const updateProfile = (id, payload) => {
    // this will update profile by profile user_id

    return protectedApi.put('/management/profile/' + id, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const sendPing = () => {
    return protectedApi.get('/management/heartbeat/')
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getAllTemplates = () => {
    return protectedApi.get('/announcement/templates')
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getTemplates = (type, isBirthDay = false) => {
    return protectedApi.get(`/announcement/templates?isBirthDayTemplate=${isBirthDay}&type=${type}`)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getTemplate = (templateId) => {
    return protectedApi.get('/announcement/template/' + templateId)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createTemplate = (model) => {
    return protectedApi.post('/announcement/templates', model)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const updateTemplate = (model) => {
    return protectedApi.put('/announcement/template/' + model.id, model)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const uploadFiles = (data, config) => {
    return protectedApi.post('/announcement/fileuploads', data, config);
}

export const getCompany = (id) => {
    return protectedApi.get('/announcement/company/' + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getBraces = () => {
    return protectedApi.get('/announcement/braces')
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getCalenderDates = () => {
    return protectedApi.get('/management/calenderdates')
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createCalenderDates = (payload) => {
    return protectedApi.post('/management/calenderdates', payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const removeCalenderDate = (id) => {
    return protectedApi.delete('/management/calenderdate/' + id)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const sendEmailHelp = (payload) => {
    return protectedApi.post('/management/sendemail', payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getDocument = (id) => {
    return protectedApi.get('/management/document/' + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getDocuments = () => {
    return protectedApi.get('/management/documents?ordering=-updated')
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createDocument = (payload) => {
    return protectedApi.post('/management/documents', payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const updateDocument = (id, payload) => {
    return protectedApi.put('/management/document/' + id, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getWaitingTimes = (id) => {
    return protectedApi.get(`/management/announcementWaitingTime/` + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const createWaitingTimes = (payload) => {
    return protectedApi.post('/management/announcementWaitingTime', payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateWaitingTimes = (payload) => {
    return protectedApi.put('/management/announcementWaitingTime/1', payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const getBirthdaySettings = () => {
    return protectedApi.get(`/announcement/birthdaysetting/1`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const createBirthdaySettings = (payload) => {
    return protectedApi.post('/announcement/birthdaysettings', payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}


export const changePassword = (payload) => {
    return protectedApi.post('/pass/password/change/', payload)
        .then((response) => {
            console.log('RESPONSE : ', response)
            return response;
        })
        .catch((error) => {
            console.log('ERROR SERVIS CATCH: ', error)
            return error;
        });
}

export const sendMailAfterCreateUser = (payload) => {
    return protectedApi.post('/api/password_reset/', payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}


export const createUserPassword = (token, password) => {
    return publicApi.post(`/api/password_reset/confirm/`, {
        "token": token,
        "password": password
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const getLoginLogoutDetail = (userId) => {
    return protectedApi.get(`/management/loginlogoutdetail?limit=2&user__id=${userId}`)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getProfileByPhone = (phone) => {
    // this will get profile by user_id
    return protectedApi.get(`/management/profile?phone=${phone}`)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const singleUserReport = (startDate, endDate, ids = '') => {
    return protectedApi.get(`/management/generaluserreport?deliveryUsers=${ids}&startDate=${startDate}&endDate=${endDate}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const singleGroupReport = (startDate, endDate, ids = '') => {
    return protectedApi.get(`/management/generalgroupreport?groups=${ids}&startDate=${startDate}&endDate=${endDate}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
