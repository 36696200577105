import React, { Component } from 'react';
import moment from "moment";
import { Tooltip } from '@trendmicro/react-tooltip';
import { setPageTitle } from "../../helpers/LayoutHelper";
import PubSub from "pubsub-js";
import { Button } from "semantic-ui-react";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewWindow from "../../components/common/PreviewWindow";
import parse from "html-react-parser";
import { ButtonBack, ButtonNext, CarouselProvider, Slider } from "pure-react-carousel";
import PreviewTicker from "../../components/common/PreviewTicker";
import CanvasManager from "../../tools/CanvasManager";
import Swal from "sweetalert2";
import Question from "../../components/quiz/Question";
import { PreviewCss } from "../../helpers/PreviewCssHelper";
import { Editor } from "@tinymce/tinymce-react";
import settings from "../../config/settings.json";
import { protectedApi } from "../../services/axios";

const CM = new CanvasManager();

class TickerDesignPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: 'content',
            hasChanges: false,
            hasTitleChanged: false,
            id: 0,
            sending: false,
            media: null,
            mediaNamespace: null,
            mediaType: null,
            action: 'add',
            showSettings: false,
            sendDate: { iso: moment(), readable: 'Hemen', sendTime: '' },
            devices: { mobile: true, desktop: true },
            folderOptions: [],
            groupOptions: [],
            emojiPickerShow: false,
            messageTitle: 'Mesaj başlığınızı buraya yazınız.',
            notifyType: null,
            folder: '',
            content: '',
            sender: '', //localStorage.getItem('sender') ? localStorage.getItem('sender') : '',
            showError: false,
            showMessages: '',
            isAllGroups: false,
            groups: [],
            includeUser: [],
            excludeUser: [],
            snoozeTime: '',
            snoozeCount: '',
            bgcolor: localStorage.getItem('bgcolor') ? localStorage.getItem('bgcolor') : '#ffffff',
            position: 'BOTTOM',
            speed: 2,
            repeatCount: 1,
            separator: '',
            forecolor: '#000000',
            texts: [{ 'content': '', 'class': 'ticker-item' }],
            tickermessages: [],
            title: '',
            carouselItems: [],
            carouselActiveSlide: 0,
            analysisEndNote: '',
            image: null,
            video: null,
            isUploading: false,
            windowSize: 'NORMAL',
            lcv: {
                accept_button: 'Evet',
                decline_button: 'Hayır',
                maybe_button: 'Belki',
                location: '',
                start_date: new Date(),
                end_date: new Date(),
                response_date: new Date(),
                optional_response_date: false
            },
            periodInterval: 0,
            periodCount: 0,
            files: [],
            waitingForSelectPreviewImage: false,
            btnEmojiDisabled: true,
            previewToggle: false
        }

        this.editorNotify = null;

    }
    componentDidMount() {
        this.handlePasteEvent()

        if (this.props.location.state && this.props.location.state.type === "TICKER") {
            setPageTitle(this.props.location.state.pageTitle);
            PubSub.publish("CANVAS", { showPanel: false, model: this.props.location.state });
            PubSub.publish("TICKER", true)
            this.loadCanvas()

        } else {
            if (this.props.history) {
                this.props.history.push('/dashboard');
            }
        }


        PubSub.subscribe("TickerSettings", this.setSettings)
        PubSub.subscribe('ask-template-ticker', this.sendCurrentTemplate)
    }

    getPosition = (string, subString, index) => {
        return string.split(subString, index).join(subString).length;
    }

    loadCanvas() {
        CM.ElementWaiting(['canvasTools'], () => {
            this.editorNotify = CM.CreateCanvas('design-notify');

            CM.AppendDefaultHeadLinks();


            this.editorNotify.setComponents('<div class="ui container main-notify-container first-layer">\n' +
                '            <img class="ui wireframe image fluid image-style" src="https://dummyimage.com/320x86/ba84ba/fff.jpg&text=Görsel" />\n' +
                '            <div class="ui basic segment height180">\n' +
                '                <p class="headerText">Bildirim Başlığı</p>\n' +
                '                <p class="infoText">Kısa bildirim içeriğini buraya yazabilirsiniz.</p>\n' +
                '            </div>\n' +
                '            <div class="emptyDiv"></div>\n' +
                '            <div class="ui middle aligned two column centered padded grid footerNotify">\n' +
                '                <div class="ui divider row"></div>\n' +
                '                <div class="row footerDiv">\n' +
                '                    <div class="column middle aligned"><button class="ui button button-morcivert" onclick="handleClick(0)">Ötele</button></div>\n' +
                '                    <div class="column middle aligned"><button class="ui button button-morcivert" onclick="handleClick(1)">Göster</button></div>\n' +
                '                </div>\n' +
                '            </div>\n' +
                '        </div>')
            this.editorNotify.setStyle('.image-style {\n' +
                '                margin: auto;\n' +
                '                max-width: 96%;\n' +
                '            }\n' +
                '            .first-layer {\n' +
                '                background-color: #fff;\n' +
                '                margin: 10px auto auto;\n' +
                '                border: 1px solid rgba(34, 36, 38, 0.15);\n' +
                '                border-radius: 10px;\n' +
                '                width: 300px !important;\n' +
                '                overflow:auto;\n' +
                '            }\n' +
                '            .height180 {\n' +
                '                height: auto;\n' +
                '            }\n' +
                '            .button-morcivert{\n' +
                '                background-color: #3b4182 !important;\n' +
                '                color: white !important;\n' +
                '                font-size: 10px !important;\n' +
                '            }\n' +
                '            .footerNotify{\n' +
                '                position:fixed;\n' +
                '                width: 310px;\n' +
                '                bottom:1px;\n' +
                '                background-color: white;\n' +
                '                z-index: 100;\n' +
                '                margin-top: 1px;\n' +
                '            }\n' +
                '            .emptyDiv{\n' +
                '                height: 58px;\n' +
                '            }\n' +
                '            .footerDiv{\n' +
                '                margin-top: -46px;\n' +
                '                height: 46px;\n' +
                '            }\n' +
                '            .headerText {\n' +
                '                font-size: 16px;\n' +
                '                font-weight: 700;\n' +
                '            }\n' +
                '            .infoText {\n' +
                '                font-size: 12px;\n' +
                '                word-wrap: break-word;\n' +
                '                width:280px;\n' +
                '            }')

            let text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
            let textStart = this.getPosition(text, '>', 1)
            let textEnd = this.getPosition(text, '<', 2)
            let finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
            let finalTextLength = finalText.length

            this.editorNotify.on('change:editing', (em, editing) => {
                setTimeout(() => {
                    if (editing) {
                        const cmp = this.editorNotify.getSelected();
                        const el = cmp && cmp.getEl();
                        if (el && el.innerText === 'Kısa bildirim içeriğini buraya yazabilirsiniz.') //placeholderın içini siliyor
                            el.innerText = '';

                        text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
                        textStart = this.getPosition(text, '>', 1)
                        textEnd = this.getPosition(text, '<', 2)
                        finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
                        finalTextLength = finalText.length

                        if (this.editorNotify?.getWrapper().find('.headerText').length !== 0) {
                            this.editorNotify?.getWrapper().find('.infoLengthText')[0].remove()
                            this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength + "/300"}</p>`)
                        }
                    }
                }, 500)

            });


            this.editorNotify.on('component:update', model => {
                text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML() // seçili classın html bilgisi dönüyor
                textStart = this.getPosition(text, '>', 1)
                textEnd = this.getPosition(text, '<', 2)
                finalText = this.editorNotify?.getWrapper().find('.infoText')[0].getEl().innerText
                finalTextLength = finalText.length

                if (finalTextLength >= 300) {
                    Swal.fire({
                        icon: 'warning',
                        text: '300 karakter ile sınırlıdır.',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,

                    });
                    text = this.editorNotify?.getWrapper().find('.infoText')[0].toHTML()
                    textStart = this.getPosition(text, '>', 1)
                    textEnd = this.getPosition(text, '<', 2)
                    finalText = text.substr(Number(textStart) + 1, 299)

                    if (this.editorNotify?.getWrapper().find('.headerText').length !== 0) {
                        this.editorNotify?.getWrapper().find('.infoText')[0]?.remove()
                        this.editorNotify?.getWrapper().find('.infoText')[0]?.replaceWith(`<p class="infoText">${finalText}</p>`)
                    }
                }

                if (this.editorNotify?.getWrapper().find('.headerText').length !== 0) {
                    this.editorNotify?.getWrapper().find('.infoLengthText')[0].remove()
                    this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength + "/300"}</p>`)
                }
            })

            if (this.editorNotify?.getWrapper().find('.headerText').length !== 0) {
                this.editorNotify?.getWrapper().find('.headerText')[0].remove()
                this.editorNotify?.getWrapper().find('.infoText')[0].remove()
                this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="headerText" data-gjs-removable="false">${this.props.location.state.title}</p>`, { at: 0 })
                this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoText" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-droppable="false">Kısa bildirim içeriğini buraya yazabilirsiniz.</p>`, { at: 1 })
                this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="infoLengthText" data-gjs-editable="false" data-gjs-removable="false" data-gjs-hoverable="false">${finalTextLength + "/300"}</p>`)
            }

        }
        );
        if (this.editorNotify?.getWrapper().find('.headerText').length !== 0) {
            this.editorNotify?.getWrapper().find('.headerText')[0].remove()
            this.editorNotify?.getWrapper().find('.height180')[0].append(`<p class="headerText">${this.props.location.state.title}</p>`, { at: 0 })
        }
        this.setActiveCanvas('content');
    }

    componentWillUnmount() {
        PubSub.publish("TICKER", false)

        PubSub.unsubscribe('ask-template-ticker')
        PubSub.unsubscribe("TickerSettings")
    }

    sendCurrentTemplate = () => {
        if (this.editorNotify?.getWrapper().find('.headerText').length !== 0) {
            this.editorNotify?.getWrapper().find('.infoLengthText')[0]?.remove()
        }
        let error = false

        const template = {
            message: this.state.tickermessages,
            position: this.state.position,
            speed: this.state.speed,
            periodInterval: this.state.periodInterval,
            periodCount: this.state.periodCount,
            repeatCount: this.state.repeatCount,
            bgcolor: this.state.bgcolor,
            notify: "<style>" + this.editorNotify?.getCss() + "</style>" + this.editorNotify?.getHtml(),
        }

        if (this.state.texts.filter(data => this.isEmpty(data.content)).length !== 0) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen altyazı metni giriniz .',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            return
        }
        PubSub.publish('current-template', template)
    }

    isEmpty = (str) => {
        return !str.trim().length
    }

    setSettings = (msg, data) => {
        let state = { ...this.state, ...data }
        this.setState(state, () => this.createTickerTexts())

    }

    getPosition = (string, subString, index) => {
        return string.split(subString, index).join(subString).length;
    }

    addTickerText = () => {
        const lenText = this.state.texts.length - 1

        if (this.state.texts.filter(item => this.isEmpty(item.content)).length > 0) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen altyazı metni giriniz .',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            return
        }

        // if(this.state.texts[lenText]?.content.trim().length <= 1) {
        //     Swal.fire({
        //         icon: 'warning',
        //         title: 'Lütfen altyazı metni giriniz .',
        //         showCancelButton: true,
        //         showConfirmButton: false,
        //         cancelButtonColor: '#3B4182',
        //         cancelButtonText: "Tamam",
        //         focusCancel: false,
        //
        //     })
        //     return
        // }
        this.setState({
            texts: [...this.state.texts, { 'content': '', 'class': 'ticker-item' }]
        }, () => {
            this.createTickerTexts()
            this.handlePasteEvent()
        })
    }

    removeTickerText = (index) => {
        let newArr = [...this.state.texts];
        newArr.splice(index, 1);

        this.setState({
            texts: newArr
        }, () => this.createTickerTexts());
    }

    changeTickerText = (val, index) => {
        let rows = [...this.state.texts];

        rows[index] = {
            ...rows[index],
            'content': val
        };

        this.setState({
            texts: rows
        }, () => this.createTickerTexts());
    }

    createTickerTexts = () => {
        if (this.state.texts.length > 0) {
            let tickermessages = [];

            this.state.texts.map((text) => {
                let message = {
                    'content': `<label style='color:${this.state.forecolor}'>${text.content}</label>`,
                    'class': 'ticker-item'
                };

                tickermessages.push(message);

                if (this.state.separator !== '') {
                    tickermessages.push({ 'content': this.state.separator, 'class': 'ticker-separator' });
                }
            });

            this.setState({ tickermessages: tickermessages });
        }
    }

    changeTickerPosition = (fromIndex, toIndex) => {
        let tempArr = this.state.texts
        let element = tempArr[fromIndex];
        tempArr.splice(fromIndex, 1);
        tempArr.splice(toIndex, 0, element);
        this.setState({ texts: tempArr }, () => this.createTickerTexts())
    }

    handlePasteEvent = () => {
        for (let i = 0; i < 100; i++) {
            let temp = document.getElementById(`editorDiv` + i)
            temp?.addEventListener('paste', (e) => {
                e.preventDefault();

                // get text representation of clipboard
                var text = (e.originalEvent || e).clipboardData.getData('text/plain');


                // insert text manually
                document.execCommand("insertHTML", false, text);
            })
        }
    }

    getEditor(val, placeholder, index) {
        return (
            <div className="form-control" style={{ width: "80%" }}>
                <Editor
                    id={`editorDiv${index}`}
                    apiKey="4wp2oams7a8jp489lzu8ywwx16edfuxlxrhjchhl7vsbafut"
                    value={val}
                    init={{
                        selector: 'textarea#myTextArea',
                        height: 120,
                        menubar: false,
                        inline: true,
                        toolbar_mode: "sliding",
                        language: "tr",
                        language_url: '/js/tr.js',
                        placeholder: placeholder,
                        automatic_uploads: true,
                        image_uploadtab: true,
                        image_caption: false,
                        image_advtab: false,
                        image_description: false,
                        image_dimensions: true,
                        content_css: "/css/editor.css",
                        content_style: '.mce-content-body {overflow-y:auto !important}',
                        file_browser_callback_types: 'image media',
                        contextmenu: false,
                        images_upload_url: settings.apiBase + 'announcement/fileuploads',
                        images_upload_base_path: settings.apiBase,
                        images_upload_credentials: false,
                        browser_spellcheck: true,
                        force_br_newlines: false,
                        force_p_newlines: false,
                        forced_root_block: '',
                        images_upload_handler: function (blobInfo, success, failure, progress) {
                            let data = new FormData();
                            let config = { header: { 'Content-Type': 'multipart/form-data' } }

                            data.append('file', blobInfo.blob(), blobInfo.filename());

                            protectedApi.post('/announcement/fileuploads', data, config).then(response => {
                                success(settings.apiBaseClear + response.data.file);
                            }).catch(error => {
                                failure(error);
                            })
                        },
                        plugins: [
                            'emoticons link'
                        ],
                        toolbar:
                            'emoticons link'
                    }}
                    onEditorChange={(content, editor) => {
                        this.changeTickerText(content, index)

                        // hack the editor to enable spellcheck
                        editor.bodyElement.spellcheck = true;
                    }}

                />
            </div>
        )
    }

    getTickerTexts = () => {
        let list = []

        this.state.texts.map((text, index) => {
            list.push(
                <div key={`textRow${index}`} className="input-group mb-3">
                    {this.getEditor(text.content, "Mesajınızı başlıklar halinde yazınız.", index)}
                    {/*<input type="text" className="form-control" placeholder="Mesajınızı başlıklar halinde yazınız." value={text.content} onChange={e => this.changeTickerText(e.target.value, index)}/>*/}
                    <div className="input-group-append">
                        <Tooltip placement="top" hideOnClick={true} content="Yukarı Taşı">
                            <button className="btn btn-primary" type="button" onClick={() => this.changeTickerPosition(index, index - 1)}><i className="la la-angle-up" /></button>
                        </Tooltip>
                        <Tooltip placement="top" hideOnClick={true} content="Aşağı Taşı">
                            <button className="btn btn-primary" type="button" onClick={() => this.changeTickerPosition(index, index + 1)}><i className="la la-angle-down" /></button>
                        </Tooltip>
                        {/*<Tooltip placement="top" hideOnClick={true} content="Yeni bir mesaj satırı ekle">*/}
                        {/*    <button className="btn btn-primary" type="button" disabled={index < this.state.texts.length - 1} onClick={() => this.addTickerText()}><i className="la la-plus"/></button>*/}
                        {/*</Tooltip>*/}

                        <Tooltip placement="top" hideOnClick={true} content="Mesajı sil">
                            <button className="btn btn-danger" type="button" onClick={() => this.removeTickerText(index)}><i className="la la-trash" /></button>
                        </Tooltip>
                    </div>
                </div>
            )
        });

        return list;
    }

    setActiveCanvas = (mode) => {
        if (this.state.mode !== mode) {
            this.setState({ mode }, () => {
                if (mode === 'preview') {
                    if (document.getElementsByClassName("infoLengthText")[0] && document.getElementsByClassName("infoLengthText")[0].style) {
                        if (document.getElementsByClassName("infoLengthText")[0] && document.getElementsByClassName("infoLengthText")[0].style) {
                            document.getElementsByClassName("infoLengthText")[0].style.visibility = 'hidden'
                        }
                    }
                }
            });
        }

        if (this.editorNotify && this.editorNotify.hideWrappers)
            this.editorNotify.hideWrappers();


        switch (mode) {
            case 'design-notify':
                if (this.editorNotify)
                    this.editorNotify.showWrappers();
                break;
            case 'preview':

            case 'content':

            default:
                break;
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="design-change">
                    <Button.Group>
                        <Button toggle onClick={() => this.setActiveCanvas('content')}>İçerik</Button>
                        ,<Button toggle onClick={() => this.setActiveCanvas('design-notify')}>Bildirim</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('preview')}>Önizleme</Button>
                    </Button.Group>
                </div>
                <div id="design-notify" className="canvas-content">

                </div>
                <div className="row pr-3" style={{ display: this.state.mode === "content" ? "block" : "none" }}>
                    {this.state.texts.length === 0 &&
                        <button className="btn btn-xl btn-block" style={{ fontSize: '18px' }} onClick={this.addTickerText}>
                            Bir altyazı ekle
                        </button>
                    }
                    {this.getTickerTexts()}
                    <div className="text-center">
                        <button className="btn btn-primary mt-5" style={{ display: "inline-block", margin: "auto", width: "150px" }}
                            onClick={() => {
                                this.addTickerText()
                                let div = document.getElementById("ticker_builder_bottom_div")
                                div.scrollIntoView({ behavior: "smooth" })
                            }}>Altyazı Ekle</button>
                    </div>
                    <div id="ticker_builder_bottom_div" style={{ float: "left", clear: "both", height: "200px" }}>
                    </div>
                </div>
                <div className="tab-pane" id="preview_desktop" style={{ padding: 30, height: 500, display: this.state.mode === "preview" ? "block" : "none" }}>
                    <PreviewDevice
                        deviceType="DESKTOP"
                        device="macbookpro"
                        scale="0.65"
                        wallpaper="url(/images/macos-wallpaper.png)"
                        notification={{
                            show: this.state.notifyType === 'POPUP',
                            title: this.state.messageTitle,
                            subtitle: this.state.title,
                            body: this.state.sender,
                            snooze: this.state.snoozeTime > 0,
                            top: 0
                        }}
                    >
                        <PreviewTicker
                            bgcolor={this.state.bgcolor}
                            mediaType={this.state.mediaType}
                            position={this.state.position.toLowerCase()}
                            speed={this.state.speed}
                            tickermessages={this.state.tickermessages.map((msg) => {
                                return msg.content
                            })}
                        />

                    </PreviewDevice>
                </div>

            </div>
        );
    }
}

export default TickerDesignPage;