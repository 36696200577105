import React from 'react';

class PreviewApp extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div style={{padding:20, paddingTop: this.props.top, width: '100%', height: '100%', backgroundColor: '#ffffff' }}>
                <img
                    src={`/images/icons/${this.props.mediaType}.svg`}
                    width={50} height={50}
                    style={{display:'block', float:'left', marginRight: 5}}/>
                <h4 style={{display:'block', float:'left', marginTop: 5}}>
                    {this.props.title}<br/><small>{this.props.subtitle}</small>
                </h4>
                <p className="clearfix"/>
                <div style={{backgroundColor: `${this.props.bgcolor}`}}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default PreviewApp;
