"use strict";

const tUImageEditor = require("grapesjs-tui-image-editor");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  blockManager: {
    labels: {
      "1 Column": "Kolon"
      // 'block-id': 'Block Label',
    },
    categories: {
      // 'category-id': 'Category Label',
    }
  },
  domComponents: {
    names: {
      '': 'Kutu',
      wrapper: 'Gövde',
      text: 'Metin',
      comment: 'Yorum',
      image: 'Görsel',
      video: 'Video',
      label: 'Etiket',
      link: 'Link',
      map: 'Harita',
      tfoot: 'Tablo alt',
      tbody: 'Tablo gövde',
      thead: 'Tablo başlık',
      table: 'Tablo',
      row: 'Satır',
      cell: 'Hücre'
    }
  },
  assetManager: {
    addButton: 'Ekle',
    modalTitle: 'Görsel Seçin',
    uploadTitle: 'Görsel eklemek için tıklayın veya buraya sürükleyin',
    inputPlh: 'Url girin'
  },
  deviceManager: {
    device: 'Cihaz',
    devices: {
      desktop: 'Masaüstü',
      tablet: 'Tablet',
      mobileLandscape: 'Mobil Yatay',
      mobilePortrait: 'Mobil Dikey'
    }
  },
  panels: {
    buttons: {
      titles: {
        preview: 'Önizleme',
        fullscreen: 'Tam Ekran',
        'sw-visibility': 'Bileşenleri Göster',
        'export-template': 'Kodu Göster',
        'open-sm': 'Stil Düzenleyiciyi Aç',
        'open-tm': 'Ayarlar',
        'open-layers': 'Katmanlar',
        'open-blocks': 'Bloklar'
      }
    }
  },
  selectorManager: {
    selected: 'Seçili',
    emptyState: '- DURUM -',
    label: 'Sınıflar'
  },
  styleManager: {
    empty: 'Stilini düzenlemek istediğiniz öğeyi seçiniz',
    layer: 'Katman',
    fileButton: 'Görsel Ekle',
    sectors: {
      general: 'Genel',
      layout: 'Düzen',
      typography: 'Metin',
      decorations: 'Tasarım',
      extra: 'Ekstra',
      flex: 'Flex',
      dimension: 'Boyut'
    },
    properties: {
      float: 'Kaydır',
      display: 'Görünüm',
      position: 'Pozisyon',
      top: 'Üst',
      right: 'Sağ',
      left: 'Sol',
      bottom: 'Alt',
      width: 'Genişlik',
      height: 'Yükseklik',
      'max-width': 'Maks. Genişlik',
      'max-height': 'Maks. Yükseklik',
      'min-height': 'Min. Yükseklik',
      margin: 'Margin',
      'margin-top': 'Margin Üst',
      'margin-right': 'Margin Sağ',
      'margin-left': 'Margin Sol',
      'margin-bottom': 'Margin Alt',
      padding: 'Padding',
      'padding-top': 'Padding Üst',
      'padding-left': 'Padding Sol',
      'padding-right': 'Padding Sağ',
      'padding-bottom': 'Padding Alt',
      'font-family': 'Yazı Tipi',
      'font-size': 'Boyut',
      'font-weight': 'Kalınlık',
      'letter-spacing': 'Harf Boşluğu',
      color: 'Yazı Rengi',
      'line-height': 'Satır Boşluğu',
      'text-align': 'Hiza',
      'text-shadow': 'Yazı Gölgesi',
      'text-shadow-h': 'Yatay',
      'text-shadow-v': 'Dikey',
      'text-shadow-blur': 'Bulanıklık',
      'text-shadow-color': 'Renk',
      'border-top-left': 'Kenar Üst Sol',
      'border-top-right': 'Kenar Üst Sağ',
      'border-bottom-left': 'Kenar Alt Sol',
      'border-bottom-right': 'Kenar Alt Sağ',
      'border-radius-top-left': 'Köşe Yumuşuması Üst Sol',
      'border-radius-top-right': 'Köşe Yumuşuması Üst Sağ',
      'border-radius-bottom-left': 'Köşe Yumuşuması Alt Sol',
      'border-radius-bottom-right': 'Köşe Yumuşuması Alt Sağ',
      'border-top-left-radius': 'Köşe Yumuşuması Üst Sol',
      'border-top-right-radius': 'Köşe Yumuşuması Üst Sağ',
      'border-bottom-left-radius': 'Köşe Yumuşuması Alt Sol',
      'border-bottom-right-radius': 'Köşe Yumuşuması Alt Sağ',
      'border-radius': 'Köşe Yumuşaması',
      border: 'Kenar',
      'border-width': 'Kenar Kalınlığı',
      'border-style': 'Kenar Stili',
      'border-color': 'Kenar Rengi',
      'box-shadow': 'Kutu Gölgesi',
      'box-shadow-h': 'Kutu Gölgesi - Yatay',
      'box-shadow-v': 'Kutu Gölgesi - Dikey',
      'box-shadow-blur': 'Kutu Gölgesi Bulanıklığı',
      'box-shadow-spread': 'Kutu Gölgesi Dağılımı',
      'box-shadow-color': 'Kutu Gölgesi Rengi',
      'box-shadow-type': 'Kutu Gölgesi Tipi',
      background: 'Arkaplan Görseli',
      'background-image': 'Arkaplan Resmi',
      'background-repeat': 'Arkaplan Tekrarı',
      'background-position': 'Arkaplan Pozisyonu',
      'background-attachment': 'Arkaplan Eklentisi',
      'background-size': 'Arkaplan Boyutu',
      transition: 'Geçiş',
      'transition-property': 'Geçiş Özelliği',
      'transition-duration': 'Geçiş Süresi',
      'transition-timing-function': 'Geçiş Zamanlaması Metodu',
      perspective: 'Perspektif',
      transform: 'Boyutlama',
      'transform-rotate-x': 'Yatay Yönlendirme',
      'transform-rotate-y': 'Dikey Yönlendirme',
      'transform-rotate-z': 'Hacimsel Yönlendirme',
      'transform-scale-x': 'Dikey Oran',
      'transform-scale-y': 'Yatay Oran',
      'transform-scale-z': 'Hacimsel Oran',
      'flex-direction': 'Flex Yönü',
      'flex-wrap': 'Flex Kesme',
      'justify-content': 'İçeriği Sığdır',
      'align-items': 'Öğeleri Hizala',
      'align-content': 'İçeriği Hizala',
      order: 'Sıra',
      'flex-basis': 'Flex Bazı',
      'flex-grow': 'Flex Büyüme',
      'flex-shrink': 'Flex Küçülme',
      'align-self': 'Kendini Hizala',
      'background-color': 'Arkaplan Rengi',
    },
  },
  traitManager: {
    empty: 'Özelliklerini düzenlemek istediğiniz öğeyi seçiniz',
    label: 'Bileşen Özellikleri',
    traits: {
      labels: {
        title: 'Başlık',
        target: 'Yönlendirme',
        href: 'Link',
        alt: 'Alt Başlık'
      },
      attributes: {},
      options: {}
    }
  },
};
exports.default = _default;
