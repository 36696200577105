import React, {Component} from 'react';
import moment from "moment";
import { Tooltip } from '@trendmicro/react-tooltip';
import {setPageTitle} from "../../helpers/LayoutHelper";
import PubSub from "pubsub-js";
import {Button} from "semantic-ui-react";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewWindow from "../../components/common/PreviewWindow";
import parse from "html-react-parser";
import {ButtonBack, ButtonNext, CarouselProvider, Slider} from "pure-react-carousel";
import PreviewTicker from "../../components/common/PreviewTicker";
import * as API from "../../services/api";
import {PreviewCss} from "../../helpers/PreviewCssHelper";
import {Editor} from "@tinymce/tinymce-react";
import settings from "../../config/settings.json";
import {protectedApi} from "../../services/axios";

class PublishedAgainTicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasChanges: false,
            hasTitleChanged: false,
            id: 0,
            sending: false,
            media: null,
            mediaNamespace: null,
            mediaType: null,
            action: 'add',
            showSettings: false,
            sendDate: {iso: moment(), readable: 'Hemen', sendTime: ''},
            devices: {mobile: true, desktop: true},
            folderOptions: [],
            groupOptions: [],
            emojiPickerShow: false,
            messageTitle: 'Mesaj başlığınızı buraya yazınız.',
            notifyType: null,
            folder: '',
            content: '',
            sender: '', //localStorage.getItem('sender') ? localStorage.getItem('sender') : '',
            showError: false,
            showMessages: '',
            isAllGroups: false,
            groups:[],
            includeUser: [],
            excludeUser: [],
            snoozeTime: '',
            snoozeCount: '',
            bgcolor: localStorage.getItem('bgcolor') ? localStorage.getItem('bgcolor') : '#ffffff',
            position: 'BOTTOM',
            speed: 2,
            repeatCount: 1,
            separator: '',
            forecolor: '#000000',
            texts: [{'content': '', 'class': 'ticker-item'}],
            tickermessages: [],
            title: '',
            carouselItems : [],
            carouselActiveSlide: 0,
            analysisEndNote: '',
            image: null,
            video: null,
            isUploading: false,
            windowSize: 'NORMAL',
            lcv: {
                accept_button: 'Evet',
                decline_button: 'Hayır',
                maybe_button: 'Belki',
                location: '',
                start_date: new Date(),
                end_date: new Date(),
                response_date: new Date(),
                optional_response_date: false
            },
            periodInterval: 0,
            periodCount: 0,
            files: [],
            waitingForSelectPreviewImage: false,
            btnEmojiDisabled: true,
            previewToggle:false
        }

    }
    componentDidMount() {
        this.loadTemplate();
        PubSub.publish("CANVAS", { showPanel: false});
        PubSub.publish("TICKER", true)

        PubSub.subscribe("TickerSettings",this.setSettings)
        PubSub.subscribe('ask-template-ticker',this.sendCurrentTemplate)
    }

    componentWillUnmount() {
        PubSub.publish("TICKER", false)
        PubSub.unsubscribe("TickerSettings")
        PubSub.unsubscribe('ask-template-ticker')
    }

    sendCurrentTemplate = () => {
        const template = {
            message:this.state.tickermessages,
            position:this.state.position,
            speed:this.state.speed,
            periodInterval: this.state.periodInterval,
            periodCount: this.state.periodCount,
            repeatCount: this.state.repeatCount,
            bgcolor: this.state.bgcolor,
            folder:this.state.folder?.id?this.state.folder.id:this.state.folder,
            title:this.state.title,
        }
        PubSub.publish('current-template',template)
    }

    getPosition = (string, subString, index) => {
        return string.split(subString, index).join(subString).length;
    }

    loadTemplate = () => {
        API.getAnnouncement(this.props.location?.state?.id?this.props.location.state.id:window.sendAgainId).then((response) => {
            if (response) {
                PubSub.publish('sendAgainPage', response)
                let texts = []
                for (let i=0;i<response.tickermessages.length;i++){
                    let index = null
                    let msg = null
                    index = this.getPosition(response.tickermessages[i].content, '<', 2)
                    msg =  response.tickermessages[i].content.substring(29,Number(index))
                    texts.push({content:msg})
                    // console.log(msg)
                    i++
                }
                // console.log(texts)
                setPageTitle(response.title);
                this.setState({
                    texts,
                    bgcolor:response.bgcolor,
                    position:response.position,
                    speed:response.speed,
                    tickermessages:response.tickermessages,
                    previewToggle:true,
                    folder:response.folder,
                    title:response.title,
                },()=>this.getTickerTexts())
            }
        })
    }



    setSettings = (msg,data) => {
        let state = {...this.state,...data}
        this.setState(state,() => this.createTickerTexts())

    }

    addTickerText = () => {
        this.setState({
            texts: [...this.state.texts, {'content': '', 'class': 'ticker-item'}]
        }, () => this.createTickerTexts())
    }

    removeTickerText = (index) => {
        let newArr = [...this.state.texts];
        newArr.splice(index, 1);

        this.setState({
            texts: newArr
        }, () => this.createTickerTexts());
    }

    changeTickerText = (val, index) => {
        let rows = [...this.state.texts];

        rows[index] = {
            ...rows[index],
            'content': val
        };

        this.setState({
            texts: rows
        }, () => this.createTickerTexts());
    }

    createTickerTexts = () => {
        if(this.state.texts.length > 0) {
            let tickermessages = [];

            this.state.texts.map((text) => {
                let message = {
                    'content': `<label style='color:${this.state.forecolor}'>${text.content}</label>`,
                    'class': 'ticker-item'
                };

                tickermessages.push(message);

                if (this.state.separator !== '') {
                    tickermessages.push({'content': this.state.separator, 'class': 'ticker-separator'});
                }
            });

            this.setState({tickermessages: tickermessages});
        }
    }

    getEditor (val, placeholder,index) {

        return(
            <div className="form-control" style={{width: "80%"}}>
                <Editor
                    apiKey="4wp2oams7a8jp489lzu8ywwx16edfuxlxrhjchhl7vsbafut"
                    value={val}
                    init={{
                        selector: 'textarea#myTextArea',
                        height: 120,
                        menubar: false,
                        inline: true,
                        toolbar_mode: "sliding",
                        language: "tr",
                        language_url: '/js/tr.js',
                        placeholder: placeholder,
                        automatic_uploads: true,
                        image_uploadtab: true,
                        image_caption: false,
                        image_advtab: false,
                        image_description: false,
                        image_dimensions: true,
                        content_css : "/css/editor.css",
                        content_style : '.mce-content-body {overflow-y:auto !important}',
                        file_browser_callback_types: 'image media',
                        contextmenu: false,
                        images_upload_url: settings.apiBase + 'announcement/fileuploads',
                        images_upload_base_path: settings.apiBase,
                        images_upload_credentials: false,
                        browser_spellcheck: true,
                        force_br_newlines: false,
                        force_p_newlines: false,
                        forced_root_block: '',
                        images_upload_handler: function (blobInfo, success, failure, progress) {
                            let data = new FormData();
                            let config = {header: {'Content-Type': 'multipart/form-data'}}

                            data.append('file', blobInfo.blob(), blobInfo.filename());

                            protectedApi.post('/announcement/fileuploads', data, config).then(response => {
                                success(settings.apiBaseClear + response.data.file);
                            }).catch(error => {
                                failure(error);
                            })
                        },
                        plugins: [
                            'emoticons link'
                        ],
                        toolbar:
                            'emoticons link'
                    }}
                    onEditorChange={(content, editor) => {
                        this.changeTickerText(content, index)

                        // hack the editor to enable spellcheck
                        editor.bodyElement.spellcheck = true;
                    }}

                />
            </div>
        )
    }

    changeTickerPosition = (fromIndex, toIndex) => {
        let tempArr = this.state.texts
        let element = tempArr[fromIndex];
        tempArr.splice(fromIndex, 1);
        tempArr.splice(toIndex, 0, element);
        this.setState({texts:tempArr},() => this.createTickerTexts())
    }

    getTickerTexts = () => {
        let list = []

        this.state.texts.map((text,index) => {
            list.push(
                <div key={`textRow${index}`} className="input-group mb-3">
                    {this.getEditor(text.content,"Mesajınızı başlıklar halinde yazınız.",index)}
                    {/*<input type="text" className="form-control" placeholder="Mesajınızı başlıklar halinde yazınız." value={text.content} onChange={e => this.changeTickerText(e.target.value, index)}/>*/}
                    <div className="input-group-append">
                        <Tooltip placement="top" hideOnClick={true} content="Yukarı Taşı">
                            <button className="btn btn-primary" type="button"  onClick={() => this.changeTickerPosition(index,index-1)}><i className="la la-angle-up"/></button>
                        </Tooltip>
                        <Tooltip placement="top" hideOnClick={true} content="Aşağı Taşı">
                            <button className="btn btn-primary" type="button"  onClick={() => this.changeTickerPosition(index,index+1)}><i className="la la-angle-down"/></button>
                        </Tooltip>
                        {/*<Tooltip placement="top" hideOnClick={true} content="Yeni bir mesaj satırı ekle">*/}
                        {/*    <button className="btn btn-primary" type="button" disabled={index < this.state.texts.length - 1} onClick={() => this.addTickerText()}><i className="la la-plus"/></button>*/}
                        {/*</Tooltip>*/}

                        <Tooltip placement="top" hideOnClick={true} content="Mesajı sil">
                            <button className="btn btn-danger" type="button" onClick={() => this.removeTickerText(index)}><i className="la la-trash"/></button>
                        </Tooltip>
                    </div>
                </div>
            )
        });

        return list;
    }

    backButton = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="col-12" style={{height:"35px"}}>
                    <button className="btn btn-primary btn-xs" onClick={() => {this.backButton()}}><i className="la la-arrow-left"/></button>
                    <span className="ml-1">Geri</span>
                </div>
                <div className="design-change">
                    <Button.Group>
                        <Button toggle onClick={()=> this.setState({previewToggle:false})}>İçerik</Button>
                        <Button toggle onClick={()=> this.setState({previewToggle:true})}>Önizleme</Button>
                    </Button.Group>
                </div>
                {!this.state.previewToggle?
                    <div className="row pr-3">
                        {this.getTickerTexts()}
                    </div>
                    :
                    <div className="tab-pane" id="preview_desktop" style={{ marginLeft: 'auto', marginRight: 'auto', width: 900, paddingLeft: 30, height: 500}}>
                        <PreviewDevice
                            deviceType="DESKTOP"
                            device="macbookpro"
                            scale="0.8"
                            wallpaper="url(/images/macos-wallpaper.png)"
                            notification={{
                                show: false,
                                title: this.state.messageTitle,
                                subtitle: this.state.title,
                                body: this.state.sender,
                                snooze: this.state.snoozeTime > 0,
                                top: 0
                            }}
                        >
                            <PreviewTicker
                                bgcolor={this.state.bgcolor}
                                mediaType="TICKER"
                                position={this.state.position.toLowerCase()}
                                speed={this.state.speed}
                                tickermessages={this.state.tickermessages.map((msg) => {return msg.content})}
                            />

                        </PreviewDevice>
                    </div>
                }
            </div>
        );
    }
}

export default PublishedAgainTicker;