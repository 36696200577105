const InitialMediaModel = {
    announcementType: '',
    type: '',
    title: '', 
    description: null,
    folder: '',
    template: '',
    sender: '',
    startDate: null,
    endDate: null,
    isAllGroups: false,
    groups: [],
    includeUser: [],
    excludeUser: [],
    files: [],
    times: {
        date: null,
        time: null
    },
    devices: {
        mobile: true,
        desktop: true
    },
    windowSize: 'NORMAL',
    notificationType: 'POPUP',
    snoozeEnabled: false,
    snoozeTime: '',
    snoozeCount: '',
    image: null,
    video: null,
    summary: '',
    bgcolor: '',
    analysisEndNote: '',
    deliveryOption: 'ALL',
    periodCount: 0,
    periodInterval: 0,
    periodReaming: 0,
    nextPeriodTime: null,
    position: 'BOTTOM',
    speed: 2,
    repeatCount: 1,
    recurrenceDate: null,
    isPublished: ''
}

export default InitialMediaModel;