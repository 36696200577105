export default class Question {
    static TYPES = Object.freeze({
        YESNO: "Doğru/Yanlış Sorusu",
        SINGLE: "Tek Seçimli Soru",
        MULTIPLE: "Çok Seçimli Soru"
    });

    static DEFAULTS = Object.freeze({
        text: "",
        type: null,
        options: [],
        cols: [],
        rows: [],
        answers:[],
        hasOther:false
    });

    constructor(params = {}) {
        const { text, type, options, id, cols, rows,answers,hasOther } = { ...Question.DEFAULTS, ...params };
        this.hasOther=hasOther
        this.answers = answers
        this.text = text;
        this.type = type;
        this.options = options;
        this.cols = cols;
        this.rows = rows;
        this.id = id || Math.random();
    }

    get hasOptions() {
        return (
            this.type === Question.TYPES.YESNO ||
            this.type === Question.TYPES.SINGLE ||
            this.type === Question.TYPES.MULTIPLE
        );
    }

    get inputType() {
        if (this.type === Question.TYPES.YESNO) return "radio";
        if (this.type === Question.TYPES.SINGLE) return "radio";
        if (this.type === Question.TYPES.MULTIPLE) return "checkbox";
        throw new Error("This question does not have an input type.");
    }

    merge(patch) {
        return new Question({ ...this, ...patch });
    }
}
