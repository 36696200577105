import React from 'react';
import PubSub from "pubsub-js";
import { Button } from "semantic-ui-react";
import PreviewWindow from "../../components/common/PreviewWindow";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewFrame from "../../components/common/PreviewFrame";
import CanvasManager from "../../tools/CanvasManager";
import * as API from '../../services/api';
import { setPageTitle } from "../../helpers/LayoutHelper";
import {PreviewCss} from "../../helpers/PreviewCssHelper";

const CM = new CanvasManager();

class WallpaperDesignPage extends React.Component {
    mounted = false;

    state = {
        mode: 'design-content',
        content: {},
        notify: {},
        model: null
    };

    constructor(props) {
        super(props);

        this.editorContent = null;
        this.editorNotify = null;
    }

    componentDidMount = () => {
        if (this.mounted) return;
        this.mounted = true;

        if (this.props.location.state && this.props.location.state.type == "WALLPAPER") {
            setPageTitle(this.props.location.state.pageTitle);
            PubSub.publish("CANVAS", { showPanel: true, model: this.props.location.state });
            this.loadTemplate();
        } else {
            if (this.props.history) {
                this.props.history.push('/dashboard');
            }
        }

        PubSub.subscribe('canvas_lock.save', this.onSave.bind(this));
        PubSub.subscribe('canvas_lock.update', this.onUpdate.bind(this));
        PubSub.subscribe('ask-template-wallpaper',this.sendCurrentTemplate)
    }

    sendCurrentTemplate = () => {
        const templates = {
            html:this.editorContent?.getHtml(),
            css:this.editorContent?.getCss(),
            notify: "<style>"+this.editorNotify?.getCss()+"</style>"+this.editorNotify?.getHtml()
        }
        //const template = "<style>"+this.editorContent?.getCss()+"</style>"+this.editorContent?.getHtml()
        PubSub.publish('current-template',templates)
    }

    loadTemplate() {
        API.getTemplate(this.props.location.state.template).then((response) => {
            if (response) {
                CM.ElementWaiting(['canvasTools'], () => {
                    this.editorContent = CM.CreateCanvas('design-content');
                    this.editorNotify = CM.CreateCanvas('design-notify');
                    this.setActiveCanvas('design-content');
                    CM.AppendDefaultHeadLinks();

                    if (response.templateJson.content) {
                        if (response.templateJson.content.html)
                            this.editorContent.setComponents(CM.GetLiquidTemplateContent(response.templateJson.content.html));

                        if (response.templateJson.content.css)
                            this.editorContent.setStyle(response.templateJson.content.css);
                    }

                    if (response.templateJson.notify) {
                        if (response.templateJson.notify.html)
                            this.editorNotify.setComponents(CM.GetLiquidTemplateContent(response.templateJson.notify.html));

                        if (response.templateJson.notify.css)
                            this.editorNotify.setStyle(response.templateJson.notify.css);
                    }
                });
            }
        });
    }

    onSave = (event, data) => {

        this.state.model = data;
        if (this.editorContent) {
            this.state.content = {
                css: this.editorContent.getCss(),
                html: this.editorContent.getHtml()
            };
        }

        if (this.editorNotify) {
            this.state.notify = {
                css: this.editorNotify.getCss(),
                html: this.editorNotify.getHtml()
            };
        }

        console.log('Design State:');
        console.log(JSON.stringify(this.state));
    }

    onUpdate = (event, data) => {
        this.state.model = data;
        console.log('Design Update:');
        console.log(JSON.stringify(this.state));
    }

    componentWillUnmount() {
        if (!this.mounted) return;
        this.mounted = false;

        PubSub.unsubscribe('canvas_wallpaper.save');
        PubSub.unsubscribe('canvas_wallpaper.update');

        PubSub.publish("CANVAS", { showPanel: false });

        if (this.editorContent) {
            this.editorContent.destroy();
        }
        if (this.editorNotify) {
            this.editorNotify.destroy();
        }
    }

    setActiveCanvas = (mode) => {
        if (this.state.mode != mode) {
            this.setState({ mode });
        }

        if (this.editorNotify && this.editorNotify.hideWrappers)
            this.editorNotify.hideWrappers();
        if (this.editorContent && this.editorContent.hideWrappers)
            this.editorContent.hideWrappers();

        switch (mode) {
            case 'design-content':
                if (this.editorContent)
                    this.editorContent.showWrappers();
                break;
            case 'design-notify':
                if (this.editorNotify)
                    this.editorNotify.showWrappers();
                break;
            case 'preview':
            default:
                break;
        }
    }

    render() {
        return (
            <div className="canvasRoot">
                <div className="design-change">
                    <Button.Group>
                        <Button toggle onClick={() => this.setActiveCanvas('design-content')}>İçerik</Button>
                        {/*<Button toggle onClick={() => this.setActiveCanvas('design-notify')}>Bildirim</Button>*/}
                        <Button toggle onClick={() => this.setActiveCanvas('preview')}>Önizleme</Button>
                    </Button.Group>
                </div>
                <div id="design-content" className="canvas-content">

                </div>
                <div id="design-notify" className="canvas-content">

                </div>
                <div id="preview" style={{ marginLeft: 'auto', marginRight: 'auto', width: 900, paddingLeft: 30, display: this.state.mode === 'preview' ? 'block' : 'none' }}>
                    <PreviewDevice
                        deviceType="DESKTOP"
                        device="macbookpro"
                        scale="0.8"
                        wallpaper="url(/images/macos-wallpaper.png)"
                        notification={{
                            show: false,
                            title: 'Mesaj başlığını buraya yazınız',
                            subtitle: 'Yazılı ve görsel mesajların e-posta trafiğine takılmadan çalışanlara ulaşmasını sağlar.',
                            body: 'Pazarlama',
                            snooze: 0,
                            top: 0,
                            content: this.editorNotify ? `<style>html,body{padding:0; margin:0};${this.editorNotify.getCss()}${PreviewCss.css}</style>${this.editorNotify.getHtml()}` : null
                        }}>
                        <PreviewWindow
                            bgcolor="#ffffff"
                            mediaType="WALLPAPER"
                            title='The title of message'
                            subtitle='The sub title of message'>

                            <PreviewFrame
                                content={`<style>html,body{padding:0; margin:0};${this.editorContent?.getCss()}</style>${this.editorContent?.getHtml()}`}  />

                        </PreviewWindow>
                    </PreviewDevice>

                </div>
            </div>
        )
    }
}

export default WallpaperDesignPage;
