export const DeliveryOptionTypes = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE',
    ALL: 'ALL'
}

export const PublishStatus = {
    PENDING: 'PENDING',
    DRAFT: 'DRAFT',
    SENT: 'SENT',
    TEST: 'TEST',
    DELETE: 'DELETE'
}