import React, { useState, useEffect } from "react";
import SurveyQuestion from "./SurveyQuestion";
import Question from "./Question";
import ListController from "./ListController";
import EmptyView from "../common/EmptyView";

export default function SurveyBuilder({onChange, mode, data,show,subMode}) {
    const [questions, setQuestions] = useState(data ? data : []);
    const [count,setCount] = useState(0)
    const listController = new ListController(questions, setQuestions);

    if(subMode==="again"){
        if (data!==questions && count <= 1){
            setQ()
            setCount(count+1)
        }

        function setQ()  {
            const temp = data?.map((data,index)=>{
                if (data!==undefined){
                    return(new Question({text:data.text,type:data.type,options:data.options,cols:data.cols,rows:data.rows,id:data.id}))
                }
            })
            setQuestions(temp)


        }
    }

    useEffect(() => {
        if(questions?.length > 0 && mode === "edit"){
            onChange(questions);
        }

    }, [questions])

    return (
        <div className="small-container" style={{overflowY:"auto"}}>
            <ul>
                {questions?.filter(ques => ques!==undefined).map((question, i) => (
                    <SurveyQuestion
                        key={question?.id}
                        index={i}
                        mode={mode}
                        total={questions?.length}
                        question={question}
                        setQuestion={question => listController.set(i, question)}
                        removeQuestion={() => listController.remove(i)}
                        moveQuestionUp={() => listController.moveUp(i)}
                        moveQuestionDown={() => listController.moveDown(i)}
                        show={show}
                    />
                ))}
            </ul>

            {questions?.length === 0 &&
                <>
                    <br/>
                    <EmptyView title="Henüz gösterilecek bir soru yok + butonu ile hemen yeni bir soru oluşturabilirsiniz."/>
                </>
            }

            {mode === "edit" &&
                <div className="text-center">
                    <button className="btn btn-primary mt-5" style={{display:"inline-block",margin:"auto",width:"150px"}}
                            onClick={() => {listController.add(new Question())
                                let div = document.getElementById("survey_builder_bottom")
                                div.scrollIntoView({ behavior: "smooth" })}}>Soru Ekle</button>
                </div>

            }
            <div id="survey_builder_bottom" style={{ float:"left", clear: "both" ,height:"200px"}}>
            </div>

            {/*{mode === "edit" &&*/}
            {/*    <button className="btn btn-primary" style={{height: 60, width: 60, borderRadius: 30, position: "fixed", bottom: 30,  right: 400}} onClick={() => listController.add(new Question())}>+</button>*/}
            {/*}*/}
        </div>
    );
}
