import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { setPageTitle } from "../../helpers/LayoutHelper";
import { Card, Modal, Image, Icon } from "semantic-ui-react";
import CreateTemplate from "./forms/CreateTemplate";
import { protectedApi } from "../../services/axios";
import PubSub from "pubsub-js";
import Swal from "sweetalert2";
import templateImg from '../../assets/images/templateImg.jpg'
import { PreviewCss } from "../../helpers/PreviewCssHelper";
import filter from "tui-image-editor/src/js/ui/template/submenu/filter";

class TemplateSelectionPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            templates: null,
            birthdayTemplates: [],
            channels: ["POPUP", "EMERGENCY", "NEWSLETTER", "LCV", "LOCK", "WALLPAPER", "SOCIAL", "SCREENSAVER"]
        }
        this.headerStyle = { textAlign: "center", fontSize: "0.7vw" }
        this.cardWidth = { maxWidth: "99%" }
        this.contentHeight = { height: "100px" }
        this.imageClick = { cursor: "pointer" }
    }

    componentDidMount = async () => {
        PubSub.publish('MENU', 'selectcanvas');
        setPageTitle("Şablonlar");
        this.getTemplates()
        this.getBirthdayTemplates()

    }

    getTemplates = () => {
        const temp = this.state.channels.map((data, index) => {
            protectedApi.get(`/announcement/templates?type=${data}&ordering=name&?isBirthDayTemplate=false`)
                .then((response) => {
                    //this.setState({templates: response.data.results})
                    this.setState({ templates: { ...this.state.templates, [data]: response.data.results } })
                })
                .catch((error) => {
                    console.log(error);
                });
        })

    }
    getBirthdayTemplates = () => {

        protectedApi.get(`/announcement/templates?type=POPUP&ordering=name&isBirthDayTemplate=true`)
            .then((response) => {
                //this.setState({templates: response.data.results})
                this.setState({ birthdayTemplates: response.data.results })
            })
            .catch((error) => {
                console.log(error);
            });


    }

    setOpen = () => {
        this.setState({ open: !this.state.open })
        this.getTemplates()
    }

    deleteTemplate = (id) => {

        Swal.fire({
            icon: 'warning',
            text: "Seçtiğiniz şablonu silmek istiyor musunuz?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#3B4182',
            confirmButtonText: 'Evet',
            cancelButtonColor: '#E40426',
            cancelButtonText: 'Hayır',
            focusCancel: false,
            reverseButtons: true,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                //const cancellationDescription = window.prompt("İptal nedinini yazınız");
                protectedApi.delete(`/announcement/template/${id}`)
                    .then((response) => {
                        Swal.fire({
                            icon: 'success',
                            text: 'Şablon Silindi!',
                            // text: response.message,
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: '#3B4182',
                            cancelButtonText: "Tamam",
                            focusCancel: false,

                        })
                        this.getTemplates();
                    }).catch(error => Swal.fire({
                        icon: 'error',
                        text: 'Şablon Silinemedi!',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,

                    }))
            }
        })

    }

    sendSignal = (id, name) => {
        window.template_id = id
        window.template_name = name
        setTimeout(() => {
            this.props.history.push("/canvas")
        }, 1000)

    }

    render() {
        let templatePOPUP = this.state.templates?.POPUP?.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} src={templateImg} wrapped ui={false} />


                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>

                    </Card>
                </div>
            )
        })
        let templateBIRTHDAY = this.state.birthdayTemplates.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} src={templateImg} wrapped ui={false} />

                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>
                    </Card>
                </div>
            )
        })
        let templateLCV = this.state.templates?.LCV?.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} style={this.imageClick} src={templateImg} wrapped ui={false} />
                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>
                    </Card>
                </div>
            )
        })
        let templateEMERGENCY = this.state.templates?.EMERGENCY?.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} src={templateImg} wrapped ui={false} />
                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>
                    </Card>
                </div>
            )
        })
        let templateSOCIAL = this.state.templates?.SOCIAL?.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} src={templateImg} wrapped ui={false} />
                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>
                    </Card>
                </div>
            )
        })
        let templateLOCK = this.state.templates?.LOCK?.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} src={templateImg} wrapped ui={false} />
                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>
                    </Card>
                </div>
            )
        })
        let templateWALLPAPER = this.state.templates?.WALLPAPER?.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} src={templateImg} wrapped ui={false} />
                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>
                    </Card>
                </div>
            )
        })
        let templateSCREENSAVER = this.state.templates?.SCREENSAVER?.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} src={templateImg} wrapped ui={false} />
                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>
                    </Card>
                </div>
            )
        })
        let templateNEWSLETTER = this.state.templates?.NEWSLETTER?.map((data, index) => {
            return (
                <div className="col-sm-2 w-25" key={index}>
                    <Card style={this.cardWidth} >
                        <Image style={this.imageClick} src={templateImg} wrapped ui={false} />
                        <Card.Content style={this.contentHeight}>
                            <Card.Header style={this.headerStyle}>{data.name}<br /></Card.Header>
                            <button className="btn btn-xs" onClick={() => this.sendSignal(data.id, data.name)} style={this.buttonCss}>
                                <i className="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
                            <button className="btn btn-xs" onClick={() => this.deleteTemplate(data.id)} style={this.buttonCss}>
                                <i className="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
                        </Card.Content>
                    </Card>
                </div>
            )
        })
        return (
            <>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body px-4 py-3 py-md-2">
                                <div className="row align-items-center">
                                    <div className="col-sm-12 col-md-12 text-md-right mt-md-0 mt-4">
                                        <Link onClick={() => this.setOpen()} className="btn btn-primary rounded btn-sm px-4">+ Yeni Şablon</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className="ml-3">Pop-up Şablonları</h3>
                    <div className="row ml-5">
                        {templatePOPUP}
                    </div>
                </div>
                <div>
                    <h3 className="ml-3">Doğum Günü Şablonları</h3>
                    <div className="row ml-5">
                        {templateBIRTHDAY}
                    </div>
                </div>
                <div>
                    <h3 className="ml-3">Newsletter Şablonları</h3>
                    <div className="row ml-5">
                        {templateNEWSLETTER}
                    </div>
                </div>
                <div>
                    <h3 className="ml-3">LCV Şablonları</h3>
                    <div className="row ml-5">
                        {templateLCV}
                    </div>
                </div>
                <div>
                    <h3 className="ml-3">Acil Durum Şablonları</h3>
                    <div className="row ml-5">
                        {templateEMERGENCY}
                    </div>
                </div>
                {/*<div>*/}
                {/*    <h3 className="ml-3">Sosyal Hat Şablonları</h3>*/}
                {/*    <div className="row ml-5">*/}
                {/*        {templateSOCIAL}*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div>
                    <h3 className="ml-3">Kilit Ekranı Şablonları</h3>
                    <div className="row ml-5">
                        {templateLOCK}
                    </div>
                </div>
                <div>
                    <h3 className="ml-3">Duvar Kağıdı Şablonları</h3>
                    <div className="row ml-5">
                        {templateWALLPAPER}
                    </div>
                </div>
                <div>
                    <h3 className="ml-3">Ekran Koruyucu Şablonları</h3>
                    <div className="row ml-5">
                        {templateSCREENSAVER}
                    </div>
                </div>

                <Modal
                    closeIcon
                    onClose={() => this.setOpen()}
                    onOpen={() => this.setOpen()}
                    open={this.state.open}
                    trigger={this.props.trigger}
                    style={{ maxWidth: "420px" }}
                >
                    <CreateTemplate history={this.props.history} closeEvent={() => this.setOpen()} />
                </Modal>
            </>

        );
    }
}

export default TemplateSelectionPage;