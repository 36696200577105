import React from "react";
import {Button, Header, Label, Form, Modal, Grid, Checkbox, Icon} from "semantic-ui-react";
import {Formik, ErrorMessage} from "formik";
import {Form as FormikForm} from "formik";
import tr from "date-fns/locale/tr";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import MultiSelectAll from "../../../components/forms/MultiSelectAll";
import {Tooltip} from "@trendmicro/react-tooltip";
import InitialMediaModel from "../../../models/constants/InitialMediaModel";
import * as E from "../../../models/enums/Common";
import MediaSchema from "../../../models/validations/MediaSettingsValidations";
import * as API from "../../../services/api";
import AsyncSelect from "react-select/async";
import moment from "moment";
import {setChonkyDefaults, FullFileBrowser, ChonkyActions, defineFileAction} from "chonky";
import {turkishI18n} from "../../../helpers/Language";
import {ChonkyIconFA} from "chonky-icon-fontawesome";
import settings from "../../../config/settings.json";
import PubSub from "pubsub-js";
import MediaSettingsValidations from "../../../models/validations/MediaSettingsValidations";
import Swal from "sweetalert2";

class AnnouncementSettings extends React.Component {
    constructor(props) {
        super(props);

        setChonkyDefaults({iconComponent: ChonkyIconFA});

        this.state = {
            model : {
                announcementType: '',
                type: '',
                title: '',
                description: null,
                folder: '',
                template: '',
                sender: '',
                startDate: null,
                endDate: null,
                isAllGroups: false,
                groups: [],
                includeUser: [],
                excludeUser: [],
                files: [],
                times: {
                    date: null,
                    time: null
                },
                endTime:{
                    date: null,
                    time: null
                },
                devices: {
                    mobile: true,
                    desktop: true
                },
                windowSize: 'NORMAL',
                notificationType: 'POPUP',
                snoozeEnabled: false,
                snoozeTime: '',
                snoozeCount: '',
                image: null,
                video: null,
                summary: '',
                bgcolor: '',
                analysisEndNote: '',
                deliveryOption: 'ALL',
                periodReaming: 0,
                nextPeriodTime: null,
                position: 'BOTTOM',
                speed: 2,
                repeatCount: 1,
                recurrenceDate: null,
                isPublished: '',
                repeatBy:"0",
                periodCount:1,
                periodInterval: 1,
                dataClassification:"0",
            },
            groups:[],
            groups2:[],
            sentClicked:false,

        }
    }

    componentDidMount() {
        this.loadData();
        this.setState({model:{...this.state.model,...this.props.model}})

        PubSub.subscribe('sendAgainPage', this.sendAgainPage)
    }

    sendAgainPage = (msg, data) => {
        // console.log(data)
        let tempData = data
        if (data.folder.id){
            tempData.folder=data.folder.id
        }else{
            tempData.folder=data.folder
        }
        tempData.groups = []
        if (!data.dataClassification){
            data.dataClassification = "0"
        }
        this.setState({model:{...this.state.model,...tempData}} )
    }

    async loadData() {
        const [groups] = await Promise.all([API.getGroups()]);

        this.setState({groups,groups2:groups});
        //this.updateState("groups",groups)
    }

    onSubmit = () => {
        this.setState({sentClicked:true})
        let recurrenceError = false
        let groupError = false
        let senderError = false
        let recurrenceHourError = false
        let recurrenceDayError = false
        let recurrenceWeekError = false
        let recurrenceMonthError = false
        if (this.state.model.repeatBy !== "0"){
            if (this.state.model.repeatBy === "1"){
                if (this.state.model.periodCount === 0 || this.state.model.periodCount === "" || this.state.model.periodInterval === 0 || this.state.model.periodInterval === ""){
                    recurrenceError = true
                }else if (this.state.model.periodInterval <= 0 || this.state.model.periodInterval > 24){
                    recurrenceHourError = true
                } else{
                    this.updateState("periodInterval",this.state.model.periodInterval*60)
                }
            }else if (this.state.model.repeatBy === "2"){
                if (this.state.model.periodCount === 0 || this.state.model.periodCount === ""){
                    this.updateState("periodInterval",0)
                    recurrenceError = true
                }else if (this.state.model.periodCount <= 0 || this.state.model.periodCount > 365){
                    recurrenceDayError = true
                }
            }else if (this.state.model.repeatBy === "3"){
                if (this.state.model.periodCount === 0 || this.state.model.periodCount === ""){
                    this.updateState("periodInterval",0)
                    recurrenceError = true
                }else if (this.state.model.periodCount <= 0 || this.state.model.periodCount > 52){
                    recurrenceWeekError = true
                }
            }else if (this.state.model.repeatBy === "4"){
                if (this.state.model.periodCount === 0 || this.state.model.periodCount === ""){
                    this.updateState("periodInterval",0)
                    recurrenceError = true
                }else if (this.state.model.periodCount <= 0 || this.state.model.periodCount > 12){
                    recurrenceMonthError = true
                }
            }
        }else{
            this.updateState("repeatBy","")
            this.updateState("periodCount",0)
            this.updateState("periodInterval",0)
        }
        if (this.state.model.isPublished !=="DRAFT"){
            if (this.state.model.groups.length === 0){
                groupError = true

            }

            if (this.isEmpty(this.state.model.sender)){
                senderError = true
            }
        }




        if (senderError){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen "Gönderen" alanını doldurunuz',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            this.setState({sentClicked:false})
            return
        }

        if (groupError) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen "Hedef Kitle" seçiniz',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            this.setState({sentClicked:false})
            return
        }

        if (recurrenceHourError){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen geçerli bir "Saat Aralığı" giriniz. (1-24)',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            this.setState({sentClicked:false})
            return
        }

        if (recurrenceDayError){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen geçerli bir "Tekrar Aralığı" giriniz. (1-365)',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            this.setState({sentClicked:false})
            return
        }
        if (recurrenceWeekError){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen geçerli bir "Tekrar Aralığı" giriniz. (1-52)',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            this.setState({sentClicked:false})
            return
        }
        if (recurrenceMonthError){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen geçerli bir "Tekrar Aralığı" giriniz. (1-12)',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            this.setState({sentClicked:false})
            return
        }

        if (recurrenceError){
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen "Yineleme" ayarlarını doldurunuz',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,

            })
            this.setState({sentClicked:false})
            return
        }
        // if (this.state.model.groups.length === 11 && this.state.model.isAllGroups === false) {
        //     this.setState({groupsError: true})
        // }
        if (this.state.model.devices.mobile === false && this.state.model.devices.desktop === false) {
            this.setState({devicesError: true})
        }
        if (!this.state.groupsError && !this.state.devicesError) {
            if (this.state.model.devices.desktop && this.state.model.devices.mobile) this.state.model.deliveryOption = E.DeliveryOptionTypes.ALL;
            else if (this.state.model.devices.desktop) this.state.model.deliveryOption = E.DeliveryOptionTypes.DESKTOP;
            else if (this.state.model.devices.mobile) this.state.model.deliveryOption = E.DeliveryOptionTypes.MOBILE;

            if (this.state.model.isAllGroups) {
                this.state.model.groups = this.state.groups?.map((g) => {
                    return g.id;
                });
            }
            this.state.model.isAllGroups = (this.state.model.groups.length === this.state.groups.length)
            if (this.state.model.times && this.state.model.times.date && this.state.model.times.time) {
                this.state.model.startDate = moment(moment(this.state.model.times.date).format("YYYY-MM-DD") + " " + this.state.model.times.time.format("HH:mm")).format("YYYY-MM-DD HH:mm:00.0");
            } else if (!this.state.model.times.date && this.state.model.times.time){
                this.state.model.startDate = moment(moment().format("YYYY-MM-DD") + " " + this.state.model.times.time.format("HH:mm")).format("YYYY-MM-DD HH:mm:00.0")
                this.state.model.isPublished = E.PublishStatus.PENDING
            }

            if (this.state.model.endTime && this.state.model.endTime.date && this.state.model.endTime.time) {
                this.state.model.endDate = moment(moment(this.state.model.endTime.date).format("YYYY-MM-DD") + " " + this.state.model.endTime.time.format("HH:mm")).format("YYYY-MM-DD HH:mm:00.0");
            }
            // if(!values.startDate){
            //     values.startDate = moment(moment().format("YYYY-MM-DD") + " " + moment().format("HH:mm")).format("YYYY-MM-DD HH:mm:00.0")
            // }

            if (this.state.model.startDate && this.state.model.isPublished === E.PublishStatus.PENDING) {
                this.state.model.isPublished = E.PublishStatus.SENT;
            }

            this.state.model.snoozeTime = this.state.model.snoozeTime || 0;
            this.state.model.snoozeCount = this.state.model.snoozeCount || 0;
            //this.state.model.files = this.state.files;
            if (this.props.model?.type === "EMERGENCY"){
                this.updateState("notificationType","DIRECT")
            }
            if (this.state.model.type==="TICKER"){
                this.state.model.deliveryOption=E.DeliveryOptionTypes.DESKTOP
            }
            if (this.state.model.dataClassification === "0"){
                this.updateState('dataClassification',null)
            }


            //this.state = Object.assign(this.state, values);
            window.scrollTo(0, 0)
            this.props.getValues(this.state.model)

            PubSub.publish(("canvas_" + this.state.model.type + ".save").toLowerCase(), this.state.model);
        }
        this.setState({sentClicked:false})
    };

    handleFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let selectedFile = event.target.files[0];

        this.handleFileUpload(selectedFile);
    };

    handleFileBrowserAction = (event) => {
        if (event.id === "upload_files") {
            this.uploadInput.click();
        }

        if (event.id === "delete_files") {
            let allFiles = this.state.files;
            let newFiles = allFiles?.filter((i) => !event.state.selectedFiles?.some((j) => j.id === i.id));
            this.updateState("files",newFiles)
        }
    };
    handleFileUpload = (file) => {
        let data = new FormData();
        let config = {header: {"Content-Type": "multipart/form-data"}};

        data.append("file", file, file.name);

        API.uploadFiles(data, config)
            .then((response) => {
                let uploadedFile = {
                    id: response.data.id,
                    name: file.name,
                    url: settings.apiBaseClear + response.data.file,
                    thumbnailUrl: settings.apiBaseClear + response.data.file,
                    isThumbnail: false,
                    isVideo: false,
                    color: "#3B4182",
                    icon: "image",
                    type: file.type,
                };

                let allFiles = [...this.state.model.files.filter((f) => f !== null)];

                allFiles.push(uploadedFile);
                this.updateState("files",allFiles)
                PubSub.publish(("canvas_" + this.props.model.type + ".update").toLowerCase(), this.state.model);
                Swal.fire({
                    icon: 'success',
                    text: 'Dosya Yüklendi!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    text: 'Dosya yüklenirken bir hata oluştu.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
            });
    };

    isEmpty = (str) => {
        return !str.trim().length
    }

    updateState =(name,value)=>{
        let model = this.state.model
        model[`${name}`] = value
        if (name === "repeatBy"){
            model.periodCount = 1
            model.periodInterval = 1
        }
        if (name === "dataClassification"){
            PubSub.publish("data-classification",value)
        }
        this.setState({model,groups:this.state.groups2})// this.state.groups ilginç bir şekilde sıfırlandığı için eklenmiştir
        // console.log(model)
    }
    updateStateNested =(title,name,value)=>{
        let model = this.state.model
        model[`${title}`][`${name}`] = value
        this.setState({model,groups:this.state.groups2})// this.state.groups ilginç bir şekilde sıfırlandığı için eklenmiştir
        // console.log(model)
    }
    multiSelectFunction = (values) => {
        // console.log("valuessssss",values)
        if (values.filter(data => data.value==="*").length === 0){
            if (this.state.groups.length === values.length){
                this.updateState("groups",[])
            }else{
                this.updateState("groups",values.map((m) => {
                    return m.value;
                }))
            }
        }else{
            this.updateState("groups",this.state.groups?.map((m) => {
                return m.id;
            }))
        }
    }

    render() {
        return (
            <div className="ui form" style={{padding:"16px"}}>
                <div className="row">
                    <div className="col-sm-12 field">
                        <label>* Gönderen</label>
                        <input type="text" placeholder='Örneğin; İnsan Kaynakları' className="ui input" name="sender" value={this.state.model.sender} onChange={(event)=> this.updateState(event.target.name,event.target.value)}/>
                    </div>
                </div>
                {this.props.model?.type !== "EMERGENCY" ? (
                    <>
                        <div className='field'>
                            <Grid padded>
                                <Grid.Row columns={2} divided='horizantally'>
                                    <Grid.Column textAlign='left' verticalAlign='top'>
                                        <div className='field'>
                                            <label>Gönderim Tarihi</label>
                                            <DatePicker placeholderText='Bugün'
                                                        selected={this.state.model.times?.date}
                                                        isClearable={true} minDate={new Date()}
                                                        showTimeSelect={false} timeFormat='H:mm'
                                                        timeIntervals={15} locale={tr}
                                                        dateFormat='dd.MM.yyyy'
                                                        onChange={(value) => this.updateStateNested("times","date",value)}/>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column textAlign='left' verticalAlign='top'>
                                        <div className='field'>
                                            <label>Gönderim Saati</label>
                                            <TimePicker  placeholder='Hemen'
                                                         showSecond={false}
                                                        minuteStep={15}
                                                        clearIcon={<button type='button'
                                                                           className='react-datepicker__close-icon'
                                                                           tabIndex='-1'/>}
                                                        value={this.state.model.times?.time}
                                                        onChange={(value) => this.updateStateNested("times","time",value)}/>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </>
                ):null}
                { // ALANLARIN SERVİS KARŞILIĞI BİLİNMEDİĞİ İÇİN DEĞERLERİ AYARLANMADI
                    this.props.model?.type === "LOCK" || this.props.model?.type === "WALLPAPER" || this.props.model?.type === "SCREENSAVER" && false ?
                        <div className='field'>
                            <Grid padded>
                                <Grid.Row columns={2} divided='horizantally'>
                                    <Grid.Column textAlign='left' verticalAlign='top'>
                                        <div className='field'>
                                            <label>Bitiş Tarihi</label>
                                            <DatePicker placeholderText='Bugün'
                                                        selected={this.state.model.endTime.date}
                                                        isClearable={true} minDate={new Date()}
                                                        showTimeSelect={false} timeFormat='H:mm'
                                                        timeIntervals={15} locale={tr}
                                                        dateFormat='dd.MM.yyyy'
                                                        onChange={(value) => this.updateStateNested("endTime","date",value)}
                                            />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column textAlign='left' verticalAlign='top'>
                                        <div className='field'>
                                            <label>Bitiş Saati</label>
                                            <DatePicker placeholderText='Bugün'
                                                        selected={this.state.model.endTime.time}
                                                        isClearable={true} minDate={new Date()}
                                                        showTimeSelect={false} timeFormat='H:mm'
                                                        timeIntervals={15} locale={tr}
                                                        dateFormat='dd.MM.yyyy'
                                                        onChange={(value) => this.updateStateNested("endTime","time",value)}
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div> : null
                }
                {this.state.model?.type === "POPUP" || this.state.model?.type === "QUIZ" || this.state.model?.type === "SURVEY" || this.state.model?.type === "ELEARNING" || this.state.model?.type === "LCV" || this.state.model?.type === "EMERGENCY" || this.state.model?.type === "NEWSLETTER"  ?
                    <div className="field">
                        <div className="row">
                            <div className="col-sm-12">
                                <Form.Select
                                    fluid
                                    label='Veri Sınıflandırma'
                                    name='dataClassification'
                                    onChange={(e,{name,value})=>{this.updateState("dataClassification",value)}}
                                    value={this.state.model.dataClassification}
                                    options={[
                                        {text: "Veri sınıflandırması yok", value: "0"},
                                        {text: "Genel", value: "PUBLIC"},
                                        {text: "Şirket İçi", value: "RESTRICTED"},
                                        {text: "Gizli", value: "CONFIDENTIAL"},
                                        {text: "Çok Gizli", value: "TOPSECRET"},
                                    ]}
                                />
                            </div>

                        </div>
                    </div>
                    :
                    null}
                {((this.props.model?.type !== "EMERGENCY")&&(this.props.model?.type === "TICKER" || this.props.model?.type === "NEWSLETTER")) ?
                <>
                    <div className="field">
                        <div className="row">
                            <div className="col-sm-12">
                                <Form.Select
                                    fluid
                                    label='Yineleme'
                                    name='repeatBy'
                                    onChange={(e,{name,value})=>{this.updateState("repeatBy",value)}}
                                    value={this.state.model.repeatBy}
                                    options={[
                                        {text: "Yineleme yok", value: "0"},
                                        {text: "Saatlik", value: "1"},
                                        {text: "Günlük", value: "2"},
                                        {text: "Haftalık", value: "3"},
                                        {text: "Aylık", value: "4"},
                                    ]}
                                    placeholder='Seçiniz..'
                                />
                            </div>

                        </div>
                    </div>

                    {this.state.model.repeatBy!=="0"?
                        <div className="field">
                            <div className="row">
                                <div className="col-sm-6 field">
                                    <label>* Tekrar Sayısı</label>
                                    <input type="text" placeholder='' className="ui input" name="periodCount" value={this.state.model.periodCount} onChange={(event)=> this.updateState(event.target.name,event.target.value.replace(/[^1-9.-]+/g,'').replace('-',''))}/>
                                </div>
                                <div className="col-sm-6 field" >
                                    <label>* Saat Aralığı</label>
                                    <input disabled={this.state.model.repeatBy !== "1"} type="text" placeholder='' className="ui input" name="periodInterval" value={this.state.model.periodInterval} onChange={(event)=> this.updateState(event.target.name,event.target.value.replace(/[^1-9.-]+/g,'').replace('-',''))}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    {this.state.model.repeatBy==="1"?<p style={{color:"grey",fontSize:"12px"}}>Duyurunuz gün içerisinde {this.state.model.periodCount?this.state.model.periodCount:"..."} defa {this.state.model.periodInterval?this.state.model.periodInterval:"..."} saat aralıklarla {(this.state.model.times && this.state.model.times.date && this.state.model.times.time)?moment(moment(this.state.model.times.date).format("YYYY-MM-DD") + " " + this.state.model.times.time.format("HH:mm")).format("DD-MM-YYYY HH:mm"):moment().format("DD-MM-YYYY HH:mm")} tarihinden itibaren yayımlanacaktır.</p>
                                        :this.state.model.repeatBy==="2"?<p style={{color:"grey",fontSize:"12px"}}>Duyurunuz {this.state.model.periodCount?this.state.model.periodCount:"..."} gün boyunca her gün saat {(this.state.model.times && this.state.model.times.date && this.state.model.times.time)?moment(moment(this.state.model.times.date).format("YYYY-MM-DD") + " " + this.state.model.times.time.format("HH:mm")).format("HH:mm"):moment().format("HH:mm")}'te yayınlanacaktır.</p>
                                            :this.state.model.repeatBy==="3"?<p style={{color:"grey",fontSize:"12px"}}>Duyurunuz {this.state.model.periodCount?this.state.model.periodCount:"..."} hafta boyunca her {(this.state.model.times && this.state.model.times.date && this.state.model.times.time)?moment(moment(this.state.model.times.date).format("YYYY-MM-DD") + " " + this.state.model.times.time.format("HH:mm")).format("dddd HH:mm"):moment().format("dddd HH:mm")}'te yayınlanacaktır.</p>
                                                :this.state.model.repeatBy==="4"?<p style={{color:"grey",fontSize:"12px"}}>Duyurunuz {this.state.model.periodCount?this.state.model.periodCount:"..."} ay boyunca her ayın {(this.state.model.times && this.state.model.times.date && this.state.model.times.time)?moment(moment(this.state.model.times.date).format("YYYY-MM-DD") + " " + this.state.model.times.time.format("HH:mm")).format("DD")+". günü saat "+moment(moment(this.state.model.times.date).format("YYYY-MM-DD") + " " + this.state.model.times.time.format("HH:mm")).format("HH:mm"):moment().format("DD")+". günü saat "+moment().format("HH:mm")}'te yayınlanacaktır.</p>
                                                    :null
                                    }

                                </div>
                            </div>
                        </div>
                        :null}
                </> : null }




                {this.props.type !== "TICKER"?
                <div className='field'>
                    <label>Gönderilecek Cihaz Tipi</label>
                    {(this.props.model?.type !== "LOCK" && this.props.model?.type !== "WALLPAPER" && this.props.model?.type !== "SCREENSAVER") ? (

                            <>
                            <Checkbox label='Mobil Cihazlar' checked={this.state.model.devices?.mobile}
                                      onChange={(event) => this.updateStateNested("devices","mobile",!this.state.model.devices?.mobile)}/>
                            <br/>
                        </>
                    ) : null}

                    <Checkbox label='Bilgisayarlar' checked={this.state.model.devices?.desktop} disabled={this.props.model?.type === "LOCK" || this.props.model?.type === "WALLPAPER" || this.props.model?.type === "SCREENSAVER" || this.props.model?.type === "TICKER"}
                              onChange={(event) => this.updateStateNested("devices","desktop",!this.state.model.devices?.desktop)}/>
                </div>
                    :
                    null}
                {this.props.mediaType === "/publish/quiz" || this.props.type === "ELEARNING" ? (
                <div className='field'>
                    <label>Quiz sonunda doğru cevaplar gösterilsin</label>
                    <Checkbox label='Evet' checked={this.state.model.showAnswers}
                              onChange={() => this.updateState("showAnswers",!this.state.model.showAnswers)}/>
                </div>

                ) : null}
                <div className="field">
                    <label>* Hedef Kitle</label>
                    <MultiSelectAll
                        itemTitle='Grup'
                        name='groups'
                        value = {this.state.model.groups}
                        allGroups={this.state.model.isAllGroups}
                        onChange={(values)=>this.multiSelectFunction(values)}
                        // onChange={(values) => {
                        //     console.log('values : ', values)
                        //     this.updateState("isAllGroups",false)
                        //     if (values && values.length > 0) {
                        //         this.updateState("groups",
                        //             values.map((m) => {
                        //             return m.value;
                        //         }))
                        //         if (values.filter((m) => m.value === "*").length > 0) {
                        //             this.updateState("groups",
                        //                 this.state.model.groups?.map((m) => {
                        //                 return m.id;
                        //             }))
                        //             this.updateState("isAllGroups",true)
                        //         }
                        //     }
                        // }}
                        options={this.state.groups?.map((m) => {
                            return {value: m?.id, label: m?.name};
                        })}

                    />
                </div>
                <>
                    <div className='field'>
                        <label>Kişi Ekle</label>
                        <AsyncSelect
                            isMulti={true}
                            isClearable={true}
                            loadingMessage={() => <span>Yükleniyor</span>}
                            noOptionsMessage={() => <span>Henüz kimseyi seçmediniz</span>}
                            onChange={(values) => this.updateState("includeUser",values)}
                            placeholder='Seçiniz'
                            defaultOptions={[]}
                            loadOptions={(inputValue) => {
                                return API.searchUser(inputValue).then((list) => {
                                    return list.map((u) => {
                                        return {
                                            value: u.id,
                                            label: u.first_name + " " + u.last_name
                                        };
                                    });
                                });
                            }}
                        />
                    </div>

                    <div className='field'>
                        <label>Kişi Çıkart</label>
                        <AsyncSelect
                            isMulti={true}
                            isClearable={true}
                            loadingMessage={() => <span>Yükleniyor</span>}
                            noOptionsMessage={() => <span>Henüz kimseyi seçmediniz</span>}
                            onChange={(values) => this.updateState("excludeUser",values)}
                            placeholder='Seçiniz'
                            defaultOptions={[]}
                            loadOptions={(inputValue) => {
                                return API.searchUser(inputValue).then((list) => {
                                    return list.map((u) => {
                                        return {
                                            value: u.id,
                                            label: u.first_name + " " + u.last_name
                                        };
                                    });
                                });
                            }}
                        />
                    </div>
                </>
                {this.props.type !== "TICKER" && this.props.model?.type !== "EMERGENCY" ? (

                        <Form.Select
                    fluid
                    label='Pencere Boyutu'
                    name='windowSize'
                    onChange={(e,{name,value})=>{this.updateState("windowSize",value)}}
                    value={this.state.model.windowSize}
                    options={[
                        {text: "Normal", value: "NORMAL"},
                        {text: "Ekranı Kaplayan", value: "MAXIMIZED"},
                    ]}
                    placeholder='Seçiniz..'
                />
                ) : null}
                {this.props.type==="TICKER"?
                <Form.Select
                    fluid
                    label='Tekrar Sayısı'
                    name='repeatCount'
                    onChange={(e,{name,value})=>{this.updateState("repeatCount",value)}}
                    value={this.state.model.repeatCount}
                    options={[
                        {text: "1 Tekrar", value: 1},
                        {text: "2 Tekrar", value: 2},
                        {text: "3 Tekrar", value: 3},
                        {text: "4 Tekrar", value: 4},
                        {text: "5 Tekrar", value: 5},

                    ]}
                    placeholder='Seçiniz'
                />
                    :null}
                {(this.props.model?.type !== "EMERGENCY" && this.props.model?.type !== "LOCK" && this.props.model?.type !== "WALLPAPER" && this.props.model?.type !== "SCREENSAVER" ) ? (

                        <Form.Select
                    fluid
                    label='Gönderi Tipi'
                    name='notificationType'
                    onChange={(e,{name,value})=>{this.updateState("notificationType",value)}}
                    value={this.state.model.notificationType}
                    options={[
                        {text: "Bildirim", value: "POPUP"},
                        {text: "Doğrudan", value: "DIRECT"},
                        {text: "Sessiz", value: "FLASHING"},
                    ]}
                    placeholder='Seçiniz..'
                />
                ) : null}
                {this.props.model?.type !== "EMERGENCY" ? this.state.model?.notificationType === "POPUP" && (

                <div className='field'>
                    <label>Öteleme Süresi ve Sayısı</label>

                    <label htmlFor='snoozeEnabled'>
                        <input
                            name='snoozeTime'
                            id='snoozeTime'
                            // disabled={!props.values.snoozeEnabled}
                            value={this.state.model.snoozeTime}
                            onChange={(event)=> this.updateState(event.target.name,event.target.value)}
                            style={{
                                height: 22,
                                width: 45,
                                marginLeft: 10,
                                marginRight: 10,
                                cursor: "text",
                            }}
                        />
                        dakika arayla en fazla
                        <input
                            name='snoozeCount'
                            //disabled={!props.values.snoozeEnabled}
                            value={this.state.model.snoozeCount}
                            onChange={(event)=> this.updateState(event.target.name,event.target.value)}
                            style={{
                                height: 22,
                                width: 45,
                                marginLeft: 10,
                                marginRight: 10,
                                cursor: "text",
                            }}
                        />
                        kez ötelenebilir.
                    </label>
                </div>
                ) : null}
                {this.props.type !== "TICKER" ? (this.props.model?.type !== "EMERGENCY" && this.props.model?.type !== "LOCK" && this.props.model?.type !== "WALLPAPER" && this.props.model?.type !== "SCREENSAVER") && (

                <div className='field'>
                    <input type='file' ref={(ref) => (this.uploadInput = ref)}
                           style={{display: "none"}} onChange={this.handleFileChange}/>
                    <FullFileBrowser i18n={turkishI18n.folders} files={this.state.model.files}
                                     folderChain={[]}
                                     fileActions={[!this.state.model.isUploading ? ChonkyActions.UploadFiles : null, ChonkyActions.DeleteFiles]}
                                     disableDragAndDrop={true} disableDefaultFileActions={true}
                                     onFileAction={this.handleFileBrowserAction}/>
                </div>
                ) : null}
                <div className='field mt-5'>
                    <Button className='btn btn-send btn-rounded btn-md btn-block' type='submit'
                             icon style={{width: "100%"}}
                            disabled={this.state.sentClicked}
                            onClick={()=>{
                                this.updateState("isPublished",E.PublishStatus.DRAFT)
                                this.onSubmit()
                            }}>
                        <i className="flaticon-381-save "/>
                        &nbsp; Taslak Olarak Kaydet
                    </Button>
                </div>

                <div className='field'>
                    <Button className='btn btn-send btn-rounded btn-md btn-block' type='submit'
                            icon style={{width: "100%"}}
                            disabled={this.state.sentClicked}
                            onClick={()=>{
                                this.updateState("isPublished",E.PublishStatus.TEST)
                                this.onSubmit()
                            }}>
                        <i className="flaticon-381-send-1"/>
                        &nbsp; Test Grubuna Paylaş
                    </Button>
                </div>
                <div className='field'>
                    <Button
                        className='btn btn-send btn-rounded btn-md btn-block'
                        type='submit'
                        icon
                        style={{width: "100%"}}
                        disabled={this.state.sentClicked}
                        // onClick={() => handleValue('isPublished', E.PublishStatus.SENT)}
                        //asıl çalışan fonksiyon yukarıdakidir aşağıdaki fonksiyon loading ekranı içindir
                        onClick={()=>{
                            this.updateState("isPublished",E.PublishStatus.SENT)
                            this.onSubmit()
                        }}
                    >
                        <i className="flaticon-381-send-2"/>
                        &nbsp;Paylaş
                    </Button>
                </div>
            </div>
        );
    }
}

export default AnnouncementSettings;