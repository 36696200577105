import React, {Component} from 'react';
import {Modal, ModalContent, ModalHeader} from "semantic-ui-react";

class ShowUsersModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openModal: false
        }
    }

    setOpen = (state) => {
        this.setState({openModal: state})
    }

    render() {
        const userGroups = this.props && this.props.row && this.props.row.userList && this.props.row.userList.map((item,index)=>{
            return (
                <div className="col-sm-4">
                    <span>&bull;{item}</span>
                </div>
            )
        })
        return (
            <Modal onClose={() => this.setOpen(false)}
                   onOpen={() => this.setOpen(true)}
                   closeIcon
                   trigger={this.props.trigger}
                   style={{position: "relative", height: "200px", width: "450px"}}
                   size="small"
                   open={this.state.openModal}>
                <ModalHeader style={{height: "50px", width: "450px"}}>Kullanıcılar</ModalHeader>
                <ModalContent className="sentDepartmentDiv" style={{height: "150px", width: "450px",overflow:"auto"}}>
                    <div className="row">
                        {userGroups}
                    </div>
                </ModalContent>
            </Modal>
        );
    }
}

export default ShowUsersModal;