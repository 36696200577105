import React from 'react';
import PubSub from "pubsub-js";
import {setPageTitle} from "../../helpers/LayoutHelper";

class HelpHowTo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "Yardım"
        }
    }

    /* Standart methods for all pages */
    componentDidMount(){
        this.updatePageTitle();

        PubSub.publish('MENU', '');
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(nextProps) {
        this.updatePage(nextProps);
    }

    componentWillMount(){
        this.updatePage(this.props);
    }

    updatePage = async(props) => {
        this.updatePageTitle();
    };

    updatePageTitle = () => {
        setPageTitle(this.state.pageTitle);
    };

    subscribeDate = (msg, data) => {

    };
    /* Standart methods for all pages */

    render() {
        return (
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <h2>Teknik Bilgiler</h2>
                    </div>
                    <div className="card-body">
                        Bu sayfanın dokümanları en kısa süre içerisinde yüklenecektir.
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpHowTo
