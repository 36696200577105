import React from "react";
import { Link } from "react-router-dom";
import { format } from 'date-fns'
import {
    removeUsers,
    removeGroups,
    removeTestGroup,
    updateProfile,
    removeCalenderDate,
    updateDocument, updateUser
} from "../services/api";
import { right, Tooltip } from "@trendmicro/react-tooltip";
import LockModal from "../components/modals/CreateLockModal";
import UpdateUserModal from "../components/modals/updateUserModal";
import UpdateUserGroupModal from "../components/modals/updateUserGroupModal";
import Swal from 'sweetalert2';
import { protectedApi } from "../services/axios";
import PubSub from "pubsub-js";
import UpdateProfileModal from "../components/modals/updateProfileModal";
import moment from "moment";
import UpdateDocumentModal from "../components/modals/UpdateDocumentModal";
import { Modal, ModalContent, ModalHeader } from "semantic-ui-react";
import ShowGroupsModal from "../components/modals/ShowGroupsModal";
import ShowUsersModal from "../components/modals/ShowUsersModal";

export const setPageTitle = (title) => {
    let header = document.getElementById('pageTitle');

    if (header) {
        header.innerText = title;
        setPageSubTitle('');
    }
};

export const setPageSubTitle = (title) => {
    let header = document.getElementById('pageSubTitle');

    if (header) {
        header.innerText = title;
    }
}

export const isoValues = {
    PUBLIC: " Genel/Public ",
    RESTRICTED: " Şirket İçi/Internal",
    CONFIDENTIAL: " Gizli/Confidential",
    TOPSECRET: " Çok Gizli/Highly Confidential",
}

export const AnnouncementStatus = {
    SENT: 'Yayımlandı',
    PENDING: 'Planlandı',
    DRAFT: 'Taslak',
    TEST: 'Test Grubunda',
    DELETE: 'Silinmiş'
}

export const dateFormat = (e, format = null) => {
    return `${e.getDate()}.${e.getMonth() + 1}.${e.getFullYear()}`
};

export const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const notificationDisplay = (type) => {
    if (type === 'DIRECT') return 'Doğrudan';
    if (type === 'POPUP') return 'Bildirim';
    if (type === 'FLASHING') return 'Sessiz';
}

const setPublished = (id) => {
    window.publishControl = false
    PubSub.publish("test-to-sent", id)

}

const deletePublishedTest = (id) => {
    PubSub.publish("delete-published-test", id)

}

export const mediaActions = {
    ADD: 'add',
    LIST: 'list',
    CLONE: 'clone'
}

export const deliveryOptionsTypes = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE',
    ALL: 'ALL'
}

export const deliveryOptionsTitles = {
    DESKTOP: 'Bilgisayalar',
    MOBILE: 'Mobil Cihazlar',
    ALL: 'Tüm Cihazlar'
}

export const customMediaTableStyles = {
    headCells: {
        style: {
            paddingLeft: '10px',
            fontSize: 14
        },
    },
    cells: {
        style: {
            paddingLeft: '10px',

        },
    },
};

export const mediaTableColumnsDraft = [
    {
        name: 'Başlık',
        selector: 'title',
        sortable: true,
        width: '180px',
    },
    {
        name: 'İçerik Yöneticisi',
        selector: 'createdby.first_name',
        sortable: true,
        width: '120px',
    },
    {
        name: 'Gönderen',
        selector: 'sender',
        sortable: true,
        width: '130px',
    },
    {
        name: 'Klasör',
        selector: 'folder.name',
        sortable: true,
        width: '140px',
        cell: row => {
            return <Link to={`/folders/${row.folder.id}`}><i className="flaticon-381-folder-8" /> {row.folder.name}
            </Link>
        }
    },
    {
        name: 'Oluşturma Tarihi',
        selector: 'startDate',
        sortable: true,
        width: '140px',
        minWidth: '140px',
        maxWidth: '140px',
        format: row => format(new Date(row.startDate), 'dd.MM.yyyy HH:mm')
    },
    // {
    //     name: 'Son Yayın Tarihi',
    //     selector: 'endDate',
    //     sortable: true,
    //     width: '140px',
    //     minWidth: '140px',
    //     maxWidth: '140px',
    //     format: row => format(new Date(row.endDate), 'dd.MM.yyyy HH:mm')
    // },
    {
        name: 'Gönderi Tipi',
        selector: 'notificationType',
        sortable: true,
        width: '120px',
        minWidth: '120px',
        maxWidth: '120px',
        cell: row => {
            return notificationDisplay(row.notificationType)
        }
    },
    {
        name: <i className="la la-mobile-phone fs-18 " />,
        selector: 'deliveryOption',
        sortable: false,
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'MOBILE' ? '✓' : '✕'
        }
    },
    {
        name: <i className="la la-desktop fs-18 " />,
        selector: 'deliveryOption',
        sortable: false,
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'DESKTOP' ? '✓' : '✕'
        }
    },
    {
        name: 'Düzenle',
        sortable: false,
        center: 'true',
        width: '80px',
        minWidth: '80px',
        maxWidth: '80px',
        cell: row => {
            return <Link
                to={{ pathname: row.type === "TICKER" ? "/publish/againticker" : row.type === "QUIZ" ? "/publish/againquiz" : row.type === "SURVEY" ? "/publish/againsurvey" : row.type === "ELEARNING" ? "/publish/againelearning" : "/publish/again", state: { id: row.id } }}><i
                    className="las la-edit fs-24 ml-3" /></Link>
        }
    },
];

export const mediaTableColumns = [
    {
        name: 'Başlık',
        left: "true",
        selector: 'title',
        sortable: true,
        wrap: false,
        width: '180px',
    },
    {
        name: 'İçerik Yöneticisi',
        selector: 'createdby.first_name',
        sortable: true,
        width: '120px',
    },
    {
        name: 'Gönderen',
        selector: 'sender',
        sortable: true,
        width: '130px',
    },
    {
        name: 'Klasör',
        selector: 'folder.name',
        sortable: true,
        width: '140px',

        cell: row => {
            return <Link to={`/folders/${row.folder.id}`}><i className="flaticon-381-folder-8" /> {row.folder.name}
            </Link>
        }
    },
    {
        name: 'Yayımlama Tarihi',
        selector: 'startDate',
        sortable: true,
        width: '140px',
        format: row => format(new Date(row.startDate), 'dd.MM.yyyy HH:mm')
    },
    // {
    //     name: 'Son Yayın Tarihi',
    //     selector: 'endDate',
    //     sortable: true,
    //     width: '140px',
    //     format: row => format(new Date(row.endDate), 'dd.MM.yyyy HH:mm')
    // },
    {
        name: 'Gönderi Tipi',
        selector: 'notificationType',
        sortable: true,
        width: '120px',
        cell: row => {
            return notificationDisplay(row.notificationType)
        }
    },
    {
        name: <i className="la la-mobile-phone fs-18 " />,
        selector: 'deliveryOption',
        sortable: false,
        center: 'true',
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'MOBILE' ? '✓' : '✕'
        }
    },
    {
        name: <i className="la la-desktop fs-18 " />,
        selector: 'deliveryOption',
        sortable: false,
        center: 'true',
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'DESKTOP' ? '✓' : '✕'
        }
    },
    {
        name: 'Görüntüle',
        sortable: false,
        center: 'true',
        width: '80px',
        minWidth: '80px',
        maxWidth: '80px',

        cell: row => {
            return <Link to={{
                pathname: row.type === "TICKER" ? `/publish/showticker` : row.type === "QUIZ" ? `/publish/showquiz` : row.type === "SURVEY" ? `/publish/showsurvey` : row.type === "ELEARNING" ? `/publish/showelearning` : `/publish/showcanvas`,
                state: { id: row.id }
            }}><i className="las la-eye fs-24 " /></Link>
        }
    },
    {
        name: 'Paylaş',
        sortable: false,
        center: 'true',
        width: '80px',
        minWidth: '80px',
        maxWidth: '80px',
        cell: row => {
            return <Link
                to={{ pathname: row.type === "TICKER" ? "/publish/againticker" : row.type === "QUIZ" ? "/publish/againquiz" : row.type === "SURVEY" ? "/publish/againsurvey" : row.type === "ELEARNING" ? "/publish/againelearning" : "/publish/again", state: { id: row.id } }}><i
                    className="flaticon-381-send-1 fs-20" /></Link>
        }
    },
];

export const mediaTableColumnsTest = [
    {
        name: 'Başlık',
        selector: 'title',
        sortable: true,
        wrap: false,
        width: '180px',
    },
    {
        name: 'İçerik Yöneticisi',
        selector: 'createdby.first_name',
        sortable: true,
        width: '120px',
    },
    {
        name: 'Gönderen',
        selector: 'sender',
        sortable: true,
        width: '130px',
    },
    {
        name: 'Klasör',
        selector: 'folder.name',
        sortable: true,
        width: '140px',

        cell: row => {
            return <Link to={`/folders/${row.folder.id}`}><i className="flaticon-381-folder-8" /> {row.folder.name}
            </Link>
        }
    },
    {
        name: 'Yayımlama Tarihi',
        selector: 'startDate',
        sortable: true,
        width: '140px',
        minWidth: '140px',
        maxWidth: '140px',
        format: row => format(new Date(row.startDate), 'dd.MM.yyyy HH:mm')
    },
    // {
    //     name: 'Son Yayın Tarihi',
    //     selector: 'endDate',
    //     sortable: true,
    //     width: '140px',
    //     minWidth: '140px',
    //     maxWidth: '140px',
    //     format: row => format(new Date(row.endDate), 'dd.MM.yyyy HH:mm')
    // },
    {
        name: 'Gönderi Tipi',
        selector: 'notificationType',
        sortable: true,
        width: '120px',
        minWidth: '120px',
        maxWidth: '120px',
        cell: row => {
            return notificationDisplay(row.notificationType)
        }
    },
    {
        name: <i className="la la-mobile-phone fs-18 " />,
        selector: 'deliveryOption',
        sortable: false,
        center: 'true',
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'MOBILE' ? '✓' : '✕'
        }
    },
    {
        name: <i className="la la-desktop fs-18 " />,
        selector: 'deliveryOption',
        sortable: false,
        center: 'true',
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'DESKTOP' ? '✓' : '✕'
        }
    },
    {
        name: 'Görüntüle',
        sortable: false,
        width: '80px',
        center: 'true',
        minWidth: '80px',
        maxWidth: '80px',

        cell: row => {
            return <Link to={{
                pathname: row.type === "TICKER" ? `/publish/showticker` : row.type === "QUIZ" ? `/publish/showquiz` : row.type === "SURVEY" ? `/publish/showsurvey` : row.type === "ELEARNING" ? `/publish/showelearning` : `/publish/showcanvas`,
                state: { id: row.id }
            }}><i className="las la-eye fs-24" /></Link>
        }
    },
    {
        name: 'Düzenle',
        sortable: false,
        width: '80px',
        center: 'true',
        minWidth: '80px',
        maxWidth: '80px',
        cell: row => {
            return <Link
                to={{ pathname: row.type === "TICKER" ? "/publish/againticker" : row.type === "QUIZ" ? "/publish/againquiz" : row.type === "SURVEY" ? "/publish/againsurvey" : row.type === "ELEARNING" ? "/publish/againelearning" : "/publish/again", state: { id: row.id } }}><i
                    className="las la-edit fs-24" /></Link>
        }
    },
    {
        name: 'Sil',
        sortable: false,
        width: '35px',
        minWidth: '35px',
        maxWidth: '35px',
        cell: row => {
            return <Link><i className="las la-trash-alt fs-24" onClick={() => { deletePublishedTest(row.id) }} /></Link>
        }
    },
    {
        name: 'Paylaş',
        sortable: false,
        width: '80px',
        minWidth: '80px',
        maxWidth: '80px',
        cell: row => {
            return <Link><i className="flaticon-381-send-1 fs-20 ml-3" onClick={() => { setPublished(row.id) }} /></Link>
        }
    },
];

export const mediaTableColumnsWithoutFolder = [
    {
        name: 'Başlık',
        selector: 'title',
        sortable: true,
        wrap: false,
        grow: 0.35
    },
    {
        name: 'İçerik Yöneticisi',
        selector: 'createdby.first_name',
        sortable: true,
        width: '120px',
    },
    {
        name: 'Gönderen',
        selector: 'sender',
        sortable: true,
        width: '130px',
    },
    {
        name: 'Yayımlama Tarihi',
        selector: 'startDate',
        sortable: true,
        width: '140px',
        minWidth: '140px',
        maxWidth: '140px',
        format: row => format(new Date(row.startDate), 'dd.MM.yyyy HH:mm')
    },
    {
        name: 'Son Yayın Tarihi',
        selector: 'endDate',
        sortable: true,
        width: '140px',
        minWidth: '140px',
        maxWidth: '140px',
        format: row => format(new Date(row.endDate), 'dd.MM.yyyy HH:mm')
    },
    {
        name: 'Gönderi Tipi',
        selector: 'notificationType',
        sortable: true,
        width: '120px',
        minWidth: '120px',
        maxWidth: '120px',
        cell: row => {
            return notificationDisplay(row.notificationType)
        }
    },
    {
        name: <i className="la la-mobile-phone fs-18 text-center" />,
        selector: 'deliveryOption',
        sortable: false,
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'MOBILE' ? '✓' : '✕'
        }
    },
    {
        name: <i className="la la-desktop fs-18 text-center" />,
        selector: 'deliveryOption',
        sortable: false,
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'DESKTOP' ? '✓' : '✕'
        }
    }
];

export const mediaTableColumnsWithoutUser = [
    {
        name: 'Başlık',
        selector: 'title',
        sortable: true,
        wrap: false,
        grow: 0.35
    },
    {
        name: 'Yayımlama Tarihi',
        selector: 'startDate',
        sortable: true,
        width: '140px',
        minWidth: '140px',
        maxWidth: '140px',
        format: row => format(new Date(row.startDate), 'dd.MM.yyyy HH:mm')
    },
    {
        name: 'Son Yayın Tarihi',
        selector: 'endDate',
        sortable: true,
        width: '140px',
        minWidth: '140px',
        maxWidth: '140px',
        format: row => format(new Date(row.endDate), 'dd.MM.yyyy HH:mm')
    },
    {
        name: 'Gönderi Tipi',
        selector: 'notificationType',
        sortable: true,
        width: '120px',
        minWidth: '120px',
        maxWidth: '120px',
        cell: row => {
            return notificationDisplay(row.notificationType)
        }
    },
    {
        name: <i className="la la-mobile-phone fs-18 text-center" />,
        selector: 'deliveryOption',
        sortable: false,
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'MOBILE' ? '✓' : '✕'
        }
    },
    {
        name: <i className="la la-desktop fs-18 text-center" />,
        selector: 'deliveryOption',
        sortable: false,
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => {
            return row.deliveryOption === 'ALL' ? '✓' : row.deliveryOption === 'DESKTOP' ? '✓' : '✕'
        }
    }
]


export const userTableColumns = [
    {
        name: 'Ad Soyad',
        selector: row => row.first_name + " " + row.last_name,
        sortable: true,
        width: '200px',
    },
    {
        name: 'Unvan',
        selector: row => row.profile?.title,
        sortable: false,
        wrap: true,
        width: '100px',
    },
    {
        name: 'Kullanıcı Adı',
        selector: 'username',
        sortable: true,
        width: '150px',
    },
    {
        name: 'E-Posta Adresi',
        selector: 'email',
        sortable: true,
        width: '200px',
    },
    {
        name: 'Tel No',
        selector: row => row.profile?.phone,
        sortable: false,
        width: '150px',
        wrap: true
    },
    {
        name: 'Doğum Tarihi',
        selector: row => moment(row.profile?.birthDate, "DD-MM-YYYY").format('DD.MM.YYYY'),
        sortable: false,
        width: '150px',
        wrap: true
    },
    {
        name: 'Bulunduğu Gruplar',
        selector: 'groups',
        sortable: false,
        wrap: true,
        width: '300px',
        cell: row => {
            return (
                row.groups.map((item, index) => {
                    if (index < 3) {
                        return (
                            <span key={`item${index}`} className="badge badge-sm light badge-secondary mr-1">{item.name}</span>
                        )
                    }
                    if (index === 3) {
                        return (
                            <ShowGroupsModal row={row} trigger={
                                <button className="btn btn-xl">
                                    <span key={`item${index}`} style={{ cursor: "pointer" }} className="badge badge-sm light badge-secondary mr-1">...</span>
                                </button>
                            } />
                        )
                    }
                })
            )
        }
    },
    {
        name: 'Departman',
        selector: row => row.profile?.department,
        sortable: false,
        wrap: true,
        width: '100px',
    },
    {
        name: 'Lokasyon',
        selector: row => row.profile?.city + "/" + row.profile?.locations,
        sortable: false,
        wrap: true,
        width: '100px',
    },
    {
        name: 'Kullanıcı Tipi',
        selector: row => row.profile?.userType,
        sortable: true,
        wrap: true,
        width: '150px',
        center: true,
    },
    {
        name: 'Standart Kullanıcı',
        selector: "profile.isStandartUser",
        sortable: false,
        wrap: true,
        width: '150px',
        center: true,
        cell: (row, index, column, id) => { return row.profile?.isStandartUser ? '✓' : '✕' }
    },
    {
        name: 'İçerik Yöneticisi',
        selector: "profile.isContentAdmin",
        sortable: false,
        wrap: true,
        width: '150px',
        center: true,
        cell: (row, index, column, id) => { return row.profile?.isContentAdmin ? '✓' : '✕' }
    },
    {
        name: 'Sistem Yöneticisi',
        selector: "profile.isSystemAdmin",
        sortable: false,
        wrap: true,
        width: '150px',
        center: true,
        cell: (row, index, column, id) => { return row.profile?.isSystemAdmin ? '✓' : '✕' }
    },
    {
        name: 'Süper Admin',
        selector: "profile.isSuperAdmin",
        sortable: false,
        wrap: true,
        width: '150px',
        center: true,
        cell: (row, index, column, id) => { return row.profile?.isSuperAdmin ? '✓' : '✕' }
    },
    {
        name: 'Güncelle',
        sortable: false,
        width: '100px',
        center: true,
        cell: row => {
            return <UpdateUserModal id={row.id} trigger={
                <button className="btn btn-xl">
                    <i className="fa fa-edit" />
                </button>
            } />
        },
    },
    {
        name: 'Sil',
        sortable: false,
        width: '100px',
        center: true,
        cell: row => {
            return <button onClick={() => {
                Swal.fire({
                    icon: 'warning',
                    text: "Seçtiğiniz kullanıcıyı silmek istiyor musunuz?",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonColor: '#3B4182',
                    confirmButtonText: 'Evet',
                    cancelButtonColor: '#E40426',
                    cancelButtonText: 'Hayır',
                    focusCancel: false,
                    reverseButtons: true,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //const cancellationDescription = window.prompt("İptal nedinini yazınız");
                        let payload = {
                            is_active: false,
                            groups: [],
                            username: row.username + "-" + String(moment().format('DD-MM-YYYY-HH:mm:ss'))
                        }
                        updateUser(row.id, payload)
                            .then(response => {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Kullanıcı Silindi!',
                                    text: response.message,
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,

                                })
                                PubSub.publish('update-users-2', [])
                            }).catch(error => {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Kullanıcı Silinemedi',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                            })
                    }
                })
            }} className="btn btn-xl">
                <i className="fa fa-trash" />
            </button>
        }
    },
    {
        name: <i className="la la-check fs-18 text-center" />,
        selector: 'is_active',
        center: true,
        sortable: false,
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => { return row.is_active ? '✓' : '✕' }
    },
];


export const userGroupsTableColumns = [
    {
        name: 'Grup Adı',
        selector: 'name',
        sortable: true,
        width: '200px',
    },
    {
        name: 'Kullanıcılar',
        selector: 'userList',
        sortable: true,
        width: '600px',
        cell: row => {
            return (
                row.userList.map((item, index) => {
                    if (index < 5) {
                        return (
                            <span key={`item${index}`} className="badge badge-sm light badge-secondary mr-1">{item}</span>
                        )
                    }
                    if (index === 5) {
                        return (
                            <ShowUsersModal row={row} trigger={
                                <button className="btn btn-xl">
                                    <span key={`item${index}`} style={{ cursor: "pointer" }} className="badge badge-sm light badge-secondary mr-1">...</span>
                                </button>
                            } />
                        )
                    }
                })
            )
            //return <button className="btn btn-xl">{row.userList}</button>
        }
    },
    {
        name: 'Güncelle',
        sortable: false,
        center: 'true',
        width: '60px',
        cell: row => {
            return <UpdateUserGroupModal id={row.id} userList={row.user_list} trigger={
                <button className="btn btn-xl">
                    <i className="fa fa-edit" />
                </button>
            } />
        },
    },
    {
        name: 'Sil',
        sortable: false,
        center: 'true',
        width: '60px',
        cell: row => {
            return <button onClick={() => {
                Swal.fire({
                    icon: 'warning',
                    text: "Seçtiğiniz grubu silmek istiyor musunuz?",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonColor: '#3B4182',
                    confirmButtonText: 'Evet',
                    cancelButtonColor: '#E40426',
                    cancelButtonText: 'Hayır',
                    focusCancel: false,
                    reverseButtons: true,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //const cancellationDescription = window.prompt("İptal nedinini yazınız");
                        removeGroups(row.id)
                            .then(response => {
                                if (response && response.detail && response.detail === "Bu işlemi yapmak için izniniz bulunmuyor.") {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Grup yetkiniz olmadığı için silinemedi',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#3B4182',
                                        cancelButtonText: "Tamam",
                                        focusCancel: false,
                                    })
                                } else {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Grup Silindi!',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#3B4182',
                                        cancelButtonText: "Tamam",
                                        focusCancel: false,
                                    })
                                }

                                PubSub.publish('group-delete-reload', {})
                            }).catch(error => {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Grup yetkiniz olmadığı için silinemedi',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                            })
                    }
                })
            }} className="btn btn-xl">
                <i className="fa fa-trash" />
            </button>
        }
    },
    {
        name: <i className="la la-check fs-18 " />,
        selector: 'is_active',
        center: 'true',
        sortable: false,
        width: '60px',

        cell: (row, index, column, id) => {
            return row.is_active ? '✓' : '✕'
        }
    },
];

export const testGroupUserTableColumns = [
    {
        name: 'Ad Soyad',
        selector: 'user.first_name',
        sortable: true,
        width: '200px',
    },
    {
        name: 'Kullanıcı Adı',
        selector: 'user.username',
        sortable: true,
        width: '200px',
    },
    {
        name: 'Unvan',
        selector: 'user.profile.title',
        sortable: true,
        width: '200px',
    },
    {
        name: 'E-Posta Adresi',
        selector: 'user.email',
        sortable: true,
        width: '200px',
    },
    {
        name: 'Departman',
        selector: 'user.profile.department',
        sortable: true,
        width: '200px',
    },
    // {
    //     name: 'Bulunduğu Gruplar',
    //     selector: 'user?.groups',
    //     sortable: false,
    //     wrap: true
    // },
    // {        Tek Bir kullanıcı Test grubu ilişkisi olduğu için güncelle işlemine gerek yok.
    //     name: 'Güncelle',
    //     sortable: false,
    //     width: '100px',
    //     cell: row => {
    //         return <UpdateTestGroupModal id={row.id} trigger={
    //             <button className="btn btn-xl">
    //                 <i className="fa fa-edit" />
    //             </button>
    //         } />
    //     },
    // },
    {
        name: 'Sil',
        sortable: false,
        center: 'true',
        width: '40px',
        cell: row => {
            return <button onClick={() => {
                Swal.fire({
                    icon: 'warning',
                    text: "Seçtiğiniz kullanıcıyı test grubundan silmek istiyor musunuz?",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonColor: '#3B4182',
                    confirmButtonText: 'Evet',
                    cancelButtonColor: '#E40426',
                    cancelButtonText: 'Hayır',
                    focusCancel: false,
                    reverseButtons: true,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //const cancellationDescription = window.prompt("İptal nedinini yazınız");
                        removeTestGroup(row.id)
                            .then(response => {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Kullanıcı Silindi!',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                                PubSub.publish("group-user-reload", {})
                            }).catch(error => {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Kullanıcı Silinemedi',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                                PubSub.publish("group-user-reload", {})

                            })
                    }
                })
            }} className="btn btn-xl">
                <i className="fa fa-trash" />
            </button>
        }
    },
    // {
    //     name: <i className="la la-check fs-18 text-center" />,
    //     selector: 'is_active',
    //     sortable: false,
    //     width: '40px',
    //     minWidth: '40px',
    //     maxWidth: '40px',
    //     cell: (row, index, column, id) => { return row.is_active ? '✓' : '✕' }
    // },
];

export const profileTableColumns = [
    {
        name: 'Ad',
        selector: 'user.first_name',
        sortable: true,
        width: '150px',
    },
    {
        name: 'Soyad',
        selector: 'user.last_name',
        sortable: true,
        width: '150px',
    },
    {
        name: 'Kullanıcı Adı',
        selector: 'user.username',
        sortable: true,
        width: '150px',
    },
    {
        name: 'E-Posta Adresi',
        selector: 'user.email',
        sortable: true,
        width: '250px',
    },
    {
        name: 'Content Admin',
        selector: 'user.profile.isContentAdmin',
        sortable: true,
        width: '100px',
        cell: (row, index, column, id) => { return row.isContentAdmin ? '✓' : '✕' }
    },
    {
        name: 'System Admin',
        selector: 'user.profile.isSystemAdmin',
        sortable: true,
        width: '100px',
        cell: (row, index, column, id) => { return row.isSystemAdmin ? '✓' : '✕' }
    },
    {
        name: 'Güncelle',
        sortable: false,
        width: '100px',
        cell: row => {
            return <UpdateProfileModal profile={row} trigger={
                <button className="btn btn-xl">
                    <i className="fa fa-edit" />
                </button>
            } />
        },
    },
    {
        name: <i className="la la-check fs-18 text-center" />,
        selector: 'is_active',
        sortable: false,
        width: '40px',
        minWidth: '40px',
        maxWidth: '40px',
        cell: (row, index, column, id) => { return row.is_active ? '✓' : '✕' }
    },
];


export const calenderTableColumns = [
    {
        name: 'Tarih',
        selector: 'date',
        sortable: true,
        width: '150px',
        cell: row => {
            return moment(row.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
        }
    },
    {
        name: 'Açıklama',
        selector: 'description',
        sortable: true,
        width: '600px',
    },
    {
        name: 'Sil',
        sortable: false,
        center: true,
        width: '100px',
        cell: row => {
            return <button onClick={() => {
                Swal.fire({
                    icon: 'warning',
                    text: "Seçtiğiniz tarihi silmek istiyor musunuz?",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonColor: '#3B4182',
                    confirmButtonText: 'Evet',
                    cancelButtonColor: '#E40426',
                    cancelButtonText: 'Hayır',
                    focusCancel: false,
                    reverseButtons: true,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        removeCalenderDate(row.id)
                            .then(response => {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Tarih Silindi!',
                                    text: response.message,
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                                window.location.reload()
                            }).catch(error => {
                                PubSub.publish('reload-calender', {})
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Tarih Silindi',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })

                            })
                    }
                })
            }} className="btn btn-xl">
                <i className="fa fa-trash" />
            </button>
        }
    },
];

export const documentTableColumns = [
    {
        name: 'Adı',
        selector: 'documentName',
        sortable: true,
        width: '200px',
    },
    {
        name: 'Amacı',
        selector: 'description',
        wrap: true,
        sortable: false,
        width: '300px',
    },
    {
        name: 'İndir',
        sortable: false,
        width: '70px',
        cell: row => {
            return <button className="btn btn-xl" style={{ marginLeft: "-10px" }}>
                <a href={row.documentFile.url} target="_blank" download>
                    <i className="fa fa-download fs-20" />
                </a>
            </button>
        }
    },
    {
        name: 'Versiyon',
        selector: row => row.version === null ? 1.00.toFixed(2) : row.version.toFixed(2),
        sortable: true,
        width: '80px',
    },
    {
        name: 'Yayımlanma Tarihi',
        selector: row => moment(row.updated).format('DD/MM/YYYY'),
        sortable: true,
        width: '120px',
    },
    {
        name: 'Departman',
        selector: 'department',
        sortable: false,
        width: '150px',
    },
    {
        name: 'İlgili',
        selector: 'updatedby',
        sortable: false,
        width: '120px',
    },
    {
        name: 'Güncelle',
        sortable: false,
        center: true,
        width: '50px',
        cell: row => {
            return <UpdateDocumentModal id={row.id} trigger={
                <button className="btn btn-xl">
                    <i className="fa fa-edit" />
                </button>
            } />
        },
    },
    {
        name: 'Sil',
        sortable: false,
        center: true,
        width: '50px',
        cell: row => {
            return <button onClick={() => {
                Swal.fire({
                    icon: 'warning',
                    text: "Seçtiğiniz dökümanı silmek istiyor musunuz?",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonColor: '#3B4182',
                    confirmButtonText: 'Evet',
                    cancelButtonColor: '#E40426',
                    cancelButtonText: 'Hayır',
                    focusCancel: false,
                    reverseButtons: true,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        let payload = {
                            documentStatus: false
                        }
                        updateDocument(row.id, payload)
                            .then(response => {
                                PubSub.publish('load-documents', [])
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Döküman Silindi!',
                                    text: response.message,
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                            }).catch(error => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Döküman Silinemedi',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                            })
                    }
                })
            }} className="btn btn-xl">
                <i className="fa fa-trash" />
            </button>
        }
    },
    {
        name: 'Durum',
        sortable: false,
        width: '150px',
        cell: row => {
            return <button className="btn btn-xl" style={{ color: row.documentStatus ? "green" : "red" }}>
                {row.documentStatus ? <i className="fa fa-check fs-20" /> : <i className="fa fa-times fs-20" />}
            </button>
        }
    },
];

export const documentTableArchiveColumns = [
    {
        name: 'Versiyon',
        selector: row => row.version === null ? 1.00.toFixed(2) : row.version.toFixed(2),
        sortable: true,
        width: '80px',
    },
    {
        name: 'Adı',
        selector: 'documentName',
        sortable: true,
        width: '200px',
    },
    {
        name: 'Amacı',
        selector: 'description',
        wrap: true,
        sortable: false,
        width: '300px',
    },
    {
        name: 'İndir',
        sortable: false,
        width: '70px',
        cell: row => {
            return <button className="btn btn-xl" style={{ marginLeft: "-10px" }}>
                <a href={row.documentFile.url} target="_blank" download>
                    <i className="fa fa-download fs-20" />
                </a>
            </button>
        }
    },
    {
        name: 'Yayınlanma Tarihi',
        selector: row => moment(row.updated).format('DD/MM/YYYY'),
        sortable: true,
        width: '120px',
    },
    {
        name: 'Revizyon Sorumlusu',
        selector: 'updatedby',
        sortable: false,
        width: '120px',
    },
    {
        name: 'Departman',
        selector: 'department',
        sortable: false,
        width: '150px',
    },
    {
        name: 'Güncelle',
        sortable: false,
        center: true,
        width: '50px',
        cell: row => {
            return <UpdateDocumentModal id={row.id} trigger={
                <button className="btn btn-xl">
                    <i className="fa fa-edit" />
                </button>
            } />
        },
    },
    {
        name: 'Geri Al',
        sortable: false,
        center: true,
        width: '80px',
        cell: row => {
            return <button onClick={() => {
                Swal.fire({
                    icon: 'warning',
                    text: "Seçtiğiniz dökümanı geri almak istiyor musunuz?",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonColor: '#3B4182',
                    confirmButtonText: 'Evet',
                    cancelButtonColor: '#E40426',
                    cancelButtonText: 'Hayır',
                    focusCancel: false,
                    reverseButtons: true,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        let payload = {
                            documentStatus: true
                        }
                        updateDocument(row.id, payload)
                            .then(response => {
                                PubSub.publish('load-documents', [])
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Döküman geri alındı!',
                                    text: response.message,
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                            }).catch(error => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Döküman geri alınamadı',
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonColor: '#3B4182',
                                    cancelButtonText: "Tamam",
                                    focusCancel: false,
                                })
                            })
                    }
                })
            }} className="btn btn-xl">
                <i className="fa fa-undo" />
            </button>
        }
    },
    {
        name: 'Durum',
        sortable: false,
        width: '150px',
        cell: row => {
            return <button className="btn btn-xl" style={{ color: row.documentStatus ? "green" : "red" }}>
                {row.documentStatus ? <i className="fa fa-check fs-20" /> : <i className="fa fa-times fs-20" />}
            </button>
        }
    },
];