import React from 'react';
import Marquee from 'react-double-marquee';
import parse from "html-react-parser";
// import Marquee from 'react-fast-marquee';


class PreviewTicker extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        let speedo;

        if (this.props.speed === 1) { speedo = 0.02; }
        if (this.props.speed === 2) { speedo = 0.05; }
        if (this.props.speed === 3) { speedo = 0.9; }

        return (
            <div style={{ position: 'absolute', width: '100%', fontSize: 24, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', paddingTop: 10, height: 50, [this.props.position]: 0, borderRadius: 8, backgroundColor: `${this.props.bgcolor}` }}>
                <div className="mt-2">
                    <Marquee
                        delay={0}
                        speed={speedo}
                        direction="left">
                        {parse(this.props.tickermessages.join(' '))}
                    </Marquee>
                    {/* <Marquee onFinish={() => alert('ON FINISH')} loop={1} speed={100} onCycleComplete={() => alert('onCycleComplete')}>
                        {parse(this.props.tickermessages.join(' '))}
                    </Marquee> */}
                </div>

            </div>
        )
    }
}

export default PreviewTicker;

/*
<h3
    style={{lineHeight: 1.5, display: 'block', float: 'left', position: 'absolute', marginLeft: 60, wordWrap: 'inherit', wordBreak: 'break-word'}}
    dangerouslySetInnerHTML={{__html: this.props.tickermessages.join(' ') }}/>
 */
