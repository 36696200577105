// Pdf export için kullanılan component.
// DataTables kütüphanesinde tablo oluşturup pdf'i bu tabloya göre düzenliyoruz.


import React, {Component} from 'react';
import 'datatables.net-dt/css/jquery.dataTables.css'

import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import moment from "moment";

import duyuroomIcon from '../../assets/images/duyuroom-erkek-logo.png'
import duyuroomIcon2 from '../../assets/images/Allianz_Logo256x64.png'

// CDN ile import edilen pdfMake kütüphanesini cdn importunu kaldırdıktan sonra 
// Bu şekilde import edip manipüle edersek font hatasından kurtuluyoruz ve
// DataTables PDF çalışır hale geliyor.
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

const $ = require('jquery');
$.DataTable = require('datatables.net-dt');
const jzip = require('jzip');


const columns = [
    {
        title: 'Grup Adı',
        data: 'group'
    },
    {
        title: 'Mecra Adı',
        data: 'type'
    },
    {
        title: 'Gönderi Başlığı',
        data: 'title'
    },
    {
        title: 'Gönderen',
        data: 'sender'
    },
    {
        title: 'İçerik Yöneticisi',
        data: 'announcementCreator'
    },
    {
        title: 'Yayınlanma Tarihi',
        data: 'announcementDate',
        type: 'datetime',
        displayFormat: 'DD.MM.YYYY HH:mm:ss'
    }
];

class GroupReportsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        }

        this.tableAPI = null

    }


    componentDidMount() {
        this.setState({data: this.props.data})
        window.JSZip = jzip

        const companyLogo = duyuroomIcon


        const generalInfo = this.props.generalInfo
        const startDate = this.props.linkData.startDate
        const endDate = this.props.linkData.endDate
        this.tableAPI = $(this.refs.dataTable).DataTable({
            dom: '<"data-table-wrapper"t> Bfrtip',
            searching: false,
            ordering: false,
            paging: false,
            info: false,
            data: this.props.data,
            columns,
            columnDefs:[{targets:[5,6], render:function(data){
                    if(data){
                        return moment(data).format('DD.MM.YYYY HH:mm:ss');
                    }
                    return ''
                }}],
            buttons: [
                {
                    extend: 'excel',
                    text: 'Excel',
                    fileName: "data.xlsx",
                    title: "Grup Etkinlik Raporu",
                    className: 'btn btn-primary btn-sm ml-4'
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    fileName: "rapor.pdf",
                    title: "Grup Etkinlik Raporu",
                    messageTop: null,
                    messageBottom: null,
                    orientation: 'portrait',
                    className: 'btn btn-primary btn-sm',
                    customize: function ( doc ) {
                        doc.content.splice( 1, 0, {
                            columns: [{
                                margin: [ 0, 0, 0, 12 ],
                                alignment: 'left',
                                image: duyuroomIcon2,
                                fit: [64, 64]
                            },{
                                margin: [ 0, 0, 0, 0 ],
                                alignment: 'right',
                                image: duyuroomIcon,
                            },]
                        } );
                        doc.content.splice(2, 0, {
                            text: [{
                                text: 'Rapor Adı: ',
                                bold: true,
                                fontSize: 11
                            }, {
                                text: 'Grup Etkinlik Raporu \n',
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Raporlama Tarihi: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${moment().format('DD.MM.YYYY')} \n`,
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Rapor Tarihi Aralığı: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${moment(startDate).format('DD.MM.YYYY')} / ${moment(endDate).format('DD.MM.YYYY')} \n`,
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Toplam Gönderi Sayısı: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${generalInfo.totalAnnouncementCount} \n`,
                                bold: false,
                                fontSize: 11
                            }, {
                                text: `Görüntüleme Performansı: `,
                                bold: true,
                                fontSize: 11
                            }, {
                                text: `${generalInfo.totalOpenedRate} \n`,
                                bold: false,
                                fontSize: 11
                            }
                            ],
                            margin: [0, 0, 0, 12],
                            alignment: 'left'
                        });
                    }
                },
                {
                    extend: 'csv',
                    text: 'CSV',
                    fileName: "rapor.csv",
                    title: "Grup Etkinlik Raporu",
                    className: 'btn btn-primary btn-sm'
                }
            ]
        });
    }

    componentWillUnmount() {
        $('.report-table')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.tableAPI.clear();
        this.tableAPI.rows.add(this.props.data);
        this.tableAPI.draw();
    }


    render() {
        return (
            <div>
                <table hidden={true} ref="dataTable"/>
            </div>
        );
    }
}

export default GroupReportsTable;