import React, {Component} from 'react';
import * as API from "../../../services/api";
import settings from "../../../config/settings.json";
import {Button} from "semantic-ui-react";
import PubSub from "pubsub-js";
import LoadingOverlay from 'react-loading-overlay';
import Swal from "sweetalert2";

class VideoUrlPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoBlock: [],

            videoUrl1:"",
            videoUrl2:"",
            videoUrl3:"",
            videoUrl4:"",
            videoUrl5:"",

            disableButton: true,
            loading: false
        }

        this.videoFile = null

    }

    componentWillMount() {
        setTimeout(() => {
            const iframe = document.getElementsByClassName('gjs-frame')[0]
            const iWindow = iframe.contentWindow;
            const iDocument = iWindow.document;

            let videoBlock = []
            if(iDocument.getElementById('videoBlock') !== null){
                videoBlock.push('1')
            }
            if(iDocument.getElementById('videoBlock-2') !== null){
                videoBlock.push('2')
            }
            if(iDocument.getElementById('videoBlock-3') !== null){
                videoBlock.push('3')
            }
            if(iDocument.getElementById('videoBlock-4') !== null){
                videoBlock.push('4')
            }
            if(iDocument.getElementById('videoBlock-5') !== null){
                videoBlock.push('5')
            }

            this.setState({videoBlock: videoBlock})

            //
            // iDocument.getElementById('videoBlock').children[0].src = "https://api.duyuroom.com/media/MacBook_Pro_13_Review.mp4"
            // iDocument.getElementById('videoBlock').src = "https://api.duyuroom.com/media/MacBook_Pro_13_Review.mp4"


        },2000)
    }

    getVideoUrl = (event, index) => {
        this.setState({disableButton: true, loading: true})
        let data = new FormData();
        let config = {header: {"Content-Type": "multipart/form-data"}};

        const iframe = document.getElementsByClassName('gjs-frame')[0]
        const iWindow = iframe.contentWindow;
        const iDocument = iWindow.document;

        data.append("file", event.target.files[0], event.target.files[0].name);

        API.uploadFiles(data, config)
            .then(response => {
                let videoUrl = settings.apiBaseClear + response.data.file
                const videoStateName = "videoUrl" + String(index+1)
                this.setState({[videoStateName] : videoUrl})
                if(index == 0){
                    iDocument.getElementById('videoBlock').children[0].src = videoUrl
                } else {
                    iDocument.getElementById(`videoBlock-${index+1}`).children[0].src = videoUrl
                }
                this.setState({disableButton: false, loading: false}, () => {
                    this.handleSave()
                })
            })
            .catch(error => {
                Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                }).fire({
                    icon: 'error',
                    title: 'Video Formatı uygun değil!'
                })
            })

    }

    handleSave = () => {
        let videoUrls = [this.state.videoUrl1, this.state.videoUrl2, this.state.videoUrl3, this.state.videoUrl4, this.state.videoUrl5].filter(e => e)

        PubSub.publish('popup-video-urls', videoUrls)

        Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        }).fire({
            icon: 'success',
            title: 'Video kaydedildi'
        })
    }


    render() {
        const videoUpload = this.state.videoBlock.map((value, index, array) => {
            return (
                <div className="row mt-2"  key={index}>
                    <div className='col-sm-4 d-flex justify-content-center'>
                        <label className='mt-4' style={{marginLeft: 10}}>Video {index+1}: </label>
                    </div>
                    <div className='col-sm-8'>
                        <button className="btn mt-2 ml-2" style={{
                            marginLeft: -10,
                            marginRight: 10,
                            border: "1px solid #f3f3f3",
                            borderRadius: 20,
                            height: 40,
                            width: 200
                        }} onClick={() => {
                            this["uploadBtn"+String(index+1)].click();
                        }}>
                            <i className="fa fa-upload"/> Video Yükle
                        </button>
                        {this.state["videoName"+String(index+1)] &&
                            <h4><i className="fa fa-file-video-o"/> {this.state["videoName"+String(index+1)]}</h4>
                        }
                        <input
                            name="videoFile"
                            id={`videoFile${index}`}
                            type='file'
                            accept="video/mp4,video/x-m4v,video/*"
                            ref={(ref) => this["uploadBtn"+String(index+1)] = ref}
                            style={{display: 'none'}}
                            onChange={(event) => {
                                if(event.target.files[0].type.slice(0,5) !== "video"){
                                    Swal.fire({
                                        icon: 'warning',
                                        text: 'Lütfen video yükleyiniz!',
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonColor: '#3B4182',
                                        cancelButtonText: "Tamam",
                                        focusCancel: false,
                                    })
                                    return;
                                }
                                this.getVideoUrl(event, index)
                                this.setState({["videoName"+String(index+1)]: event.target.files[0]?.name})
                                // this.videoName = event.target?.files[0]
                            }}
                        />
                    </div>
                </div>
            )
        })


        return (
            <div>
                <LoadingOverlay
                    active={this.state.loading}
                    // spinner
                    text="Yükleniyor..."
                    styles={{ wrapper: { width: "100%", height: "100%" } }}
                >
                    {videoUpload}
                </LoadingOverlay>
                {/*<div className="row mt-3">*/}
                {/*    <div className="col-sm-10 offset-1">*/}
                {/*        <Button className='btn btn-send btn-rounded btn-md btn-block'*/}
                {/*                color='default' icon style={{width: "100%"}}*/}
                {/*                disabled={this.state.disableButton}*/}
                {/*                onClick={this.handleSave}>*/}
                {/*            Videoyu kaydet*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default VideoUrlPage;