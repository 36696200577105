import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const MultiSelectAll = (props) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (props.allGroups) setSelectedOptions([{ label: "Tümü", value: "*" }, ...props.options]);
    }, []);

    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: Tümü`;
        } else if (value.length === 0) {
            return "Seçiniz";
        } else {
            return `${placeholderButtonLabel}: ${value.length} ${props.itemTitle}`;
        }
    }

    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }

        props.onChange(value);
    }

    return (
        <ReactMultiSelectCheckboxes
            options={[{ label: "Tümü", value: "*" }, ...props.options]}
            placeholderButtonLabel="Seçilen"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptions}
            onChange={onChange}
            setState={setSelectedOptions}
            width="100%"
        />
    );
};

export default MultiSelectAll;