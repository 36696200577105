import React from "react";
import { Button, Header, Label, Form, Modal, Grid, Checkbox, Icon } from "semantic-ui-react";
import { Formik, ErrorMessage } from "formik";
import { Form as FormikForm } from "formik";
import tr from "date-fns/locale/tr";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import MultiSelectAll from "../../../components/forms/MultiSelectAll";
import { Tooltip } from "@trendmicro/react-tooltip";
import InitialMediaModel from "../../../models/constants/InitialMediaModel";
import * as E from "../../../models/enums/Common";
import MediaSchema from "../../../models/validations/MediaSettingsValidations";
import * as API from "../../../services/api";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { setChonkyDefaults, FullFileBrowser, ChonkyActions, defineFileAction } from "chonky";
import { turkishI18n } from "../../../helpers/Language";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import settings from "../../../config/settings.json";
import PubSub from "pubsub-js";
import MediaSettingsValidations from "../../../models/validations/MediaSettingsValidations";
import Swal from "sweetalert2";

class MediaSettings extends React.Component {
    uploadInput = null;

    state = {
        senderCollapse: false,
        sentDateCollapse: false,
        sentDeviceCollapse: false,
        quizCollapse: false,
        targetCollapse: false,
        windowSizeCollapse: false,
        sentTypeCollapse: false,
        snoozeCollapse: false,
        fileCollapse: false,
        sendClicked: false,

        groupsError: false,
        devicesError: false,

        groupsOnChangeTriggered: false
    };

    constructor(props) {
        super(props);

        setChonkyDefaults({ iconComponent: ChonkyIconFA });

        this.state = Object.assign(this.state, InitialMediaModel, this.props.model || {});

        this.collapseHeader = {
            backgroundColor: "#3B4182",
            height: "38px",
            padding: "9px 10px 9px 20px",
            letterSpacing: "1px",
            color: "white",
            cursor: "pointer"
        }
        this.contentCss = { padding: 16 }
    }

    componentDidMount() {
        this.loadData();

        PubSub.subscribe('sendAgainPage', this.sendAgainPage)
    }

    sendAgainPage = (msg, data) => {
        console.log(data)
        let tempData = data
        if (data.folder.id) {
            tempData.folder = data.folder.id
        } else {
            tempData.folder = data.folder
        }

        const state = Object.assign({ ...this.state, ...tempData });
        this.setState({ ...state });
    }

    onSubmit = (values, actions) => {
        if (values.groups.length === 11 && values.isAllGroups === false) {
            this.setState({ groupsError: true })
        }
        if (values.devices.mobile === false && values.devices.desktop === false) {
            this.setState({ devicesError: true })
        }
        if (!this.state.groupsError && !this.state.devicesError) {
            if (values.devices.desktop && values.devices.mobile) values.deliveryOption = E.DeliveryOptionTypes.ALL;
            else if (values.devices.desktop) values.deliveryOption = E.DeliveryOptionTypes.DESKTOP;
            else if (values.devices.mobile) values.deliveryOption = E.DeliveryOptionTypes.MOBILE;

            if (values.isAllGroups) {
                values.groups = this.state.groups?.map((g) => {
                    return g.id;
                });
            }
            values.isAllGroups = (values.groups.length === this.state.groups.length)

            if (values.times && values.times.date && values.times.time) {
                values.startDate = moment(moment(values.times.date).format("YYYY-MM-DD") + " " + values.times.time.format("HH:mm")).format("YYYY-MM-DD HH:mm:00.0");
            }
            // if(!values.startDate){
            //     values.startDate = moment(moment().format("YYYY-MM-DD") + " " + moment().format("HH:mm")).format("YYYY-MM-DD HH:mm:00.0")
            // }

            if (values.startDate && values.isPublished == E.PublishStatus.SENT) {
                values.isPublished = E.PublishStatus.SENT;
            }

            values.snoozeTime = values.snoozeTime || 0;
            values.snoozeCount = values.snoozeCount || 0;
            values.files = this.state.files;
            if (values.type === "TICKER") {
                values.deliveryOption = E.DeliveryOptionTypes.DESKTOP
            }


            this.state = Object.assign(this.state, values);
            window.scrollTo(0, 0)
            this.props.getValues(values)

            PubSub.publish(("canvas_" + values.type + ".save").toLowerCase(), values);
        }
    };

    async loadData() {
        const [groups] = await Promise.all([API.getGroups()]);

        this.setState({ groups });
    }

    handleFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let selectedFile = event.target.files[0];

        this.handleFileUpload(selectedFile);
    };

    handleFileBrowserAction = (event) => {
        if (event.id === "upload_files") {
            this.uploadInput.click();
        }

        if (event.id === "delete_files") {
            let allFiles = [...this.state.files];
            let newFiles = allFiles.filter((i) => !event.state.selectedFiles.some((j) => j.id === i.id));
            this.setState({ files: newFiles });
        }
    };

    handleFileUpload = (file) => {
        let data = new FormData();
        let config = { header: { "Content-Type": "multipart/form-data" } };

        data.append("file", file, file.name);

        API.uploadFiles(data, config)
            .then((response) => {
                let uploadedFile = {
                    id: response.data.id,
                    name: file.name,
                    url: settings.apiBaseClear + response.data.file,
                    thumbnailUrl: settings.apiBaseClear + response.data.file,
                    isThumbnail: false,
                    isVideo: false,
                    color: "#3B4182",
                    icon: "image",
                    type: file.type,
                };

                let allFiles = [...this.state.files.filter((f) => f !== null)];

                allFiles.push(uploadedFile);

                this.setState({ files: allFiles });
                PubSub.publish(("canvas_" + this.props.model.type + ".update").toLowerCase(), this.state);
                Swal.fire({
                    icon: 'success',
                    text: 'Dosya Yüklendi!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    text: 'Dosya yüklenirken bir hata oluştu.',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
            });
    };

    render() {
        const model = Object.assign({}, this.state);
        if (this.props.type === "TICKER") {
            model.type = "TICKER"
        }



        return (
            <Formik enableReinitialize={true} initialValues={model} validationSchema={MediaSettingsValidations}
                onSubmit={this.onSubmit}>
                {(props) => {
                    let sendClicked = false
                    let groupsOnChangeTriggered = false
                    const handleGroupChange = (value) => {
                        groupsOnChangeTriggered = value
                    }
                    const handleSendClicked = () => {

                    }
                    const handleChange = (e, { name, value }) => props.setFieldValue(name, value);
                    const handleValue = (name, value) => {
                        if (this.props.model?.type === "EMERGENCY" || this.props.model?.type === "LOCK" || this.props.model?.type === "WALLPAPER" || this.props.model?.type === "SCREENSAVER") {
                            props.setFieldValue('notificationType', 'DIRECT')
                        }
                        props.setFieldValue(name, value);
                    };
                    return (
                        <FormikForm className='ui form' style={this.contentCss}>
                            {/*<div style={this.collapseHeader} onClick={()=>this.setState({senderCollapse:!this.state.senderCollapse})}>*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="col-sm-12">*/}
                            {/*            {this.state.senderCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Gönderen</span>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <Form.Input label='Gönderen' placeholder='Örneğin; İnsan Kaynakları' name='sender'
                                onChange={props.handleChange} onBlur={props.handleBlur}
                                value={props.values.sender}
                                className={props.errors.sender && props.touched.sender ? "error" : ""} />


                            {this.props.model?.type !== "EMERGENCY" ? (
                                <>
                                    {/*<div style={this.collapseHeader} onClick={()=>this.setState({sentDateCollapse:!this.state.sentDateCollapse})}>*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            {this.state.sentDateCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Gönderim Zamanı</span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className='field'>
                                        <Grid padded>
                                            <Grid.Row columns={2} divided='horizantally'>
                                                <Grid.Column textAlign='left' verticalAlign='top'>
                                                    <div className='field'>
                                                        <label>Gönderim Tarihi*</label>
                                                        <DatePicker placeholderText='Bugün'
                                                            selected={props.values.times.date}
                                                            isClearable={true} minDate={new Date()}
                                                            showTimeSelect={false} timeFormat='H:mm'
                                                            timeIntervals={15} locale={tr}
                                                            dateFormat='dd.MM.yyyy'
                                                            onChange={(value) => handleValue("times.date", value)} />
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column textAlign='left' verticalAlign='top'>
                                                    <div className='field'>
                                                        <label>Gönderim Saati</label>
                                                        <TimePicker style={{ padding: 2 }} placeholder='Hemen'
                                                            className='form-select' showSecond={false}
                                                            minuteStep={15} clearIcon={<button type='button'
                                                                className='react-datepicker__close-icon'
                                                                tabIndex='-1' />}
                                                            value={props.values.times.time}
                                                            onChange={(value) => handleValue("times.time", value)} />
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </div>


                                </>

                            ) : null}
                            { // ALANLARIN SERVİS KARŞILIĞI BİLİNMEDİĞİ İÇİN DEĞERLERİ AYARLANMADI
                                this.props.model?.type === "LOCK" || this.props.model?.type === "WALLPAPER" || this.props.model?.type === "SCREENSAVER" && false ?
                                    <div className='field'>
                                        <Grid padded>
                                            <Grid.Row columns={2} divided='horizantally'>
                                                <Grid.Column textAlign='left' verticalAlign='top'>
                                                    <div className='field'>
                                                        <label>Başlangıç Tarihi*</label>
                                                        <DatePicker placeholderText='Bugün'
                                                            selected={props.values.times.date}
                                                            isClearable={true} minDate={new Date()}
                                                            showTimeSelect={false} timeFormat='H:mm'
                                                            timeIntervals={15} locale={tr}
                                                            dateFormat='dd.MM.yyyy'
                                                            onChange={(value) => handleValue("times.date", value)} />
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column textAlign='left' verticalAlign='top'>
                                                    <div className='field'>
                                                        <label>Bitiş Tarihi*</label>
                                                        <DatePicker placeholderText='Bugün'
                                                            selected={props.values.times.date}
                                                            isClearable={true} minDate={new Date()}
                                                            showTimeSelect={false} timeFormat='H:mm'
                                                            timeIntervals={15} locale={tr}
                                                            dateFormat='dd.MM.yyyy'
                                                            onChange={(value) => handleValue("times.date", value)} />
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </div> : null
                            }
                            {/*<div style={this.collapseHeader} onClick={()=>this.setState({sentDeviceCollapse:!this.state.sentDeviceCollapse})}>*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="col-sm-12">*/}
                            {/*            {this.state.sentDeviceCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Gönderim Cihaz Tipi</span>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {this.props.type !== "TICKER" ?
                                <div className='field'>
                                    <label style={{ color: this.state.devicesError ? "#9f3a38" : "black" }}>Gönderilecek Cihaz
                                        Tipi {this.state.devicesError &&
                                            <span style={{ color: "#9f3a38" }}> (Bu alan zorunludur !)</span>}</label>
                                    {(this.props.model?.type !== "LOCK" && this.props.model?.type !== "WALLPAPER" && this.props.model?.type !== "SCREENSAVER") ? (
                                        <>
                                            <Checkbox label='Mobil Cihazlar' checked={props.values.devices.mobile}
                                                onChange={() => handleValue("devices.mobile", !props.values.devices.mobile)} />
                                            <br />
                                        </>
                                    ) : null}
                                    <Checkbox label='Bilgisayarlar' checked={props.values.devices.desktop}
                                        onChange={() => handleValue("devices.desktop", !props.values.devices.desktop)} />
                                </div>
                                :
                                null}



                            {this.props.mediaType === "/publish/quiz" || this.props.type === "ELEARNING" ? (
                                <>
                                    {/*<div style={this.collapseHeader} onClick={()=>this.setState({quizCollapse:!this.state.quizCollapse})}>*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            {this.state.quizCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Quiz Seçenekleri</span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className='field'>
                                        <label>Quiz sonunda doğru cevaplar gösterilsin</label>
                                        <Checkbox label='Evet' checked={props.values.showAnswers}
                                            onChange={() => handleValue("showAnswers", !props.values.showAnswers)} />
                                    </div>

                                </>

                            ) : null}

                            {/*<div style={this.collapseHeader} onClick={()=>this.setState({targetCollapse:!this.state.targetCollapse})}>*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="col-sm-12">*/}
                            {/*            {this.state.targetCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Hedef Kitle</span>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={"field " + (props.errors.groups && props.touched.groups ? "error" : "")}>
                                <label style={{ color: (this.state.groupsError && !props.values.groupsOnChangeTriggered) ? "#9f3a38" : "black" }}>Hedef Kitle {this.state.groupsError && !props.values.groupsOnChangeTriggered &&
                                    <span style={{ color: "#9f3a38" }}> (Bu alan zorunludur !)</span>}</label>
                                <ErrorMessage name='groups' />
                                <MultiSelectAll
                                    itemTitle='Grup'
                                    name='groups'
                                    allGroups={this.props.model?.type === "EMERGENCY" ? true : props.values.isAllGroups}
                                    onChange={(values) => {
                                        console.log('values : ', values)
                                        handleValue("groupsOnChangeTriggered", true)
                                        props.setFieldValue("isAllGroups", false);
                                        if (values && values.length > 0) {
                                            props.setFieldValue(
                                                "groups",
                                                values.map((m) => {
                                                    return m.value;
                                                })
                                            );
                                            if (values.filter((m) => m.value === "*").length > 0) {
                                                props.setFieldValue(
                                                    "groups",
                                                    this.state.groups?.map((m) => {
                                                        return m.id;
                                                    })
                                                );
                                                props.setFieldValue("isAllGroups", true);
                                            }
                                        }
                                    }}
                                    options={this.state.groups?.map((m) => {
                                        return { value: m?.id, label: m?.name };
                                    })}
                                    className={props.errors.groups && props.touched.groups ? "error" : ""}
                                />
                            </div>
                            {
                                this.props.model?.type !== "EMERGENCY" ?
                                    <>
                                        <div className='field'>
                                            <label>Kişi Ekle</label>
                                            <ErrorMessage name='includeUser' />
                                            <AsyncSelect
                                                isMulti={true}
                                                isClearable={true}
                                                loadingMessage={() => <span>Yükleniyor</span>}
                                                noOptionsMessage={() => <span>Henüz kimseyi seçmediniz</span>}
                                                onChange={(values) => handleValue("includeUser", values)}
                                                placeholder='Seçiniz'
                                                defaultOptions={[]}
                                                loadOptions={(inputValue) => {
                                                    return API.searchUser(inputValue).then((list) => {
                                                        return list.map((u) => {
                                                            return {
                                                                value: u.id,
                                                                label: u.first_name + " " + u.last_name
                                                            };
                                                        });
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className='field'>
                                            <label>Kişi Çıkart</label>
                                            <ErrorMessage name='excludeUser' />
                                            <AsyncSelect
                                                isMulti={true}
                                                isClearable={true}
                                                loadingMessage={() => <span>Yükleniyor</span>}
                                                noOptionsMessage={() => <span>Henüz kimseyi seçmediniz</span>}
                                                onChange={(values) => handleValue("excludeUser", values)}
                                                placeholder='Seçiniz'
                                                defaultOptions={[]}
                                                loadOptions={(inputValue) => {
                                                    return API.searchUser(inputValue).then((list) => {
                                                        return list.map((u) => {
                                                            return {
                                                                value: u.id,
                                                                label: u.first_name + " " + u.last_name
                                                            };
                                                        });
                                                    });
                                                }}
                                            />
                                        </div>
                                    </> : null
                            }


                            {this.props.type !== "TICKER" && this.props.model?.type !== "EMERGENCY" ? (
                                <>
                                    {/*<div style={this.collapseHeader} onClick={()=>this.setState({windowSizeCollapse:!this.state.windowSizeCollapse})}>*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            {this.state.windowSizeCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Pencere Boyutu</span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <Form.Select
                                        fluid
                                        label='Pencere Boyutu'
                                        name='windowSize'
                                        onChange={handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.windowSize}
                                        options={[
                                            { text: "Normal", value: "NORMAL" },
                                            { text: "Ekranı Kaplayan", value: "MAXIMIZED" },
                                        ]}
                                        placeholder='Seçiniz..'
                                        className={props.errors.windowSize && props.touched.windowSize ? "error" : ""}
                                    />

                                </>

                            ) : null}
                            {this.props.type === "TICKER" ? <>
                                <Form.Select
                                    fluid
                                    label='Tekrar Sayısı'
                                    name='repeatCount'
                                    onChange={handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.repeatCount}
                                    options={[
                                        { text: "1 Tekrar", value: 1 },
                                        { text: "2 Tekrar", value: 2 },
                                        { text: "3 Tekrar", value: 3 },
                                        { text: "4 Tekrar", value: 4 },
                                        { text: "5 Tekrar", value: 5 },

                                    ]}
                                    placeholder='Seçiniz'
                                    className={props.errors.repeatCount && props.touched.repeatCount ? "error" : ""}
                                />
                            </> : null}
                            {(this.props.model?.type !== "EMERGENCY" && this.props.model?.type !== "LOCK" && this.props.model?.type !== "WALLPAPER" && this.props.model?.type !== "SCREENSAVER") ? (
                                <>
                                    {/*<div style={this.collapseHeader} onClick={()=>this.setState({sentTypeCollapse:!this.state.sentTypeCollapse})}>*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            {this.state.sentTypeCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Gönderi Tipi</span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <Form.Select
                                        fluid
                                        label='Gönderi Tipi'
                                        name='notificationType'
                                        onChange={handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.notificationType}
                                        options={[
                                            { text: "Bildirim", value: "POPUP" },
                                            { text: "Doğrudan", value: "DIRECT" },
                                            { text: "Sessiz", value: "FLASHING" },
                                        ]}
                                        placeholder='Seçiniz..'
                                        className={props.errors.notificationType && props.touched.notificationType ? "error" : ""}
                                    />

                                </>

                            ) : null}

                            {this.props.model?.type !== "EMERGENCY" ? props.values.notificationType == "POPUP" && (
                                <>
                                    {/*<div style={this.collapseHeader} onClick={()=>this.setState({snoozeCollapse:!this.state.snoozeCollapse})}>*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            {this.state.snoozeCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Gönderi Öteleme</span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className='field'>
                                        <label>Öteleme Süresi ve Sayısı</label>

                                        <label htmlFor='snoozeEnabled'>
                                            {/*<Checkbox*/}
                                            {/*    checked={props.values.snoozeEnabled}*/}
                                            {/*    onChange={() => {*/}
                                            {/*        const newValue = !props.values.snoozeEnabled;*/}
                                            {/*        handleValue("snoozeEnabled", newValue);*/}
                                            {/*        if (!newValue) {*/}
                                            {/*            handleValue("snoozeTime", "");*/}
                                            {/*            handleValue("snoozeCount", "");*/}
                                            {/*        } else {*/}
                                            {/*            document.getElementById("snoozeTime").focus();*/}
                                            {/*        }*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            <input
                                                name='snoozeTime'
                                                id='snoozeTime'
                                                // disabled={!props.values.snoozeEnabled}
                                                value={props.values.snoozeTime}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                style={{
                                                    height: 22,
                                                    width: 45,
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    cursor: "text",
                                                }}
                                            />
                                            dakika arayla en fazla
                                            <input
                                                name='snoozeCount'
                                                //disabled={!props.values.snoozeEnabled}
                                                value={props.values.snoozeCount}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                style={{
                                                    height: 22,
                                                    width: 45,
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    cursor: "text",
                                                }}
                                            />
                                            kez ötelenebilir.
                                        </label>
                                    </div>

                                </>

                            ) : null}
                            {this.props.type !== "TICKER" ? (this.props.model?.type !== "EMERGENCY" && this.props.model?.type !== "LOCK" && this.props.model?.type !== "WALLPAPER" && this.props.model?.type !== "SCREENSAVER") && (
                                <>
                                    {/*<div style={this.collapseHeader} onClick={()=>this.setState({fileCollapse:!this.state.fileCollapse})}>*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-sm-12">*/}
                                    {/*            {this.state.fileCollapse?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Dosya Ayarları</span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className='field'>
                                        <input type='file' ref={(ref) => (this.uploadInput = ref)}
                                            style={{ display: "none" }} onChange={this.handleFileChange} />
                                        <FullFileBrowser i18n={turkishI18n.folders} files={this.state.files}
                                            folderChain={[]}
                                            fileActions={[!this.state.isUploading ? ChonkyActions.UploadFiles : null, ChonkyActions.DeleteFiles]}
                                            disableDragAndDrop={true} disableDefaultFileActions={true}
                                            onFileAction={this.handleFileBrowserAction} />
                                    </div>

                                </>

                            ) : null}

                            <div className='field mt-5'>
                                <Button className='btn btn-send btn-rounded btn-md btn-block' type='submit'
                                    color='default' icon style={{ width: "100%" }}
                                    disabled={this.props.model?.type === "EMERGENCY" ? false : !props.values.groupsOnChangeTriggered}
                                    onClick={() => handleValue("isPublished", E.PublishStatus.DRAFT)}>
                                    <i className="flaticon-381-save " />
                                    &nbsp; Taslak Olarak Kaydet
                                </Button>
                            </div>

                            <div className='field'>
                                <Button className='btn btn-send btn-rounded btn-md btn-block' type='submit'
                                    color='default' icon style={{ width: "100%" }}
                                    disabled={this.props.model?.type === "EMERGENCY" ? false : !props.values.groupsOnChangeTriggered}
                                    onClick={() => handleValue("isPublished", E.PublishStatus.TEST)}>
                                    <i className="flaticon-381-send-1" />
                                    &nbsp; Test Grubuna Paylaş
                                </Button>
                            </div>
                            <div className='field'>
                                <Button
                                    className='btn btn-send btn-rounded btn-md btn-block'
                                    type='submit'
                                    color='default'
                                    icon
                                    style={{ width: "100%" }}
                                    disabled={this.props.model?.type === "EMERGENCY" ? false : !props.values.groupsOnChangeTriggered}
                                    // onClick={() => handleValue('isPublished', E.PublishStatus.SENT)}
                                    //asıl çalışan fonksiyon yukarıdakidir aşağıdaki fonksiyon loading ekranı içindir
                                    onClick={() => {
                                        handleValue('isPublished', E.PublishStatus.SENT)
                                    }}
                                >
                                    <i className="flaticon-381-send-2" />
                                    &nbsp;Paylaş
                                </Button>
                            </div>

                            {/*<div className="field">*/}
                            {/*    <Button type='reset' icon style={{ width: '100%' }} onClick={props.handleReset}>*/}
                            {/*        <Icon name='cancel' />*/}
                            {/*        &nbsp;*/}
                            {/*        Temizle*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </FormikForm>
                    );
                }}
            </Formik>
        );
    }
}

export default MediaSettings;
