import React from "react";

export const MenuIcon = (props) => {
    return (
        <div style={{overflow: 'visible', width: 80, height: 30}}>
            <div style={{width: 3, height: 52, borderRadius: 2, backgroundColor: '#F4C752', left: 0, top: 12, position: 'absolute', visibility: props.visible}}/>
            <img src={`/images/menu/menu_icon_${props.icon}.svg`}/>
            <small style={{fontSize: 9, position: 'absolute', width: 70, height: 12, left: 5, top: 40}}>{props.title}</small>
        </div>
    )
}
