import axios from 'axios';
import CustomStorage from "../models/CustomStorage";
import config from "../config/settings.json"

const base = config.apiBase;

export const protectedApi = axios.create({
    baseURL: base
});

export const publicApi = axios.create({
    baseURL: base
});


protectedApi.interceptors.request.use(function (config) {
    const user = JSON.parse(new CustomStorage().getItem('user'));
    config.headers.Authorization =  'Bearer ' + user.access_token;

    return config;
});
