import React from 'react';
import PubSub from 'pubsub-js';
import { Editor } from '@tinymce/tinymce-react';
import { withRouter, Prompt } from "react-router-dom";
import { Picker } from 'emoji-mart';
import { getFolders, getGroups, sendMessage, getAnnouncement, searchUser } from "../../../services/api";
import { Tooltip } from '@trendmicro/react-tooltip';
import { mediaActions, deliveryOptionsTypes } from "../../../helpers/LayoutHelper";
import { MessageTypes } from "../../../models/Message";
import { setPageTitle } from "../../../helpers/LayoutHelper";
import MultiSelectAll from "../../../components/forms/MultiSelectAll";
import parse from "html-react-parser";
import tr from 'date-fns/locale/tr';
import moment from "moment";
import DatePicker from "react-datepicker";
import 'pure-react-carousel/dist/react-carousel.es.css';
import LoadingOverlay from 'react-loading-overlay';
import { toast, ToastContainer } from "react-toastify";
import { protectedApi } from "../../../services/axios";
import PreviewDevice from "../../../components/common/PreviewDevice";
import PreviewWindow from "../../../components/common/PreviewWindow";
import PreviewApp from "../../../components/common/PreviewApp";
import TimePicker from "rc-time-picker";
import CustomStorage from "../../../models/CustomStorage";
import { setChonkyDefaults, FullFileBrowser, ChonkyActions, defineFileAction } from 'chonky';
import { turkishI18n } from "../../../helpers/Language";
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import AsyncSelect from 'react-select/async';
import settings from "../../../config/settings.json";
import '../../../assets/css/popup.form.css';

const pageState = {
    hasChanges: false,
    hasTitleChanged: false,
    id: 0,
    sending: false,
    media: null,
    mediaNamespace: null,
    mediaType: 'POPUP',
    action: 'add',
    showSettings: false,
    sendDate: null,
    sendTime: null,
    devices: { mobile: true, desktop: true },
    folderOptions: [],
    groupOptions: [],
    emojiPickerShow: false,
    messageTitle: '',
    notifyType: null,
    folder: '',
    content: '',
    sender: '',
    showError: false,
    showMessages: '',
    isAllGroups: false,
    groups: [],
    includeUser: [],
    excludeUser: [],
    snoozeTime: '',
    snoozeCount: '',
    bgcolor: localStorage.getItem('bgcolor') ? localStorage.getItem('bgcolor') : '#ffffff',
    position: 'BOTTOM',
    speed: 2,
    repeatCount: 1,
    separator: '',
    forecolor: '#000000',
    texts: [{ 'content': '', 'class': 'ticker-item' }],
    tickermessages: [],
    title: '',
    carouselItems: [],
    carouselActiveSlide: 0,
    analysisEndNote: '',
    image: null,
    video: null,
    isUploading: false,
    windowSize: 'NORMAL',
    lcv: {
        accept_button: 'Evet',
        decline_button: 'Hayır',
        maybe_button: 'Belki',
        location: '',
        start_date: new Date(),
        end_date: new Date(),
        response_date: new Date(),
        optional_response_date: false
    },
    periodInterval: 0,
    periodCount: 0,
    files: [],
    waitingForSelectPreviewImage: false,
    btnEmojiDisabled: true
};

class CreatePopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...pageState }

        window.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                this.setState({ emojiPickerShow: false })
            }
        })

        this.user = new CustomStorage().getItem('user', true).user;

        setChonkyDefaults({ iconComponent: ChonkyIconFA });

        this.previewFileAction = defineFileAction({
            id: 'preview_file',
            requiresSelection: true,
            button: {
                name: 'Ön İzleme',
                toolbar: true,
                contextMenu: true,
                icon: 'symlink',
                group: 'Actions'
            },
        });
    }

    UndoMessageButton = (onUndo, contentProps) => {
        const handleClick = () => {
            onUndo();
            contentProps.closeToast();
        };

        return (
            <div><span style={{ marginTop: 6 }}>Gönderiliyor&hellip;</span> <button className="float-right btn btn-xs btn-primary" onClick={handleClick}>Durdur</button></div>
        );
    }

    startSendMessage = async () => {
        this.setState({ sending: true, showSettings: false })

        toast.warn((contentProps) => this.UndoMessageButton(this.stopMessage, contentProps), {
            onClose: () => {
                if (this.state.sending) {
                    this.publishMessage();
                }
            }
        });
    }

    stopMessage = () => {
        this.setState({ sending: false })
    }

    validateMessage = async () => {
        let errors = [];
        let deliveryOption = this.getDeliveryOption();
        let folder = this.state.folder;
        let sender = this.state.sender;
        let summary = this.state.messageTitle;
        let isAllGroups = this.state.isAllGroups;
        let hasTitleChanged = this.state.hasTitleChanged;

        this.setState({ showError: false, showMessages: '' });

        if (this.state.groups.length === 1 && this.state.groups[0]['value'] === "*") {
            isAllGroups = true;
        }

        if (!hasTitleChanged) {
            errors.push("&bull; Lütfen duyuru başlığı giriniz.");
        }

        if (this.state.sendDate !== null) {
            if (!moment().isSame(moment(this.state.sendDate), "day") && this.state.sendTime === null) {
                errors.push("&bull; İleri tarihli gönderiler için lütfen bir saat belirleyiniz.");
            }
        }

        if (summary.trim() === '') {
            errors.push("&bull; Lütfen duyuru başlığı giriniz.");
        }

        if (this.state.notifyType === "POPUP" && this.state.title.trim() === '') {
            errors.push("&bull; Bildirim tipli gönderilerde özet alanı zorunludur.");
        }

        if (deliveryOption === '') {
            errors.push("&bull; Lütfen en az bir gönderim cihazı belirleyiniz.");
        }

        if (folder === '') {
            errors.push("&bull; Lütfen klasör seçiniz.");
        }

        if (sender.trim() === '') {
            errors.push("&bull; Lütfen gönderen başlığını belirleyiniz.");
        }

        if (!isAllGroups && this.state.groups.length === 0) {
            errors.push("&bull; Lütfen en az bir gönderim grubu belirleyiniz.");
        }

        if (this.state.snoozeTime !== '' && this.state.snoozeCount === '') {
            errors.push("&bull; Öteleme ayarı için lütfen en fazla öteleme sayısını belirtiniz.");
        }

        if (this.state.snoozeCount !== '' && this.state.snoozeTime === '') {
            errors.push("&bull; Öteleme sayısı belirtilmiş, ötelemeyi aktifleştirmek için lütfen dakika belirtiniz.");
        }

        if (errors.length > 0) {
            this.setState({ showError: true, showMessages: errors.join('<br/>') });
            window.scrollTo(0, 0);
            return false;
        }

        console.log("validation completed.");
        await this.startSendMessage();
    }

    publishMessage = async (asDraft = false, isTest = false) => {
        let isAllGroups = this.state.isAllGroups;
        let sendNow = false;
        let publishStatus = 'DRAFT';

        if (this.state.groups.length === 1 && this.state.groups[0]['value'] === "*") {
            isAllGroups = true;
        }

        // TODO: kontrol
        if (!asDraft) {
            if (this.state.sendDate === null && this.state.sendTime === null) {
                sendNow = true;
            }

            if (sendNow) {
                publishStatus = 'SENT';
            } else {
                publishStatus = 'PENDING';
            }
        }

        if (isTest) {
            publishStatus = 'TEST';
        }

        let payload = {
            "announcementType": this.state.mediaNamespace,
            "description": this.state.content,
            "summary": this.state.messageTitle,
            "type": this.state.mediaType,
            "deliveryOption": this.getDeliveryOption(),
            "notificationType": this.state.notifyType,
            "folder": this.state.folder,
            "groups": isAllGroups ? [] : this.state.groups.map((item) => { return item.value }),
            "IsAllGroups": isAllGroups,
            "bgcolor": this.state.bgcolor,
            "startDate": sendNow ? '' : moment(moment(this.state.sendDate).format('YYYY-MM-DD') + ' ' + this.state.sendTime.format('HH:mm')).format('YYYY-MM-DD HH:mm:00.0'),
            "endDate": "", // TODO: hemen seciliyse
            "isPublished": publishStatus,
            "sender": this.state.sender,
            "snoozeTime": this.state.snoozeTime === '' ? 0 : this.state.snoozeTime,
            "snoozeCount": this.state.snoozeCount === '' ? 0 : this.state.snoozeCount,
            "title": this.state.title,
            "position": this.state.position,
            "speed": this.state.speed,
            "repeatCount": this.state.repeatCount,
            "tickermessages": this.state.tickermessages,
            "questions": this.state.carouselItems,
            "analysisEndNote": this.state.analysisEndNote,
            "video": this.state.video !== null ? this.state.video.id : null,
            "image": this.state.image !== null ? this.state.image.id : null,
            "lcv": this.state.lcv,
            "windowSize": this.state.windowSize,
            "periodInterval": this.state.periodInterval,
            "periodCount": this.state.periodCount,
            "periodReaming": this.state.periodCount,
            "files": this.state.files,
            "includeUser": this.state.includeUser ? this.state.includeUser.map(item => item.value) : [],
            "excludeUser": this.state.excludeUser ? this.state.excludeUser.map(item => item.value) : [],
        };

        this.setState({ sending: true, hasChanges: false }, async () => {
            await sendMessage(payload);

            localStorage.setItem('bgcolor', this.state.bgcolor);
            localStorage.setItem('forecolor', this.state.forecolor);

            this.props.history.push("/media/list/" + this.state.mediaType);
        });
    };

    /* Standart methods for all pages */
    componentDidMount() {
        PubSub.publish('MENU', 'medias');

        this.updatePageTitle();

        document.addEventListener("keypress", () => {
            this.setState({ hasChanges: true })
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.type !== nextProps.match.params.type) {
            this.setState({ ...pageState }, () => {
                this.updatePage(nextProps);
            });
        }

        this.setState({ hasChanges: false })
    }

    componentWillMount() {
        this.updatePage(this.props);
    }

    updatePage = async (props) => {
        let mediaId = props.match.params.id;
        let media = MessageTypes['POPUP'];
        let action = props.match.params.action;
        let mediaObject = { notifyType: null, devices: { mobile: true, desktop: true }, isAllGroups: false };

        mediaObject.notifyType = media.defaults.notifyType;
        mediaObject.devices = media.defaults.devices;
        mediaObject.isAllGroups = media.defaults.isAllGroups;

        this.setState({
            ...this.state,
            media: media,
            mediaNamespace: media.namespace,
            mediaType: 'POPUP',
            action: action,
            notifyType: mediaObject.notifyType,
            devices: mediaObject.devices,
            isAllGroups: mediaObject.isAllGroups,
            bgcolor: media.defaults.bgColor,
            forecolor: media.defaults.textColor
        }, () => {
            this.updatePageTitle();
            this.updateLists(mediaId, action);
        });
    }

    updatePageTitle = () => {
        setPageTitle(this.state.media.title);
    };
    /* Standart methods for all pages */

    // TODO: fix
    cloneMedia = async (id) => {
        let clonedMedia = await getAnnouncement(id);
        let media = MessageTypes['POPUP'];

        this.setState({
            id: 0,
            hasTitleChanged: true,
            action: 'clone',
            media: media,
            mediaNamespace: media.namespace,
            mediaType: 'POPUP',
            messageTitle: clonedMedia.summary,
            title: clonedMedia.title,
            notifyType: clonedMedia.notificationType,
            folder: clonedMedia.folder.id,
            groups: clonedMedia.groups ? clonedMedia.groups.map(g => { return { id: g.id, label: g.name, value: g.id } }) : [],
            content: clonedMedia.description,
            sender: clonedMedia.sender,
            isAllGroups: clonedMedia.isAllGroups,
            snoozeTime: clonedMedia.snoozeTime,
            snoozeCount: clonedMedia.snoozeCount,
            bgcolor: clonedMedia.bgcolor,
            position: clonedMedia.position,
            speed: clonedMedia.speed,
            repeatCount: clonedMedia.repeatCount,
            periodInterval: clonedMedia.repeatCount,
            periodCount: clonedMedia.periodCount,
            devices: {
                mobile: clonedMedia.deliveryOption === 'ALL' ? true : clonedMedia.deliveryOption === 'MOBILE',
                desktop: clonedMedia.deliveryOption === 'ALL' ? true : clonedMedia.deliveryOption === 'DESKTOP'
            },
            windowSize: clonedMedia.windowSize,
            analysisEndNote: clonedMedia.analysisEndNote,
            separator: '',
            forecolor: '#000000',
            texts: clonedMedia.tickermessages ? clonedMedia.tickermessages.filter(tm => tm.class === "ticker-item").map(tm => { return { class: "ticker-item", content: tm.content.replace(/<[^>]*>?/gm, '') } }) : [{ 'content': '', 'class': 'ticker-item' }],
            tickermessages: clonedMedia.tickermessages ? clonedMedia.tickermessages : [],
            carouselItems: [],
            carouselActiveSlide: 0,
            image: null,
            video: null,
            lcv: {
                accept_button: 'Evet',
                decline_button: 'Hayır',
                maybe_button: 'Belki',
                location: '',
                start_date: new Date(),
                end_date: new Date(),
                response_date: new Date(),
                optional_response_date: false
            },
            files: clonedMedia.files ? clonedMedia.files : []
        });
    }

    updateLists = async (mediaId = null, action = "") => {
        // TODO: check api connection and show global error from master layout

        // folders
        let folderResponse = await getFolders();
        let folderArr = folderResponse.map((item) => { return { "id": item.id, "value": item.id, "label": item.name } });

        // groups
        let groupResponse = await getGroups();
        let groupArr = groupResponse.map((item) => { return { "id": item.id, "value": item.id, "label": item.name } });

        this.setState({
            folderOptions: folderArr,
            groupOptions: groupArr
        }, () => {
            if (action === mediaActions.CLONE) {
                this.cloneMedia(mediaId);
            }
        });
    };

    showSettings = (show) => {
        this.setState({
            showSettings: show
        });
    };

    handleTitleKeyDown = (e) => {
        if (e.keyCode === 40) {
            this.setState({ emojiPickerShow: true });
            e.preventDefault();
        } else {
            this.setState({ emojiPickerShow: false });
        }
    };

    handleGroupChange = (value) => {
        this.setState({
            groups: value
        })
    };

    getDeliveryOption = () => {
        let deliveryOption = "";

        if (this.state.devices.desktop && this.state.devices.mobile) {
            deliveryOption = deliveryOptionsTypes.ALL;
        } else if (!this.state.devices.desktop && this.state.devices.mobile) {
            deliveryOption = deliveryOptionsTypes.MOBILE;
        } else {
            deliveryOption = deliveryOptionsTypes.DESKTOP;
        }

        if (!this.state.devices.desktop && !this.state.devices.mobile) {
            deliveryOption = "";
        }

        return deliveryOption;
    };

    getEditor = (targetName, placeholder) => {
        const toolbarMediaButton = 'media';
        const val = this.state[targetName];

        return (
            <>
                <Editor
                    apiKey="4wp2oams7a8jp489lzu8ywwx16edfuxlxrhjchhl7vsbafut"
                    value={val}
                    init={{
                        selector: 'textarea#myTextArea',
                        height: 120,
                        menubar: false,
                        inline: true,
                        toolbar_mode: "sliding",
                        language: "tr",
                        language_url: '/js/tr.js',
                        placeholder: placeholder,
                        automatic_uploads: true,
                        image_uploadtab: true,
                        image_caption: false,
                        image_advtab: false,
                        image_description: false,
                        image_dimensions: true,
                        content_css: "/css/editor.css",
                        file_browser_callback_types: 'image media',
                        contextmenu: false,
                        images_upload_url: settings.apiBase + 'announcement/fileuploads',
                        images_upload_base_path: settings.apiBase,
                        images_upload_credentials: false,
                        browser_spellcheck: true,
                        force_br_newlines: false,
                        force_p_newlines: false,
                        forced_root_block: '',
                        images_upload_handler: function (blobInfo, success, failure, progress) {
                            let data = new FormData();
                            let config = { header: { 'Content-Type': 'multipart/form-data' } }

                            data.append('file', blobInfo.blob(), blobInfo.filename());

                            protectedApi.post('/announcement/fileuploads', data, config).then(response => {
                                success(settings.apiBaseClear + response.data.file);
                            }).catch(error => {
                                failure(error);
                            })
                        },
                        plugins: [
                            'autolink lists link customUserButton customBgButton emoticons image media anchor code table paste code help'
                        ],
                        toolbar:
                            'undo redo | fontsizeselect | bold italic customBgButton forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        customUserButton link image ' + toolbarMediaButton + ' table emoticons | \
                        bullist numlist | removeformat | code help'
                    }}
                    onEditorChange={(content, editor) => {
                        this.setState({
                            [targetName]: content,
                            // title: editor.getContent({format : 'text'}).substring(0,150)
                        });

                        // hack the editor to enable spellcheck
                        editor.bodyElement.spellcheck = true;
                    }}
                    onInit={(event, editor) => {
                        this.editorBody = editor.bodyElement;

                        editor.ui.registry.addButton('customBgButton', {
                            icon: "color-picker",
                            onAction: (_) => {
                                editor.windowManager.open({
                                    title: "Arka Plan Rengi",
                                    body: {
                                        type: 'panel',
                                        items: [{
                                            type: 'colorpicker',
                                            name: 'bgcolor',
                                            flex: true
                                        }]
                                    },
                                    onSubmit: (api) => {
                                        let bgColor = api.getData().bgcolor;

                                        this.setState({ bgcolor: bgColor });
                                        if (this.editorBody) { this.editorBody.style.backgroundColor = bgColor; }

                                        api.close();
                                    },
                                    buttons: [
                                        {
                                            text: 'Vazgeç',
                                            type: 'cancel',
                                            onclick: 'close'
                                        },
                                        {
                                            text: 'Tamam',
                                            type: 'submit',
                                            primary: true,
                                            enabled: false
                                        }
                                    ]
                                })
                            },
                            onSetup: function (buttonApi) {
                                var editorEventCallback = function (eventApi) {
                                    buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase() === 'time');
                                };
                                editor.on('NodeChange', editorEventCallback);

                                return function (buttonApi) {
                                    editor.off('NodeChange', editorEventCallback);
                                };
                            }
                        });

                        editor.ui.registry.addButton('customUserButton', {
                            icon: 'user',
                            tooltip: 'Kullanıcı Bilgileri Ekle',
                            disabled: true,
                            onAction: function (_) {
                                //editor.insertContent("#AD#");

                                editor.windowManager.open({
                                    title: 'Kişiye Özel Gönderi',
                                    body: {
                                        type: 'panel',
                                        items: [{
                                            type: 'selectbox',
                                            name: 'type',
                                            label: '',
                                            items: [
                                                { text: 'Ad', value: '#AD#' },
                                                { text: 'Soyad', value: '#SOYAD#' },
                                                { text: 'Ad Soyad', value: '#AD# #SOYAD#' },
                                            ],
                                            flex: true
                                        }]
                                    },
                                    onSubmit: function (api) {
                                        // insert markup
                                        editor.insertContent(api.getData().type);

                                        // close the dialog
                                        api.close();
                                    },
                                    buttons: [
                                        {
                                            text: 'Vazgeç',
                                            type: 'cancel',
                                            onclick: 'close'
                                        },
                                        {
                                            text: 'Ekle',
                                            type: 'submit',
                                            primary: true,
                                            enabled: false
                                        }
                                    ]
                                });

                            },
                            onSetup: function (buttonApi) {
                                var editorEventCallback = function (eventApi) {
                                    buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase() === 'time');
                                };
                                editor.on('NodeChange', editorEventCallback);

                                /* onSetup should always return the unbind handlers */
                                return function (buttonApi) {
                                    editor.off('NodeChange', editorEventCallback);
                                };
                            }
                        });

                    }}
                />
            </>
        )
    }

    handleThumbnailUpload = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let selectedFile = event.target.files[0];

        this.setState({ waitingForSelectPreviewImage: true }, () => {
            this.handleFileUpload(selectedFile)
        });
    }

    handleFileUpload = (file) => {
        let data = new FormData();
        let config = { header: { 'Content-Type': 'multipart/form-data' } }

        this.setState({ isUploading: true, files: [...this.state.files, null] });

        data.append('file', file, file.name);

        protectedApi.post('/announcement/fileuploads', data, config).then(response => {
            let uploadedFile = {
                id: response.data.id,
                name: file.name,
                url: settings.apiBaseClear + response.data.file,
                thumbnailUrl: settings.apiBaseClear + response.data.file,
                isThumbnail: this.state.waitingForSelectPreviewImage,
                isVideo: false,
                color: this.state.waitingForSelectPreviewImage ? "#F4C752" : "#3B4182",
                icon: this.state.waitingForSelectPreviewImage ? "image" : null,
                type: file.type
            }

            let allFiles = [...this.state.files.filter(f => f !== null)];

            allFiles.push(uploadedFile);

            this.setState({ files: allFiles, showError: false, isUploading: false, waitingForSelectPreviewImage: false });

        }).catch(error => {
            this.setState({ files: [...this.state.files.filter(f => f !== null)], isUploading: false, showError: true, waitingForSelectPreviewImage: false, showMessages: "&bull; Dosya yükleme işlemi başarısız." }, () => {
                window.scrollTo(0, 0);
            })
        })
    }

    handleFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let selectedFile = event.target.files[0];

        this.handleFileUpload(selectedFile);
    }

    handleFileBrowserAction = (event) => {
        if (event.id === "upload_files") {
            this.upload.click();
        }

        if (event.id === "delete_files") {
            let allFiles = [...this.state.files];
            let newFiles = allFiles.filter(i => !event.state.selectedFiles.some(j => j.id === i.id));

            this.setState({ files: newFiles });
        }

        if (event.id === "set_thumbnail") {
            if (!event.state.selectedFiles || event.state.selectedFiles.length === 1) {
                let allFiles = JSON.parse(JSON.stringify([...this.state.files]));
                let index = allFiles.findIndex(x => x.id === event.state.selectedFiles[0].id);

                for (let f of allFiles) {
                    f.isThumbnail = false;
                    f.icon = null;
                    f.color = "#3B4182";
                }

                allFiles[index].isThumbnail = true;
                allFiles[index].icon = "image";
                allFiles[index].color = "#F4C752";

                this.setState({ files: allFiles, image: event.state.selectedFiles[0] });
            } else {
                this.setState({ showError: true, showMessages: "&bull; Öne çıkan görseli ayarlamak için lütfen bir görsel seçiniz." }, () => {
                    window.scrollTo(0, 0);
                })
            }
        }

        if (event.id === "preview_file" && event.state.selectedFiles.length === 1) {
            window.open(event.state.selectedFiles[0].url, "_blank")
        }
    }

    render() {
        return (
            <>
                <Prompt
                    when={this.state.hasChanges}
                    message={location => 'Sayfada değişiklikler var ayrılmak için emin misiniz?'}
                />
                <LoadingOverlay
                    active={this.state.sending}
                    // spinner
                    // text=''
                    styles={{ wrapper: { width: '100%', height: '100vh' } }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="alert alert-danger" style={{ display: this.state.showError ? 'block' : 'none' }}><p dangerouslySetInnerHTML={{ __html: this.state.showMessages }} /></div>
                                <div className="card">
                                    <div className="card-header border-0 pb-0">
                                        {/**
                                    <Picker
                                        native={false}
                                        showPreview={true}
                                        color="#3B4182"
                                        title="Duyuroom"
                                        onSelect={emoji => { this.setState({emojiPickerShow: false, messageTitle: document.getElementById('txtTitle').value + emoji.native}); document.getElementById('txtTitle').focus() }}
                                        style={{display: this.state.emojiPickerShow ? 'block' : 'none', position: 'absolute', top: '65px', left: '25px', zIndex: 9999 }}
                                        i18n={{
                                            search: 'Ara',
                                            clear: 'Temizle',
                                            notfound: 'Emoji bulunamadı',
                                            skintext: 'Varsayılan ton seçiniz',
                                            categories: {
                                                search: 'Arama sonuçları',
                                                recent: 'Sık Kullanılan',
                                                smileys: 'Duygular',
                                                people: 'İnsanlar',
                                                nature: 'Hayvanlar ve Doğa',
                                                foods: 'Yeme ve İçme',
                                                activity: 'Etkinlik',
                                                places: 'Yolculuk',
                                                objects: 'Nesneler',
                                                symbols: 'Semboller',
                                                flags: 'Bayraklar',
                                                custom: 'Özel',
                                            },
                                            categorieslabel: 'Kategoriler',
                                            skintones: {
                                                1: 'Default Skin Tone',
                                                2: 'Light Skin Tone',
                                                3: 'Medium-Light Skin Tone',
                                                4: 'Medium Skin Tone',
                                                5: 'Medium-Dark Skin Tone',
                                                6: 'Dark Skin Tone',
                                            }
                                        }}
                                    />
                                     */}

                                        <input
                                            ref={elm => { this.summaryElement = elm }}
                                            value={this.state.messageTitle}
                                            id="txtTitle"
                                            type="text"
                                            className="w-100"
                                            style={{ border: 'none', width: '100%', fontFamily: 'Roboto', fontSize: 22, fontWeight: 'bold', color: '#000000' }}
                                            placeholder="Mesaj başlığınızı buraya yazınız."
                                            onKeyDown={this.handleTitleKeyDown}
                                            onFocus={() => this.setState({ btnEmojiDisabled: false })}
                                            onChange={e => this.setState({ messageTitle: e.target.value, hasTitleChanged: true })}
                                        />

                                        <div>
                                            <div style={{ position: 'absolute', width: 200, marginTop: -50 }}>
                                                {!this.state.btnEmojiDisabled &&
                                                    <Tooltip content="Başlığa emoji ekleyebilirsiniz." placement="top">
                                                        <button className="btn btn-light btn-sm" style={{ position: 'absolute', marginLeft: -40, marginTop: 23, color: '#424242', fontSize: 20, width: 45, height: 45, borderRadius: 22.5 }} onClick={() => this.setState({ emojiPickerShow: !this.state.emojiPickerShow })}>🙂</button>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <div className="card-body">
                                        {this.getEditor('content', 'Buraya mesajınızı en iyi anlatacak metni ve görselleri ekleyiniz.')}
                                    </div>

                                    <div className="card-footer border-0 pt-0">
                                        <p className="card-text d-inline">&nbsp;</p>
                                        <div className="float-right">
                                            <Tooltip placement="top" content="Mesajınızla ilgili ayarları buradan yapabilirsiniz.">
                                                <button className="btn btn-primary btn-rounded btn-md" style={{ display: this.state.showSettings ? 'none' : 'inline-block' }} onClick={() => { this.showSettings(true) }}>
                                                    Gönderi Ayarları
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.showSettings &&
                                <div className="col-xl-12" style={{ marginBottom: 50 }}>
                                    <div className="card">
                                        <div className="card-header border-0 pb-0">
                                            <h4 className="card-title" style={{ fontFamily: 'Roboto', fontSize: 22, fontWeight: 'bold', color: '#424242' }}>Gönderi Ayarları</h4>
                                            <div className="float-right">
                                                <button className="btn btn-xs btn-rounded light btn-danger" onClick={() => { this.showSettings(false) }}>X</button>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ overflow: 'visible' }}>
                                            <div className="custom-tab-1">
                                                <ul className="nav nav-tabs">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-toggle="tab" href="#target">
                                                            <i className="la la-pencil mr-1" /> İçerik Ayarları
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-toggle="tab" href="#options">
                                                            <i className="la la-envelope mr-1" /> Gönderi Ayarları
                                                        </a>
                                                    </li>

                                                    {this.state.devices.mobile &&
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#preview_mobile">
                                                                <i className="la la-mobile-phone mr-1" /> Mobil Ön İzleme
                                                            </a>
                                                        </li>
                                                    }

                                                    {this.state.devices.desktop &&
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#preview_desktop">
                                                                <i className="la la-desktop mr-1" /> Masaüstü Ön İzleme
                                                            </a>
                                                        </li>
                                                    }

                                                    <li className="nav-item">
                                                        <a className="nav-link" data-toggle="tab" href="#share">
                                                            <i className="la la-send" /> Paylaş
                                                        </a>
                                                    </li>
                                                </ul>
                                                {/* --------------- Settings Tabs -------------- */}
                                                <div className="tab-content">
                                                    <div className="tab-pane show active" id="target" role="tabpanel">
                                                        <div className="pt-4">
                                                            <div className="row">
                                                                <div className="col-5">
                                                                    <div className="form-group mb-0">
                                                                        <p style={{ color: '#000000' }}>Hangi departman ya da kimin adına paylaşıldığını belirleyiniz. <span className="requiredField">*</span></p>
                                                                        <input className="form-select" type="text" placeholder="Örneğin; İnsan Kaynakları" style={{ cursor: 'text' }} value={this.state.sender} onChange={e => { this.setState({ sender: e.target.value }) }} />
                                                                    </div>

                                                                    <br />

                                                                    <p style={{ color: '#000000' }}>Gönderinin kaydedileceği klasörü seçiniz. <span className="requiredField">*</span></p>
                                                                    <select className="form-select" value={this.state.folder} onChange={e => this.setState({ folder: e.target.value })}>
                                                                        <option value="">Seçiniz</option>
                                                                        {this.state.folderOptions.map((item, optIndex) => {
                                                                            return (
                                                                                <option key={`optIndex_${optIndex}`} value={item.id}>{item.label}</option>
                                                                            )
                                                                        })}
                                                                    </select>

                                                                    <br /><br />

                                                                    <p style={{ color: '#000000' }}>Gönderi için tarih ve saat belirleyiniz.</p>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <DatePicker
                                                                                placeholderText="Bugün"
                                                                                selected={this.state.sendDate}
                                                                                isClearable={true}
                                                                                minDate={new Date()}
                                                                                showTimeSelect={false}
                                                                                timeFormat="H:mm"
                                                                                timeIntervals={15}
                                                                                locale={tr}
                                                                                dateFormat="dd.MM.yyyy"
                                                                                onChange={date => {
                                                                                    this.setState({ sendDate: date })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <TimePicker
                                                                                style={{ padding: 2 }}
                                                                                placeholder="Hemen"
                                                                                className="form-select"
                                                                                showSecond={false}
                                                                                minuteStep={15}
                                                                                clearIcon={<button type="button" className="react-datepicker__close-icon" tabIndex="-1" />}
                                                                                value={this.state.sendTime}
                                                                                onChange={time => this.setState({ sendTime: time })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-7">
                                                                    <input
                                                                        type="file"
                                                                        ref={(ref) => this.upload = ref}
                                                                        style={{ display: 'none' }}
                                                                        onChange={this.handleFileChange}
                                                                    />
                                                                    <FullFileBrowser
                                                                        i18n={turkishI18n.folders}
                                                                        files={this.state.files}
                                                                        folderChain={[]}
                                                                        fileActions={[!this.state.isUploading ? ChonkyActions.UploadFiles : null, ChonkyActions.DeleteFiles]}
                                                                        disableDragAndDrop={true}
                                                                        disableDefaultFileActions={true}
                                                                        onFileAction={this.handleFileBrowserAction}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="options">
                                                        <div className="pt-4 row">
                                                            <div className="col-6">

                                                                <p style={{ color: '#000000' }}>Göndereceğiniz cihaz tipini seçiniz. <span className="requiredField">*</span></p>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        id="devicesMOBILE" value="MOBILE"
                                                                        checked={this.state.devices.mobile}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                devices: {
                                                                                    ...this.state.devices,
                                                                                    mobile: e.target.checked
                                                                                }
                                                                            })
                                                                        }} />
                                                                    <label className="custom-control-label pt-1" htmlFor="devicesMOBILE">Mobil Cihazlar</label>
                                                                </div>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" id="devicesDESKTOP" value="DESKTOP" checked={this.state.devices.desktop} onChange={(e) => { this.setState({ devices: { ...this.state.devices, desktop: e.target.checked } }) }} />
                                                                    <label className="custom-control-label pt-1" htmlFor="devicesDESKTOP">Bilgisayarlar</label>
                                                                </div>

                                                                <br />

                                                                <p style={{ color: '#000000' }}>Kime göndereceğinizi seçiniz. <span className="requiredField">*</span></p>
                                                                <MultiSelectAll
                                                                    itemTitle="Grup"
                                                                    allGroups={this.state.isAllGroups}
                                                                    onChange={this.handleGroupChange}
                                                                    options={this.state.groupOptions}
                                                                />
                                                                <br />

                                                                <label style={{ color: '#000000' }}><i className="la la-user-minus " /> Gönderim listesinden isim çıkar</label>
                                                                <AsyncSelect
                                                                    isMulti={true}
                                                                    isClearable={true}
                                                                    loadingMessage={() => <span>Yükleniyor</span>}
                                                                    noOptionsMessage={() => <span>Henüz kimseyi seçmediniz</span>}
                                                                    onChange={selected => this.setState({ excludeUser: selected })}
                                                                    placeholder="Seçiniz"
                                                                    defaultOptions={[]}
                                                                    loadOptions={inputValue => {
                                                                        return searchUser(inputValue).then(list => {
                                                                            return list.map(u => { return { value: u.id, label: u.first_name + ' ' + u.last_name } })
                                                                        })
                                                                    }}
                                                                />
                                                                <br />

                                                                <label style={{ color: '#000000' }}><i className="la la-user-plus" /> Gönderim listesine kişi ekle</label>
                                                                <AsyncSelect
                                                                    isMulti={true}
                                                                    isClearable={true}
                                                                    loadingMessage={() => <span>Yükleniyor</span>}
                                                                    noOptionsMessage={() => <span>Henüz kimseyi seçmediniz</span>}
                                                                    onChange={selected => this.setState({ includeUser: selected })}
                                                                    placeholder="Seçiniz"
                                                                    defaultOptions={[]}
                                                                    loadOptions={inputValue => {
                                                                        return searchUser(inputValue).then(list => {
                                                                            return list.map(u => { return { value: u.id, label: u.first_name + ' ' + u.last_name } })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <div>
                                                                    <p style={{ color: '#000000' }}>Gönderi penceresinin boyutunu belirleyiniz.</p>
                                                                    <div className="form-group mb-0">
                                                                        <select className="form-select" value={this.state.windowSize} onChange={e => this.setState({ windowSize: e.target.value })}>
                                                                            <option value="NORMAL">Normal</option>
                                                                            <option value="MAXIMIZED">Ekranı Kaplayan</option>
                                                                        </select>
                                                                    </div>
                                                                    <br />
                                                                </div>

                                                                <p style={{ color: '#000000' }}>Gönderi tipini seçiniz.</p>
                                                                <div className="form-group mb-0">
                                                                    <Tooltip placement="top" content="Ekranda pop-up olarak görünür.">
                                                                        <label className="radio-inline mr-3">
                                                                            <input type="radio" name="notifyType" value="POPUP"
                                                                                checked={this.state.notifyType === 'POPUP'}
                                                                                onClick={e => this.setState({ notifyType: 'POPUP' })} /> Bildirim
                                                                        </label>
                                                                    </Tooltip>

                                                                    <Tooltip placement="top" content="Doğrudan ekranda çıkar.">
                                                                        <label className="radio-inline mr-3">
                                                                            <input type="radio" name="notifyType" value="DIRECT" checked={this.state.notifyType === 'DIRECT'} onClick={e => this.setState({ notifyType: 'DIRECT' })} /> Doğrudan
                                                                        </label>
                                                                    </Tooltip>

                                                                    <Tooltip placement="top" content="Sadece bildiri mesajı olarak görünür.">
                                                                        <label className="radio-inline mr-3">
                                                                            <input type="radio" name="notifyType" value="FLASHING"
                                                                                checked={this.state.notifyType === 'FLASHING'}
                                                                                onClick={e => this.setState({ notifyType: 'FLASHING' })} /> Sessiz
                                                                        </label>
                                                                    </Tooltip>

                                                                    <div>
                                                                        {this.state.notifyType === 'POPUP' &&
                                                                            <>
                                                                                <br />
                                                                                <p style={{ color: '#000000' }}>Öteleme süresi ve sayısı belirleyiniz. </p>
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="snoozeEnabled"
                                                                                        checked={this.state.snoozeTime !== ''}
                                                                                        onChange={e => {
                                                                                            if (!e.target.checked) {
                                                                                                this.setState({ snoozeTime: '', snoozeCount: '' })
                                                                                            } else {
                                                                                                document.getElementById('txtSnoozeTime').focus();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="snoozeEnabled">
                                                                                        <input
                                                                                            className="form-select"
                                                                                            type="text"
                                                                                            id="txtSnoozeTime"
                                                                                            pattern="[0-9]*"
                                                                                            value={this.state.snoozeTime}
                                                                                            onChange={e => { const valid = (e.target.validity.valid) ? this.setState({ snoozeTime: e.target.value }) : false }}
                                                                                            style={{ height: 22, width: 50, marginLeft: 10, marginRight: 10, cursor: 'text' }} />
                                                                                        dakika arayla en fazla
                                                                                        <input
                                                                                            className="form-select"
                                                                                            type="text"
                                                                                            pattern="[0-9]*"
                                                                                            value={this.state.snoozeCount}
                                                                                            onChange={e => { const valid = (e.target.validity.valid) ? this.setState({ snoozeCount: e.target.value }) : false }}
                                                                                            style={{ height: 22, width: 30, marginLeft: 10, marginRight: 10, cursor: 'text' }} />
                                                                                        kez ötelenebilir.
                                                                                    </label>
                                                                                </div>
                                                                                <br />
                                                                                <p style={{ color: '#000000' }}>Bildirim Görseli</p>
                                                                                <div className="input-group">
                                                                                    <div className="custom-file">
                                                                                        <input disabled={this.state.isUploading} type="file" className="custom-file-input form-select" id="inputGroupFile04" onChange={e => this.handleThumbnailUpload(e)} ref={(ref) => this.thumbnailUpload = ref} />
                                                                                        <label className="custom-file-label" htmlFor="inputGroupFile04">Görsel Seç</label>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <div className="form-group mb-0">
                                                                                    <p style={{ color: '#000000' }}>Bildirim mesajı için metin yazınız. <span className="requiredField">*</span> <small><em>140 karakterle sınırlıdır.</em></small> </p>
                                                                                    <textarea className="form-select" style={{ cursor: 'text', whiteSpace: 'pre-line' }} value={this.state.title.replace('\\n', '\n')} onChange={e => { this.setState({ title: e.target.value }) }} rows={2} maxLength={140} />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="preview_mobile" style={{ padding: 30, height: 600 }}>
                                                        <PreviewDevice
                                                            deviceType="MOBILE"
                                                            device="iphonex"
                                                            scale="0.65"
                                                            wallpaper="url(/images/ios-wallpaper.png)"
                                                            notification={{
                                                                show: this.state.notifyType === 'POPUP',
                                                                title: this.state.messageTitle,
                                                                subtitle: this.state.title,
                                                                body: this.state.sender,
                                                                snooze: this.state.snoozeTime > 0,
                                                                top: 40,
                                                                thumbnail: this.state.files.filter(f => f && f.isThumbnail)
                                                            }}>
                                                            <PreviewApp
                                                                bgcolor={this.state.bgcolor}
                                                                mediaType="POPUP"
                                                                title={this.state.messageTitle}
                                                                subtitle={this.state.sender}
                                                                top={40}>
                                                                <div className="previewDiv">{parse(this.state.content)}</div>
                                                            </PreviewApp>
                                                        </PreviewDevice>
                                                    </div>
                                                    <div className="tab-pane" id="preview_desktop" style={{ padding: 30, height: 500 }}>
                                                        <PreviewDevice
                                                            deviceType="DESKTOP"
                                                            device="macbookpro"
                                                            scale="0.65"
                                                            wallpaper="url(/images/macos-wallpaper.png)"
                                                            notification={{
                                                                show: this.state.notifyType === 'POPUP',
                                                                title: this.state.messageTitle,
                                                                subtitle: this.state.title,
                                                                body: this.state.sender,
                                                                snooze: this.state.snoozeTime > 0,
                                                                top: 0,
                                                                thumbnail: this.state.files.filter(f => f && f.isThumbnail)
                                                            }}>
                                                            <PreviewWindow
                                                                bgcolor={this.state.bgcolor}
                                                                mediaType='POPUP'
                                                                title={this.state.messageTitle}
                                                                subtitle={this.state.sender}>
                                                                <div className="previewDiv">{parse(this.state.content)}</div>
                                                            </PreviewWindow>
                                                        </PreviewDevice>
                                                    </div>
                                                    <div className="tab-pane" id="share" style={{ padding: 30 }}>
                                                        <div className="row align-items-center">
                                                            <div className="col-xl-12 col-xxl-12">
                                                                <div className="pr-5">
                                                                    <h3 className="text-black mt-0 pt-0">{this.state.messageTitle}</h3>
                                                                    <p className="alert alert-info"><i className="fa fa-info" /> Gönderinizin belirli ayarlarını bu özet sayfasından gözden geçirebilirsiniz. Her şey hazır olduğunda <b>Paylaş</b> butonu ile yayımlayabilir ya da <b>Taslak Olarak Kaydedip</b> daha sonra işlemlerinize devam edebilirsiniz.</p>
                                                                    <ul className="list-group list-group-flush">
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>Gönderen</strong>
                                                                            <span className="mb-0">{this.state.sender}</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>Gönderim Tipi</strong>
                                                                            <span className="mb-0">
                                                                                {this.state.notifyType === 'POPUP' ? 'Bildirim' : this.state.notifyType === 'DIRECT' ? 'Doğrudan' : this.state.notifyType === 'FLASHING' ? 'Sessiz' : 'Belirtilmemiş'}
                                                                            </span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>Hedef Cihazlar</strong>
                                                                            <span className="mb-0">
                                                                                {(this.state.devices.mobile && this.state.devices.desktop) ? 'Tümü' : this.state.devices.mobile ? 'Mobil Cihazlar' : this.state.devices.desktop ? 'Bilgisayarlar' : 'Belirtilmemiş'}
                                                                            </span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>Mecra</strong>
                                                                            <span className="mb-0">
                                                                                {/*<i className="la la-desktop la-2x" style={{color: '#3B4182'}}/>*/} {this.state.media.title}
                                                                            </span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>Yayımlanma Tarihi</strong>
                                                                            <span className="mb-0">
                                                                                {/*<i className="la la-desktop la-2x" style={{color: '#3B4182'}}/>*/} Hemen
                                                                            </span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>İçerik Yöneticisi</strong>
                                                                            <span className="mb-0">
                                                                                {this.user.first_name} {this.user.last_name}
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="media mt-4 mb-4 align-items-center">
                                                                    <div className="media-body">
                                                                        <button className="btn btn-send btn-rounded btn-md btn-block"
                                                                            disabled={this.state.sending}
                                                                            onClick={_ => this.publishMessage(true, false)}>
                                                                            <i className="flaticon-381-save " /> Taslak Olarak Kaydet
                                                                        </button>

                                                                        <button className="btn btn-send btn-rounded btn-md btn-block"
                                                                            disabled={this.state.sending}
                                                                            onClick={_ => this.publishMessage(true, true)}>
                                                                            <i className="flaticon-381-send-2" /> Test Grubuna Gönder
                                                                        </button>

                                                                        <button className="btn btn-send btn-rounded btn-md btn-block"
                                                                            disabled={this.state.sending}
                                                                            onClick={this.validateMessage}>
                                                                            <i className="flaticon-381-send-2" /> Paylaş
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="mt-2"><small><span className="requiredField">*</span> İşaretli alanların doldurulması zorunludur.</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <ToastContainer
                            pauseOnFocusLoss={false}
                            closeOnClick={false}
                            closeButton={false}
                            draggable={false}
                            position="bottom-left"
                            autoClose={10000}
                        />
                    </div>
                </LoadingOverlay>
            </>
        )
    }
}

export default withRouter(CreatePopup);
