export const PreviewCss = {
    css: ".footerNotify {\n" +
        "    position: static !important;\n" +
        "    width : 270px !important;\n" +
        "    margin-bottom: 5px !important;\n" +
        "}\n" +
        ".emptyDiv{\n" +
        "    height: 60px !important;\n" +
        "    display: none !important;\n" +
        "}\n" +
        ".footerDiv{\n" +
        "    margin-top: -40px !important;\n" +
        "    height: 60px !important;\n" +
        "}",

}