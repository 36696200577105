import React from 'react';
import PubSub from 'pubsub-js';
import EdiText from 'react-editext';
import { Editor } from '@tinymce/tinymce-react';
import { withRouter, Prompt } from "react-router-dom";
import { Picker } from 'emoji-mart';
import { CompactPicker } from 'react-color';
import { getFolders, getGroups, sendMessage, getAnnouncement, searchUser } from "../../../services/api";
import { Tooltip } from '@trendmicro/react-tooltip';
import { mediaActions, deliveryOptionsTypes, dateFormat } from "../../../helpers/LayoutHelper";
import { MessageTypes } from "../../../models/Message";
import { setPageTitle } from "../../../helpers/LayoutHelper";
import MultiSelectAll from "../../../components/forms/MultiSelectAll";
import parse from "html-react-parser";
import tr from 'date-fns/locale/tr';
import moment from "moment";
import DatePicker from "react-datepicker";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ReactDragListView from "react-drag-listview";
import LoadingOverlay from 'react-loading-overlay';
import { toast, ToastContainer } from "react-toastify";
import { Player, BigPlayButton } from 'video-react';
import { protectedApi } from "../../../services/axios";
import PreviewDevice from "../../../components/common/PreviewDevice";
import PreviewWindow from "../../../components/common/PreviewWindow";
import PreviewApp from "../../../components/common/PreviewApp";
import PreviewTicker from "../../../components/common/PreviewTicker";
import TimePicker from "rc-time-picker";
import CustomStorage from "../../../models/CustomStorage";
import { setChonkyDefaults, FullFileBrowser, ChonkyActions, defineFileAction } from 'chonky';
import { turkishI18n } from "../../../helpers/Language";
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import AsyncSelect from 'react-select/async';
import settings from "../../../config/settings.json";

const pageState = {
    hasChanges: false,
    hasTitleChanged: false,
    id: 0,
    sending: false,
    media: null,
    mediaNamespace: null,
    mediaType: null,
    action: 'add',
    showSettings: false,
    sendDate: { iso: moment(), readable: 'Hemen', sendTime: '' },
    devices: { mobile: true, desktop: true },
    folderOptions: [],
    groupOptions: [],
    emojiPickerShow: false,
    messageTitle: 'Mesaj başlığınızı buraya yazınız.',
    notifyType: null,
    folder: '',
    content: '',
    sender: '', //localStorage.getItem('sender') ? localStorage.getItem('sender') : '',
    showError: false,
    showMessages: '',
    isAllGroups: false,
    groups: [],
    includeUser: [],
    excludeUser: [],
    snoozeTime: '',
    snoozeCount: '',
    bgcolor: localStorage.getItem('bgcolor') ? localStorage.getItem('bgcolor') : '#ffffff',
    position: 'BOTTOM',
    speed: 2,
    repeatCount: 1,
    separator: '',
    forecolor: '#000000',
    texts: [{ 'content': '', 'class': 'ticker-item' }],
    tickermessages: [],
    title: '',
    carouselItems: [],
    carouselActiveSlide: 0,
    analysisEndNote: '',
    image: null,
    video: null,
    isUploading: false,
    windowSize: 'NORMAL',
    lcv: {
        accept_button: 'Evet',
        decline_button: 'Hayır',
        maybe_button: 'Belki',
        location: '',
        start_date: new Date(),
        end_date: new Date(),
        response_date: new Date(),
        optional_response_date: false
    },
    periodInterval: 0,
    periodCount: 0,
    files: [],
    waitingForSelectPreviewImage: false,
    btnEmojiDisabled: true
};

class CreateMedia extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...pageState }

        window.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                this.setState({ emojiPickerShow: false })
            }
        })

        this.subscribeToken = PubSub.subscribe('DATECHANGED', this.subscribeDate);

        this.slider = React.createRef();

        this.user = new CustomStorage().getItem('user', true).user;

        setChonkyDefaults({ iconComponent: ChonkyIconFA });


        this.uploadThumbnailAction = defineFileAction({
            id: 'upload_thumbnail',
            requiresSelection: false,
            button: {
                name: 'Bildirim Görseli Yükle',
                toolbar: true,
                contextMenu: false,
                icon: 'upload',
                group: ''
            },
        });


        this.setThumbnailAction = defineFileAction({
            id: 'set_thumbnail',
            requiresSelection: true,
            fileFilter: file => file && file.type.includes("image"),
            button: {
                name: 'Görseli Öne Çıkarma',
                toolbar: true,
                contextMenu: true,
                icon: 'image',
                group: 'Actions'
            },
        });

        this.setVideoAction = defineFileAction({
            id: 'set_video',
            requiresSelection: true,
            fileFilter: file => file && file.type.includes("video"),
            button: {
                name: 'Eğitim Videosu',
                toolbar: true,
                contextMenu: true,
                icon: 'video',
                group: 'Actions'
            },
        });

        this.previewFileAction = defineFileAction({
            id: 'preview_file',
            requiresSelection: true,
            button: {
                name: 'Ön İzleme',
                toolbar: true,
                contextMenu: true,
                icon: 'symlink',
                group: 'Actions'
            },
        });
    }

    UndoMessageButton = (onUndo, contentProps) => {
        const handleClick = () => {
            onUndo();
            contentProps.closeToast();
        };

        return (
            <div><span style={{ marginTop: 6 }}>Gönderiliyor&hellip;</span> <button className="float-right btn btn-xs btn-primary" onClick={handleClick}>Durdur</button></div>
        );
    };

    startSendMessage = async () => {
        // console.log("Start sending...");
        this.setState({ sending: true, showSettings: false })

        toast.warn((contentProps) => this.UndoMessageButton(this.stopMessage, contentProps), {
            onClose: () => {
                if (this.state.sending) {
                    // console.log("Start publishing...");
                    this.publishMessage();
                } else {
                    // console.log("sending canceled");
                }
            }
        });
    }

    stopMessage = () => {
        this.setState({ sending: false })
    }

    validateMessage = async () => {
        console.log("Start validating...");
        let errors = [];
        let deliveryOption = this.getDeliveryOption();
        let folder = this.state.folder;
        let sender = this.state.sender;
        let summary = this.state.messageTitle;
        let isAllGroups = this.state.isAllGroups;
        let hasTitleChanged = this.state.hasTitleChanged;

        this.setState({ showError: false, showMessages: '' });

        if (this.state.groups.length === 1 && this.state.groups[0]['value'] === "*") {
            isAllGroups = true;
        }

        if (!hasTitleChanged) {
            errors.push("&bull; Lütfen duyuru başlığı giriniz.");
        }

        if (!moment().isSame(this.state.sendDate.iso, "day") && this.state.sendDate.sendTime === '') {
            errors.push("&bull; İleri tarihli gönderiler için lütfen bir saat belirleyiniz.");
        }

        if (summary.trim() === '') {
            errors.push("&bull; Lütfen duyuru başlığı giriniz.");
        }

        if (this.state.notifyType === "POPUP" && this.state.title.trim() === '') {
            errors.push("&bull; Bildirim tipli gönderilerde özet alanı zorunludur.");
        }

        if (deliveryOption === '') {
            errors.push("&bull; Lütfen en az bir gönderim cihazı belirleyiniz.");
        }

        if (folder === '') {
            errors.push("&bull; Lütfen klasör seçiniz.");
        }

        if (sender.trim() === '') {
            errors.push("&bull; Lütfen gönderen başlığını belirleyiniz.");
        }

        if (!isAllGroups && this.state.groups.length === 0) {
            errors.push("&bull; Lütfen en az bir gönderim grubu belirleyiniz.");
        }

        if (this.state.snoozeTime !== '' && this.state.snoozeCount === '') {
            errors.push("&bull; Öteleme ayarı için lütfen en fazla öteleme sayısını belirtiniz.");
        }

        if (this.state.snoozeCount !== '' && this.state.snoozeTime === '') {
            errors.push("&bull; Öteleme sayısı belirtilmiş, ötelemeyi aktifleştirmek için lütfen dakika belirtiniz.");
        }

        if (this.state.tickermessages.length === 0 && this.state.mediaType === 'TICKER') {
            errors.push("&bull; Lütfen en az bir adet kayan yazı içeriği giriniz.");
        }

        if (this.state.mediaType === 'QUIZ' || this.state.mediaType === 'ELEARNING' || this.state.mediaType === 'SURVEY') {
            if (this.state.mediaType === 'QUIZ' || this.state.mediaType === 'SURVEY') {
                if (this.state.carouselItems.length === 0) {
                    errors.push("&bull; Lütfen en az bir adet soru giriniz.");
                }
            }

            if ((this.state.mediaType === 'QUIZ' || this.state.mediaType === 'ELEARNING') && this.state.carouselItems.length > 0) {
                for (let [itemIndex, carouselItem] of this.state.carouselItems.entries()) {
                    let correctOptions = carouselItem.options.filter(o => o.is_correct === true);

                    if (carouselItem.question.trim() === '') {
                        errors.push("&bull; Lütfen " + (itemIndex + 1) + ". sorunuzun soru metnini doldurunuz.");
                    }

                    if (!carouselItem.freeform && correctOptions.length === 0) {
                        errors.push("&bull; Lütfen " + (itemIndex + 1) + ". sorunuz için bir doğru cevap seçiniz.");
                    }
                }
            }
        }

        if (this.state.mediaType === 'LCV') {
            if (this.state.lcv.start_date === '') {
                errors.push("&bull; Lütfen etkinlik başlangıç tarihini belirtiniz.");
            }

            if (this.state.lcv.end_date === '') {
                errors.push("&bull; Lütfen etkinlik bitiş tarihini belirtiniz.");
            }

            if (this.state.lcv.response_date === '') {
                errors.push("&bull; Lütfen son cevap tarihini belirtiniz.");
            }
        }

        if (errors.length > 0) {
            this.setState({ showError: true, showMessages: errors.join('<br/>') });
            window.scrollTo(0, 0);
            return false;
        }

        console.log("validation completed.");
        await this.startSendMessage();
    }

    publishMessage = async (asDraft = false, isTest = false) => {
        let isAllGroups = this.state.isAllGroups;
        let sendNow = false;
        let publishStatus = 'DRAFT';

        if (this.state.groups.length === 1 && this.state.groups[0]['value'] === "*") {
            isAllGroups = true;
        }

        if (!asDraft) {
            if (moment().isSame(this.state.sendDate.iso, "day") && this.state.sendDate.sendTime === '') {
                sendNow = true;
            }

            if (sendNow) {
                publishStatus = 'SENT';
            } else {
                publishStatus = 'PENDING';
            }
        }

        if (isTest) {
            publishStatus = 'TEST';
        }

        let payload = {
            "announcementType": this.state.mediaNamespace,
            "description": this.state.content,
            "summary": this.state.messageTitle,
            "type": this.state.mediaType,
            "deliveryOption": this.getDeliveryOption(),
            "notificationType": this.state.notifyType,
            "folder": this.state.folder,
            "groups": isAllGroups ? [] : this.state.groups.map((item) => { return item.value }),
            "IsAllGroups": isAllGroups,
            "bgcolor": this.state.bgcolor,
            "startDate": sendNow ? '' : this.state.sendDate.iso.format('YYYY-MM-DD HH:mm:00.0'),
            "endDate": "", // TODO: hemen seciliyse
            "isPublished": publishStatus,
            "sender": this.state.sender,
            "snoozeTime": this.state.snoozeTime === '' ? 0 : this.state.snoozeTime,
            "snoozeCount": this.state.snoozeCount === '' ? 0 : this.state.snoozeCount,
            "title": this.state.title,
            "position": this.state.position,
            "speed": this.state.speed,
            "repeatCount": this.state.repeatCount,
            "tickermessages": this.state.tickermessages,
            "questions": this.state.carouselItems,
            "analysisEndNote": this.state.analysisEndNote,
            "video": this.state.video !== null ? this.state.video.id : null,
            "image": this.state.image !== null ? this.state.image.id : null,
            "lcv": this.state.lcv,
            "windowSize": this.state.windowSize,
            "periodInterval": this.state.periodInterval,
            "periodCount": this.state.periodCount,
            "periodReaming": this.state.periodCount,
            "files": this.state.files,
            "includeUser": this.state.includeUser ? this.state.includeUser.map(item => item.value) : [],
            "excludeUser": this.state.excludeUser ? this.state.excludeUser.map(item => item.value) : [],
        };

        console.log(payload);

        this.setState({ sending: true, hasChanges: false }, async () => {
            await sendMessage(payload);

            localStorage.setItem('bgcolor', this.state.bgcolor);
            localStorage.setItem('forecolor', this.state.forecolor);

            this.props.history.push("/media/list/" + this.state.mediaType);
        });
    };

    /* Standart methods for all pages */
    componentDidMount() {
        PubSub.publish('MENU', 'medias');

        this.updatePageTitle();

        document.addEventListener("keypress", () => {
            this.setState({ hasChanges: true })
        })
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.subscribeToken);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.type !== nextProps.match.params.type) {
            this.setState({ ...pageState }, () => {
                this.updatePage(nextProps);
            });
        }

        this.setState({ hasChanges: false })
    }

    componentWillMount() {
        this.updatePage(this.props);
    }

    updatePage = async (props) => {
        let mediaId = props.match.params.id;
        let media = MessageTypes[props.match.params.type];
        let action = props.match.params.action;
        let mediaObject = { notifyType: null, devices: { mobile: true, desktop: true }, isAllGroups: false };

        mediaObject.notifyType = media.defaults.notifyType;
        mediaObject.devices = media.defaults.devices;
        mediaObject.isAllGroups = media.defaults.isAllGroups;

        this.setState({
            ...this.state,
            media: media,
            mediaNamespace: media.namespace,
            mediaType: props.match.params.type,
            action: action,
            notifyType: mediaObject.notifyType,
            devices: mediaObject.devices,
            isAllGroups: mediaObject.isAllGroups,
            bgcolor: media.defaults.bgColor,
            forecolor: media.defaults.textColor
        }, () => {
            this.updatePageTitle();
            this.updateLists(mediaId, action);
        });
    };

    updatePageTitle = () => {
        setPageTitle(this.state.media.title);
    };

    subscribeDate = (msg, data) => {
        this.setState({
            sendDate: { iso: data.iso, readable: data.readable, sendTime: '' }
        });
    };
    /* Standart methods for all pages */

    // TODO: fix
    cloneMedia = async (id) => {
        let clonedMedia = await getAnnouncement(id);
        let media = MessageTypes[this.props.match.params.type];

        this.setState({
            id: 0,
            hasTitleChanged: true,
            action: 'clone',
            media: media,
            mediaNamespace: media.namespace,
            mediaType: clonedMedia.type,
            messageTitle: clonedMedia.summary,
            title: clonedMedia.title,
            notifyType: clonedMedia.notificationType,
            folder: clonedMedia.folder.id,
            groups: clonedMedia.groups ? clonedMedia.groups.map(g => { return { id: g.id, label: g.name, value: g.id } }) : [],
            content: clonedMedia.description,
            sender: clonedMedia.sender,
            isAllGroups: clonedMedia.isAllGroups,
            snoozeTime: clonedMedia.snoozeTime,
            snoozeCount: clonedMedia.snoozeCount,
            bgcolor: clonedMedia.bgcolor,
            position: clonedMedia.position,
            speed: clonedMedia.speed,
            repeatCount: clonedMedia.repeatCount,
            periodInterval: clonedMedia.repeatCount,
            periodCount: clonedMedia.periodCount,
            devices: {
                mobile: clonedMedia.deliveryOption === 'ALL' ? true : clonedMedia.deliveryOption === 'MOBILE',
                desktop: clonedMedia.deliveryOption === 'ALL' ? true : clonedMedia.deliveryOption === 'DESKTOP'
            },
            windowSize: clonedMedia.windowSize,
            analysisEndNote: clonedMedia.analysisEndNote,
            separator: '',
            forecolor: '#000000',
            texts: clonedMedia.tickermessages ? clonedMedia.tickermessages.filter(tm => tm.class === "ticker-item").map(tm => { return { class: "ticker-item", content: tm.content.replace(/<[^>]*>?/gm, '') } }) : [{ 'content': '', 'class': 'ticker-item' }],
            tickermessages: clonedMedia.tickermessages ? clonedMedia.tickermessages : [],
            carouselItems: [],
            carouselActiveSlide: 0,
            image: null,
            video: null,
            lcv: {
                accept_button: 'Evet',
                decline_button: 'Hayır',
                maybe_button: 'Belki',
                location: '',
                start_date: new Date(),
                end_date: new Date(),
                response_date: new Date(),
                optional_response_date: false
            },
            files: clonedMedia.files ? clonedMedia.files : []
        });
    }

    updateLists = async (mediaId = null, action = "") => {
        // TODO: check api connection and show global error from master layout

        // folders
        let folderResponse = await getFolders();
        let folderArr = folderResponse.map((item) => { return { "id": item.id, "value": item.id, "label": item.name } });

        // groups
        let groupResponse = await getGroups();
        let groupArr = groupResponse.map((item) => { return { "id": item.id, "value": item.id, "label": item.name } });

        this.setState({
            folderOptions: folderArr,
            groupOptions: groupArr
        }, () => {
            if (action === mediaActions.CLONE) {
                this.cloneMedia(mediaId);
            }
        });
    };

    resetDate = () => {
        this.setState({
            sendDate: { iso: moment(), readable: 'Hemen', sendTime: '' }
        });
    };

    showSettings = (show) => {
        this.setState({
            showSettings: show
        });
    };

    handleTitleKeyDown = (e) => {
        if (e.keyCode === 40) {
            this.setState({ emojiPickerShow: true });
            e.preventDefault();
        } else {
            this.setState({ emojiPickerShow: false });
        }
    };

    handleGroupChange = (value) => {
        this.setState({
            groups: value
        })
    };

    getDeliveryOption = () => {
        let deliveryOption = "";

        if (this.state.devices.desktop && this.state.devices.mobile) {
            deliveryOption = deliveryOptionsTypes.ALL;
        } else if (!this.state.devices.desktop && this.state.devices.mobile) {
            deliveryOption = deliveryOptionsTypes.MOBILE;
        } else {
            deliveryOption = deliveryOptionsTypes.DESKTOP;
        }

        if (!this.state.devices.desktop && !this.state.devices.mobile) {
            deliveryOption = "";
        }

        return deliveryOption;
    };

    addTickerText = () => {
        this.setState({
            texts: [...this.state.texts, { 'content': '', 'class': 'ticker-item' }]
        }, () => this.createTickerTexts())
    }

    removeTickerText = (index) => {
        let newArr = [...this.state.texts];
        newArr.splice(index, 1);

        this.setState({
            texts: newArr
        }, () => this.createTickerTexts());
    }

    changeTickerText = (val, index) => {
        let rows = [...this.state.texts];

        rows[index] = {
            ...rows[index],
            'content': val
        };

        this.setState({
            texts: rows
        }, () => this.createTickerTexts());
    }

    createTickerTexts = () => {
        if (this.state.texts.length > 0) {
            let tickermessages = [];

            this.state.texts.map((text) => {
                let message = {
                    'content': `<label style='color:${this.state.forecolor}'>${text.content}</label>`,
                    'class': 'ticker-item'
                };

                tickermessages.push(message);

                if (this.state.separator !== '') {
                    tickermessages.push({ 'content': this.state.separator, 'class': 'ticker-separator' });
                }
            });

            this.setState({ tickermessages: tickermessages });
        }
    }

    getTickerTexts = () => {
        let list = []

        this.state.texts.map((text, index) => {
            list.push(
                <div key={`textRow${index}`} className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Mesajınızı başlıklar halinde yazınız." value={text.content} onChange={e => this.changeTickerText(e.target.value, index)} />
                    <div className="input-group-append">
                        <Tooltip placement="top" content="Yeni bir mesaj satırı ekle">
                            <button className="btn btn-primary" type="button" disabled={index < this.state.texts.length - 1} onClick={() => this.addTickerText()}><i className="la la-plus" /></button>
                        </Tooltip>

                        <Tooltip placement="top" content="Mesajı sil">
                            <button className="btn btn-danger" type="button" disabled={index === 0} onClick={() => this.removeTickerText(index)}><i className="la la-trash" /></button>
                        </Tooltip>
                    </div>
                </div>
            )
        });

        return list;
    }

    getEditor = (targetName, placeholder) => {
        const toolbarMediaButton = this.state.mediaType === 'ELEARNING' ? '' : 'media';
        const val = this.state[targetName];

        return (
            <>
                <Editor
                    apiKey="4wp2oams7a8jp489lzu8ywwx16edfuxlxrhjchhl7vsbafut"
                    value={val}
                    init={{
                        selector: 'textarea#myTextArea',
                        height: 120,
                        menubar: false,
                        inline: true,
                        toolbar_mode: "sliding",
                        language: "tr",
                        language_url: '/js/tr.js',
                        placeholder: placeholder,
                        automatic_uploads: true,
                        image_uploadtab: true,
                        file_browser_callback_types: 'image media',
                        contextmenu: false,
                        images_upload_url: settings.apiBase + 'announcement/fileuploads',
                        images_upload_base_path: settings.apiBase,
                        images_upload_credentials: false,
                        browser_spellcheck: true,
                        images_upload_handler: function (blobInfo, success, failure, progress) {
                            let data = new FormData();
                            let config = { header: { 'Content-Type': 'multipart/form-data' } }

                            data.append('file', blobInfo.blob(), blobInfo.filename());

                            protectedApi.post('/announcement/fileuploads', data, config).then(response => {
                                success(settings.apiBaseClear + response.data.file);
                            }).catch(error => {
                                failure(error);
                            })
                        },
                        plugins: [
                            'autolink lists link customUserButton emoticons image media anchor code table paste code help'
                        ],
                        toolbar:
                            'undo redo | fontsizeselect | bold italic forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        customUserButton link image ' + toolbarMediaButton + ' table emoticons | \
                        bullist numlist | removeformat | code help'
                    }}
                    onEditorChange={(content, editor) => {
                        this.setState({
                            [targetName]: content,
                            // title: editor.getContent({format : 'text'}).substring(0,150)
                        });

                        // hack the editor to enable spellcheck
                        editor.bodyElement.spellcheck = true;
                    }}
                    onInit={(event, editor) => {
                        this.editorBody = editor.bodyElement;

                        editor.ui.registry.addButton('customUserButton', {
                            icon: 'user',
                            tooltip: 'Kullanıcı Bilgileri Ekle',
                            disabled: true,
                            onAction: function (_) {
                                //editor.insertContent("#AD#");

                                editor.windowManager.open({
                                    title: 'Kişiye Özel Gönderi',
                                    body: {
                                        type: 'panel',
                                        items: [{
                                            type: 'selectbox',
                                            name: 'type',
                                            label: '',
                                            items: [
                                                { text: 'Ad', value: '#AD#' },
                                                { text: 'Soyad', value: '#SOYAD#' },
                                                { text: 'Ad Soyad', value: '#AD# #SOYAD#' },
                                            ],
                                            flex: true
                                        }]
                                    },
                                    onSubmit: function (api) {
                                        // insert markup
                                        editor.insertContent(api.getData().type);

                                        // close the dialog
                                        api.close();
                                    },
                                    buttons: [
                                        {
                                            text: 'Vazgeç',
                                            type: 'cancel',
                                            onclick: 'close'
                                        },
                                        {
                                            text: 'Ekle',
                                            type: 'submit',
                                            primary: true,
                                            enabled: false
                                        }
                                    ]
                                });




                            },
                            onSetup: function (buttonApi) {
                                var editorEventCallback = function (eventApi) {
                                    buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase() === 'time');
                                };
                                editor.on('NodeChange', editorEventCallback);

                                /* onSetup should always return the unbind handlers */
                                return function (buttonApi) {
                                    editor.off('NodeChange', editorEventCallback);
                                };
                            }
                        });

                    }}
                />
            </>
        )
    }

    addCarouselItemOption = (index, type) => {
        const data = [...this.state.carouselItems[index].options, { "title": "", "is_correct": false, "type": type }];

        this.changeCarouselItemData(index, "options", data);
    }

    removeCarouselItemOption = (index, optionIndex) => {
        let data = [...this.state.carouselItems[index].options];

        data.splice(optionIndex, 1);

        this.changeCarouselItemData(index, "options", data);
    }

    carouselItemListDragHandler = (fromIndex, toIndex) => {
        const activeItemIndex = this.slider.current.carouselStore.state.currentSlide - 1;
        const data = [...this.state.carouselItems[activeItemIndex].options];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);

        this.changeCarouselItemData(activeItemIndex, "options", data);
    }

    changeCarouselItemOptionData = (index, optionIndex, field, val, reverseOthers = false) => {
        const data = [...this.state.carouselItems[index].options];
        const item = data[optionIndex];

        if (reverseOthers) {
            data.forEach(opt => {
                opt[field] = !val
            })
        }

        item[field] = val;

        this.changeCarouselItemData(index, "options", data);
    }

    changeCarouselItemData = (index, field, val) => {
        let rows = [...this.state.carouselItems];

        rows[index] = {
            ...rows[index],
            [field]: val
        };

        this.setState({
            carouselItems: rows
        });
    }

    removeCarouselItem = (index) => {
        let newArr = [...this.state.carouselItems];
        newArr.splice(index, 1);

        this.setState({
            carouselItems: newArr
        });
    }

    addCarouselItem = () => {
        const slides = [...this.state.carouselItems, { "question": "", "required": false, "freeform": false, "options": [] }];
        this.setState({
            carouselItems: slides,
            carouselActiveSlide: slides.length
        })
    }

    getCarouselItems = () => {
        let items = [];

        items.push(
            <Slide index={0} onFocus={e => console.log(e)} style={{ overflow: 'scroll' }}>
                {this.getEditor('content', 'Soruları oluşturmaya başlamadan önce katılımcılar için bir ön yazı ekleyebilirsiniz.')}
            </Slide>)

        {
            this.state.carouselItems.map((item, index) => {
                items.push(
                    <Slide key={`slider_${index}`} index={index + 1} onFocus={e => console.log(e)}>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <Tooltip placement="top" content="Soruyu sil">
                                    <button className="btn btn-danger" onClick={() => this.removeCarouselItem(index)}><i className="flaticon-381-trash" /></button>
                                </Tooltip>
                                <Tooltip placement="top" content={item.required ? 'Zorunluluğu kaldır' : 'Zorunlu yap'}>
                                    <button className="btn btn-warning" onClick={() => this.changeCarouselItemData(index, 'required', !item.required)}>{item.required && <i className="flaticon-381-warning-1" />}{!item.required && <i className="flaticon-381-success-1" />}</button>
                                </Tooltip>
                            </div>
                            <input type="text" className="form-control" placeholder="Soru metninizi buraya yazınız." value={item.question} onChange={e => this.changeCarouselItemData(index, 'question', e.target.value)} />
                            <div className="input-group-append">
                                <div className="dropdown">
                                    <button className="btn btn-primary dropdown-toggle" data-toggle="dropdown" />
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="javascript:void(0)" onClick={() => this.changeCarouselItemData(index, 'freeform', false)}>{!item.freeform && <i className="flaticon-381-success-2" />} Çoktan Tekli Soru</a>
                                        {this.state.mediaType !== 'ELEARNING' &&
                                            <a className="dropdown-item" href="javascript:void(0)" onClick={() => this.changeCarouselItemData(index, 'freeform', true)}>{item.freeform && <i className="flaticon-381-success-2" />} Açık Uçlu Soru</a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!item.freeform &&
                            <div>
                                <div className="row">
                                    <div className="col-6">
                                        <button type="button" className="btn btn-xs btn-block btn-light" onClick={() => this.addCarouselItemOption(index, this.state.mediaType === 'SURVEY' ? 'checkbox' : 'radio')}><input type="radio" disabled={true} /> Çoktan seçmeli seçenek ekle</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="btn btn-xs btn-block btn-light" onClick={() => this.addCarouselItemOption(index, 'text')}><i className="la la-pencil" /> Açık uçlu seçenek ekle</button>
                                    </div>
                                </div>
                                <br />
                                <ReactDragListView
                                    nodeSelector='li'
                                    handleSelector='a'
                                    onDragEnd={this.carouselItemListDragHandler}
                                >
                                    <ul>
                                        {item.options.map((option, optionIndex) => {
                                            return (
                                                <li key={index}>
                                                    <div key={`textOptionRow${optionIndex}`} className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-danger" onClick={e => this.removeCarouselItemOption(index, optionIndex)}><i className="flaticon-381-trash" /></button>
                                                            <div className="input-group-text">
                                                                {this.state.mediaType === 'SURVEY' &&
                                                                    <input type="checkbox" name={`optionCorrect${index}`} readOnly disabled />
                                                                }

                                                                {this.state.mediaType !== 'SURVEY' &&
                                                                    <input type="radio" name={`optionCorrect${index}`} checked={option.is_correct} onChange={e => this.changeCarouselItemOptionData(index, optionIndex, 'is_correct', e.target.checked, true)} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <input type="text" className="form-control" value={option.title} onChange={e => this.changeCarouselItemOptionData(index, optionIndex, 'title', e.target.value)} placeholder={`${(option.type === 'radio' || option.type === 'checkbox') ? 'Cevap seçeneği alanı.' : 'Açık uçlu yanıt alanı'}`} disabled={option.type === 'text'} style={{ backgroundColor: `${option.type === 'text' ? '#e1e1e1' : '#ffffff'}` }} />
                                                        <div className="input-group-append">
                                                            <a className="btn btn-primary" type="button"><i style={{ color: '#ffffff' }} className="la la-arrows-v" /></a>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </ReactDragListView>
                            </div>
                        }

                        {item.freeform &&
                            <textarea className="form-control" rows={3} style={{ backgroundColor: '#e2e2e2' }} placeholder="Serbest giriş alanı." disabled={true} readOnly={true} />
                        }
                    </Slide>
                )
            })
        }

        items.push(
            <Slide index={this.state.carouselItems.length + 1} onFocus={e => console.log(e)} style={{ overflow: 'scroll' }}>
                {this.getEditor('analysisEndNote', 'Soruları tamamlayan katılımcılar için bir kapanış yazısı ekleyebilirsiniz.')}
            </Slide>
        )

        return items;
    }

    getCarouselItemsForPreview = () => {
        let items = [];

        items.push(
            <Slide index={0} onFocus={e => console.log(e)} style={{ overflow: 'scroll' }}>
                <div>
                    {this.state.video !== null &&
                        <>
                            <Player src={this.state.video.url}>
                                <BigPlayButton position="center" />
                            </Player>
                        </>
                    }
                    <div className="previewDiv">{parse(this.state.content)}</div>
                    <button className="btn btn-warning btn-sm">{`${this.state.mediaType === 'SURVEY' ? 'Ankete' : 'Teste'} Başla`}</button>
                </div>
            </Slide>)

        {
            this.state.carouselItems.map((item, index) => {
                items.push(
                    <Slide index={index + 1} onFocus={e => console.log(e)}>
                        <b>{item.question}</b>

                        {!item.freeform &&
                            <ul>
                                {item.options.map((option) => {
                                    return (
                                        <li key={index} style={{ height: 20 }}>
                                            {this.state.mediaType === 'SURVEY' &&
                                                <input type="checkbox" name={`optionCorrect${index}`} />
                                            }

                                            {this.state.mediaType !== 'SURVEY' &&
                                                <input type="radio" name={`optionCorrect${index}`} checked={option.is_correct} />
                                            }

                                            {(option.type === 'radio' || option.type === 'checkbox') &&
                                                <label>{option.title}</label>
                                            }

                                            {option.type === 'text' &&
                                                <input readOnly={true} type="text" disabled={true} style={{ backgroundColor: '#e1e1e1', borderRadius: 4, border: 'solid 1px #ccc' }} placeholder="Açık uçlu yanıt alanı." />
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                        }

                        {item.freeform &&
                            <textarea className="form-control" rows={1} placeholder="Serbest giriş alanı." disabled={true} readOnly={true} />
                        }
                    </Slide>
                )
            })
        }

        items.push(
            <Slide index={this.state.carouselItems.length + 1} onFocus={e => console.log(e)} style={{ overflow: 'scroll' }}>
                <>
                    <div className="previewDiv">{parse(this.state.analysisEndNote)}</div>
                    <button className="btn btn-warning btn-sm">Tamamla</button>
                </>
            </Slide>
        )

        return items;
    }

    getLcvButtons = (preview = false) => {
        if (!preview) {
            return (
                <>
                    <br /><small style={{ fontSize: 10 }}><em>* Yanıt seçeneklerini istediğiniz şekilde değiştirebilirsiniz.</em></small><br />
                    <Tooltip content="Olumlu Cevap" placement="bottom">
                        <input type="text" style={{ width: 200 }} className="btn btn-success btn-rounded mr-2" value={this.state.lcv.accept_button} onChange={e => this.setState({ lcv: { ...this.state.lcv, accept_button: e.target.value } })} />
                    </Tooltip>

                    <Tooltip content="Olumsuz Cevap" placement="bottom">
                        <input type="text" style={{ width: 200 }} className="btn btn-danger btn-rounded mr-2" value={this.state.lcv.decline_button} onChange={e => this.setState({ lcv: { ...this.state.lcv, decline_button: e.target.value } })} />
                    </Tooltip>

                    <Tooltip content="Belirsiz Cevap" placement="bottom">
                        <input type="text" style={{ width: 200 }} className="btn btn-light btn-rounded" value={this.state.lcv.maybe_button} onChange={e => this.setState({ lcv: { ...this.state.lcv, maybe_button: e.target.value } })} />
                    </Tooltip>
                </>
            )
        } else {
            return (
                <div>
                    <small>
                        <b>Başlama Tarihi</b> <label>{dateFormat(this.state.lcv.start_date)}</label><br />
                        <b>Bitiş Tarihi</b> <label>{dateFormat(this.state.lcv.end_date)}</label><br />
                        <b>Son Cevap Tarihi</b> <label>{dateFormat(this.state.lcv.response_date)}</label>
                    </small>
                    <br />
                    <button type="button" className="btn btn-success btn-md btn-rounded mr-2">{this.state.lcv.accept_button}</button>
                    <button type="button" className="btn btn-danger btn-md btn-rounded mr-2">{this.state.lcv.decline_button}</button>
                    <button type="button" className="btn btn-light btn-md btn-rounded">{this.state.lcv.maybe_button}</button>
                </div>
            )
        }
    }

    setSendDateTime = (t) => {
        const timeReadable = moment(t).format('HH:mm');
        const sendDate = this.state.sendDate.iso;
        let readable = "";

        console.log(this.state.sendDate);

        if (t) {
            if (moment().isSame(sendDate, "day")) {
                readable = "Bugün" + " " + timeReadable;
            } else {
                readable = sendDate.format('DD.MM') + " " + timeReadable;
            }

            sendDate.set({ hour: t.get('hour'), minute: t.get('minute'), second: t.get('second') });
        } else {
            if (moment().isSame(sendDate, "day")) {
                readable = "Hemen";
            } else {
                readable = sendDate.format('DD.MM.YYYY');
            }
        }

        this.setState({
            sendDate: { iso: sendDate, sendTime: t, readable: readable }
        }, () => {
            console.log(this.state.sendDate);
        });
    }

    handleFileUpload = (file) => {
        let data = new FormData();
        let config = { header: { 'Content-Type': 'multipart/form-data' } }

        this.setState({ isUploading: true, files: [...this.state.files, null] });

        data.append('file', file, file.name);

        protectedApi.post('/announcement/fileuploads', data, config).then(response => {
            let uploadedFile = {
                id: response.data.id,
                name: file.name,
                url: settings.apiBaseClear + response.data.file,
                thumbnailUrl: settings.apiBaseClear + response.data.file,
                isThumbnail: false,
                isVideo: false,
                color: "#3B4182",
                icon: null,
                type: file.type
            }

            let allFiles = [...this.state.files.filter(f => f !== null)];

            allFiles.push(uploadedFile);


            this.setState({ files: allFiles, showError: false, isUploading: false, waitingForSelectPreviewImage: false });

        }).catch(error => {
            this.setState({ files: [...this.state.files.filter(f => f !== null)], isUploading: false, showError: true, showMessages: "&bull; Dosya yükleme işlemi başarısız." }, () => {
                window.scrollTo(0, 0);
            })
        })
    }

    handleFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let selectedFile = event.target.files[0];

        this.handleFileUpload(selectedFile);
    }

    handleFileBrowserAction = (event) => {
        if (event.id === "upload_files") {
            this.upload.click();
        }

        if (event.id === "upload_thumbnail") {
            this.setState({ waitingForSelectPreviewImage: true }, () => {
                this.upload.click();
            });
        }

        if (event.id === "delete_files") {
            let allFiles = [...this.state.files];
            let newFiles = allFiles.filter(i => !event.state.selectedFiles.some(j => j.id === i.id));

            this.setState({ files: newFiles });
        }

        if (event.id === "set_thumbnail") {
            if (!event.state.selectedFiles || event.state.selectedFiles.length === 1) {
                let allFiles = JSON.parse(JSON.stringify([...this.state.files]));
                let index = allFiles.findIndex(x => x.id === event.state.selectedFiles[0].id);

                for (let f of allFiles) {
                    f.isThumbnail = false;
                    f.icon = null;
                    f.color = "#3B4182";
                }

                allFiles[index].isThumbnail = true;
                allFiles[index].icon = "image";
                allFiles[index].color = "#F4C752";

                this.setState({ files: allFiles, image: event.state.selectedFiles[0] });
            } else {
                this.setState({ showError: true, showMessages: "&bull; Öne çıkan görseli ayarlamak için lütfen bir görsel seçiniz." }, () => {
                    window.scrollTo(0, 0);
                })
            }
        }

        if (event.id === "set_video") {
            if (!event.state.selectedFiles || event.state.selectedFiles.length === 1) {
                let allFiles = JSON.parse(JSON.stringify([...this.state.files]));
                let index = allFiles.findIndex(x => x.id === event.state.selectedFiles[0].id);

                for (let f of allFiles) {
                    f.isVideo = false;
                    f.icon = null;
                    f.color = "#3B4182";
                }

                allFiles[index].isVideo = true;
                allFiles[index].icon = "video";
                allFiles[index].color = "#F4C752";

                this.setState({ files: allFiles, image: event.state.selectedFiles[0] });
            } else {
                this.setState({ showError: true, showMessages: "&bull; Eğitim videosunu ayarlamak için lütfen bir video seçiniz." }, () => {
                    window.scrollTo(0, 0);
                })
            }
        }

        if (event.id === "preview_file" && event.state.selectedFiles.length === 1) {
            window.open(event.state.selectedFiles[0].url, "_blank")
        }
    }

    render() {
        return (
            <>
                <Prompt
                    when={this.state.hasChanges}
                    message={location => `Sayfada değişiklikler var ayrılmak için emin misiniz?`}
                />
                <LoadingOverlay
                    active={this.state.sending}
                    // spinner
                    // text=''
                    styles={{ wrapper: { width: '100%', height: '100vh' } }}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="alert alert-danger" style={{ display: this.state.showError ? 'block' : 'none' }}><p dangerouslySetInnerHTML={{ __html: this.state.showMessages }} /></div>
                                <div className="card">
                                    <div className="card-header border-0 pb-0">
                                        <Picker
                                            native={true}
                                            showPreview={true}
                                            color="#3B4182"
                                            title="Duyuroom"
                                            onSelect={emoji => { this.setState({ emojiPickerShow: false, messageTitle: document.getElementById('txtTitle').value + emoji.native }); document.getElementById('txtTitle').focus() }}
                                            style={{ display: this.state.emojiPickerShow ? 'block' : 'none', position: 'absolute', top: '65px', left: '130px', zIndex: 9999 }}
                                            i18n={{
                                                search: 'Ara',
                                                clear: 'Temizle',
                                                notfound: 'Emoji bulunamadı',
                                                skintext: 'Varsayılan ton seçiniz',
                                                categories: {
                                                    search: 'Arama sonuçları',
                                                    recent: 'Sık Kullanılan',
                                                    smileys: 'Duygular',
                                                    people: 'İnsanlar',
                                                    nature: 'Hayvanlar ve Doğa',
                                                    foods: 'Yeme ve İçme',
                                                    activity: 'Etkinlik',
                                                    places: 'Yolculuk',
                                                    objects: 'Nesneler',
                                                    symbols: 'Semboller',
                                                    flags: 'Bayraklar',
                                                    custom: 'Özel',
                                                },
                                                categorieslabel: 'Kategoriler',
                                                skintones: {
                                                    1: 'Default Skin Tone',
                                                    2: 'Light Skin Tone',
                                                    3: 'Medium-Light Skin Tone',
                                                    4: 'Medium Skin Tone',
                                                    5: 'Medium-Dark Skin Tone',
                                                    6: 'Dark Skin Tone',
                                                }
                                            }}
                                        />

                                        <EdiText
                                            ref={elm => { this.summaryElement = elm }}
                                            value={this.state.messageTitle}
                                            type="text"
                                            buttonsAlign="before"
                                            inputProps={{ placeholder: 'Mesaj başlığınızı buraya yazınız.', onKeyDown: this.handleTitleKeyDown, id: "txtTitle", style: { border: 'none', width: '100%' } }}
                                            viewProps={{ className: 'w-100', style: { fontFamily: 'Roboto', fontSize: 22, fontWeight: 'bold', color: '#424242' } }}
                                            hideIcons={true}
                                            showButtonsOnHover={false}
                                            editOnViewClick={true}
                                            cancelOnEscape={false}
                                            submitOnEnter={true}
                                            submitOnUnfocus={false}
                                            editButtonContent="✎"
                                            cancelButtonContent="✕"
                                            saveButtonContent="✓"
                                            saveButtonClassName="btn btn-success light btn-xs"
                                            cancelButtonClassName="btn btn-danger light btn-xs ml-1"
                                            editButtonClassName="btn btn-light btn-xs"
                                            viewContainerClassName=""
                                            editContainerClassName=""
                                            mainContainerClassName="w-100"
                                            validationMessage="Başlık zorunlu."
                                            validation={(value) => { return value.trim() !== '' }}
                                            onEditingStart={(value, inputProps) => { this.setState({ btnEmojiDisabled: false }); this.summaryElement.input.current.select() }}
                                            onSave={(value, inputProps) => { this.setState({ messageTitle: value, hasTitleChanged: true, btnEmojiDisabled: true }) }}
                                            onCancel={() => this.setState({ btnEmojiDisabled: true })}
                                        />

                                        <div>
                                            <div style={{ position: 'absolute', marginTop: -24, left: 30 }}>
                                                <small style={{ fontSize: 10 }}><em><i className="la la-exclamation-triangle" /> Mesaj başlığınızı yazdıktan sonra lütfen başlığı onaylayınız.</em></small>
                                            </div>

                                            <div style={{ position: 'absolute', width: 200, marginTop: -24, marginLeft: 6 }}>
                                                <Tooltip content="Başlığa emoji ekleyebilirsiniz." placement="top">
                                                    <button disabled={this.state.btnEmojiDisabled} className="btn btn-light btn-sm" style={{ position: 'absolute', marginLeft: -60, marginTop: 23, color: '#424242', fontSize: 20, width: 45, height: 45, borderRadius: 22.5 }} onClick={() => this.setState({ emojiPickerShow: !this.state.emojiPickerShow })}>🙂</button>
                                                </Tooltip>

                                                <small style={{ fontSize: 10 }}><em><i className="la la-exclamation-triangle" /> Takvimden değişebilir.</em></small>
                                            </div>

                                            <Tooltip content="Gönderim tarihi ayarlayınız." placement="top">
                                                <div className="dropdown">
                                                    <button className="btn btn-light btn-rounded btn-sm dropdown-toggle" data-toggle="dropdown" style={{ width: 126 }}><i className="flaticon-381-calendar" /> {this.state.sendDate.readable}</button>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a className="dropdown-item" href="javascript:void(0)" onClick={this.resetDate}><i className="flaticon-381-calendar" /> Hemen</a>
                                                        <a className="dropdown-item" href="javascript:void(0)"><i className="flaticon-381-clock" /><TimePicker clearText="Temizle" placeholder="Gönderim saati" showSecond={false} minuteStep={15} inputReadOnly={true} value={this.state.sendDate.sendTime} onChange={t => this.setSendDateTime(t)} /></a>
                                                        <div className="dropdown-divider" />
                                                        <a className="dropdown-item" href="javascript:void(0)"><i className="flaticon-381-flag-1" /> Bitiş Zamanı</a>
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ paddingLeft: 81 }}>
                                        {(this.state.mediaType === 'POPUP' || this.state.mediaType === 'EMERGENCY') &&
                                            <>{this.getEditor('content', 'Buraya mesajınızı en iyi anlatacak metni ve görselleri ekleyiniz.')}</>
                                        }

                                        {this.state.mediaType === 'TICKER' &&
                                            <div className="row pr-3">
                                                {this.getTickerTexts()}
                                            </div>
                                        }

                                        {this.state.mediaType === 'LCV' &&
                                            <>
                                                {this.getEditor('content', 'Buraya mesajınızı en iyi anlatacak metni ve görselleri ekleyiniz.')}
                                                {this.getLcvButtons()}
                                            </>
                                        }

                                        {(this.state.mediaType === 'QUIZ' || this.state.mediaType === 'ELEARNING' || this.state.mediaType === 'SURVEY') &&
                                            <div className="content-carousel">
                                                <CarouselProvider
                                                    ref={this.slider}
                                                    className="content-carousel"
                                                    naturalSlideWidth={800}
                                                    naturalSlideHeight={300}
                                                    totalSlides={this.state.carouselItems.length + 2}
                                                    touchEnabled={false}
                                                    dragEnabled={false}
                                                    disableKeyboard={true}
                                                    currentSlide={this.state.carouselActiveSlide}
                                                >
                                                    <Slider className="content-carousel">
                                                        {this.getCarouselItems()}
                                                    </Slider>
                                                    <ButtonBack
                                                        className="btn btn-primary btn-rounded btn-md mr-1"
                                                    >{"<"}
                                                    </ButtonBack>
                                                    <ButtonNext
                                                        className="btn btn-primary btn-rounded btn-md"
                                                    >{">"}
                                                    </ButtonNext>
                                                </CarouselProvider>
                                            </div>
                                        }
                                    </div>
                                    <div className="card-footer border-0 pt-0">
                                        <p className="card-text d-inline">&nbsp;</p>
                                        <div className="float-right">

                                            {(this.state.mediaType === 'QUIZ' || this.state.mediaType === 'SURVEY') &&
                                                <button className="btn btn-primary btn-rounded btn-md" style={{ marginRight: 5 }} onClick={() => { this.addCarouselItem() }}>
                                                    Soru Ekle {this.state.carouselItems.length > 0 && `(${this.state.carouselItems.length})`}
                                                </button>
                                            }

                                            {(this.state.mediaType === 'ELEARNING') &&
                                                <Tooltip placement="top" content="* Eğitim sonrası katılımcıların performansını hazırlayacağınız sorularla test edebilirsiniz.">
                                                    <button className="btn btn-danger btn-rounded btn-md" style={{ marginRight: 5 }} onClick={() => { this.addCarouselItem() }}>
                                                        Soru Ekle {this.state.carouselItems.length > 0 && `(${this.state.carouselItems.length})`}
                                                    </button>
                                                </Tooltip>
                                            }


                                            <Tooltip placement="top" content="Mesajınızla ilgili ayarları buradan yapabilirsiniz.">
                                                <button className="btn btn-primary btn-rounded btn-md" style={{ display: this.state.showSettings ? 'none' : 'inline-block' }} onClick={() => { this.showSettings(true) }}>
                                                    Gönderi Ayarları
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.showSettings &&
                                <div className="col-xl-12" style={{ marginBottom: 50 }}>
                                    <div className="card">
                                        <div className="card-header border-0 pb-0">
                                            <h4 className="card-title" style={{ fontFamily: 'Roboto', fontSize: 22, fontWeight: 'bold', color: '#424242' }}>Gönderi Ayarları</h4>
                                            <div className="float-right">
                                                <button className="btn btn-xs btn-rounded light btn-danger" onClick={() => { this.showSettings(false) }}>X</button>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ overflow: 'visible' }}>
                                            <div className="custom-tab-1">
                                                <ul className="nav nav-tabs">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-toggle="tab" href="#target">
                                                            <i className="la la-group mr-1" /> Liste Seçme
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-toggle="tab" href="#options">
                                                            <i className="la la-bell mr-1" /> Ek Ayarlar
                                                        </a>
                                                    </li>

                                                    {this.state.mediaType !== 'TICKER' &&
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#attachments">
                                                                {this.state.mediaType === 'ELEARNING' ?
                                                                    <><i className="la la-graduation-cap mr-1" /> Eğitim
                                                                        Materyalleri</>
                                                                    :
                                                                    <><i className="la la-files-o mr-1" /> Dosya Ekleme</>
                                                                }
                                                            </a>
                                                        </li>
                                                    }

                                                    {this.state.mediaType === 'TICKER' &&
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#ticker_options">
                                                                <i className="la la-ruler-horizontal mr-1" /> Alt Yazı Bandı
                                                            </a>
                                                        </li>
                                                    }

                                                    {this.state.mediaType === 'LCV' &&
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#lcv_options">
                                                                <i className="la la-question-circle mr-1" /> LCV Ayarları
                                                            </a>
                                                        </li>
                                                    }

                                                    {this.state.devices.mobile &&
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#preview_mobile">
                                                                <i className="la la-mobile-phone mr-1" /> Mobil Ön İzleme
                                                            </a>
                                                        </li>
                                                    }

                                                    {this.state.devices.desktop &&
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#preview_desktop">
                                                                <i className="la la-desktop mr-1" /> Masaüstü Ön İzleme
                                                            </a>
                                                        </li>
                                                    }

                                                    <li className="nav-item">
                                                        <a className="nav-link" data-toggle="tab" href="#share">
                                                            <i className="la la-send mr-1" /> Paylaş
                                                        </a>
                                                    </li>
                                                </ul>

                                                {/* --------------- Settings Tabs -------------- */}

                                                <div className="tab-content">
                                                    <div className="tab-pane show active" id="target" role="tabpanel">
                                                        <div className="pt-4">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <p>Göndereceğiniz cihaz tipini seçiniz. <span className="requiredField">*</span></p>
                                                                    {this.state.mediaType !== 'TICKER' &&
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" className="custom-control-input"
                                                                                id="devicesMOBILE" value="MOBILE"
                                                                                checked={this.state.devices.mobile}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        devices: {
                                                                                            ...this.state.devices,
                                                                                            mobile: e.target.checked
                                                                                        }
                                                                                    })
                                                                                }} />
                                                                            <label className="custom-control-label pt-1"
                                                                                htmlFor="devicesMOBILE">Mobil Cihazlar</label>
                                                                        </div>
                                                                    }
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input" id="devicesDESKTOP" value="DESKTOP" checked={this.state.devices.desktop} onChange={(e) => { this.setState({ devices: { ...this.state.devices, desktop: e.target.checked } }) }} />
                                                                        <label className="custom-control-label pt-1" htmlFor="devicesDESKTOP">Bilgisayarlar</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <p>Kime göndereceğinizi seçiniz. <span className="requiredField">*</span></p>
                                                                    <MultiSelectAll
                                                                        itemTitle="Grup"
                                                                        allGroups={this.state.isAllGroups}
                                                                        onChange={this.handleGroupChange}
                                                                        options={this.state.groupOptions}
                                                                    />
                                                                    <br />

                                                                    <label><i className="la la-user-minus " /> Gönderim listesinden isim çıkar</label>
                                                                    <AsyncSelect
                                                                        isMulti={true}
                                                                        isClearable={true}
                                                                        loadingMessage={() => <span>Yükleniyor</span>}
                                                                        noOptionsMessage={() => <span>Henüz kimseyi seçmediniz</span>}
                                                                        onChange={selected => this.setState({ excludeUser: selected })}
                                                                        placeholder="Seçiniz"
                                                                        defaultOptions={[]}
                                                                        loadOptions={inputValue => {
                                                                            return searchUser(inputValue).then(list => {
                                                                                return list.map(u => { return { value: u.id, label: u.first_name + ' ' + u.last_name } })
                                                                            })
                                                                        }}
                                                                    />
                                                                    <br />

                                                                    <label><i className="la la-user-plus" /> Gönderim listesine kişi ekle</label>
                                                                    <AsyncSelect
                                                                        isMulti={true}
                                                                        isClearable={true}
                                                                        loadingMessage={() => <span>Yükleniyor</span>}
                                                                        noOptionsMessage={() => <span>Henüz kimseyi seçmediniz</span>}
                                                                        onChange={selected => this.setState({ includeUser: selected })}
                                                                        placeholder="Seçiniz"
                                                                        defaultOptions={[]}
                                                                        loadOptions={inputValue => {
                                                                            return searchUser(inputValue).then(list => {
                                                                                return list.map(u => { return { value: u.id, label: u.first_name + ' ' + u.last_name } })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane" id="options">
                                                        <div className="pt-4 row">
                                                            <div className="col-6">
                                                                <div className="form-group mb-0">
                                                                    <p>Hangi departman ya da kimin adına paylaşıldığını belirtiniz. <span className="requiredField">*</span></p>
                                                                    <input className="form-select" type="text" placeholder="Örneğin; İnsan Kaynakları" style={{ cursor: 'text' }} value={this.state.sender} onChange={e => { this.setState({ sender: e.target.value }) }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Gönderinin kaydedileceği klasörü seçiniz. <span className="requiredField">*</span></p>
                                                                <select className="form-select" value={this.state.folder} onChange={e => this.setState({ folder: e.target.value })}>
                                                                    <option value="">Seçiniz</option>
                                                                    {this.state.folderOptions.map((item, optIndex) => {
                                                                        return (
                                                                            <option key={`optIndex_${optIndex}`} value={item.id}>{item.label}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="pt-4 row">
                                                            <div className="col-6">
                                                                {this.state.mediaType !== 'TICKER' &&
                                                                    <>
                                                                        <p>Gönderi penceresinin boyutunu belirleyebilirsiniz.</p>
                                                                        <div className="form-group mb-0">
                                                                            <select className="form-select" value={this.state.windowSize} onChange={e => this.setState({ windowSize: e.target.value })}>
                                                                                <option value="NORMAL">Normal</option>
                                                                                <option value="MAXIMIZED">Ekranı Kaplayan</option>
                                                                            </select>
                                                                        </div>
                                                                        <br />
                                                                    </>
                                                                }
                                                                <p>Gönderi tipini seçiniz.</p>
                                                                <div className="form-group mb-0">
                                                                    {this.state.mediaType !== 'EMERGENCY' &&
                                                                        <Tooltip placement="top" content="Ekranda pop-up olarak görünür.">
                                                                            <label className="radio-inline mr-3">
                                                                                <input type="radio" name="notifyType" value="POPUP"
                                                                                    checked={this.state.notifyType === 'POPUP'}
                                                                                    onClick={e => this.setState({ notifyType: 'POPUP' })} /> Bildirim
                                                                            </label>
                                                                        </Tooltip>
                                                                    }

                                                                    <Tooltip placement="top" content="Doğrudan ekranda çıkar.">
                                                                        <label className="radio-inline mr-3">
                                                                            <input type="radio" name="notifyType" value="DIRECT" checked={this.state.notifyType === 'DIRECT'} onClick={e => this.setState({ notifyType: 'DIRECT' })} /> Doğrudan
                                                                        </label>
                                                                    </Tooltip>

                                                                    {this.state.mediaType !== 'EMERGENCY' && this.state.mediaType !== 'TICKER' &&
                                                                        <Tooltip placement="top" content="Sadece bildiri mesajı olarak görünür.">
                                                                            <label className="radio-inline mr-3">
                                                                                <input type="radio" name="notifyType" value="FLASHING"
                                                                                    checked={this.state.notifyType === 'FLASHING'}
                                                                                    onClick={e => this.setState({ notifyType: 'FLASHING' })} /> Sessiz
                                                                            </label>
                                                                        </Tooltip>
                                                                    }

                                                                    {this.state.notifyType === "POPUP" &&
                                                                        <div>
                                                                            <br />
                                                                            <p>Öteleme süresi ve sayısı belirleyebilirsiniz. </p>
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="snoozeEnabled"
                                                                                    checked={this.state.snoozeTime !== ''}
                                                                                    onChange={e => {
                                                                                        if (!e.target.checked) {
                                                                                            this.setState({ snoozeTime: '', snoozeCount: '' })
                                                                                        } else {
                                                                                            document.getElementById('txtSnoozeTime').focus();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor="snoozeEnabled">
                                                                                    <input
                                                                                        className="form-select"
                                                                                        type="text"
                                                                                        id="txtSnoozeTime"
                                                                                        pattern="[0-9]*"
                                                                                        value={this.state.snoozeTime}
                                                                                        onChange={e => { const valid = (e.target.validity.valid) ? this.setState({ snoozeTime: e.target.value }) : false }}
                                                                                        style={{ height: 22, width: 50, marginLeft: 10, marginRight: 10, cursor: 'text' }} />
                                                                                    dakika arayla en fazla
                                                                                    <input
                                                                                        className="form-select"
                                                                                        type="text"
                                                                                        pattern="[0-9]*"
                                                                                        value={this.state.snoozeCount}
                                                                                        onChange={e => { const valid = (e.target.validity.valid) ? this.setState({ snoozeCount: e.target.value }) : false }}
                                                                                        style={{ height: 22, width: 30, marginLeft: 10, marginRight: 10, cursor: 'text' }} />
                                                                                    kez ötelenebilir.
                                                                                </label>
                                                                            </div>
                                                                            <br />
                                                                            <div className="form-group mb-0">
                                                                                <p>Bildirim mesajı için metin yazınız. <span className="requiredField">*</span> <small><em>140 karakterle sınırlıdır.</em></small> </p>
                                                                                <textarea className="form-select" style={{ cursor: 'text', whiteSpace: 'pre-line' }} value={this.state.title.replace('\\n', '\n')} onChange={e => { this.setState({ title: e.target.value }) }} rows={2} maxLength={140} />
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <p>Arka plan rengi seçiniz</p>
                                                                        <CompactPicker color={this.state.bgcolor} onChange={(color, event) => {
                                                                            this.setState({ bgcolor: color.hex });
                                                                            if (this.editorBody) { this.editorBody.style.backgroundColor = color.hex; }
                                                                        }} />

                                                                        {this.state.mediaType === 'TICKER' &&
                                                                            <div>
                                                                                <br />
                                                                                <p>Yazı rengi seçiniz.</p>
                                                                                <CompactPicker color={this.state.forecolor} onChange={(color, event) => {
                                                                                    this.setState({ forecolor: color.hex }, () => this.createTickerTexts());
                                                                                }} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.state.mediaType !== 'TICKER' &&
                                                        <div className="tab-pane" id="attachments">
                                                            <input
                                                                type="file"
                                                                ref={(ref) => this.upload = ref}
                                                                style={{ display: 'none' }}
                                                                onChange={this.handleFileChange}
                                                            />
                                                            <FullFileBrowser
                                                                i18n={turkishI18n.folders}
                                                                files={this.state.files}
                                                                folderChain={[]}
                                                                fileActions={[!this.state.isUploading ? ChonkyActions.UploadFiles : null, this.state.notifyType === "POPUP" ? this.uploadThumbnailAction : null, this.state.notifyType === "POPUP" ? this.setThumbnailAction : null, this.previewFileAction, ChonkyActions.DeleteFiles, this.state.mediaType === "ELEARNING" ? this.setVideoAction : null]}
                                                                disableDragAndDrop={true}
                                                                disableDefaultFileActions={true}
                                                                onFileAction={this.handleFileBrowserAction}
                                                            />
                                                        </div>
                                                    }

                                                    {this.state.mediaType === 'LCV' &&
                                                        <div className="tab-pane" id="lcv_options">
                                                            <div className="pt-4 row">
                                                                <div className="col-6">
                                                                    <div className="form-group mb-0">
                                                                        <p>Etkinlik başlangıç tarihi seçiniz. </p>
                                                                        <DatePicker
                                                                            locale={tr}
                                                                            showTimeSelect={true}
                                                                            showTimeInput={false}
                                                                            timeInputLabel="Zaman"
                                                                            placeholderText="Başlangıç tarihi"
                                                                            dateFormat="dd.MM.yyyy H:mm"
                                                                            timeFormat="p"
                                                                            timeIntervals={15}
                                                                            selectsStart={true}
                                                                            selected={this.state.lcv.start_date}
                                                                            startDate={this.state.lcv.start_date}
                                                                            endDate={this.state.lcv.end_date}
                                                                            onChange={date => this.setState({ lcv: { ...this.state.lcv, start_date: date } })} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="form-group mb-0">
                                                                        <p>Etkinlik mekanını yazınız.</p>
                                                                        <input className="form-select" type="text" style={{ cursor: 'text' }} value={this.state.lcv.location} onChange={e => this.setState({ lcv: { ...this.state.lcv, location: e.target.value } })} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="pt-4 row">
                                                                <div className="col-6">
                                                                    <div className="form-group mb-0">
                                                                        <p>Etkinlik bitiş tarihi seçiniz. </p>
                                                                        <DatePicker
                                                                            locale={tr}
                                                                            showTimeSelect={true}
                                                                            showTimeInput={false}
                                                                            timeInputLabel="Zaman"
                                                                            placeholderText="Bitiş tarihi"
                                                                            dateFormat="dd.MM.yyyy H:mm"
                                                                            timeFormat="p"
                                                                            timeIntervals={15}
                                                                            selectsEnd={true}
                                                                            selected={this.state.lcv.end_date}
                                                                            startDate={this.state.lcv.start_date}
                                                                            endDate={this.state.lcv.end_date}
                                                                            minDate={this.state.lcv.start_date}
                                                                            onChange={date => this.setState({ lcv: { ...this.state.lcv, end_date: date } })} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="form-group mb-0">
                                                                        <p>
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input
                                                                                    id="optionalResponseDate"
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    checked={this.state.lcv.optional_response_date}
                                                                                    onChange={e => {
                                                                                        this.setState({ lcv: { ...this.state.lcv, optional_response_date: e.target.checked } })
                                                                                    }}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor="optionalResponseDate" style={{ paddingTop: 3 }}>LCV için son tarihi belirleyiniz.</label>
                                                                            </div>
                                                                        </p>
                                                                        {this.state.lcv.optional_response_date &&
                                                                            <DatePicker
                                                                                locale={tr}
                                                                                showTimeSelect={true}
                                                                                showTimeInput={false}
                                                                                timeInputLabel="Zaman"
                                                                                placeholderText="Son cevap tarihi"
                                                                                dateFormat="dd.MM.yyyy H:mm"
                                                                                timeFormat="p"
                                                                                timeIntervals={15}
                                                                                selected={this.state.lcv.response_date}
                                                                                minDate={this.state.lcv.start_date}
                                                                                maxDate={this.state.lcv.end_date}
                                                                                onChange={date => this.setState({ lcv: { ...this.state.lcv, response_date: date } })} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.mediaType === 'TICKER' &&
                                                        <div className="tab-pane" id="ticker_options">
                                                            <div className="pt-4 row">
                                                                <div className="col-6">
                                                                    <div className="form-group mb-0">
                                                                        <p>Alt yazı bandının pozisyonunu belirleyiniz.</p>
                                                                        <select className="form-select" value={this.state.position} onChange={e => this.setState({ position: e.target.value })}>
                                                                            <option value="BOTTOM">Ekranın altında</option>
                                                                            <option value="TOP">Ekranın üstünde</option>
                                                                        </select>
                                                                    </div>
                                                                    <br />
                                                                    <div className="form-group mb-0">
                                                                        <p>Yazının geçiş hızını seçiniz. </p>
                                                                        <select className="form-select" value={this.state.speed} onChange={e => this.setState({ speed: e.target.value })}>
                                                                            <option value="1">Yavaş</option>
                                                                            <option value="2">Orta</option>
                                                                            <option value="3">Hızlı</option>
                                                                        </select>
                                                                    </div>
                                                                    <br />
                                                                    <div className="form-group mb-0">
                                                                        <p>Alt yazıların kaç defa geçeceğini belirleyiniz.</p>
                                                                        <select className="form-select" value={this.state.repeatCount} onChange={e => this.setState({ repeatCount: e.target.value })}>
                                                                            <option value="1">1 tekrar</option>
                                                                            <option value="2">2 tekrar</option>
                                                                            <option value="3">3 tekrar</option>
                                                                            <option value="4">4 tekrar</option>
                                                                            <option value="5">5 tekrar</option>
                                                                        </select>
                                                                    </div>
                                                                    <br />
                                                                    <div className="form-group mb-0">
                                                                        <p>Alt yazının yineleme ayarlarını belirleyebilirsiniz.</p>
                                                                        Toplamda
                                                                        <input type="text" style={{ width: 60, textAlign: 'right', marginLeft: 5, marginRight: 5 }} value={this.state.periodCount} onChange={e => this.setState({ periodCount: e.target.value })} />
                                                                        kez,
                                                                        her
                                                                        <input type="text" style={{ width: 60, textAlign: 'right', marginLeft: 5, marginRight: 5 }} value={this.state.periodInterval} onChange={e => this.setState({ periodInterval: e.target.value })} />
                                                                        dakikada bir tekrar gönderilsin.
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="form-group mb-0">
                                                                        <p>Metinleri konularına göre ayırmak için ayraç seçebilirsiniz. <span className="float-right fs-22" dangerouslySetInnerHTML={{ __html: this.state.separator }} /></p>
                                                                        <Picker
                                                                            native={true}
                                                                            showPreview={false}
                                                                            showSkinTones={false}
                                                                            useButton={true}
                                                                            color="#3B4182"
                                                                            title="Duyuroom"
                                                                            onSelect={emoji => { if (emoji.custom) { this.setState({ separator: `<img src="${emoji.imageUrl}" style="width: 32px; height: 32px "/>` }, () => this.createTickerTexts()) } else { this.setState({ separator: emoji.native }, () => this.createTickerTexts()) } }}
                                                                            style={{ width: '100%', height: 360 }}
                                                                            include={["symbols", "custom"]}
                                                                            emojisToShowFilter={emoji => {
                                                                                return emoji.custom ? true : ["1F534", "1F7E0", "1F7E1", "1F7E1", "1F535", "1F7E3", "1F7E4", "26AB", "26AA", "1F7E5", "1F7E7", "1F7E8", "1F7E9", "1F7E6", "1F7EA", "1F7EB", "2B1B", "2B1C", "25FC-FE0F", "25FB-FE0F", "25FE", "25FD", "25AA-FE0F", "25AB-FE0F", "1F536", "1F537", "1F538", "1F539", "1F53A", "1F53B", "1F4A0", "1F518", "1F533", "1F532"].includes(emoji.unified)
                                                                            }}
                                                                            i18n={{
                                                                                search: 'Ara',
                                                                                clear: 'Temizle',
                                                                                notfound: 'Emoji bulunamadı',
                                                                                emojiSize: 16,
                                                                                categorieslabel: 'Kategoriler',
                                                                                categories: {
                                                                                    symbols: 'Ayraç Alternatifleri',
                                                                                    custom: 'Özel',
                                                                                }
                                                                            }}
                                                                            custom={[
                                                                                {
                                                                                    name: 'Duyuroom',
                                                                                    short_names: ['Duyuroom'],
                                                                                    text: 'Duyuroom',
                                                                                    emoticons: [],
                                                                                    keywords: ['Duyuroom'],
                                                                                    imageUrl: 'https://test.duyuroom.com/images/custom_emoji_duyuroom.png',
                                                                                    customCategory: '',
                                                                                    custom: true,
                                                                                },
                                                                                {
                                                                                    name: 'Yıldız Holding',
                                                                                    short_names: ['Yıldız'],
                                                                                    text: 'Yıldız',
                                                                                    emoticons: [],
                                                                                    keywords: ['Yıldız'],
                                                                                    imageUrl: 'https://www.yildizholding.com.tr/media/1052/logo-star.gif',
                                                                                    customCategory: '',
                                                                                    custom: true,
                                                                                }

                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="tab-pane" id="preview_mobile" style={{ padding: 30, height: 600 }}>
                                                        <PreviewDevice
                                                            deviceType="MOBILE"
                                                            device="iphonex"
                                                            scale="0.65"
                                                            wallpaper="url(/images/ios-wallpaper.png)"
                                                            notification={{
                                                                show: this.state.notifyType === 'POPUP',
                                                                title: this.state.messageTitle,
                                                                subtitle: this.state.title,
                                                                body: this.state.sender,
                                                                snooze: this.state.snoozeTime > 0,
                                                                top: 40
                                                            }}
                                                        >
                                                            {(this.state.mediaType === 'POPUP' || this.state.mediaType === 'EMERGENCY' || this.state.mediaType === 'LCV') &&
                                                                <PreviewApp
                                                                    bgcolor={this.state.bgcolor}
                                                                    mediaType={this.state.mediaType}
                                                                    title={this.state.messageTitle}
                                                                    subtitle={this.state.sender}
                                                                    top={40}
                                                                >
                                                                    <div className="previewDiv">{parse(this.state.content)}</div>
                                                                    {this.state.mediaType === 'LCV' &&
                                                                        this.getLcvButtons(true)
                                                                    }
                                                                </PreviewApp>
                                                            }

                                                            {(this.state.mediaType === 'QUIZ' || this.state.mediaType === 'ELEARNING' || this.state.mediaType === 'SURVEY') &&
                                                                <PreviewApp
                                                                    bgcolor={this.state.bgcolor}
                                                                    mediaType={this.state.mediaType}
                                                                    title={this.state.messageTitle}
                                                                    subtitle={this.state.sender}
                                                                    top={40}
                                                                >

                                                                    <CarouselProvider
                                                                        naturalSlideWidth={400}
                                                                        naturalSlideHeight={150}
                                                                        totalSlides={this.state.carouselItems.length + 2}
                                                                        touchEnabled={false}
                                                                        dragEnabled={false}
                                                                    >
                                                                        <Slider className="content-carousel-half">
                                                                            {this.getCarouselItemsForPreview()}
                                                                        </Slider>
                                                                        <ButtonBack className="btn btn-xs btn-light">{"<"}</ButtonBack>
                                                                        <ButtonNext className="btn btn-xs btn-light">{">"}</ButtonNext>
                                                                    </CarouselProvider>

                                                                </PreviewApp>
                                                            }
                                                        </PreviewDevice>
                                                    </div>

                                                    <div className="tab-pane" id="preview_desktop" style={{ padding: 30, height: 500 }}>
                                                        <PreviewDevice
                                                            deviceType="DESKTOP"
                                                            device="macbookpro"
                                                            scale="0.65"
                                                            wallpaper="url(/images/macos-wallpaper.png)"
                                                            notification={{
                                                                show: this.state.notifyType === 'POPUP',
                                                                title: this.state.messageTitle,
                                                                subtitle: this.state.title,
                                                                body: this.state.sender,
                                                                snooze: this.state.snoozeTime > 0,
                                                                top: 0
                                                            }}
                                                        >
                                                            {(this.state.mediaType === 'POPUP' || this.state.mediaType === 'EMERGENCY' || this.state.mediaType === 'LCV') &&
                                                                <PreviewWindow
                                                                    bgcolor={this.state.bgcolor}
                                                                    mediaType={this.state.mediaType}
                                                                    title={this.state.messageTitle}
                                                                    subtitle={this.state.sender}
                                                                >
                                                                    <div className="previewDiv">{parse(this.state.content)}</div>

                                                                    {this.state.mediaType === 'LCV' &&
                                                                        this.getLcvButtons(true)
                                                                    }

                                                                </PreviewWindow>
                                                            }

                                                            {(this.state.mediaType === 'QUIZ' || this.state.mediaType === 'ELEARNING' || this.state.mediaType === 'SURVEY') &&
                                                                <PreviewWindow
                                                                    bgcolor={this.state.bgcolor}
                                                                    mediaType={this.state.mediaType}
                                                                    title={this.state.messageTitle}
                                                                    subtitle={this.state.sender}
                                                                >
                                                                    <CarouselProvider
                                                                        naturalSlideWidth={400}
                                                                        naturalSlideHeight={150}
                                                                        totalSlides={this.state.carouselItems.length + 2}
                                                                        touchEnabled={false}
                                                                        dragEnabled={false}
                                                                    >
                                                                        <Slider className="content-carousel-half">
                                                                            {this.getCarouselItemsForPreview()}
                                                                        </Slider>
                                                                        <ButtonBack className="btn btn-xs btn-light">{"<"}</ButtonBack>
                                                                        <ButtonNext className="btn btn-xs btn-light">{">"}</ButtonNext>
                                                                    </CarouselProvider>
                                                                </PreviewWindow>
                                                            }

                                                            {this.state.mediaType === 'TICKER' &&
                                                                <PreviewTicker
                                                                    bgcolor={this.state.bgcolor}
                                                                    mediaType={this.state.mediaType}
                                                                    position={this.state.position.toLowerCase()}
                                                                    speed={this.state.speed}
                                                                    tickermessages={this.state.tickermessages.map((msg) => { return msg.content })}
                                                                />
                                                            }
                                                        </PreviewDevice>
                                                    </div>

                                                    <div className="tab-pane" id="share" style={{ padding: 30 }}>
                                                        <div className="row align-items-center">
                                                            <div className="col-xl-8 col-xxl-12">
                                                                <div className="border-right pr-5">
                                                                    <h3 className="text-black">{this.state.messageTitle}</h3>
                                                                    <p>Gönderinizin belirli ayarlarını bu özet sayfasından gözden geçirebilirsiniz. Her şey hazır olduğunda <b>Paylaş</b> butonu ile yayımlayabilir ya da <b>Taslak Olarak Kaydedip</b> daha sonra işlemlerinize devam edebilirsiniz.</p>
                                                                    <ul className="list-group list-group-flush">
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>Gönderen</strong>
                                                                            <span className="mb-0">{this.state.sender}</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>Gönderim Tipi</strong>
                                                                            <span className="mb-0">
                                                                                {this.state.notifyType === 'POPUP' ? 'Bildirim' : this.state.notifyType === 'DIRECT' ? 'Doğrudan' : this.state.notifyType === 'FLASHING' ? 'Sessiz' : 'Belirtilmemiş'}
                                                                            </span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                                                            <strong>Hedef Cihazlar</strong>
                                                                            <span className="mb-0">
                                                                                {(this.state.devices.mobile && this.state.devices.desktop) ? 'Tümü' : this.state.devices.mobile ? 'Mobil Cihazlar' : this.state.devices.desktop ? 'Bilgisayarlar' : 'Belirtilmemiş'}
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-xxl-12">
                                                                <div className="media mb-4 align-items-center">
                                                                    <div className="mr-3">
                                                                        <i className="la la-desktop la-2x" style={{ color: '#3B4182' }} />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <p className="mb-1">Mecra</p>
                                                                        <h4 className="mb-0 text-black">{this.state.media.title}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="media mb-4 align-items-center">
                                                                    <div className="mr-3">
                                                                        <i className="la la-calendar la-2x" style={{ color: '#3B4182' }} />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <p className="mb-1">Yayımlanma Tarihi</p>
                                                                        <h4 className="mb-0 text-black">{this.state.sendDate.readable}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="media mb-4 align-items-center">
                                                                    <div className="mr-3">
                                                                        <i className="la la-user la-2x" style={{ color: '#3B4182' }} />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <p className="mb-1">Kullanıcı</p>
                                                                        <h4 className="mb-0 text-black">{this.user.first_name} {this.user.last_name}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="media mb-4 align-items-center">
                                                                    <div className="media-body">
                                                                        <button className="btn btn-send btn-rounded btn-md btn-block"
                                                                            disabled={this.state.sending}
                                                                            onClick={_ => this.publishMessage(true, false)}>
                                                                            <i className="flaticon-381-save " /> Taslak Olarak Kaydet
                                                                        </button>

                                                                        <button className="btn btn-send btn-rounded btn-md btn-block"
                                                                            disabled={this.state.sending}
                                                                            onClick={_ => this.publishMessage(true, true)}>
                                                                            <i className="flaticon-381-send-2" /> Test Grubuna Gönder
                                                                        </button>

                                                                        <button className="btn btn-send btn-rounded btn-md btn-block"
                                                                            disabled={this.state.sending}
                                                                            onClick={this.validateMessage}>
                                                                            <i className="flaticon-381-send-2" /> Paylaş
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p className="mt-2"><small><span className="requiredField">*</span> İşaretli alanların doldurulması zorunludur.</small></p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                        <ToastContainer
                            pauseOnFocusLoss={false}
                            closeOnClick={false}
                            closeButton={false}
                            draggable={false}
                            position="bottom-left"
                            autoClose={10000}
                        />
                    </div>
                </LoadingOverlay>
            </>
        )
    }
}

export default withRouter(CreateMedia);
