import * as API from '../services/api';
import settings from "../config/settings.json";

let _instance;
export default class SettingsManager {
    company = null;

    constructor() {
        this.LoadAll();
    }

    static create(useSingleton = true) {
        if (!_instance || !useSingleton) {
            _instance = new SettingsManager();
        }
        return _instance;
    }

    LoadAll = () => {
        this.LoadCompany();
    }

    LoadCompany = () => {
        API.getCompany(settings.companyId).then(response => {
            if (response && response.id)
                this.company = response;
        });
    }
}