import React from 'react';

class ReportCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="card">
                <div className="card-body text-center">
                    <i className={this.props.icon}/>
                    <h3 className="my-2">{this.props.title}</h3>
                    <p>{this.props.description}</p>
                    <br/>
                    <button style={{position: 'absolute', bottom: 15, left: 15, marginRight: 15, width: '88%'}} onClick={() => this.props.onSelect(this.props.id)} className="btn btn-sm btn-primary">Gözat</button>
                </div>
            </div>
        )
    }
}

export default ReportCard;
