import * as Yup from "yup";

const schema = Yup.object().shape({
    title: Yup.string()
        .required('Required'),
    folder: Yup.string()
        .required('Required')
});

export default schema;
