import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs/dist/grapes.min";
import webpage from "./grapesjs/gjs-preset-webpage";
import CKEDITOR from "ckeditor";
import pluginCKEditor from "grapesjs-plugin-ckeditor";
import tr from "./grapesjs/locale/tr";
import React from "react";
import SettingsManager from './SettingsManager';
import PubSub from "pubsub-js";
import traits from "grapesjs-plugin-forms/src/traits";
import tUImageEditor from 'grapesjs-tui-image-editor'
import iconD from 'tui-image-editor/dist/svg/icon-d.svg'
import iconB from 'tui-image-editor/dist/svg/icon-b.svg'
import iconA from 'tui-image-editor/dist/svg/icon-a.svg'
import iconC from 'tui-image-editor/dist/svg/icon-c.svg'

const SM = SettingsManager.create();

let emoji = ""
let special = ""
let preventFirst = 0

export default class CanvasManager extends React.Component {
    constructor(props) {
        super(props);

    }


    addEmoji = (msg, data) => {
        emoji = data
        document.getElementById("emoji-add").click()
    }

    addSpecialPost = (msg, data) => {
        special = data
        document.getElementById("special-post-add").click()
    }

    handleClick = (event) => {
        console.log('EVENT', event)
    }

    // handleSelectedButtonEvent = (msg, data) => {
    //     const iframe = document.getElementsByClassName('gjs-frame')[0]
    //     const iWindow = iframe.contentWindow;
    //     const iDocument = iWindow.document;



    //     let button = iDocument.getElementById(data.id)
    //     // console.log('BUTON : ', button)
    //     button.setAttribute('name', String(data.event))
    //     // button.onclick = this.handleClick(data.event)

    //     // console.log('BUTON 2 : ', button)


    //     // if(data){
    //     //     editor.on('component:selected', (component) => {
    //     //         const domElement = component.getEl()
    //     //         if(data.id === domElement.id){
    //     //             domElement.onclick = handleClick(data.event)
    //     //         }
    //     //         console.log('DOM ELEMENT : ', domElement)
    //     //     })
    //     // }

    // }



    CreateCanvas = (elemId, width, height) => {
        PubSub.subscribe('emoji_add', this.addEmoji);
        PubSub.subscribe('special_post', this.addSpecialPost);

        // Masterjs tarafından gelen Seçilmiş buton için uygulanacak fonksiyonu taşıyan sinyal
        // PubSub.subscribe('selected-button-event', this.handleSelectedButtonEvent);

        const previewMode = elemId.startsWith('preview');
        const tempMode = elemId.startsWith('temp');

        const blockWrapper = document.getElementById('canvasComponents');
        const styleWrapper = document.getElementById('canvasTools');
        const traitWrapper = document.getElementById('traits-container');

        const blocks = document.createElement('div');
        const styles = document.createElement('div');
        const traits = document.createElement('div');

        //console.log(styles)
        blocks.id = elemId + '-' + 'blocks';
        styles.id = elemId + '-' + 'styles';
        traits.id = elemId + '-' + 'traits'
        //console.log(styles)
        if (blockWrapper) {
            blockWrapper.append(blocks);
        }

        if (styleWrapper) {
            styleWrapper.append(styles);
        }

        if (traitWrapper) {
            traitWrapper.append(traits);
        }
        const container = document.getElementById(elemId);
        if (!container) {
            return null;
        }

        container.style.display = 'none';

        //const bg = require('tui-image-editor/examples/img/bg.png')
        let blackTheme = { // or white
            // main icons
            'menu.normalIcon.path': iconD,
            'menu.activeIcon.path': iconB,
            'menu.disabledIcon.path': iconA,
            'menu.hoverIcon.path': iconC,
        }

        let locale_tr = {
            'Apply': 'Uygula',
            'Arrow': 'Ok',
            'Arrow-2': 'Ok-2',
            'Arrow-3': 'Ok-3',
            'Blend': 'Karıştır',
            'Blur': 'Bulanıklık',
            'Bold': 'Kalınlık',
            'Brightness': 'Parlaklık',
            'Bubble': 'Kabarcık',
            'Cancel': 'Vazgeç',
            'Center': 'Orta',
            'Color': 'Renk',
            'Color Filter': 'Renk Filtrele',
            'Crop': 'Kırp',
            'Custom': 'Özel',
            'Custom icon': 'Özel Simge',
            'Delete': 'Sil',
            'Delete-all': 'Hepsini Sil',
            'Distance': 'Mesafe',
            'Download': 'İndir',
            'Draw': 'Çiz',
            'Emboss': 'Kabartma',
            'Fill': 'Doldur',
            'Filter': 'Filtre',
            'Flip': 'Döndür',
            'Flip X': 'Döndür X',
            'Flip Y': 'Döndür Y',
            'Free': 'Serbest',
            'Grayscale': 'Gri Tonlama',
            'Heart': 'Kalp',
            'Icon': 'Simge',
            'Invert': 'Ters Çevir',
            'Italic': 'İtalik',
            'Left': 'Sol',
            'Load': 'Yükle',
            'Load Mask Image': '',
            'Location': 'Konum',
            'Mask': 'Maske',
            'Multiply': 'Çoğalt',
            'Noise': 'Gürültü',
            'Pixelate': 'Pikselleştir',
            'Polygon': 'Çokgen',
            'Range': 'Menzil',
            'Rectangle': 'Dikdörtgen',
            'Redo': 'Yeniden Yap',
            'Remove White': 'Beyazı Kaldır',
            'Reset': 'Sıfırla',
            'Right': 'Sağ',
            'Rotate': 'Döndür',
            'Sepia': 'Sepya',
            'Sepia2': 'Sepya-2',
            'Shape': 'Şekil',
            'Sharpen': 'Keskinleştir',
            'Square': 'Kare',
            'Star-1': 'Yıldız-1',
            'Star-2': 'Yıldız-2',
            'Straight': 'Düz',
            'Stroke': 'Fırça Darbesi',
            'Text': 'Metin',
            'Text size': 'Metin Boyutu',
            'Threshold': 'Eğik',
            'Tint': 'Renk Tonu',
            'Triangle': 'Üçgen',
            'Underline': 'Alt Çizgi',
            'Undo': 'Geri Al',
            'Value': 'Değer',
        }

        // Canvasta seçili objenin border rengini değiştirmek için.
        // canvasCss: `
        //         .gjs-selected {
        //             outline: 1px solid #ddddff !important;
        //         }`,

        const editor = grapesjs.init(
            {
                container: container,
                dragMode: 'absolute',
                height: previewMode ? "208px" : height ? height + "px" : "800px",
                width: previewMode ? "454px" : width ? width + "px" : "1024px",
                fromElement: true,
                blockManager: {
                    appendTo: previewMode ? "" : blocks
                },
                storageManager: {
                    autoload: false
                },
                panels: { defaults: [] },
                assetManager: {
                    showUrlInput: false,
                    inputPlh: 'http://path/to/the/image.jpg',
                    addBtnText: 'Add image',
                },
                selectorManager: {
                    // hover normal vs..
                    // appendTo: blocks
                },
                styleManager: {
                    appendTo: previewMode ? "" : styles
                },
                traitManager: {
                    appendTo: previewMode ? "" : traits
                },
                components: null,
                colorPicker: {
                    palette: [
                        ['#ffffff', '#f2f2f2', '#d8d8d8', '#bfbfbf', '#a5a5a5', '#7f7f7f', '#000000', '#595959', '#3f3f3f', '#3a3838', '#0c0c0c', '#e7e5e6', '#d0cece',
                            '#aeabab', '#757070', '#3a3838', '#171616', '#d6dce4', '#adb9ca', '#8496b0', '#323f4f', '#222a35',
                            '#4472c4', '#d9e2f3', '#b4c6e7', '#8eaadb', '#2f5496', '#1f3864', '#ed7d31', '#fbe5d5', '#f7cbac',
                            '#f3b183', '#c55a11', '#833c0b', '#a5a5a5', '#ededed', '#dbdbdb', '#c9c9c9', '#7b7b7b', '#525252', '#ffc001', '#fff2cc', '#fee599', '#ffd965', '#bf9000',
                            '#7f6000', '#5b9bd5', '#deebf6', '#bdd7ee', '#9cc2e4', '#2e75b5', '#1e4e79', '#70ad47', '#e2efd9', '#c5e0b3', '#92d04f', '#a8d08d', '#538135', '#375623', '#012060',
                            '#7030a2', '#43536c', '#4572c5', '#ee7d31', '#febf00', '#5b9cd6', '#6db03f'
                        ],
                    ],
                },
                plugins: [
                    pluginCKEditor,
                    'gjs-preset-webpage',
                    editor => tUImageEditor(editor, {
                        config: {
                            includeUI: {
                                locale: locale_tr,
                                initMenu: 'filter',

                            },
                        },
                        icons: {
                            'menu.normalIcon.path': iconD,
                            'menu.activeIcon.path': iconB,
                            'menu.disabledIcon.path': iconA,
                            'menu.hoverIcon.path': iconC,
                            'submenu.normalIcon.path': iconD,
                            'submenu.activeIcon.path': iconC,
                        },
                        labelApply: 'Uygula',
                    }),
                ],
                pluginsOpts: {
                    'gjs-preset-webpage': {
                        i18n: { tr }
                    },
                    [pluginCKEditor]: {
                        options: {
                            disableNativeSpellChecker: false,
                            language: 'tr',
                            // startupFocus:true,
                            // extraAllowedContent:'*(*);*{*}',
                            // allowedContent:true,
                            // enterMode:CKEDITOR.ENTER_BR,
                            // toolbar:[
                            //     { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike'] },
                            //     { name: 'paragraph', items: [ 'NumberedList', 'BulletedList'] },
                            //     { name: 'links', items: [ 'Link', 'Unlink', ] },
                            //     { name: 'insert', items: ['Smiley'] },
                            // ],
                            extraPlugins: 'emoji',
                            toolbar: [
                                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', "TextColor"] },
                                { name: 'styles', items: ['FontSize'] },
                                { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
                                { name: 'links', items: ['Link', 'Unlink',] },
                                { name: 'insert', items: ['EmojiPanel'] },
                                { name: 'special', items: ['my-combo'] },
                            ],
                            on: {
                                pluginsLoaded: function () {
                                    let editor = this,
                                        config = editor.config;
                                    // console.log('editör : ',editor)


                                    editor.ui.addRichCombo('my-combo', {
                                        label: 'Kişiye Özel Gönderi',
                                        title: 'Kişiye Özel Gönderi',


                                        panel: {
                                            css: 'a:focus-visible{outline-offset:0px !important;outline:0px !important}.cke_panel{visibility:visible;width:120px;height:100px;overflow:hidden;background-color:#fff;}.cke_menu_panel{padding:0;margin:0}.cke_combopanel{width:150px;height:170px}.cke_panel_frame{width:100%;height:100%;font-size:12px;overflow:hidden;overflow-x:hidden}.cke_panel_container{overflow-y:hidden;overflow-x:hidden}.cke_panel_block:focus{outline:0px none transparent !important;}.cke_panel_list{margin:0;padding:0;list-style-type:none;white-space:nowrap}.cke_panel_listItem{margin:0;padding:0}.cke_panel_listItem a{padding:6px 7px;display:block;color:inherit!important;text-decoration:none;overflow:hidden;text-overflow:ellipsis}.cke_hc .cke_panel_listItem a{border-style:none}.cke_panel_listItem.cke_selected a,.cke_panel_listItem a:hover{background-color:#e9e9e9}.cke_hc .cke_panel_listItem a:hover,.cke_hc .cke_panel_listItem a:focus,.cke_hc .cke_panel_listItem a:active{padding:4px 5px}.cke_panel_listItem p,.cke_panel_listItem h1,.cke_panel_listItem h2,.cke_panel_listItem h3,.cke_panel_listItem h4,.cke_panel_listItem h5,.cke_panel_listItem h6,.cke_panel_listItem pre{margin-top:0;margin-bottom:0}.cke_panel_grouptitle{cursor:default;font-size:11px;font-weight:bold;white-space:nowrap;margin:0;padding:6px 6px 7px 6px;color:#484848;background:#f8f8f8}',
                                            multiSelect: false,
                                        },


                                        init: function () {

                                            //this.startGroup( 'My Dropdown Group #1' );
                                            this.add(' #AD# ', 'Ad');
                                            this.add(' #SOYAD# ', 'Soyad');

                                            //this.startGroup( 'My Dropdown Group #2' );
                                            this.add(' #AD# #SOYAD# ', 'Ad Soyad');


                                        },

                                        onClick: function (value) {
                                            editor.focus();
                                            editor.fire('saveSnapshot');

                                            editor.insertHtml(value);

                                            editor.fire('saveSnapshot');
                                        }
                                    });

                                    // setTimeout(()=> {
                                    //     editor.setData(" ",()=>{
                                    //         editor.focus()
                                    //     })
                                    // },200)




                                },
                            }
                        }

                    },

                    [tUImageEditor]: {
                        i18n: { tr }
                    },

                },
                i18n: {
                    detectLocale: false,
                    locale: 'tr',
                    localeFallback: 'tr',
                    messages: { tr },
                },
                commands: {
                    insertText: () => console.log("inserted")
                },
                richTextEditor: {
                    actions: [
                        {
                            icon: '<b>B</b>',
                            attributes: { title: 'Kalın' },
                            result: rte => rte.exec('bold')
                        },
                        {
                            icon: '<b><em>I</em></b>',
                            attributes: { title: 'İtalik' },
                            result: rte => rte.exec('italic')
                        },
                        {
                            icon: '<b><strike>S</strike></b>',
                            attributes: { title: 'Üstü çizili' },
                            result: rte => rte.exec('strikethrough')
                        },
                        {
                            icon: '<b><u>U</u></b>',
                            attributes: { title: 'Alt çizgi' },
                            result: rte => rte.exec('underline')
                        },
                        {
                            icon: '<b><i class="fa fa-link" aria-hidden="true"></i></b>',
                            attributes: { title: 'Link' },
                            // Example on it's easy to wrap a selected content
                            result: rte => {
                                let link = `<a data-gjs-type="link" class="link" href="https://null">${rte.selection()}</a>`
                                rte.insertHTML(link)
                            }
                        },
                        {
                            icon: '<i class="fa fa-list-ol fa-lg"></i>',
                            attributes: { title: 'Düzenli Liste' },
                            result: rte => rte.exec('insertOrderedList')
                        },
                        {
                            icon: '<i class="fa fa-list-ul fa-lg"></i>',
                            attributes: { title: 'Düzensiz Liste' },
                            result: rte => rte.exec('insertUnOrderedList')
                        },
                        {
                            name: 'specialPost',
                            icon: `
            <select>
                <option selected="true" disabled="disabled" id="defaultPlaceholder">Kişiye Özel Gönderi</option>
                <option value="#AD#">İsme Göre</option>
                <option value="#SOYAD#">Soyisme Göre</option>
                <option value="#ADSOYAD#">İsim ve Soyisme Göre</option>
            </select>
        `,
                            event: 'change',
                            result: (rte, action) => {
                                rte.insertHTML(action.btn.children[0].selectedOptions[0].value)
                                document.getElementById('defaultPlaceholder').selected = true;
                            },
                        },
                        {
                            name: 'custom-vars',
                            attributes: { hidden: true },
                            icon: `<div><button id="emoji-add"/></div>`,
                            // Bind the 'result' on 'change' listener
                            event: "click",
                            result: (rte, action) => {
                                const data = `${emoji}`
                                let el = rte.doc.querySelectorAll(".gjs-selected")[0]
                                el.contentEditable = true
                                //el.selectionStart = el.selectionEnd = el.innerText.length;
                                //el.innerHTML = el.innerText + emoji
                                //rte.insertHTML("hasan")
                                rte.insertHTML(data)


                            },
                        },
                        {
                            name: 'deneme',
                            attributes: { hidden: true },
                            icon: `<div><button id="special-post-add"/></div>`,
                            // Bind the 'result' on 'change' listener
                            event: "click",
                            result: (rte, action) => {
                                const data = `${special}`
                                let el = rte.doc.querySelectorAll(".gjs-selected")[0]
                                el.contentEditable = true
                                //el.selectionStart = el.selectionEnd = el.innerText.length;
                                //el.innerHTML = el.innerText + emoji
                                //rte.insertHTML("hasan")
                                rte.insertHTML(data)




                            },

                        },
                    ],
                },
            });




        const comps = editor.DomComponents;
        const textType = comps.getType('text');

        comps.addType('text', {
            model: textType.model,
            view: textType.view.extend({
                events: {
                    click: 'onActive',
                    input: 'onInput'
                },
            }),
        });

        editor.Canvas.getBody().addEventListener('click', e => {
            PubSub.publish('trait-toggle', {})
        })

        editor.on('component:selected', (component) => {
            const domElement = component.getEl();
            // const style = window.getComputedStyle(domElement)
            PubSub.publish('button-block-selected', domElement.id)
        })

        // editor.BlockManager.add('the-row-block', {
        //     label: '2 Columns',
        //     content: '<div class="stackable"><div class="row gjs-row" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row">' +
        //         '<div class="eight wide column gjs-cell" data-gjs-draggable=".row"></div>' +
        //         '<div class="eight wide column gjs-cell" data-gjs-draggable=".row"></div>' +
        //         '</div></div>',
        // });

        // const block = editor.BlockManager.get('youtubeVideo');
        // console.log('BLOCKKK OLD: ', block)
        // block.attributes.content = `<div id="youtubeVideoPlayer"><iframe width="420" height="345" src="https://www.youtube.com/watch?v=l2X5rwJ_bW4&ab_channel=TechWithTim"></iframe></div>`
        // block.set('content',<div id="youtubeVideoPlayer"><iframe width="420" height="345" src="https://www.youtube.com/watch?v=l2X5rwJ_bW4&ab_channel=TechWithTim"></iframe></div>)
        // console.log('BLOCKKK NEW: ', block)

        // Yalnızca Canvas mecralarında gönderim yaparken çalışsın, Şablonlardan girildiğinde çalışmasın.
        // if(elemId === "design-content") {
        //     // Bu Alan Tek tıklama ile text içerisine girmeye yarıyor.
        //     const comps = editor.DomComponents;
        //     const textType = comps.getType('text');
        //     console.log('TEXT TYPE : ', textType)
        //     comps.addType('text', {
        //         model: textType.model,
        //         view: textType.view.extend({
        //             events: {
        //                 click: 'onActive',
        //             },
        //         }),
        //     });
        // }


        editor.blockWrapper = blocks;
        editor.styleWrapper = styles;
        editor.container = container;



        if (!tempMode) {
            editor.StyleManager.removeSector('general');
            editor.StyleManager.removeSector('layout');
            editor.StyleManager.removeSector('extra');

            editor.StyleManager.removeProperty("decorations", "border-radius");
            editor.StyleManager.removeProperty("decorations", "border-radius-c");
            editor.StyleManager.removeProperty("decorations", "border");
            editor.StyleManager.removeProperty("decorations", "box-shadow");
            editor.StyleManager.removeProperty("decorations", "border-radius");
        }

        editor.hideWrappers = () => {
            if (editor.blockWrapper) {
                editor.blockWrapper.style.display = 'none';
            }

            if (editor.styleWrapper) {
                editor.styleWrapper.style.display = 'none';
            }

            editor.container.style.display = 'none';
        };

        editor.showWrappers = () => {
            if (editor.blockWrapper) {
                editor.blockWrapper.style.display = 'block';
            }

            if (editor.styleWrapper) {
                editor.styleWrapper.style.display = 'block';
            }

            editor.container.style.display = 'block';
        };

        // setTimeout(() => {
        //     const pact = document.querySelectorAll(".added-event")
        //     for (let i = 0; i < pact.length; i++) {
        //         console.log(pact[0])
        //         pact[i].innerHTML = "<i class='fa' id='sort-desc'>&#xf0dd;</i>"
        //     }
        // }, 2000);
        // setTimeout(() => {
        //     let layerButtons = document.querySelectorAll('[data-add-layer]');
        //     for (let i = 0; i < layerButtons.length; i++) {
        //         const button = layerButtons[i];
        //         if (!button.classList.contains("added-event")) {
        //             button.classList.add("added-event");
        //             button.addEventListener('click', function () {
        //                 button.classList.toggle("added-layer");
        //                 setTimeout(function () {
        //                     const closeButton = button.parentElement.querySelector('#gjs-sm-close-layer');
        //                     if (closeButton) {
        //                         closeButton.addEventListener('click', function () {
        //                             button.classList.toggle("added-layer");
        //                         });
        //                     }
        //                 }, 300);
        //             });
        //         }
        //     }
        // }, 2000);


        return editor;
    }



    CreateCanvasAsync = (elemId, onReady) => {
        this.ElementWaiting(['canvasComponents', 'canvasTools', 'traits-container'], () => {
            const editor = this.CreateCanvas(elemId);
            if (onReady)
                onReady(editor);
            var layerButtons = document.querySelectorAll('[data-add-layer]');
            for (let i = 0; i < layerButtons.length; i++) {
                const button = layerButtons[i];
                button.addEventListener('click', function () {
                    this.style.backgroundColor = "red";
                    button.classList.toggle("added-layer");
                });
            }
        });
    }

    GetLiquidTemplateContent = (content) => {
        let result = '';
        if (content) {
            result = content;
            if (SM.company) {
                result = result.replace(/data-start="company_logo"(.*?)data-end="company_logo"/gi, 'data-start="company_logo" src="' + (SM.company.logo || '') + '" data-end="company_logo"');
                result = result.replace(/{company_name}/gi, SM.company.name || '');
            }
        }
        return result;
    }

    AppendDefaultHeadLinks = () => {
        var allFrames = document.getElementsByTagName('iframe');
        for (let index = 0; index < allFrames.length; index++) {
            const frame = allFrames[index];
            if (frame && frame.className && frame.className === "gjs-frame" && frame.contentDocument) {
                var link1 = document.createElement("link");
                link1.href = "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
                link1.rel = "stylesheet";
                frame.contentDocument.getElementsByTagName('head')[0].append(link1);

                var link2 = document.createElement("link");
                link2.href = window.location.protocol + "//" + window.location.host + "/templates/css/template.css";
                link2.rel = "stylesheet";
                frame.contentDocument.getElementsByTagName('head')[0].append(link2);
            }
        }
    }

    ElementWaiting = (idArray, onReady) => {
        const fnWaiting = () => {
            var waiting = false;
            idArray.forEach((id) => {
                const element = document.getElementById(id);
                if (!element) {
                    waiting = true;
                }
            });

            if (waiting) {
                setTimeout(fnWaiting, 500);
            }
            else {
                onReady();
            }
        };

        if (idArray && idArray.length > 0 && onReady) {
            setTimeout(fnWaiting, 500);
        }
    }



}
