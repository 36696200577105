import React, {Component} from 'react';
import AuthService from "../../services/auth.service";
import '../../assets/css/login.css';
import {getUserByUsername} from "../../services/api";
import settings from "../../config/settings.json"
import axios from "axios";
import Swal from "sweetalert2";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            message: "",
            remember: false,
            loading: false,
            showPassword: false
        }
    }

    handleForgot = () => {
        this.props.history.push("/authentication/forgot");
    };

    handleLogin = (e) => {
        e.preventDefault();

        let errors = [];
        let username = this.state.username;
        let password = this.state.password;

        if (username.trim() === '') {
            errors.push("&bull; Lütfen kullanıcı adınızı giriniz.");
        }

        if (password.trim() === '') {
            errors.push("&bull; Lütfen şifrenizi giriniz.")
        }

        if (errors.length === 0) {
            this.setState({
                message: "",
                loading: true
            });

            this.loginService()

            // const config = {
            //     headers: { "x-api-key": "I2byJef7O13ymaItCl2Gq7hRuMIcaTvsaYy6Fzwt" },
            // };
            // const payload = { customerID: settings.customerID };
            // axios.post("https://9b2gn7jd5h.execute-api.eu-central-1.amazonaws.com/default/checkLicenseDuyuroom", payload, config
            // ).then((result) => {
            //     window.globalApiBase = result.data.apiurl

            // })
            //     .catch(err => {
            //         console.log('CATCH ERROR : ', err)
            //     })

            // return

        } else {
            this.setState({
                loading: false,
                message: errors.join('<br/>')
            });
        }
    };

    loginService = () => {
        AuthService.login(this.state.username, this.state.password, this.state.remember).then(
            (r) => {
                if (r.user) {
                    if (r.user.profile.isContentAdmin || r.user.profile.isSuperAdmin || r.user.profile.isSystemAdmin) {
                        setTimeout(() => {
                            window.sessionStorage.setItem('showLicenceAlert', true)
                            this.props.history.push("/dashboard");
                        }, 1000);
                    } else {
                        this.setState({
                            loading: false,
                            message: "&bull; " + "Giriş yetkiniz bulunmamaktadır"
                        }, () => {
                            AuthService.logout();
                        });
                    }
                }
            },
            error => {
                const resMessage =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                this.setState({
                    loading: false,
                    message: "&bull; " + resMessage
                });
            }
        );
    }

    render() {
        return (
            <div className="login_wrapper" style={{
                backgroundImage: "url(/images/background_login.png)",
                backgroundSize: "cover",
                backgroundColor: "#3B4182"
            }}>
                <div className="row">
                    <div className="col-lg-7 col-xl-7 d-none d-lg-block text-center" style={{paddingTop: 75}}>
                        <h2 style={{color: "#ffffff", fontSize: '5vw', fontWeight: "550", fontFamily: "Montserrat"}}>Hoş
                            Geldiniz!</h2>
                        <img src="/images/decorative_login.png" width="60%" style={{marginTop: 30}}/>
                    </div>
                    <div className="col-lg-5 col-xl-5 col-md-12 text-center">
                        <img src="/images/logo_login.png" width={180} style={{paddingTop: 50}}/>
                        <div style={{
                            width: "70%",
                            minHeight: 470,
                            minWidth: 385,
                            paddingTop: 50,
                            paddingLeft: 50,
                            paddingRight: 50,
                            borderRadius: 30,
                            marginTop: 75,
                            marginLeft: "auto",
                            marginRight: "auto",
                            border: "solid 1px #ffffff"
                        }}>
                            <h3 style={{
                                color: "#ffffff",
                                fontSize: 26,
                                marginBottom: 40,
                                fontWeight: "400",
                                fontFamily: "Montserrat"
                            }}>Kullanıcı Girişi</h3>
                            <form onSubmit={this.handleLogin} autoComplete="off" autoCorrect="off">
                                <div className="form-group mb-3">
                                    <input type="text"
                                           placeholder="Kullanıcı Adı ya da E-posta"
                                           autoComplete="new-password"
                                           autoCorrect="off"
                                           className="form-control rounded-pill border-0 shadow-sm px-4"
                                           value={this.state.username}
                                           style={{fontSize: 14, paddingTop: 13}}
                                           onChange={(e) => {
                                               this.setState({username: e.target.value})
                                           }}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <div className="text-right">
                                        {!this.state.showPassword &&
                                            <i
                                                className="la la-eye-slash la-2x text-black-50"
                                                style={{
                                                    position: 'absolute',
                                                    marginLeft: -50,
                                                    marginTop: 13,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={e => this.setState({showPassword: true})}
                                            />
                                        }

                                        {this.state.showPassword &&
                                            <i
                                                className="la la-eye la-2x text-black-50"
                                                style={{
                                                    position: 'absolute',
                                                    marginLeft: -50,
                                                    marginTop: 13,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={e => this.setState({showPassword: false})}
                                            />
                                        }
                                        <input type={this.state.showPassword ? 'search' : 'password'}
                                               placeholder="Şifre"
                                               autoComplete="new-password"
                                               autoCorrect="off"
                                               className="form-control rounded-pill border-0 shadow-sm px-4"
                                               value={this.state.password}
                                               style={{
                                                   color: '#000000',
                                                   fontWeight: '400',
                                                   fontSize: 14,
                                                   paddingTop: 13
                                               }}
                                               onChange={(e) => {
                                                   this.setState({password: e.target.value})
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <div className="custom-control custom-checkbox text-left ml-2 mb-3 ">
                                        <input type="checkbox" className="custom-control-input"
                                               id="rememberMe" checked={this.state.remember} onChange={(e) => {
                                            this.setState({remember: e.target.checked})
                                        }}
                                        />
                                        <label htmlFor="rememberMe"
                                               className="custom-control-label pt-1 pl-2 text-white">Bu bilgisayarda
                                            beni hatırla</label>
                                    </div>
                                    <div className="text-center d-flex ">
                                        <p className="pt-1 pl-2 text-white" style={{fontSize: 12}}>
                                            <a href="#" onClick={this.handleForgot} style={{color: '#ffffff'}}>
                                                Şifrenizi mi unuttuz?
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <button type="submit"
                                        className="btn btn-login btn-block text-uppercase mb-3 mt-3 rounded-pill shadow-sm"
                                        disabled={this.state.loading}>GİRİŞ YAP
                                </button>

                                <div className="btn btn-block rounded-pill bg-white">
                                    <span><img src="/images/icon_microsoft_login.png" width={30}/> <a
                                        href="#">Office365</a></span>
                                    <span style={{
                                        width: 1,
                                        height: 28,
                                        marginLeft: 15,
                                        marginRight: 10,
                                        borderLeft: "solid 1px #eaeaea"
                                    }}/>
                                    <span><img src="/images/icon_google_login.png" width={30}/> <a
                                        href="#">Google</a></span>
                                </div>

                            </form>
                        </div>

                        {this.state.message !== '' &&
                            <div
                                className="alert alert-outline-warning"
                                dangerouslySetInnerHTML={{__html: this.state.message}}
                                style={{
                                    width: "70%",
                                    minWidth: 385,
                                    marginTop: 10,
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}
                            />
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
