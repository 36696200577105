import React from 'react';
import PubSub from "pubsub-js";
import {Button} from "semantic-ui-react";
import CanvasManager from "../../tools/CanvasManager";
import * as API from '../../services/api';
import {setPageTitle} from "../../helpers/LayoutHelper";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewWindow from "../../components/common/PreviewWindow";
import PreviewFrame from "../../components/common/PreviewFrame";
import Swal from "sweetalert2";
import CustomStorage from "../../models/CustomStorage";
import {PreviewCss} from "../../helpers/PreviewCssHelper";
import LoadingOverlay from 'react-loading-overlay';
import {toast, ToastContainer} from "react-toastify";

const CM = new CanvasManager();

class TemplateDesignPage extends React.Component {
    mounted = false;

    state = {
        mode: 'design-content',
        content: {},
        notify: {},
        model: null,
        isLoading: false,
    };

    constructor(props) {
        super(props);

        this.editorContent = null;
        this.editorNotify = null;


        this.cs = new CustomStorage();
    }

    componentDidMount = () => {
        //
        // if(document.getElementById("videoBlock").children[0]) {
        //     document.getElementById("videoBlock").children[0].src = "https://api.duyuroom.com/media/MacBook_Pro_13_Review.mp4"
        //     document.getElementById('videoBlock-2').children[0].src = "https://api.duyuroom.com/media/MacBook_Pro_13_Review.mp4"
        // }
        // if(document.getElementById("videoBlock").children.length === 0){
        //     document.getElementById("videoBlock").src = "https://api.duyuroom.com/media/MacBook_Pro_13_Review.mp4"
        //     document.getElementById('videoBlock-2').src = "https://api.duyuroom.com/media/MacBook_Pro_13_Review.mp4"
        // }
        if (this.mounted) return;
        this.mounted = true;

        const user = this.cs.getItem('user');
        const user_id = JSON.parse(user).user.pk;
        this.setState({userId: user_id})


        if (window.template_name) {
            setPageTitle(window.template_name);
            PubSub.publish("CANVAS", {showPanel: true, editTemplates: true, selected: true});
        } else {
            PubSub.publish("CANVAS", {showPanel: true, editTemplates: true});
        }


        PubSub.subscribe('canvas_template.save', this.handleOnSave);
        PubSub.subscribe('canvas_template.saveAs', this.handleOnSaveAs);
        PubSub.subscribe('canvas_template.update', this.onUpdate.bind(this));

        setTimeout(() => {
            this.editorContent = CM.CreateCanvas('temp-design-content');
            this.editorNotify = CM.CreateCanvas('temp-design-notify');
            this.setActiveCanvas('design-content');
            CM.AppendDefaultHeadLinks();
        }, 400);

    }

    handleOnSave = (event, values) => {
        this.startCreateTemplate(event,values, "onSave");
    }
    handleOnSaveAs = (event, values) => {
        this.startCreateTemplate(event,values, "onSaveAs");
    }

    onSave = (event, values) => {
        values.templateJson = {
            content: {
                css: null,
                html: null
            },
            notify: {
                css: null,
                html: null
            }
        }
        if (this.editorContent) {
            values.templateJson.content = {
                css: this.editorContent.getCss(),
                html: this.editorContent.getHtml()
            };
        }

        if (this.editorNotify) {
            values.templateJson.notify = {
                css: this.editorNotify.getCss(),
                html: this.editorNotify.getHtml()
            };
        }
        values.createdby = this.state?.userId
        values.updatedby = this.state?.userId
        if (values.templateJson.notify.html && values.templateJson.content.html) {
            API.updateTemplate(values).then(response => {
                this.setState({isLoading: false}, () => {
                    Swal.fire({
                        icon: 'success',
                        text: 'Şablon Kaydedildi',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,

                    })
                })

            }).catch(error => this.setState({isLoading: false}, () => {
                Swal.fire({
                    icon: 'error',
                    text: 'Şablon Güncellenemedi',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,

                })
            }))
        }
    }
    onSaveAs = (event, values) => {
        this.setState({isLoading: true})
        values.templateJson = {
            content: {
                css: null,
                html: null
            },
            notify: {
                css: null,
                html: null
            }
        }
        if (this.editorContent) {
            values.templateJson.content = {
                css: this.editorContent.getCss(),
                html: this.editorContent.getHtml()
            };
        }

        if (this.editorNotify) {
            values.templateJson.notify = {
                css: this.editorNotify.getCss(),
                html: this.editorNotify.getHtml()
            };
        }
        const payload = {
            "id": '',
            "name": values.name,
            "type": values.type,
            "templateJson": values.templateJson,
        }
        // console.log(payload)
        if (values.templateJson.notify.html && values.templateJson.content.html) {
            API.createTemplate(payload).then(response => {
                window.template_id = response.id
                window.template_name = response.name
                this.setState({isLoading: false}, () => {
                    Swal.fire({
                        icon: 'success',
                        text: 'Şablon Oluşturuldu!',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#3B4182',
                        cancelButtonText: "Tamam",
                        focusCancel: false,

                    })
                })
                PubSub.publish(('canvas_template.new').toLowerCase(), response.id);
                this.props.history.push('/canvas')
            }).catch(error => this.setState({isLoading: false}, () => {
                Swal.fire({
                    icon: 'error',
                    text: 'Şablon oluşturulamadı!',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#3B4182',
                    cancelButtonText: "Tamam",
                    focusCancel: false,
                })
            }));
        }

    }

    onUpdate = (event, data) => {
        // setTimeout(() => {
        //     this.editorContent = CM.CreateCanvas('temp-design-content',data.width,data.height);
        //     this.editorNotify = CM.CreateCanvas('temp-design-notify');
        //     this.setActiveCanvas('design-content');
        //     CM.AppendDefaultHeadLinks();
        // }, 400);

        // console.log(this.editorContent)

        if (this.mounted && data) {
            this.setActiveCanvas('design-content');
            this.setState({model: data});
            if (this.editorContent) {
                //this.editorContent.setComponents('<div class="ui container main-container bgi-12"><div class="ui raised very padded text container segment second-container" style="background-color:#f8f8f8bb"><div class="ui middle aligned one column padded grid"><div class="row"><div class="column middle aligned"><img class="ui wireframe image first-image" style="float:right" data-start="company_logo" src="https://dummyimage.com/320x86/000000/ffffff.jpg&text=Dinamik+Logo" data-end="company_logo"></div></div></div><div class="ui divider"></div><div class="ui text"><h2 class="first">Yeni Örnek Şablon</h2><p></p></div></div></div>');

                if (data.templateJson.content) {
                    document.getElementById("temp-design-content").style.width = data.width ? `${data.width}px` : "1024";
                    document.getElementById("temp-design-content").style.height = data.height ? `${data.height}px` : "768px"
                    this.setState({templateWidth: data.width, templateHeight: data.height})
                    // this.editorContent.Config.width=data.width?`${data.width}px`:"200px"
                    // this.editorContent.Config.height=data.height?`${data.height}px`:"200px"
                    // this.editorContent.refresh()
                    // this.editorContent.on("change", (a,b,c)=>{
                    //     console.log(a,b,c)
                    // })

                    if (data.templateJson.content.html)
                        this.editorContent.setComponents(CM.GetLiquidTemplateContent(data.templateJson.content.html));

                    if (data.templateJson.content.css)
                        this.editorContent.setStyle(data.templateJson.content.css + 'body{width:' + data.width + 'px;height: ' + data.height + 'px;}');
                }
            }

            if (this.editorNotify) {
                //this.editorNotify.setComponents('<div class="ui container main-notify-container" style="background-color:#fff;max-width:300px!important;max-height:400px!important;margin:auto!important;border:1px solid rgba(34,36,38,.15);border-radius:10px"><div class="ui middle aligned one column centered padded grid" style="height:100px"><div class="row"><div class="column middle aligned"><img class="ui wireframe image first-image" style="margin:auto;max-height:100px" src="https://dummyimage.com/320x86/000000/ffffff.jpg&text=Görsel+İçerik"></div></div></div><div class="ui divider"></div><div class="ui basic segment" style="height:180px"><h2 class="ui header">Bildirim Başlığı</h2><p>Kısa bildirim içerini buraya yazabilirsiniz.</p></div><div class="ui divider"></div><div class="ui middle aligned two column centered padded grid"><div class="row"><div class="column middle aligned"><button class="ui grey basic button">Ötele</button></div><div class="column middle aligned"><button class="ui blue basic button">Göster</button></div></div></div></div>');

                if (data.templateJson.notify) {
                    if (data.templateJson.notify.html)
                        this.editorNotify.setComponents(CM.GetLiquidTemplateContent(data.templateJson.notify.html));

                    if (data.templateJson.notify.css)
                        this.editorNotify.setStyle(data.templateJson.notify.css);
                }
            }
        }
    }

    componentWillUnmount() {
        if (!this.mounted) return;
        this.mounted = false;

        PubSub.unsubscribe('canvas_template.save');
        PubSub.unsubscribe('canvas_template.update');

        PubSub.publish("CANVAS", {showPanel: false, editTemplates: true});

        if (this.editorContent) {
            this.editorContent.destroy();
        }
        if (this.editorNotify) {
            this.editorNotify.destroy();
        }
    }

    setActiveCanvas = (mode) => {
        if (this.state.mode != mode) {
            this.setState({mode});
        }

        if (this.editorNotify && this.editorNotify.hideWrappers)
            this.editorNotify.hideWrappers();
        if (this.editorContent && this.editorContent.hideWrappers)
            this.editorContent.hideWrappers();

        switch (mode) {
            case 'design-content':
                if (this.editorContent)
                    this.editorContent.showWrappers();
                break;
            case 'design-notify':
                if (this.editorNotify)
                    this.editorNotify.showWrappers();
                break;
            case 'preview':
            default:
                break;
        }
    }


    UndoMessageButton = (onUndo, contentProps) => {
        if(contentProps.toastProps.autoClose === 3000) {
            const handleClick = () => {
                onUndo();
                contentProps.closeToast();
            };
            return (
                <div><span style={{marginTop: 6}}>Kaydediliyor&hellip;</span> <button className="float-right btn btn-xs btn-primary" onClick={handleClick}>Durdur</button></div>
            );
        } else {
            contentProps.toastProps.className = "d-none"
        }


    }

    startCreateTemplate = async(event, values, status) => {
        // console.log("Start isLoading...");
        this.setState({isLoading: true})
        toast.warn((contentProps) => this.UndoMessageButton(this.stopMessage, contentProps) , {
            onClose: () => {
                if(this.state.isLoading){
                    if(status === "onSave"){
                        this.onSave(event,values)
                    }
                    if(status === "onSaveAs"){
                        this.onSaveAs(event,values)
                    }
                }
            }
        });
    }

    stopMessage = () => {
        this.setState({isLoading: false})
    }


    render() {
        return (
            <LoadingOverlay
                active={this.state.isLoading}
                // spinner
                // text="Şablon kaydediliyor!"
                styles={{wrapper: {width: '100%', height: '100%'}}}
            >
                <div className="canvasRoot" style={{width: "300px !important"}}>

                    <div className="design-change">
                        <Button.Group>
                            <Button toggle onClick={() => this.setActiveCanvas('design-content')}>İçerik</Button>
                            <Button toggle onClick={() => this.setActiveCanvas('design-notify')}>Bildirim</Button>
                            <Button toggle onClick={() => this.setActiveCanvas('preview')}>Önizleme</Button>
                        </Button.Group>
                    </div>

                    <div id="temp-design-content" className="canvas-content">

                    </div>
                    <div id="temp-design-notify" className="canvas-content">

                    </div>
                    <div id="preview" style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: 900,
                        paddingLeft: 30,
                        display: this.state.mode === 'preview' ? 'block' : 'none'
                    }}>
                        <PreviewDevice
                            deviceType="DESKTOP"
                            device="macbookpro"
                            scale="0.8"
                            wallpaper="url(/images/macos-wallpaper.png)"
                            notification={{
                                show: true,
                                title: 'Mesaj başlığını buraya yazınız',
                                subtitle: 'Yazılı ve görsel mesajların e-posta trafiğine takılmadan çalışanlara ulaşmasını sağlar.',
                                body: 'Pazarlama',
                                snooze: 0,
                                top: 0,
                                content: this.editorNotify ? `<style>html,body{padding:0; margin:0};${this.editorNotify.getCss()}${PreviewCss.css}</style>${this.editorNotify.getHtml()}` : null
                            }}>
                            <PreviewWindow
                                width={this.state.templateWidth}
                                height={this.state.templateHeight}
                                bgcolor="#ffffff"
                                mediaType="POPUP"
                                title='The title of message'
                                subtitle='The sub title of message'>
                                {this.editorContent &&
                                <PreviewFrame
                                    content={`<style>html,body{padding:0; margin:0; height: 560px};${this.editorContent.getCss()}</style><div style="width: ${this.state.templateWidth}px; height: ${this.state.templateHeight}px">${this.editorContent.getHtml()}</div>`}/>
                                }
                            </PreviewWindow>
                        </PreviewDevice>

                    </div>
                    <ToastContainer
                        pauseOnFocusLoss={false}
                        closeOnClick={false}
                        closeButton={false}
                        draggable={false}
                        position="bottom-left"
                        autoClose={3000}

                    />
                </div>
            </LoadingOverlay>
        )
    }
}

export default TemplateDesignPage;
