import React, {Component} from 'react';
import { CompactPicker } from 'react-color';
import { Picker } from 'emoji-mart';
import PubSub from "pubsub-js";
import {getBraces} from "../../../services/api";

class TickerSettings extends Component {
    constructor(props) {
        super(props);


        this.state={
            braces: [],
            forecolor: '#000000',
            texts: [{'content': '', 'class': 'ticker-item'}],
            separator: '',
            tickermessages: [],
            periodInterval: 0,
            periodCount: 0,
            repeatCount: 1,
            position: 'BOTTOM',
            speed: 2,
            bgcolor: localStorage.getItem('bgcolor') ? localStorage.getItem('bgcolor') : '#ffffff',
            bgColorToggle:false,
            foreColorToggle:false,
            separatorToggle:false,
            tickerPositionToggle:false,
            tickerSpeedToggle:false,
            tickerRepeatToggle:false,
            tickerSendToggle:false
        }
        this.labelCss = {backgroundColor:"#3B4182",height:"38px",padding:"9px 10px 9px 20px",letterSpacing:"1px",color:"white",cursor:"pointer"}
        this.contentCss = {padding: 16}
    }
    tickerPositionToggle = () =>{
        this.setState({tickerPositionToggle:!this.state.tickerPositionToggle})
    }
    tickerSpeedToggle = () =>{
        this.setState({tickerSpeedToggle:!this.state.tickerSpeedToggle})
    }
    tickerRepeatToggle = () =>{
        this.setState({tickerRepeatToggle:!this.state.tickerRepeatToggle})
    }
    tickerSendToggle = () =>{
        this.setState({tickerSendToggle:!this.state.tickerSendToggle})
    }
    bgColorToggle = () =>{
        this.setState({bgColorToggle:!this.state.bgColorToggle})
    }
    foreColorToggle = () =>{
        this.setState({foreColorToggle:!this.state.foreColorToggle})
    }
    separatorToggle = () =>{
        this.setState({separatorToggle:!this.state.separatorToggle})
    }

    createTickerTexts = () => {
        if(this.state.texts.length > 0) {
            let tickermessages = [];

            this.state.texts.map((text) => {
                let message = {
                    'content': `<label style='color:${this.state.forecolor}'>${text.content}</label>`,
                    'class': 'ticker-item'
                };

                tickermessages.push(message);

                if (this.state.separator !== '') {
                    tickermessages.push({'content': this.state.separator, 'class': 'ticker-separator'});
                }
            });

            this.setState({tickermessages: tickermessages}, () => this.publishSettings());
        }
    }

    publishSettings = () => {
        PubSub.publish("TickerSettings",{
            forecolor: this.state.forecolor,
            //texts: this.state.texts,
            separator: this.state.separator,
            // tickermessages: this.state.tickermessages,
            periodInterval: this.state.periodInterval,
            periodCount: this.state.periodCount,
            repeatCount: this.state.repeatCount,
            position: this.state.position,
            speed: this.state.speed,
            bgcolor: this.state.bgcolor
        })
    }

    showPicker = () => {
        this.setState({showPicker:true})
    }

    componentDidMount = async () =>{
        await getBraces()
            .then(data => {
                if(data !== undefined){
                    let tempArr = [];
                    data.map(brace => {
                        return (
                            tempArr.push({
                                name: brace.name,
                                short_names: [brace.name],
                                text: brace.name,
                                emoticons: [],
                                keywords: [brace.name],
                                imageUrl: brace.logo,
                                customCategory: '',
                                custom: true,
                                }
                            )
                        )
                    })
                    this.setState({braces: tempArr},()=>{this.showPicker()})
                }
            })

    }

    render() {
        return (
            <div>
                    <div className="row" >
                        <div className="col-12">
                            {/*<div style={this.labelCss} onClick={()=>this.bgColorToggle()} >*/}
                            {/*    <p>{this.state.bgColorToggle?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Arka plan rengi </span></p>*/}
                            {/*</div>*/}


                                <div style={this.contentCss}>
                                    <label className="settingsLabel" >Arkaplan Rengi</label>
                                <CompactPicker color={this.state.bgcolor} onChange={(color, event) => {
                                    this.setState({bgcolor: color.hex}, () => this.publishSettings());
                                    if(this.editorBody){this.editorBody.style.backgroundColor = color.hex;}
                                }} /></div>

                            <div>
                                {/*<div style={this.labelCss} onClick={()=>this.foreColorToggle()}>*/}
                                {/*<p >{this.state.foreColorToggle?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Yazı rengi</span> </p>*/}
                                {/*</div>*/}

                                    <div  style={this.contentCss}>
                                        <label className="settingsLabel" >Metin Rengi</label>
                                <CompactPicker color={this.state.forecolor} onChange={(color, event) => {
                                    this.setState({forecolor: color.hex}, () => this.createTickerTexts());
                                }} /></div>
                            </div>
                            <div>
                                {/*<div style={this.labelCss} onClick={() => this.separatorToggle()}>*/}
                                {/*    <p>{this.state.separatorToggle?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Metin ayracı</span></p>*/}
                                {/*</div>*/}
                                    <div style={this.contentCss}>
                                        <label className="settingsLabel" >Ayraç</label>
                                         <span>Seçili Ayraç : </span><span className="fs-22 ml-1"
                                               dangerouslySetInnerHTML={{__html: this.state.separator}}/>
                                        {this.state.showPicker?
                                            <Picker
                                                native={true}
                                                showPreview={false}
                                                showSkinTones={false}
                                                useButton={true}
                                                color="#3B4182"
                                                title="Duyuroom"
                                                onSelect={emoji => {
                                                    if (emoji.custom) {
                                                        this.setState({separator: `<img src="${emoji.imageUrl}" style="width: 32px; height: 32px "/>`}, () => this.createTickerTexts())
                                                    } else {
                                                        this.setState({separator: emoji.native}, () => this.createTickerTexts())
                                                    }
                                                }}
                                                style={{width: '100%', height: 360}}
                                                include={["symbols", "custom"]}
                                                emojisToShowFilter={emoji => {
                                                    return emoji.custom ? true : ["1F534", "1F7E0", "1F7E1", "1F7E1", "1F535", "1F7E3", "1F7E4", "26AB", "26AA", "1F7E5", "1F7E7", "1F7E8", "1F7E9", "1F7E6", "1F7EA", "1F7EB", "2B1B", "2B1C", "25FC-FE0F", "25FB-FE0F", "25FE", "25FD", "25AA-FE0F", "25AB-FE0F", "1F536", "1F537", "1F538", "1F539", "1F53A", "1F53B", "1F4A0", "1F518", "1F533", "1F532"].includes(emoji.unified)
                                                }}
                                                i18n={{
                                                    search: 'Ara',
                                                    clear: 'Temizle',
                                                    notfound: 'Emoji bulunamadı',
                                                    emojiSize: 16,
                                                    categorieslabel: 'Kategoriler',
                                                    categories: {
                                                        symbols: 'Ayraç Alternatifleri',
                                                        custom: 'Özel',
                                                    }
                                                }}
                                                custom={this.state.braces}
                                            />
                                            :
                                            null
                                        }

                                    </div>
                            </div>
                            <div className="tab-pane" id="ticker_options">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mb-0">
                                            {/*<div style={this.labelCss} onClick={() => this.tickerPositionToggle()}>*/}
                                            {/*<p >{this.state.tickerPositionToggle?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Alt yazı bandının pozisyonunu </span></p>*/}
                                            {/*</div>*/}

                                            <div style={this.contentCss}>
                                                <label className="settingsLabel" >Ekran Konumu</label>
                                                <select className="form-select" value={this.state.position} onChange={e => this.setState({position: e.target.value}, () => this.publishSettings())}>
                                                <option value="BOTTOM">Ekranın altında</option>
                                                <option value="TOP">Ekranın üstünde</option>
                                            </select></div>

                                        </div>
                                        <div className="form-group mb-0">
                                            {/*<div style={this.labelCss} onClick={() => this.tickerSpeedToggle()}>*/}
                                            {/*<p>{this.state.tickerSpeedToggle?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Yazının geçiş hızını</span> </p>*/}
                                            {/*</div>*/}
                                            <div style={this.contentCss}>
                                                <label className="settingsLabel" >Hız</label>
                                                <select className="form-select" value={this.state.speed} onChange={e => this.setState({speed: e.target.value}, () => this.publishSettings())}>
                                                <option value="1">Yavaş</option>
                                                <option value="2">Orta</option>
                                                <option value="3">Hızlı</option>
                                            </select></div>

                                        </div>
                                        <div className="form-group mb-0">
                                            {/*<div style={this.labelCss}  onClick={() => this.tickerRepeatToggle()}>*/}
                                            {/*<p >{this.state.tickerRepeatToggle?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Alt yazı tekrar gösterim sayısı</span></p>*/}
                                            {/*</div>*/}
                                            <div style={this.contentCss}>
                                                <label className="settingsLabel" >Tekrar Sayısı</label>
                                                <select className="form-select" value={this.state.repeatCount} onChange={e => this.setState({repeatCount: e.target.value}, () => this.publishSettings())}>
                                                <option value="1">1 tekrar</option>
                                                <option value="2">2 tekrar</option>
                                                <option value="3">3 tekrar</option>
                                                <option value="4">4 tekrar</option>
                                                <option value="5">5 tekrar</option>
                                            </select></div>

                                        </div>
                                        <div className="form-group mb-0">
                                            {/*<div style={this.labelCss} onClick={() => this.tickerSendToggle()}>*/}
                                            {/*<p>{this.state.tickerSendToggle?<i className="fa fa-caret-down" aria-hidden="true"></i>:<i className="fa fa-caret-right" aria-hidden="true"></i>}<span className="ml-3">Alt yazı tekrar gönderim sayısı</span></p>*/}
                                            {/*</div>*/}
                                             <div style={this.contentCss}>
                                                Toplamda
                                                <input type="text" style={{width: 60, textAlign: 'right', marginLeft: 5, marginRight: 5}} value={this.state.periodCount} onChange={e => this.setState({periodCount: e.target.value}, () => this.publishSettings())}/>
                                                kez,
                                                her
                                                <input type="text" style={{width: 60, textAlign: 'right', marginLeft: 5, marginRight: 5}} value={this.state.periodInterval} onChange={e => this.setState({periodInterval: e.target.value}, () => this.publishSettings())}/>
                                                dakikada bir tekrar gönderilsin.
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default TickerSettings;