import React from 'react';
import { renderToString } from 'react-dom/server';
import PubSub from "pubsub-js";
import { Button } from "semantic-ui-react";
import PreviewWindow from "../../components/common/PreviewWindow";
import PreviewDevice from "../../components/common/PreviewDevice";
import PreviewFrame from "../../components/common/PreviewFrame";
import CanvasManager from "../../tools/CanvasManager";
import {isoValues, setPageTitle} from "../../helpers/LayoutHelper";
import * as API from "../../services/api";
import ListController from "../../components/quiz/ListController";
import {ChonkyActions, defineFileAction, FullFileBrowser} from "chonky";
import {protectedApi} from "../../services/axios";
import settings from "../../config/settings.json";
import {turkishI18n} from "../../helpers/Language";
import ELearningBuilder from "../../components/elearning/ELearningBuilder";
import Swal from "sweetalert2";
import {PreviewCss} from "../../helpers/PreviewCssHelper";

const CM = new CanvasManager();
let subTokens = [];

class PublishAgainElearning extends React.Component {
    mounted = false;

    state = {
        mode: 'quiz',
        content1: {},
        content2: {},
        notify: {},
        model: null,
        questions: []
    };

    constructor(props) {
        super(props);

        this.state={
            previewPage:0
        }

        this.editorContent1 = null;
        this.editorContent2 = null;
        this.editorNotify = null;

        this.setVideoAction = defineFileAction({
            id: 'set_video',
            requiresSelection: true,
            fileFilter: file => file && file.type.includes("video"),
            button: {
                name: 'Eğitim Videosu',
                toolbar: true,
                contextMenu: true,
                icon: 'video',
                group: 'Actions'
            },
        });

        this.previewFileAction = defineFileAction({
            id: 'preview_file',
            requiresSelection: true,
            button: {
                name: 'Ön İzleme',
                toolbar: true,
                contextMenu: true,
                icon: 'symlink',
                group: 'Actions'
            },
        });
    }

    componentDidMount = () => {
        this.loadTemplate();
        PubSub.publish("CANVAS", { showPanel: true});
        PubSub.subscribe('ask-template-elearning',this.sendCurrentTemplate)
        PubSub.subscribe('data-classification',this.addISO)
    }

    addISO = (msg,data) => {
        const component = this.editorContent1?.getWrapper().find('#isoFooter');
        if (component?.length !==0){
            component[0].remove();
        }
        const component1 = this.editorContent2?.getWrapper().find('#isoFooter');
        if (component1?.length !==0){
            component1[0].remove();
        }
        const componentText = this.editorContent1?.getWrapper().find('.second-container');
        const componentText1 = this.editorContent2?.getWrapper().find('.second-container');
        if (data === "PUBLIC"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.PUBLIC}</p>`);
            }
            if (componentText1?.length !==0){
                componentText1[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.PUBLIC}</p>`);
            }
        }else if(data === "RESTRICTED"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.RESTRICTED}</p>`);
            }
            if (componentText1?.length !==0){
                componentText1[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.RESTRICTED}</p>`);
            }

        }else if(data === "CONFIDENTIAL"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.CONFIDENTIAL}</p>`);
            }
            if (componentText1?.length !==0){
                componentText1[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.CONFIDENTIAL}</p>`);
            }

        }else if(data === "TOPSECRET"){
            if (componentText?.length !==0){
                componentText[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.TOPSECRET}</p>`);
            }
            if (componentText1?.length !==0){
                componentText1[0].append(`<p id="isoFooter" class="ui footer isoFooterCss"><br>${isoValues.TOPSECRET}</p>`);
            }

        }

    }
    getPosition = (string, subString, index) => {
        return string.split(subString, index).join(subString).length;
    }

    loadTemplate = () => {
        API.getAnnouncement(this.props.location?.state?.id?this.props.location.state.id:window.sendAgainId).then((response) => {
            if (response && response.questions.filter(que => que===null).length ===0) {
                PubSub.publish('sendAgainPage', response)
                setPageTitle(response.title);

                const temp = response.questions?.map((data,index)=> {
                    if (data.type==="YESNO"){
                        data.type="Doğru/Yanlış Sorusu"
                        return data
                    }else if (data.type==="SINGLE"){
                        data.type="Tek Seçimli Soru"
                        data.inputType="radio"
                        return data
                    }else if (data.type==="MULTIPLE"){
                        data.type="Çok Seçimli Soru"
                        data.inputType="checkbox"
                        return data
                    }
                })
                this.setState({
                    questions:temp,
                    files:response.files
                })

                let cssEnd1 = this.getPosition(response.description, '<', 2)
                let cssContent1 = response.description.substr(0,Number(cssEnd1))

                cssContent1 = cssContent1.replace('<style>', '')
                let htmlContent1 = response.description.substr(Number(cssEnd1)+8)

                let cssEnd2 = this.getPosition(response.analysisEndNote, '<', 2)
                let cssContent2 = response.analysisEndNote.substr(0,Number(cssEnd2))

                cssContent2 = cssContent2.replace('<style>', '')
                let htmlContent2 = response.analysisEndNote.substr(Number(cssEnd2)+8)

                let notifyCssEnd = this.getPosition(response.notifyHtml, '<', 2)
                let notifyCssContent = response.notifyHtml.substr(0,Number(notifyCssEnd))

                notifyCssContent = notifyCssContent.replace('<style>', '')
                let notifyHtmlContent = response.notifyHtml.substr(Number(notifyCssEnd)+8)

                CM.ElementWaiting(['canvasTools'], () => {
                    this.editorContent1 = CM.CreateCanvas('design-content-1');
                    this.editorContent2 = CM.CreateCanvas('design-content-2');
                    this.editorNotify = CM.CreateCanvas('design-notify');
                    this.setActiveCanvas('design-content-1');
                    CM.AppendDefaultHeadLinks();

                    this.editorContent1.setComponents(CM.GetLiquidTemplateContent(htmlContent1));
                    this.editorContent1.setStyle(cssContent1+'.isoFooterCss{color: #010232;font-size: 12px;}');

                    this.editorContent2.setComponents(CM.GetLiquidTemplateContent(htmlContent2));
                    this.editorContent2.setStyle(cssContent2+'.isoFooterCss{color: #010232;font-size: 12px;}');

                    this.editorNotify.setComponents(notifyHtmlContent);
                    this.editorNotify.setStyle(notifyCssContent);
                });


            }
        })
    }



    componentWillUnmount() {

        PubSub.unsubscribe('canvas_popup.save');
        PubSub.unsubscribe('canvas_popup.update');
        PubSub.unsubscribe('ask-template-elearning')
        PubSub.unsubscribe('data-classification')

        PubSub.publish("CANVAS", { showPanel: false });

        if (this.editorContent1) {
            this.editorContent1.destroy();
        }
        if (this.editorContent2) {
            this.editorContent2.destroy();
        }
        if (this.editorNotify) {
            this.editorNotify.destroy();
        }
    }

    setActiveCanvas = (mode) => {
        if (this.state.mode !== mode) {
            this.setState({ mode,previewPage:0 });
        }

        const content1 = this.editorContent1.getHtml()
        let indexOfDiv = this.getPosition(content1,">",2)

        let newText = content1.slice(0, indexOfDiv+1) + `
                <div class="ui middle aligned two column centered padded grid">
                <div class="row">
                <div class="column"  id="video-player"><img data-gjs-editable="false" data-gjs-removable="false" data-gjs-draggable="false" class="ui fluid image second-image" style="margin:auto"
                src="https://dummyimage.com/700x400/ba84ba/ffffff.jpg&text=Video+Alanı"></div>
                </div>
                </div>` + content1.slice(indexOfDiv+1);

        console.log(newText)
        this.setState({editorPreviewPage1:newText})

        if (this.editorNotify && this.editorNotify.hideWrappers)
            this.editorNotify.hideWrappers();
        if (this.editorContent1 && this.editorContent1.hideWrappers)
            this.editorContent1.hideWrappers();
        if (this.editorContent2 && this.editorContent2.hideWrappers)
            this.editorContent2.hideWrappers();

        switch (mode) {
            case 'design-content-1':
                if (this.editorContent1)
                    this.editorContent1.showWrappers();
                break;
            case 'design-content-2':
                if (this.editorContent2)
                    this.editorContent2.showWrappers();
                break;
            case 'design-notify':
                if (this.editorNotify)
                    this.editorNotify.showWrappers();
                break;
            case 'preview':

            case 'elearning':

            default:
                break;
        }
    }


    sendCurrentTemplate = () => {
        let tempArr = []
        this.state?.questions?.map((data) => {
            if(data.type === null || data.text === "" || (data.answers.length === 0 && data.cols.length === 0)) {
                tempArr.push(data)
            }
        })

        if (this.state.questions === undefined) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen Soru giriniz.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }
        if (tempArr.length !== 0) {
            Swal.fire({
                icon: 'warning',
                text: 'Lütfen Soru/Soruların detaylarını giriniz.',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: '#3B4182',
                cancelButtonText: "Tamam",
                focusCancel: false,
            })
            return
        }
        let tempQuestions = this.state.questions.map((data,index)=>{
            if(data.type==="Doğru/Yanlış Sorusu"){
                data.type="YESNO"
                return data
            }else if(data.type==="Tek Seçimli Soru"){
                data.type="SINGLE"
                return data
            }else if(data.type==="Çok Seçimli Soru"){
                data.type="MULTIPLE"
                return data
            }
        })

        const templates = {
            beginNote: "<style>" + this.editorContent1?.getCss() + "</style>" + this.editorContent1?.getHtml(),
            endNote: "<style>" + this.editorContent2?.getCss() + "</style>" + this.editorContent2?.getHtml(),
            notify : "<style>" + this.editorNotify?.getCss() + "</style>" + this.editorNotify?.getHtml(),
            questions: tempQuestions,
            files:this.state.files
        }
        PubSub.publish('current-template',templates)
    }

    handleFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let selectedFile = event.target.files[0];

        this.handleFileUpload(selectedFile);
    }

    handleFileUpload = (file) => {
        console.log(this.state.files)
        let data = new FormData();
        let config = {header: {'Content-Type': 'multipart/form-data'}}

        this.setState({isUploading: true, files: [...this.state.files, null]});

        data.append('file', file, file.name);

        protectedApi.post('/announcement/fileuploads', data, config).then(response => {
            let uploadedFile = {
                id: response.data.id,
                name: file.name,
                url: settings.apiBaseClear + response.data.file,
                thumbnailUrl: settings.apiBaseClear + response.data.file,
                isThumbnail: false,
                isVideo: false,
                color: "#3B4182",
                icon: null,
                type: file.type
            }

            let allFiles = [...this.state.files.filter(f => f !== null)];

            allFiles.push(uploadedFile);


            this.setState({files: allFiles, showError: false, isUploading: false, waitingForSelectPreviewImage: false});

        }).catch(error => {
            this.setState({files: [...this.state.files.filter(f => f !== null)], isUploading: false, showError: true, showMessages: "&bull; Dosya yükleme işlemi başarısız."}, () => {
                window.scrollTo(0,0);
            })
        })
    }

    handleFileBrowserAction = (event) => {
        if(event.id === "upload_files"){
            this.upload.click();
        }

        if(event.id === "upload_thumbnail"){
            this.setState({waitingForSelectPreviewImage: true}, () => {
                this.upload.click();
            });
        }

        if(event.id === "delete_files"){
            let allFiles = [...this.state.files];
            let newFiles = allFiles.filter(i => !event.state.selectedFiles.some(j => j.id === i.id));

            this.setState({files: newFiles});
        }

        if(event.id === "set_thumbnail"){
            if(!event.state.selectedFiles || event.state.selectedFiles.length === 1){
                let allFiles = JSON.parse(JSON.stringify( [...this.state.files]));
                let index = allFiles.findIndex( x => x.id === event.state.selectedFiles[0].id);

                for(let f of allFiles){
                    f.isThumbnail = false;
                    f.icon = null;
                    f.color = "#3B4182";
                }

                allFiles[index].isThumbnail = true;
                allFiles[index].icon = "image";
                allFiles[index].color = "#F4C752";

                this.setState({files: allFiles, image: event.state.selectedFiles[0]});
            }else{
                this.setState({showError: true, showMessages: "&bull; Öne çıkan görseli ayarlamak için lütfen bir görsel seçiniz."}, () => {
                    window.scrollTo(0,0);
                })
            }
        }

        if(event.id === "set_video"){
            if(!event.state.selectedFiles || event.state.selectedFiles.length === 1){
                let allFiles = JSON.parse(JSON.stringify( [...this.state.files]));
                let index = allFiles.findIndex( x => x.id === event.state.selectedFiles[0].id);

                for(let f of allFiles){
                    f.isVideo = false;
                    f.icon = null;
                    f.color = "#3B4182";
                }

                allFiles[index].isVideo = true;
                allFiles[index].icon = "video";
                allFiles[index].color = "#F4C752";

                this.setState({files: allFiles, image: event.state.selectedFiles[0]});
            }else{
                this.setState({showError: true, showMessages: "&bull; Eğitim videosunu ayarlamak için lütfen bir video seçiniz."}, () => {
                    window.scrollTo(0,0);
                })
            }
        }

        if(event.id === "preview_file" && event.state.selectedFiles.length === 1){
            window.open(event.state.selectedFiles[0].url, "_blank")
        }
    }

    changePreviewPage = () =>{
        this.setState({previewPage:this.state.previewPage+1})
    }


    surveyToHtml = () => {
        return renderToString(<ELearningBuilder data={this.state.questions} show={false} mode="preview"/>)
    }

    backButton = () => {
        this.props.history.goBack()
    }

    render() {
        console.log("quesssssssssssssss",this.state.questions)
        return (
            <div className="canvasRoot" style={{background: '#ffffff'}}>
                <div className="col-12" style={{backgroundColor:"white",height:"35px"}}>
                    <button className="btn btn-primary btn-xs" onClick={() => {this.backButton()}}><i className="la la-arrow-left"/></button>
                    <span className="ml-1">Geri</span>
                </div>
                <div className="design-change" style={{zIndex:4}}>
                    <Button.Group>
                        <Button toggle onClick={() => this.setActiveCanvas('design-content-1')}>Ön Yazı</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('elearning')}>Sorular</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('design-content-2')}>Kapanış Yazısı</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('design-notify')}>Bildirim</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('preview')}>Önizleme</Button>
                        <Button toggle onClick={() => this.setActiveCanvas('video')}>Video Ekleme</Button>
                    </Button.Group>
                </div>
                <div id="design-content-1" className="canvas-content">

                </div>

                <div id="design-content-2" className="canvas-content">

                </div>

                <div id="design-notify" className="canvas-content">

                </div>

                <div id="quiz" style={{background: '#ffffff', display: this.state.mode === 'elearning' ? 'block' : 'none' }}>
                    <ELearningBuilder
                        mode="edit"
                        subMode="again"
                        data={this.state.questions}
                        onChange={ (questions) => this.setState({questions}) }
                    />
                </div>
                <div id="add_video" style={{marginLeft: 'auto', marginRight: 'auto',background: '#ffffff', display: this.state.mode === 'video' ? 'block' : 'none' }}>
                    <input
                        type="file"
                        ref={(ref) => this.upload = ref}
                        style={{display: 'none'}}
                        onChange={this.handleFileChange}
                    />
                    <FullFileBrowser
                        i18n={turkishI18n.folders}
                        files={this.state.files}
                        folderChain={[]}
                        fileActions={[!this.state.isUploading ? ChonkyActions.UploadFiles : null,  this.previewFileAction, ChonkyActions.DeleteFiles,this.setVideoAction]}
                        disableDragAndDrop={true}
                        disableDefaultFileActions={true}
                        onFileAction={this.handleFileBrowserAction}
                    />
                </div>

                <div id="preview" style={{ marginLeft: 'auto', marginRight: 'auto', width: 900, paddingLeft: 30, display: this.state.mode === 'preview' ? 'block' : 'none' }}>
                    <PreviewDevice
                        deviceType="DESKTOP"
                        device="macbookpro"
                        scale="0.8"
                        wallpaper="url(/images/macos-wallpaper.png)"
                        notification={{
                            show: this.state.previewPage===0,
                            title: 'Mesaj başlığını buraya yazınız',
                            subtitle: 'Yazılı ve görsel mesajların e-posta trafiğine takılmadan çalışanlara ulaşmasını sağlar.',
                            body: 'Pazarlama',
                            snooze: 0,
                            top: 0,
                            content: this.editorNotify ? `<style>html,body{padding:0; margin:0};${this.editorNotify.getCss()}${PreviewCss.css}</style>${this.editorNotify.getHtml()}` : null,
                        }}>
                        <PreviewWindow
                            bgcolor="#ffffff"
                            mediaType="POPUP"
                            title='The title of message'
                            subtitle='The sub title of message'>
                            <div style={{height:this.state.previewPage===2?"100%":"510px"}}>
                                <button style={{position:"absolute",right:"20px",bottom:"3px",display:this.state.previewPage===2?"none":"block"}} className="btn btn-primary btn-sm px-4" onClick={()=>this.changePreviewPage()}>İleri</button>
                                {this.state.previewPage === 0 ?
                                    <>
                                        <PreviewFrame
                                            content={`<style>html,body{padding:5; margin:0}</style>${this.state.editorPreviewPage1?this.state.editorPreviewPage1:null}`}
                                        />
                                    </>

                                    : this.state.previewPage === 1 ?
                                        <PreviewFrame
                                            content={`<style>html,body{padding:5; margin:0}</style>${this.surveyToHtml()}`}
                                        />
                                        : this.state.previewPage === 2 ?
                                            <PreviewFrame
                                                content={`<style>html,body{padding:5; margin:0}</style>${this.editorContent2.getHtml()}`}
                                            />
                                            : null
                                }
                            </div>


                        </PreviewWindow>
                    </PreviewDevice>

                </div>
            </div>
        )
    }
}

export default PublishAgainElearning;
